"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Icon, Form, ContextMenu } from "kosmi-sdk/uikit";
import platform from "platform";
import React, { memo, useEffect, useState } from "react";
import { TopBarButton } from "../..";
import Modal from "../../../../../../../Widgets/AnimatedModal";
import { gql, useMutation } from "kosmi-sdk/gql";
import { useTracking, withDialogs } from "kosmi-sdk/helpers";
import kosmiHead from "../../../RoomBanner/kosmihead.svg";
import LoginWall from "../../../../../../../Widgets/LoginWall";
const inPwa = ["fullscreen", "standalone", "minimal-ui"].some(
  (displayMode) => window.matchMedia("(display-mode: " + displayMode + ")").matches
) || "standalone" in window.navigator && window.navigator.standalone;
const CONTACT_SUPPORT = gql(`
  mutation SendSupportEmail($body: String!) {
    sendSupportEmail(body: $body) {
      ok
    }
  }
`);
export const SupportForm = withDialogs(
  ({
    onSuccess,
    alert
  }) => {
    const [text, setText] = React.useState("");
    const [sendSupportEmail, { loading }] = useMutation(CONTACT_SUPPORT);
    return /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(
      Form.TextArea,
      {
        placeholder: "What can we help you with?",
        value: text,
        onChange: (e) => {
          setText(e.target.value);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Form.Button,
      {
        disabled: !text || text.split(" ").length < 5,
        fluid: true,
        color: "yellow",
        inverted: true,
        loading,
        style: { marginTop: 10 },
        onClick: () => __async(void 0, null, function* () {
          if (!alert)
            return;
          try {
            yield sendSupportEmail({
              variables: {
                body: `${text}

Sent from ${platform.description}
              `
              }
            });
            yield alert(
              "Thanks for contacting support. We will reach out to you as soon as possible. Keep an eye on your email!"
            );
            if (onSuccess)
              onSuccess();
          } catch (e) {
            alert(
              "There was an error sending your message. Please try again later."
            );
          }
        })
      },
      "Send"
    ));
  }
);
const _SupportModal = memo(({ trigger }) => {
  const track = useTracking();
  const [modal, setModal] = React.useState(null);
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      closeIcon: true,
      trigger,
      onOpen: () => track("UI", "OpenSupportModal", "Open Support Modal"),
      onModalClose: () => track("UI", "CloseSupportModal", "Close Support Modal"),
      onModal: (modal2) => setModal(modal2)
    },
    /* @__PURE__ */ React.createElement(Modal.Header, null, "Send support email"),
    /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(SupportForm, { onSuccess: () => modal == null ? void 0 : modal.handleClose() }))
  );
});
export const SupportModal = withDialogs(_SupportModal);
export const Links = memo(() => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("a", { target: "_blank", href: "https://kosmi.io", rel: "noreferrer" }, /* @__PURE__ */ React.createElement(Icon, { name: "home" }), " Visit Website"), /* @__PURE__ */ React.createElement(
  SupportModal,
  {
    trigger: /* @__PURE__ */ React.createElement("a", null, /* @__PURE__ */ React.createElement(Icon, { name: "help circle" }), " Contact Support")
  }
)));
const KosmiDropdown = memo(
  ({
    trigger,
    onClose,
    open
  }) => {
    const [showInstallButton, setShowInstallButton] = useState(false);
    useEffect(() => {
      const isAndroid = /(android)/i.test(navigator.userAgent);
      const waitForInstallPrompt = () => {
        if (!window.installPrompt && window.installPrompt !== null) {
          setTimeout(() => waitForInstallPrompt(), 1e3);
          return;
        }
        if (window.installPrompt) {
          setShowInstallButton(!inPwa && !isAndroid);
          if (window.installPrompt.userChoice) {
            ;
            (() => __async(void 0, null, function* () {
              const userChoice = yield window.installPrompt.userChoice;
              if (userChoice && userChoice.outcome === "accepted") {
                setShowInstallButton(false);
              }
            }))();
          }
        }
      };
      waitForInstallPrompt();
    }, []);
    return /* @__PURE__ */ React.createElement(
      ContextMenu,
      {
        position: "right",
        open,
        trigger,
        onClose
      },
      showInstallButton && /* @__PURE__ */ React.createElement(
        ContextMenu.Item,
        {
          text: "Install Kosmi",
          onClick: () => __async(void 0, null, function* () {
            yield window.installPrompt.prompt();
          })
        }
      ),
      /* @__PURE__ */ React.createElement(
        ContextMenu.Item,
        {
          text: "Visit Website",
          icon: "home",
          onClick: () => {
            window.open("https://kosmi.io", "_blank");
          }
        }
      ),
      /* @__PURE__ */ React.createElement(
        SupportModal,
        {
          trigger: /* @__PURE__ */ React.createElement(LoginWall, null, /* @__PURE__ */ React.createElement(ContextMenu.Item, { text: "Contact Support", icon: "help circle" }))
        }
      )
    );
  }
);
const KosmiDropdownWrapper = memo(() => {
  const [showDropdown, setShowDropdown] = useState(false);
  return /* @__PURE__ */ React.createElement("div", { style: { position: "relative", display: "flex" } }, /* @__PURE__ */ React.createElement(
    KosmiDropdown,
    {
      open: showDropdown,
      trigger: /* @__PURE__ */ React.createElement(
        TopBarButton,
        {
          onClick: () => {
            setShowDropdown(!showDropdown);
          }
        },
        /* @__PURE__ */ React.createElement("img", { src: kosmiHead, alt: "Kosmi Logo" })
      ),
      onClose: () => {
        if (showDropdown) {
          setShowDropdown(false);
        }
      }
    }
  ));
});
export default KosmiDropdownWrapper;
