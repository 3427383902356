"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Form, Loader, Select } from "kosmi-sdk/uikit";
import React, { useEffect, useRef, useState } from "react";
import AVEngine from "../../../../../../../../../core/services/AVEngine";
import { withStyleHook } from "../../../../../../../../sdk/styles";
import style from "./style.css";
const MediaSourceSettings = ({ onClose }) => {
  const [availableAudioDevices, setAvailableAudioDevices] = useState(
    []
  );
  const [availableVideoDevices, setAvailableVideoDevices] = useState(
    []
  );
  const [preferredAudioSourceId, setPreferredAudioSourceId] = useState(
    null
  );
  const [preferredVideoSourceId, setPreferredVideoSourceId] = useState(
    null
  );
  const [previewVideoStream, setPreviewVideoStream] = useState(
    null
  );
  const [previewAudioStream, setPreviewAudioStream] = useState(
    null
  );
  const styles = withStyleHook(style);
  const [permissionsRejected, _setPermissionsRejected] = useState(false);
  const [devicesRequested, setDevicesRequested] = useState(false);
  const [videoShown, setVideoShown] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const meteringRef = useRef(false);
  const canvasCtxRef = useRef(null);
  useEffect(() => {
    _getUsersMediaDevices();
    return () => {
      meteringRef.current = false;
      if (!AVEngine.videoRequestedForCall) {
        AVEngine.stopUserMediaVideoStream();
      }
      if (!AVEngine.audioRequestedForCall) {
        AVEngine.stopUserMediaAudioStream();
      }
    };
  }, []);
  useEffect(() => {
    if (AVEngine.userMediaVideoStream && videoRef.current) {
      videoRef.current.srcObject = AVEngine.userMediaVideoStream;
    }
  });
  const _drawAudioMeter = () => {
    const canvasCtx = canvasCtxRef.current;
    if (canvasRef.current && canvasCtx) {
      requestAnimationFrame(_drawAudioMeter);
      AVEngine._userMediaAudioAnalyser.getByteTimeDomainData(
        AVEngine._userAudioAnalyserDataArray
      );
      canvasCtx.fillStyle = "#130a25";
      canvasCtx.fillRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "#fff377";
      canvasCtx.beginPath();
      const sliceWidth = canvasRef.current.width * 1 / AVEngine._userAudioAnalyserBufferLength;
      let x = 0;
      for (let i = 0; i < AVEngine._userAudioAnalyserBufferLength; i++) {
        const v = AVEngine._userAudioAnalyserDataArray[i] / 128;
        const y = v * canvasRef.current.height / 2;
        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }
        x += sliceWidth;
      }
      canvasCtx.lineTo(canvasRef.current.width, canvasRef.current.height / 2);
      canvasCtx.stroke();
    }
  };
  const _setupAudioMeter = () => {
    var _a;
    canvasCtxRef.current = (_a = canvasRef.current) == null ? void 0 : _a.getContext("2d");
    _drawAudioMeter();
    meteringRef.current = true;
  };
  const _getUsersMediaDevices = () => __async(void 0, null, function* () {
    const mediaDevices = yield AVEngine.getUserMediaDevices();
    if (mediaDevices) {
      setDevicesRequested(true);
      setAvailableAudioDevices(mediaDevices.audioDevices);
      setAvailableVideoDevices(mediaDevices.videoDevices);
      setPreferredAudioSourceId(AVEngine.getPreferredAudioInputDeviceId());
      setPreferredVideoSourceId(AVEngine.getPreferredVideoInputDeviceId());
      if (AVEngine.getPreferredVideoInputDeviceId()) {
        if (AVEngine.userMediaVideoStream) {
          setPreviewVideoStream(AVEngine.userMediaVideoStream);
        } else {
          setPreviewVideoStream(
            yield AVEngine.getUserMediaVideoStream(
              AVEngine.getPreferredVideoInputDeviceId()
            )
          );
        }
        if (previewVideoStream && videoRef.current) {
          videoRef.current.srcObject = previewVideoStream;
          setVideoShown(true);
        }
      }
      if (AVEngine.getPreferredAudioInputDeviceId()) {
        if (AVEngine.userMediaAudioStream) {
          setPreviewAudioStream(AVEngine.userMediaAudioStream);
        } else {
          setPreviewAudioStream(
            yield AVEngine.getUserMediaAudioStream(
              AVEngine.getPreferredAudioInputDeviceId(),
              true
            )
          );
        }
        if (previewAudioStream && canvasRef.current) {
          _setupAudioMeter();
        }
      }
    } else {
      onClose();
    }
  });
  return /* @__PURE__ */ React.createElement("div", { className: styles.root }, devicesRequested && !permissionsRejected && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: styles.col }, /* @__PURE__ */ React.createElement("h2", null, "Video source"), /* @__PURE__ */ React.createElement("div", { className: styles.videoContainer }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: styles.videoLoading,
      style: { display: videoShown ? "none" : "flex" }
    },
    /* @__PURE__ */ React.createElement(Loader, null)
  ), /* @__PURE__ */ React.createElement(
    "video",
    {
      playsInline: true,
      autoPlay: true,
      className: styles.videoPreview,
      ref: videoRef
    }
  )), /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(
    Select,
    {
      value: preferredVideoSourceId || void 0,
      onChange: (e) => __async(void 0, null, function* () {
        setPreferredVideoSourceId(e.target.value);
        AVEngine.setPreferredVideoInputDeviceId(e.target.value);
        setPreviewVideoStream(
          yield AVEngine.getUserMediaVideoStream(
            AVEngine.getPreferredVideoInputDeviceId()
          )
        );
        if (videoRef.current) {
          videoRef.current.srcObject = previewVideoStream;
        }
      }),
      className: styles.select,
      options: availableVideoDevices.map((d) => ({
        text: d.label,
        value: d.deviceId
      }))
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: styles.col }, /* @__PURE__ */ React.createElement("h2", null, "Audio source"), /* @__PURE__ */ React.createElement(
    "canvas",
    {
      className: styles.audioCanvas,
      width: "240",
      height: "135",
      ref: canvasRef
    }
  ), /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(
    Select,
    {
      value: preferredAudioSourceId || void 0,
      onChange: (e) => __async(void 0, null, function* () {
        setPreferredAudioSourceId(e.target.value);
        AVEngine.setPreferredAudioInputDeviceId(e.target.value);
        yield AVEngine.getUserMediaAudioStream(
          AVEngine.getPreferredAudioInputDeviceId()
        );
        if (!meteringRef.current) {
          _setupAudioMeter();
        }
      }),
      className: styles.select,
      options: availableAudioDevices.map((d) => ({
        text: d.label,
        value: d.deviceId
      }))
    }
  )))), !devicesRequested && /* @__PURE__ */ React.createElement("div", { className: styles.loaderContainer }, /* @__PURE__ */ React.createElement(Loader, { active: true })));
};
export default MediaSourceSettings;
