"use strict";
import React from "react";
import { Icon } from "kosmi-sdk/uikit";
import { withStyleHook } from "kosmi-sdk/styles";
import style from "./style.css";
export default ({ onClick }) => {
  const styles = withStyleHook(style);
  return /* @__PURE__ */ React.createElement(
    Icon,
    {
      size: "big",
      className: styles.root,
      name: "arrow left",
      onClick
    }
  );
};
