"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Input, TabView } from "kosmi-sdk/uikit";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import poweredByGiphyPng from "./Poweredby_100px-Black_VertLogo.png";
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);
const gf = new GiphyFetch("Hr9Vxqy8bNEMoridTgplyBIPWeqG1bmj");
const fetchGifs = (offset) => __async(void 0, null, function* () {
  const data = yield gf.trending({ offset, limit: 10 });
  return data.data.map((img) => img.images.fixed_width.url);
});
const GifPicker = forwardRef(
  ({
    onGifSelect
  }, ref) => {
    const [gifs, setGifs] = useState([]);
    const [trendingGifs, setTrendingGifs] = useState([]);
    useEffect(() => {
      ;
      (() => __async(void 0, null, function* () {
        const gifs2 = yield fetchGifs(0);
        setTrendingGifs(gifs2);
        setGifs(gifs2);
      }))();
    }, []);
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        ref,
        style: {
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          flex: 1
        }
      },
      /* @__PURE__ */ React.createElement("div", { style: { display: "flex", flex: 1, maxHeight: "50px" } }, /* @__PURE__ */ React.createElement(
        "div",
        {
          style: {
            flex: 0,
            width: "200px",
            margin: "5px auto",
            height: "30px",
            minHeight: 0,
            marginBottom: 15
          }
        },
        /* @__PURE__ */ React.createElement(
          Input,
          {
            placeholder: "Search GIPHY...",
            debounce: 1e3,
            style: {
              borderColor: "var(--foreground)"
            },
            onChange: (e) => {
              const value = e.target.value;
              if (value === "") {
                setGifs(trendingGifs);
              } else {
                ;
                (() => __async(void 0, null, function* () {
                  const results = yield gf.search(value, {
                    limit: 10
                  });
                  const gifs2 = results.data.map(
                    (img) => img.images.fixed_width.url
                  );
                  setGifs(gifs2);
                }))();
              }
            }
          }
        )
      ), /* @__PURE__ */ React.createElement(
        "div",
        {
          style: {
            background: `url('${poweredByGiphyPng}')`,
            width: 100,
            height: 27,
            maxWidth: 100,
            margin: "5px auto"
          }
        }
      )),
      /* @__PURE__ */ React.createElement(
        "div",
        {
          style: {
            overflow: "auto",
            textAlign: "center",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 0
          }
        },
        gifs.map((gif, i) => /* @__PURE__ */ React.createElement(
          "img",
          {
            key: i,
            style: { margin: "auto", width: 200, cursor: "pointer" },
            src: gif,
            onClick: () => {
              if (onGifSelect) {
                onGifSelect(gif);
              }
            }
          }
        ))
      )
    );
  }
);
const InnerEmojiPicker = ({ onSelect }) => {
  const pickerRef = useRef(null);
  useEffect(() => {
    const picker = pickerRef.current;
    picker.className = "dark";
    picker.addEventListener(
      "emoji-click",
      (event) => {
        onSelect({ native: event.detail.unicode });
      }
    );
    const style = document.createElement("style");
    style.textContent = ".favorites.emoji-menu {display: none !important;}";
    picker.shadowRoot.appendChild(style);
  }, []);
  return /* @__PURE__ */ React.createElement(
    "emoji-picker",
    {
      ref: pickerRef,
      style: { width: "100%", height: "100%" }
    }
  );
};
const EmojiPicker = forwardRef(
  ({
    hideGifPicker,
    onGifSelect,
    onSelect
  }, ref) => {
    if (isMobile) {
      return /* @__PURE__ */ React.createElement(GifPicker, { onGifSelect });
    }
    return /* @__PURE__ */ React.createElement(TabView, { ref }, /* @__PURE__ */ React.createElement(TabView.Item, { name: "Emojis" }, /* @__PURE__ */ React.createElement(InnerEmojiPicker, { onSelect })), !hideGifPicker && /* @__PURE__ */ React.createElement(TabView.Item, { name: "GIFs" }, /* @__PURE__ */ React.createElement(GifPicker, { onGifSelect })));
  }
);
export default EmojiPicker;
