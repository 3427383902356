"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql } from "kosmi-sdk/gql";
import { Settings, ImageUploader, Button } from "kosmi-sdk/uikit";
import React, { useEffect, useState } from "react";
import { useRoomId } from "../../../../../../withRoomId";
import { useMutation, useQuery } from "@apollo/client";
import { BannedMembers } from "../BannedMembersModal";
import { BannedIps } from "../BannedIpsModal";
import { withDialogs, currentUserHook } from "kosmi-sdk/helpers";
import {
  Themes,
  ColorSchemeSelector,
  DefaultTVPlaceholder
} from "../../../../../../../../../apps/spaces/app";
import backgroundSvg from "../../../../../../../../../apps/spaces/bg.svg";
import { ReactComponent as SofaSvg } from "../../../../../../../../../apps/spaces/sofa.svg";
import { useSpacesActions } from "../../../../../../../../../apps/spaces/hooks";
import { useSpacesStateCached } from "../../../../../../hooks/useRoomData";
const GET_METADATA = gql(`
  query MetaDataQuery($roomId: String!) {
    room(id: $roomId) {
      id
      state {
        metadata {
          avatarUrl
          roomName
          public
          requireAdminForWebcamAndMicrophone
          relayWebcamAndMicrophone
          relayServerRegion
          description
          autoAcceptJoins
          promoteNewUsersToAdmin
          requireLogin
          disableAutoplay
          disableWebcamAndMicrophone
          hideSidebarForNonAdmins
        }
      }
    }
  }
`);
const UPDATE_METADATA = gql(`
  mutation UpdateMetadata(
    $roomId: String!
    $autoAcceptJoins: Boolean
    $avatarFilename: String
    $description: String
    $disableAutoplay: Boolean
    $disableWebcamAndMicrophone: Boolean
    $hideSidebarForNonAdmins: Boolean
    $promoteNewUsersToAdmin: Boolean
    $public: Boolean
    $relayServerRegion: String
    $relayWebcamAndMicrophone: Boolean
    $requireAdminForWebcamAndMicrophone: Boolean
    $requireLogin: Boolean
    $roomName: String
  ) {
    updateMetadata(
      roomId: $roomId
      autoAcceptJoins: $autoAcceptJoins
      avatarFilename: $avatarFilename
      description: $description
      disableAutoplay: $disableAutoplay
      disableWebcamAndMicrophone: $disableWebcamAndMicrophone
      hideSidebarForNonAdmins: $hideSidebarForNonAdmins
      promoteNewUsersToAdmin: $promoteNewUsersToAdmin
      public: $public
      relayServerRegion: $relayServerRegion
      relayWebcamAndMicrophone: $relayWebcamAndMicrophone
      requireAdminForWebcamAndMicrophone: $requireAdminForWebcamAndMicrophone
      requireLogin: $requireLogin
      roomName: $roomName
    ) {
      ok
    }
  }
`);
const CLEAR_CHAT = gql(`
  mutation ClearChat($roomId: String!) {
    clearChat(roomId: $roomId) {
      ok
    }
  }
`);
const DESTROY_ROOM = gql(`
  mutation DestroyRoom($roomId: String!) {
    destroyRoom(roomId: $roomId) {
      ok
    }
  }
`);
const PermissionsSection = () => {
  var _a, _b;
  const roomId = useRoomId();
  const { data } = useQuery(GET_METADATA, { variables: { roomId } });
  const metadata = ((_b = (_a = data == null ? void 0 : data.room) == null ? void 0 : _a.state) == null ? void 0 : _b.metadata) || {};
  const [updateMetadata] = useMutation(UPDATE_METADATA);
  const updateAdminPermissions = (promoteNewUsersToAdmin) => {
    updateMetadata({ variables: { roomId, promoteNewUsersToAdmin } });
  };
  const updateRequireLogin = (requireLogin) => {
    updateMetadata({ variables: { roomId, requireLogin } });
  };
  const updateHideSidebar = (hideSidebarForNonAdmins) => {
    updateMetadata({ variables: { roomId, hideSidebarForNonAdmins } });
  };
  const updateRequireAdminForWebcamAndMicrophone = (requireAdminForWebcamAndMicrophone) => {
    updateMetadata({
      variables: { roomId, requireAdminForWebcamAndMicrophone }
    });
  };
  const updateDisableWebcamAndMicrophone = (disableWebcamAndMicrophone) => {
    updateMetadata({ variables: { roomId, disableWebcamAndMicrophone } });
  };
  return /* @__PURE__ */ React.createElement(Settings.Section, { name: "Permissions", icon: "lock" }, /* @__PURE__ */ React.createElement(Settings.InputGroup, null, /* @__PURE__ */ React.createElement(
    Settings.Checkbox,
    {
      setChecked: updateAdminPermissions,
      checked: !!metadata.promoteNewUsersToAdmin,
      label: "Give new members admin permissions by default"
    }
  ), /* @__PURE__ */ React.createElement(
    Settings.Checkbox,
    {
      setChecked: updateRequireLogin,
      checked: !!metadata.requireLogin,
      label: "Only allow logged in users to join the room"
    }
  ), /* @__PURE__ */ React.createElement(
    Settings.Checkbox,
    {
      setChecked: updateHideSidebar,
      checked: !!metadata.hideSidebarForNonAdmins,
      label: "Hide Sidebar from non-admins"
    }
  ), /* @__PURE__ */ React.createElement(
    Settings.Checkbox,
    {
      setChecked: updateDisableWebcamAndMicrophone,
      checked: !!metadata.disableWebcamAndMicrophone,
      label: "Disable Webcams and Microphones"
    }
  ), !metadata.disableWebcamAndMicrophone && /* @__PURE__ */ React.createElement(
    Settings.Checkbox,
    {
      setChecked: updateRequireAdminForWebcamAndMicrophone,
      checked: !!metadata.requireAdminForWebcamAndMicrophone,
      label: "Only allow admins to use their webcam and microphone"
    }
  )));
};
const BanSection = () => {
  return /* @__PURE__ */ React.createElement(Settings.Section, { name: "Bans", icon: "ban" }, /* @__PURE__ */ React.createElement(Settings.Container, { name: "Bans" }, /* @__PURE__ */ React.createElement(Settings.Section, { name: "User Bans", icon: "ban" }, /* @__PURE__ */ React.createElement(BannedMembers, null)), /* @__PURE__ */ React.createElement(Settings.Section, { name: "IP Bans", icon: "ban" }, /* @__PURE__ */ React.createElement(BannedIps, null))));
};
const SecuritySection = withDialogs(({ confirm }) => {
  const roomId = useRoomId();
  const [clearChat] = useMutation(CLEAR_CHAT);
  const [destroyRoom] = useMutation(DESTROY_ROOM);
  return /* @__PURE__ */ React.createElement(Settings.Section, { name: "Security", icon: "exclamation circle" }, /* @__PURE__ */ React.createElement(Settings.Container, { name: "Security" }, /* @__PURE__ */ React.createElement(PermissionsSection, null), /* @__PURE__ */ React.createElement(BanSection, null), /* @__PURE__ */ React.createElement(
    Settings.Button,
    {
      name: "Clear chat history",
      icon: "trash",
      onClick: () => __async(void 0, null, function* () {
        if (confirm && (yield confirm(
          "This will permanently delete all messages in the room. Are you sure?"
        ))) {
          yield clearChat({ variables: { roomId } });
        }
      })
    }
  ), /* @__PURE__ */ React.createElement(
    Settings.Button,
    {
      color: "danger",
      name: "Destroy room",
      icon: "remove",
      onClick: () => __async(void 0, null, function* () {
        if (confirm && (yield confirm(
          "This will permanently destroy the room and all it's data. Are you sure?"
        ))) {
          yield destroyRoom({ variables: { roomId } });
        }
      })
    }
  )));
});
const AppearanceSection = withDialogs(({ alert }) => {
  const { setValue, removeValue } = useSpacesActions();
  const spacesState = useSpacesStateCached() || {};
  const updateVal = (resetVal, f, key) => __async(void 0, null, function* () {
    if (resetVal) {
      removeValue(key);
      return true;
    }
    if (!f)
      return true;
    const data = new FormData();
    data.append("file", f);
    const response = yield fetch("https://img.kosmi.io/", {
      method: "POST",
      body: data
    });
    const response_json = yield response.json();
    const avatarFilename = response_json.filename;
    try {
      yield setValue(key, "https://img.kosmi.io/" + avatarFilename);
      return true;
    } catch (e) {
      alert && alert((e == null ? void 0 : e.message) || "Unexpected error");
      return false;
    }
  });
  const updateTvPlaceholder = () => {
    return updateVal(resetTVPlaceholder, tvPlaceholderFile, "tvPlaceholder");
  };
  const updateBackground = () => {
    return updateVal(resetBackground, backgroundFile, "background");
  };
  const updateSofa = () => {
    return updateVal(resetSofa, sofaFile, "sofa");
  };
  const [tvPlaceholderFile, setTVPlaceholderFile] = React.useState(
    null
  );
  const [sofaFile, setSofaFile] = React.useState(null);
  const [resetTVPlaceholder, setResetTVPlaceholder] = React.useState(false);
  const ResetButton = ({ onClick }) => /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "blue",
      style: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 12,
        marginBottom: 12
      },
      onClick
    },
    "Reset to default"
  );
  const [resetBackground, setResetBackground] = React.useState(false);
  const [resetSofa, setResetSofa] = React.useState(false);
  const [backgroundFile, setBackgroundFile] = React.useState(
    null
  );
  return /* @__PURE__ */ React.createElement(Settings.Section, { name: "Appearance", icon: "paint brush" }, /* @__PURE__ */ React.createElement(Settings.Container, { name: "Appearance" }, /* @__PURE__ */ React.createElement(Settings.Section, { name: "Theme", icon: "paint brush" }, /* @__PURE__ */ React.createElement(Themes, null)), /* @__PURE__ */ React.createElement(Settings.Section, { name: "Customize", icon: "object group" }, /* @__PURE__ */ React.createElement(Settings.Container, { name: "Appearance" }, /* @__PURE__ */ React.createElement(
    Settings.Section,
    {
      name: "TV Placeholder",
      icon: "tv",
      onSave: updateTvPlaceholder,
      onBack: () => setTVPlaceholderFile(null)
    },
    /* @__PURE__ */ React.createElement(
      ImageUploader,
      {
        src: !resetTVPlaceholder && spacesState.tvPlaceholder || /* @__PURE__ */ React.createElement(DefaultTVPlaceholder, null),
        onFile: (f) => {
          setResetTVPlaceholder(false);
          setTVPlaceholderFile(f);
        },
        forceDefault: resetTVPlaceholder
      }
    ),
    !tvPlaceholderFile && spacesState.tvPlaceholder && /* @__PURE__ */ React.createElement(ResetButton, { onClick: () => setResetTVPlaceholder(true) })
  ), /* @__PURE__ */ React.createElement(
    Settings.Section,
    {
      name: "Room background",
      icon: "image outline",
      onSave: updateBackground
    },
    /* @__PURE__ */ React.createElement(
      ImageUploader,
      {
        src: !resetBackground && spacesState.background || /* @__PURE__ */ React.createElement(
          "div",
          {
            style: {
              backgroundImage: 'url("' + backgroundSvg + '")',
              backgroundColor: "var(--primary)",
              backgroundBlendMode: "hard-light",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: 355,
              aspectRatio: "16 / 9"
            }
          }
        ),
        onFile: (f) => {
          setResetBackground(false);
          setBackgroundFile(f);
        },
        forceDefault: resetBackground
      }
    ),
    !backgroundFile && spacesState.background && /* @__PURE__ */ React.createElement(ResetButton, { onClick: () => setResetBackground(true) })
  ), /* @__PURE__ */ React.createElement(
    Settings.Section,
    {
      name: "Sofa",
      icon: "window minimize",
      onSave: updateSofa
    },
    /* @__PURE__ */ React.createElement(
      ImageUploader,
      {
        src: !resetSofa && spacesState.sofa || /* @__PURE__ */ React.createElement(SofaSvg, null),
        onFile: (f) => {
          setResetSofa(false);
          setSofaFile(f);
        },
        forceDefault: resetSofa
      }
    ),
    !backgroundFile && spacesState.sofa && /* @__PURE__ */ React.createElement(ResetButton, { onClick: () => setResetSofa(true) }),
    /* @__PURE__ */ React.createElement(
      Settings.Checkbox,
      {
        setChecked: (val) => __async(void 0, null, function* () {
          setValue("enableSofa", !val);
        }),
        checked: spacesState.enableSofa === false,
        label: "Hide Sofa"
      }
    )
  ), /* @__PURE__ */ React.createElement(Settings.Section, { name: "Color Scheme", icon: "eye dropper" }, /* @__PURE__ */ React.createElement(ColorSchemeSelector, null))))));
});
export default withDialogs(({ confirm, alert }) => {
  var _a, _b;
  const roomId = useRoomId();
  const { data } = useQuery(GET_METADATA, { variables: { roomId } });
  const metadata = ((_b = (_a = data == null ? void 0 : data.room) == null ? void 0 : _a.state) == null ? void 0 : _b.metadata) || {};
  const [updateMetadata] = useMutation(UPDATE_METADATA);
  const [roomName, setRoomName] = useState(metadata.roomName || "");
  const [description, setDescription] = useState(metadata.description || "");
  const [avatarFile, setAvatarFile] = React.useState(null);
  const currentUser = currentUserHook();
  const setPublic = (publicRoom) => {
    updateMetadata({ variables: { roomId, public: publicRoom } });
  };
  const updateAvatar = () => __async(void 0, null, function* () {
    try {
      if (!avatarFile)
        return true;
      const data2 = new FormData();
      data2.append("file", avatarFile);
      const response = yield fetch("https://img.kosmi.io/", {
        method: "POST",
        body: data2
      });
      const response_json = yield response.json();
      const avatarFilename = response_json.filename;
      yield updateMetadata({
        variables: {
          roomId,
          avatarFilename
        }
      });
      return true;
    } catch (e) {
      alert && alert((e == null ? void 0 : e.message) || "Unexpected error");
      return false;
    }
  });
  const updateName = () => __async(void 0, null, function* () {
    if (roomName) {
      updateMetadata({
        variables: {
          roomId,
          roomName
        }
      });
      return true;
    }
    return false;
  });
  useEffect(() => {
    setRoomName(metadata.roomName || "");
    setDescription(metadata.description || "");
    setAvatarFile(null);
  }, [JSON.stringify(metadata)]);
  return /* @__PURE__ */ React.createElement(Settings.Container, { name: "Room settings", dark: true }, /* @__PURE__ */ React.createElement(
    Settings.Section,
    {
      name: "Avatar",
      avatarUrl: metadata.avatarUrl || void 0,
      onSave: updateAvatar,
      icon: "user circle"
    },
    /* @__PURE__ */ React.createElement(
      ImageUploader,
      {
        circular: true,
        src: metadata.avatarUrl || "",
        onFile: (f) => setAvatarFile(f)
      }
    )
  ), /* @__PURE__ */ React.createElement(
    Settings.Section,
    {
      name: "Room name",
      onSave: updateName,
      value: metadata.roomName || void 0,
      icon: "pencil",
      onBack: () => {
        setRoomName(metadata.roomName || "");
      }
    },
    /* @__PURE__ */ React.createElement(
      Settings.Input,
      {
        setValue: setRoomName,
        name: "Display name",
        value: roomName
      }
    )
  ), metadata.public && /* @__PURE__ */ React.createElement(
    Settings.Section,
    {
      name: "Room Description",
      icon: "pen square",
      onSave: () => __async(void 0, null, function* () {
        yield updateMetadata({
          variables: {
            roomId,
            description
          }
        });
        return true;
      }),
      onBack: () => {
        setDescription(metadata.description || "");
      },
      value: metadata.description
    },
    /* @__PURE__ */ React.createElement(
      Settings.TextArea,
      {
        name: "Room description",
        value: (description == null ? metadata.description : description) || "",
        setValue: setDescription
      }
    )
  ), /* @__PURE__ */ React.createElement(AppearanceSection, null), /* @__PURE__ */ React.createElement(
    Settings.Section,
    {
      name: "Visibility",
      value: metadata.public ? "Public" : "Private",
      icon: "eye"
    },
    /* @__PURE__ */ React.createElement(Settings.InputGroup, null, /* @__PURE__ */ React.createElement(
      Settings.Checkbox,
      {
        setChecked: (val) => __async(void 0, null, function* () {
          if (val && !(currentUser == null ? void 0 : currentUser.emailConfirmed) && alert) {
            alert("Please confirm your email");
            return;
          }
          if (val && confirm && (yield confirm(
            "Please be aware that any explicit content(18+) is not allowed in public rooms. A violation of this rule may result in a permanent ban."
          ))) {
            setPublic(val);
          } else {
            setPublic(val);
          }
        }),
        checked: !!metadata.public,
        label: "Show room in the public lobby"
      }
    ))
  ), /* @__PURE__ */ React.createElement(SecuritySection, null));
});
