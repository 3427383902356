"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.AVPermissionsWarningModal = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useTracking = _interopRequireDefault(require("./hooks/useTracking"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const styles = {
  permissionsWarning: {
    "AVPermissionsWarningModal__styles.permissionsWarning": "AVPermissionsWarningModal__styles.permissionsWarning",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    $$css: true
  },
  warning: {
    "AVPermissionsWarningModal__styles.warning": "AVPermissionsWarningModal__styles.warning",
    color: "x16tr98c",
    $$css: true
  }
};
const AVPermissionsWarningModal = ({
  onClose,
  open
}) => {
  const track = (0, _useTracking.default)();
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open,
    onOpen: () => track("UI", "AVPermissionsModalOpened", "AV permission modal opened"),
    onClose: () => {
      onClose();
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.permissionsWarning
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    size: "huge",
    name: "warning",
    className: styles.warning
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.h2, null, "Camera/microphone blocked!"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Kosmi needs access to your ", /* @__PURE__ */_react.default.createElement("b", null, "camera"), " and ", /* @__PURE__ */_react.default.createElement("b", null, "micophone"), " so that other participants can see and hear you."))));
};
exports.AVPermissionsWarningModal = AVPermissionsWarningModal;
var _default = exports.default = AVPermissionsWarningModal;