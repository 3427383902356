"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _gql = require("kosmi-sdk/gql");
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireDefault(require("react"));
var _reactRouterDom = require("react-router-dom");
var _helpers = require("kosmi-sdk/helpers");
var _widgets = require("kosmi-sdk/widgets");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const GET_ROOM = (0, _gql.gql)(`
  query PublicRoom($joinCode: String!) {
    publicRoom(joinCode: $joinCode) {
      appInfo
      appName
      avatarUrl
      countryCode
      description
      joinCode
      memberCount
      roomName
      autoAcceptJoins
    }
  }
`);
const styles = {
  loggedIn: {
    "JoinRoomModal__styles.loggedIn": "JoinRoomModal__styles.loggedIn",
    cursor: "x1ypdohk",
    $$css: true
  },
  createServerButton: {
    "JoinRoomModal__styles.createServerButton": "JoinRoomModal__styles.createServerButton",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    backgroundColor: "x1vaw2wd",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    width: "xygnafs",
    minHeight: "x1ba4aug",
    textAlign: "x2b8uid",
    lineHeight: "x1vy86p0",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    cursor: "x1ypdohk",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  roomType: {
    "JoinRoomModal__styles.roomType": "JoinRoomModal__styles.roomType",
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    padding: "xc7ga6q",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  roomProfilePicture: {
    "JoinRoomModal__styles.roomProfilePicture": "JoinRoomModal__styles.roomProfilePicture",
    width: "x1exxlbk",
    height: "xpyat2d",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  roomName: {
    "JoinRoomModal__styles.roomName": "JoinRoomModal__styles.roomName",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    textAlign: "x2b8uid",
    fontWeight: "x117nqv4",
    width: "x8yj6t5",
    maxWidth: "x1j9u4d2",
    userSelect: "x1hx0egp",
    $$css: true
  },
  roomDescription: {
    "JoinRoomModal__styles.roomDescription": "JoinRoomModal__styles.roomDescription",
    textAlign: "x2b8uid",
    margin: "xhifcr4",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    userSelect: "x1hx0egp",
    $$css: true
  },
  joinFormWrapper: {
    "JoinRoomModal__styles.joinFormWrapper": "JoinRoomModal__styles.joinFormWrapper",
    textAlign: "x2b8uid",
    $$css: true
  },
  modalHeader: {
    "JoinRoomModal__styles.modalHeader": "JoinRoomModal__styles.modalHeader",
    display: "x78zum5",
    $$css: true
  }
};
const JoinRoomForm = ({
  joinCode,
  onClose
}) => {
  if (!joinCode) return null;
  const history = (0, _reactRouterDom.useHistory)();
  const {
    data,
    loading
  } = (0, _gql.useQuery)(GET_ROOM, {
    variables: {
      joinCode
    }
  });
  const room = (data == null ? void 0 : data.publicRoom) || {};
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.joinFormWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomName
  }, room.countryCode && /* @__PURE__ */_react.default.createElement(_widgets.Flag, {
    name: room.countryCode && room.countryCode.toLowerCase()
  }), room.roomName || "..."), /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    src: room.avatarUrl || "",
    className: styles.roomProfilePicture,
    circular: true,
    loading: "lazy",
    width: 100,
    height: 100
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomDescription
  }, loading ? "..." : room.description), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "blue",
    style: {
      margin: "auto"
    },
    onClick: () => __async(void 0, null, function* () {
      history.push("/room/" + room.joinCode);
      onClose();
    })
  }, "Join Room"));
};
const JoinRoomModal = ({
  onClose,
  open,
  joinCode
}) => {
  const track = (0, _helpers.useTracking)();
  const history = (0, _reactRouterDom.useHistory)();
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open,
    onOpen: () => track("UI", "JoinPublicRoomModalOpened", "Join public room modal opened"),
    onClose: () => {
      onClose();
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, {
    style: styles.modalHeader
  }, "Join Room"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(JoinRoomForm, {
    onClose,
    joinCode,
    styles,
    history
  })));
};
var _default = exports.default = JoinRoomModal;