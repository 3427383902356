"use strict";
import { withStyleHook } from "kosmi-sdk/styles";
import React from "react";
import { LoginModalInner } from "../Widgets/LoginModal";
import style from "./style.css";
import { useHistory, useParams } from "react-router-dom";
import { gql, useQuery } from "kosmi-sdk/gql";
import { currentUserHook } from "kosmi-sdk/helpers";
import { LoadingScreen } from "../Widgets";
const GET_USER_BY_INVITE_CODE = gql(`
  query GetUserByInviteCode($inviteCode: String!) {
    userByInviteCode(inviteCode: $inviteCode) {
      id
      username
      displayName
      avatarUrl
    }
  }
`);
export default () => {
  var _a;
  const styles = withStyleHook(style);
  const history = useHistory();
  const params = useParams();
  const currentUser = currentUserHook();
  const inviteCode = params == null ? void 0 : params.inviteCode;
  const { data } = useQuery(GET_USER_BY_INVITE_CODE, {
    variables: { inviteCode },
    pollInterval: 5e3
  });
  const user = data == null ? void 0 : data.userByInviteCode;
  if (!user)
    return /* @__PURE__ */ React.createElement(LoadingScreen, null);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: (currentUser.isAnonymous ? [styles.signupWrapper] : [styles.signupWrapper, styles.disabled]).join(" ")
    },
    /* @__PURE__ */ React.createElement("div", { className: styles.inviter }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("img", { src: (_a = user.avatarUrl) == null ? void 0 : _a.replaceAll("=300", "=50") }), /* @__PURE__ */ React.createElement("div", null, user.displayName || user.username)), /* @__PURE__ */ React.createElement("div", null, "has invited you to Join Kosmi!"), /* @__PURE__ */ React.createElement(
      "a",
      {
        target: "_blank",
        rel: "noopener noreferrer",
        href: "https://www.youtube.com/watch?v=UDYhckhG104"
      },
      "What's that?"
    )),
    /* @__PURE__ */ React.createElement(
      LoginModalInner,
      {
        signup: true,
        inviteCode,
        onLogin: () => {
          history.replace("/");
        }
      }
    )
  );
};
