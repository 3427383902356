"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextArea = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _lib = require("react-debounce-input/lib");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  textarea: {
    "TextArea__styles.textarea": "TextArea__styles.textarea",
    width: "xh8yej3",
    margin: "x1ghz6dp",
    appearance: "xjyslct",
    outline: "x1a2a7pz",
    padding: "x17smxli",
    backgroundColor: "x128cn8a",
    borderWidth: "xmkeg23",
    borderStyle: "x1y0btm7",
    borderColor: "xzgz5g3",
    color: "x11jfisy",
    borderRadius: "x1aaq583",
    boxShadow: "x9gmhs3",
    resize: "x288g5",
    verticalAlign: "x16dsc37",
    ":focus_borderColor": "x1eg2tmu",
    $$css: true
  }
};
const TextAreaElement = (0, _react.forwardRef)((props, ref) => /* @__PURE__ */_react.default.createElement("textarea", __spreadProps(__spreadValues({}, props), {
  ref
})));
const DebounceTextArea = (debounce, minLength) => (0, _react.forwardRef)((props, ref) => /* @__PURE__ */_react.default.createElement(_lib.DebounceInput, __spreadValues({
  element: "textarea",
  minLength,
  debounceTimeout: debounce,
  ref
}, props)));
const TextArea = exports.TextArea = (0, _react.memo)((0, _react.forwardRef)(({
  onChange,
  value,
  rows,
  maxLength,
  debounce,
  debounceMinLength,
  placeholder,
  disabled,
  className
}, ref) => {
  const {
    className: styleXClassName
  } = stylex.props(styles.textarea, typeof className !== "string" && className);
  const Tag = (0, _react.useMemo)(() => debounce ? DebounceTextArea(debounce, debounceMinLength || 0) : TextAreaElement, [debounce]);
  return /* @__PURE__ */_react.default.createElement(Tag, {
    className: styleXClassName + (typeof className === "string" ? ` ${className}` : ""),
    placeholder,
    maxLength,
    disabled,
    rows,
    onChange,
    value,
    ref
  });
}));