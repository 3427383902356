"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useRef, useState, useEffect, useContext, memo } from "react";
import { AnimatedModal as Modal, FadeInTransition } from "kosmi-sdk/widgets";
import { useTracking, withDialogs } from "kosmi-sdk/helpers";
import { withStyleHook } from "kosmi-sdk/styles";
import PlayerControls from "./Controls";
import SubtitleModal from "./subtitle_modal";
import SyncedVideo from "./SyncedVideo";
import VideoLoadMenu from "./Menu";
import style from "./ui.css";
import AppContext from "../../../../AppContext";
import MediaPlayerContext from "../../MediaPlayerContext";
import { useMediaPlayerStateCached } from "../../hooks/useMediaPlayerData";
import { useMediaPlayerActions } from "./useMediaPlayerState";
import { useMembers } from "../../withMembers";
import { getPosition } from "./helpers";
import {
  isScreenShare,
  getVideoTitle,
  isVbrowser
} from "./Video/helpers";
import { useIsAdmin } from "../../withIsAdmin";
import { useIsMobile } from "../../../helpers/withMobileDetection";
import useDoubleClick from "use-double-click";
const LoadVideoModal = memo(
  ({ onClose, open }) => {
    const track = useTracking();
    const styles = withStyleHook(style);
    const [modalTitle, setModalTitle] = useState("Select Media");
    return /* @__PURE__ */ React.createElement(
      Modal,
      {
        open: typeof open === "boolean" ? open : void 0,
        className: styles.loadVideoModal,
        large: true,
        onOpen: () => track("UI", "LoadVideoModalOpened", "Load video Modal opened"),
        closeIcon: "close",
        onClose
      },
      /* @__PURE__ */ React.createElement(Modal.Header, null, modalTitle.startsWith("https://") ? /* @__PURE__ */ React.createElement("img", { className: styles.modalIcon, src: modalTitle }) : modalTitle),
      /* @__PURE__ */ React.createElement(
        Modal.Content,
        {
          style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: 0
          }
        },
        /* @__PURE__ */ React.createElement(
          VideoLoadMenu,
          {
            onClose: onClose || (() => {
            }),
            setModalTitle
          }
        )
      )
    );
  },
  (props, nextProps) => {
    return props.open === nextProps.open;
  }
);
export default memo(
  withDialogs(
    ({
      visible,
      onFullscreen,
      isCompact,
      forceFullScreen,
      onPlayer
    }) => {
      var _a, _b, _c, _d, _e;
      const isAdmin = useIsAdmin();
      const { isMobile, isLandscape } = useIsMobile();
      const styles = withStyleHook(style);
      const mediaplayerContext = useContext(MediaPlayerContext);
      const { mediaPlayerState, subtitles } = useMediaPlayerStateCached();
      const appContext = useContext(AppContext);
      const {
        removeSubtitles,
        setSubtitles,
        setPaused,
        setPosition,
        stopVideo
      } = useMediaPlayerActions();
      const members = useMembers();
      const mediaPlayerStateRef = useRef();
      const membersRef = useRef();
      const prevMediaPlayerState = mediaPlayerStateRef.current;
      const { url } = mediaPlayerState;
      const prevMembers = membersRef.current || [];
      const [player, setPlayer] = useState(null);
      const [hovering, setHovering] = useState(false);
      const hoveringTimeoutRef = useRef(
        null
      );
      const doubleClickTimeoutRef = useRef(
        null
      );
      const hover = (event = void 0) => {
        var _a2;
        if (event == null ? void 0 : event.target) {
          if (((_a2 = event == null ? void 0 : event.target) == null ? void 0 : _a2.className.split(" ").indexOf("autoPlayBuster")) !== -1)
            return;
        }
        setHovering(true);
        if (hoveringTimeoutRef.current) {
          clearTimeout(hoveringTimeoutRef.current);
        }
        if (nativeControls) {
          hoveringTimeoutRef.current = null;
        } else {
          hoveringTimeoutRef.current = setTimeout(() => {
            setHovering(false);
          }, 5e3);
        }
      };
      const [enableCC, setEnableCC] = useState(false);
      const [nativeControls, setNativeControls] = useState(false);
      const [localPlayerState, setLocalPlayerState] = useState(
        {}
      );
      const [modal, setModal] = useState(null);
      const [showSubtitleModal, setShowSubtitleModal] = useState(false);
      const wrapperRef = useRef(null);
      useDoubleClick({
        onSingleClick: () => {
        },
        onDoubleClick: () => {
          setNativeControls(true);
          if (doubleClickTimeoutRef.current) {
            clearTimeout(doubleClickTimeoutRef.current);
          }
          doubleClickTimeoutRef.current = setTimeout(() => {
            setNativeControls(false);
          }, 5e3);
        },
        ref: wrapperRef,
        latency: 250
      });
      useEffect(() => {
        if (!onPlayer)
          return;
        onPlayer({
          loadMedia: () => {
            setModal("pick");
          }
        });
      }, [onPlayer]);
      useEffect(() => {
        if ((prevMediaPlayerState == null ? void 0 : prevMediaPlayerState.url) !== (mediaPlayerState == null ? void 0 : mediaPlayerState.url)) {
          hover();
        }
      }, [mediaPlayerState == null ? void 0 : mediaPlayerState.url]);
      useEffect(() => {
        if (!members || !prevMembers)
          return;
        if (prevMembers.length < 3 && members.length >= 3) {
          mediaplayerContext.setRelayStream(true);
        }
      }, [mediaPlayerState == null ? void 0 : mediaPlayerState.url, members == null ? void 0 : members.length]);
      const pause = () => __async(void 0, null, function* () {
        if (!((mediaPlayerState == null ? void 0 : mediaPlayerState.live) && !mediaplayerContext.localUrl)) {
          yield setPaused(true);
          if (localPlayerState.duration != null) {
            const position = getPosition(
              !!mediaPlayerState.paused,
              mediaPlayerState.position || 0,
              localPlayerState.duration,
              mediaPlayerState.unixTimePositionSent || 0
            );
            setPosition(position);
          }
        }
      });
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
        LoadVideoModal,
        {
          open: !!modal,
          onClose: () => {
            setModal(null);
          }
        }
      ), /* @__PURE__ */ React.createElement(
        "div",
        {
          className: styles.videoplayerWrapper,
          style: {
            display: visible === false ? "none" : void 0,
            cursor: hovering ? "default" : "none"
          },
          ref: wrapperRef
        },
        /* @__PURE__ */ React.createElement("div", { className: styles.player }, /* @__PURE__ */ React.createElement(
          SubtitleModal,
          {
            open: showSubtitleModal,
            subtitles,
            onRemoveSubtitles: () => removeSubtitles(),
            onSubtitles: (subtitles2) => {
              setSubtitles(subtitles2.filename, subtitles2.cues);
              setShowSubtitleModal(false);
            },
            onClose: () => setShowSubtitleModal(false)
          }
        ), /* @__PURE__ */ React.createElement(
          "div",
          {
            className: styles.videoWrapper,
            style: { cursor: hovering ? "default" : "none" },
            onMouseMove: hover,
            onMouseOver: hover,
            onMouseLeave: () => {
              if (!showSubtitleModal && !modal) {
                if (hoveringTimeoutRef.current) {
                  clearTimeout(hoveringTimeoutRef.current);
                }
                setHovering(false);
              }
            }
          },
          ((mediaPlayerState == null ? void 0 : mediaPlayerState.paused) || hovering || isVbrowser(url || "")) && /* @__PURE__ */ React.createElement(
            FadeInTransition,
            {
              style: {
                position: "absolute",
                pointerEvents: "none",
                zIndex: 2
              }
            },
            /* @__PURE__ */ React.createElement(
              PlayerControls,
              {
                key: mediaPlayerState.url,
                isCompact,
                isSmall: isMobile && !isLandscape,
                paused: !!(mediaPlayerState == null ? void 0 : mediaPlayerState.paused),
                duration: localPlayerState.duration,
                muted: !!localPlayerState.muted,
                volume: localPlayerState.volume,
                isFullscreen: forceFullScreen,
                freeUpRightSide: forceFullScreen && (!appContext.showNav || isMobile),
                timestamp: getPosition(
                  !!mediaPlayerState.paused,
                  mediaPlayerState.position || 0,
                  localPlayerState.duration || 0,
                  mediaPlayerState.unixTimePositionSent || 0
                ),
                isLive: mediaPlayerState.live !== false && !mediaplayerContext.localUrl,
                videoInfo: /* @__PURE__ */ React.createElement("div", { className: styles.videoInfo }, getVideoTitle(url || "")),
                url: mediaPlayerState.url,
                nativeControls,
                controls: __spreadValues({
                  mute: (_a = player == null ? void 0 : player.player) == null ? void 0 : _a.mute,
                  unmute: (_b = player == null ? void 0 : player.player) == null ? void 0 : _b.unmute,
                  setVolume: isVbrowser(url || "") ? null : (_c = player == null ? void 0 : player.player) == null ? void 0 : _c.setVolume,
                  toggleNativeControls: () => {
                    if (doubleClickTimeoutRef.current) {
                      clearTimeout(doubleClickTimeoutRef.current);
                    }
                    setNativeControls(!nativeControls);
                  }
                }, isAdmin ? {
                  play: () => setPaused(false),
                  mute: (_d = player == null ? void 0 : player.player) == null ? void 0 : _d.mute,
                  unmute: (_e = player == null ? void 0 : player.player) == null ? void 0 : _e.unmute,
                  pause: !((mediaPlayerState == null ? void 0 : mediaPlayerState.live) && !mediaplayerContext.localUrl) ? pause : null,
                  stop: () => stopVideo(),
                  loadMedia: () => {
                    setModal("pick");
                  },
                  showCcMenu: (!(mediaPlayerState == null ? void 0 : mediaPlayerState.live) || !!mediaplayerContext.localUrl) && enableCC ? () => {
                    setShowSubtitleModal(true);
                  } : null,
                  seekTo: !mediaPlayerState.live ? (val) => setPosition(val) : null,
                  fullscreen: onFullscreen
                } : {})
              }
            )
          ),
          /* @__PURE__ */ React.createElement(
            SyncedVideo,
            {
              onUpdate: setLocalPlayerState,
              controls: nativeControls,
              onReady: (player2) => {
                setEnableCC(
                  !isScreenShare((mediaPlayerState == null ? void 0 : mediaPlayerState.url) || "") && !mediaPlayerState.live && (player2 == null ? void 0 : player2.video) instanceof Element
                );
                setPlayer(player2);
              }
            }
          )
        ))
      ));
    }
  )
);
export { LoadVideoModal, VideoLoadMenu };
