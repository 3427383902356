"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { currentUserHook } from "kosmi-sdk/helpers";
import { Loader } from "kosmi-sdk/uikit";
import React, { memo, useEffect } from "react";
import RoomContext from "./RoomContext";
export default memo(
  ({
    userId,
    className,
    style
  }) => {
    const { webcamStreams } = React.useContext(RoomContext);
    const currentUser = currentUserHook();
    const videoRef = React.useRef(null);
    const classNames = currentUser.id === userId ? [className, "mirror"] : [className];
    const stream = webcamStreams && webcamStreams[userId];
    useEffect(() => {
      if (!stream)
        return;
      if (stream && stream.id === "LOADING") {
        return;
      }
      const video = videoRef.current;
      if (video) {
        video.srcObject = stream;
        video.onloadedmetadata = () => {
          video.muted = true;
          try {
            video.play();
          } catch (e) {
            console.warn(e);
          }
        };
      }
    }, [stream == null ? void 0 : stream.id]);
    if (stream && stream.id === "LOADING") {
      return /* @__PURE__ */ React.createElement(Loader, { active: true });
    }
    return stream ? /* @__PURE__ */ React.createElement(
      "video",
      {
        playsInline: true,
        disableRemotePlayback: true,
        className: classNames.filter((c) => c).join(" "),
        style: __spreadProps(__spreadValues({}, style), { transform: "rotateY(180deg)" }),
        ref: videoRef,
        muted: true
      }
    ) : null;
  }
);
