"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchInput = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Input = require("./Input");
var _Image = require("./Image");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
const styles = {
  searchDropdown: {
    "SearchInput__styles.searchDropdown": "SearchInput__styles.searchDropdown",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    height: "x5yr21d",
    maxHeight: "x9ybwvh",
    $$css: true
  },
  results: {
    "SearchInput__styles.results": "SearchInput__styles.results",
    height: "xqtp20y",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    flexDirection: "xdt5ytf",
    overflowY: "x1odjw0f",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    marginTop: "x1anpbxc",
    marginBottom: "xyorhqc",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    width: "xh8yej3",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "x9r1u3d",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    backgroundColor: "x128cn8a",
    $$css: true
  },
  result: {
    "SearchInput__styles.result": "SearchInput__styles.result",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    alignItems: "x1cy8zhl",
    justifyContent: "x1nhvcw1",
    display: "x78zum5",
    height: "x1vqgdyp",
    ":hover_backgroundColor": "x1imby3o",
    ":hover_cursor": "x1277o0a",
    $$css: true
  },
  selectedResult: {
    "SearchInput__styles.selectedResult": "SearchInput__styles.selectedResult",
    backgroundColor: "xivp17k",
    ":hover_backgroundColor": "x836mop",
    $$css: true
  },
  resultImg: {
    "SearchInput__styles.resultImg": "SearchInput__styles.resultImg",
    maxHeight: "x19phtnz",
    marginTop: "xr1yuqi",
    marginBottom: "x4ii5y1",
    marginLeft: "x16ydxro",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xyo1k6t",
    $$css: true
  },
  resultText: {
    "SearchInput__styles.resultText": "SearchInput__styles.resultText",
    display: "x78zum5",
    $$css: true
  }
};
const SearchInput = exports.SearchInput = (0, _react.memo)(({
  value,
  className,
  autoComplete,
  onSelect,
  placeholder,
  readOnly,
  action,
  onChange,
  id,
  type,
  fluid,
  autoFocus,
  disabled,
  min,
  loading,
  max,
  required,
  label,
  name,
  maxLength,
  onKeyPress,
  onKeyDown,
  results
}) => {
  const [selected, setSelected] = (0, _react.useState)(null);
  (0, _react.useEffect)(() => {
    setSelected(null);
  }, [JSON.stringify(results)]);
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.searchDropdown
  }, /* @__PURE__ */_react.default.createElement(_Input.Input, __spreadValues({}, {
    value,
    className,
    autoComplete: autoComplete || "off",
    placeholder,
    readOnly,
    action,
    onChange,
    loading,
    id,
    type,
    fluid,
    autoFocus,
    disabled,
    min,
    max,
    required,
    icon: "search",
    label,
    name,
    maxLength,
    onKeyPress,
    onKeyDown
  })), /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.results
  }, results.map((u, i) => {
    return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      key: u.key,
      style: [styles.result, i === selected && styles.selectedResult],
      onClick: () => {
        setSelected(i);
        onSelect(u.key);
      }
    }, u.image && /* @__PURE__ */_react.default.createElement(_Image.Image, {
      className: styles.resultImg,
      circular: true,
      src: u.image
    }), /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.resultText
    }, u.text));
  })));
});