"use strict";
import React, { useContext, useState } from "react";
import RoomContext from "../../RoomContext";
import { useMember } from "../../hooks/useMembersData";
import { WebRTCUserVideoTrack } from "kosmi-sdk/webrtc";
import { withStyleHook } from "../../../../sdk/styles";
import style from "./style.css";
const WebcamItem = ({ memberId }) => {
  const styles = withStyleHook(style);
  const member = useMember(memberId);
  return /* @__PURE__ */ React.createElement("div", { className: styles.webcamItem }, /* @__PURE__ */ React.createElement(WebRTCUserVideoTrack, { className: styles.webcamVideo, userId: memberId }), /* @__PURE__ */ React.createElement("div", { className: styles.displayName }, (member == null ? void 0 : member.isAnonymous) ? "Anonymous" : (member == null ? void 0 : member.displayName) || (member == null ? void 0 : member.username)));
};
export default () => {
  const styles = withStyleHook(style);
  const [expanded, setExpanded] = useState(true);
  const { webcamStreams } = useContext(RoomContext);
  const memberIds = Object.keys(webcamStreams || {});
  if (memberIds.length === 0)
    return null;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: styles.webcamBar,
      style: {
        position: "relative",
        flex: 1,
        maxHeight: expanded ? "20%" : "10%",
        minHeight: expanded ? 160 : 80
      },
      onClick: () => {
        setExpanded(!expanded);
      }
    },
    memberIds.map((memberId) => /* @__PURE__ */ React.createElement(WebcamItem, { key: memberId, memberId }))
  );
};
