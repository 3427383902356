"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _Widgets = require("../../components/Widgets");
var _react = _interopRequireWildcard(require("react"));
var _reactRouterDom = require("react-router-dom");
var _AppContext = _interopRequireDefault(require("../../AppContext"));
var _AppMenuDropdown = _interopRequireDefault(require("./components/AppMenuDropdown"));
var _Sidebar = _interopRequireWildcard(require("./components/Sidebar"));
var _RoomError = _interopRequireDefault(require("./components/RoomError"));
var _WebcamBar = _interopRequireDefault(require("./components/WebcamBar"));
var _MessageOverlay = _interopRequireDefault(require("./components/MessageOverlay"));
var _WebcamOverlay = _interopRequireDefault(require("./components/WebcamOverlay"));
var _useIsAdmin = require("./hooks/useIsAdmin");
var _useRoomId = require("./hooks/useRoomId");
var _useApplyRoomColorScheme = _interopRequireDefault(require("./hooks/useApplyRoomColorScheme"));
var _RoomContext = _interopRequireDefault(require("./contexts/RoomContext"));
var _MobileContext = _interopRequireDefault(require("../../layout/Mobile/MobileContext"));
var _TopBar = _interopRequireDefault(require("./components/TopBar"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useCurrentMember = require("./hooks/useCurrentMember");
var _useMobileDetection = require("@/hooks/useMobileDetection");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
const LeaveRoomPrompt = (0, _react.memo)(() => /* @__PURE__ */_react.default.createElement(_reactRouterDom.Prompt, {
  message: (_nextLocation, action) => {
    if (window.skipHistoryPrompt) {
      delete window.skipHistoryPrompt;
      return true;
    }
    return JSON.stringify({
      action,
      message: "Are you sure you want to leave the current room?"
    });
  }
}));
const useShowNav = () => {
  const {
    showNav,
    toggleNav
  } = (0, _react.useContext)(_AppContext.default);
  return (0, _react.useMemo)(() => ({
    showNav,
    toggleNav
  }), [showNav]);
};
const styles = {
  room: {
    "RoomUI__styles.room": "RoomUI__styles.room",
    display: "x78zum5",
    willChange: "x193hhfp",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    maxHeight: "xmz0i5r",
    maxWidth: "x193iq5w",
    width: "xh8yej3",
    height: "x5yr21d",
    position: "x1n2onr6",
    zIndex: "x1vjfegm",
    pointerEvents: "x71s49j",
    backgroundColor: "x128cn8a",
    flexDirection: "x15zctf7",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    backgroundImage: "x1n9hcem",
    minHeight: "x7ywyr2",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_borderTopLeftRadius": "x14uiaeo",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_borderStartStartRadius": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_borderStartEndRadius": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_borderTopRightRadius": "xhq7pu8",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_flexDirection": "x16wjy3l",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_margin": "x8e0yzh",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_marginInline": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_marginInlineStart": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_marginLeft": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_marginInlineEnd": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_marginRight": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_marginBlock": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_marginTop": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_marginBottom": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_backgroundImage": "x1thtqqg",
    $$css: true
  },
  desktopToggleShowChat: {
    "RoomUI__styles.desktopToggleShowChat": "RoomUI__styles.desktopToggleShowChat",
    display: "x1lliihq",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    zIndex: "x1u8a7rm",
    cursor: "x1ypdohk",
    backgroundColor: "xsjsedy",
    height: "xmix8c7",
    transform: "x1rr8tx7",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    position: "x10l6tqk",
    "@media (max-width: 1000px)_display": "xznp9yw",
    $$css: true
  },
  desktopToggleHideChat: {
    "RoomUI__styles.desktopToggleHideChat": "RoomUI__styles.desktopToggleHideChat",
    display: "x1lliihq",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    zIndex: "x1u8a7rm",
    cursor: "x1ypdohk",
    backgroundColor: "xsjsedy",
    height: "xmix8c7",
    transform: "x1rr8tx7",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    position: "x10l6tqk",
    "@media (max-width: 1000px)_display": "xznp9yw",
    $$css: true
  },
  collapsed: {
    "RoomUI__styles.collapsed": "RoomUI__styles.collapsed",
    boxShadow: "x1lwb36s",
    backgroundColor: "x1i349x",
    borderRadius: "x4pepcl",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xntzcp6",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    pointerEvents: "x71s49j",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_borderTopLeftRadius": "xm7scsr",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_borderStartStartRadius": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_borderStartEndRadius": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10)_borderTopRightRadius": "x9u7igj",
    $$css: true
  },
  appWrapper: {
    "RoomUI__styles.appWrapper": "RoomUI__styles.appWrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    position: "x1n2onr6",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    height: "x5yr21d",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _aspectRatio": "x162c55f",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _flex": "xx5x02g",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _flexGrow": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _flexShrink": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _flexBasis": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _width": "xkoi83h",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _maxHeight": "x15netmt",
    $$css: true
  },
  lobbyAppWrapper: {
    "RoomUI__styles.lobbyAppWrapper": "RoomUI__styles.lobbyAppWrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    height: "xt7dq6l",
    minHeight: "x2lwn1j",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _aspectRatio": "x1jfq9fs",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _flex": "x17opwrd",
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _flexGrow": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _flexShrink": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _flexBasis": null,
    "@media (max-width: 1000px) and (max-aspect-ratio: 16/10) _maxHeight": "x1u61znf",
    $$css: true
  },
  appMenuPadding: {
    "RoomUI__styles.appMenuPadding": "RoomUI__styles.appMenuPadding",
    height: "xwvwv9b",
    width: "xh8yej3",
    "@media (max-height: 625px)_display": "xs0nf6o",
    $$css: true
  },
  container: {
    "RoomUI__styles.container": "RoomUI__styles.container",
    height: "x5yr21d",
    width: "xh8yej3",
    $$css: true
  },
  collapsedContainer: {
    "RoomUI__styles.collapsedContainer": "RoomUI__styles.collapsedContainer",
    height: "xyv96y2",
    pointerEvents: "x47corl",
    $$css: true
  },
  collapsedAppWrapper: {
    "RoomUI__styles.collapsedAppWrapper": "RoomUI__styles.collapsedAppWrapper",
    display: "x1s85apg",
    $$css: true
  },
  collapsedMediaPlayer: {
    "RoomUI__styles.collapsedMediaPlayer": "RoomUI__styles.collapsedMediaPlayer",
    height: "xnxb3zj",
    $$css: true
  },
  hiddenMediaPlayer: {
    "RoomUI__styles.hiddenMediaPlayer": "RoomUI__styles.hiddenMediaPlayer",
    height: "xqtp20y",
    $$css: true
  },
  hidden: {
    "RoomUI__styles.hidden": "RoomUI__styles.hidden",
    display: "x1s85apg",
    $$css: true
  }
};
const AppContainer = (0, _react.memo)(({
  showWebcamFallback,
  showNav,
  AppComponent
}) => {
  const roomId = (0, _useRoomId.useRoomId)();
  const {
    isMobile,
    isLandscape
  } = (0, _useMobileDetection.useIsMobile)();
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, showWebcamFallback && /* @__PURE__ */_react.default.createElement(_WebcamOverlay.default, {
    hidden: !showWebcamFallback
  }), (!isMobile && !showNav && roomId !== "lobby" || isMobile && isLandscape) && /* @__PURE__ */_react.default.createElement(_MessageOverlay.default, null), AppComponent ? /* @__PURE__ */_react.default.createElement(AppComponent, null) : /* @__PURE__ */_react.default.createElement(_Widgets.LoadingScreen, {
    key: "loader",
    text: "Loading"
  }));
}, (a, b) => {
  const relevantProps = props => JSON.stringify([props.appName, !!props.AppComponent, props.showWebcamFallback, props.showNav]);
  return relevantProps(a) === relevantProps(b);
});
const InnestUI = (0, _react.memo)(({
  appModule,
  hideSidebarForNonAdmins,
  showWebcamFallback,
  prestartApp,
  AppComponent,
  appName,
  error,
  rejoin,
  expandRoom,
  joining
}) => {
  const isAdmin = (0, _useIsAdmin.useIsAdmin)();
  const {
    showNav,
    toggleNav
  } = useShowNav();
  const showAppMenu = isAdmin && showNav;
  const {
    isMobile,
    isLandscape
  } = (0, _useMobileDetection.useIsMobile)();
  const {
    runningApp,
    isPlayingMedia
  } = (0, _react.useContext)(_RoomContext.default);
  const isSpaces = runningApp === "SPACES";
  const roomId = (0, _useRoomId.useRoomId)();
  const isLobby = roomId === "lobby" || (roomId == null ? void 0 : roomId.endsWith("/lobby"));
  const [_showLobbyChat, setShowLobbyChat] = (0, _react.useState)(false);
  const showLobbyChat = isMobile && !isLandscape && _showLobbyChat && isLobby;
  (0, _useApplyRoomColorScheme.default)();
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, isMobile && !isLandscape && /* @__PURE__ */_react.default.createElement(_TopBar.default, null), !isMobile && !joining && (isAdmin || !hideSidebarForNonAdmins) && !error && /* @__PURE__ */_react.default.createElement(_Sidebar.default, {
    open: showNav
  }), error && /* @__PURE__ */_react.default.createElement(_RoomError.default, {
    error,
    rejoin
  }), joining && !isLobby && /* @__PURE__ */_react.default.createElement(_Widgets.LoadingScreen, {
    text: "Joining"
  }), isLobby && isMobile && !isLandscape && /* @__PURE__ */_react.default.createElement(_uikit.TabView.Menu, {
    items: [/* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      key: "list",
      name: "list"
    }), /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      key: "chat",
      name: "chat"
    })],
    index: showLobbyChat ? 1 : 0,
    onTabChange: i => setShowLobbyChat(i === 0 ? false : true)
  }), !error && !joining && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.appWrapper, isMobile && !isLandscape && !isPlayingMedia && isSpaces && isAdmin && styles.collapsedMediaPlayer, isMobile && !isLandscape && !isPlayingMedia && isSpaces && !isAdmin && styles.hiddenMediaPlayer, isLobby && isMobile && styles.lobbyAppWrapper, !expandRoom && isMobile && styles.collapsedAppWrapper, showLobbyChat && styles.hidden]
  }, appModule && showAppMenu && !(isMobile && !expandRoom) && /* @__PURE__ */_react.default.createElement(_AppMenuDropdown.default, null), appModule && appModule.pushUnderAppMenu && showAppMenu && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.appMenuPadding
  }), showNav ? /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.desktopToggleHideChat,
    name: "chevron right",
    onClick: toggleNav
  }) : /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.desktopToggleShowChat,
    name: "chevron left",
    onClick: toggleNav
  }), !joining && /* @__PURE__ */_react.default.createElement(AppContainer, __spreadValues({}, {
    showWebcamFallback: showWebcamFallback && !(isMobile && !isLandscape),
    showNav,
    prestartApp,
    AppComponent,
    appName
  }))), !error && !joining && (showWebcamFallback || runningApp === "SPACES") && isMobile && !isLandscape && /* @__PURE__ */_react.default.createElement(_WebcamBar.default, null), !error && !joining && isMobile && !isLandscape && expandRoom && /* @__PURE__ */_react.default.createElement(_Sidebar.Chat, {
    hide: isLobby && !showLobbyChat
  }));
});
const InnerUI = (0, _react.memo)(({
  appModule,
  hideSidebarForNonAdmins,
  showWebcamFallback,
  prestartApp,
  AppComponent,
  appName,
  error,
  rejoin,
  joining
}) => {
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const {
    expandRoom
  } = (0, _react.useContext)(_MobileContext.default);
  const size = (0, _react.useMemo)(() => {
    return !expandRoom && isMobile ? [250, 90] : ["100%", "100%"];
  }, [isMobile, expandRoom]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Throwable.Container, {
    className: [styles.container, isMobile && !expandRoom && styles.collapsedContainer]
  }, /* @__PURE__ */_react.default.createElement(_uikit.Throwable, {
    animate: isMobile,
    disabled: !isMobile || expandRoom,
    className: [styles.room, !expandRoom && isMobile && styles.collapsed],
    size
  }, /* @__PURE__ */_react.default.createElement(InnestUI, {
    appModule,
    hideSidebarForNonAdmins,
    showWebcamFallback,
    prestartApp,
    AppComponent,
    appName,
    error,
    rejoin,
    joining,
    expandRoom
  })));
});
const RoomUI = (0, _react.memo)(({
  AppComponent,
  prestartApp,
  hideSidebarForNonAdmins,
  showWebcamFallback,
  roomId,
  appModule,
  appName,
  error,
  rejoin,
  joining
}) => {
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const [cachedCurrentMemberId, setCachedCurrentMemberId] = (0, _react.useState)(null);
  const currentMember = (0, _useCurrentMember.useCurrentMember)();
  const currentMemberId = (currentMember == null ? void 0 : currentMember.id) || cachedCurrentMemberId;
  (0, _react.useEffect)(() => {
    if (currentMemberId && !cachedCurrentMemberId) {
      setCachedCurrentMemberId(currentMemberId);
    }
  }, [currentMemberId]);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, roomId !== "lobby" && !isMobile && /* @__PURE__ */_react.default.createElement(LeaveRoomPrompt, null), /* @__PURE__ */_react.default.createElement(InnerUI, {
    AppComponent,
    appModule,
    appName: appName || "",
    hideSidebarForNonAdmins,
    prestartApp,
    roomId,
    showWebcamFallback,
    error,
    joining: joining || !error && !currentMemberId && roomId !== "lobby",
    rejoin
  }));
});
var _default = exports.default = RoomUI;