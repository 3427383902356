"use strict";
import React, { forwardRef, memo, useState } from "react";
import { currentUserHook } from "../helpers/withCurrentUser";
import LoginModal from "./LoginModal";
export default memo(
  forwardRef(
    ({
      children,
      onClick
    }, ref) => {
      const [open, setOpen] = useState(false);
      const currentUser = currentUserHook();
      const isLoggedIn = !(currentUser == null ? void 0 : currentUser.isAnonymous);
      const openModal = () => setOpen(true);
      if (isLoggedIn) {
        return typeof children === "function" ? children(() => {
        }) : React.cloneElement(children, {
          ref,
          onClick: onClick || children.props.onClick
        });
      }
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(LoginModal, { open, onClose: () => setOpen(false) }), typeof children === "function" ? children(openModal) : React.cloneElement(children, { onClick: openModal }));
    }
  )
);
