"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _reactVirtualized = require("react-virtualized");
var _helpers = require("kosmi-sdk/helpers");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _withMobileDetection = require("../../components/helpers/withMobileDetection");
var _ContextMenu = require("../ContextMenu");
var _Button = require("../Button");
var _Image = require("../Image");
var _Icon = require("../Icon");
var _reactResponsive = require("react-responsive");
var _react2 = require("@use-gesture/react");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const isAndroid = /android/i.test(navigator.userAgent);
const styles = {
  wrapper: {
    "index__styles.wrapper": "index__styles.wrapper",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    height: "x5yr21d",
    width: "xh8yej3",
    $$css: true
  },
  preventScroll: {
    "index__styles.preventScroll": "index__styles.preventScroll",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    pointerEvents: "x47corl",
    $$css: true
  },
  roomItem: {
    "index__styles.roomItem": "index__styles.roomItem",
    display: "x78zum5",
    minHeight: "x1us19tq",
    zIndex: "x1vjfegm",
    pointerEvents: "x71s49j",
    paddingLeft: "x1lqa7cf",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    paddingRight: "xcicffo",
    paddingTop: "x889kno",
    paddingBottom: "x1a8lsjc",
    flexDirection: "x1q0g3np",
    position: "x1n2onr6",
    color: "x11jfisy",
    textDecoration: "x1hl2dhg",
    textDecorationColor: null,
    textDecorationLine: null,
    textDecorationStyle: null,
    textDecorationThickness: null,
    cursor: "x1ypdohk",
    alignItems: "x6s0dn4",
    fontSize: "x1j61zf2",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    "@media (max-width: 1000px)_fontSize": "x1xnrtps",
    "@media (max-width: 1000px)_minHeight": "x15fgtqy",
    "@media (max-width: 1000px)_height": "x1ltrzbh",
    backgroundColor: "x52vrxo xv9cu4j",
    $$css: true
  },
  roomItemWrapper: {
    "index__styles.roomItemWrapper": "index__styles.roomItemWrapper",
    position: "x1n2onr6",
    minHeight: "xseoqlg",
    height: "xwzfr38",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    "@media (max-width: 1000px)_minHeight": "x1azp1ia",
    "@media (max-width: 1000px)_height": "x1kzbrei",
    $$css: true
  },
  drawerMenu: {
    "index__styles.drawerMenu": "index__styles.drawerMenu",
    width: "xygnafs",
    height: "x5yr21d",
    background: "x1dq10d1",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    color: "x11jfisy",
    transform: "xbf94vf",
    position: "x10l6tqk",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x13vifvy",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    paddingTop: "x889kno",
    paddingBottom: "x1a8lsjc",
    $$css: true
  },
  right: {
    "index__styles.right": "index__styles.right",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    paddingLeft: "x163pfp",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  upperRow: {
    "index__styles.upperRow": "index__styles.upperRow",
    display: "x78zum5",
    width: "xh8yej3",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    $$css: true
  },
  lowerRow: {
    "index__styles.lowerRow": "index__styles.lowerRow",
    display: "x78zum5",
    marginTop: "x1anpbxc",
    height: "x1qx5ct2",
    width: "xh8yej3",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    $$css: true
  },
  members: {
    "index__styles.members": "index__styles.members",
    display: "x78zum5",
    marginLeft: "x1tpqehw",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  roomIcon: {
    "index__styles.roomIcon": "index__styles.roomIcon",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: "xyo1k6t",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    position: "x1n2onr6",
    width: "x100vrsf",
    height: "x1vqgdyp",
    minWidth: "x1fns5xo",
    "@media (max-width: 1000px)_width": "xxsk4lk",
    "@media (max-width: 1000px)_height": "xo2sn4m",
    "@media (max-width: 1000px)_minWidth": "x1xep6jk",
    $$css: true
  },
  roomIconImage: {
    "index__styles.roomIconImage": "index__styles.roomIconImage",
    height: "x5yr21d",
    width: "x14atkfc",
    $$css: true
  },
  inActive: {
    "index__styles.inActive": "index__styles.inActive",
    color: "x164e47y",
    $$css: true
  },
  latestMessage: {
    "index__styles.latestMessage": "index__styles.latestMessage",
    display: "x3nfvp2",
    justifyContent: "x1qughib",
    height: "x1qx5ct2",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    margin: "x1k3vv4n",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    color: "x164e47y",
    maxWidth: "x193iq5w",
    width: "xh8yej3",
    $$css: true
  },
  latestMessageText: {
    "index__styles.latestMessageText": "index__styles.latestMessageText",
    display: "xt0psk2",
    maxWidth: "x256u9z",
    width: "xh8yej3",
    height: "x5yr21d",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    whiteSpace: "xuxw1ft",
    paddingRight: "xy13l1i",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    paddingLeft: "x1uhho1l",
    $$css: true
  },
  unread: {
    "index__styles.unread": "index__styles.unread",
    color: "x16cd2qt",
    fontWeight: "x1s688f",
    $$css: true
  },
  optionsDropdownWrapper: {
    "index__styles.optionsDropdownWrapper": "index__styles.optionsDropdownWrapper",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    justifyContent: "x13a6bvl",
    maxWidth: "xazcve0",
    width: "x1exxlbk",
    marginLeft: null,
    marginInlineStart: null,
    marginInlineEnd: null,
    position: "x10l6tqk",
    height: "x10w6t97",
    right: "x1gv0tr7",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x13vifvy",
    bottom: "x1ey2m1c",
    margin: "x1bpp3o7",
    marginInline: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    alignItems: "x6s0dn4",
    $$css: true
  },
  roomOptions: {
    "index__styles.roomOptions": "index__styles.roomOptions",
    minHeight: "x1wxaq2x",
    minWidth: "x900493",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    opacity: "x1us6l5c",
    ":hover_opacity": "x1o7uuvo",
    $$css: true
  },
  odd: {
    "index__styles.odd": "index__styles.odd",
    backgroundColor: "x1hgespx xv9cu4j x11zpyrr",
    $$css: true
  },
  member: {
    "index__styles.member": "index__styles.member",
    width: "xw4jnvo",
    minWidth: "xt4ypqs",
    height: "x1qx5ct2",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xntzcp6",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    marginLeft: "x18wv8x9",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  onlineIcon: {
    "index__styles.onlineIcon": "index__styles.onlineIcon",
    position: "x10l6tqk",
    bottom: "x1o583il",
    right: "xzkxjte",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  },
  roomItemMovedToTheLeft: {
    "index__styles.roomItemMovedToTheLeft": "index__styles.roomItemMovedToTheLeft",
    transform: "x1gaodxz",
    $$css: true
  },
  drawerMenuOpen: {
    "index__styles.drawerMenuOpen": "index__styles.drawerMenuOpen",
    transform: "xulpuih",
    $$css: true
  }
};
const getClassName = (...styleObj) => {
  const {
    className
  } = stylex.props(...styleObj);
  return className;
};
const ListItem = (0, _react.memo)(({
  onClick,
  title,
  content,
  unread,
  avatarUrl,
  memberAvatars,
  timestamp,
  style: styleProp,
  inActive,
  contextMenu,
  odd,
  online,
  setPreventScroll,
  preventScroll
}) => {
  const [showContextMenu, setShowContextMenu] = (0, _react.useState)(false);
  const [hover, setHover] = (0, _react.useState)(false);
  const hasPointer = (0, _reactResponsive.useMediaQuery)({
    query: "(hover: hover) and (pointer: fine)"
  });
  const [drawerOpen, setDrawerOpen] = (0, _react.useState)(false);
  const openDrawer = () => {
    setDrawerOpen(true);
    if (setPreventScroll) setPreventScroll(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
    if (setPreventScroll) setPreventScroll(false);
  };
  const bind = (0, _react2.useDrag)(hasPointer ? () => {} : ({
    down,
    movement: [mx, my]
  }) => {
    if (isAndroid) {
      mx = mx * 7;
      my = my * 7;
    }
    if (!contextMenu || contextMenu.length === 0) return;
    if (mx == 0 && mx === my) return;
    if (!down) {
      if (setPreventScroll) setPreventScroll(true);
      if (mx < 0 && Math.abs(my) < (isAndroid ? 5 : 50)) {
        if (!preventScroll) {
          openDrawer();
        }
      } else {
        closeDrawer();
      }
    }
  }, {
    filterTaps: true
  });
  const wrapperRef = (0, _react.useRef)(null);
  const cMenu = /* @__PURE__ */_react.default.createElement(_ContextMenu.ContextMenu, {
    onClose: () => setShowContextMenu(false),
    fluid: true,
    position: "center",
    open: showContextMenu,
    className: styles.optionsDropdownWrapper,
    trigger: /* @__PURE__ */_react.default.createElement(_Button.Button, {
      color: "blue",
      size: "xs",
      className: styles.roomOptions,
      style: {
        borderWidth: 0
      },
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        setShowContextMenu(!showContextMenu);
      }
    }, /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      name: "ellipsis horizontal",
      style: {
        fontSize: "20px",
        margin: 0
      }
    }))
  }, contextMenu == null ? void 0 : contextMenu.map(({
    icon,
    text,
    action
  }, index) => /* @__PURE__ */_react.default.createElement(_ContextMenu.ContextMenu.Item, {
    key: index,
    text,
    icon,
    onClick: action
  })));
  return /* @__PURE__ */_react.default.createElement("div", __spreadProps(__spreadValues({}, bind()), {
    ref: wrapperRef,
    className: getClassName(styles.roomItemWrapper),
    style: styleProp
  }), /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.roomItem, odd && styles.odd, drawerOpen && styles.roomItemMovedToTheLeft),
    onClick: !drawerOpen && !showContextMenu && onClick || void 0,
    onMouseEnter: hasPointer ? () => setHover(true) : void 0,
    onMouseLeave: hasPointer ? () => setHover(false) : void 0
  }, /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.roomIcon)
  }, /* @__PURE__ */_react.default.createElement(_Image.Image, {
    circular: true,
    className: styles.roomIconImage,
    src: avatarUrl,
    loading: "lazy"
  }), online && /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    className: styles.onlineIcon,
    name: "circle",
    color: "green"
  })), /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.right)
  }, /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.upperRow)
  }, /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(inActive && styles.inActive, unread && styles.unread)
  }, title)), /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.latestMessage, unread && styles.unread)
  }, /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.latestMessageText)
  }, content), timestamp && /* @__PURE__ */_react.default.createElement("div", null, (0, _helpers.getShorthandTime)(timestamp))), memberAvatars && /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.lowerRow)
  }, /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.members)
  }, memberAvatars == null ? void 0 : memberAvatars.slice(0, 3).map((m, index) => /* @__PURE__ */_react.default.createElement(_Image.Image, {
    key: index,
    className: styles.member,
    circular: true,
    src: m,
    loading: "lazy"
  })), (memberAvatars == null ? void 0 : memberAvatars.length) > 3 && /* @__PURE__ */_react.default.createElement("div", null, "+", (memberAvatars == null ? void 0 : memberAvatars.length) - 3)))), hover && contextMenu && contextMenu.length > 0 && hasPointer && cMenu), !hasPointer && /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.drawerMenu, drawerOpen && styles.drawerMenuOpen)
  }, contextMenu && contextMenu.length > 0 && cMenu));
});
var _default = exports.default = (0, _react.memo)((0, _react.forwardRef)(({
  items,
  large
}, ref) => {
  const {
    isMobile
  } = (0, _withMobileDetection.useIsMobile)();
  const [preventScroll, setPreventScroll] = (0, _react.useState)(false);
  const wrapperClass = getClassName(styles.wrapper, preventScroll && styles.preventScroll);
  (0, _react.useEffect)(() => {
    return () => {
      setPreventScroll(false);
    };
  }, [items.length]);
  const rowRenderer = ({
    index,
    // Index f the row within the collection
    style
    // Style object to be applied to row (to position it)
  }) => {
    const item = items[index];
    return /* @__PURE__ */_react.default.createElement(ListItem, __spreadProps(__spreadValues({}, item), {
      odd: index % 2 !== 0,
      style: __spreadProps(__spreadValues({}, style), {
        height: large ? 120 : 80
      }),
      setPreventScroll,
      preventScroll
    }));
  };
  if (items.length < 10) {
    return /* @__PURE__ */_react.default.createElement("div", {
      className: wrapperClass
    }, items.map((_item, index) => rowRenderer({
      index
    })));
  }
  return /* @__PURE__ */_react.default.createElement("div", {
    className: wrapperClass
  }, /* @__PURE__ */_react.default.createElement(_reactVirtualized.AutoSizer, null, ({
    width,
    height
  }) => /* @__PURE__ */_react.default.createElement(_reactVirtualized.List, {
    ref,
    width,
    height,
    rowCount: items.length,
    rowHeight: isMobile || large ? 120 : 80,
    rowRenderer
  })));
}));