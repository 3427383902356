"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _react = _interopRequireWildcard(require("react"));
var _Loader = require("../Loader");
var _useScrollToBottom = require("use-scroll-to-bottom");
var _usehooksTs = require("usehooks-ts");
var _lodash = _interopRequireDefault(require("lodash.debounce"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
function mergeRefs(...refs) {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    });
  };
}
var _default = exports.default = (0, _react.memo)((0, _react.forwardRef)(({
  nextPage,
  children,
  style,
  loading
}, ref) => {
  const {
    className
  } = {
    className: "MessageContainer__styles.root x1p2dfur x1tamke2 x889kno xysyzu8 x1hx0egp xh8yej3 x78zum5 x2i4810 xdt5ytf x1n2onr6"
  };
  const messageIds = (children == null ? void 0 : children.map(child => child.key)) || [];
  const containerRef = (0, _react.useRef)(null);
  const scrollStateRef = (0, _react.useRef)({
    prevScrollHeight: -1,
    autoScroll: true,
    hasScrolled: false
  });
  const [setBottomRef, bottomElementInView] = (0, _useScrollToBottom.useScrollToBottom)();
  const isBottom = (0, _react.useCallback)(() => {
    const container = containerRef.current;
    if (!container) return true;
    return container.scrollHeight - container.scrollTop <= container.clientHeight || bottomElementInView;
  }, [bottomElementInView]);
  const loadMore = (0, _react.useCallback)((0, _lodash.default)(() => __async(void 0, null, function* () {
    const scrollState = scrollStateRef.current;
    const container = containerRef.current;
    if (!container) return;
    scrollState.prevScrollHeight = container.scrollHeight;
    yield nextPage();
  }), 500, {
    leading: true
  }), [nextPage]);
  const scrollHandler = (0, _react.useCallback)(() => __async(void 0, null, function* () {
    const container = containerRef.current;
    const scrollState = scrollStateRef.current;
    if (!container) return;
    if (!scrollState.hasScrolled || isBottom()) {
      scrollState.autoScroll = true;
    } else {
      scrollState.autoScroll = false;
    }
    if (container.scrollTop === 0) {
      yield loadMore();
    }
    scrollState.hasScrolled = true;
  }), [loadMore, bottomElementInView, isBottom]);
  const scrollToBottom = (0, _react.useCallback)(() => {
    const container = containerRef.current;
    if (!container) throw "Container not initialized";
    container.scrollTop = container.scrollHeight;
  }, []);
  const onResize = (0, _react.useCallback)(({
    height: newHeight
  }) => {
    const container = containerRef.current;
    const scrollState = scrollStateRef.current;
    if (!newHeight) {
      scrollState.hasScrolled = false;
    }
    if (!scrollState.hasScrolled) {
      scrollToBottom();
      return;
    }
    if (newHeight && container) {
      const scrollTopAdjustment = scrollState.autoScroll ? container.scrollHeight : container.scrollTop - newHeight;
      container.scrollTop = scrollTopAdjustment;
    }
  }, []);
  (0, _usehooksTs.useResizeObserver)({
    ref: containerRef,
    box: "border-box",
    onResize
  });
  (0, _react.useEffect)(() => {
    const container = containerRef.current;
    const scrollState = scrollStateRef.current;
    if (messageIds.length === 0) return;
    if (!container) return;
    if (scrollState.autoScroll) {
      scrollToBottom();
    }
    if (scrollState.prevScrollHeight !== -1) {
      container.scrollTop = container.scrollHeight - scrollState.prevScrollHeight;
      scrollState.prevScrollHeight = -1;
    }
  }, [JSON.stringify(messageIds)]);
  return /* @__PURE__ */_react.default.createElement("div", {
    ref: mergeRefs(containerRef, ref),
    style,
    className,
    onScroll: scrollHandler
  }, loading && /* @__PURE__ */_react.default.createElement("div", {
    style: {
      height: "50px",
      margin: "auto"
    }
  }, /* @__PURE__ */_react.default.createElement(_Loader.Loader, {
    active: true
  })), _react.default.Children.map(children, (child, i) => {
    if (!child) return;
    if (i === _react.default.Children.count(children) - 1) {
      return _react.default.cloneElement(child, {
        ref: setBottomRef
      });
    }
    return child;
  }));
}));