"use strict";
import { useCallback, useRef, useState, useEffect } from "react";
export const useOnClickOutside = (refs, onClickOutside) => {
  useEffect(() => {
    if (!refs)
      return;
    const refsLength = refs.filter((r) => r).length;
    function handleClickOutside(event) {
      if (document.querySelector("[data-spotlight]"))
        return;
      let outsides = 0;
      for (const ref of refs) {
        if (!ref)
          continue;
        let current = ref.current;
        if (current && current.ref) {
          current = current.ref.current;
        }
        if (current && !current.contains(event.target)) {
          outsides++;
        }
      }
      if (outsides === refsLength)
        onClickOutside();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs]);
};
export const useLongPress = (callback = () => {
}, duration = 300) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timerId = useRef(null);
  const touchStartLocation = useRef({ x: 0, y: 0 });
  const startPress = useCallback(
    (e) => {
      timerId.current = setTimeout(() => {
        setLongPressTriggered(true);
        callback();
      }, duration);
      if (e.touches) {
        touchStartLocation.current = {
          x: e.touches[0].clientX,
          y: e.touches[0].clientY
        };
      }
    },
    [callback, duration]
  );
  const clearPress = useCallback(
    (e) => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      if (longPressTriggered) {
        e.preventDefault();
      }
      setLongPressTriggered(false);
    },
    [longPressTriggered]
  );
  const handleTouchMove = useCallback((e) => {
    const moveThreshold = 10;
    if (e.touches) {
      const xDiff = Math.abs(
        e.touches[0].clientX - touchStartLocation.current.x
      );
      const yDiff = Math.abs(
        e.touches[0].clientY - touchStartLocation.current.y
      );
      if (xDiff > moveThreshold || yDiff > moveThreshold) {
        if (timerId.current) {
          clearTimeout(timerId.current);
        }
        setLongPressTriggered(false);
      }
    }
  }, []);
  useEffect(() => {
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, []);
  return {
    onTouchStart: startPress,
    onTouchEnd: clearPress,
    onTouchMove: handleTouchMove
  };
};
