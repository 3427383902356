"use strict";
import { gql, useMutation, useQuery } from "kosmi-sdk/gql";
import {
  Button,
  ContextMenu,
  Icon,
  Image,
  Loader,
  MessageList
} from "kosmi-sdk/uikit";
import { AnimatedModal as Modal } from "kosmi-sdk/widgets";
import React, {
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Linkify from "react-linkify";
import { useHistory } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import { withStyleHook } from "../../../../../../../../sdk/styles";
import style from "../../style.css";
import { TopBarButton } from "../..";
import LoginWall from "../../../../../../../Widgets/LoginWall";
import * as pwa from "../../../../../../../../services/pwa";
function isNotNull(value) {
  return value !== null;
}
const NOTIFICATION_QUERY = gql(`
  query NotificationQuery2 {
    currentUser {
      id
      connectionId
      user {
        id
        username
        isAnonymous
        notifications {
          id
          title
          text
          type
          timestamp
          seen
          autoOpen
          sender {
            id
            username
            displayName
            avatarUrl
          }
        }
      }
    }
  }
`);
const ROOM_QUERY = gql(`
  query RoomActionQuery($roomId: String!) {
    room(id: $roomId) {
      id
      state {
        metadata {
          roomName
          avatarUrl
        }
      }
    }
  }
`);
const MARK_SEEN_MUTATION = gql(`
  mutation MarkAllNotificationsAsSeen {
    markAllNotificationsAsSeen {
      id
      seen
    }
  }
`);
const JoinRoomAction = memo(
  ({
    roomId,
    joinRoomCallback
  }) => {
    var _a, _b, _c, _d, _e, _f;
    const history = useHistory();
    const { data } = useQuery(ROOM_QUERY, { variables: { roomId } });
    const styles = withStyleHook(style);
    return /* @__PURE__ */ React.createElement("div", { className: styles.joinRoomAction }, data ? /* @__PURE__ */ React.createElement(React.Fragment, null, (_c = (_b = (_a = data.room) == null ? void 0 : _a.state) == null ? void 0 : _b.metadata) == null ? void 0 : _c.roomName, " ", /* @__PURE__ */ React.createElement(
      Image,
      {
        circular: true,
        src: ((_f = (_e = (_d = data.room) == null ? void 0 : _d.state) == null ? void 0 : _e.metadata) == null ? void 0 : _f.avatarUrl) || "",
        loading: "lazy"
      }
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "yellow",
        inverted: true,
        onClick: () => {
          history.push("/room/" + roomId);
          if (joinRoomCallback) {
            joinRoomCallback(true);
          }
        }
      },
      "Join Room"
    ), " ") : /* @__PURE__ */ React.createElement(Loader, null));
  }
);
const addKosmiEmbeds = (styles, body) => reactStringReplace(
  body,
  /(https:\/\/img\.kosmi\.io\/\w+[.]\S+)/g,
  (match) => /* @__PURE__ */ React.createElement("img", { className: styles.image, src: match, loading: "lazy" })
);
const ViewNotificationModal = ({
  notification,
  onClose,
  currentUser
}) => {
  const styles = withStyleHook(style);
  let content = null;
  const username = (currentUser == null ? void 0 : currentUser.username) || "";
  if (notification) {
    if (notification.type === "INVITE") {
      content = /* @__PURE__ */ React.createElement("div", { className: styles.notificationModalContent }, /* @__PURE__ */ React.createElement("div", { className: styles.invitingUser }, /* @__PURE__ */ React.createElement(Image, { circular: true, src: notification.sender.avatarUrl }), notification.sender.displayName), /* @__PURE__ */ React.createElement("div", null, "has invited you to join a room"), /* @__PURE__ */ React.createElement(
        JoinRoomAction,
        {
          roomId: notification.text,
          joinRoomCallback: onClose
        }
      ));
    } else {
      content = /* @__PURE__ */ React.createElement("div", { className: styles.notificationModalContent }, /* @__PURE__ */ React.createElement("div", { className: styles.invitingUser }, /* @__PURE__ */ React.createElement(Image, { circular: true, src: notification.sender.avatarUrl }), notification.sender.displayName), /* @__PURE__ */ React.createElement("div", { className: styles.title }, notification.title), /* @__PURE__ */ React.createElement("div", { className: styles.text }, /* @__PURE__ */ React.createElement(Linkify, null, notification.text.replace(
        "[username]",
        username !== "anonymous" ? username : "yourusername"
      ).split("\n").map((line, i) => /* @__PURE__ */ React.createElement("div", { key: i }, addKosmiEmbeds(styles, line))))));
    }
  }
  return /* @__PURE__ */ React.createElement(Modal, { size: "tiny", closeIcon: true, open: !!notification, onClose }, /* @__PURE__ */ React.createElement(Modal.Content, null, content));
};
const Notifications = forwardRef(({ onClose }, ref) => {
  var _a, _b;
  const { data, refetch } = useQuery(NOTIFICATION_QUERY);
  const notifications = ((_b = (_a = data == null ? void 0 : data.currentUser) == null ? void 0 : _a.user) == null ? void 0 : _b.notifications) || [];
  const styles = withStyleHook(style);
  const prevNotificationsRef = useRef(notifications);
  const [viewNotification, setViewNotification] = useState(null);
  useEffect(() => {
    const prevNotifications = prevNotificationsRef.current;
    if (notifications.length >= prevNotifications.length) {
      const notification = notifications[0];
      if (notification == null ? void 0 : notification.autoOpen) {
        setViewNotification(notification);
      }
      prevNotificationsRef.current = notifications;
    }
  }, [JSON.stringify(notifications)]);
  const items = useMemo(
    () => notifications.filter(isNotNull).map((n) => {
      var _a2, _b2;
      return {
        onClick: () => setViewNotification(n),
        title: n.title || "",
        content: ((_a2 = n == null ? void 0 : n.sender) == null ? void 0 : _a2.displayName) || "",
        unread: !n.seen,
        avatarUrl: ((_b2 = n == null ? void 0 : n.sender) == null ? void 0 : _b2.avatarUrl) || "",
        timestamp: n.timestamp || 0
      };
    }),
    [JSON.stringify(notifications)]
  );
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      id: styles.notificationDropdown,
      className: notifications.length === 0 ? " " + styles.empty : "",
      ref
    },
    /* @__PURE__ */ React.createElement("div", { className: styles.dropdownHeader }, "Notifications", /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "white",
        inverted: true,
        style: {
          borderWidth: 0,
          fontSize: 18,
          visibility: "hidden"
        }
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "cog" })
    )),
    /* @__PURE__ */ React.createElement(
      ViewNotificationModal,
      {
        notification: viewNotification,
        currentUser: data == null ? void 0 : data.currentUser,
        onClose: (closeDropdown) => {
          setViewNotification(null);
          if (closeDropdown) {
            onClose();
          }
          if (viewNotification == null ? void 0 : viewNotification.autoOpen) {
            refetch();
          }
        }
      }
    ),
    items.length ? /* @__PURE__ */ React.createElement(MessageList, { items }) : /* @__PURE__ */ React.createElement("div", { className: styles.emptyNotifications }, "You have no notifications!")
  );
});
const NotificationIcon = memo(
  ({
    style: iconStyle,
    className
  }) => {
    var _a, _b, _c, _d, _e;
    const styles = withStyleHook(style);
    const { data } = useQuery(NOTIFICATION_QUERY);
    const unseenNotifications = !((_b = (_a = data == null ? void 0 : data.currentUser) == null ? void 0 : _a.user) == null ? void 0 : _b.isAnonymous) && ((_e = (_d = (_c = data == null ? void 0 : data.currentUser) == null ? void 0 : _c.user) == null ? void 0 : _d.notifications) == null ? void 0 : _e.filter((n) => !n.seen)) || [];
    const classNames = [];
    if (unseenNotifications.length > 0) {
      classNames.push(styles.glowingIcon);
    }
    if (className) {
      classNames.push(className);
    }
    return /* @__PURE__ */ React.createElement(React.Fragment, null, unseenNotifications.length > 0 && /* @__PURE__ */ React.createElement("div", { className: styles.notificationNumber }, unseenNotifications.length), /* @__PURE__ */ React.createElement(Icon, { name: "bell", className: classNames.join(" "), style: iconStyle }));
  }
);
const NotificationDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [markAsSeen] = useMutation(MARK_SEEN_MUTATION);
  const registerWebPushNotifications = pwa.useRegisterWebPushNotifications();
  return /* @__PURE__ */ React.createElement(
    ContextMenu,
    {
      trigger: /* @__PURE__ */ React.createElement(LoginWall, null, /* @__PURE__ */ React.createElement(
        TopBarButton,
        {
          onClick: () => {
            setShowDropdown(!showDropdown);
          }
        },
        /* @__PURE__ */ React.createElement(NotificationIcon, null)
      )),
      open: showDropdown,
      onOpen: () => {
        markAsSeen();
        registerWebPushNotifications();
      },
      onClose: () => {
        setShowDropdown(false);
      },
      position: "right"
    },
    /* @__PURE__ */ React.createElement(
      Notifications,
      {
        onClose: () => {
          setShowDropdown(false);
        }
      }
    )
  );
};
export default NotificationDropdown;
export {
  Notifications,
  NOTIFICATION_QUERY,
  MARK_SEEN_MUTATION,
  NotificationIcon
};
