"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _gql = require("kosmi-sdk/gql");
var _web = _interopRequireDefault(require("@hyperbeam/web"));
var _react = _interopRequireWildcard(require("react"));
var _Premium = require("../../../components/Widgets/Premium");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useMembers = require("../hooks/useMembers");
var _useRoomId = require("../hooks/useRoomId");
var _useCurrentMember = require("../hooks/useCurrentMember");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  maxUsers: {
    "VirtualBrowser__styles.maxUsers": "VirtualBrowser__styles.maxUsers",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    backgroundColor: "xhn7nhy",
    alignItems: "x6s0dn4",
    height: "x5yr21d",
    width: "xh8yej3",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    fontSize: "xosj86m",
    $$css: true
  },
  root: {
    "VirtualBrowser__styles.root": "VirtualBrowser__styles.root",
    width: "xh8yej3",
    height: "x5yr21d",
    $$css: true
  },
  stillThere: {
    "VirtualBrowser__styles.stillThere": "VirtualBrowser__styles.stillThere",
    width: "xh8yej3",
    height: "x5yr21d",
    position: "x10l6tqk",
    backgroundColor: "xcpsgoo",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    fontSize: "x1pvqxga",
    zIndex: "x1vjfegm",
    $$css: true
  },
  paywall: {
    "VirtualBrowser__styles.paywall": "VirtualBrowser__styles.paywall",
    width: "xh8yej3",
    height: "x5yr21d",
    position: "x10l6tqk",
    backgroundColor: "xblu0er",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    fontSize: "x1pvqxga",
    zIndex: "x1vjfegm",
    textAlign: "x2b8uid",
    $$css: true
  },
  iframe: {
    "VirtualBrowser__styles.iframe": "VirtualBrowser__styles.iframe",
    width: "xh8yej3",
    height: "x5yr21d",
    background: "x14ziusj",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    position: "x1n2onr6",
    $$css: true
  }
};
const BROWSER_QUERY = (0, _gql.gql)(`
  query VirtualBrowserQuery($roomId: String!, $sessionId: String!) {
    virtualBrowser(roomId: $roomId, sessionId: $sessionId) {
      id
      embedUrl
    }
  }
`);
const BROWSER_CALLBACK = (0, _gql.gql)(`
  mutation BrowserCallback(
    $sessionId: String!
    $roomId: String!
    $frameUserId: String!
  ) {
    virtualBrowserCallback(
      sessionId: $sessionId
      roomId: $roomId
      frameUserId: $frameUserId
    ) {
      ok
    }
  }
`);
const HyperBeamFrame = ({
  src,
  roomId,
  sessionId,
  currentUser,
  onTabUpdated
}) => {
  const [browserCallback] = (0, _gql.useMutation)(BROWSER_CALLBACK);
  const iframeRef = (0, _react.useRef)(null);
  const wrapperRef = (0, _react.useRef)();
  const [showWarning, setShowWarning] = (0, _react.useState)(false);
  const [hb, setHb] = (0, _react.useState)(null);
  const isSubscribed = !!(currentUser == null ? void 0 : currentUser.isSubscribed);
  (0, _react.useEffect)(() => {
    ;
    (() => __async(void 0, null, function* () {
      const iframe = iframeRef.current;
      const hb2 = yield (0, _web.default)(iframe, src, {
        onDisconnect: () => {},
        onCloseWarning: e => {
          setShowWarning(!!e.deadline);
        }
      });
      if (onTabUpdated) {
        hb2.tabs.onUpdated.addListener(onTabUpdated);
      }
      setHb(hb2);
      iframe.focus();
      browserCallback({
        variables: {
          roomId,
          sessionId,
          frameUserId: hb2.userId
        }
      });
    }))();
  }, []);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.root,
    ref: wrapperRef
  }, showWarning && isSubscribed && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.stillThere
  }, "Are you still there?", /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    onClick: () => {
      hb == null ? void 0 : hb.ping();
      setShowWarning(false);
    },
    style: {
      margin: 10
    },
    color: "blue"
  }, "Yes")), showWarning && !isSubscribed && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.paywall
  }, /* @__PURE__ */_react.default.createElement(_Premium.PaywallMessage, {
    message: "Please subscribe to Kosmi Premium to get unlimited time in the virtual browser",
    source: "Virtual Browser timeout"
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.iframe, {
    style: styles.iframe,
    frameBorder: "0",
    ref: iframeRef,
    src
  }));
};
var _default = ({
  sessionId,
  onTabUpdated
}) => {
  const members = (0, _useMembers.useMembers)() || [];
  const roomId = (0, _useRoomId.useRoomId)();
  const currentMember = (0, _useCurrentMember.useCurrentMember)();
  const {
    data
  } = (0, _gql.useQuery)(BROWSER_QUERY, {
    variables: {
      roomId,
      sessionId
    }
  });
  const maxMembers = 15;
  const tooManyAdmins = members.filter(m => (m == null ? void 0 : m.role) === "ADMIN").length >= maxMembers;
  const isAdmin = !tooManyAdmins && (currentMember == null ? void 0 : currentMember.role) === "ADMIN";
  if (members && members.length > maxMembers && !isAdmin) {
    return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.maxUsers
    }, "Unfortunately the Virtual Browser can only be used with a maximum of", " ", maxMembers, " people at a time.");
  }
  const virtualBrowser = data == null ? void 0 : data.virtualBrowser;
  if (virtualBrowser == null ? void 0 : virtualBrowser.embedUrl) return /* @__PURE__ */_react.default.createElement(HyperBeamFrame, {
    key: [roomId, sessionId, virtualBrowser.embedUrl, currentMember == null ? void 0 : currentMember.role].join(","),
    src: virtualBrowser.embedUrl,
    roomId,
    sessionId,
    onTabUpdated,
    currentUser: currentMember == null ? void 0 : currentMember.user
  });
  return null;
};
exports.default = _default;