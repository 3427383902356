"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserProfileModal = exports.UserPickerModal = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _gql = require("kosmi-sdk/gql");
var _react = _interopRequireWildcard(require("react"));
var _client = require("@apollo/client");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _helpers = require("kosmi-sdk/helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  inviteToRoomModal: {
    "index__styles.inviteToRoomModal": "index__styles.inviteToRoomModal",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  inviteToRoomTitle: {
    "index__styles.inviteToRoomTitle": "index__styles.inviteToRoomTitle",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  selectRoom: {
    "index__styles.selectRoom": "index__styles.selectRoom",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  inviteFriendToRoom: {
    "index__styles.inviteFriendToRoom": "index__styles.inviteFriendToRoom",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    $$css: true
  },
  userPicker: {
    "index__styles.userPicker": "index__styles.userPicker",
    height: "x18dl8mb",
    $$css: true
  }
};
const INVITE_TO_ROOM_MUTATION = (0, _gql.gql)(`
  mutation InviteToRoomMutation2($userId: String!, $roomId: String!) {
    inviteToRoom(userId: $userId, roomId: $roomId) {
      ok
    }
  }
`);
const REMOVE_FRIEND_MUTATION = (0, _gql.gql)(`
  mutation RemoveFriendMutation($userId: String!) {
    removeFriend(userId: $userId) {
      id
      friends {
        state
        user {
          id
          isSubscribed
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const USER_QUERY = (0, _gql.gql)(`
  query UserQuery($id: String!) {
    user(id: $id) {
      id
      username
      displayName
      avatarUrl
      isConnected
      isSubscribed
    }
  }
`);
const USER_SEARCH_QUERY = (0, _gql.gql)(`
  query UserSearchQuery($query: String!) {
    searchUsers(query: $query) {
      id
      username
      displayName
      avatarUrl
    }
  }
`);
const USER_ROOMS = (0, _gql.gql)(`
  query UserRoomsQuery {
    userRooms {
      id
      state {
        metadata {
          roomName
          avatarUrl
        }
      }
    }
  }
`);
const ADD_FRIEND_MUTATION = (0, _gql.gql)(`
  mutation AddFriendMutation1($username: String!) {
    addFriend(username: $username) {
      id
      friends {
        state
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const InviteToRoomModal = (0, _helpers.withDialogs)(({
  alert,
  userId,
  user,
  onClose
}) => {
  const roomRegex = /\/room\/([a-zA-Z0-9@]+)/;
  const matches = roomRegex.exec(window.location.pathname);
  const currentRoomId = matches ? matches[1] : null;
  const [roomId, setRoomId] = (0, _react.useState)(currentRoomId);
  const {
    data
  } = (0, _client.useQuery)(USER_ROOMS, {
    fetchPolicy: "cache-first"
  });
  const rooms = data && data.userRooms ? data.userRooms : [];
  const [inviteToRoom] = (0, _gql.useMutation)(INVITE_TO_ROOM_MUTATION);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    onClose
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteToRoomModal
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteToRoomTitle
  }, "Invite ", user.displayName, " to room:"), /* @__PURE__ */_react.default.createElement(_uikit.Select, {
    className: styles.selectRoom,
    placeholder: "Select room",
    defaultValue: roomId || "",
    value: roomId || "",
    options: rooms.map(r => {
      return {
        key: r.id,
        value: r.id,
        text: r.state.metadata.roomName,
        image: r.state.metadata.avatarUrl
      };
    }),
    onChange: e => {
      setRoomId(e.target.value);
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    inverted: true,
    style: {
      margin: 10
    },
    onClick: () => __async(void 0, null, function* () {
      if (roomId) {
        inviteToRoom({
          variables: {
            userId: userId || "",
            roomId
          }
        });
        if (alert) yield alert("Invite sent");
      } else if (alert) {
        yield alert("No room selected");
      }
      onClose();
    })
  }, "Invite"))));
});
const UserProfileModal = exports.UserProfileModal = (0, _helpers.withDialogs)(({
  open,
  onClose,
  userId,
  currentUser,
  confirm
}) => {
  const friendIds = currentUser.friends.filter(f => f.state === "ACCEPTED").map(f => f.user.id);
  const isFriend = friendIds.indexOf(userId) !== -1;
  const [removeFriend] = (0, _gql.useMutation)(REMOVE_FRIEND_MUTATION);
  const [inviteToRoomUserId, setInviteToRoomUserId] = (0, _react.useState)(null);
  const {
    data
  } = (0, _client.useQuery)(USER_QUERY, {
    variables: {
      id: userId || ""
    },
    fetchPolicy: "cache-first",
    skip: !userId
  });
  const user = data == null ? void 0 : data.user;
  const actions = [];
  if (isFriend) {
    actions.push({
      icon: "remove user",
      text: "Remove friend",
      color: "red",
      onClick: () => __async(void 0, null, function* () {
        if (confirm && (yield confirm(`Are you sure you want to remove ${user == null ? void 0 : user.displayName}`))) {
          yield removeFriend({
            variables: {
              userId: userId || ""
            }
          });
          onClose();
        }
      })
    });
    actions.push({
      icon: "user plus",
      onClick: () => setInviteToRoomUserId(userId),
      text: "Invite to room..."
    });
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open,
    onClose
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, user ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteFriendToRoom
  }, /* @__PURE__ */_react.default.createElement(InviteToRoomModal, {
    userId: inviteToRoomUserId,
    user,
    onClose: () => setInviteToRoomUserId(null)
  }), /* @__PURE__ */_react.default.createElement(_uikit.UserProfile, {
    displayName: user.displayName || "",
    username: user.username || "",
    avatarUrl: user.avatarUrl || "",
    isSubscribed: user.isSubscribed || false,
    isAnonymous: false,
    actions
  })) : /* @__PURE__ */_react.default.createElement(_uikit.Loader, {
    size: "massive"
  })));
});
const UserPickerModal = exports.UserPickerModal = (0, _helpers.withDialogs)(({
  alert,
  open,
  onClose
}) => {
  const [searchQuery, setSearchQuery] = (0, _react.useState)("");
  const {
    data,
    loading
  } = (0, _client.useQuery)(USER_SEARCH_QUERY, {
    variables: {
      query: searchQuery
    },
    skip: searchQuery.length < 3
  });
  const _onClose = () => {
    onClose();
  };
  const [addFriend, {
    loading: addFriendLoading
  }] = (0, _gql.useMutation)(ADD_FRIEND_MUTATION);
  const [value, setValue] = (0, _react.useState)(null);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open,
    onClose: _onClose
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "Add a Friend"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.userPicker
  }, /* @__PURE__ */_react.default.createElement(_uikit.SearchInput, {
    placeholder: "Search...",
    fluid: true,
    value: searchQuery,
    onChange: e => {
      setValue(null);
      setSearchQuery(e.target.value);
    },
    onSelect: value2 => {
      setValue(value2);
    },
    loading,
    results: data && data.searchUsers && data.searchUsers.map(u => ({
      key: u.username,
      image: u.avatarUrl,
      text: u.username
    })) || []
  }))), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Actions, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    inverted: true,
    color: "white",
    disabled: loading,
    onClick: () => {
      setSearchQuery("");
      setValue(null);
      _onClose();
    }
  }, "Cancel"), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    disabled: !value,
    inverted: true,
    loading: addFriendLoading,
    style: {
      marginLeft: 8
    },
    onClick: () => __async(void 0, null, function* () {
      var _a;
      try {
        yield addFriend({
          variables: {
            username: value || ""
          }
        });
        if (alert) alert("Friend request sent");
        setSearchQuery("");
        setValue(null);
        _onClose();
      } catch (e) {
        if (alert) alert((_a = e == null ? void 0 : e.graphQLErrors[0]) == null ? void 0 : _a.message);
      }
    })
  }, "Add friend")));
});