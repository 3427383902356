"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { memo, useContext, useRef, useState } from "react";
import { Button, Image, Icon } from "kosmi-sdk/uikit";
import platform from "platform";
import { useRoomStateCached } from "../../hooks/useRoomData";
import { withStyleHook } from "kosmi-sdk/styles";
import { useRoomId, useTracking } from "kosmi-sdk/helpers";
import style from "./style.css";
import { RoomDropdown } from "../Sidebar/components/RoomToolbar";
import MobileContext from "../../../Widgets/Mobile/MobileContext";
import { useSidebarData } from "../Sidebar/";
import LeaveRoomButton from "../Sidebar/components/LeaveRoomButton";
import { InviteFriendsModal } from "../Sidebar/components/RoomToolbar";
import MembersModal from "../Sidebar/components/MembersModal";
import LoginWall from "../../../Widgets/LoginWall";
import { useHistory } from "react-router-dom";
export default memo(() => {
  var _a;
  const roomId = useRoomId();
  const inviteLink = `https://kosmi.to/${roomId}`;
  const data = useRoomStateCached(roomId);
  const metadata = ((_a = data == null ? void 0 : data.state) == null ? void 0 : _a.metadata) || {};
  const { roomName } = metadata;
  const styles = withStyleHook(style);
  const [dropdownIsOpen, setdropdownIsOpen] = useState(false);
  const { setExpandRoom, expandRoom } = useContext(MobileContext);
  const { memberCount } = useSidebarData(roomId);
  const track = useTracking();
  const isLobby = roomId === "lobby" || (roomId == null ? void 0 : roomId.endsWith("/lobby"));
  const history = useHistory();
  const topbarRef = useRef(null);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      ref: topbarRef,
      onClick: !expandRoom ? (e) => {
        if (e.target === topbarRef.current) {
          setExpandRoom(true);
        }
      } : void 0,
      className: styles.topBar,
      style: {
        position: expandRoom ? void 0 : "absolute",
        height: expandRoom ? void 0 : "100%",
        width: "100%",
        cursor: !expandRoom ? "pointer" : void 0,
        zIndex: 3,
        backgroundColor: expandRoom ? void 0 : "color-mix(in srgb, var(--primary-dark-15) 80%, transparent)",
        backdropFilter: "blur(17px)"
      }
    },
    expandRoom && !isLobby && /* @__PURE__ */ React.createElement(LoginWall, null, /* @__PURE__ */ React.createElement(
      Button,
      {
        style: { borderWidth: 0 },
        color: "white",
        inverted: true,
        icon: expandRoom ? "chevron down" : "chevron up",
        onClick: () => {
          setExpandRoom(!expandRoom);
        }
      }
    )),
    isLobby && /* @__PURE__ */ React.createElement(
      Button,
      {
        style: { borderWidth: 0 },
        color: "white",
        inverted: true,
        icon: "chevron left",
        onClick: () => {
          ;
          window.skipHistoryPrompt = true;
          history.replace("/");
        }
      }
    ),
    isLobby && /* @__PURE__ */ React.createElement("div", { className: styles.lobbyTitle }, /* @__PURE__ */ React.createElement(Icon, { name: "globe" }), "Lobby"),
    !isLobby && /* @__PURE__ */ React.createElement(
      MembersModal,
      {
        trigger: /* @__PURE__ */ React.createElement(
          "div",
          {
            className: styles.roomInfo,
            onClick: expandRoom ? void 0 : () => {
              setExpandRoom(true);
            },
            style: {
              position: "relative",
              flex: 1,
              cursor: "pointer"
            }
          },
          /* @__PURE__ */ React.createElement(
            Image,
            {
              className: styles.avatar,
              circular: true,
              src: metadata.avatarUrl || ""
            }
          ),
          /* @__PURE__ */ React.createElement(
            "div",
            {
              style: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 5
              }
            },
            /* @__PURE__ */ React.createElement(
              "div",
              {
                style: {
                  display: "flex",
                  alignItems: "center",
                  fontSize: 14
                }
              },
              roomName
            ),
            memberCount > 0 && /* @__PURE__ */ React.createElement("div", { style: { fontSize: 12, opacity: 0.8 } }, /* @__PURE__ */ React.createElement(Icon, { name: "user group" }), " ", memberCount)
          )
        )
      }
    ),
    !isLobby && (expandRoom ? /* @__PURE__ */ React.createElement("div", { className: styles.buttons }, /* @__PURE__ */ React.createElement(
      InviteFriendsModal,
      {
        roomId,
        trigger: ({ onClick }) => {
          var _a2, _b;
          return /* @__PURE__ */ React.createElement(
            Button,
            {
              color: "white",
              inverted: true,
              onClick: ((_b = (_a2 = platform) == null ? void 0 : _a2.os) == null ? void 0 : _b.family) && ["Android", "iOS"].indexOf(platform.os.family) !== -1 && navigator["share"] ? () => __async(void 0, null, function* () {
                yield navigator.share({
                  title: "Kosmi",
                  text: `Join my Kosmi room! - ${inviteLink}`
                });
                track(
                  "UI",
                  "MobileShareLinkClicked",
                  "Mobile Share Link Clicked",
                  roomId
                );
              }) : onClick,
              style: {
                borderWidth: 0,
                width: 40,
                height: 40,
                marginLeft: "auto",
                marginRight: 4,
                fontSize: 16
              },
              icon: "add user"
            }
          );
        }
      }
    ), /* @__PURE__ */ React.createElement(
      RoomDropdown,
      {
        visible: dropdownIsOpen,
        onClose: () => setdropdownIsOpen(false),
        trigger: /* @__PURE__ */ React.createElement(
          Button,
          {
            onClick: () => setdropdownIsOpen(!dropdownIsOpen),
            color: "white",
            inverted: true,
            style: {
              borderWidth: 0,
              width: 40,
              height: 40,
              marginLeft: 4,
              marginRight: 4,
              fontSize: 20
            },
            icon: "ellipsis horizontal"
          }
        )
      }
    )) : /* @__PURE__ */ React.createElement(
      LeaveRoomButton,
      {
        trigger: /* @__PURE__ */ React.createElement(
          Button,
          {
            color: "white",
            inverted: true,
            style: {
              borderWidth: 0,
              width: 40,
              height: 40,
              marginLeft: 4,
              marginRight: 4,
              fontSize: 20,
              zIndex: 2
            },
            icon: "close"
          }
        )
      }
    ))
  );
});
