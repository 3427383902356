"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation } from "kosmi-sdk/gql";
import { Button, Checkbox, Form, Icon, Input } from "kosmi-sdk/uikit";
import { withStyleHook } from "kosmi-sdk/styles";
import React, { forwardRef, memo, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../AppContext";
import { currentUserHook } from "../../helpers/withCurrentUser";
import withDialogs from "../../helpers/withDialogs";
import { FadeInTransition } from "../../Widgets";
import { LoginModalInner } from "../../Widgets/LoginModal";
import Modal from "../AnimatedModal";
import style from "./style.css";
import { PaywallMessage } from "../Premium";
const CREATE_ROOM = gql(`
  mutation CreateRoomMutation2(
    $appName: RoomApp
    $description: String
    $roomName: String
    $public: Boolean!
    $autoAcceptJoins: Boolean
    $promoteNewUsersToAdmin: Boolean
    $colorScheme: String
    $roomId: String
  ) {
    createRoom(
      appName: $appName
      roomName: $roomName
      description: $description
      public: $public
      colorScheme: $colorScheme
      autoAcceptJoins: $autoAcceptJoins
      promoteNewUsersToAdmin: $promoteNewUsersToAdmin
      roomId: $roomId
    ) {
      id
    }
  }
`);
export const CreateRoomForm = withDialogs(
  ({
    type,
    modal,
    alert,
    onRoomJoined,
    confirm
  }) => {
    var _a;
    const [description, setDescription] = useState("");
    const [roomName, setRoomName] = useState("");
    const [customRoomId, setCustomRoomId] = useState("");
    const [useCustomRoomId, setUseCustomRoomId] = useState(false);
    const [promoteNewUsersToAdmin, setPromoteNewUsersToAdmin] = useState(
      type !== "public"
    );
    const history = useHistory();
    const currentUser = currentUserHook();
    const appContext = useContext(AppContext);
    const [createRoom, { loading }] = useMutation(CREATE_ROOM);
    const requireLogin = (_a = window == null ? void 0 : window.featureFlags) == null ? void 0 : _a.requireLoginToCreateRoom;
    const isSubscribed = !!(currentUser == null ? void 0 : currentUser.isSubscribed);
    if ((currentUser == null ? void 0 : currentUser.isAnonymous) && requireLogin) {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { style: { textAlign: "center", fontSize: 16 } }, "Creating a room requires an account. But don't worry,", " ", /* @__PURE__ */ React.createElement("em", null, "it's free"), ", and your friends can join your room without signing up."), /* @__PURE__ */ React.createElement(LoginModalInner, null));
    }
    return /* @__PURE__ */ React.createElement(
      Form,
      {
        onSubmit: (e) => __async(void 0, null, function* () {
          var _a2, _b, _c;
          e.preventDefault();
          if (!((_a2 = e == null ? void 0 : e.currentTarget) == null ? void 0 : _a2.checkValidity()))
            return;
          if (type === "public" && confirm && !(yield confirm(
            "Please be aware that any explicit content(18+) is not allowed in public rooms. A violation of this rule may result in a permanent ban."
          ))) {
            return;
          }
          try {
            const result = yield createRoom({
              variables: {
                roomName,
                description,
                public: type === "public",
                promoteNewUsersToAdmin,
                roomId: useCustomRoomId ? customRoomId : void 0
              }
            });
            setRoomName("");
            setDescription("");
            setPromoteNewUsersToAdmin(false);
            setUseCustomRoomId(false);
            setCustomRoomId("");
            modal == null ? void 0 : modal.handleClose();
            if (onRoomJoined) {
              onRoomJoined();
            }
            const roomId = (_c = (_b = result == null ? void 0 : result.data) == null ? void 0 : _b.createRoom) == null ? void 0 : _c.id;
            if (roomId) {
              appContext.setTutorialRoomId(roomId);
              history.push("/room/" + roomId);
            }
          } catch (e2) {
            const errorMessage = (e2 == null ? void 0 : e2.message) || e2.toString();
            alert && alert(errorMessage);
          }
        })
      },
      /* @__PURE__ */ React.createElement(Form.Field, null, /* @__PURE__ */ React.createElement(
        Form.Input,
        {
          label: "Room name",
          autoFocus: true,
          required: true,
          placeholder: "Room name",
          value: roomName,
          onChange: (e) => {
            setRoomName(e.target.value);
          }
        }
      )),
      useCustomRoomId && /* @__PURE__ */ React.createElement(Form.Field, null, /* @__PURE__ */ React.createElement(
        Form.Input,
        {
          label: "Custom room URL",
          placeholder: "roomId",
          prefix: "https://app.kosmi.io/room/",
          minLength: 6,
          maxLength: 20,
          required: true,
          value: customRoomId,
          onChange: (e) => {
            setCustomRoomId(e.target.value);
          }
        }
      )),
      type === "public" && /* @__PURE__ */ React.createElement(Form.Field, null, /* @__PURE__ */ React.createElement(Form.Label, null, "Room Description (optional)"), /* @__PURE__ */ React.createElement(
        Input,
        {
          value: description,
          onChange: (e) => {
            setDescription(e.target.value);
          }
        }
      )),
      type !== "public" && /* @__PURE__ */ React.createElement(Form.Field, null, /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          label: "Use custom room URL",
          toggle: true,
          checked: useCustomRoomId,
          onChange: (_e, data) => {
            if (!isSubscribed) {
              alert && alert(
                /* @__PURE__ */ React.createElement(
                  PaywallMessage,
                  {
                    message: "Please get Premium to use custom room URLs!",
                    source: "Custom Room ID"
                  }
                ),
                true
              );
              return;
            }
            setUseCustomRoomId(data.checked);
            setCustomRoomId("");
          }
        }
      ), /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          label: "Give new members admin permissions by default",
          toggle: true,
          checked: promoteNewUsersToAdmin,
          onChange: (_e, data) => {
            setPromoteNewUsersToAdmin(data.checked);
          }
        }
      )),
      /* @__PURE__ */ React.createElement(
        Form.Button,
        {
          loading,
          color: "yellow",
          style: {
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            width: 150
          },
          type: "submit"
        },
        "Create"
      )
    );
  }
);
export const JoinRoomForm = ({
  modal,
  alert,
  onRoomJoined
}) => {
  const [value, setValue] = useState("");
  const history = useHistory();
  return /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(
    Form.Input,
    {
      placeholder: "Room URL...",
      onChange: (e) => setValue(e.target.value)
    }
  ), /* @__PURE__ */ React.createElement(
    Form.Button,
    {
      color: "yellow",
      fluid: true,
      style: {
        marginTop: 20,
        marginBottom: 0,
        marginLeft: "auto",
        marginRight: "auto"
      },
      onClick: () => {
        const roomId = value.split("https://app.kosmi.io/room/")[1];
        if (!roomId && alert) {
          alert("Invalid Room URL");
          return;
        }
        if (onRoomJoined) {
          onRoomJoined();
        }
        history.push("/room/" + roomId);
        modal.handleClose();
      }
    },
    "Join Room"
  ));
};
const CreateRoomModal = memo(
  forwardRef(
    ({ type, onRoomJoined, onExplicitClose, onOpen, onClose, open, trigger }, ref) => {
      const styles = withStyleHook(style);
      const [form, setForm] = useState(null);
      const [modal, setModal] = useState(null);
      const inPwa = ["fullscreen", "standalone", "minimal-ui"].some(
        (displaymode) => window.matchMedia("(display-mode: " + displaymode + ")").matches
      ) || location.href.startsWith("capacitor") || location.hostname === "localhost" || "standalone" in window.navigator && window.navigator.standalone;
      return /* @__PURE__ */ React.createElement(
        Modal,
        {
          preventClosing: true,
          open,
          size: "tiny",
          closeIcon: true,
          trigger: trigger && React.cloneElement(trigger, { ref }),
          onOpen,
          onClose: (e) => {
            if (e && onExplicitClose)
              onExplicitClose();
            if (onClose)
              onClose();
          },
          onModal: (modal2) => {
            setModal(modal2);
          }
        },
        !inPwa && /* @__PURE__ */ React.createElement(Modal.Header, { style: { display: "flex" } }, "Create a ", type === "public" ? "Public " : "", "Room"),
        /* @__PURE__ */ React.createElement(Modal.Content, null, inPwa ? /* @__PURE__ */ React.createElement(React.Fragment, null, !form && /* @__PURE__ */ React.createElement("div", { className: styles.buttonWrapper }, /* @__PURE__ */ React.createElement(
          Button,
          {
            inverted: true,
            icon: true,
            size: "l",
            style: {
              margin: "auto",
              marginBottom: 10,
              width: 300,
              height: 50
            },
            onClick: () => {
              setForm("createRoom");
            },
            color: "white"
          },
          /* @__PURE__ */ React.createElement(Icon, { name: "plus square", size: "large" }),
          /* @__PURE__ */ React.createElement("div", { className: styles.roomButtonText }, "Create a new Room")
        ), /* @__PURE__ */ React.createElement(
          Button,
          {
            inverted: true,
            style: {
              margin: "auto",
              marginTop: 10,
              width: 300,
              height: 50
            },
            icon: true,
            size: "l",
            onClick: () => {
              setForm("joinRoom");
            },
            color: "white"
          },
          /* @__PURE__ */ React.createElement(Icon, { name: "sign-in", size: "large" }),
          /* @__PURE__ */ React.createElement("div", { className: styles.roomButtonText }, "Join an existing Room")
        )), form && /* @__PURE__ */ React.createElement(
          Button,
          {
            color: "white",
            style: {
              marginTop: 20,
              marginBottom: 20,
              marginLeft: 0,
              marginRight: 0,
              maxWidth: 50
            },
            inverted: true,
            icon: true,
            onClick: () => setForm(null)
          },
          /* @__PURE__ */ React.createElement(Icon, { name: "chevron left" })
        ), form === "createRoom" && /* @__PURE__ */ React.createElement(FadeInTransition, null, /* @__PURE__ */ React.createElement(
          CreateRoomForm,
          {
            onRoomJoined,
            modal,
            type
          }
        )), form === "joinRoom" && /* @__PURE__ */ React.createElement(FadeInTransition, null, /* @__PURE__ */ React.createElement(JoinRoomForm, { onRoomJoined, modal }))) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
          CreateRoomForm,
          {
            type,
            onRoomJoined,
            modal
          }
        )))
      );
    }
  )
);
export default withDialogs(CreateRoomModal);
