"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation, useQuery } from "kosmi-sdk/gql";
import Hyperbeam from "@hyperbeam/web";
import { Button } from "kosmi-sdk/uikit";
import React, { useEffect, useRef, useState } from "react";
import { PaywallMessage } from "../Widgets/Premium";
import withCurrentMember from "./withCurrentMember";
import withMembers from "./withMembers";
import withRoomId from "./withRoomId";
const BROWSER_QUERY = gql(`
  query VirtualBrowserQuery($roomId: String!, $sessionId: String!) {
    virtualBrowser(roomId: $roomId, sessionId: $sessionId) {
      id
      embedUrl
    }
  }
`);
const BROWSER_CALLBACK = gql(`
  mutation BrowserCallback(
    $sessionId: String!
    $roomId: String!
    $frameUserId: String!
  ) {
    virtualBrowserCallback(
      sessionId: $sessionId
      roomId: $roomId
      frameUserId: $frameUserId
    ) {
      ok
    }
  }
`);
const HyperBeamFrame = ({
  src,
  roomId,
  sessionId,
  currentUser,
  onTabUpdated
}) => {
  const [browserCallback] = useMutation(BROWSER_CALLBACK);
  const iframeRef = useRef();
  const wrapperRef = useRef();
  const [showWarning, setShowWarning] = useState(false);
  const [hb, setHb] = useState(null);
  const isSubscribed = !!(currentUser == null ? void 0 : currentUser.isSubscribed);
  useEffect(() => {
    ;
    (() => __async(void 0, null, function* () {
      const iframe = iframeRef.current;
      const hb2 = yield Hyperbeam(iframe, src, {
        onDisconnect: () => {
        },
        onCloseWarning: (e) => {
          setShowWarning(!!e.deadline);
        }
      });
      if (onTabUpdated) {
        hb2.tabs.onUpdated.addListener(onTabUpdated);
      }
      setHb(hb2);
      iframe.focus();
      browserCallback({
        variables: { roomId, sessionId, frameUserId: hb2.userId }
      });
    }))();
  }, []);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        width: "100%",
        height: "100%"
      },
      ref: wrapperRef
    },
    showWarning && isSubscribed && /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "24px",
          zIndex: 1
        }
      },
      "Are you still there?",
      /* @__PURE__ */ React.createElement(
        Button,
        {
          onClick: () => {
            hb == null ? void 0 : hb.ping();
            setShowWarning(false);
          },
          style: { margin: 10 },
          color: "blue"
        },
        "Yes"
      )
    ),
    showWarning && !isSubscribed && /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundColor: "rgba(0,0,0,0.9)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "24px",
          zIndex: 1,
          textAlign: "center"
        }
      },
      /* @__PURE__ */ React.createElement(
        PaywallMessage,
        {
          message: "Please subscribe to Kosmi Premium to get unlimited time in the virtual browser",
          source: "Virtual Browser timeout"
        }
      )
    ),
    /* @__PURE__ */ React.createElement(
      "iframe",
      {
        className: "hyperbeam",
        style: {
          width: "100%",
          height: "100%",
          background: "#000"
        },
        frameBorder: "0",
        ref: iframeRef,
        src
      }
    )
  );
};
export default withMembers(
  withRoomId(
    withCurrentMember(
      ({ roomId, sessionId, currentMember, onTabUpdated, members }) => {
        const { data } = useQuery(BROWSER_QUERY, {
          variables: { roomId, sessionId }
        });
        const maxMembers = 15;
        const tooManyAdmins = members.filter((m) => (m == null ? void 0 : m.role) === "ADMIN").length >= maxMembers;
        const isAdmin = !tooManyAdmins && (currentMember == null ? void 0 : currentMember.role) === "ADMIN";
        if (members && members.length > maxMembers && !isAdmin) {
          return /* @__PURE__ */ React.createElement(
            "div",
            {
              style: {
                margin: "auto",
                backgroundColor: "rgba(255,0,0,0.5)",
                alignItems: "center",
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                fontSize: "18px"
              }
            },
            "Unfortunately the Virtual Browser can only be used with a maximum of ",
            maxMembers,
            " people at a time."
          );
        }
        const virtualBrowser = data == null ? void 0 : data.virtualBrowser;
        if (virtualBrowser == null ? void 0 : virtualBrowser.embedUrl)
          return /* @__PURE__ */ React.createElement(
            HyperBeamFrame,
            {
              key: [
                roomId,
                sessionId,
                virtualBrowser.embedUrl,
                currentMember == null ? void 0 : currentMember.role
              ].join(","),
              src: virtualBrowser.embedUrl,
              roomId,
              sessionId,
              onTabUpdated,
              currentUser: currentMember == null ? void 0 : currentMember.user
            }
          );
        return null;
      }
    )
  )
);
