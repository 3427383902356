"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { memo } from "react";
import { StyleSheet, View, Pressable, Platform, Text } from "react-native";
import { CheckBox, Switch } from "@rneui/themed";
import { getVar } from "../../components/helpers/colors";
const checkboxStyles = StyleSheet.create({
  checkBoxWrapperStyles: __spreadValues({
    display: "flex",
    padding: 10,
    fontSize: 14,
    flexDirection: "row"
  }, Platform.select({
    web: {
      cursor: "pointer"
    }
  })),
  checkboxInnerStyles: {
    display: "flex",
    marginRight: 10,
    flexDirection: "row"
  },
  checkboxStyles: {
    fontSize: 14
  },
  checkBoxLabel: {
    color: "inherit",
    fontFamily: "inherit"
  },
  checkboxBox: {
    backgroundColor: "rgba(0,0,0,0)",
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: 0
  },
  checkboxBoxInner: {
    backgroundColor: "rgba(0,0,0,1)",
    padding: 3
  }
});
export const Checkbox = memo(
  ({
    onChange,
    checked: _checked,
    defaultChecked,
    label,
    disabled,
    toggle
  }) => {
    const checked = _checked === void 0 ? defaultChecked : _checked;
    return /* @__PURE__ */ React.createElement(
      Pressable,
      {
        style: checkboxStyles.checkBoxWrapperStyles,
        onPress: (_) => {
          if (onChange) {
            onChange(null, { checked: !checked });
          }
        }
      },
      /* @__PURE__ */ React.createElement(View, { style: checkboxStyles.checkBoxWrapperStyles }, /* @__PURE__ */ React.createElement(View, { style: checkboxStyles.checkboxInnerStyles }, toggle ? /* @__PURE__ */ React.createElement(
        Switch,
        {
          trackColor: {
            false: getVar("primary-light-40"),
            true: getVar("secondary")
          },
          color: getVar("foreground"),
          disabled,
          onValueChange: (val) => {
            const data = { checked: val };
            if (onChange) {
              onChange(null, data);
            }
          },
          value: checked === true
        }
      ) : /* @__PURE__ */ React.createElement(
        CheckBox,
        {
          disabled,
          checkedColor: getVar("foreground"),
          checkedIcon: "check",
          onPress: () => {
            if (onChange) {
              onChange(null, { checked: !checked });
            }
          },
          checked: checked === true,
          size: 12,
          containerStyle: checkboxStyles.checkboxBox,
          wrapperStyle: checkboxStyles.checkboxBoxInner
        }
      )), /* @__PURE__ */ React.createElement(Text, { style: checkboxStyles.checkBoxLabel }, label))
    );
  }
);
