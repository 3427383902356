"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _helpers = require("kosmi-sdk/helpers");
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _ProfileModal = _interopRequireDefault(require("./ProfileModal"));
var _SettingsModal = _interopRequireDefault(require("./SettingsModal"));
var _index = require("../../index");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _LogoutButton = _interopRequireDefault(require("@/components/Widgets/LogoutButton"));
var _widgets = require("kosmi-sdk/widgets");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  loginButton: {
    "index__styles.loginButton": "index__styles.loginButton",
    position: "x1n2onr6",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    width: "x1exxlbk",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    margin: "x1suov85",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    height: "x5yr21d",
    transition: "x1jte46n",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  profileMenu: {
    "index__styles.profileMenu": "index__styles.profileMenu",
    width: "x1pju0fl",
    position: "x1n2onr6",
    display: "x78zum5",
    $$css: true
  }
};
const ProfileMenu = (0, _react.memo)(() => {
  const [showProfileModal, setShowProfileModal] = (0, _react.useState)(false);
  const currentUser = (0, _helpers.currentUserHook)();
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.profileMenu
  }, /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu, {
    open: showProfileModal,
    onClose: () => {
      setShowProfileModal(false);
    },
    trigger: /* @__PURE__ */_react.default.createElement(_index.TopBarButton, {
      onClick: () => setShowProfileModal(!showProfileModal)
    }, /* @__PURE__ */_react.default.createElement(_index.TopBarImage, {
      src: currentUser.avatarUrl
    }))
  }, /* @__PURE__ */_react.default.createElement(_ProfileModal.default, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu.Item, {
      icon: "user outline",
      text: "Account Settings"
    })
  }), /* @__PURE__ */_react.default.createElement(_SettingsModal.default, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu.Item, {
      icon: "setting",
      text: "App Settings"
    })
  }), /* @__PURE__ */_react.default.createElement(_LogoutButton.default, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu.Item, {
      icon: "sign out",
      text: "Log out"
    })
  })));
});
const LoginButtons = (0, _react.memo)(() => {
  const [showTooltip, setShowTooltip] = (0, _react.useState)(true);
  const currentUser = (0, _helpers.currentUserHook)();
  (0, _react.useEffect)(() => {
    if (currentUser.isAnonymous) {
      const interval = setInterval(() => {
        setShowTooltip(true);
      }, 1e3 * 60 * 20);
      return () => {
        clearInterval(interval);
      };
    }
  }, [setShowTooltip, showTooltip, currentUser == null ? void 0 : currentUser.isAnonymous]);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_widgets.LoginModal, {
    trigger: ({
      onClick
    }) => /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
      open: currentUser.isAnonymous && showTooltip,
      content: "By logging in you can change your name, avatar, add friends and sync your rooms across devices.",
      onClickOutside: () => {
        setShowTooltip(false);
      },
      onClick: () => {
        setShowTooltip(false);
      },
      trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        color: "white",
        inverted: true,
        className: styles.loginButton,
        onClick
      }, "Login")
    })
  }), /* @__PURE__ */_react.default.createElement(_widgets.LoginModal, {
    signup: true,
    trigger: ({
      onClick
    }) => /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "white",
      inverted: true,
      className: styles.loginButton,
      onClick
    }, "Signup")
  }));
});
var _default = exports.default = (0, _react.memo)(() => {
  const currentUser = (0, _helpers.currentUserHook)();
  if (currentUser.isAnonymous) return /* @__PURE__ */_react.default.createElement(LoginButtons, null);
  return /* @__PURE__ */_react.default.createElement(ProfileMenu, null);
});