"use strict";
import { withCurrentUser } from "kosmi-sdk/helpers";
import React, { useEffect, useRef, useState } from "react";
import { withStyle } from "../../../../../../sdk/styles";
import withRoomId from "../../../../withRoomId";
import KosmiHead from "./kosmihead.svg";
import KomsiioText from "./kosmiioText.svg";
import style from "./style.css";
const RoomBanner = ({ roomId, showRoomUrl, styles }) => {
  const [faded, setFaded] = useState(false);
  const splashTimeoutRef = useRef(null);
  useEffect(() => {
    if (splashTimeoutRef.current)
      clearTimeout(splashTimeoutRef.current);
    splashTimeoutRef.current = setTimeout(() => setFaded(true), 2e3);
  }, []);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { style: { opacity: faded ? 0 : 1 }, className: styles.splashOverlay }, /* @__PURE__ */ React.createElement("img", { src: KosmiHead, width: "300" }), /* @__PURE__ */ React.createElement("img", { src: KomsiioText, width: "300" })), showRoomUrl && /* @__PURE__ */ React.createElement("div", { className: styles.roomTab }, /* @__PURE__ */ React.createElement("div", null, "kosmi.app/", roomId)));
};
export default withRoomId(withCurrentUser(withStyle(style)(RoomBanner)));
