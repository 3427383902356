"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ContextMenu, Icon, Image } from "kosmi-sdk/uikit";
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../../../../../../../../AppContext";
import { withStyleHook } from "../../../../../../../../sdk/styles";
import { useIsMobile } from "../../../../../../../helpers/withMobileDetection";
import { useTracking } from "../../../../../../../helpers/withTracking";
import CreateRoomModal from "../../../../../../../Widgets/CreateRoomModal";
import style from "./style.css";
import { OnboardingTooltip } from "../../../../../../../Widgets/OnboardingTooltip";
import globeIcon from "./globe.svg";
import Rooms, { useUserRoomDataCached } from "./Rooms";
import LoginWall from "../../../../../../../Widgets/LoginWall";
const useCurrentRoom = () => {
  var _a, _b;
  const { data } = useUserRoomDataCached();
  const location = useLocation();
  const anyUnreadMessages = !!((_a = data == null ? void 0 : data.userRooms) == null ? void 0 : _a.find(
    (r) => (r == null ? void 0 : r.unreadMessages) && r.unreadMessages > 0
  ));
  let result = null;
  if (location.pathname === "/lobby") {
    result = {
      currentRoom: {
        id: "lobby",
        state: {
          metadata: {
            roomName: "Lobby"
          }
        }
      },
      anyUnreadMessages
    };
  } else if (data == null ? void 0 : data.userRooms) {
    const room = {
      currentRoom: (_b = data == null ? void 0 : data.userRooms) == null ? void 0 : _b.map((r) => {
        var _a2;
        return __spreadProps(__spreadValues({}, r), { id: (_a2 = r == null ? void 0 : r.id) == null ? void 0 : _a2.replace(/^@\w+\//, "") });
      }).find((r) => {
        const roomId = r.id;
        const prefixedLink = `/room/${roomId}`;
        const link = `/${roomId}`;
        return link === location.pathname || prefixedLink === location.pathname;
      }),
      anyUnreadMessages
    };
    if (room)
      result = room;
  }
  return result;
};
const RoomMenuLabel = memo(() => {
  var _a, _b;
  const styles = withStyleHook(style);
  const { currentRoom, anyUnreadMessages } = useCurrentRoom() || {};
  const { roomName, avatarUrl } = currentRoom ? (_a = currentRoom == null ? void 0 : currentRoom.state) == null ? void 0 : _a.metadata : {};
  return /* @__PURE__ */ React.createElement(React.Fragment, null, currentRoom && /* @__PURE__ */ React.createElement(
    Image,
    {
      src: ((_b = currentRoom == null ? void 0 : currentRoom.id) == null ? void 0 : _b.endsWith("lobby")) ? globeIcon : avatarUrl,
      className: styles.roomIcon,
      circular: true,
      loading: "lazy"
    }
  ), !currentRoom && /* @__PURE__ */ React.createElement("div", { className: styles.roomTitle }, "My rooms..."), currentRoom && /* @__PURE__ */ React.createElement("div", { className: styles.roomTitle }, roomName), anyUnreadMessages && /* @__PURE__ */ React.createElement(Icon, { name: "circle", className: styles.unreadMessages }));
});
export default memo(() => {
  var _a;
  const appContext = useContext(AppContext);
  const { roomsDropdownOpen, setTutorialStep, tutorial, toggleRoomsDropdown } = appContext;
  const [createRoomModalOpen, setCreateRoomModalOpen] = useState(false);
  const track = useTracking();
  const { isMobile } = useIsMobile();
  const location = useLocation();
  const styles = withStyleHook(style);
  const ref = useRef(null);
  const { data } = useUserRoomDataCached();
  const currentRoomId = location.pathname.split("/room/")[1];
  const prevRoomIdRef = useRef(currentRoomId);
  const roomCount = ((_a = data == null ? void 0 : data.userRooms) == null ? void 0 : _a.length) || 0;
  const onClose = useMemo(
    () => () => {
      if (roomsDropdownOpen) {
        toggleRoomsDropdown();
      }
    },
    [roomsDropdownOpen, toggleRoomsDropdown]
  );
  useEffect(() => {
    if (currentRoomId !== prevRoomIdRef.current) {
      prevRoomIdRef.current = currentRoomId;
      onClose();
    }
  }, [currentRoomId, onClose]);
  const classNames = [styles.roomsDropdown];
  if (roomCount > 10) {
    classNames.push(styles.tall);
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    CreateRoomModal,
    {
      onClose: () => setCreateRoomModalOpen(false),
      onOpen: () => track(
        "UI",
        "CreateRoomModalOpenedFromTopBar",
        "CreateRoom Modal Opened from Top Bar"
      ),
      open: createRoomModalOpen
    }
  ), /* @__PURE__ */ React.createElement(
    ContextMenu,
    {
      onClose: () => onClose(),
      fluid: true,
      position: "center",
      open: roomsDropdownOpen,
      trigger: /* @__PURE__ */ React.createElement(
        OnboardingTooltip,
        {
          key: currentRoomId,
          flowing: true,
          content: /* @__PURE__ */ React.createElement("div", { className: styles.roomOnboardingMessage }, /* @__PURE__ */ React.createElement("div", null, "You can customize your room name and icon from the room settings."), /* @__PURE__ */ React.createElement("div", null, "The room is permanent and you can come back to it anytime!")),
          step: 1,
          totalSteps: 3,
          onClick: () => setTutorialStep(2),
          onSkip: () => {
            setTutorialStep(4);
            track("UI", "OnboardingSkipped", "Onboarding skipped");
          },
          open: !isMobile && tutorial.roomId === currentRoomId && tutorial.step === 1,
          trigger: /* @__PURE__ */ React.createElement(LoginWall, null, /* @__PURE__ */ React.createElement(
            "div",
            {
              ref,
              className: styles.roomName,
              onMouseEnter: () => {
                document.body.classList.add("roomSelectionHover");
              },
              onMouseLeave: () => {
                document.body.classList.remove("roomSelectionHover");
              },
              onMouseDown: () => {
                document.body.classList.add("roomSelectionClick");
              },
              onMouseUp: () => {
                document.body.classList.remove("roomSelectionClick");
              },
              onClick: () => {
                toggleRoomsDropdown();
              }
            },
            /* @__PURE__ */ React.createElement(RoomMenuLabel, null),
            /* @__PURE__ */ React.createElement(Icon, { name: "caret down" })
          ))
        }
      )
    },
    /* @__PURE__ */ React.createElement("div", { className: styles.roomsDropdownWrapper }, /* @__PURE__ */ React.createElement("div", { className: classNames.join(" ") }, /* @__PURE__ */ React.createElement(
      Rooms,
      {
        visible: roomsDropdownOpen,
        onRoomSelected: () => onClose()
      }
    )))
  ));
});
