"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation } from "kosmi-sdk/gql";
import { Button, Form, Settings, Message } from "kosmi-sdk/uikit";
import React, { useContext, useRef, useState } from "react";
import { ConnectionContext } from "../../ConnectionContext";
import SignupForm from "./Signup";
const styles = {
  loginContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  signupLoginMessage: {
    margin: "20px"
  },
  loginSection: {
    flex: 1,
    minWidth: "200px",
    padding: "10px"
  },
  link: {
    color: "#fff377",
    cursor: "pointer",
    fontWeight: 800
  }
};
const LOGIN = gql(`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
    }
  }
`);
const VERIFY_EMAIL_CODE = gql(`
  mutation VerifyEmailCode2($email: String!, $code: String!) {
    verifyEmailCode(email: $email, code: $code) {
      ok
    }
  }
`);
const SEND_RESET_PASSWORD_CODE = gql(`
  mutation SendResetPasswordCode($email: String!) {
    sendResetPasswordCode(email: $email) {
      ok
    }
  }
`);
const RESET_PASSWORD = gql(`
  mutation ResetPassword($email: String!, $code: String!, $password: String!) {
    resetPassword(email: $email, code: $code, password: $password) {
      ok
    }
  }
`);
const ForgotPasswordForm = () => {
  const [error, setError] = useState("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [codeVerified, setCodeVerified] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const [verifyEmailCode, { loading: verifyEmailCodeLoading }] = useMutation(VERIFY_EMAIL_CODE);
  const [sendResetPasswordCode, { loading: sendResetPasswordCodeLoading }] = useMutation(SEND_RESET_PASSWORD_CODE);
  const errorMessage = /* @__PURE__ */ React.createElement(
    Message,
    {
      error: true,
      header: "Reset Password Failed!",
      content: error,
      onDismiss: () => setError("")
    }
  );
  if (codeVerified) {
    return /* @__PURE__ */ React.createElement(
      Form,
      {
        error: !!error,
        size: "small",
        key: "resetpasswordform",
        onSubmit: () => __async(void 0, null, function* () {
          try {
            yield resetPassword({
              variables: {
                code,
                email,
                password
              }
            });
          } catch (e) {
            if (e.graphQLErrors) {
              setError(e.graphQLErrors[0].message);
            }
          }
        })
      },
      errorMessage,
      /* @__PURE__ */ React.createElement(
        Form.Input,
        {
          placeholder: "Password",
          type: "password",
          value: password,
          large: true,
          onChange: (e) => {
            e.preventDefault();
            setPassword(e.target.value);
          }
        }
      ),
      /* @__PURE__ */ React.createElement(
        Form.Input,
        {
          placeholder: "Password Repeat",
          type: "password",
          large: true,
          value: passwordRepeat,
          onChange: (e) => {
            e.preventDefault();
            setPasswordRepeat(e.target.value);
          }
        }
      ),
      /* @__PURE__ */ React.createElement(
        Button,
        {
          color: "yellow",
          inverted: true,
          loading,
          type: "submit",
          disabled: !password || password !== passwordRepeat
        },
        "Reset Password"
      )
    );
  }
  if (emailSent) {
    return /* @__PURE__ */ React.createElement(
      Form,
      {
        error: !!error,
        size: "small",
        key: "codesubmit",
        onSubmit: () => __async(void 0, null, function* () {
          try {
            yield verifyEmailCode({
              variables: {
                code,
                email
              }
            });
            setCodeVerified(true);
          } catch (e) {
            if (e.graphQLErrors) {
              setError(e.graphQLErrors[0].message);
            }
          }
        })
      },
      errorMessage,
      /* @__PURE__ */ React.createElement(
        Message,
        {
          info: true,
          header: "Reset Code Sent!",
          content: `We've sent a reset code to ${email}`
        }
      ),
      /* @__PURE__ */ React.createElement(
        Form.Input,
        {
          placeholder: "Reset Password Code",
          value: code || "",
          onChange: (e) => {
            e.preventDefault();
            setCode(e.target.value);
          }
        }
      ),
      /* @__PURE__ */ React.createElement(
        Button,
        {
          color: "yellow",
          inverted: true,
          loading: verifyEmailCodeLoading,
          disabled: !code,
          type: "submit"
        },
        "Submit"
      )
    );
  }
  return /* @__PURE__ */ React.createElement(
    Form,
    {
      error: !!error,
      size: "small",
      key: "sendcodeform",
      onSubmit: () => __async(void 0, null, function* () {
        try {
          yield sendResetPasswordCode({
            variables: {
              email
            }
          });
          setEmailSent(true);
        } catch (e) {
          if (e.graphQLErrors) {
            setError(e.graphQLErrors[0].message);
          }
        }
      })
    },
    errorMessage,
    /* @__PURE__ */ React.createElement(
      Form.Input,
      {
        placeholder: "Email",
        type: "email",
        value: email,
        onChange: (e) => {
          e.preventDefault();
          setEmail(e.target.value);
        }
      }
    ),
    /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "yellow",
        inverted: true,
        loading: sendResetPasswordCodeLoading,
        disabled: !email,
        type: "submit",
        style: {
          margin: "auto"
        }
      },
      "Send Reset Password Code"
    )
  );
};
export default ({
  inviteCode,
  onLogin,
  signup
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);
  const { updateClient } = useContext(ConnectionContext);
  const [login, { loading }] = useMutation(LOGIN);
  const signupButtonRef = useRef(null);
  const forgotPasswordButtonRef = useRef(null);
  if (signup) {
    return /* @__PURE__ */ React.createElement(SignupForm, { inviteCode });
  }
  return /* @__PURE__ */ React.createElement(Settings.Container, { name: "Login" }, /* @__PURE__ */ React.createElement("div", { style: styles.loginContainer }, /* @__PURE__ */ React.createElement("div", { style: styles.loginSection }, /* @__PURE__ */ React.createElement(
    Form,
    {
      size: "small",
      onSubmit: () => __async(void 0, null, function* () {
        var _a;
        try {
          const { data } = yield login({
            variables: {
              username,
              password
            }
          });
          const token = (_a = data == null ? void 0 : data.login) == null ? void 0 : _a.token;
          if (token) {
            window.localStorage.setItem("token", token);
            updateClient();
            onLogin && onLogin();
          }
        } catch (e) {
          setLoginFailed(true);
        }
      })
    },
    /* @__PURE__ */ React.createElement(
      Settings.Input,
      {
        name: "Username or Email",
        value: username,
        setValue: setUsername
      }
    ),
    /* @__PURE__ */ React.createElement(
      Settings.Input,
      {
        name: "Password",
        value: password,
        setValue: setPassword,
        type: "password",
        autoFocus: false
      }
    ),
    /* @__PURE__ */ React.createElement(Form.Field, null, /* @__PURE__ */ React.createElement(
      "a",
      {
        style: {
          display: "block",
          float: "left",
          marginLeft: 0,
          marginBottom: 20,
          cursor: "pointer"
        },
        ref: forgotPasswordButtonRef
      },
      "Forgot your password?"
    )),
    /* @__PURE__ */ React.createElement(Form.Field, null, /* @__PURE__ */ React.createElement(
      Button,
      {
        type: "submit",
        color: "yellow",
        inverted: true,
        centered: true,
        size: "xl",
        style: {
          width: 200
        },
        disabled: !username || !password,
        loading
      },
      loginFailed ? "Login Failed!" : "Login"
    ))
  ), /* @__PURE__ */ React.createElement("div", { style: styles.signupLoginMessage }, /* @__PURE__ */ React.createElement("div", null, "Dont have an account?"), /* @__PURE__ */ React.createElement("div", { style: styles.link, ref: signupButtonRef }, "Click here to Signup!")))), /* @__PURE__ */ React.createElement(Settings.Section, { name: "Signup", trigger: signupButtonRef }, /* @__PURE__ */ React.createElement(SignupForm, { inviteCode })), /* @__PURE__ */ React.createElement(
    Settings.Section,
    {
      name: "Forgot Password",
      trigger: forgotPasswordButtonRef
    },
    /* @__PURE__ */ React.createElement(ForgotPasswordForm, null)
  ));
};
