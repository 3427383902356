"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DefaultTVPlaceholder = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _Player = _interopRequireDefault(require("../../../core/pages/room/components/Player"));
var _useMediaPlayerData = require("../../../core/pages/room/hooks/useMediaPlayerData");
var _useRoomData = require("../../../core/pages/room/hooks/useRoomData");
var _useIsAdmin = require("../../../core/pages/room/hooks/useIsAdmin");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useMobileDetection = require("@/hooks/useMobileDetection");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const getClassName = (...styleObj) => {
  const {
    className
  } = stylex.props(...styleObj);
  return className;
};
const gradientAnimation = "x1lrmoca-B";
const styles = {
  mediaButtonIcon: {
    "TV__styles.mediaButtonIcon": "TV__styles.mediaButtonIcon",
    marginRight: "xyo1k6t",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  tvPlaceholder: {
    "TV__styles.tvPlaceholder": "TV__styles.tvPlaceholder",
    position: "x1n2onr6",
    width: "xh8yej3",
    height: "x5yr21d",
    maxWidth: "x193iq5w",
    $$css: true
  },
  tvPlaceholderInner: {
    "TV__styles.tvPlaceholderInner": "TV__styles.tvPlaceholderInner",
    maxWidth: "x193iq5w",
    maxHeight: "xmz0i5r",
    objectFit: "xl1xv1r",
    width: "xh8yej3",
    height: "x5yr21d",
    $$css: true
  },
  tvPlaceholderDefault: {
    "TV__styles.tvPlaceholderDefault": "TV__styles.tvPlaceholderDefault",
    backgroundColor: "x128cn8a",
    "@media (min-width: 1000px)_aspectRatio": "x1r8mgf9",
    "@media (min-width: 1000px)_background": "x1lvv1om",
    "@media (min-width: 1000px)_backgroundAttachment": null,
    "@media (min-width: 1000px)_backgroundClip": null,
    "@media (min-width: 1000px)_backgroundColor": null,
    "@media (min-width: 1000px)_backgroundImage": null,
    "@media (min-width: 1000px)_backgroundOrigin": null,
    "@media (min-width: 1000px)_backgroundPosition": null,
    "@media (min-width: 1000px)_backgroundPositionX": null,
    "@media (min-width: 1000px)_backgroundPositionY": null,
    "@media (min-width: 1000px)_backgroundRepeat": null,
    "@media (min-width: 1000px)_backgroundSize": "x8vrxej",
    "@media (min-width: 1000px)_animationName": "xnk8mce",
    "@media (min-width: 1000px)_animationDuration": "x1k02usz",
    "@media (min-width: 1000px)_animationTimingFunction": "xaj3uy3",
    "@media (min-width: 1000px)_animationDirection": "xu18mf7",
    "@media (min-width: 1000px)_animationIterationCount": "x1c3h5a7",
    $$css: true
  },
  fullScreenTv: {
    "TV__styles.fullScreenTv": "TV__styles.fullScreenTv",
    display: "x1lliihq",
    width: "xh8yej3",
    height: "x5yr21d",
    zIndex: "x1vjfegm",
    top: "x13vifvy",
    minWidth: "xgqtt45",
    minHeight: "x1us19tq",
    $$css: true
  },
  tv: {
    "TV__styles.tv": "TV__styles.tv",
    position: "x10l6tqk",
    top: "xuilav5",
    width: "x5sxuk9",
    height: "xt7dq6l",
    aspectRatio: "xgwsg93",
    maxHeight: "xpepdf8",
    zIndex: "xhtitgo",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    borderWidth: "x9r2w2z",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xzgz5g3",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    display: "x78zum5",
    "@media (min-aspect-ratio: 16/10)_borderRadius": "xaf9lzt",
    "@media (min-aspect-ratio: 16/10)_borderStartStartRadius": null,
    "@media (min-aspect-ratio: 16/10)_borderStartEndRadius": null,
    "@media (min-aspect-ratio: 16/10)_borderEndStartRadius": null,
    "@media (min-aspect-ratio: 16/10)_borderEndEndRadius": null,
    "@media (min-aspect-ratio: 16/10)_borderTopLeftRadius": null,
    "@media (min-aspect-ratio: 16/10)_borderTopRightRadius": null,
    "@media (min-aspect-ratio: 16/10)_borderBottomLeftRadius": null,
    "@media (min-aspect-ratio: 16/10)_borderBottomRightRadius": null,
    $$css: true
  }
};
const DefaultTVPlaceholder = ({
  style
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [style, styles.tvPlaceholder, styles.tvPlaceholderDefault]
  });
};
exports.DefaultTVPlaceholder = DefaultTVPlaceholder;
var _default = exports.default = (0, _react.memo)(({
  fullScreen,
  setFullscreen
}) => {
  const isAdmin = (0, _useIsAdmin.useIsAdmin)();
  const spacesState = (0, _useRoomData.useSpacesStateCached)();
  const [player, setPlayer] = (0, _react.useState)(null);
  const {
    mediaPlayerState
  } = (0, _useMediaPlayerData.useMediaPlayerStateCached)();
  const {
    isMobile,
    isLandscape
  } = (0, _useMobileDetection.useIsMobile)();
  if (!spacesState) return null;
  return /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.tv, fullScreen && styles.fullScreenTv)
  }, /* @__PURE__ */_react.default.createElement(_Player.default, {
    visible: !!mediaPlayerState.url,
    forceFullScreen: !!fullScreen,
    onPlayer: setPlayer,
    onFullscreen: () => {
      if (isAdmin) {
        setFullscreen(fullScreen ? 0 : 1);
      }
    }
  }), !mediaPlayerState.url && /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.tvPlaceholder, !spacesState.tvPlaceholder && styles.tvPlaceholderDefault)
  }, (() => {
    var _a;
    if (!(isMobile && !isLandscape) && spacesState.tvPlaceholder) {
      if ((_a = spacesState.tvPlaceholder) == null ? void 0 : _a.endsWith(".mp4")) {
        return /* @__PURE__ */_react.default.createElement(_uikit.Html.video, {
          autoPlay: true,
          loop: true,
          src: spacesState.tvPlaceholder,
          muted: true,
          playsInline: true,
          style: styles.tvPlaceholderInner
        });
      } else {
        return /* @__PURE__ */_react.default.createElement(_uikit.Html.img, {
          src: spacesState.tvPlaceholder,
          style: styles.tvPlaceholderInner
        });
      }
    }
  })(), isAdmin && /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "blue",
    onClick: () => {
      player == null ? void 0 : player.loadMedia();
    },
    style: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: "10%",
      margin: "auto",
      width: "30%",
      height: "15%",
      minWidth: 200,
      minHeight: 50,
      alignItems: "center",
      justifyContent: "center"
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    size: "big",
    name: "kosmi mediabutton",
    className: styles.mediaButtonIcon
  }), "Select Media"))));
});