"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Icon, Image } from "kosmi-sdk/uikit";
import { getShorthandTime } from "kosmi-sdk/helpers";
import { withStyleHook } from "kosmi-sdk/styles";
import React, { memo, useContext, useEffect, useState } from "react";
import AVEngine from "../../../../../../core/services/AVEngine";
import Flag from "../../../../Widgets/Flag";
import SpeakingState from "../../../../Widgets/SpeakingState";
import { Diamond } from "../../../../Widgets/Premium";
import { UserProfileModal } from "../../../../Widgets";
import RoomContext from "../../../RoomContext";
import { useRoomId } from "../../../withRoomId";
import LoginWall from "../../../../Widgets/LoginWall";
import style from "./Members.css";
import {
  useMembers,
  useLinkedMembersCached
} from "../../../hooks/useMembersData";
const Member = memo(
  ({
    viewUserProfile,
    offline,
    styles,
    member,
    hasAudio,
    audioStreamError,
    audioLoading,
    hasVideo,
    isMuted,
    muteMicrophone,
    unmuteMicrophone,
    currentlySpeakingUserIds
  }) => {
    var _a, _b;
    return /* @__PURE__ */ React.createElement(LoginWall, null, /* @__PURE__ */ React.createElement(
      "div",
      {
        onClick: () => viewUserProfile(member.user.id),
        className: (offline ? [styles.user, styles.offline] : [styles.user]).join(" ")
      },
      /* @__PURE__ */ React.createElement("div", { className: styles.avatar }, /* @__PURE__ */ React.createElement(
        Image,
        {
          src: (_b = (_a = member == null ? void 0 : member.user) == null ? void 0 : _a.avatarUrl) == null ? void 0 : _b.replaceAll("=300", "=50"),
          size: "mini",
          circular: true,
          loading: "lazy"
        }
      ), member.user.isSubscribed && /* @__PURE__ */ React.createElement(
        Diamond,
        {
          style: {
            position: "absolute",
            bottom: -5,
            right: -4,
            left: "auto",
            marginBottom: 0,
            fontSize: 16
          }
        }
      )),
      /* @__PURE__ */ React.createElement(
        "div",
        {
          className: (member.user.isSubscribed ? [styles.username, styles.rubyGlow] : [styles.username]).join(" ")
        },
        member.role === "ADMIN" && /* @__PURE__ */ React.createElement(Icon, { name: "key", color: "yellow" }),
        member.user.displayName
      ),
      hasAudio && !audioStreamError && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
        Icon,
        {
          style: { cursor: "pointer" },
          onClick: (e) => {
            e.stopPropagation();
            if (!isMuted) {
              muteMicrophone(member.user.id);
            } else {
              unmuteMicrophone(member.user.id);
            }
          },
          name: isMuted ? "microphone slash" : "microphone"
        }
      ), /* @__PURE__ */ React.createElement(
        SpeakingState,
        {
          speaking: currentlySpeakingUserIds.includes(member.user.id)
        }
      )),
      audioStreamError && /* @__PURE__ */ React.createElement(Icon, { name: "remove" }),
      audioLoading && /* @__PURE__ */ React.createElement(Icon, { name: "sync" }),
      hasVideo && /* @__PURE__ */ React.createElement(Icon, { name: "video camera" }),
      /* @__PURE__ */ React.createElement("div", { className: styles.countryCode }, member.user.countryCode && /* @__PURE__ */ React.createElement(Flag, { name: member.user.countryCode.toLowerCase() }), offline && getShorthandTime(member.lastVisited))
    ));
  }
);
const MemberList = memo(
  ({
    offline,
    members,
    memberCount,
    microphoneStreams,
    webcamStreams,
    currentlySpeakingUserIds,
    mutedMicrophones,
    muteMicrophone,
    unmuteMicrophone,
    viewUserProfile
  }) => {
    const styles = withStyleHook(style);
    if (!(members == null ? void 0 : members.length))
      return null;
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: offline ? styles.userlist + " " + styles.offline : styles.userlist
      },
      /* @__PURE__ */ React.createElement(
        "div",
        {
          className: [
            styles.label,
            offline ? styles.offline : styles.online
          ].join(" ")
        },
        offline ? `Offline (${memberCount})` : `Online (${memberCount})`
      ),
      members.map((member) => {
        const audiostream = !offline && microphoneStreams && microphoneStreams[member.user.id];
        const videostream = !offline && webcamStreams && webcamStreams[member.user.id];
        const hasAudio = !offline && audiostream && audiostream.getAudioTracks && Boolean(audiostream.getAudioTracks().length);
        const audioLoading = !offline && audiostream && audiostream.id === "LOADING";
        const hasVideo = !offline && videostream && videostream.getVideoTracks && Boolean(videostream.getVideoTracks().length);
        const audioStreamError = audiostream && !audiostream.active;
        const isMuted = !!(mutedMicrophones && mutedMicrophones[member.user.id]);
        return /* @__PURE__ */ React.createElement(
          Member,
          {
            key: member.id,
            viewUserProfile,
            offline,
            styles,
            member,
            hasAudio,
            audioStreamError,
            audioLoading,
            hasVideo,
            isMuted,
            muteMicrophone,
            unmuteMicrophone,
            currentlySpeakingUserIds
          }
        );
      })
    );
  }
);
const Members = memo(({ visible }) => {
  const {
    microphoneStreams,
    webcamStreams,
    muteMicrophone,
    unmuteMicrophone,
    mutedMicrophones
  } = useContext(RoomContext);
  const roomId = useRoomId();
  const [currentlySpeakingUserIds, setCurrentlySpeakingUserIds] = useState(
    []
  );
  useEffect(() => {
    AVEngine.eventEmitter.on(
      "currently_speaking",
      updateCurrentlySpeaking,
      void 0
    );
    return () => {
      AVEngine.eventEmitter.removeListener(
        "currently_speaking",
        updateCurrentlySpeaking,
        void 0
      );
    };
  }, []);
  const updateCurrentlySpeaking = (speakingSet) => {
    setCurrentlySpeakingUserIds(Array.from(speakingSet));
  };
  const members = useMembers(roomId);
  const styles = withStyleHook(style);
  const { linkedMembers, linkedMemberCount } = useLinkedMembersCached(roomId);
  const [viewUserProfileId, setViewUserProfileId] = useState("");
  if (!visible)
    return null;
  return /* @__PURE__ */ React.createElement("div", { className: styles.membersWrapper }, /* @__PURE__ */ React.createElement(
    UserProfileModal,
    {
      userId: viewUserProfileId,
      open: !!viewUserProfileId,
      onClose: () => setViewUserProfileId("")
    }
  ), /* @__PURE__ */ React.createElement(
    MemberList,
    __spreadProps(__spreadValues({}, {
      microphoneStreams,
      webcamStreams,
      muteMicrophone,
      unmuteMicrophone,
      mutedMicrophones,
      members,
      viewUserProfile: (userId) => setViewUserProfileId(userId),
      currentlySpeakingUserIds
    }), {
      memberCount: members.length
    })
  ), /* @__PURE__ */ React.createElement(
    MemberList,
    __spreadProps(__spreadValues({}, {
      members: linkedMembers,
      viewUserProfile: (userId) => setViewUserProfileId(userId)
    }), {
      offline: true,
      memberCount: linkedMemberCount
    })
  ));
});
export default Members;
