"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { memo } from "react";
import { Button, Icon, Popup } from "kosmi-sdk/uikit";
import { withStyleHook } from "kosmi-sdk/styles";
import { Dimensions } from "kosmi-sdk/room";
import Seekbar from "./Seekbar";
import VolumeSlider from "./VolumeSlider";
import style from "./style.css";
const HintedButton = (_a) => {
  var _b = _a, { hint } = _b, props = __objRest(_b, ["hint"]);
  return /* @__PURE__ */ React.createElement(
    Popup,
    {
      content: hint,
      position: "top center",
      trigger: /* @__PURE__ */ React.createElement(
        Button,
        __spreadProps(__spreadValues({}, props), {
          style: {
            marginLeft: 5,
            marginRight: 5,
            pointerEvents: "all",
            width: 36,
            height: 36
          }
        })
      )
    }
  );
};
export default memo(
  Dimensions()(
    ({
      paused,
      timestamp,
      duration,
      url,
      isLive,
      isFullscreen,
      videoInfo,
      freeUpRightSide,
      controls: {
        play,
        pause,
        stop,
        loadMedia,
        showCcMenu,
        setVolume,
        seekTo,
        fullscreen,
        mute,
        unmute,
        toggleNativeControls
      },
      muted,
      volume,
      nativeControls,
      containerWidth,
      isCompact,
      isSmall
    }) => {
      const styles = withStyleHook(style);
      const rightButtons = /* @__PURE__ */ React.createElement(React.Fragment, null, showCcMenu && !isCompact && /* @__PURE__ */ React.createElement(
        HintedButton,
        {
          hint: "Subtitles",
          color: "blue",
          onClick: showCcMenu,
          icon: "closed captioning outline",
          disabled: !showCcMenu
        }
      ), fullscreen && !isCompact && /* @__PURE__ */ React.createElement(
        HintedButton,
        {
          hint: isFullscreen ? "Shrink into TV" : "Expand",
          icon: isFullscreen ? "compress" : "expand",
          color: "blue",
          onClick: fullscreen
        }
      ));
      if (toggleNativeControls && nativeControls) {
        return /* @__PURE__ */ React.createElement(
          Popup,
          {
            content: "Go back to Kosmi Controls",
            trigger: /* @__PURE__ */ React.createElement(
              Button,
              {
                style: {
                  borderWidth: 1,
                  position: "absolute",
                  left: 0,
                  right: 0,
                  marginLeft: "auto",
                  marginRight: "auto",
                  top: "10%",
                  width: "10%",
                  maxWidth: 50
                },
                color: "blue",
                onClick: () => {
                  toggleNativeControls();
                },
                icon: "caret down"
              }
            )
          }
        );
      }
      return /* @__PURE__ */ React.createElement("div", { className: styles.fullScreenControls }, !isLive && url && duration !== void 0 && timestamp !== void 0 && /* @__PURE__ */ React.createElement(
        Seekbar,
        {
          seekTo,
          disabled: !seekTo,
          position: timestamp,
          duration
        }
      ), /* @__PURE__ */ React.createElement("div", { className: styles.menu }, !isCompact && /* @__PURE__ */ React.createElement(
        HintedButton,
        {
          hint: "Load media",
          icon: "kosmi mediabutton",
          color: "blue",
          onClick: loadMedia,
          disabled: !loadMedia
        }
      ), !isCompact && /* @__PURE__ */ React.createElement(
        HintedButton,
        {
          hint: "Stop",
          disabled: !stop,
          icon: "stop",
          color: "blue",
          onClick: stop
        }
      ), paused && !isCompact && /* @__PURE__ */ React.createElement(
        HintedButton,
        {
          hint: "Play",
          disabled: !play,
          icon: "play",
          color: "blue",
          onClick: play
        }
      ), !paused && !isLive && !isCompact && /* @__PURE__ */ React.createElement(
        HintedButton,
        {
          hint: "Pause",
          disabled: !pause,
          icon: "pause",
          color: "blue",
          onClick: pause
        }
      ), freeUpRightSide && rightButtons, setVolume && unmute && mute && /* @__PURE__ */ React.createElement(
        VolumeSlider,
        {
          unmute,
          mute,
          muted,
          volume,
          setVolume
        }
      ), /* @__PURE__ */ React.createElement("div", { className: styles.middle }, !isSmall && toggleNativeControls && containerWidth > 300 && /* @__PURE__ */ React.createElement(
        Popup,
        {
          content: "Fallback to native controls",
          trigger: /* @__PURE__ */ React.createElement(
            Icon,
            {
              className: styles.toggleNativeControlsButton,
              style: { pointerEvents: "all" },
              onClick: () => {
                toggleNativeControls();
              },
              name: "caret up"
            }
          )
        }
      ), !isSmall && containerWidth > 400 && /* @__PURE__ */ React.createElement("div", { className: styles.videoInfo }, videoInfo)), !freeUpRightSide && rightButtons));
    }
  )
);
