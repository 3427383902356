"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var _a, _b;
import platform from "platform";
import { gql, useMutation } from "kosmi-sdk/gql";
import { Mutation } from "kosmi-sdk/gql";
import { Plugins } from "@capacitor/core";
import { OktaAuth } from "@okta/okta-auth-js";
import {
  SignInWithApple
} from "@capacitor-community/apple-sign-in";
import "@codetrix-studio/capacitor-google-auth";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Button, Icon, Settings } from "kosmi-sdk/uikit";
import React, { forwardRef, useContext, useEffect, useRef } from "react";
import { ConnectionContext } from "../../ConnectionContext";
import Modal from "./AnimatedModal";
import EmailLogin from "./EmailLogin";
import { withDialogs } from "kosmi-sdk/helpers";
let googleInitialized = false;
const isAndroid = ((_b = (_a = platform) == null ? void 0 : _a.os) == null ? void 0 : _b.family) === "Android";
const isLocalhost = location.hostname == "localhost";
const withOkta = (Component) => {
  if (location.host.endsWith(".kosmi.business")) {
    const realm = location.host.split(".kosmi.business")[0];
    if (realm === "reddit") {
      return (props) => {
        const oktaAuth = new OktaAuth({
          issuer: "https://reddit.okta.com/",
          clientId: "0oakjziut53urD5Jq2p7",
          scopes: ["openid profile email"],
          redirectUri: location.href
        });
        return /* @__PURE__ */ React.createElement(Component, __spreadProps(__spreadValues({}, props), { oktaAuth }));
      };
    }
  }
  return (props) => /* @__PURE__ */ React.createElement(Component, __spreadValues({}, props));
};
export const GoogleLoginButton = withDialogs(
  ({
    signup,
    onLogin,
    inviteCode,
    alert
  }) => {
    const { updateClient } = useContext(ConnectionContext);
    const [login, { loading }] = useMutation(GOOGLE_LOGIN);
    useEffect(() => {
      if (!googleInitialized) {
        googleInitialized = true;
        GoogleAuth.initialize();
      }
    }, []);
    return /* @__PURE__ */ React.createElement(
      Button,
      {
        size: "l",
        style: styles.loginButton,
        loading,
        color: "google plus",
        onClick: () => __async(void 0, null, function* () {
          var _a2, _b2;
          try {
            const result = yield Plugins.GoogleAuth.signIn();
            const accessToken = (_a2 = result == null ? void 0 : result.authentication) == null ? void 0 : _a2.accessToken;
            const { data } = yield login({
              variables: {
                token: accessToken,
                inviteCode
              }
            });
            const token = (_b2 = data == null ? void 0 : data.loginWithGoogle) == null ? void 0 : _b2.token;
            if (token) {
              window.localStorage.setItem("token", token);
              updateClient();
              onLogin && onLogin();
            }
          } catch (e) {
            console.error(e);
            if (alert)
              alert(JSON.stringify(e));
          }
        })
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "google" }),
      signup ? "Signup" : "Login",
      " with Google"
    );
  }
);
const OktaLoginButton = ({
  oktaAuth,
  signup,
  onLogin
}) => {
  const [oktaLogin, { loading: oktaLoading }] = useMutation(OKTA_LOGIN);
  const { updateClient } = useContext(ConnectionContext);
  return /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "l",
      style: styles.loginButton,
      color: "blue",
      loading: oktaLoading,
      onClick: () => __async(void 0, null, function* () {
        var _a2;
        yield oktaAuth.start();
        const {
          tokens: {
            accessToken: { accessToken, userinfoUrl }
          }
        } = yield oktaAuth.token.getWithPopup({
          prompt: "login"
        });
        const { data } = yield oktaLogin({
          variables: {
            token: accessToken,
            userInfoUrl: userinfoUrl
          }
        });
        const token = (_a2 = data == null ? void 0 : data.loginWithOkta) == null ? void 0 : _a2.token;
        if (token) {
          window.localStorage.setItem("token", token);
        }
        updateClient();
        onLogin && onLogin();
      })
    },
    /* @__PURE__ */ React.createElement(Icon, { name: "circle notch" }),
    signup ? "Signup" : "Login",
    " with Okta"
  );
};
const AppleLoginButton = ({
  signup,
  onLogin,
  inviteCode
}) => {
  const { updateClient } = useContext(ConnectionContext);
  if (isAndroid && isLocalhost) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(Mutation, { mutation: APPLE_LOGIN }, (login, { loading }) => /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "l",
      style: styles.loginButton,
      loading,
      color: "black",
      onClick: () => __async(void 0, null, function* () {
        try {
          const appleOptions = {
            clientId: "com.kosmi.kosmi.web",
            scopes: "email name",
            redirectURI: location.host.indexOf("kosmi.dev") !== -1 ? location.origin : "https://app.kosmi.io/"
          };
          const {
            response: { authorizationCode, identityToken }
          } = yield SignInWithApple.authorize(appleOptions);
          const parseJwt = (token2) => {
            const base64Url = token2.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(
              atob(base64).split("").map(function(c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              }).join("")
            );
            return JSON.parse(jsonPayload);
          };
          const { aud } = parseJwt(identityToken);
          const { data } = yield login({
            variables: {
              token: authorizationCode,
              native: appleOptions.clientId !== aud,
              inviteCode
            }
          });
          const token = data.loginWithApple.token;
          window.localStorage.setItem("token", token);
          updateClient();
          onLogin && onLogin();
        } catch (e) {
          console.error(e);
        }
      })
    },
    /* @__PURE__ */ React.createElement(Icon, { name: "apple" }),
    signup ? "Signup" : "Login",
    " with Apple"
  ));
};
const LoginForm = ({
  signup,
  oktaAuth,
  inviteCode,
  onLogin
}) => {
  const emailLoginButtonRef = useRef(null);
  return /* @__PURE__ */ React.createElement(Settings.Container, { name: signup ? "Signup" : "Login" }, /* @__PURE__ */ React.createElement("div", { style: styles.loginContainer }, /* @__PURE__ */ React.createElement(
    GoogleLoginButton,
    {
      inviteCode,
      signup: !!signup,
      onLogin: onLogin || (() => {
      })
    }
  ), oktaAuth && /* @__PURE__ */ React.createElement(
    OktaLoginButton,
    {
      onLogin: onLogin || (() => {
      }),
      oktaAuth,
      signup
    }
  ), /* @__PURE__ */ React.createElement(
    AppleLoginButton,
    {
      inviteCode,
      onLogin: onLogin || (() => {
      }),
      signup
    }
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "l",
      color: "white",
      style: styles.loginButton,
      ref: emailLoginButtonRef,
      inverted: true
    },
    /* @__PURE__ */ React.createElement(Icon, { name: "mail" }),
    signup ? "Signup" : "Login",
    " with Email"
  )), /* @__PURE__ */ React.createElement(
    Settings.Section,
    {
      name: signup ? "Email Signup" : "Email Login",
      trigger: emailLoginButtonRef
    },
    /* @__PURE__ */ React.createElement(
      EmailLogin,
      {
        onLogin: () => onLogin && onLogin(),
        inviteCode,
        signup: !!signup
      }
    )
  ));
};
export const LoginModalInner = withOkta(
  ({
    inviteCode,
    signup,
    oktaAuth,
    onLogin
  }) => {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          padding: "0em",
          maxWidth: "100%"
        }
      },
      /* @__PURE__ */ React.createElement(
        LoginForm,
        {
          onLogin: () => {
            onLogin && onLogin();
          },
          oktaAuth,
          signup,
          inviteCode
        }
      )
    );
  }
);
export const LoginModal = forwardRef(
  ({
    trigger: Trigger,
    signup,
    open,
    onClose
  }, ref) => {
    const [_modalOpen, setModalOpen] = React.useState(false);
    const modalOpen = open === void 0 ? _modalOpen : open;
    return /* @__PURE__ */ React.createElement(
      Modal,
      {
        ref,
        trigger: Trigger && /* @__PURE__ */ React.createElement(Trigger, { onClick: () => setModalOpen(true) }),
        open: modalOpen,
        onClose: onClose ? onClose : () => setModalOpen(false),
        size: "small",
        closeIcon: "close",
        className: "loginmodal",
        preventClosing: true
      },
      /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(LoginModalInner, { signup }))
    );
  }
);
const styles = {
  link: {
    color: "#fff377",
    cursor: "pointer",
    fontWeight: 800
  },
  signupLoginMessage: {
    margin: "20px"
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column"
  },
  loginSection: {
    flex: 1,
    minWidth: "200px",
    padding: "10px"
  },
  loginButton: {
    marginTop: 4,
    marginBottom: 4,
    marginLeft: "auto",
    marginRight: "auto",
    width: 400,
    maxWidth: "100%",
    height: 50,
    minHeight: 36
  }
};
const OKTA_LOGIN = gql(`
  mutation OktaLogin($token: String!, $userInfoUrl: String!) {
    loginWithOkta(token: $token, userInfoUrl: $userInfoUrl) {
      token
    }
  }
`);
const GOOGLE_LOGIN = gql(`
  mutation GoogleLogin($token: String!, $inviteCode: String) {
    loginWithGoogle(token: $token, inviteCode: $inviteCode) {
      token
    }
  }
`);
const APPLE_LOGIN = gql(`
  mutation AppleLogin($token: String!, $native: Boolean!, $inviteCode: String) {
    loginWithApple(token: $token, native: $native, inviteCode: $inviteCode) {
      token
    }
  }
`);
export default LoginModal;
