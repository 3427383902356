"use strict";
import React, { useEffect, useRef } from "react";
import { useTracking } from "../../helpers/withTracking";
import Modal from "../AnimatedModal";
import { AccountSection } from "../Mobile/Settings";
import { useLocation } from "react-router-dom";
export default ({ trigger }) => {
  const track = useTracking();
  const modalRef = useRef(null);
  const location = useLocation();
  const inited = useRef(true);
  useEffect(() => {
    var _a;
    if (!inited.current) {
      inited.current = true;
      return;
    }
    (_a = modalRef.current) == null ? void 0 : _a.close();
  }, [location.pathname]);
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      large: true,
      closeIcon: true,
      trigger,
      ref: modalRef,
      onOpen: () => {
        track("UI", "UserProfileModalOpened", "User Profile Modal Opened");
      }
    },
    /* @__PURE__ */ React.createElement(Modal.Header, { style: { display: "flex" } }, "Profile"),
    /* @__PURE__ */ React.createElement(
      Modal.Content,
      {
        style: {
          display: "flex",
          flexDirection: "column"
        }
      },
      /* @__PURE__ */ React.createElement(AccountSection, { dark: true })
    )
  );
};
