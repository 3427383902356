"use strict";
import withCurrentUser, { currentUserHook } from "../hooks/useCurrentUser";
import withDialogs from "../hooks/withDialogs";
import withMobileDetection from "../hooks/useMobileDetection";
import withTracking, { useTracking } from "../hooks/useTracking";
import withRoomId, { useRoomId } from "../pages/room/hooks/useRoomId";
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);
function getShorthandTime(timestamp) {
  const currentTime = Math.floor(Date.now() / 1e3);
  const timePassed = Math.abs(currentTime - timestamp);
  if (timePassed < 3600) {
    return `${Math.floor(timePassed / 60)}m`;
  } else if (timePassed < 86400) {
    return `${Math.floor(timePassed / 3600)}h`;
  } else {
    return `${Math.floor(timePassed / 86400)}d`;
  }
}
export {
  withDialogs,
  withCurrentUser,
  currentUserHook,
  withRoomId,
  withMobileDetection,
  withTracking,
  isMobile,
  useRoomId,
  useTracking,
  getShorthandTime
};
