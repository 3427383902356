"use strict";
import { gql, useQuery } from "kosmi-sdk/gql";
import { Table } from "kosmi-sdk/uikit";
import React, { memo } from "react";
import APPS from "../Room/apps";
import logo from "../Widgets/Navbar/logo.svg";
const ROOMS_QUERY = gql(`
  query RoomsQuery($minUsers: Int!) {
    rooms(minUsers: $minUsers) {
      id
      state {
        memberCount
        runningApp
      }
    }
  }
`);
const sum = (arr) => arr.reduce((a, b) => a + b, 0);
const minUsers = window.location.search.startsWith("?min=") ? parseInt(window.location.search.split("?min=")[1]) : 1;
const Dashboard = memo(() => {
  var _a, _b, _c;
  const { data } = useQuery(ROOMS_QUERY, { variables: { minUsers } });
  const appNames = Object.keys(APPS);
  if (!data)
    return null;
  const memberCount = (_b = (_a = data == null ? void 0 : data.rooms) == null ? void 0 : _a.map(
    (r) => {
      var _a2;
      return (_a2 = r == null ? void 0 : r.state) == null ? void 0 : _a2.memberCount;
    }
  )) == null ? void 0 : _b.reduce((a, b) => (a || 0) + (b || 0), 0);
  const roomCount = (_c = data == null ? void 0 : data.rooms) == null ? void 0 : _c.length;
  if (!roomCount)
    return null;
  const aggregatedData = appNames.map((appName) => {
    var _a2;
    const rooms = (_a2 = data == null ? void 0 : data.rooms) == null ? void 0 : _a2.filter(
      (r) => {
        var _a3;
        return ((_a3 = r == null ? void 0 : r.state) == null ? void 0 : _a3.runningApp) === appName;
      }
    );
    return {
      appName,
      userCount: sum(
        rooms == null ? void 0 : rooms.map(
          (r) => {
            var _a3;
            return (_a3 = r == null ? void 0 : r.state) == null ? void 0 : _a3.memberCount;
          }
        )
      ),
      roomCount: rooms == null ? void 0 : rooms.length
    };
  }).filter((r) => r.userCount > 0).sort((a, b) => b.userCount - a.userCount);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%"
      }
    },
    /* @__PURE__ */ React.createElement(Table, { striped: true, style: { maxWidth: "70%", margin: "auto" } }, /* @__PURE__ */ React.createElement(Table.Header, null, /* @__PURE__ */ React.createElement(Table.Row, null, /* @__PURE__ */ React.createElement(Table.Cell, null), /* @__PURE__ */ React.createElement(Table.Cell, null, "Connected Users"), /* @__PURE__ */ React.createElement(Table.Cell, null, "Room count"))), /* @__PURE__ */ React.createElement(Table.Body, null, /* @__PURE__ */ React.createElement(Table.Row, null, /* @__PURE__ */ React.createElement(Table.Cell, null, /* @__PURE__ */ React.createElement(
      "img",
      {
        src: logo,
        style: {
          width: "20%",
          margin: "auto"
        }
      }
    )), /* @__PURE__ */ React.createElement(Table.Cell, { style: { fontSize: "48px", paddingLeft: "0px" } }, memberCount), /* @__PURE__ */ React.createElement(Table.Cell, { style: { fontSize: "48px", paddingLeft: "0px" } }, roomCount)), aggregatedData.map((d) => /* @__PURE__ */ React.createElement(Table.Row, { key: d.appName }, /* @__PURE__ */ React.createElement(Table.Cell, null, d.appName), /* @__PURE__ */ React.createElement(Table.Cell, null, d.userCount), /* @__PURE__ */ React.createElement(Table.Cell, null, d.roomCount)))))
  );
});
export default Dashboard;
