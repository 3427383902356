"use strict";
import { Select } from "kosmi-sdk/uikit";
import React from "react";
const DateSelector = ({
  onChange,
  value: valueProp
}) => {
  const getDaysOfMonth = (monthNum) => {
    if (!monthNum)
      return 0;
    if ([4, 6, 9, 11].indexOf(monthNum) !== -1) {
      return 30;
    }
    if (monthNum === 2)
      return 29;
    return 31;
  };
  const value = valueProp || [null, null, null];
  let day = value[2];
  const [year, month] = [value[0], value[1]];
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 10
      }
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "10px"
        }
      },
      "Date of birth"
    ),
    /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Select,
      {
        style: { minWidth: "90px" },
        placeholder: "Month",
        onChange: (e) => {
          const value2 = parseInt(e.target.value);
          if (day && day > getDaysOfMonth(value2))
            day = getDaysOfMonth(value2);
          onChange([year, value2, day]);
        },
        options: MONTHS.map((m, i) => {
          return { text: m, value: i + 1 };
        })
      }
    ), /* @__PURE__ */ React.createElement(
      Select,
      {
        disabled: !month,
        style: {
          minWidth: "70px",
          marginLeft: "10px",
          marginRight: "10px"
        },
        placeholder: "Day",
        value: day ? day.toString() : void 0,
        onChange: (e) => {
          const value2 = parseInt(e.target.value);
          onChange([year, month, value2]);
        },
        options: [...Array(getDaysOfMonth(month || 0)).keys()].map((d) => ({
          text: d + 1,
          value: d + 1
        }))
      }
    ), /* @__PURE__ */ React.createElement(
      Select,
      {
        style: { minWidth: "90px" },
        placeholder: "Year",
        value: year ? year.toString() : void 0,
        onChange: (e) => {
          const value2 = parseInt(e.target.value);
          onChange([value2, month, day]);
        },
        options: [...Array(120).keys()].map((d) => {
          const year2 = (/* @__PURE__ */ new Date()).getFullYear() - d;
          return {
            value: year2,
            text: year2
          };
        })
      }
    ))
  );
};
export default DateSelector;
