"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMessages = exports.default = exports.PrivateMessageList = exports.MessagesScreen = exports.FriendsIcon = void 0;
var _gql = require("kosmi-sdk/gql");
var _usePersistedState = _interopRequireDefault(require("use-persisted-state"));
var _react = _interopRequireWildcard(require("react"));
var _uikit = require("kosmi-sdk/uikit");
var _ = require("../..");
var _FriendsDropdown = require("../FriendsDropdown");
var _client = require("@apollo/client");
var _helpers = require("kosmi-sdk/helpers");
var _useMobileDetection = require("@/hooks/useMobileDetection");
var _widgets = require("kosmi-sdk/widgets");
var _Messagebox = _interopRequireDefault(require("@/pages/room/components/Sidebar/components/Messagebox"));
var _Messages = _interopRequireDefault(require("@/pages/room/components/Sidebar/components/Messages"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _MobileContext = _interopRequireDefault(require("@/layout/Mobile/MobileContext"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
function isNotNull(value) {
  return value !== null;
}
const useCurrentChatUserIdState = (0, _usePersistedState.default)("friendlist-currentChatUserId");
const useOnlyOnlineState = (0, _usePersistedState.default)("friendlist-onlyOnline");
const ON_MESSAGE = (0, _gql.gql)(`
  subscription OnNewPrivateMessage {
    newPrivateMessage {
      message {
        id
        time
        body
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
          isSubscribed
        }
      }
      receivingUser {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
      }
      seen
    }
  }
`);
const ON_MESSAGE_FROM_USER = (0, _gql.gql)(`
  subscription NewPrivateMessageSubscription($userId: String!) {
    newPrivateMessageFromUser(userId: $userId) {
      seen
      receivingUser {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
      }
      message {
        id
        time
        body
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
          isSubscribed
        }
      }
    }
  }
`);
const ON_PRIVATE_MESSAGE_DELETED = (0, _gql.gql)(`
  subscription PrivateMessageDeletedSubsciption {
    privateMessageDeleted {
      previousMessage {
        latestMessage {
          id
          time
          body
        }
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
      deletedMessageId
    }
  }
`);
const ADD_FRIEND_MUTATION = (0, _gql.gql)(`
  mutation AddFriendMutation1($username: String!) {
    addFriend(username: $username) {
      id
      friends {
        state
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const REMOVE_FRIEND_MUTATION = (0, _gql.gql)(`
  mutation RemoveFriendMutation2($userId: String!) {
    removeFriend(userId: $userId) {
      id
      friends {
        state
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const MESSAGE_LIST = (0, _gql.gql)(`
  query MessageList {
    messageList {
      id
      unreadCount
      latestMessage {
        id
        time
        body
      }
      user {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
        isSubscribed
      }
    }
  }
`);
const ON_MESSAGE_LIST_UPDATE = (0, _gql.gql)(`
  subscription MessageListUpdate {
    messageListUpdate {
      id
      unreadCount
      latestMessage {
        id
        time
        body
      }
      user {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
      }
    }
  }
`);
const PRIVATE_MESSAGES = (0, _gql.gql)(`
  query PrivateMessageQuery($userId: String!, $cursor: String) {
    privateMessages(userId: $userId, cursor: $cursor) {
      forwardCursor
      backCursor
      results {
        id
        body
        time
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
          isSubscribed
        }
      }
    }
  }
`);
const SEND_MESSAGE = (0, _gql.gql)(`
  mutation SendPrivateMessage($body: String!, $userId: String!) {
    sendPrivateMessage(body: $body, toUserId: $userId) {
      ok
    }
  }
`);
const DELETE_PRIVATE_MESSAGE = (0, _gql.gql)(`
  mutation DetelePrivateMessage($privateMessageId: UUID!) {
    deletePrivateMessage(privateMessageId: $privateMessageId) {
      ok
    }
  }
`);
const glowingIcon = "x2u31rq-B";
const styles = {
  plus: {
    "index__styles.plus": "index__styles.plus",
    fontSize: "xosj86m",
    $$css: true
  },
  tabView: {
    "index__styles.tabView": "index__styles.tabView",
    width: "xh8yej3",
    $$css: true
  },
  checkbox: {
    "index__styles.checkbox": "index__styles.checkbox",
    zoom: "xiyzoji",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xq0ohe5",
    $$css: true
  },
  pleaseLogin: {
    "index__styles.pleaseLogin": "index__styles.pleaseLogin",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    flexDirection: "xdt5ytf",
    height: "x5yr21d",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    display: "x78zum5",
    $$css: true
  },
  glowingIcon: {
    "index__styles.glowingIcon": "index__styles.glowingIcon",
    color: "x16cd2qt",
    fontWeight: "xuv8nkb",
    animationName: "x19n8mo0",
    animationDuration: "xmg6eyc",
    animationTimingFunction: "x4hg4is",
    animationIterationCount: "xa4qsjk",
    animationDirection: "xpz12be",
    $$css: true
  },
  user: {
    "index__styles.user": "index__styles.user",
    height: "x1gnnpzl",
    margin: "xwj1hky",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    minHeight: "x1rz828m",
    maxHeight: "x990d50",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    position: "x1n2onr6",
    $$css: true
  },
  onlineCircle: {
    "index__styles.onlineCircle": "index__styles.onlineCircle",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    lineHeight: "xyptofi",
    paddingRight: "x1s7jvk7",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    position: "x10l6tqk",
    zIndex: "x1vjfegm",
    left: "x130dsm3",
    insetInlineStart: null,
    insetInlineEnd: null,
    bottom: "x1gh3lm5",
    cursor: "xt0e3qv",
    fontSize: "xvwhms9",
    $$css: true
  },
  hidden: {
    "index__styles.hidden": "index__styles.hidden",
    visibility: "xlshs6z",
    $$css: true
  },
  message: {
    "index__styles.message": "index__styles.message",
    display: "xt0psk2",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    whiteSpace: "xuxw1ft",
    $$css: true
  },
  avatar: {
    "index__styles.avatar": "index__styles.avatar",
    height: "x1gnnpzl",
    width: "x1849jeq",
    marginRight: "x1db2dqx",
    marginInlineStart: null,
    marginInlineEnd: null,
    "@media(max-width: 1000px)_height": "x1gmjqbg",
    "@media(max-width: 1000px)_width": "x1s1rvhm",
    "@media(max-width: 1000px)_marginRight": "x2llh2",
    "@media(max-width: 1000px)_marginInlineStart": null,
    "@media(max-width: 1000px)_marginInlineEnd": null,
    $$css: true
  },
  time: {
    "index__styles.time": "index__styles.time",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    fontSize: "xfifm61",
    color: "xh3659q",
    height: "x5yr21d",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    paddingRight: "xyfqnmn",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    paddingLeft: "xnm25rq",
    justifyContent: "x13a6bvl",
    "@media(max-width: 1000px)_fontSize": "xaid5v7",
    $$css: true
  },
  messageNumber: {
    "index__styles.messageNumber": "index__styles.messageNumber",
    position: "x10l6tqk",
    left: "x1rvfpmx",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x1jzctok",
    width: "x1a00udw",
    height: "xx3o462",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    background: "x7v9ooy",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    fontWeight: "x1xlr1w8",
    padding: "x46zyou",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    fontSize: "x1k6wstc",
    lineHeight: "x6kjo9u",
    $$css: true
  },
  userWrapper: {
    "index__styles.userWrapper": "index__styles.userWrapper",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    alignItems: "x6s0dn4",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  unread: {
    "index__styles.unread": "index__styles.unread",
    fontWeight: "x1xlr1w8",
    color: "x16cd2qt",
    $$css: true
  },
  messageList: {
    "index__styles.messageList": "index__styles.messageList",
    width: "xh8yej3",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    flexDirection: "xdt5ytf",
    $$css: true
  },
  latestMessage: {
    "index__styles.latestMessage": "index__styles.latestMessage",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    height: "x1wkxgih",
    maxHeight: "x1mu601j",
    textOverflow: "xlyipyv",
    position: "x1n2onr6",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    alignItems: "x6s0dn4",
    display: "x78zum5",
    $$css: true
  },
  messagesDropdownMenu: {
    "index__styles.messagesDropdownMenu": "index__styles.messagesDropdownMenu",
    position: "x1n2onr6",
    height: "x16nrsnc",
    width: "xdzyupr",
    maxHeight: "x1phlbz0",
    $$css: true
  },
  messagesDropdown: {
    "index__styles.messagesDropdown": "index__styles.messagesDropdown",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x1cy8zhl",
    height: "x5yr21d",
    width: "xh8yej3",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  actions: {
    "index__styles.actions": "index__styles.actions",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xaw8158",
    width: "x16grhtn",
    background: "x1odt36a",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "xyorhqc",
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  acceptFriendRequestIcon: {
    "index__styles.acceptFriendRequestIcon": "index__styles.acceptFriendRequestIcon",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  removeButton: {
    "index__styles.removeButton": "index__styles.removeButton",
    padding: "xn1qhxa",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  privateChat: {
    "index__styles.privateChat": "index__styles.privateChat",
    backgroundImage: "xvixog2",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xh8yej3",
    height: "xqtp20y",
    position: "x1n2onr6",
    textAlign: "xdpxx8g",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  body: {
    "index__styles.body": "index__styles.body",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    position: "x1n2onr6",
    userSelect: "x1hx0egp",
    $$css: true
  },
  messageBox: {
    "index__styles.messageBox": "index__styles.messageBox",
    display: "x78zum5",
    $$css: true
  },
  titlebar: {
    "index__styles.titlebar": "index__styles.titlebar",
    display: "x78zum5",
    background: "x1dq10d1",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    width: "xh8yej3",
    height: "x1vqgdyp",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    "@media(max-width: 1000px)_height": "x10w29gr",
    "@media(max-width: 1000px)_fontSize": "xddx7y0",
    $$css: true
  },
  userBox: {
    "index__styles.userBox": "index__styles.userBox",
    position: "x10l6tqk",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    cursor: "x1ypdohk",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  backIcon: {
    "index__styles.backIcon": "index__styles.backIcon",
    cursor: "x1ypdohk",
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  },
  messagesDropdownWrapper: {
    "index__styles.messagesDropdownWrapper": "index__styles.messagesDropdownWrapper",
    position: "x1n2onr6",
    $$css: true
  },
  friendsHeader: {
    "index__styles.friendsHeader": "index__styles.friendsHeader",
    display: "x78zum5",
    width: "xh8yej3",
    justifyContent: "x1qughib",
    alignItems: "x6s0dn4",
    padding: "x6w2896",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    "@media(max-width: 1000px)_padding": "xqea0eg",
    "@media(max-width: 1000px)_paddingInline": null,
    "@media(max-width: 1000px)_paddingStart": null,
    "@media(max-width: 1000px)_paddingLeft": null,
    "@media(max-width: 1000px)_paddingEnd": null,
    "@media(max-width: 1000px)_paddingRight": null,
    "@media(max-width: 1000px)_paddingBlock": null,
    "@media(max-width: 1000px)_paddingTop": null,
    "@media(max-width: 1000px)_paddingBottom": null,
    "@media(max-width: 1000px)_fontSize": "x18yd3ym",
    $$css: true
  },
  friendListWrapper: {
    "index__styles.friendListWrapper": "index__styles.friendListWrapper",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    width: "xh8yej3",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  friendSection: {
    "index__styles.friendSection": "index__styles.friendSection",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xh8yej3",
    textAlign: "x2b8uid",
    backgroundColor: "x8qxh4v",
    height: "xg7h5cd",
    $$css: true
  },
  friendSectionTitle: {
    "index__styles.friendSectionTitle": "index__styles.friendSectionTitle",
    paddingTop: "x889kno",
    paddingBottom: "x1a8lsjc",
    $$css: true
  },
  usernameSearchWrapper: {
    "index__styles.usernameSearchWrapper": "index__styles.usernameSearchWrapper",
    height: "x18dl8mb",
    $$css: true
  },
  noMessages: {
    "index__styles.noMessages": "index__styles.noMessages",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    alignItems: "x6s0dn4",
    justifyContent: "x1l1ennw",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    maxHeight: "x1aoj0v",
    fontSize: "xosj86m",
    $$css: true
  }
};
const usePrivateMessages = userId => {
  var _a, _b;
  const {
    data,
    fetchMore,
    subscribeToMore,
    networkStatus
  } = (0, _gql.useQuery)(PRIVATE_MESSAGES, {
    variables: {
      userId
    },
    fetchPolicy: "cache-and-network",
    skip: !userId,
    notifyOnNetworkStatusChange: true
  });
  const privateMessages = ((_a = data == null ? void 0 : data.privateMessages) == null ? void 0 : _a.results) || [];
  const forwardCursor = (_b = data == null ? void 0 : data.privateMessages) == null ? void 0 : _b.forwardCursor;
  (0, _react.useEffect)(() => {
    if (!userId) return;
    const unsubscribeToMessages = subscribeToMore({
      document: ON_MESSAGE_FROM_USER,
      variables: {
        userId
      },
      updateQuery: (prev, {
        subscriptionData: {
          data: subData
        }
      }) => {
        var _a2, _b2, _c, _d, _e;
        const newMessage = (_a2 = subData == null ? void 0 : subData.newPrivateMessageFromUser) == null ? void 0 : _a2.message;
        const sender = newMessage == null ? void 0 : newMessage.user;
        const receiver = (_b2 = subData == null ? void 0 : subData.newPrivateMessageFromUser) == null ? void 0 : _b2.receivingUser;
        if (userId === (sender == null ? void 0 : sender.id) || userId === (receiver == null ? void 0 : receiver.id)) {
          return __spreadProps(__spreadValues({}, prev), {
            privateMessages: __spreadProps(__spreadValues({}, prev.privateMessages), {
              results: newMessage ? (_d = (_c = prev.privateMessages) == null ? void 0 : _c.results) == null ? void 0 : _d.concat([newMessage]) : (_e = prev.privateMessages) == null ? void 0 : _e.results
            })
          });
        }
        return prev;
      }
    });
    const unsubscribeToMessageDeletion = subscribeToMore({
      document: ON_PRIVATE_MESSAGE_DELETED,
      updateQuery: (prev, {
        subscriptionData: {
          data: subData
        }
      }) => {
        var _a2, _b2, _c;
        const messageId = (_a2 = subData == null ? void 0 : subData.privateMessageDeleted) == null ? void 0 : _a2.deletedMessageId;
        const updated = (_c = (_b2 = prev == null ? void 0 : prev.privateMessages) == null ? void 0 : _b2.results) == null ? void 0 : _c.filter(message => message.id != messageId);
        const updatedState = __spreadProps(__spreadValues({}, prev), {
          privateMessages: __spreadProps(__spreadValues({}, prev.privateMessages), {
            results: updated
          })
        });
        return updatedState;
      }
    });
    return () => {
      unsubscribeToMessages();
      unsubscribeToMessageDeletion();
    };
  }, [subscribeToMore, userId]);
  const nextPage = () => __async(void 0, null, function* () {
    if (!forwardCursor) return;
    yield fetchMore({
      variables: {
        userId,
        cursor: forwardCursor
      },
      updateQuery: (prev, {
        fetchMoreResult
      }) => {
        var _a2, _b2, _c, _d, _e;
        if (!fetchMoreResult) return prev;
        return __spreadProps(__spreadValues({}, prev), {
          privateMessages: __spreadProps(__spreadValues({}, prev.privateMessages), {
            results: (_c = (_a2 = fetchMoreResult == null ? void 0 : fetchMoreResult.privateMessages) == null ? void 0 : _a2.results) == null ? void 0 : _c.concat(((_b2 = prev == null ? void 0 : prev.privateMessages) == null ? void 0 : _b2.results) || []),
            forwardCursor: (_d = fetchMoreResult == null ? void 0 : fetchMoreResult.privateMessages) == null ? void 0 : _d.forwardCursor,
            backCursor: (_e = fetchMoreResult == null ? void 0 : fetchMoreResult.privateMessages) == null ? void 0 : _e.forwardCursor
          })
        });
      }
    });
  });
  return {
    privateMessages,
    nextPage,
    loading: !data || networkStatus === 3
  };
};
const fixMessages = messages => (messages == null ? void 0 : messages.map(m => ({
  id: (m == null ? void 0 : m.id) || "",
  user: (m == null ? void 0 : m.user) || {
    id: "",
    displayName: "",
    avatarUrl: "",
    isSubscribed: false
  },
  body: (m == null ? void 0 : m.body) || "",
  time: (m == null ? void 0 : m.time) || 0
}))) || [];
const MessagesWithData = (0, _react.memo)(({
  loading,
  messages,
  nextPage,
  deleteMessage
}) => {
  return /* @__PURE__ */_react.default.createElement(_Messages.default, {
    loading,
    messages: fixMessages(messages),
    nextPage,
    deleteMessage: privateMessageId => __async(void 0, null, function* () {
      yield deleteMessage(privateMessageId);
    })
  });
});
const PrivateChat = (0, _helpers.withDialogs)((0, _react.memo)(({
  onClose,
  user,
  viewProfile,
  alert
}) => {
  var _a;
  const currentUser = (0, _helpers.currentUserHook)();
  const client = (0, _client.useApolloClient)();
  const friend = (_a = currentUser == null ? void 0 : currentUser.friends) == null ? void 0 : _a.find(f => f.user.id === (user == null ? void 0 : user.id));
  const friendAccepted = (friend == null ? void 0 : friend.state) === "ACCEPTED";
  const {
    privateMessages,
    nextPage,
    loading
  } = usePrivateMessages(user == null ? void 0 : user.id);
  const [sendMessage] = (0, _gql.useMutation)(SEND_MESSAGE);
  const [deleteMessage] = (0, _gql.useMutation)(DELETE_PRIVATE_MESSAGE);
  const [addFriend, {
    loading: addFriendLoading
  }] = (0, _gql.useMutation)(ADD_FRIEND_MUTATION);
  const [removeFriend, {
    loading: removeFriendLoading
  }] = (0, _gql.useMutation)(REMOVE_FRIEND_MUTATION);
  const {
    contextMenuRef
  } = (0, _react.useContext)(MessagesDropdownContext);
  (0, _react.useEffect)(() => {
    var _a2;
    const data = client.readQuery({
      query: MESSAGE_LIST
    });
    if (data == null ? void 0 : data.messageList) {
      client.writeQuery({
        query: MESSAGE_LIST,
        data: {
          messageList: (_a2 = data == null ? void 0 : data.messageList) == null ? void 0 : _a2.map(m => {
            var _a3;
            if (((_a3 = m == null ? void 0 : m.user) == null ? void 0 : _a3.id) === (user == null ? void 0 : user.id)) return __spreadProps(__spreadValues({}, m), {
              unreadCount: 0
            });
            return m;
          })
        }
      });
    }
  }, [client, user == null ? void 0 : user.id]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.privateChat
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.titlebar
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.backIcon,
    onClick: () => onClose(),
    name: "angle left",
    size: "big"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.userBox,
    onClick: () => viewProfile(user == null ? void 0 : user.id)
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "circle",
    color: "green",
    className: [styles.onlineCircle, !(user == null ? void 0 : user.isConnected) && styles.hidden]
  }), /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    src: user == null ? void 0 : user.avatarUrl,
    size: "mini",
    circular: true,
    className: styles.avatar,
    loading: "lazy"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, user == null ? void 0 : user.displayName))), /* @__PURE__ */_react.default.createElement(MessagesWithData, {
    key: user == null ? void 0 : user.id,
    loading,
    messages: fixMessages(privateMessages.filter(isNotNull)),
    nextPage,
    deleteMessage: privateMessageId => __async(void 0, null, function* () {
      yield deleteMessage({
        variables: {
          privateMessageId
        }
      });
    })
  }), friendAccepted ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messageBox
  }, /* @__PURE__ */_react.default.createElement(_Messagebox.default, {
    isPrivate: true,
    sendMessage: body => sendMessage({
      variables: {
        body,
        userId: user == null ? void 0 : user.id
      }
    }),
    emojiPickerMountNode: (contextMenuRef == null ? void 0 : contextMenuRef.current) || void 0
  })) : friend && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.actions
  }, friend.state === "REQUESTED" && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Friend requested"), friend.state === "PENDING" && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Friend request pending"), friend.state === "PENDING" && /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    loading: addFriendLoading,
    inverted: true,
    size: "xs",
    color: "white",
    disabled: !(user == null ? void 0 : user.username),
    onClick: e => __async(void 0, null, function* () {
      e.stopPropagation();
      try {
        yield addFriend({
          variables: {
            username: user == null ? void 0 : user.username
          }
        });
      } catch (e2) {
        alert(e2.message);
      }
    }),
    style: {
      borderRadius: 10,
      width: 25,
      height: 25,
      padding: 0,
      alignItems: "center",
      justifyContent: "center",
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 4,
      marginRight: 4
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.acceptFriendRequestIcon,
    name: "check"
  })), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    loading: removeFriendLoading,
    inverted: true,
    size: "xs",
    color: "white",
    onClick: e => __async(void 0, null, function* () {
      e.stopPropagation();
      onClose();
      yield removeFriend({
        variables: {
          userId: user == null ? void 0 : user.id
        }
      });
    }),
    style: {
      borderRadius: 10,
      width: 25,
      height: 25,
      padding: 0,
      alignItems: "center",
      justifyContent: "center",
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 4,
      marginRight: 4
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "remove"
  }))));
}));
const MessagesDropdown = (0, _react.memo)(({
  viewProfile,
  messageList,
  currentChatUserId,
  setCurrentChatUserId,
  onlyOnline,
  loading,
  setChoosingFriend
}) => {
  var _a;
  const currentChatUser = (_a = messageList.find(m => m.id === currentChatUserId)) == null ? void 0 : _a.user;
  const filteredMessageList = (0, _react.useMemo)(() => onlyOnline ? messageList.filter(m => {
    var _a2;
    return (_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.isConnected;
  }) : messageList, [onlyOnline, JSON.stringify(messageList)]);
  if (loading) return /* @__PURE__ */_react.default.createElement(_widgets.LoadingScreen, null);
  return /* @__PURE__ */_react.default.createElement(_uikit.TabView, {
    menu: false,
    forceIndex: currentChatUser ? 1 : 0,
    className: styles.tabView
  }, /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: "MessageList"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messageList
  }, messageList.length > 0 && /* @__PURE__ */_react.default.createElement(_uikit.MessageList, {
    items: (filteredMessageList == null ? void 0 : filteredMessageList.map((member, index) => {
      var _a2, _b;
      return {
        key: member.user.id,
        onClick: () => setCurrentChatUserId(member.user.id),
        title: member.user.displayName,
        content: ((_a2 = member.latestMessage) == null ? void 0 : _a2.body) || "",
        unread: member.unreadCount > 0,
        online: member.user.isConnected,
        avatarUrl: member.user.avatarUrl,
        timestamp: (_b = member.latestMessage) == null ? void 0 : _b.time,
        odd: index % 2 !== 0
      };
    })) || []
  }), (messageList == null ? void 0 : messageList.length) === 0 && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.noMessages
  }, "Add friends to use DMs!", /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "blue",
    onClick: () => {
      setChoosingFriend(true);
    }
  }, "Add a friend")))), /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: "PrivateChat"
  }, /* @__PURE__ */_react.default.createElement(PrivateChat, {
    user: currentChatUser,
    onClose: () => setCurrentChatUserId(null),
    viewProfile
  })));
});
const PrivateMessageList = exports.PrivateMessageList = (0, _react.memo)(({
  messageList,
  notifyOnUserOnline,
  loading
}) => {
  var _a;
  const [currentChatUserId, setCurrentChatUserId] = useCurrentChatUserIdState(null);
  const currentChatUser = (_a = messageList == null ? void 0 : messageList.find(m => m.id === currentChatUserId)) == null ? void 0 : _a.user;
  const [onlyOnline, setOnlyOnline] = useOnlyOnlineState(false);
  const currentUser = (0, _helpers.currentUserHook)();
  const [choosingFriend, setChoosingFriend] = (0, _react.useState)(false);
  const [viewUserProfileId, setViewUserProfileId] = (0, _react.useState)(null);
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const {
    setHideBottomMenu,
    overlayMenu
  } = (0, _react.useContext)(_MobileContext.default);
  (0, _react.useEffect)(() => {
    if (setHideBottomMenu && overlayMenu === "chats") {
      setHideBottomMenu(!!currentChatUser);
    }
  }, [currentChatUser, setHideBottomMenu, overlayMenu]);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_FriendsDropdown.UserPickerModal, {
    open: choosingFriend,
    onClose: () => {
      setChoosingFriend(false);
    }
  }), /* @__PURE__ */_react.default.createElement(_FriendsDropdown.UserProfileModal, {
    userId: viewUserProfileId,
    open: !!viewUserProfileId,
    onClose: () => {
      setViewUserProfileId(null);
    },
    currentUser
  }), !currentChatUser && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.friendsHeader
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Chats"), messageList && messageList.length > 0 && /* @__PURE__ */_react.default.createElement(_uikit.Checkbox, {
    className: styles.checkbox,
    defaultChecked: onlyOnline,
    label: "Only online",
    toggle: true,
    onChange: (_e, data) => {
      if (data.checked !== onlyOnline) {
        setOnlyOnline(!!data.checked);
      }
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    content: "Add a friend",
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "white",
      inverted: true,
      size: isMobile ? void 0 : "s",
      style: isMobile ? {
        borderWidth: 0
      } : {
        width: 50,
        height: 25,
        padding: 0,
        borderWidth: 0
      },
      onClick: () => {
        setChoosingFriend(true);
      }
    }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      name: "plus",
      className: styles.plus
    }))
  })), /* @__PURE__ */_react.default.createElement(MessagesDropdown, {
    loading,
    styles,
    notifyOnUserOnline,
    setCurrentChatUserId: currentChatUserId2 => setCurrentChatUserId(currentChatUserId2),
    currentChatUserId,
    onlyOnline,
    messageList,
    loggedIn: Boolean(currentUser && !currentUser.isAnonymous),
    viewingProfile: !!viewUserProfileId,
    currentUser,
    setChoosingFriend,
    viewProfile: userId => {
      setViewUserProfileId(userId);
    }
  }));
});
const MessagesScreen = exports.MessagesScreen = (0, _react.memo)(({
  refetchMessageList,
  messageList,
  loading
}) => {
  const currentUser = (0, _helpers.currentUserHook)();
  (0, _react.useEffect)(() => {
    if (refetchMessageList) {
      refetchMessageList();
    }
  }, []);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messagesDropdown
  }, (currentUser == null ? void 0 : currentUser.isAnonymous) ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.pleaseLogin
  }, "Please Log in to add friends", /* @__PURE__ */_react.default.createElement(_widgets.LoginModal, {
    trigger: ({
      onClick
    }) => /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      onClick,
      inverted: true,
      size: "xs",
      color: "white",
      style: {
        marginTop: 10
      }
    }, "Click here to log in")
  })) : /* @__PURE__ */_react.default.createElement(PrivateMessageList, {
    notifyOnUserOnline: true,
    messageList,
    loading
  }));
});
const useMessages = () => {
  const {
    loading,
    data,
    subscribeToMore,
    refetch
  } = (0, _gql.useQuery)(MESSAGE_LIST, {
    fetchPolicy: "cache-first"
  });
  const currentUser = (0, _helpers.currentUserHook)();
  (0, _react.useEffect)(() => {
    if (subscribeToMore) {
      const unsubscribeToMessageList = subscribeToMore({
        document: ON_MESSAGE_LIST_UPDATE,
        updateQuery: (prev, {
          subscriptionData: {
            data: data2
          }
        }) => {
          const {
            messageListUpdate
          } = data2;
          return __spreadProps(__spreadValues({}, prev), {
            messageList: messageListUpdate
          });
        }
      });
      const unsubscribetoMessages = subscribeToMore({
        document: ON_MESSAGE,
        updateQuery: (prev, {
          subscriptionData: {
            data: data2
          }
        }) => {
          var _a;
          const {
            newPrivateMessage
          } = data2;
          if (!newPrivateMessage) return prev;
          const {
            message,
            receivingUser,
            seen
          } = newPrivateMessage;
          const updated = __spreadProps(__spreadValues({}, prev), {
            messageList: (_a = prev == null ? void 0 : prev.messageList) == null ? void 0 : _a.map(item => {
              var _a2, _b, _c;
              if (item.user.id === ((_a2 = message == null ? void 0 : message.user) == null ? void 0 : _a2.id) || currentUser.id === ((_b = message == null ? void 0 : message.user) == null ? void 0 : _b.id) && (receivingUser == null ? void 0 : receivingUser.id) === ((_c = item == null ? void 0 : item.user) == null ? void 0 : _c.id)) {
                return __spreadProps(__spreadValues({}, item), {
                  latestMessage: message,
                  unreadCount: seen ? 0 : item.unreadCount + 1
                });
              }
              return item;
            }).sort((a, b) => {
              const aTime = a.latestMessage ? a.latestMessage.time : 0;
              const bTime = b.latestMessage ? b.latestMessage.time : 0;
              return bTime - aTime;
            })
          });
          return updated;
        }
      });
      const unsubscribetoDeletedMessages = subscribeToMore({
        document: ON_PRIVATE_MESSAGE_DELETED,
        updateQuery: (prev, {
          subscriptionData: {
            data: subData
          }
        }) => {
          const messageId = subData.privateMessageDeleted.deletedMessageId;
          const previousMessage = subData.privateMessageDeleted.previousMessage.latestMessage;
          return __spreadProps(__spreadValues({}, prev), {
            messageList: prev.messageList.map(elem => {
              var _a;
              if (((_a = elem == null ? void 0 : elem.latestMessage) == null ? void 0 : _a.id) == messageId) return __spreadProps(__spreadValues({}, elem), {
                latestMessage: previousMessage
              });
              return elem;
            })
          });
        }
      });
      return () => {
        unsubscribeToMessageList();
        unsubscribetoMessages();
        unsubscribetoDeletedMessages();
      };
    }
  }, [subscribeToMore]);
  const messageList = (data == null ? void 0 : data.messageList) || [];
  const unreadCount = messageList.reduce((acc, item) => acc + item.unreadCount, 0);
  return {
    loading,
    messageList,
    unreadCount,
    refetch
  };
};
exports.useMessages = useMessages;
const FriendsIcon = exports.FriendsIcon = (0, _react.forwardRef)(({
  unreadCount,
  onClick
}, ref) => {
  return /* @__PURE__ */_react.default.createElement(_.TopBarButton, {
    ref,
    onClick
  }, unreadCount > 0 && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messageNumber
  }, unreadCount), /* @__PURE__ */_react.default.createElement(_.TopBarIcon, {
    name: "comment",
    className: [unreadCount > 0 && styles.glowingIcon]
  }));
});
const MessagesDropdownContext = _react.default.createContext({
  contextMenuRef: null
});
const MessagesDropdownWrapper = (0, _react.memo)(() => {
  const {
    messageList,
    refetch: refetchMessageList,
    unreadCount
  } = useMessages();
  const [showDropdown, setShowDropdown] = (0, _react.useState)(false);
  const contextMenuRef = (0, _react.useRef)(null);
  return /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu, {
    open: showDropdown,
    onClose: () => setShowDropdown(false),
    position: "left",
    ref: contextMenuRef,
    trigger: /* @__PURE__ */_react.default.createElement(FriendsIcon, {
      onClick: () => {
        setShowDropdown(!showDropdown);
      },
      unreadCount
    })
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messagesDropdownMenu
  }, /* @__PURE__ */_react.default.createElement(MessagesDropdownContext.Provider, {
    value: {
      contextMenuRef
    }
  }, /* @__PURE__ */_react.default.createElement(MessagesScreen, {
    refetchMessageList,
    messageList
  }))));
});
var _default = exports.default = MessagesDropdownWrapper;