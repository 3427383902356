"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Html = _interopRequireDefault(require("./Html"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const slideBackandForth = "x1vx0qrx-B";
const styles = {
  topStripLoader: {
    "TopStripLoader__styles.topStripLoader": "TopStripLoader__styles.topStripLoader",
    height: "x36qwtl",
    zIndex: "xfo81ep",
    width: "xh8yej3",
    position: "x10l6tqk",
    top: "x13vifvy",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    animationName: "x1mbsb8t",
    animationDuration: "x1qy094b",
    animationTimingFunction: "x1esw782",
    animationDirection: "xpz12be",
    animationIterationCount: "xa4qsjk",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    $$css: true
  }
};
var _default = () => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.topStripLoader
  });
};
exports.default = _default;