"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _helpers = require("kosmi-sdk/helpers");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _AVEngine = _interopRequireDefault(require("../../../../../../core/services/AVEngine"));
var _Flag = _interopRequireDefault(require("../../../../../components/Widgets/Flag"));
var _SpeakingState = _interopRequireDefault(require("./SpeakingState"));
var _Premium = require("../../../../../components/Widgets/Premium");
var _UserProfileModal = _interopRequireDefault(require("../UserProfileModal"));
var _RoomContext = _interopRequireDefault(require("../../../contexts/RoomContext"));
var _useRoomId = require("../../../hooks/useRoomId");
var _LoginWall = _interopRequireDefault(require("../../../../../components/Widgets/LoginWall"));
var _useMembersData = require("../../../hooks/useMembersData");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  membersWrapper: {
    "Members__styles.membersWrapper": "Members__styles.membersWrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    justifyContent: "x1nhvcw1",
    height: "x5yr21d",
    $$css: true
  },
  user: {
    "Members__styles.user": "Members__styles.user",
    cursor: "x1ypdohk",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    minHeight: "x7ywyr2",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    fontSize: "x1j61zf2",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    ":hover_backgroundColor": "xqnq8ic",
    ":active_backgroundColor": "x1irydxw",
    $$css: true
  },
  username: {
    "Members__styles.username": "Members__styles.username",
    marginLeft: "x16ydxro",
    marginInlineStart: null,
    marginInlineEnd: null,
    width: "x1oysuqx",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    $$css: true
  },
  countryCode: {
    "Members__styles.countryCode": "Members__styles.countryCode",
    margin: "x1ghk45w",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  avatar: {
    "Members__styles.avatar": "Members__styles.avatar",
    position: "x1n2onr6",
    $$css: true
  },
  label: {
    "Members__styles.label": "Members__styles.label",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "x1anpbxc",
    marginBottom: "xyorhqc",
    display: "x78zum5",
    justifyContent: "x1qughib",
    padding: "x1p3i4rr",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderBottomWidth: "xso031l",
    borderBottomStyle: "x1q0q8m5",
    borderBottomColor: "xgxu88z",
    ":first-child_marginTop": "xveetc6",
    $$css: true
  },
  onlineLabel: {
    "Members__styles.onlineLabel": "Members__styles.onlineLabel",
    color: "x1ko3i6n",
    $$css: true
  },
  offlineLabel: {
    "Members__styles.offlineLabel": "Members__styles.offlineLabel",
    borderBottomWidth: "xso031l",
    borderBottomStyle: "x1q0q8m5",
    borderBottomColor: "xuq24mj",
    color: "x164e47y",
    opacity: "xbyyjgo",
    $$css: true
  },
  offlineUserlist: {
    "Members__styles.offlineUserlist": "Members__styles.offlineUserlist",
    opacity: "xbyyjgo",
    $$css: true
  },
  rubyGlow: {
    "Members__styles.rubyGlow": "Members__styles.rubyGlow",
    color: "xozz0dy",
    $$css: true
  },
  userlist: {
    "Members__styles.userlist": "Members__styles.userlist",
    "@media(max-width: 1000px)_fontSize": "xddx7y0",
    $$css: true
  },
  pointer: {
    "Members__styles.pointer": "Members__styles.pointer",
    cursor: "x1ypdohk",
    $$css: true
  },
  diamond: {
    "Members__styles.diamond": "Members__styles.diamond",
    position: "x10l6tqk",
    bottom: "xtzk6r2",
    right: "xzkxjte",
    insetInlineStart: null,
    insetInlineEnd: null,
    left: "x1t8mvi6",
    marginBottom: "xat24cr",
    fontSize: "x1j61zf2",
    $$css: true
  }
};
const Member = (0, _react.memo)(({
  viewUserProfile,
  offline,
  member,
  hasAudio,
  audioStreamError,
  audioLoading,
  hasVideo,
  isMuted,
  muteMicrophone,
  unmuteMicrophone,
  currentlySpeakingUserIds
}) => {
  var _a, _b;
  return /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    onClick: () => viewUserProfile(member.user.id),
    style: styles.user
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.avatar
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    src: (_b = (_a = member == null ? void 0 : member.user) == null ? void 0 : _a.avatarUrl) == null ? void 0 : _b.replaceAll("=300", "=50"),
    size: "mini",
    circular: true,
    loading: "lazy"
  }), member.user.isSubscribed && /* @__PURE__ */_react.default.createElement(_Premium.Diamond, {
    className: styles.diamond
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.username, member.user.isSubscribed && styles.rubyGlow]
  }, member.role === "ADMIN" && /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "key",
    color: "yellow"
  }), member.user.displayName), hasAudio && !audioStreamError && /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.pointer,
    onClick: e => {
      e.stopPropagation();
      if (!isMuted) {
        muteMicrophone(member.user.id);
      } else {
        unmuteMicrophone(member.user.id);
      }
    },
    name: isMuted ? "microphone slash" : "microphone"
  }), /* @__PURE__ */_react.default.createElement(_SpeakingState.default, {
    speaking: currentlySpeakingUserIds.includes(member.user.id)
  })), audioStreamError && /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "remove"
  }), audioLoading && /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "sync"
  }), hasVideo && /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "video camera"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.countryCode
  }, member.user.countryCode && /* @__PURE__ */_react.default.createElement(_Flag.default, {
    name: member.user.countryCode.toLowerCase()
  }), offline && (0, _helpers.getShorthandTime)(member.lastVisited))));
});
const MemberList = (0, _react.memo)(({
  offline,
  members,
  memberCount,
  microphoneStreams,
  webcamStreams,
  currentlySpeakingUserIds,
  mutedMicrophones,
  muteMicrophone,
  unmuteMicrophone,
  viewUserProfile
}) => {
  if (!(members == null ? void 0 : members.length)) return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.userlist, offline && styles.offlineUserlist]
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.label, offline ? styles.offlineLabel : styles.onlineLabel]
  }, offline ? `Offline (${memberCount})` : `Online (${memberCount})`), members.map(member => {
    const audiostream = !offline && microphoneStreams && microphoneStreams[member.user.id];
    const videostream = !offline && webcamStreams && webcamStreams[member.user.id];
    const hasAudio = !offline && audiostream && audiostream.getAudioTracks && Boolean(audiostream.getAudioTracks().length);
    const audioLoading = !offline && audiostream && audiostream.id === "LOADING";
    const hasVideo = !offline && videostream && videostream.getVideoTracks && Boolean(videostream.getVideoTracks().length);
    const audioStreamError = audiostream && !audiostream.active;
    const isMuted = !!(mutedMicrophones && mutedMicrophones[member.user.id]);
    return /* @__PURE__ */_react.default.createElement(Member, {
      key: member.id,
      viewUserProfile,
      offline,
      styles,
      member,
      hasAudio,
      audioStreamError,
      audioLoading,
      hasVideo,
      isMuted,
      muteMicrophone,
      unmuteMicrophone,
      currentlySpeakingUserIds
    });
  }));
});
const Members = (0, _react.memo)(({
  visible
}) => {
  const {
    microphoneStreams,
    webcamStreams,
    muteMicrophone,
    unmuteMicrophone,
    mutedMicrophones
  } = (0, _react.useContext)(_RoomContext.default);
  const roomId = (0, _useRoomId.useRoomId)();
  const [currentlySpeakingUserIds, setCurrentlySpeakingUserIds] = (0, _react.useState)([]);
  (0, _react.useEffect)(() => {
    _AVEngine.default.eventEmitter.on("currently_speaking", updateCurrentlySpeaking, void 0);
    return () => {
      _AVEngine.default.eventEmitter.removeListener("currently_speaking", updateCurrentlySpeaking, void 0);
    };
  }, []);
  const updateCurrentlySpeaking = speakingSet => {
    setCurrentlySpeakingUserIds(Array.from(speakingSet));
  };
  const members = (0, _useMembersData.useMembers)(roomId);
  const {
    linkedMembers,
    linkedMemberCount
  } = (0, _useMembersData.useLinkedMembersCached)(roomId);
  const [viewUserProfileId, setViewUserProfileId] = (0, _react.useState)("");
  if (!visible) return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.membersWrapper
  }, /* @__PURE__ */_react.default.createElement(_UserProfileModal.default, {
    userId: viewUserProfileId,
    open: !!viewUserProfileId,
    onClose: () => setViewUserProfileId("")
  }), /* @__PURE__ */_react.default.createElement(MemberList, __spreadProps(__spreadValues({}, {
    microphoneStreams,
    webcamStreams,
    muteMicrophone,
    unmuteMicrophone,
    mutedMicrophones,
    members,
    viewUserProfile: userId => setViewUserProfileId(userId),
    currentlySpeakingUserIds
  }), {
    memberCount: members.length
  })), /* @__PURE__ */_react.default.createElement(MemberList, __spreadProps(__spreadValues({}, {
    members: linkedMembers,
    viewUserProfile: userId => setViewUserProfileId(userId)
  }), {
    offline: true,
    memberCount: linkedMemberCount
  })));
});
var _default = exports.default = Members;