"use strict";
import { gql, useMutation, useQuery } from "kosmi-sdk/gql";
import { MessageList, Popup, Button, Icon } from "kosmi-sdk/uikit";
import style from "./style.css";
import React, { forwardRef, memo, useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useIsMobile } from "../../../../../../../helpers/withMobileDetection";
import { withStyleHook } from "kosmi-sdk/styles";
import CreateRoomModal from "../../../../../../../Widgets/CreateRoomModal";
import globeIcon from "./globe.svg";
const ON_USER_ROOMS_UPDATE = gql(`
  subscription OnRoomListUpdate {
    roomlistUpdate {
      id
      unreadMessages
      latestMessage {
        id
        body
        time
        user {
          displayName
        }
      }
      state {
        metadata {
          roomName
          avatarUrl
        }
        isLive
        members {
          id
          role
          user {
            id
            username
            displayName
            avatarUrl
            friends {
              state
              user {
                id
                displayName
                username
                avatarUrl
                email
                isConnected
              }
            }
          }
        }
      }
    }
  }
`);
export const USER_ROOMS = gql(`
  query UserRoomQuery {
    userRooms {
      id
      unreadMessages
      latestMessage {
        id
        time
        body
        user {
          displayName
        }
      }
      state {
        metadata {
          roomName
          avatarUrl
        }
        isLive
        members {
          id
          role
          user {
            id
            username
            displayName
            avatarUrl
            friends {
              state
              user {
                id
                displayName
                username
                avatarUrl
                email
                isConnected
              }
            }
          }
        }
      }
    }
  }
`);
const UNLINK_ROOM = gql(`
  mutation UnlinkRoom2($roomId: String!) {
    unlinkRoom(roomId: $roomId) {
      ok
    }
  }
`);
export const useUserRoomData = () => {
  const { data, refetch, subscribeToMore } = useQuery(USER_ROOMS, {
    fetchPolicy: "cache-first"
  });
  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: ON_USER_ROOMS_UPDATE,
      updateQuery: (_prev, { subscriptionData: { data: data2 } }) => {
        return { userRooms: data2.roomlistUpdate };
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return {
    data,
    refetch
  };
};
export const useUserRoomDataCached = () => {
  const { data } = useQuery(USER_ROOMS, {
    fetchPolicy: "cache-first"
  });
  return {
    data
  };
};
export default memo(
  forwardRef(
    ({
      onRoomSelected
    }, ref) => {
      const { isMobile } = useIsMobile();
      const location = useLocation();
      const { data } = useUserRoomData();
      const userRooms = (data == null ? void 0 : data.userRooms) || [];
      const roomItems = useMemo(() => {
        return userRooms.filter((r) => {
          const roomId = r.id.replace(/^@\w+\//, "");
          return !location.pathname.endsWith(roomId);
        }).map((r) => {
          var _a;
          const roomId = r.id.replace(/^@\w+\//, "");
          return {
            link: `/room/${roomId}`,
            roomId: r.id,
            key: r.id,
            isLive: r.state.isLive,
            roomName: r.state.metadata.roomName,
            unreadMessages: r.unreadMessages,
            avatarUrl: r.state.metadata.avatarUrl,
            memberAvatars: (_a = r.state.members) == null ? void 0 : _a.map((m) => m.user.avatarUrl),
            latestMessage: r.latestMessage
          };
        });
      }, [
        JSON.stringify(
          userRooms.map((r) => {
            var _a, _b, _c, _d, _e, _f, _g;
            return [
              r == null ? void 0 : r.id,
              (_a = r == null ? void 0 : r.state) == null ? void 0 : _a.isLive,
              (_c = (_b = r == null ? void 0 : r.state) == null ? void 0 : _b.metadata) == null ? void 0 : _c.roomName,
              r == null ? void 0 : r.unreadMessages,
              (_e = (_d = r == null ? void 0 : r.state) == null ? void 0 : _d.metadata) == null ? void 0 : _e.avatarUrl,
              (_g = (_f = r == null ? void 0 : r.state) == null ? void 0 : _f.members) == null ? void 0 : _g.map((m) => m.user.avatarUrl)
            ];
          })
        ),
        isMobile,
        location.pathname
      ]);
      const history = useHistory();
      const [unlinkRoom] = useMutation(UNLINK_ROOM);
      const currentRoomId = location.pathname.split("/room/")[1];
      const styles = withStyleHook(style);
      const renderItems = useMemo(
        () => roomItems.map(
          ({
            link,
            isLive,
            roomName,
            latestMessage,
            unreadMessages,
            avatarUrl,
            memberAvatars,
            roomId
          }) => {
            var _a;
            return {
              key: roomId,
              onClick: () => {
                history.push(link);
                onRoomSelected && onRoomSelected();
              },
              title: roomName,
              content: latestMessage && (((_a = latestMessage.user) == null ? void 0 : _a.displayName) || "User") + ": " + (latestMessage == null ? void 0 : latestMessage.body),
              unread: unreadMessages > 0,
              avatarUrl,
              memberAvatars,
              timestamp: latestMessage == null ? void 0 : latestMessage.time,
              contextMenu: [
                {
                  action: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    unlinkRoom({ variables: { roomId } });
                  },
                  icon: "remove",
                  text: "Unlink"
                }
              ],
              inActive: !isLive
            };
          }
        ).concat(
          currentRoomId !== "lobby" ? [
            {
              key: "lobby",
              onClick: () => {
                history.push("/room/lobby");
                onRoomSelected && onRoomSelected();
              },
              title: "No one online?",
              content: "Join the public lobby!",
              unread: false,
              avatarUrl: globeIcon,
              memberAvatars: [],
              timestamp: null,
              inActive: false,
              contextMenu: []
            }
          ] : []
        ),
        [JSON.stringify(roomItems), onRoomSelected]
      );
      return /* @__PURE__ */ React.createElement("div", { className: styles.rooms }, /* @__PURE__ */ React.createElement("div", { className: styles.dropdownHeader }, "Rooms", /* @__PURE__ */ React.createElement("div", { className: styles.buttons }, currentRoomId !== "lobby" && /* @__PURE__ */ React.createElement(
        Popup,
        {
          disabled: isMobile,
          trigger: /* @__PURE__ */ React.createElement(
            Button,
            {
              color: "white",
              inverted: true,
              borderLess: true,
              size: "l",
              onClick: () => {
                history.push("/room/lobby");
              }
            },
            /* @__PURE__ */ React.createElement(Icon, { name: "globe" })
          ),
          content: "Lobby"
        }
      ), /* @__PURE__ */ React.createElement(
        Popup,
        {
          content: "Create a room",
          disabled: isMobile,
          trigger: /* @__PURE__ */ React.createElement(
            CreateRoomModal,
            {
              trigger: /* @__PURE__ */ React.createElement(Button, { color: "white", inverted: true, borderLess: true, size: "l" }, /* @__PURE__ */ React.createElement(Icon, { name: "plus" }))
            }
          )
        }
      ))), /* @__PURE__ */ React.createElement("div", { className: styles.itemsWrapper }, /* @__PURE__ */ React.createElement(MessageList, { large: true, ref, items: renderItems })));
    }
  )
);
