"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect, memo, useRef } from "react";
import ReactPlayer from "react-player";
import canAutoPlay from "can-autoplay";
import { Icon } from "kosmi-sdk/uikit";
import { withStyleHook } from "kosmi-sdk/styles";
import AVEngine from "../../../../../../core/services/AVEngine";
import CineSendPlayer from "./Cinesend";
import VBrowserPlayer from "./VBrowserPlayer";
import style from "./video.css";
import spinnerVideo from "./spinner.mp4";
import withPlaylistHandling from "./withPlaylistHandling";
import withProxyHandling from "./withProxyHandling";
const isLocalhost = location.hostname == "localhost";
import {
  facebookAndVidyardWorkaround,
  getInternalPlayerVolume,
  getIsPaused,
  getPlayerOptions,
  ignoredErrors,
  isCinesendUrl,
  pausePlayer,
  unPausePlayer,
  addSubtitles,
  getIsMuted,
  isHttp,
  clearSubtitleCues
} from "./helpers";
const tryParseUrl = (url) => {
  try {
    return new URL(url);
  } catch (_e) {
    return null;
  }
};
export const Player = memo(
  ({
    autoPlay,
    onBannedUrl,
    onError,
    onSupportedUrl,
    onUnmount,
    paused,
    stream,
    subtitles,
    url,
    onReady,
    onUpdate,
    loop,
    controls
  }) => {
    var _a;
    const styles = withStyleHook(style);
    const [error, setError] = useState("");
    const [playerPaused, setPlayerPaused] = useState(false);
    const [muted, setMuted] = useState(false);
    const [showPlayButton, setShowPlayButton] = useState(false);
    const [reactPlayer, setReactPlayer] = useState(null);
    const [position, setPosition] = useState(void 0);
    const [duration, setDuration] = useState(void 0);
    const autoPlayBusterRef = useRef(
      null
    );
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    const httpUrl = url && (url.startsWith("blob") || url.startsWith("http") ? url : spinnerVideo);
    const initialVolume = AVEngine.getCurrentlySpeaking().size > 0 ? AVEngine.dimLevel : AVEngine.initialEmbedVolume;
    const [currentVolume, setCurrentVolume] = useState(initialVolume);
    const [preDuckUserVolume, setPreduckUserVolume] = useState(
      AVEngine.initialEmbedVolume
    );
    const applySubtitles = (subtitles2, offset = 0) => {
      if (!internalPlayer)
        return;
      if (!internalPlayer.textTracks)
        return;
      if (!subtitles2)
        return;
      if (!(subtitles2 == null ? void 0 : subtitles2.cues))
        return;
      addSubtitles(internalPlayer, subtitles2, offset);
    };
    const offsetSubtitles = (subtitles2, offset) => applySubtitles(subtitles2, offset);
    const setVolume = (volume) => {
      if (volume === 0) {
        mute();
      } else {
        unmute();
      }
      setCurrentVolume(volume);
      localStorage.setItem("embedVolume", String(volume));
    };
    const seekTo = (position2) => {
      if (Number.isNaN(position2))
        return;
      if (reactPlayer) {
        return reactPlayer == null ? void 0 : reactPlayer.seekTo(position2);
      }
    };
    const isPaused = () => __async(void 0, null, function* () {
      const result = yield getIsPaused(internalPlayer);
      return Boolean(result !== null ? result : playerPaused);
    });
    const pause = () => pausePlayer(internalPlayer);
    const play = () => unPausePlayer(internalPlayer);
    const mute = () => setMuted(true);
    const unmute = () => {
      setShowPlayButton(false);
      if (currentVolume == 0)
        return;
      setMuted(false);
    };
    const syncDuration = () => {
      const playerDuration = reactPlayer == null ? void 0 : reactPlayer.getDuration();
      if (playerDuration) {
        setDuration(playerDuration);
      }
    };
    const handleVideoLoaded = () => __async(void 0, null, function* () {
      var _a2;
      onReady({
        id: (_a2 = reactPlayer == null ? void 0 : reactPlayer._reactInternals) == null ? void 0 : _a2.key,
        video: internalPlayer,
        offsetSubtitles,
        setVolume,
        seekTo,
        isPaused,
        pause,
        play,
        mute,
        unmute
      });
      applySubtitles(subtitles);
      onUpdate({
        volume: currentVolume,
        duration,
        muted,
        position,
        paused: playerPaused
      });
      if (internalPlayer instanceof HTMLVideoElement) {
        internalPlayer.oncanplay = () => {
          if (internalPlayer.videoHeight === 0 && internalPlayer.videoWidth === 0) {
            internalPlayer.poster = "";
          }
        };
      }
    });
    const applyStream = () => {
      if (internalPlayer && stream) {
        internalPlayer.src = "";
        internalPlayer.srcObject = stream;
        handleVideoLoaded();
      }
    };
    const onPlayerError = (e) => __async(void 0, null, function* () {
      var _a2, _b;
      const error2 = e;
      console.error({ e });
      const errorName = (error2 == null ? void 0 : error2.message) || ((_a2 = error2 == null ? void 0 : error2.name) == null ? void 0 : _a2.toLowerCase());
      if (ignoredErrors.indexOf(errorName) !== -1 || ignoredErrors.indexOf((_b = error2 == null ? void 0 : error2.name) == null ? void 0 : _b.toLowerCase()) !== -1) {
        return;
      }
      if (isHttp(url) && onError && onError(errorName)) {
        return;
      }
      if (typeof errorName === "string") {
        setError(errorName || "Unknown Error");
      }
    });
    const onPlayerReady = (newReactPlayer) => __async(void 0, null, function* () {
      var _a2, _b;
      if (((_a2 = newReactPlayer == null ? void 0 : newReactPlayer._reactInternals) == null ? void 0 : _a2.key) === ((_b = reactPlayer == null ? void 0 : reactPlayer._reactInternals) == null ? void 0 : _b.key)) {
        return;
      }
      const hls = newReactPlayer.getInternalPlayer("hls");
      hls == null ? void 0 : hls.on("hlsError", function(_eventName, data) {
        if (data.fatal) {
          if (data.type === "mediaError") {
            hls.recoverMediaError();
          }
        }
      });
      setReactPlayer(newReactPlayer);
    });
    const registerEmbedVolumeCallback = () => AVEngine.registerEmbedVolumeCallback(
      url,
      (level) => __async(void 0, null, function* () {
        const currentPlayerVolume = yield getInternalPlayerVolume(
          internalPlayer
        );
        setPreduckUserVolume(currentPlayerVolume);
        if (level !== null) {
          if (currentPlayerVolume > level) {
            setCurrentVolume(level);
          }
        } else {
          setCurrentVolume(preDuckUserVolume);
        }
      })
    );
    const onProgress = ({ playedSeconds }) => {
      setPosition(playedSeconds);
      syncDuration();
    };
    const onPlay = () => {
      setPlayerPaused(false);
      syncDuration();
    };
    const onEnded = () => {
      setPlayerPaused(true);
      syncDuration();
    };
    const onPause = () => {
      setPlayerPaused(true);
      syncDuration();
    };
    const onDuration = (duration2) => {
      if (duration2) {
        setDuration(duration2);
      }
    };
    const unPauseAndPlay = () => {
      if (!paused) {
        play();
      }
      unmute();
    };
    useEffect(() => {
      if (!(subtitles == null ? void 0 : subtitles.cues)) {
        clearSubtitleCues(internalPlayer);
      } else {
        applySubtitles(subtitles);
      }
    }, [JSON.stringify(subtitles)]);
    useEffect(() => {
      applyStream();
    }, [internalPlayer, stream]);
    useEffect(() => {
      onUpdate({
        volume: currentVolume,
        duration,
        muted,
        position,
        paused: playerPaused
      });
    }, [currentVolume, duration, muted, position, playerPaused]);
    useEffect(() => {
      if (muted && showPlayButton && !paused) {
        play();
      }
    }, [muted, showPlayButton, paused]);
    useEffect(() => {
      ;
      (() => __async(void 0, null, function* () {
        const playerIsMuted = yield getIsMuted(internalPlayer);
        setMuted(playerIsMuted || false);
      }))();
    }, [controls]);
    useEffect(() => {
      if (!internalPlayer)
        return;
      if (!paused) {
        play();
      }
      facebookAndVidyardWorkaround(internalPlayer, setPlayerPaused);
      handleVideoLoaded();
      applyStream();
      applySubtitles(subtitles);
      if (url) {
        registerEmbedVolumeCallback();
      }
      if (!autoPlayBusterRef.current) {
        const playFallback = () => {
          mute();
          play();
          setShowPlayButton(true);
        };
        autoPlayBusterRef.current = setTimeout(() => __async(void 0, null, function* () {
          const { result } = yield canAutoPlay.audio();
          if (!result) {
            playFallback();
          } else {
            const { result: videoResult } = yield canAutoPlay.video({
              inline: true
            });
            if (!videoResult) {
              setShowPlayButton(true);
            }
          }
          autoPlayBusterRef.current = null;
        }), 2e3);
      }
    }, [(_a = reactPlayer == null ? void 0 : reactPlayer._reactInternals) == null ? void 0 : _a.key]);
    useEffect(() => {
      var _a2, _b;
      const parsedUrl = tryParseUrl(url);
      if ((url == null ? void 0 : url.startsWith("https://www.twitch.tv/")) && (location.href.startsWith("capacitor") || isLocalhost)) {
        onPlayerError("twitch won't load on capacitor");
      }
      if ((_a2 = parsedUrl == null ? void 0 : parsedUrl.hostname) == null ? void 0 : _a2.includes("pluto.tv")) {
        onPlayerError("Force Pluto to use proxy");
      }
      if ((_b = parsedUrl == null ? void 0 : parsedUrl.hostname) == null ? void 0 : _b.includes(".real-debrid.com")) {
        onPlayerError("Force RD to use proxy");
      }
      return () => {
        if (reactPlayer) {
          if (autoPlayBusterRef.current) {
            clearTimeout(autoPlayBusterRef.current);
            autoPlayBusterRef.current = null;
          }
          const hlsPlayer = reactPlayer.getInternalPlayer("hls");
          const dashPlayer = reactPlayer.getInternalPlayer("dash");
          if (hlsPlayer) {
            hlsPlayer.destroy();
          } else if (dashPlayer) {
            dashPlayer.reset();
          }
          if (internalPlayer && stream) {
            internalPlayer.src = "";
            internalPlayer.srcObject = null;
            internalPlayer.pause();
          }
        }
        if (onUnmount) {
          onUnmount();
        }
        if (url) {
          AVEngine.removeEmbedVolumeCallback(url);
        }
      };
    }, []);
    if (!url && !stream) {
      return null;
    }
    if (url.startsWith("VBrowser://"))
      return /* @__PURE__ */ React.createElement(
        VBrowserPlayer,
        {
          onSupportedUrl,
          onBannedUrl,
          sessionId: url.replace("VBrowser://", "")
        }
      );
    if (isCinesendUrl(url))
      return /* @__PURE__ */ React.createElement(CineSendPlayer, { volume: currentVolume, src: url, onReady });
    if (error)
      return /* @__PURE__ */ React.createElement("div", { className: styles.placeholder }, /* @__PURE__ */ React.createElement(Icon, { name: "frown", size: "huge" }), /* @__PURE__ */ React.createElement("div", null, error));
    return /* @__PURE__ */ React.createElement(React.Fragment, null, !controls && showPlayButton && /* @__PURE__ */ React.createElement(
      "div",
      {
        className: [styles.playOverlay, "autoPlayBuster"].join(" "),
        onClick: unPauseAndPlay
      },
      /* @__PURE__ */ React.createElement(Icon, { className: "autoPlayBuster", name: "play", size: "huge" })
    ), /* @__PURE__ */ React.createElement("div", { className: controls ? void 0 : styles.noMouse }, /* @__PURE__ */ React.createElement(
      ReactPlayer,
      {
        key: httpUrl,
        className: styles.reactPlayer,
        url: httpUrl,
        controls,
        muted: muted || position === void 0,
        volume: currentVolume,
        playing: !paused,
        onError: onPlayerError,
        onReady: onPlayerReady,
        config: getPlayerOptions(autoPlay),
        onDuration,
        onProgress,
        onPlay,
        onEnded,
        onPause,
        width: "100%",
        height: "100%",
        playsinline: true,
        loop
      }
    )));
  }
);
export default withPlaylistHandling(withProxyHandling(Player));
