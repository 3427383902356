"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConnectingLoader = void 0;
require("emoji-picker-element");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _client = require("react-dom/client");
var _reactRouterDom = require("react-router-dom");
var global = _interopRequireWildcard(require("./services/global"));
var _useCapacitor = _interopRequireDefault(require("./hooks/useCapacitor"));
var _AVEngine = _interopRequireDefault(require("../core/services/AVEngine"));
var _AppContext = _interopRequireDefault(require("./AppContext"));
var _AppRouter = _interopRequireWildcard(require("./AppRouter"));
var _Header = _interopRequireDefault(require("./layout/Desktop/Header"));
var _AVPermissionsWarningModal = _interopRequireDefault(require("./AVPermissionsWarningModal"));
var _Mobile = _interopRequireDefault(require("./layout/Mobile"));
var _useRootSubscriptions = _interopRequireDefault(require("./hooks/useRootSubscriptions"));
require("./theme/kosmi.css");
var _KosmiClientProvider = _interopRequireDefault(require("./api/KosmiClientProvider"));
var _ConnectionContext = require("./api/ConnectionContext");
var _useMobileDetection = require("./hooks/useMobileDetection");
var _LaunchScreen = _interopRequireDefault(require("./LaunchScreen"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
global.init();
const styles = {
  app: {
    "App__styles.app": "App__styles.app",
    fontFamily: "x1o3ll5r",
    boxSizing: "x9f619",
    textSizeAdjust: "x1javzw2",
    overscrollBehavior: "x8du1vd",
    touchAction: "xggy1nq",
    display: "x78zum5",
    flexFlow: "x1vpus58",
    flexDirection: "xdt5ytf",
    flexWrap: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    width: "xh8yej3",
    height: "x5yr21d",
    position: "xixxii4",
    backgroundImage: "x10tolqp",
    transition: "x161kt5c",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    color: "x11jfisy",
    paddingTop: "x6vio5b",
    paddingRight: "xnwblhb",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    paddingBottom: "x11ba12",
    paddingLeft: "x1lw1rap",
    lineHeight: "x1evy7pa",
    fontSize: "xif65rj",
    outline: "x1a2a7pz",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    $$css: true
  }
};
const ConnectingLoader = () => {
  const {
    disconnected
  } = (0, _react.useContext)(_ConnectionContext.ConnectionContext);
  if (!disconnected) return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.TopStripLoader, null);
};
exports.ConnectingLoader = ConnectingLoader;
const CapacitorListeners = (0, _react.memo)(() => {
  (0, _useCapacitor.default)();
  return null;
});
const App = (0, _react.memo)(() => {
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const [showNav, setShowNav] = (0, _react.useState)(true);
  const [roomsDropdownOpen, setRoomDropdownOpen] = (0, _react.useState)(false);
  const [roomsDropdownisHovered, setRoomsDropdownisHovered] = (0, _react.useState)(false);
  const [showAvPermissionsModal, setShowAVPermissionsModal] = (0, _react.useState)(false);
  const [tutorial, setTutorial] = (0, _react.useState)({
    step: 1,
    roomId: null
  });
  (0, _useRootSubscriptions.default)();
  (0, _react.useEffect)(() => {
    _AVEngine.default.eventEmitter.on("av-permissions-granted", granted => {
      setShowAVPermissionsModal(!granted);
    });
  }, []);
  const setTutorialStep = (0, _react.useCallback)(step => setTutorial(__spreadProps(__spreadValues({}, tutorial), {
    step
  })), [JSON.stringify(tutorial)]);
  const setTutorialRoomId = (0, _react.useCallback)(roomId => setTutorial(__spreadProps(__spreadValues({}, tutorial), {
    roomId
  })), [JSON.stringify(tutorial)]);
  const toggleNav = (0, _react.useCallback)(() => {
    setShowNav(!showNav);
  }, [showNav]);
  const toggleRoomsDropdown = (0, _react.useCallback)(() => {
    setRoomDropdownOpen(!roomsDropdownOpen);
  }, [roomsDropdownOpen]);
  const context = (0, _react.useMemo)(() => ({
    tutorial,
    setTutorialStep,
    setTutorialRoomId,
    toggleNav,
    showNav,
    roomsDropdownOpen,
    toggleRoomsDropdown,
    setRoomsDropdownisHovered,
    roomsDropdownisHovered
  }), [tutorial, setTutorialStep, setTutorialRoomId, toggleNav, showNav, roomsDropdownOpen, toggleRoomsDropdown, setRoomsDropdownisHovered, roomsDropdownisHovered]);
  return /* @__PURE__ */_react.default.createElement(_reactRouterDom.BrowserRouter, {
    getUserConfirmation: global.getUserConfirmation
  }, /* @__PURE__ */_react.default.createElement(_AppRouter.RouterListeners, null), /* @__PURE__ */_react.default.createElement(CapacitorListeners, null), /* @__PURE__ */_react.default.createElement(_AppContext.default.Provider, {
    value: context
  }, !isMobile && /* @__PURE__ */_react.default.createElement(ConnectingLoader, null), showNav && !isMobile && /* @__PURE__ */_react.default.createElement(_Header.default, null), /* @__PURE__ */_react.default.createElement(_Mobile.default, null, /* @__PURE__ */_react.default.createElement(_AppRouter.default, null)), /* @__PURE__ */_react.default.createElement(_AVPermissionsWarningModal.default, {
    open: showAvPermissionsModal,
    onClose: () => setShowAVPermissionsModal(false)
  })));
});
const AppWrapper = (0, _react.memo)(() => {
  const [launched, setLaunched] = (0, _react.useState)(!!location.pathname.startsWith("/verifyEmail") || !!location.pathname.startsWith("/signup"));
  const onLaunch = (0, _react.useMemo)(() => () => setLaunched(true), []);
  if (location.pathname !== "/" && !launched && !window.localStorage.getItem("token")) {
    return /* @__PURE__ */_react.default.createElement(_LaunchScreen.default, {
      onLaunch
    });
  }
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.app
  }, /* @__PURE__ */_react.default.createElement(_KosmiClientProvider.default, null, /* @__PURE__ */_react.default.createElement(App, null))));
});
const appElement = document.createElement("div");
document.body.innerHTML = "";
document.body.appendChild(appElement);
const root = (0, _client.createRoot)(appElement);
root.render( /* @__PURE__ */_react.default.createElement(AppWrapper, null));