"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.WebcamButton = exports.RoomDropdown = exports.MicrophoneButton = exports.InviteFriendsModal = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _gql = require("kosmi-sdk/gql");
var _OnboardingTooltip = require("../../../../../components/Widgets/OnboardingTooltip");
var _helpers = require("kosmi-sdk/helpers");
var _useIsAdmin = require("../../../hooks/useIsAdmin");
var _index = require("../index");
var _widgets = require("kosmi-sdk/widgets");
var _platform = _interopRequireDefault(require("platform"));
var _react = _interopRequireWildcard(require("react"));
var _useWindowSize = _interopRequireDefault(require("../../../../../../core/hooks/useWindowSize"));
var _AVEngine = _interopRequireDefault(require("../../../../../../core/services/AVEngine"));
var _AppContext = _interopRequireDefault(require("../../../../../AppContext"));
var _ScreenCaptureModal = _interopRequireDefault(require("./ScreenCaptureModal"));
var _ScreenCaptureReviewModal = _interopRequireDefault(require("./ScreenCaptureReviewModal"));
var _RoomContext = _interopRequireDefault(require("../../../contexts/RoomContext"));
var _useRoomId = require("../../../hooks/useRoomId");
var _useMediaPlayerState = require("../../Player/useMediaPlayerState");
var _useMediaPlayerData = require("../../../hooks/useMediaPlayerData");
require("./FriendSelection");
var _LeaveRoomButton = _interopRequireDefault(require("./LeaveRoomButton"));
var _RoomBanner = _interopRequireDefault(require("./RoomBanner"));
var _SettingsModal = _interopRequireDefault(require("./SettingsModal"));
var _MembersModal = _interopRequireDefault(require("./MembersModal"));
var _LoginWall = _interopRequireDefault(require("../../../../../components/Widgets/LoginWall"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useMobileDetection = require("@/hooks/useMobileDetection");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const METADATA_QUERY = (0, _gql.gql)(`
  query ToolbarMetadataQuery($roomId: String!) {
    room(id: $roomId) {
      id
      state {
        metadata {
          requireAdminForWebcamAndMicrophone
          disableWebcamAndMicrophone
        }
      }
    }
  }
`);
const textAlign = "left";
const styles = {
  mediaIcon: {
    "RoomToolbar__styles.mediaIcon": "RoomToolbar__styles.mediaIcon",
    fontSize: "x176odoi",
    $$css: true
  },
  active: {
    "RoomToolbar__styles.active": "RoomToolbar__styles.active",
    color: "xoxyss0",
    $$css: true
  },
  inviteToRoomModal: {
    "RoomToolbar__styles.inviteToRoomModal": "RoomToolbar__styles.inviteToRoomModal",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x1cy8zhl",
    $$css: true
  },
  ellipsis: {
    "RoomToolbar__styles.ellipsis": "RoomToolbar__styles.ellipsis",
    width: "x100vrsf",
    fontSize: "xwsyq91",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  recordingWrapper: {
    "RoomToolbar__styles.recordingWrapper": "RoomToolbar__styles.recordingWrapper",
    height: "x1vqgdyp",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    display: "x78zum5",
    justifyContent: "xl56j7k",
    position: "x1n2onr6",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  red: {
    "RoomToolbar__styles.red": "RoomToolbar__styles.red",
    color: "x1e2nbdu",
    $$css: true
  },
  mediaButton: {
    "RoomToolbar__styles.mediaButton": "RoomToolbar__styles.mediaButton",
    fontSize: "x176odoi",
    $$css: true
  },
  noYes: {
    "RoomToolbar__styles.noYes": "RoomToolbar__styles.noYes",
    display: "x78zum5",
    margin: "x1esmc1y",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  memberCount: {
    "RoomToolbar__styles.memberCount": "RoomToolbar__styles.memberCount",
    marginLeft: "x16ydxro",
    marginInlineStart: null,
    marginInlineEnd: null,
    fontSize: "x176odoi",
    $$css: true
  },
  inviteFriends: {
    "RoomToolbar__styles.inviteFriends": "RoomToolbar__styles.inviteFriends",
    marginRight: "xyo1k6t",
    marginInlineStart: null,
    marginInlineEnd: null,
    fontSize: "xwsyq91",
    $$css: true
  },
  addUserIcon: {
    "RoomToolbar__styles.addUserIcon": "RoomToolbar__styles.addUserIcon",
    fontSize: "x176odoi",
    $$css: true
  },
  menuButtonStyle: {
    "RoomToolbar__styles.menuButtonStyle": "RoomToolbar__styles.menuButtonStyle",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    margin: "x16zck5j",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    height: "x1vqgdyp",
    textAlign: "xdpxx8g",
    boxShadow: "xh0z56q",
    borderRadius: "x1m3ak92",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    minWidth: "x900493",
    width: "xeq5yr9",
    maxWidth: "x193iq5w",
    $$css: true
  },
  inviteLinkWrapper: {
    "RoomToolbar__styles.inviteLinkWrapper": "RoomToolbar__styles.inviteLinkWrapper",
    width: "xh8yej3",
    maxWidth: "x1j9u4d2",
    display: "x78zum5",
    $$css: true
  },
  roomMenuHeader: {
    "RoomToolbar__styles.roomMenuHeader": "RoomToolbar__styles.roomMenuHeader",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    "@media(max-width: 1000px)_display": "x4m06ut",
    "@media(max-width: 1000px)_alignItems": "xrad4e2",
    $$css: true
  },
  roomMenuTitle: {
    "RoomToolbar__styles.roomMenuTitle": "RoomToolbar__styles.roomMenuTitle",
    fontSize: "xfifm61",
    color: "x1uae2mv",
    marginTop: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginBottom: null,
    $$css: true
  },
  socialIcons: {
    "RoomToolbar__styles.socialIcons": "RoomToolbar__styles.socialIcons",
    marginTop: "x1sy10c2",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "x1nhvcw1",
    $$css: true
  },
  socialIcon: {
    "RoomToolbar__styles.socialIcon": "RoomToolbar__styles.socialIcon",
    width: "x1849jeq",
    height: "x1gnnpzl",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x1suov85",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    position: "x1n2onr6",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  socialIconInner: {
    "RoomToolbar__styles.socialIconInner": "RoomToolbar__styles.socialIconInner",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    top: "x1jzctok",
    position: "x10l6tqk",
    $$css: true
  },
  roomToolbar: {
    "RoomToolbar__styles.roomToolbar": "RoomToolbar__styles.roomToolbar",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    borderRadius: "x2u8bby",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    backgroundImage: "x18o3ruo",
    display: "x78zum5",
    position: "x1n2onr6",
    flexDirection: "xdt5ytf",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: "xexx8yu",
    paddingBottom: null,
    borderWidth: null,
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "xng3xce",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    "@media(max-width: 1000px)_minHeight": "xffyipr",
    "@media(max-width: 1000px)_backgroundColor": "xrwqzpa",
    "@media(max-width: 1000px)_padding": "x1dcn1nx",
    "@media(max-width: 1000px)_paddingInline": null,
    "@media(max-width: 1000px)_paddingStart": null,
    "@media(max-width: 1000px)_paddingLeft": null,
    "@media(max-width: 1000px)_paddingEnd": null,
    "@media(max-width: 1000px)_paddingRight": null,
    "@media(max-width: 1000px)_paddingBlock": null,
    "@media(max-width: 1000px)_paddingTop": null,
    "@media(max-width: 1000px)_paddingBottom": null,
    "@media(max-width: 1000px)_zIndex": "x10l4xj2",
    $$css: true
  },
  inviteButton: {
    "RoomToolbar__styles.inviteButton": "RoomToolbar__styles.inviteButton",
    height: "x1s1d1n7",
    color: "x11jfisy",
    backgroundColor: "xivp17k",
    display: "x1s85apg",
    "@media(max-width: 1000px)_height": "x1m7vh9e",
    "@media(max-width: 1000px)_color": "x88nq9m",
    "@media(max-width: 1000px)_backgroundColor": "xvx7zs6",
    "@media(max-width: 1000px)_display": "x4m06ut",
    $$css: true
  },
  toolbarWrapper: {
    "RoomToolbar__styles.toolbarWrapper": "RoomToolbar__styles.toolbarWrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    width: "xh8yej3",
    $$css: true
  },
  dropdown: {
    "RoomToolbar__styles.dropdown": "RoomToolbar__styles.dropdown",
    position: "x10l6tqk",
    top: "xdsb8wn",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    left: "xu96u03",
    width: "x3hqpx7",
    minWidth: "x1jzhcrs",
    zIndex: "x1n327nk",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "x1yf7rl7",
    maxWidth: "xq1rtah",
    boxShadow: "x14hmju0",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  communicationToolbar: {
    "RoomToolbar__styles.communicationToolbar": "RoomToolbar__styles.communicationToolbar",
    display: "x78zum5",
    maxWidth: "x193iq5w",
    height: "x10wjd1d",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    justifyContent: "xl56j7k",
    position: "x1n2onr6",
    width: "xh8yej3",
    padding: "xxmtint",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  disabled: {
    "RoomToolbar__styles.disabled": "RoomToolbar__styles.disabled",
    position: "x10l6tqk",
    color: "x1sr8853",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    zIndex: "x1vjfegm",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    textAlign: "x2b8uid",
    fontWeight: "x1xlr1w8",
    fontSize: "x3vlhr4",
    $$css: true
  },
  roomUrl: {
    "RoomToolbar__styles.roomUrl": "RoomToolbar__styles.roomUrl",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    width: "xh8yej3",
    $$css: true
  },
  screenCecordButtonToolbar: {
    "RoomToolbar__styles.screenCecordButtonToolbar": "RoomToolbar__styles.screenCecordButtonToolbar",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "x1849jeq",
    height: "x1gnnpzl",
    $$css: true
  },
  recordActive: {
    "RoomToolbar__styles.recordActive": "RoomToolbar__styles.recordActive",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  inviteModal: {
    "RoomToolbar__styles.inviteModal": "RoomToolbar__styles.inviteModal",
    maxWidth: "xrlsmeg",
    $$css: true
  }
};
const StopScreenRecordingHookListener = () => {
  const windowSize = (0, _useWindowSize.default)();
  (0, _react.useEffect)(() => _AVEngine.default.handleWindowResized(), [windowSize]);
  return null;
};
const RoomDropdown = exports.RoomDropdown = (0, _helpers.withDialogs)((0, _react.memo)(({
  visible,
  onClose,
  trigger
}) => {
  const isAdmin = (0, _useIsAdmin.useIsAdmin)();
  return /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu, {
    trigger,
    open: visible,
    onClose
  }, isAdmin && /* @__PURE__ */_react.default.createElement(_SettingsModal.default, {
    trigger: /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu.Item, {
      text: "Room settings",
      icon: "setting"
    }))
  }), /* @__PURE__ */_react.default.createElement(_LeaveRoomButton.default, {
    trigger: /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu.Item, {
      text: "Leave room",
      icon: "sign out alternate"
    }))
  }));
}));
const InviteFriendsModal = exports.InviteFriendsModal = (0, _react.memo)(({
  trigger: Trigger
}) => {
  const inputRef = (0, _react.useRef)(null);
  const timeoutRef = (0, _react.useRef)(null);
  const [modalOpen, setModalOpen] = (0, _react.useState)(false);
  const [copied, setCopied] = (0, _react.useState)(false);
  const handleOpen = () => __async(void 0, null, function* () {
    return setModalOpen(true);
  });
  const handleClose = () => setModalOpen(false);
  const track = (0, _helpers.useTracking)();
  const currentUser = (0, _helpers.currentUserHook)();
  const roomId = (0, _useRoomId.useRoomId)();
  (0, _react.useEffect)(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  const inviteLink = `https://kosmi.to/${roomId}`;
  return /* @__PURE__ */_react.default.createElement(_widgets.AnimatedModal, {
    trigger: /* @__PURE__ */_react.default.createElement(Trigger, {
      onClick: () => {
        handleOpen();
      }
    }),
    open: modalOpen,
    onClose: handleClose,
    onOpen: () => {
      track("UI", "OpenInviteFriendsModal", "Open invite friends modal", roomId);
    },
    style: styles.inviteModal
  }, /* @__PURE__ */_react.default.createElement(_widgets.AnimatedModal.Header, null, "Invite to room"), /* @__PURE__ */_react.default.createElement(_widgets.AnimatedModal.Content, {
    style: styles.inviteToRoomModal
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Share this room link for others to join:"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteLinkWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Input, {
    readOnly: true,
    wrapperClassName: styles.roomUrl,
    value: copied ? "Copied!" : inviteLink,
    ref: inputRef
  }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    style: {
      marginLeft: 5,
      minWidth: 136
    },
    onClick: () => __async(void 0, null, function* () {
      var _a, _b, _c, _d, _e;
      if (((_b = (_a = _platform.default) == null ? void 0 : _a.os) == null ? void 0 : _b.family) && ["Android", "iOS"].indexOf(_platform.default.os.family) !== -1 && navigator["share"]) {
        track("UI", "OpenInviteFriendsShareMenu", "Open invite friends share menu");
        handleClose();
        try {
          yield navigator.share({
            title: "Kosmi",
            text: "Join my Kosmi room!",
            url: inviteLink
          });
        } catch (e) {
          if (e.toString().includes("AbortError")) {
            track("UI", "OpenInviteFriendsShareMenuCancelled", "Open invite friends share menu cancelled");
            console.warn(e);
          }
        }
      } else {
        if (copied) {
          return;
        }
        (_c = inputRef == null ? void 0 : inputRef.current) == null ? void 0 : _c.select();
        if ((_d = inputRef.current) == null ? void 0 : _d.setSelectionRange) (_e = inputRef.current) == null ? void 0 : _e.setSelectionRange(0, 99999);
        document.execCommand("copy");
        setCopied(true);
        timeoutRef.current = setTimeout(() => {
          setCopied(false);
        }, 500);
        track("UI", "CopyRoomLink", "Copy room link");
      }
    }),
    color: "var(--primary-light-5)"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "copy"
  }), "Copy link")), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.socialIcons
  }, "Paste link in any email or messaging app", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.socialIcon
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.socialIconInner,
    name: "envelope outline"
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.socialIcon
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.socialIconInner,
    name: "discord"
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.socialIcon
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.socialIconInner,
    name: "twitter"
  }))), false));
});
const useWebcamAndMicrophone = confirm2 => {
  var _a, _b;
  const roomId = (0, _useRoomId.useRoomId)();
  const {
    data
  } = (0, _gql.useQuery)(METADATA_QUERY, {
    variables: {
      roomId
    },
    fetchPolicy: "cache-first"
  });
  const {
    requireAdminForWebcamAndMicrophone,
    disableWebcamAndMicrophone
  } = ((_b = (_a = data == null ? void 0 : data.room) == null ? void 0 : _a.state) == null ? void 0 : _b.metadata) || {};
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const currentUser = (0, _helpers.currentUserHook)();
  const {
    disableWebcam,
    disableMicrophone,
    enableWebcam,
    microphoneStreams,
    webcamStreams,
    enableMicrophone
  } = (0, _react.useContext)(_RoomContext.default);
  const userWebcamStream = webcamStreams && currentUser && webcamStreams[currentUser.id];
  const webcamEnabled = userWebcamStream && (userWebcamStream.id === "LOADING" || userWebcamStream.getVideoTracks().length > 0);
  const userMicrophoneStream = microphoneStreams && currentUser && microphoneStreams[currentUser.id];
  const microphoneEnabled = userMicrophoneStream && (userMicrophoneStream.id === "LOADING" || userMicrophoneStream.getAudioTracks().length > 0);
  const triggerMicrophone = () => __async(void 0, null, function* () {
    if (!enableMicrophone) return;
    yield enableMicrophone(null);
    track("UI", "EnableMicrophone", "Enable microphone");
  });
  const track = (0, _helpers.useTracking)();
  const [loadingCamera, setLoadingCamera] = (0, _react.useState)(false);
  const [loadingMicrophone, setLoadingMicrophone] = (0, _react.useState)(false);
  const toggleMicrophone = () => __async(void 0, null, function* () {
    if (!enableMicrophone) return;
    setLoadingMicrophone(true);
    try {
      if (microphoneEnabled) {
        if (disableMicrophone) {
          yield disableMicrophone();
        }
        track("UI", "DisableMicrophone", "Disable microphone");
      } else {
        yield triggerMicrophone();
      }
    } finally {
      setLoadingMicrophone(false);
    }
  });
  const toggleCamera = () => __async(void 0, null, function* () {
    setLoadingCamera(true);
    try {
      if (webcamEnabled) {
        track("UI", "DisableWebcam", "Disable webcam");
        if (disableWebcam) {
          yield disableWebcam();
        }
      } else {
        track("UI", "EnableWebcam", "Enable webcam");
        if (enableWebcam) {
          yield enableWebcam(null);
        }
        if (!microphoneEnabled) {
          if (yield confirm2( /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, "Would you like to turn on your microphone as well?", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
            style: styles.noYes
          })), "No", "Yes")) {
            triggerMicrophone();
          }
        }
      }
    } finally {
      setLoadingCamera(false);
    }
  });
  return {
    toggleCamera,
    toggleMicrophone,
    loadingCamera,
    loadingMicrophone,
    requireAdminForWebcamAndMicrophone,
    disableWebcamAndMicrophone,
    webcamEnabled,
    microphoneEnabled,
    isMobile
  };
};
const WebcamButton = exports.WebcamButton = (0, _helpers.withDialogs)((0, _react.forwardRef)((_a, ref) => {
  var _b = _a,
    {
      confirm: confirm2
    } = _b,
    btnProps = __objRest(_b, ["confirm"]);
  const {
    toggleCamera,
    loadingCamera,
    requireAdminForWebcamAndMicrophone,
    disableWebcamAndMicrophone,
    webcamEnabled
  } = useWebcamAndMicrophone(confirm2);
  const isAdmin = (0, _useIsAdmin.useIsAdmin)();
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, __spreadValues({
    ref,
    active: webcamEnabled,
    loading: loadingCamera,
    disabled: disableWebcamAndMicrophone || !isAdmin && requireAdminForWebcamAndMicrophone || false,
    onClick: toggleCamera
  }, btnProps), !webcamEnabled && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.disabled
  }, "/"), /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: [styles.mediaIcon, webcamEnabled && styles.active],
    name: "video camera"
  })));
}));
const MicrophoneButton = exports.MicrophoneButton = (0, _react.forwardRef)((props, ref) => {
  const isAdmin = (0, _useIsAdmin.useIsAdmin)();
  const {
    toggleMicrophone,
    loadingMicrophone,
    requireAdminForWebcamAndMicrophone,
    disableWebcamAndMicrophone,
    microphoneEnabled
  } = useWebcamAndMicrophone(confirm);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, __spreadValues({
    ref,
    loading: loadingMicrophone,
    disabled: disableWebcamAndMicrophone || !isAdmin && requireAdminForWebcamAndMicrophone || false,
    active: microphoneEnabled,
    onClick: toggleMicrophone
  }, props), !microphoneEnabled && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.disabled
  }, "/"), /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "microphone",
    className: [styles.mediaIcon, microphoneEnabled && styles.active]
  })));
});
var _default = exports.default = (0, _helpers.withDialogs)(({
  player
}) => {
  const [dropdownIsOpen, setdropdownIsOpen] = (0, _react.useState)(false);
  const [recordingScreen, setRecordingScreen] = (0, _react.useState)(false);
  const [selectingScreenRecordOptions, setSelectingScreenRecordOptions] = (0, _react.useState)(false);
  const [showRecordingReview, setShowRecordingReview] = (0, _react.useState)(false);
  const [showRoomUrl, setShowRoomUrl] = (0, _react.useState)(false);
  const roomId = (0, _useRoomId.useRoomId)();
  const isAdmin = (0, _useIsAdmin.useIsAdmin)();
  const {
    microphoneEnabled,
    webcamEnabled
  } = useWebcamAndMicrophone();
  const track = (0, _helpers.useTracking)();
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const {
    stopVideo
  } = (0, _useMediaPlayerState.useMediaPlayerActions)();
  const {
    mediaPlayerState
  } = (0, _useMediaPlayerData.useMediaPlayerStateCached)();
  const {
    appModule
  } = (0, _react.useContext)(_RoomContext.default);
  const appContext = (0, _react.useContext)(_AppContext.default);
  const closeDropdown = () => setdropdownIsOpen(false);
  const openDropdown = () => setdropdownIsOpen(true);
  const toggleDropdown = () => {
    if (dropdownIsOpen) {
      closeDropdown();
    } else {
      openDropdown();
    }
  };
  const handleRecordRoomClick = () => {
    if (recordingScreen) {
      track("UI", "EndScreenRecord", "End screen record");
      setRecordingScreen(false);
      setShowRecordingReview(true);
    } else {
      setSelectingScreenRecordOptions(true);
      track("UI", "EnableScreenRecord", "Enable screen record");
    }
  };
  const mediaPlayerUrl = mediaPlayerState.url;
  const inviteLink = `https://kosmi.to/${roomId}`;
  const {
    memberCount
  } = (0, _index.useSidebarData)(roomId);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomToolbar
  }, recordingScreen && /* @__PURE__ */_react.default.createElement(_RoomBanner.default, {
    showRoomUrl
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomMenuHeader
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomMenuTitle
  }, "Room menu"), !isMobile && /* @__PURE__ */_react.default.createElement(RoomDropdown, {
    trigger: /* @__PURE__ */_react.default.createElement(_OnboardingTooltip.OnboardingTooltip, {
      open: !isMobile && appContext.tutorial.roomId === roomId && appContext.tutorial.step === 2,
      content: "You can click here to access the room settings.",
      onClick: () => {
        appContext.setTutorialStep(3);
      },
      onSkip: () => {
        appContext.setTutorialStep(4);
        track("UI", "OnboardingFinished", "Onboarding finished");
      },
      step: 2,
      totalSteps: 3,
      trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        color: "blue",
        style: {
          height: 30
        },
        onClick: toggleDropdown
      }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
        name: "ellipsis horizontal",
        className: styles.ellipsis
      }))
    }),
    styles,
    visible: dropdownIsOpen,
    onClose: closeDropdown,
    recordScreenButton: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "blue",
      style: {
        width: "100%"
      },
      onClick: handleRecordRoomClick
    }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      className: [recordingScreen && styles.recordActive, styles.red],
      name: "circle"
    }), recordingScreen ? "Stop recording" : "Record room")
  })), /* @__PURE__ */_react.default.createElement(_OnboardingTooltip.OnboardingTooltip, {
    position: "left center",
    onClick: () => {
      appContext.setTutorialStep(4);
      track("UI", "OnboardingFinished", "Onboarding finished");
    },
    onSkip: () => appContext.setTutorialStep(4),
    step: 3,
    totalSteps: 3,
    open: !isMobile && appContext.tutorial.roomId === roomId && appContext.tutorial.step === 3,
    content: "You can invite friends, turn on camera and mic here",
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.toolbarWrapper
    }, !isMobile && /* @__PURE__ */_react.default.createElement(InviteFriendsModal, {
      trigger: ({
        onClick
      }) => /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        onClick,
        color: "var(--primary-light-5)",
        style: {
          marginTop: 10,
          marginBottom: 10,
          marginLeft: "auto",
          marginRight: "auto",
          width: 230,
          height: 40,
          fontSize: 15
        }
      }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
        name: "add user",
        className: styles.inviteFriends
      }), "Invite friends")
    }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.communicationToolbar
    }, /* @__PURE__ */_react.default.createElement(_ScreenCaptureModal.default, {
      open: selectingScreenRecordOptions,
      onClose: () => setSelectingScreenRecordOptions(false),
      onRecordingStarted: showRoomUrl2 => {
        setRecordingScreen(true);
        setShowRoomUrl(showRoomUrl2);
      },
      onRecordingCancelled: () => {
        setShowRecordingReview(true);
        setRecordingScreen(false);
        track("UI", "ScreenRecordingCancelled", "Screen recording cancelled");
      },
      onSelectingTab: () => {
        setSelectingScreenRecordOptions(false);
      }
    }), /* @__PURE__ */_react.default.createElement(_ScreenCaptureReviewModal.default, {
      open: showRecordingReview,
      onClose: () => setShowRecordingReview(false),
      onRecordingDownloaded: () => {
        track("UI", "ScreenRecordingDownlaoded", "Screen recording downloaded");
      }
    }), isMobile && /* @__PURE__ */_react.default.createElement(_MembersModal.default, {
      trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        className: styles.menuButtonStyle,
        size: "m",
        color: "blue"
      }, memberCount, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
        className: styles.memberCount,
        name: "user group"
      }))
    }), isMobile && /* @__PURE__ */_react.default.createElement(InviteFriendsModal, {
      trigger: ({
        onClick
      }) => {
        var _a, _b;
        return /* @__PURE__ */_react.default.createElement(_uikit.Button, {
          className: [styles.menuButtonStyle],
          color: "blue",
          onClick: ((_b = (_a = _platform.default) == null ? void 0 : _a.os) == null ? void 0 : _b.family) && ["Android", "iOS"].indexOf(_platform.default.os.family) !== -1 && navigator["share"] ? () => __async(void 0, null, function* () {
            yield navigator.share({
              title: "Kosmi",
              text: "Join my Kosmi room!",
              url: inviteLink
            });
            track("UI", "MobileShareLinkClicked", "Mobile Share Link Clicked", roomId);
          }) : onClick
        }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
          className: styles.addUserIcon,
          name: "add user"
        }));
      }
    }), /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
      disabled: isMobile,
      position: "bottom center",
      content: webcamEnabled ? "Disable camera" : "Enable camera",
      trigger: /* @__PURE__ */_react.default.createElement(WebcamButton, {
        size: "m",
        color: "blue",
        className: styles.menuButtonStyle
      })
    }), /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
      disabled: isMobile,
      position: "bottom center",
      content: microphoneEnabled ? "Disable microphone" : "Enable microphone",
      trigger: /* @__PURE__ */_react.default.createElement(MicrophoneButton, {
        size: "m",
        color: "blue",
        className: styles.menuButtonStyle
      })
    }), /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, appModule && appModule.name !== "Virtual room" && /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
      content: mediaPlayerUrl ? "Stop music" : "Load Media",
      disabled: isMobile,
      position: "bottom center",
      trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        color: "blue",
        className: styles.menuButtonStyle,
        disabled: !isAdmin || !appModule || appModule.name === "Virtual room",
        onClick: () => {
          if (mediaPlayerUrl) {
            track("UI", "StopVideoFromRoomToolbar", "Stop video from room toolbar");
            stopVideo();
          } else {
            track("UI", "OpenLoadVideoModalFromRoomToolbar", "Open load video modal from room toolbar");
            player == null ? void 0 : player.loadMedia();
          }
        }
      }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
        name: mediaPlayerUrl ? "stop circle" : "kosmi mediabutton",
        className: styles.mediaButton
      }))
    }))))
  }), /* @__PURE__ */_react.default.createElement(StopScreenRecordingHookListener, null));
});