"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { Button, Icon } from "kosmi-sdk/uikit";
import { AnimatedModal as Modal } from "kosmi-sdk/widgets";
import React, { memo, useContext } from "react";
import AVEngine from "../../services/AVEngine";
import RoomContext from "./RoomContext";
class WebRTCAudioElement extends React.Component {
  constructor(props) {
    super(props);
    __publicField(this, "audioel");
    __publicField(this, "mediaStream", null);
    __publicField(this, "forcePlay");
    this.state = {};
  }
  shouldComponentUpdate(nextProps) {
    var _a, _b;
    return ((_a = nextProps.track) == null ? void 0 : _a.id) !== ((_b = this.props.track) == null ? void 0 : _b.id) || this.props.muted !== nextProps.muted;
  }
  componentDidMount() {
    this.forcePlay = setInterval(() => {
      if (this.audioel && this.audioel.paused) {
        try {
          this.audioel.play();
        } catch (e) {
          console.warn(e);
        }
      } else {
        this.setState({ paused: false }, () => {
          clearInterval(this.forcePlay);
        });
      }
    }, 5e3);
  }
  componentDidUpdate() {
    var _a;
    if ((_a = this.props.track) == null ? void 0 : _a.id)
      AVEngine.removeMediaElementAudioFromEngine(
        this.props.track.id,
        this.props.userId
      );
  }
  componentWillUnmount() {
    if (this.audioel)
      this.audioel.srcObject = null;
    this.mediaStream = null;
    if (this.props.track)
      this.props.track.stop();
    clearInterval(this.forcePlay);
    AVEngine.removeMediaElementAudioFromEngine(
      this.props.track.id,
      this.props.userId
    );
  }
  render() {
    const { track, muted } = this.props;
    const enableAudio = () => [...document.getElementsByClassName("rtcAudioTrack")].forEach(
      (t) => {
        try {
          t.play();
        } catch (e) {
          console.warn(e);
        }
      }
    );
    return track ? /* @__PURE__ */ React.createElement(React.Fragment, null, this.state.paused ? /* @__PURE__ */ React.createElement(Modal, { open: true, preventClosing: true }, /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingBottom: "18px"
        }
      },
      "Please enable audio to use Kosmi"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        fluid: true,
        color: "yellow",
        inverted: true,
        onClick: () => {
          enableAudio();
        }
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "volume up" }),
      "Enable audio"
    ))) : null, /* @__PURE__ */ React.createElement(
      "audio",
      {
        className: "rtcAudioTrack",
        key: track.id,
        muted,
        ref: (audio) => {
          this.audioel = audio;
          if (audio) {
            this.mediaStream = new MediaStream([track]);
            audio.srcObject = this.mediaStream;
            audio.onloadedmetadata = () => {
              audio.play();
              try {
                audio.play();
              } catch (e) {
                console.warn(e);
              }
              if (audio.paused) {
                if (document.getElementsByClassName("autoPlayBuster").length === 0) {
                  this.setState({ paused: true });
                }
              }
            };
          }
        }
      }
    )) : null;
  }
}
export default memo(
  (props) => {
    const { microphoneStreams } = useContext(RoomContext);
    const stream = microphoneStreams && microphoneStreams[props.userId];
    const track = stream && stream.getAudioTracks && stream.getAudioTracks()[0];
    return (track == null ? void 0 : track.id) && track.id !== "LOADING" ? /* @__PURE__ */ React.createElement(WebRTCAudioElement, __spreadProps(__spreadValues({}, props), { track })) : null;
  },
  (props, nextProps) => {
    const relevantProps = (props2) => JSON.stringify([props2.userId, props2.muted]);
    return relevantProps(props) === relevantProps(nextProps);
  }
);
