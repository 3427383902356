"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var _a, _b, _c, _d;
import React from "react";
import platform from "platform";
import { withStyleHook } from "kosmi-sdk/styles";
import style from "./style.css";
import { gql, useMutation, useQuery, types } from "kosmi-sdk/gql";
import { Loader, Dimmer } from "kosmi-sdk/uikit";
import { useMediaPlayerActions } from "../../../../../useMediaPlayerState";
import { currentUserHook, useRoomId, withDialogs } from "kosmi-sdk/helpers";
import {
  Diamond,
  PaywallMessage
} from "../../../../../../../../Widgets/Premium";
import Tags from "./components/Tags";
const isIOS = ((_b = (_a = platform) == null ? void 0 : _a.os) == null ? void 0 : _b.family) === "iOS";
const isMacOS = ((_d = (_c = platform) == null ? void 0 : _c.os) == null ? void 0 : _d.family) === "OS X";
const isCapacitor = location.href.startsWith("capacitor");
const hidePremium = (isIOS || isMacOS) && isCapacitor;
const RESULTS = gql(`
  query MediaContentSearch(
    $query: String!
    $categories: [MediaCategories]!
    $types: [MediaType]!
  ) {
    mediaContentSearchCategories(categories: $categories, query: $query)
    mediaContentSearch(query: $query, categories: $categories, types: $types) {
      id
      premium
      thumbnail
      title
      type
      url
      logo
    }
    __type(name: "MediaType") {
      enumValues {
        name
        description
      }
    }
  }
`);
const START_APP = gql(`
  mutation StartApp2($roomId: String!, $appName: RoomApp!) {
    startApp(roomId: $roomId, appName: $appName) {
      ok
    }
  }
`);
const OPEN_LINK_IN_WEB_BROWSER = gql(`
  mutation OpenLinkInWebBrowser(
    $roomId: String!
    $url: String!
    $kiosk: Boolean!
    $extension: String
  ) {
    openLinkInWebBrowser(
      roomId: $roomId
      url: $url
      kiosk: $kiosk
      extension: $extension
    ) {
      ok
    }
  }
`);
const OPEN_LINK_IN_WEBVIEW = gql(`
  mutation OpenLinkInWebview2($roomId: String!, $url: String!) {
    openLinkInWebview(roomId: $roomId, url: $url) {
      ok
    }
  }
`);
const useResults = (query, categories, types2) => {
  const { data, loading } = useQuery(RESULTS, {
    variables: { query, categories, types: types2 }
  });
  const resultTypes = (data == null ? void 0 : data.mediaContentSearchCategories) ? [...data.mediaContentSearchCategories] : [];
  types2.forEach((t) => {
    if (!resultTypes.includes(t))
      resultTypes.push(t);
  });
  const tags = data ? resultTypes.map((c) => {
    var _a2, _b2;
    return ((_b2 = (_a2 = data.__type) == null ? void 0 : _a2.enumValues) == null ? void 0 : _b2.find((e) => e.name === c)) || {};
  }).filter((c) => !!c) : [];
  return {
    results: (data == null ? void 0 : data.mediaContentSearch) || [],
    tags,
    loading
  };
};
const Item = withDialogs(
  ({
    id,
    title,
    thumbnail,
    logo,
    type,
    url,
    premium,
    setCategories,
    closeModal,
    setModalTitle,
    setMediaTypes,
    alert,
    openCustomScreenshare
  }) => {
    const styles = withStyleHook(style);
    const { setUrl } = useMediaPlayerActions();
    const [startApp, _loading] = useMutation(START_APP);
    const roomId = useRoomId();
    const currentUser = currentUserHook();
    const [openLinkInWebBrowser] = useMutation(OPEN_LINK_IN_WEB_BROWSER);
    const [openLinkInWebview] = useMutation(OPEN_LINK_IN_WEBVIEW);
    if (hidePremium && premium && !currentUser.isSubscribed) {
      return null;
    }
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: styles.item,
        title,
        onClick: () => __async(void 0, null, function* () {
          if (premium && !currentUser.isSubscribed && alert) {
            alert(
              /* @__PURE__ */ React.createElement(
                PaywallMessage,
                {
                  message: "Web browser is for premium users only!",
                  source: "Web browser app"
                }
              ),
              true
            );
            return;
          }
          if (type === "SCREENSHARE") {
            openCustomScreenshare({
              title,
              url,
              logo
            });
            setModalTitle(logo || title);
            return;
          }
          if (type === "CATALOG") {
            setModalTitle(logo || title);
            setCategories([id.toUpperCase()]);
            setMediaTypes([]);
            return;
          }
          if (type === "VIDEO") {
            setUrl(url);
          }
          if (type === "APP") {
            if (id === "web_browser") {
              openLinkInWebBrowser({
                variables: {
                  roomId,
                  url: "",
                  kiosk: false
                }
              });
            } else {
              startApp({
                variables: {
                  roomId,
                  appName: id.toUpperCase()
                }
              });
            }
          }
          if (type === "VBROWSER_APP") {
            openLinkInWebBrowser({
              variables: {
                roomId,
                url,
                kiosk: true,
                extension: id === "tik_tok" ? "tiktok" : void 0
              }
            });
          }
          if (type === "SNES_GAME") {
            ;
            window.queuedGame = url;
            startApp({
              variables: {
                roomId,
                appName: types.RoomApp.SnesParty
              }
            });
          }
          if (type === "NES_GAME") {
            ;
            window.queuedGame = url;
            startApp({
              variables: {
                roomId,
                appName: types.RoomApp.NesParty
              }
            });
          }
          if (type === "PSX_GAME") {
            ;
            window.queuedGame = url;
            startApp({
              variables: {
                roomId,
                appName: types.RoomApp.Psx
              }
            });
          }
          if (type === "WEB_VIEW") {
            let webviewUrl = url;
            if (id === "whiteboard") {
              const response = yield fetch(
                "https://cocreate.csail.mit.edu/api/roomNew"
              );
              webviewUrl = (yield response.json()).url;
            } else if (id === "draw_battle") {
              const response = yield fetch("https://api.drawbattle.io/games", {
                method: "POST"
              });
              webviewUrl = "https://drawbattle.io/" + (yield response.json()).gameId;
            } else if (id === "shared_piano") {
              webviewUrl = `https://musiclab.chromeexperiments.com/Shared-Piano/#kosmi${roomId}`;
            }
            openLinkInWebview({
              variables: {
                roomId,
                url: webviewUrl
              }
            });
          }
          closeModal();
        })
      },
      /* @__PURE__ */ React.createElement(
        "div",
        {
          className: (thumbnail.endsWith(".svg") ? [styles.blurredCover, styles.svg] : [styles.blurredCover]).join(" "),
          style: { backgroundImage: `url(${thumbnail})` }
        }
      ),
      /* @__PURE__ */ React.createElement(
        "div",
        {
          style: { backgroundImage: `url(${thumbnail})` },
          className: (thumbnail.endsWith(".svg") ? [styles.itemCover, styles.svg] : [styles.itemCover]).join(" ")
        }
      ),
      premium && /* @__PURE__ */ React.createElement(
        Diamond,
        {
          className: styles.diamond,
          style: {
            fontSize: 16,
            position: "absolute",
            margin: 10
          }
        }
      ),
      (type === "VIDEO" || thumbnail.includes("cartridge")) && !thumbnail.endsWith(".svg") && /* @__PURE__ */ React.createElement("div", { className: styles.title }, title)
    );
  }
);
export default ({
  categories,
  searchQuery,
  setCategories,
  closeModal,
  setModalTitle,
  openCustomScreenshare,
  setMediaTypes,
  mediaTypes,
  showTags
}) => {
  const styles = withStyleHook(style);
  const { results, loading, tags } = useResults(
    searchQuery,
    categories,
    mediaTypes
  );
  return /* @__PURE__ */ React.createElement("div", { className: styles.root }, /* @__PURE__ */ React.createElement("div", { className: styles.tagsWrapper }, showTags && (tags.length > 1 || !!searchQuery) && /* @__PURE__ */ React.createElement(
    Tags,
    {
      tags,
      selected: mediaTypes,
      onSelect: (mediaType) => setMediaTypes(mediaType ? [mediaType] : [])
    }
  )), /* @__PURE__ */ React.createElement("div", { className: styles.contentBrowser }, loading ? /* @__PURE__ */ React.createElement(Dimmer, { active: true }, /* @__PURE__ */ React.createElement(Loader, { active: true, size: "massive", className: styles.loader })) : results.map((r, i) => /* @__PURE__ */ React.createElement(
    Item,
    {
      key: i,
      id: r.id,
      title: r.title,
      thumbnail: r.thumbnail,
      logo: r.logo,
      type: r.type,
      url: r.url,
      premium: r.premium,
      setCategories,
      setModalTitle,
      setMediaTypes,
      closeModal,
      openCustomScreenshare
    }
  ))));
};
