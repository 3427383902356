"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { gql, useQuery } from "@apollo/client";
import interact from "interactjs";
import { Dimensions } from "kosmi-sdk/room";
import { WebRTCUserVideoTrack as RTCVideo } from "kosmi-sdk/webrtc";
import ReactFitText from "react-fittext/lib/ReactFitText";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withStyleHook } from "../../sdk/styles";
import RoomContext from "./RoomContext";
import style from "./WebcamOverlay.css";
import { useRoomId } from "./withRoomId";
import { useMember } from "./hooks/useMembersData";
const DIAMETER = 25;
const MIN_DIAMETER = 15;
function getCoordsForIndex(index) {
  index = index % 8;
  const iteration = Math.floor(index / 4);
  let coordMultiplier = 100;
  for (let i = 0; i < iteration; i++) {
    coordMultiplier = coordMultiplier / 2;
  }
  const coords = [
    [coordMultiplier, 20],
    [100, coordMultiplier],
    [coordMultiplier === 100 ? 0 : coordMultiplier, 100],
    [0, coordMultiplier == 100 ? 0 : coordMultiplier]
  ];
  return coords[index % 4];
}
const WebcamItem = ({
  onMove,
  diameter,
  coords,
  memberId,
  hidden,
  containerWidth,
  containerHeight
}) => {
  const styles = withStyleHook(style);
  const elRef = useRef(null);
  const [_itemDiameter, setItemDiameter] = useState(0);
  const [dragging, setDragging] = useState(false);
  const member = useMember(memberId);
  const itemDiameter = _itemDiameter || DIAMETER;
  const el = elRef.current;
  const onDrag = (e) => {
    var _a;
    const el2 = elRef.current;
    if (!el2)
      return;
    const { clientX, clientY } = e;
    const { left: left2, top: top2 } = ((_a = el2.parentElement) == null ? void 0 : _a.getBoundingClientRect()) || {
      left: 0,
      top: 0
    };
    const x2 = clientX - left2;
    const y2 = clientY - top2;
    let xPercent = Math.min(Math.max(x2 / containerWidth * 100, 0), 100);
    if (xPercent < itemDiameter / 2)
      xPercent = 0;
    if (xPercent > 100 - itemDiameter / 2)
      xPercent = 100;
    let yPercent = Math.min(Math.max(y2 / containerHeight * 100, 0), 100);
    if (yPercent < itemDiameter / 2)
      yPercent = 0;
    if (yPercent > 100 - itemDiameter / 2)
      yPercent = 100;
    yPercent = Math.min(maxY, yPercent);
    onMove(memberId, [xPercent, yPercent]);
  };
  let maxY = 100;
  let minY = 0;
  let maxX = 100;
  let minX = 0;
  const parentHeight = containerHeight;
  const parentWidth = containerWidth;
  if (el) {
    const itemRadius = (el == null ? void 0 : el.clientHeight) / 2 || 0;
    const elMaxTop = parentHeight - itemRadius;
    const elMaxTopPercent = elMaxTop / parentHeight * 100;
    minY = 100 - elMaxTopPercent;
    maxY = elMaxTopPercent;
    const elMaxLeft = parentWidth - itemRadius;
    const elMaxLeftPercent = elMaxLeft / parentWidth * 100;
    minX = 100 - elMaxLeftPercent;
    maxX = elMaxLeftPercent;
  }
  const circleDiameter = diameter * itemDiameter / 100;
  let [x, y] = coords;
  if (y < minY) {
    y = minY;
  }
  if (y > maxY) {
    y = maxY;
  }
  if (x < minX) {
    x = minX;
  }
  if (x > maxX) {
    x = maxX;
  }
  let left = x + "%";
  let top = y + "%";
  if (x >= 100)
    left = "calc(" + left + " - " + circleDiameter / 2 + "px)";
  else if (x <= 0)
    left = "calc(" + left + " + " + circleDiameter / 2 + "px)";
  if (y >= 100)
    top = "calc(" + top + " - " + circleDiameter / 2 + "px)";
  if (y <= 0)
    top = "calc(" + top + " + " + circleDiameter / 2 + "px)";
  useEffect(() => {
    const el2 = elRef.current;
    let direction = "";
    if (!el2)
      return;
    interact(el2).draggable({ inertia: true }).resizable({
      edges: { left: true, right: true, bottom: true, top: true },
      listeners: {
        start: (event) => {
          setDragging(true);
          const targetRect = event.target.getBoundingClientRect();
          const relativeX = event.clientX - targetRect.left;
          const relativeY = event.clientY - targetRect.top;
          const widthThird = targetRect.width / 3;
          const heightThird = targetRect.height / 3;
          if (relativeX < widthThird && relativeY < heightThird)
            direction = "topLeft";
          else if (relativeX > 2 * widthThird && relativeY < heightThird)
            direction = "topRight";
          else if (relativeX < widthThird && relativeY > 2 * heightThird)
            direction = "bottomLeft";
          else if (relativeX > 2 * widthThird && relativeY > 2 * heightThird)
            direction = "bottomRight";
          else if (relativeY < heightThird)
            direction = "top";
          else if (relativeY > 2 * heightThird)
            direction = "bottom";
          else if (relativeX < widthThird)
            direction = "left";
          else if (relativeX > 2 * widthThird)
            direction = "right";
        },
        move: (event) => {
          setItemDiameter(
            Math.min(
              Math.max(
                (direction === "top" || direction === "bottom" ? event.rect.height : event.rect.width) / diameter * 100,
                MIN_DIAMETER
              ),
              100
            )
          );
          if (direction === "top") {
            onDrag({
              clientX: event.clientX,
              clientY: event.clientY + event.rect.height / 2
            });
          } else if (direction === "bottom") {
            onDrag({
              clientX: event.clientX,
              clientY: event.clientY - event.rect.height / 2
            });
          } else if (direction === "left") {
            onDrag({
              clientX: event.clientX + event.rect.width / 2,
              clientY: event.clientY
            });
          } else if (direction === "right") {
            onDrag({
              clientX: event.clientX - event.rect.width / 2,
              clientY: event.clientY
            });
          } else if (direction === "bottomLeft") {
            onDrag({
              clientX: event.clientX + event.rect.width / 2,
              clientY: event.clientY - event.rect.height / 2
            });
          } else if (direction === "bottomRight") {
            onDrag({
              clientX: event.clientX - event.rect.width / 2,
              clientY: event.clientY - event.rect.height / 2
            });
          } else if (direction === "topRight") {
            onDrag({
              clientX: event.clientX - event.rect.width / 2,
              clientY: event.clientY + event.rect.height / 2
            });
          } else if (direction === "topLeft") {
            onDrag({
              clientX: event.clientX + event.rect.width / 2,
              clientY: event.clientY + event.rect.height / 2
            });
          }
        },
        end: () => setDragging(false)
      },
      modifiers: [
        interact.modifiers.restrictEdges({
          outer: "parent"
        })
      ],
      inertia: true
    }).on("dragmove", (e) => {
      onDrag(e);
    });
    return () => {
      interact(el2).unset();
    };
  }, [containerHeight, containerWidth]);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      ref: elRef,
      className: (dragging ? [styles.webcamOverlayItem, styles.dragging] : [styles.webcamOverlayItem]).join(" "),
      style: {
        top,
        left,
        width: circleDiameter,
        height: circleDiameter,
        display: hidden ? "none" : void 0
      }
    },
    /* @__PURE__ */ React.createElement(RTCVideo, { userId: memberId }),
    /* @__PURE__ */ React.createElement(ReactFitText, { compressor: 0.9 }, /* @__PURE__ */ React.createElement("div", { className: styles.displayName }, (member == null ? void 0 : member.isAnonymous) ? "Anonymous" : (member == null ? void 0 : member.displayName) || (member == null ? void 0 : member.username)))
  );
};
const CURRENT_USER = gql(`
  query CurrentUserQuery2($roomId: String!) {
    currentUser {
      id
      connectionId
      user {
        id
        displayName
        username
        isAnonymous
      }
    }
    room(id: $roomId) {
      id
      state {
        members {
          id
          role
          user {
            id
            username
            displayName
            avatarUrl
          }
        }
      }
    }
  }
`);
const WebcamOverlay = ({
  containerHeight,
  containerWidth,
  hidden
}) => {
  const roomId = useRoomId();
  const { data } = useQuery(CURRENT_USER, { variables: { roomId } });
  const roomMemberIds = (data == null ? void 0 : data.room.state.members.map((m) => m.user.id)) || [];
  const [positions, setPositions] = useState(
    {}
  );
  useEffect(() => {
    const positionMemberIds = Object.keys(positions);
    const updatedPositions = __spreadValues({}, positions);
    for (const userId of positionMemberIds) {
      if (roomMemberIds.indexOf(userId) === -1) {
        delete updatedPositions[userId];
      }
    }
    setPositions(updatedPositions);
  }, [roomMemberIds.length]);
  const diameter = Math.min(containerWidth, containerHeight);
  const { webcamStreams } = useContext(RoomContext);
  const memberIds = Object.keys(webcamStreams || {});
  return /* @__PURE__ */ React.createElement(React.Fragment, null, memberIds.map((mid, i) => {
    const coords = positions[mid] || getCoordsForIndex(i);
    return /* @__PURE__ */ React.createElement(
      WebcamItem,
      {
        key: mid,
        hidden,
        coords,
        diameter,
        containerWidth,
        containerHeight,
        memberId: mid,
        onMove: (memberId, xy) => {
          setPositions((prevPositions) => __spreadProps(__spreadValues({}, prevPositions), {
            [memberId]: xy
          }));
        }
      }
    );
  }));
};
export default Dimensions()(WebcamOverlay);
