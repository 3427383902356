"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Button, Checkbox, Icon } from "kosmi-sdk/uikit";
import React, { useState } from "react";
import AudioEngine from "../../../../core/services/AVEngine";
import { withStyleHook } from "../../../sdk/styles";
import { useTracking } from "../../helpers/withTracking";
import Modal from "../AnimatedModal";
import style from "./style.css";
import TabDialogImage from "./tabDialog.jpg";
const ScreenCaptureForm = ({
  onStarted,
  onSelectTab,
  onCancelled
}) => {
  const [showRoomUrl, setShowRoomUrl] = useState(true);
  const [captureError, setCaptureError] = useState(false);
  const [pendingRecord, setPendingRecord] = useState(false);
  const [gotDisplayMedia, setGotDisplayMedia] = useState(false);
  const styles = withStyleHook(style);
  const startedCallback = () => {
    setPendingRecord(false);
    onStarted(showRoomUrl);
    onSelectTab();
  };
  const captureErrorCallback = () => {
    setCaptureError(true);
  };
  const getUserMedia = () => __async(void 0, null, function* () {
    setPendingRecord(true);
    yield AudioEngine.getDisplayMediaStream();
    setGotDisplayMedia(true);
    AudioEngine.startRecordingDisplayMediaStream(
      onCancelled,
      startedCallback,
      captureErrorCallback
    );
  });
  const resetState = () => {
    setGotDisplayMedia(false);
    setShowRoomUrl(true);
    setCaptureError(false);
    setPendingRecord(false);
  };
  const renderBody = () => {
    if (captureError) {
      return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: styles.column }, /* @__PURE__ */ React.createElement(
        Icon,
        {
          name: "warning circle",
          size: "huge",
          style: { color: "#fff377", marginRight: 10 }
        }
      ), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h2", { className: styles.errorTitle }, "We can't hear you!"), /* @__PURE__ */ React.createElement("div", null, "Please make sure you follow the instructions carefully to select the current Kosmi tab in your browser when asked."), /* @__PURE__ */ React.createElement("div", null, "Please also ensure ", /* @__PURE__ */ React.createElement("b", null, "Share tab audio"), " is selected."))), /* @__PURE__ */ React.createElement(Button, { fluid: true, color: "yellow", onClick: resetState }, "Try Again"));
    } else {
      return /* @__PURE__ */ React.createElement("div", { className: styles.column }, /* @__PURE__ */ React.createElement("div", { className: styles.selectionPreview }, /* @__PURE__ */ React.createElement(
        "img",
        {
          className: styles.selectionPreviewImage,
          src: TabDialogImage
        }
      ), /* @__PURE__ */ React.createElement("div", { className: styles.column }, /* @__PURE__ */ React.createElement(
        Icon,
        {
          name: "warning circle",
          size: "large",
          style: { color: "#d01919", marginRight: 10 }
        }
      ), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", null, "Screen recording is heavy on resources."), /* @__PURE__ */ React.createElement("div", null, "Consider a native recorder (e.g.", " ", /* @__PURE__ */ React.createElement("b", null, /* @__PURE__ */ React.createElement("a", { href: "https://obsproject.com/" }, "OBS")), ") if you have a low-end machine.")))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h2", null, "Share you experience with Kosmi"), /* @__PURE__ */ React.createElement("div", null, "You can now record the tab and download the video to share."), /* @__PURE__ */ React.createElement("div", null, "Make sure you make the following selections when asked:"), /* @__PURE__ */ React.createElement("ol", null, /* @__PURE__ */ React.createElement("li", null, "Select ", /* @__PURE__ */ React.createElement("b", null, "Chrome tab")), /* @__PURE__ */ React.createElement("li", null, "Click your ", /* @__PURE__ */ React.createElement("b", null, "Kosmi tab")), /* @__PURE__ */ React.createElement("li", null, "Click ", /* @__PURE__ */ React.createElement("b", null, "Share"))), /* @__PURE__ */ React.createElement("div", { className: styles.column }, /* @__PURE__ */ React.createElement(
        Icon,
        {
          name: "headphones",
          size: "large",
          style: { color: "#fff377", marginRight: 10 }
        }
      ), /* @__PURE__ */ React.createElement("div", null, "Your microphone will be recorded if you have it enabled, so please wear headphones to prevent feedback.")), /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          label: "Show URL in video capture",
          toggle: true,
          checked: showRoomUrl,
          onChange: (_e, data) => {
            setShowRoomUrl(data.checked);
          }
        }
      )));
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: styles.screenRecorderModal }, renderBody(), !captureError && /* @__PURE__ */ React.createElement(
    Button,
    {
      fluid: true,
      color: "red",
      disabled: !captureError && pendingRecord && gotDisplayMedia,
      onClick: captureError ? resetState : getUserMedia
    },
    /* @__PURE__ */ React.createElement(
      Icon,
      {
        style: {
          color: "white"
        },
        name: "circle"
      }
    ),
    pendingRecord && gotDisplayMedia ? "Starting recording..." : "Start recording"
  ));
};
const ScreenCaptureModal = ({
  onRecordingStarted,
  onSelectingTab,
  onRecordingCancelled,
  onClose,
  open
}) => {
  const track = useTracking();
  const handleStartRecording = (showRoomUrl) => {
    onRecordingStarted(showRoomUrl);
  };
  const handleOnSelectTab = () => {
    onSelectingTab();
  };
  const handleScreenRecordingCancelled = () => {
    onRecordingCancelled();
  };
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      size: "small",
      closeIcon: true,
      open,
      onOpen: () => track("UI", "ScreenCaptureModalOpened", "Screen capture modal opened"),
      onClose: () => {
        onClose();
      }
    },
    /* @__PURE__ */ React.createElement(Modal.Header, { style: { display: "flex" } }, "Start screen recording"),
    /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(
      ScreenCaptureForm,
      {
        onCancelled: handleScreenRecordingCancelled,
        onSelectTab: handleOnSelectTab,
        onStarted: handleStartRecording
      }
    ))
  );
};
export default ScreenCaptureModal;
