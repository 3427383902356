"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState } from "react";
import style from "./style.css";
import { withStyleHook } from "../../../../sdk/styles";
import { Button, Checkbox, Input } from "kosmi-sdk/uikit";
import { useLinkedMembersCached, useMembers } from "../../hooks/useMembersData";
import { useRoomId } from "../../withRoomId";
import Modal from "../../../Widgets/AnimatedModal";
import { gql, useMutation } from "kosmi-sdk/gql";
import { currentUserHook, useTracking, withDialogs } from "kosmi-sdk/helpers";
const INVITE_MEMBERS_BACK = gql(`
  mutation InviteMembersBackToRoom(
    $roomId: String!
    $message: String!
    $userIds: [String]!
  ) {
    inviteMembersBackToRoom(
      roomId: $roomId
      userIds: $userIds
      message: $message
    ) {
      ok
    }
  }
`);
const INVITE_ALL_MEMBERS_BACK = gql(`
  mutation InviteAllMembersBackToRoom($roomId: String!, $message: String!) {
    inviteAllMembersBackToRoom(message: $message, roomId: $roomId) {
      ok
    }
  }
`);
const MembersModal = ({
  trigger,
  onSent,
  onError
}) => {
  const roomId = useRoomId();
  const styles = withStyleHook(style);
  const { linkedMembers } = useLinkedMembersCached(roomId);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [modal, setModal] = useState(null);
  const [message, setMessage] = useState("");
  const selectAll = selectedUserIds.length === linkedMembers.length;
  const toggleUserId = (userId) => {
    if (!selectedUserIds.includes(userId)) {
      setSelectedUserIds([...selectedUserIds, userId]);
    } else {
      setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
    }
  };
  const [inviteMembersBackToRoom] = useMutation(INVITE_MEMBERS_BACK, {
    variables: { roomId, message, userIds: selectedUserIds }
  });
  const [inviteAllMembersBackToRoom] = useMutation(INVITE_ALL_MEMBERS_BACK, {
    variables: { roomId, message }
  });
  const close = () => (modal ? modal.handleClose : () => {
  })();
  const sendInvites = () => __async(void 0, null, function* () {
    try {
      yield selectAll ? inviteAllMembersBackToRoom() : inviteMembersBackToRoom();
      close();
      if (onSent)
        onSent();
    } catch (e) {
      if (onError)
        onError((e == null ? void 0 : e.message) || "Unknown Error");
      close();
    }
  });
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      trigger,
      closeIcon: "close",
      onModal: (modal2) => setModal(modal2)
    },
    /* @__PURE__ */ React.createElement(Modal.Header, { icon: "user circle outline" }, "Select members to invite to the room"),
    /* @__PURE__ */ React.createElement(Modal.Content, { className: styles.content }, /* @__PURE__ */ React.createElement(
      Input,
      {
        value: message,
        onChange: (e) => {
          setMessage(e.target.value);
        },
        placeholder: "Message (optional)"
      }
    ), /* @__PURE__ */ React.createElement(
      Checkbox,
      {
        className: styles.checkbox,
        checked: selectAll,
        onChange: () => {
          if (selectAll)
            setSelectedUserIds([]);
          else {
            setSelectedUserIds(
              linkedMembers.map((member) => {
                var _a;
                return (_a = member == null ? void 0 : member.user) == null ? void 0 : _a.id;
              })
            );
          }
        },
        label: "All room members"
      }
    ), /* @__PURE__ */ React.createElement("div", { className: styles.members }, linkedMembers.map((member) => {
      var _a, _b, _c, _d;
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          className: styles.member,
          key: member.id,
          onClick: () => {
            var _a2;
            return toggleUserId((_a2 = member == null ? void 0 : member.user) == null ? void 0 : _a2.id);
          }
        },
        /* @__PURE__ */ React.createElement(
          Checkbox,
          {
            checked: selectAll || selectedUserIds.includes((_a = member == null ? void 0 : member.user) == null ? void 0 : _a.id),
            onChange: () => {
              var _a2;
              toggleUserId((_a2 = member == null ? void 0 : member.user) == null ? void 0 : _a2.id);
            }
          }
        ),
        /* @__PURE__ */ React.createElement("img", { src: (_b = member == null ? void 0 : member.user) == null ? void 0 : _b.avatarUrl }),
        ((_c = member == null ? void 0 : member.user) == null ? void 0 : _c.displayName) || ((_d = member == null ? void 0 : member.user) == null ? void 0 : _d.username)
      );
    }))),
    /* @__PURE__ */ React.createElement(Modal.Actions, null, /* @__PURE__ */ React.createElement(Button, { color: "white", inverted: true, onClick: close, style: { margin: 5 } }, "Cancel"), /* @__PURE__ */ React.createElement(
      Button,
      {
        disabled: !selectedUserIds.length,
        color: "blue",
        onClick: sendInvites,
        style: { margin: 5 }
      },
      "Send Invite"
    ))
  );
};
export default withDialogs(({ alert }) => {
  const currentUser = currentUserHook();
  const track = useTracking();
  const styles = withStyleHook(style);
  const roomId = useRoomId();
  const { linkedMembers } = useLinkedMembersCached(roomId);
  const members = useMembers(roomId);
  const [_dismiss, setDismiss] = useState(false);
  const dismiss = () => {
    ;
    window.inviteBackDismissed = true;
    setDismiss(true);
    track("UI", "InviteBackDismissed", "Invite Back Dismissed");
  };
  const realm = location.host.endsWith(".kosmi.business") ? location.host.split(".kosmi.business")[0] : null;
  if (roomId === "lobby" || !!realm)
    return null;
  if (currentUser == null ? void 0 : currentUser.isAnonymous)
    return null;
  if (members.length > 1)
    return null;
  if (linkedMembers.length === 0)
    return null;
  if (_dismiss)
    return null;
  if (window.inviteBackDismissed)
    return null;
  return /* @__PURE__ */ React.createElement("div", { className: styles.inviteEveryone }, /* @__PURE__ */ React.createElement("div", null, "None of this room`s members are here. Invite them back?"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    MembersModal,
    {
      trigger: /* @__PURE__ */ React.createElement(Button, { size: "s", color: "yellow", style: { margin: 5 } }, "See members"),
      onSent: () => {
        setDismiss(true);
        track("UI", "InviteBackSent", "Invites Back Sent");
        alert && alert("Invites sent!");
      },
      onError: alert || (() => {
      })
    }
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "white",
      size: "s",
      onClick: dismiss,
      style: { margin: 5 },
      inverted: true
    },
    "Dismiss"
  )));
});
