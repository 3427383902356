"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import onElementResize, { unbind } from "element-resize-event";
import React, { memo, useCallback, useEffect, useState } from "react";
export default function Dimensions() {
  return (Component) => {
    return memo((props) => {
      const [containerHeight, setContainerHeight] = useState(
        null
      );
      const [containerWidth, setContainerWidth] = useState(
        null
      );
      const [container, setContainer] = useState(null);
      const containerRef = useCallback((node) => {
        if (node !== null) {
          setContainer(node);
        }
      }, []);
      const updateDimensions = useCallback(() => {
        setContainerWidth(container.clientWidth);
        setContainerHeight(container.clientHeight);
      }, [container]);
      useEffect(() => {
        if (!container)
          return;
        onElementResize(container, updateDimensions);
        updateDimensions();
        return () => {
          unbind(container);
        };
      }, [container, updateDimensions]);
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          ref: containerRef,
          style: {
            border: 0,
            height: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            minHeight: "100%",
            minWidth: "100%",
            padding: 0,
            position: "absolute",
            width: "100%",
            margin: 0,
            flex: 1
          }
        },
        (!!containerWidth || !!containerHeight) && /* @__PURE__ */ React.createElement(Component, __spreadValues(__spreadValues({}, { containerWidth, containerHeight }), props))
      );
    });
  };
}
