"use strict";
import { currentUserHook } from "kosmi-sdk/helpers";
import { Popup, ContextMenu, Button } from "kosmi-sdk/uikit";
import React, { memo, useEffect, useState } from "react";
import { withStyleHook } from "../../../../../../../../sdk/styles";
import LoginModal from "../../../../../../../Widgets/LoginModal";
import LogoutButton from "../../../../../../../Widgets/LogoutButton";
import ProfileModal from "../../../../../../../Widgets/ProfileModal";
import SettingsModal from "../../../../../../../Widgets/SettingsModal";
import style from "../../style.css";
import { TopBarButton } from "../../index";
const ProfileMenu = memo(() => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const currentUser = currentUserHook();
  const styles = withStyleHook(style);
  return /* @__PURE__ */ React.createElement("div", { className: styles.profileMenu }, /* @__PURE__ */ React.createElement(
    ContextMenu,
    {
      open: showProfileModal,
      onClose: () => {
        setShowProfileModal(false);
      },
      trigger: /* @__PURE__ */ React.createElement(TopBarButton, { onClick: () => setShowProfileModal(!showProfileModal) }, /* @__PURE__ */ React.createElement("div", { className: styles.avatar }, /* @__PURE__ */ React.createElement("img", { src: currentUser.avatarUrl })))
    },
    /* @__PURE__ */ React.createElement(
      ProfileModal,
      {
        trigger: /* @__PURE__ */ React.createElement(ContextMenu.Item, { icon: "user outline", text: "Account Settings" })
      }
    ),
    /* @__PURE__ */ React.createElement(
      SettingsModal,
      {
        trigger: /* @__PURE__ */ React.createElement(ContextMenu.Item, { icon: "setting", text: "App Settings" })
      }
    ),
    /* @__PURE__ */ React.createElement(
      LogoutButton,
      {
        trigger: /* @__PURE__ */ React.createElement(ContextMenu.Item, { icon: "sign out", text: "Log out" })
      }
    )
  ));
});
const LoginButtons = memo(() => {
  const [showTooltip, setShowTooltip] = useState(true);
  const currentUser = currentUserHook();
  const styles = withStyleHook(style);
  useEffect(() => {
    if (currentUser.isAnonymous) {
      const interval = setInterval(() => {
        setShowTooltip(true);
      }, 1e3 * 60 * 20);
      return () => {
        clearInterval(interval);
      };
    }
  }, [setShowTooltip, showTooltip, currentUser == null ? void 0 : currentUser.isAnonymous]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    LoginModal,
    {
      trigger: ({ onClick }) => /* @__PURE__ */ React.createElement(
        Popup,
        {
          open: currentUser.isAnonymous && showTooltip,
          content: "By logging in you can change your name, avatar, add friends and sync your rooms across devices.",
          onClickOutside: () => {
            setShowTooltip(false);
          },
          onClick: () => {
            setShowTooltip(false);
          },
          trigger: /* @__PURE__ */ React.createElement(
            Button,
            {
              color: "white",
              inverted: true,
              className: styles.loginButton,
              onClick
            },
            "Login"
          )
        }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    LoginModal,
    {
      signup: true,
      trigger: ({ onClick }) => /* @__PURE__ */ React.createElement(
        Button,
        {
          color: "white",
          inverted: true,
          className: styles.loginButton,
          onClick
        },
        "Signup"
      )
    }
  ));
});
export default memo(() => {
  const currentUser = currentUserHook();
  if (currentUser.isAnonymous)
    return /* @__PURE__ */ React.createElement(LoginButtons, null);
  return /* @__PURE__ */ React.createElement(ProfileMenu, null);
});
