"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Icon = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const iconLoading = "x1wc8ddo-B";
const styles = {
  icon: {
    "Icon__styles.icon": "Icon__styles.icon",
    display: "x3nfvp2",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    opacity: "x1hc1fzr",
    width: "x15vhz18",
    height: "xlzyvqe",
    fontFamily: "xyw5xrh",
    fontStyle: "x1j61x8r",
    fontWeight: "x1fcty0u",
    textDecoration: "x1r8a4m5",
    textDecorationColor: null,
    textDecorationLine: null,
    textDecorationStyle: null,
    textDecorationThickness: null,
    backfaceVisibility: "xlp1x4z",
    margin: "xrvdr0l",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "xrv4cvt",
    "::before_background": "x1y165du",
    "::before_backgroundAttachment": null,
    "::before_backgroundClip": null,
    "::before_backgroundColor": null,
    "::before_backgroundImage": null,
    "::before_backgroundOrigin": null,
    "::before_backgroundPosition": null,
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    "::before_backgroundRepeat": null,
    "::before_backgroundSize": null,
    $$css: true
  },
  loading: {
    "Icon__styles.loading": "Icon__styles.loading",
    height: "xlzyvqe",
    lineHeight: "xo5v014",
    animationName: "x1aerksh",
    animationDuration: "x1c74tu6",
    animationTimingFunction: "x1esw782",
    animationIterationCount: "xa4qsjk",
    $$css: true
  },
  link: {
    "Icon__styles.link": "Icon__styles.link",
    cursor: "x1ypdohk",
    opacity: "x1us6l5c",
    transition: "x19n8f2o",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    ":hover_opacity": "x1o7uuvo",
    $$css: true
  },
  circular: {
    "Icon__styles.circular": "Icon__styles.circular",
    borderRadius: "xy3cocn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    lineHeight: "xo5v014",
    padding: "x1ghdu0b",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    boxShadow: "x12x4fey",
    width: "xk2f0lv",
    height: "x19hywkt",
    $$css: true
  },
  red: {
    "Icon__styles.red": "Icon__styles.red",
    color: "xdi3vc4",
    $$css: true
  },
  yellow: {
    "Icon__styles.yellow": "Icon__styles.yellow",
    color: "x16cd2qt",
    $$css: true
  },
  white: {
    "Icon__styles.white": "Icon__styles.white",
    color: "x11jfisy",
    $$css: true
  },
  green: {
    "Icon__styles.green": "Icon__styles.green",
    color: "xdf6pim",
    $$css: true
  },
  blue: {
    "Icon__styles.blue": "Icon__styles.blue",
    color: "xzwifym",
    $$css: true
  },
  small: {
    "Icon__styles.small": "Icon__styles.small",
    lineHeight: "xo5v014",
    fontSize: "x3u6hjl",
    $$css: true
  },
  large: {
    "Icon__styles.large": "Icon__styles.large",
    lineHeight: "xo5v014",
    verticalAlign: "xxymvpz",
    fontSize: "xkzkwiv",
    $$css: true
  },
  big: {
    "Icon__styles.big": "Icon__styles.big",
    lineHeight: "xo5v014",
    verticalAlign: "xxymvpz",
    fontSize: "xodt8ld",
    $$css: true
  },
  huge: {
    "Icon__styles.huge": "Icon__styles.huge",
    lineHeight: "xo5v014",
    verticalAlign: "xxymvpz",
    fontSize: "x7ckqch",
    $$css: true
  },
  massive: {
    "Icon__styles.massive": "Icon__styles.massive",
    lineHeight: "xo5v014",
    verticalAlign: "xxymvpz",
    fontSize: "x1ce735w",
    $$css: true
  }
};
const Icon = exports.Icon = (0, _react.forwardRef)(({
  id,
  name,
  className,
  color,
  size,
  link,
  circular,
  onClick
}, ref) => {
  const {
    className: stylexClassName
  } = stylex.props(styles.icon, size && styles[size], color && styles[color], circular && styles.circular, link && styles.link, className instanceof Object && className);
  const classNames = ["icon", stylexClassName];
  if (name) classNames.push(name);
  if (typeof className === "string") {
    classNames.push(className);
  }
  return /* @__PURE__ */_react.default.createElement("i", {
    id,
    className: classNames.join(" "),
    onClick,
    ref
  });
});