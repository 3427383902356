"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _AVEngine = _interopRequireDefault(require("../../../../../../services/AVEngine"));
var _helpers = require("kosmi-sdk/helpers");
var _tabDialog = _interopRequireDefault(require("./tabDialog.jpg"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  screenRecorderModal: {
    "index__styles.screenRecorderModal": "index__styles.screenRecorderModal",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    "::marker_marginRight": "xhovac",
    "::marker_marginInlineStart": null,
    "::marker_marginInlineEnd": null,
    $$css: true
  },
  column: {
    "index__styles.column": "index__styles.column",
    display: "x78zum5",
    marginBottom: "xieb3on",
    $$css: true
  },
  selectionPreview: {
    "index__styles.selectionPreview": "index__styles.selectionPreview",
    height: "x127qqk8",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    marginRight: "x1wh8b8d",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginBottom: "xiv7p99",
    color: "x11jfisy",
    $$css: true
  },
  a: {
    "index__styles.a": "index__styles.a",
    color: "x16tr98c",
    $$css: true
  },
  b: {
    "index__styles.b": "index__styles.b",
    color: "x16tr98c",
    $$css: true
  },
  selectionPreviewImage: {
    "index__styles.selectionPreviewImage": "index__styles.selectionPreviewImage",
    height: "x127qqk8",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    marginRight: "x1wh8b8d",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginBottom: "xyorhqc",
    $$css: true
  },
  errorTitle: {
    "index__styles.errorTitle": "index__styles.errorTitle",
    color: "x11jfisy",
    $$css: true
  },
  warning: {
    "index__styles.warning": "index__styles.warning",
    color: "x16tr98c",
    marginRight: "xyo1k6t",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  circle: {
    "index__styles.circle": "index__styles.circle",
    color: "x1awj2ng",
    $$css: true
  },
  headphones: {
    "index__styles.headphones": "index__styles.headphones",
    color: "x16tr98c",
    marginRight: "xyo1k6t",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  modalHeader: {
    "index__styles.modalHeader": "index__styles.modalHeader",
    display: "x78zum5",
    $$css: true
  }
};
const ScreenCaptureForm = ({
  onStarted,
  onSelectTab,
  onCancelled
}) => {
  const [showRoomUrl, setShowRoomUrl] = (0, _react.useState)(true);
  const [captureError, setCaptureError] = (0, _react.useState)(false);
  const [pendingRecord, setPendingRecord] = (0, _react.useState)(false);
  const [gotDisplayMedia, setGotDisplayMedia] = (0, _react.useState)(false);
  const startedCallback = () => {
    setPendingRecord(false);
    onStarted(showRoomUrl);
    onSelectTab();
  };
  const captureErrorCallback = () => {
    setCaptureError(true);
  };
  const getUserMedia = () => __async(void 0, null, function* () {
    setPendingRecord(true);
    yield _AVEngine.default.getDisplayMediaStream();
    setGotDisplayMedia(true);
    _AVEngine.default.startRecordingDisplayMediaStream(onCancelled, startedCallback, captureErrorCallback);
  });
  const resetState = () => {
    setGotDisplayMedia(false);
    setShowRoomUrl(true);
    setCaptureError(false);
    setPendingRecord(false);
  };
  const renderBody = () => {
    if (captureError) {
      return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.column
      }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
        name: "warning circle",
        size: "huge",
        className: styles.warning
      }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.errorTitle
      }, "We can't hear you!"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Please make sure you follow the instructions carefully to select the current Kosmi tab in your browser when asked."), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Please also ensure", " ", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.b
      }, "Share tab audio"), " is selected."))), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        fluid: true,
        color: "yellow",
        onClick: resetState
      }, "Try Again"));
    } else {
      return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.column
      }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.selectionPreview
      }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
        className: styles.selectionPreviewImage,
        src: _tabDialog.default
      }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.column
      }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
        name: "warning circle",
        size: "large",
        className: styles.warning
      }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Screen recording is heavy on resources."), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Consider a native recorder (e.g.", " ", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
        style: styles.a,
        href: "https://obsproject.com/"
      }, "OBS")), ") if you have a low-end machine.")))), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.h2, null, "Share you experience with Kosmi"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "You can now record the tab and download the video to share."), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Make sure you make the following selections when asked:"), /* @__PURE__ */_react.default.createElement(_uikit.Html.ol, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.li, null, "Select ", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.b
      }, "Chrome tab")), /* @__PURE__ */_react.default.createElement(_uikit.Html.li, null, "Click your ", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.b
      }, "Kosmi tab")), /* @__PURE__ */_react.default.createElement(_uikit.Html.li, null, "Click ", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.b
      }, "Share"))), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.column
      }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
        name: "headphones",
        size: "large",
        className: styles.headphones
      }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Your microphone will be recorded if you have it enabled, so please wear headphones to prevent feedback.")), /* @__PURE__ */_react.default.createElement(_uikit.Checkbox, {
        label: "Show URL in video capture",
        toggle: true,
        checked: showRoomUrl,
        onChange: (_e, data) => {
          setShowRoomUrl(data.checked);
        }
      })));
    }
  };
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.screenRecorderModal
  }, renderBody(), !captureError && /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    fluid: true,
    color: "red",
    disabled: !captureError && pendingRecord && gotDisplayMedia,
    onClick: captureError ? resetState : getUserMedia
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.circle,
    name: "circle"
  }), pendingRecord && gotDisplayMedia ? "Starting recording..." : "Start recording"));
};
const ScreenCaptureModal = ({
  onRecordingStarted,
  onSelectingTab,
  onRecordingCancelled,
  onClose,
  open
}) => {
  const track = (0, _helpers.useTracking)();
  const handleStartRecording = showRoomUrl => {
    onRecordingStarted(showRoomUrl);
  };
  const handleOnSelectTab = () => {
    onSelectingTab();
  };
  const handleScreenRecordingCancelled = () => {
    onRecordingCancelled();
  };
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open,
    onOpen: () => track("UI", "ScreenCaptureModalOpened", "Screen capture modal opened"),
    onClose: () => {
      onClose();
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, {
    style: styles.modalHeader
  }, "Start screen recording"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(ScreenCaptureForm, {
    onCancelled: handleScreenRecordingCancelled,
    onSelectTab: handleOnSelectTab,
    onStarted: handleStartRecording
  })));
};
var _default = exports.default = ScreenCaptureModal;