"use strict";
import { useSubscription } from "kosmi-sdk/gql";
import { useEffect } from "react";
export default ({ onUpdate, subscription, variables }) => {
  const { data } = useSubscription(subscription, { variables });
  useEffect(() => {
    if (data)
      setTimeout(() => onUpdate(data));
  }, [JSON.stringify(data)]);
  return null;
};
