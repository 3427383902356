"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _momentMini = _interopRequireDefault(require("moment-mini"));
var _react = _interopRequireWildcard(require("react"));
var _Spotlight = _interopRequireDefault(require("../Spotlight"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Tooltip = _interopRequireDefault(require("../Tooltip"));
var _Button = require("../Button");
var _ContextMenu = require("../ContextMenu");
var _reactResponsive = require("react-responsive");
var _Premium = require("../../components/Widgets/Premium");
var _MessageContainer = _interopRequireDefault(require("./MessageContainer"));
var _helpers = require("../helpers");
var _Messagebox = _interopRequireDefault(require("./Messagebox"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const formatTimeOrDate = unixTime => {
  const yourDate = _momentMini.default.unix(unixTime);
  const today = (0, _momentMini.default)();
  return yourDate.isSame(today, "day") ? yourDate.format("hh:mm A") : yourDate.format("YYYY-MM-DD");
};
const formatTime = unixTime => {
  const yourDate = _momentMini.default.unix(unixTime);
  const today = (0, _momentMini.default)();
  return yourDate.isSame(today, "day") ? yourDate.format("hh:mm A") : `${yourDate.fromNow()} at ${yourDate.format("hh:mm")}`;
};
function mergeRefs(...refs) {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    });
  };
}
const TouchableOpacityWithClass = (0, _react.forwardRef)((_a, ref) => {
  var _b = _a,
    {
      className,
      style,
      onLongPress
    } = _b,
    props = __objRest(_b, ["className", "style", "onLongPress"]);
  const innerRef = (0, _react.useRef)(null);
  const [isPressed, setIsPressed] = (0, _react.useState)(false);
  const [clicked, setClicked] = (0, _react.useState)(false);
  const callback = (0, _react.useCallback)(() => {
    setIsPressed(true);
  }, []);
  const {
    onTouchStart,
    onTouchEnd,
    onTouchMove
  } = (0, _helpers.useLongPress)(callback);
  (0, _react.useEffect)(() => {
    if (onLongPress && isPressed) {
      onLongPress();
      setClicked(false);
      setIsPressed(false);
    }
  }, [isPressed]);
  return /* @__PURE__ */_react.default.createElement("div", __spreadValues({
    onTouchStart: e => {
      onTouchStart(e);
      setClicked(true);
      return false;
    },
    onTouchEnd: e => {
      onTouchEnd(e);
      setClicked(false);
      return false;
    },
    onTouchMove,
    className,
    ref: mergeRefs(ref, innerRef),
    style: __spreadProps(__spreadValues({}, style), {
      transform: clicked ? "scale(0.9)" : void 0
    })
  }, props));
});
const styles = {
  chatMessage: {
    "index__styles.chatMessage": "index__styles.chatMessage",
    position: "x1n2onr6",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    background: "x11g6tue",
    backgroundColor: "x1hwrwdo xlihjin",
    margin: "x1ghz6dp",
    padding: "x1717udv",
    paddingTop: "x1y1aw1k",
    paddingBottom: "x1120s5i",
    borderTopWidth: "x972fbf",
    lineHeight: "x1u7k74",
    borderStyle: "xng3xce",
    borderRadius: "x12oqio5",
    userSelect: "x87ps6o",
    $$css: true
  },
  hasPrevMessage: {
    "index__styles.hasPrevMessage": "index__styles.hasPrevMessage",
    paddingTop: "xexx8yu",
    paddingBottom: "x18d9i69",
    $$css: true
  },
  rubyGlow: {
    "index__styles.rubyGlow": "index__styles.rubyGlow",
    color: "xozz0dy",
    $$css: true
  },
  author: {
    "index__styles.author": "index__styles.author",
    fontSize: "x1j61zf2",
    display: "x78zum5",
    color: "x11jfisy",
    fontWeight: "x117nqv4",
    "@media (max-width: 1000px)_fontSize": "x1xnrtps",
    overflow: "xb3r6kr",
    textOverflow: "xlyipyv",
    width: "xeq5yr9",
    $$css: true
  },
  text: {
    "index__styles.text": "index__styles.text",
    marginTop: "xr9ek0c",
    marginBottom: "xjpr12u",
    fontSize: "x1j61zf2",
    display: "x78zum5",
    marginLeft: "x1gh5o4n",
    overflowWrap: "xj0a0fe",
    flexDirection: "xdt5ytf",
    color: "x11jfisy",
    backgroundColor: "x8897o3",
    lineHeight: "xwn7fz2",
    userSelect: "xmqdh3j",
    "@media (max-width: 1000px)_fontSize": "x1xnrtps",
    "@media (max-width: 1000px)_userSelect": "x1de8jf8",
    borderRadius: "xgqmno8",
    padding: "xe8ttls",
    paddingLeft: "xf18ygs",
    paddingRight: "xnuq7ks",
    width: "xeq5yr9",
    maxWidth: "xv0phki",
    transition: "x112o3c2",
    $$css: true
  },
  emoji: {
    "index__styles.emoji": "index__styles.emoji",
    fontSize: "x1s3cmhv",
    $$css: true
  },
  isOwn: {
    "index__styles.isOwn": "index__styles.isOwn",
    backgroundColor: "x8qxh4v",
    marginLeft: "x8x9d4c",
    $$css: true
  }
};
const ChatMessageMenu = (0, _react.forwardRef)(({
  actions,
  style,
  className,
  onClick,
  onClose,
  time
}, ref) => {
  const {
    className: styleXClassName
  } = {
    className: "index__styles.chatMessageMenu x78zum5 xdt5ytf"
  };
  const {
    className: styleXChatMessageMenuClassName
  } = {
    className: "index__styles.chatMessageMenuButtons x78zum5"
  };
  const {
    className: mobileTimeClassName
  } = {
    className: "index__styles.mobileTime x78zum5 xl56j7k x7z7khe"
  };
  return /* @__PURE__ */_react.default.createElement("div", {
    ref,
    style,
    className: className ? styleXClassName + " " + className : styleXClassName,
    onClick
  }, /* @__PURE__ */_react.default.createElement("div", {
    className: mobileTimeClassName
  }, time), /* @__PURE__ */_react.default.createElement("div", {
    className: styleXChatMessageMenuClassName
  }, actions.map((a, i) => a.onClick && /* @__PURE__ */_react.default.createElement(_Button.Button, {
    color: "white",
    inverted: true,
    key: i,
    icon: a.icon,
    onClick: () => __async(void 0, null, function* () {
      if (yield a.onClick()) {
        onClose();
      }
    }),
    style: {
      marginLeft: 4,
      marginRight: 4
    }
  }))));
});
const ChatMessageDate = (0, _react.memo)(({
  className,
  time,
  onClick,
  style
}) => {
  const {
    className: styleXClassName
  } = {
    className: "index__styles.date x78zum5 xrkdtlx xfifm61 x12nagc x1gslohp x8x9d4c xack27t x552jh6 xzgahhe"
  };
  return /* @__PURE__ */_react.default.createElement("div", {
    onClick,
    style,
    className: styleXClassName + (className ? " " + className : "")
  }, formatTimeOrDate(time));
});
const ChatMessageText = (0, _react.memo)(({
  children,
  className,
  isOwn,
  removeMessage,
  text,
  time,
  rootNode
}) => {
  const timeStr = formatTime(time);
  const isEmoji = Array.from(text).every(c => new RegExp("\\p{Emoji}", "u").test(c) && isNaN(parseInt(c)));
  const {
    className: styleXClassName
  } = stylex.props(styles.text, isOwn && styles.isOwn, isEmoji && styles.emoji);
  const ref = (0, _react.useRef)(null);
  const [focused, setFocused] = _react.default.useState(false);
  const clonedChildren = _react.default.cloneElement(children, {
    ref
  });
  const isHover = (0, _reactResponsive.useMediaQuery)({
    query: "(hover: hover) and (pointer: fine)"
  });
  return /* @__PURE__ */_react.default.createElement(_Spotlight.default, {
    brightness: 50,
    enabled: focused,
    onClickOutside: () => {
      setFocused(false);
    }
  }, /* @__PURE__ */_react.default.createElement(_Tooltip.default, {
    controlled: true,
    open: focused,
    content: /* @__PURE__ */_react.default.createElement(ChatMessageMenu, {
      time: timeStr,
      onClose: () => setFocused(false),
      actions: [{
        icon: "trash",
        text: "Remove message",
        onClick: removeMessage
      }, {
        icon: "copy",
        text: "Copy message",
        onClick: () => __async(void 0, null, function* () {
          navigator.clipboard.writeText(text);
          return true;
        })
      }]
    }),
    trigger: /* @__PURE__ */_react.default.createElement(_Tooltip.default, {
      content: timeStr,
      position: "left",
      disabled: !isHover,
      mountNode: rootNode,
      trigger: /* @__PURE__ */_react.default.createElement(TouchableOpacityWithClass, {
        onLongPress: () => {
          setFocused(true);
        },
        className: styleXClassName + (className ? " " + className : "")
      }, clonedChildren)
    })
  }));
});
const ChatMessageAuthor = (0, _react.memo)(({
  children,
  className,
  onClick,
  isSubscribed
}) => {
  const {
    className: styleXClassName
  } = stylex.props(styles.author, isSubscribed && styles.rubyGlow);
  return /* @__PURE__ */_react.default.createElement("div", {
    onClick,
    className: styleXClassName + (className ? " " + className : "")
  }, children);
});
const ChatMessageAvatar = (0, _react.memo)(({
  children,
  className,
  src,
  onClick
}) => {
  const {
    className: styleXClassName
  } = {
    className: "index__styles.avatar x1lliihq xvy4d1p xxk0z11 x1faq86j x1ghz6dp x1db2dqx x1n2onr6 x112o3c2 xvruv2t"
  };
  const {
    className: imgClassName
  } = {
    className: "index__styles.avatarImg x1lliihq x1ghz6dp x8x9d4c xack27t xh8yej3 x5yr21d x16rqkct"
  };
  return /* @__PURE__ */_react.default.createElement("div", {
    onClick,
    className: styleXClassName + (className ? " " + className : "")
  }, src && /* @__PURE__ */_react.default.createElement("img", {
    className: imgClassName,
    src,
    loading: "lazy"
  }), children);
});
const ChatMessageAuthorName = ({
  children
}) => {
  const {
    className
  } = {
    className: "index__styles.authorName x1ncir08 xb3r6kr xlyipyv x1ypdohk"
  };
  return /* @__PURE__ */_react.default.createElement("div", {
    className
  }, children);
};
const ChatMessage = (0, _react.memo)((0, _react.forwardRef)(({
  id,
  style,
  isOwn,
  removeMessage,
  hasPrevMessage,
  prevTime,
  time,
  content,
  rootNode,
  text,
  isSubscribed,
  viewUserProfile,
  avatarUrl,
  authorName,
  authorId
}, ref) => {
  const {
    className
  } = stylex.props(styles.chatMessage, hasPrevMessage && styles.hasPrevMessage);
  const [showOptions, setShowOptions] = (0, _react.useState)(false);
  const [openOptions, setOpenOptions] = (0, _react.useState)(false);
  const isHover = (0, _reactResponsive.useMediaQuery)({
    query: "(hover: hover) and (pointer: fine)"
  });
  const hideTime = prevTime && time - prevTime < 3600;
  return /* @__PURE__ */_react.default.createElement("div", {
    ref,
    style,
    className,
    onMouseEnter: isHover ? () => setShowOptions(true) : void 0,
    onMouseLeave: isHover ? () => setShowOptions(false) : void 0
  }, !hasPrevMessage && !hideTime && /* @__PURE__ */_react.default.createElement(ChatMessageDate, {
    time
  }), (showOptions || openOptions) && removeMessage && /* @__PURE__ */_react.default.createElement("div", {
    style: __spreadProps(__spreadValues({
      position: "absolute"
    }, isOwn ? {
      left: 0
    } : {
      right: 0
    }), {
      top: 0,
      width: 50,
      height: "fit-content",
      paddingTop: hasPrevMessage ? 0 : void 0
    })
  }, /* @__PURE__ */_react.default.createElement(_ContextMenu.ContextMenu, {
    open: openOptions,
    onOpen: () => setOpenOptions(true),
    onClose: () => setOpenOptions(false),
    position: isOwn ? "center" : "left",
    trigger: /* @__PURE__ */_react.default.createElement(_Button.Button, {
      style: {
        borderWidth: 0
      },
      color: "white",
      inverted: true,
      icon: "ellipsis horizontal",
      onClick: () => setOpenOptions(!openOptions)
    })
  }, /* @__PURE__ */_react.default.createElement(_ContextMenu.ContextMenu.Item, {
    icon: "trash",
    text: "Delete Message",
    onClick: () => removeMessage(id)
  }))), !hasPrevMessage && !isOwn && /* @__PURE__ */_react.default.createElement(ChatMessageAuthor, {
    isSubscribed,
    onClick: () => viewUserProfile(authorId)
  }, /* @__PURE__ */_react.default.createElement(ChatMessageAvatar, {
    src: avatarUrl
  }, isSubscribed && /* @__PURE__ */_react.default.createElement(_Premium.Diamond, {
    style: {
      bottom: 0,
      right: -5,
      left: "auto",
      fontSize: 16,
      position: "absolute",
      width: 10,
      height: 10
    }
  })), /* @__PURE__ */_react.default.createElement(ChatMessageAuthorName, null, authorName)), /* @__PURE__ */_react.default.createElement(ChatMessageText, {
    isOwn,
    text,
    time,
    removeMessage: removeMessage && (() => removeMessage(id)),
    rootNode
  }, content));
}));
const ChatMessages = ({
  messages,
  nextPage,
  loading
}) => {
  const [rootNode, setRootNode] = (0, _react.useState)(void 0);
  const rootRef = (0, _react.useCallback)(node => {
    setRootNode(node);
  }, []);
  return /* @__PURE__ */_react.default.createElement(_MessageContainer.default, {
    nextPage,
    loading,
    ref: rootRef
  }, rootNode && (messages == null ? void 0 : messages.map(({
    id,
    isOwn,
    time,
    hasPrevMessage,
    prevTime,
    text,
    avatarUrl,
    authorId,
    isSubscribed,
    authorName,
    content,
    removeMessage,
    viewUserProfile
  }) => {
    return /* @__PURE__ */_react.default.createElement(ChatMessage, {
      key: id,
      id,
      isOwn,
      time,
      hasPrevMessage,
      prevTime,
      text,
      rootNode,
      avatarUrl,
      authorId,
      isSubscribed,
      authorName,
      content,
      removeMessage,
      viewUserProfile
    });
  })));
};
ChatMessages.Messagebox = _Messagebox.default;
var _default = exports.default = ChatMessages;