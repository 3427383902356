"use strict";
import { gql, useQuery } from "kosmi-sdk/gql";
const ICE_SERVER_QUERY = gql(`
  query WebRTCIceServerQuery {
    webrtcIceServers {
      urls
      credential
      username
    }
  }
`);
export const useIceservers = () => {
  var _a;
  const { data } = useQuery(ICE_SERVER_QUERY, { fetchPolicy: "cache-first" });
  if (!data)
    return null;
  const iceServers = (_a = data == null ? void 0 : data.webrtcIceServers) == null ? void 0 : _a.map((val) => {
    const serverInfo = {};
    if (val.credential) {
      serverInfo.credential = val.credential;
    }
    if (val.username) {
      serverInfo.username = val.username;
    }
    serverInfo.urls = val.urls;
    return serverInfo;
  });
  return iceServers;
};
