"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { initColors } from "../layout/colors";
import * as rollbar from "./rollbar";
export const getUserConfirmation = (payload, callback) => {
  const { action, message } = JSON.parse(payload);
  const confirmed = window.confirm(message);
  callback(confirmed);
  if (!confirmed && action === "POP") {
    ;
    window.skipHistoryPrompt = true;
    window.history.forward();
  }
};
export function init() {
  window.onbeforeunload = function() {
    return false;
  };
  initColors();
  if (location.hostname === "app.kosmi.io") {
    rollbar.init();
  }
  if (!window.sessionStorage.getItem("isAndroidTWA") || window.sessionStorage.getItem("isAndroidTWA") === "false") {
    window.sessionStorage.setItem(
      "isAndroidTWA",
      document.referrer.includes("android-app://").toString()
    );
  }
  ;
  window.isAndroidTWA = window.sessionStorage.getItem("isAndroidTWA") === "true";
  window.addEventListener("beforeinstallprompt", function(e) {
    return __async(this, null, function* () {
      e.preventDefault();
      window.installPrompt = e;
    });
  });
  if (window.onbeforeinstallprompt === void 0) {
    ;
    window.installPrompt = null;
  }
}
