"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql } from "kosmi-sdk/gql";
import { Mutation } from "kosmi-sdk/gql";
import { OnboardingTooltip } from "../../components/Widgets/OnboardingTooltip";
import { useRoomId, useTracking, withDialogs } from "kosmi-sdk/helpers";
import { Button, ContextMenu, Icon } from "kosmi-sdk/uikit";
import { AnimatedModal as Modal } from "kosmi-sdk/widgets";
import React, { forwardRef, memo } from "react";
import { withStyleHook } from "../../sdk/styles";
import { useIsMobile } from "../helpers/withMobileDetection";
import RoomContext from "./RoomContext";
import style from "./AppMenuDropdown.css";
const STOP_APP = gql(`
  mutation StopApp($roomId: String!) {
    stopApp(roomId: $roomId) {
      ok
    }
  }
`);
const SettingsModal = ({ track, trigger, children }) => {
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      trigger,
      onOpen: () => {
        track("UI", "OpenAppSettingsModal", "Open app settings modal");
      }
    },
    /* @__PURE__ */ React.createElement(Modal.Header, null, "App Settings"),
    /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }
      },
      children
    ))
  );
};
const InstructionsModal = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ React.createElement(Modal, __spreadValues({ closeIcon: true }, props), /* @__PURE__ */ React.createElement(Modal.Header, null, "Usage instructions"), /* @__PURE__ */ React.createElement(Modal.Content, null, children));
};
const AppMenuDropdown = withDialogs(
  forwardRef(
    ({
      styles,
      onClose,
      confirm,
      appSettings,
      instructions,
      trigger,
      visible,
      children
    }, ref) => {
      const roomId = useRoomId();
      const track = useTracking();
      if (!trigger)
        return null;
      return /* @__PURE__ */ React.createElement(
        ContextMenu,
        {
          position: "center",
          open: visible,
          trigger,
          onClose,
          ref
        },
        /* @__PURE__ */ React.createElement("div", { className: styles.appDropdownMenu }, appSettings && /* @__PURE__ */ React.createElement(
          SettingsModal,
          {
            trigger: /* @__PURE__ */ React.createElement(ContextMenu.Item, { text: "App Settings", icon: "setting" }),
            track
          },
          appSettings
        ), instructions && /* @__PURE__ */ React.createElement(
          InstructionsModal,
          {
            trigger: /* @__PURE__ */ React.createElement(ContextMenu.Item, { text: "Instructions", icon: "help circle" }),
            track
          },
          instructions
        ), children, /* @__PURE__ */ React.createElement(Mutation, { mutation: STOP_APP, variables: { roomId } }, (stopApp) => /* @__PURE__ */ React.createElement(
          ContextMenu.Item,
          {
            text: "Stop App",
            icon: "remove",
            onClick: () => __async(void 0, null, function* () {
              if (yield confirm(
                "This will stop the currently running app. Are you sure?"
              )) {
                yield stopApp();
                onClose();
              }
            })
          }
        )))
      );
    }
  )
);
export default memo(() => {
  const styles = withStyleHook(style);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(
    !window.localStorage.getItem("AppMenuTooltipSeen")
  );
  const classNames = [styles.appMenuDropdown];
  const roomId = useRoomId();
  const { isMobile } = useIsMobile();
  const { appModule, runningApp, Settings } = React.useContext(RoomContext);
  return ["WELCOME", "LOBBY", "SPACES"].indexOf(runningApp || "") === -1 ? /* @__PURE__ */ React.createElement("div", { className: classNames.join(" ") }, /* @__PURE__ */ React.createElement(
    OnboardingTooltip,
    {
      open: !isMobile && showTooltip,
      content: "Here you can access App settings and close the App",
      onClick: () => {
        window.localStorage.setItem("AppMenuTooltipSeen", "true");
        setShowTooltip(false);
      },
      trigger: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
        AppMenuDropdown,
        {
          forceHover: showTooltip,
          appSettings: Settings && /* @__PURE__ */ React.createElement(Settings, { roomId }),
          instructions: appModule && appModule.instructions,
          visible: showDropdown,
          onClose: () => {
            setShowDropdown(false);
          },
          styles,
          trigger: /* @__PURE__ */ React.createElement(
            Button,
            {
              style: __spreadValues({
                height: 45,
                padding: 0,
                width: "100%",
                margin: 0,
                alignItems: "center",
                borderRadius: 0,
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 0,
                backgroundColor: "var(--primary-dark-5)"
              }, showDropdown && {
                backgroundColor: "var(--primary-dark-5)"
              }),
              color: "blue",
              onClick: () => {
                setShowDropdown(!showDropdown);
              }
            },
            appModule && appModule.icon && /* @__PURE__ */ React.createElement("img", { className: styles.appMenuImg, src: appModule.icon }),
            /* @__PURE__ */ React.createElement("div", { className: styles.appTitle }, appModule && appModule.name || runningApp),
            /* @__PURE__ */ React.createElement(Icon, { name: "caret down" })
          )
        },
        appModule && appModule.AppMenu && /* @__PURE__ */ React.createElement(appModule.AppMenu, { roomId })
      ))
    }
  )) : null;
});
