"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Table = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getScrollbarWidth() {
  var _a;
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  document.body.appendChild(outer);
  const inner = document.createElement("div");
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  (_a = outer == null ? void 0 : outer.parentNode) == null ? void 0 : _a.removeChild(outer);
  return scrollbarWidth;
}
const styles = {
  paddingRight: paddingRight => [{
    "Table__styles.paddingRight": "Table__styles.paddingRight",
    paddingRight: "x1af1tl5",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    $$css: true
  }, {
    "--paddingRight": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(paddingRight)
  }],
  colSpan: colSpan => [{
    "Table__styles.colSpan": "Table__styles.colSpan",
    flex: "x1kapfjr",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  }, {
    "--flex": colSpan != null ? colSpan : "initial"
  }],
  table: {
    "Table__styles.table": "Table__styles.table",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xh8yej3",
    background: "x1odt36a",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    "@media only screen and (max-width: 767px)_height": "x1n3e5xa",
    "@media only screen and (max-width: 767px)_fontSize": "xjr8gh0",
    $$css: true
  },
  thead: {
    "Table__styles.thead": "Table__styles.thead",
    fontWeight: "x117nqv4",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  tbody: {
    "Table__styles.tbody": "Table__styles.tbody",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    flexDirection: "xdt5ytf",
    overflowY: "x1odjw0f",
    "@media only screen and (max-width: 767px)_overflowY": "x190x50b",
    $$css: true
  },
  trHead: {
    "Table__styles.trHead": "Table__styles.trHead",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    height: "xwzfr38",
    flexDirection: "x1q0g3np",
    "@media only screen and (max-width: 767px)_flexDirection": "x13za5q4",
    "@media only screen and (max-width: 767px)_height": "xmgohv3",
    $$css: true
  },
  clickable: {
    "Table__styles.clickable": "Table__styles.clickable",
    display: "x78zum5",
    ":hover_background": "x1717ndu",
    ":hover_backgroundAttachment": null,
    ":hover_backgroundClip": null,
    ":hover_backgroundColor": null,
    ":hover_backgroundImage": null,
    ":hover_backgroundOrigin": null,
    ":hover_backgroundPosition": null,
    ":hover_backgroundPositionX": null,
    ":hover_backgroundPositionY": null,
    ":hover_backgroundRepeat": null,
    ":hover_backgroundSize": null,
    ":hover_cursor": "x1277o0a",
    $$css: true
  },
  tr: {
    "Table__styles.tr": "Table__styles.tr",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    flexDirection: "x1q0g3np",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    ":nth-child(odd)_background": "x1yowr5b",
    ":nth-child(odd)_backgroundAttachment": null,
    ":nth-child(odd)_backgroundClip": null,
    ":nth-child(odd)_backgroundColor": null,
    ":nth-child(odd)_backgroundImage": null,
    ":nth-child(odd)_backgroundOrigin": null,
    ":nth-child(odd)_backgroundPosition": null,
    ":nth-child(odd)_backgroundPositionX": null,
    ":nth-child(odd)_backgroundPositionY": null,
    ":nth-child(odd)_backgroundRepeat": null,
    ":nth-child(odd)_backgroundSize": null,
    "@media only screen and (max-width: 767px)_flexDirection": "x13za5q4",
    $$css: true
  },
  td: {
    "Table__styles.td": "Table__styles.td",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    padding: "x6w2896",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    "@media only screen and (max-width: 767px)_padding": "xdkot1b",
    "@media only screen and (max-width: 767px)_paddingInline": null,
    "@media only screen and (max-width: 767px)_paddingStart": null,
    "@media only screen and (max-width: 767px)_paddingLeft": null,
    "@media only screen and (max-width: 767px)_paddingEnd": null,
    "@media only screen and (max-width: 767px)_paddingRight": null,
    "@media only screen and (max-width: 767px)_paddingBlock": null,
    "@media only screen and (max-width: 767px)_paddingTop": null,
    "@media only screen and (max-width: 767px)_paddingBottom": null,
    $$css: true
  },
  centered: {
    "Table__styles.centered": "Table__styles.centered",
    display: "x78zum5",
    "@media only screen and (min-width: 1000px)_justifyContent": "xz4tw9t",
    $$css: true
  }
};
const Table = exports.Table = Object.assign((0, _react.memo)(({
  children,
  className
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: [styles.table, className]
  }, children);
}), {
  Cell: (0, _react.memo)(({
    className,
    children,
    colSpan,
    centered
  }) => {
    return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [styles.td, centered && styles.centered, !!colSpan && styles.colSpan(colSpan), className]
    }, children);
  }),
  HeaderRow: (0, _react.memo)(({
    className,
    children,
    onClick,
    colSpan
  }) => {
    return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      onClick,
      style: [styles.trHead, !!colSpan && styles.colSpan(colSpan), className]
    }, children);
  }),
  Row: (0, _react.memo)(({
    className,
    children,
    onClick
  }) => {
    return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      onClick,
      style: [styles.tr, !!onClick && styles.clickable, className]
    }, children);
  }),
  Header: (0, _react.memo)(({
    className,
    children
  }) => {
    return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [styles.thead, styles.paddingRight(getScrollbarWidth()), className]
    }, children);
  }),
  HeaderCell: (0, _react.memo)(({
    className,
    children,
    colSpan,
    centered
  }) => {
    return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [styles.td, centered && styles.centered, !!colSpan && styles.colSpan(colSpan), className]
    }, children);
  }),
  Body: (0, _react.memo)(({
    children,
    className
  }) => {
    return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [styles.tbody, className]
    }, children);
  })
});