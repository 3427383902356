"use strict";
import React, { useEffect, useRef, useState } from "react";
import style from "./style.css";
import { withStyleHook } from "kosmi-sdk/styles";
import ScreenshareModal from "../../../ScreenshareModal";
import screenshareVideo from "./screenshare.mp4";
import BackButton from "../../../BackButton";
const Steps = ({
  steps,
  currentStep,
  setStep
}) => {
  const styles = withStyleHook(style);
  return /* @__PURE__ */ React.createElement("div", { className: styles.steps }, /* @__PURE__ */ React.createElement("div", { className: styles.stepsVisual }, steps.map((_step, i) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: (i + 1 <= currentStep ? [styles.step, styles.active] : [styles.step]).join(" "),
      onClick: () => i + 1 <= currentStep ? setStep(i + 1) : void 0
    },
    i + 1
  ), i !== steps.length - 1 && /* @__PURE__ */ React.createElement(
    "div",
    {
      className: (i + 2 <= currentStep ? [styles.active, styles.line] : [styles.line]).join(" ")
    }
  )))), /* @__PURE__ */ React.createElement("div", { className: styles.stepLabels }, steps.map((step, i) => /* @__PURE__ */ React.createElement(
    "div",
    {
      key: i,
      className: (i + 1 <= currentStep ? [styles.active] : []).join(" "),
      onClick: () => i + 1 <= currentStep ? setStep(i + 1) : void 0
    },
    step
  ))));
};
export default ({
  title,
  url,
  logo,
  goBack,
  onClose
}) => {
  const styles = withStyleHook(style);
  const [step, setStep] = useState(1);
  const windowChecker = useRef(null);
  useEffect(() => {
    return () => {
      if (windowChecker.current)
        clearInterval(windowChecker.current);
    };
  }, []);
  return /* @__PURE__ */ React.createElement("div", { className: styles.root }, /* @__PURE__ */ React.createElement(BackButton, { onClick: goBack }), /* @__PURE__ */ React.createElement("div", { className: styles.guide }, /* @__PURE__ */ React.createElement(
    Steps,
    {
      steps: ["launch app", "screen share"],
      currentStep: step,
      setStep
    }
  ), step === 1 && /* @__PURE__ */ React.createElement("div", { className: styles.stepContent }, /* @__PURE__ */ React.createElement("div", null, "Launch ", title, ", log into your account and select something to watch.", /* @__PURE__ */ React.createElement("div", null, "Keep it open and return to this screen.")), /* @__PURE__ */ React.createElement("img", { className: styles.logo, src: logo }), /* @__PURE__ */ React.createElement("div", { className: styles.actions }, /* @__PURE__ */ React.createElement(
    "a",
    {
      className: styles.launchButton,
      rel: "noreferrer",
      href: url,
      target: "_blank",
      onClick: (e) => {
        e.preventDefault();
        if (windowChecker.current)
          clearInterval(windowChecker.current);
        const target = e.target;
        const popup = window.open(
          target.href,
          "popUpWindow",
          "popup=1,height=459,width=816,left=0,top=0,scrollbars=yes,toolbar=yes,menubar=no"
        );
        if (!popup || popup.closed) {
          window.open(target.href, "_blank");
        } else {
          windowChecker.current = setInterval(() => {
            if (popup.closed) {
              setStep(1);
              if (windowChecker.current)
                clearInterval(windowChecker.current);
            }
          }, 1e3);
        }
        setStep(2);
      }
    },
    "Launch ",
    title
  ), /* @__PURE__ */ React.createElement("a", { onClick: goBack }, "Cancel"))), step === 2 && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: styles.shareTabText }, "Share your tab to watch on Kosmi.", /* @__PURE__ */ React.createElement("div", null, "Make sure share tab audio is turned on.")), /* @__PURE__ */ React.createElement(
    "video",
    {
      className: styles.video,
      src: screenshareVideo,
      autoPlay: true,
      loop: true,
      muted: true
    }
  ), /* @__PURE__ */ React.createElement("div", { className: styles.screenshareSelector }, /* @__PURE__ */ React.createElement(
    ScreenshareModal,
    {
      onClose: () => {
        goBack();
        onClose();
      },
      simple: true,
      customLabel: `Share ${title} tab`
    }
  )))));
};
