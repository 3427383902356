"use strict";
import React, { memo } from "react";
import { Icon } from "../Icon";
import { CheckBox, Text } from "@rneui/themed";
import { getVar } from "../../components/helpers/colors";
export const Radio = memo(({ checked, onChange, label }) => {
  return /* @__PURE__ */ React.createElement(
    CheckBox,
    {
      checked: !!checked,
      onPress: onChange,
      checkedIcon: /* @__PURE__ */ React.createElement(Icon, { name: "dot circle", color: "white", style: { marginRight: 10 } }),
      uncheckedIcon: /* @__PURE__ */ React.createElement(Icon, { name: "circle outline", color: "white", style: { marginRight: 10 } }),
      title: /* @__PURE__ */ React.createElement(
        Text,
        {
          style: {
            color: getVar("foreground"),
            fontFamily: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif",
            justifyContent: "space-between"
          }
        },
        label
      ),
      textStyle: { color: "white" },
      containerStyle: {
        backgroundColor: "transparent",
        marginLeft: 0,
        marginRight: 0,
        padding: 0
      }
    }
  );
});
