"use strict";
import React from "react";
import { useRef } from "react";
import withCurrentUser, {
  currentUserHook
} from "../components/helpers/withCurrentUser";
import withDialogs from "../components/helpers/withDialogs";
import withMobileDetection from "../components/helpers/withMobileDetection";
import withTracking, { useTracking } from "../components/helpers/withTracking";
import withRoomId, { useRoomId } from "../components/Room/withRoomId";
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);
export const ImgUpload = ({
  onChange,
  trigger
}) => {
  const _id = useRef(Math.random().toString(36).substring(2));
  const randomId = _id.current;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("label", { htmlFor: randomId }, trigger), /* @__PURE__ */ React.createElement(
    "input",
    {
      id: randomId,
      name: randomId,
      type: "file",
      style: { display: "none" },
      onChange
    }
  ));
};
function getShorthandTime(timestamp) {
  const currentTime = Math.floor(Date.now() / 1e3);
  const timePassed = Math.abs(currentTime - timestamp);
  if (timePassed < 3600) {
    return `${Math.floor(timePassed / 60)}m`;
  } else if (timePassed < 86400) {
    return `${Math.floor(timePassed / 3600)}h`;
  } else {
    return `${Math.floor(timePassed / 86400)}d`;
  }
}
export {
  withDialogs,
  withCurrentUser,
  currentUserHook,
  withRoomId,
  withMobileDetection,
  withTracking,
  isMobile,
  useRoomId,
  useTracking,
  getShorthandTime
};
