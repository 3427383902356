"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _LocalFileModal = _interopRequireDefault(require("./components/LocalFileModal"));
var _ScreenshareModal = _interopRequireDefault(require("./components/ScreenshareModal"));
var _VideoURLModal = _interopRequireDefault(require("./components/VideoURLModal"));
var _Discover = _interopRequireDefault(require("./components/Discover"));
var _CustomScreenshare = _interopRequireDefault(require("./components/Discover/components/CustomScreenshare"));
var _BackButton = _interopRequireDefault(require("./components/BackButton"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
const styles = {
  videoChoiceScreen: {
    "index__styles.videoChoiceScreen": "index__styles.videoChoiceScreen",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  }
};
const VideoChoiceScreen = _a => {
  var _b = _a,
    {
      screen,
      goBack,
      onClose
    } = _b,
    props = __objRest(_b, ["screen", "goBack", "onClose"]);
  if (screen === "pick" || screen === "customScreenshare") return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.videoChoiceScreen
  }, /* @__PURE__ */_react.default.createElement(_BackButton.default, {
    onClick: goBack
  }), (() => {
    if (screen === "screenshare") return /* @__PURE__ */_react.default.createElement(_ScreenshareModal.default, __spreadProps(__spreadValues({}, props), {
      onClose: () => {
        goBack();
        onClose();
      }
    }));
    if (screen === "url") return /* @__PURE__ */_react.default.createElement(_VideoURLModal.default, __spreadProps(__spreadValues({}, props), {
      onClose: () => {
        goBack();
        onClose();
      }
    }));
    if (screen === "file") return /* @__PURE__ */_react.default.createElement(_LocalFileModal.default, __spreadProps(__spreadValues({}, props), {
      onClose: () => {
        goBack();
        onClose();
      }
    }));
  })());
};
var _default = exports.default = (0, _react.memo)(({
  onClose,
  setModalTitle
}) => {
  const [_screen, setScreen] = (0, _react.useState)("pick");
  const initializeScreen = () => setScreen("pick");
  const [customScreenshare, setCustomScreenshare] = (0, _react.useState)(null);
  const screen = customScreenshare ? "customScreenshare" : _screen;
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(VideoChoiceScreen, {
    screen,
    goBack: initializeScreen,
    onClose
  }), customScreenshare && /* @__PURE__ */_react.default.createElement(_CustomScreenshare.default, {
    title: customScreenshare == null ? void 0 : customScreenshare.title,
    url: customScreenshare == null ? void 0 : customScreenshare.url,
    logo: customScreenshare == null ? void 0 : customScreenshare.logo,
    goBack: () => {
      setCustomScreenshare(null);
      setModalTitle("Select Media");
    },
    onClose
  }), /* @__PURE__ */_react.default.createElement(_Discover.default, {
    closeModal: onClose,
    screen,
    setScreen,
    setModalTitle,
    openCustomScreenshare: setCustomScreenshare
  }));
});