"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Table = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
function getScrollbarWidth() {
  var _a;
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  document.body.appendChild(outer);
  const inner = document.createElement("div");
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  (_a = outer == null ? void 0 : outer.parentNode) == null ? void 0 : _a.removeChild(outer);
  return scrollbarWidth;
}
const styles = {
  clickable: {
    "index__styles.clickable": "index__styles.clickable",
    ":hover_background": "x1717ndu",
    ":hover_cursor": "x1277o0a",
    $$css: true
  },
  tr: {
    "index__styles.tr": "index__styles.tr",
    display: "x78zum5",
    flex: "x98rzlu",
    flexDirection: "x1q0g3np",
    padding: "x1717udv",
    ":nth-child(odd)_background": "x1yowr5b",
    "@media only screen and (max-width: 767px)_flexDirection": "x13za5q4",
    $$css: true
  },
  td: {
    "index__styles.td": "index__styles.td",
    display: "x78zum5",
    flex: "x98rzlu",
    padding: "x6w2896",
    "@media only screen and (max-width: 767px)_padding": "xdkot1b",
    $$css: true
  },
  centered: {
    "index__styles.centered": "index__styles.centered",
    "@media only screen and (min-width: 1000px)_justifyContent": "xz4tw9t",
    $$css: true
  }
};
const Table = exports.Table = Object.assign((0, _react.memo)(({
  children,
  style
}) => {
  const {
    className
  } = {
    className: "index__styles.table x78zum5 xdt5ytf xh8yej3 x1odt36a x1n3e5xa xjr8gh0"
  };
  return /* @__PURE__ */_react.default.createElement("div", {
    style,
    className
  }, children);
}), {
  Cell: (0, _react.memo)(({
    className,
    children,
    style,
    colSpan,
    centered
  }) => {
    const {
      className: stylexClassName
    } = stylex.props(styles.td, centered && styles.centered);
    return /* @__PURE__ */_react.default.createElement("div", {
      style: __spreadProps(__spreadValues({}, style), {
        flex: colSpan
      }),
      className: stylexClassName + (className ? " " + className : "")
    }, children);
  }),
  HeaderRow: (0, _react.memo)(({
    className,
    children,
    onClick,
    style,
    colSpan
  }) => {
    const {
      className: stylexClassName
    } = {
      className: "index__styles.trHead x78zum5 x98rzlu xwzfr38 x1q0g3np x13za5q4 xmgohv3"
    };
    return /* @__PURE__ */_react.default.createElement("div", {
      onClick,
      style: __spreadProps(__spreadValues({}, style), {
        flex: colSpan
      }),
      className: stylexClassName + (className ? " " + className : "")
    }, children);
  }),
  Row: (0, _react.memo)(({
    className,
    children,
    onClick
  }) => {
    const {
      className: stylexClassName
    } = stylex.props(styles.tr, onClick ? styles.clickable : void 0);
    return /* @__PURE__ */_react.default.createElement("div", {
      onClick,
      className: stylexClassName + (className ? " " + className : "")
    }, children);
  }),
  Header: (0, _react.memo)(({
    className,
    children
  }) => {
    const {
      className: stylexClassName
    } = {
      className: "index__styles.thead x117nqv4 x78zum5 xdt5ytf"
    };
    return /* @__PURE__ */_react.default.createElement("div", {
      className: stylexClassName + (className ? " " + className : ""),
      style: {
        paddingRight: getScrollbarWidth()
      }
    }, children);
  }),
  HeaderCell: (0, _react.memo)(({
    className,
    children,
    colSpan,
    centered
  }) => {
    const {
      className: stylexClassName
    } = stylex.props(styles.td, centered && styles.centered);
    return /* @__PURE__ */_react.default.createElement("div", {
      style: {
        flex: colSpan
      },
      className: stylexClassName + (className ? " " + className : "")
    }, children);
  }),
  Body: (0, _react.memo)(({
    children,
    className
  }) => {
    const {
      className: stylexClassName
    } = {
      className: "index__styles.tbody x78zum5 x98rzlu xdt5ytf x1odjw0f x190x50b"
    };
    return /* @__PURE__ */_react.default.createElement("div", {
      className: stylexClassName + (className ? " " + className : "")
    }, children);
  })
});