"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation } from "kosmi-sdk/gql";
import { Button, Form, Icon, Message } from "kosmi-sdk/uikit";
import React, { useContext, useState } from "react";
import { ConnectionContext } from "../../ConnectionContext";
import DateSelector from "./DateSelector";
import FadeInTransition from "./FadeInTransition";
const SignupForm = ({
  inviteCode,
  goBack,
  onSignup
}) => {
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState([null, null, null]);
  const { updateClient } = useContext(ConnectionContext);
  const backButton = /* @__PURE__ */ React.createElement(
    Icon,
    {
      style: {
        color: "white",
        float: "left",
        width: "20px",
        cursor: "pointer",
        marginBottom: "20px"
      },
      size: "big",
      name: "angle left",
      onClick: () => {
        if (goBack) {
          goBack();
        }
      }
    }
  );
  const [signup, { loading }] = useMutation(SIGNUP);
  return /* @__PURE__ */ React.createElement(FadeInTransition, null, /* @__PURE__ */ React.createElement(
    Form,
    {
      error: !!error,
      size: "small",
      onSubmit: () => __async(void 0, null, function* () {
        var _a;
        try {
          const { data } = yield signup({
            variables: {
              username,
              password,
              email,
              birthday: birthday.map((v) => v && String(v).padStart(2, "0")).join("-"),
              inviteCode
            }
          });
          const token = (_a = data == null ? void 0 : data.signup) == null ? void 0 : _a.token;
          if (token) {
            window.localStorage.setItem("token", token);
          }
          updateClient();
          if (onSignup) {
            onSignup();
          }
        } catch (e) {
          if (e.graphQLErrors) {
            setError(e.graphQLErrors[0].message);
          }
        }
      })
    },
    /* @__PURE__ */ React.createElement(
      Message,
      {
        error: true,
        header: "Signup Failed!",
        content: error,
        onDismiss: () => setError("")
      }
    ),
    goBack && backButton,
    /* @__PURE__ */ React.createElement(
      Form.Input,
      {
        placeholder: "Username",
        value: username,
        onChange: (e) => {
          e.preventDefault();
          setUsername(e.target.value);
        }
      }
    ),
    /* @__PURE__ */ React.createElement(
      Form.Input,
      {
        type: "email",
        placeholder: "Email",
        value: email,
        onChange: (e) => {
          e.preventDefault();
          setEmail(e.target.value);
        }
      }
    ),
    /* @__PURE__ */ React.createElement(
      Form.Input,
      {
        placeholder: "Password",
        type: "password",
        value: password,
        onChange: (e) => {
          e.preventDefault();
          setPassword(e.target.value);
        }
      }
    ),
    /* @__PURE__ */ React.createElement(
      Form.Input,
      {
        placeholder: "Password Repeat",
        type: "password",
        value: passwordRepeat,
        onChange: (e) => {
          e.preventDefault();
          setPasswordRepeat(e.target.value);
        }
      }
    ),
    /* @__PURE__ */ React.createElement(Form.Field, null, /* @__PURE__ */ React.createElement(
      DateSelector,
      {
        value: birthday,
        onChange: (d) => setBirthday(d)
      }
    )),
    /* @__PURE__ */ React.createElement(
      Button,
      {
        disabled: !birthday || birthday.some((val) => !val) || !username || !password || password !== passwordRepeat || !email,
        centered: true,
        style: {
          width: 100
        },
        color: "yellow",
        inverted: true,
        loading,
        type: "submit"
      },
      "Sign up"
    )
  ));
};
const SIGNUP = gql(`
  mutation Signup(
    $username: String!
    $password: String!
    $email: String!
    $birthday: Date!
    $inviteCode: String
  ) {
    signup(
      username: $username
      password: $password
      email: $email
      birthday: $birthday
      inviteCode: $inviteCode
    ) {
      token
    }
  }
`);
export default SignupForm;
