"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PremiumScreen = exports.PremiumModal = exports.PaywallMessage = exports.InviteModal = exports.Diamond = void 0;
var _gql = require("kosmi-sdk/gql");
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _helpers = require("kosmi-sdk/helpers");
var _reactRouterDom = require("react-router-dom");
var _PaymentSection = _interopRequireDefault(require("./PaymentSection"));
var _platform = _interopRequireDefault(require("platform"));
var _LoginWall = _interopRequireDefault(require("../LoginWall"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _reactShare = require("react-share");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var _a, _b, _c, _d;
const isIOS = ((_b = (_a = _platform.default) == null ? void 0 : _a.os) == null ? void 0 : _b.family) === "iOS";
const isMacOS = ((_d = (_c = _platform.default) == null ? void 0 : _c.os) == null ? void 0 : _d.family) === "OS X";
const isCapacitor = location.href.startsWith("capacitor");
const hidePremium = (isIOS || isMacOS) && isCapacitor;
const styles = {
  diamondListItem: {
    "index__styles.diamondListItem": "index__styles.diamondListItem",
    fontSize: "x1pvqxga",
    width: "x17z2i9w",
    height: "x17rw0jw",
    marginRight: "x1db2dqx",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginLeft: "xj3b58b",
    display: "x1rg5ohu",
    $$css: true
  },
  diamondHeader: {
    "index__styles.diamondHeader": "index__styles.diamondHeader",
    fontSize: "x1pvqxga",
    $$css: true
  },
  diamondPaywall: {
    "index__styles.diamondPaywall": "index__styles.diamondPaywall",
    width: "xvy4d1p",
    height: "xxk0z11",
    $$css: true
  },
  screen: {
    "index__styles.screen": "index__styles.screen",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    overflowX: "x6ikm8r",
    $$css: true
  },
  subscriptionHero: {
    "index__styles.subscriptionHero": "index__styles.subscriptionHero",
    background: "x1tudphz",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "xieb3on",
    $$css: true
  },
  info: {
    "index__styles.info": "index__styles.info",
    fontSize: "xosj86m",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "x1anpbxc",
    marginBottom: null,
    color: "x16cd2qt",
    textAlign: "x2b8uid",
    $$css: true
  },
  perkList: {
    "index__styles.perkList": "index__styles.perkList",
    listStyle: "xe8uvvx",
    listStyleImage: null,
    listStylePosition: null,
    listStyleType: null,
    fontSize: "x1pvqxga",
    $$css: true
  },
  perk: {
    "index__styles.perk": "index__styles.perk",
    margin: "x1bxwjp6",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  subscriptionChild: {
    "index__styles.subscriptionChild": "index__styles.subscriptionChild",
    marginBottom: "xieb3on",
    $$css: true
  },
  inviteLinkWrapper: {
    "index__styles.inviteLinkWrapper": "index__styles.inviteLinkWrapper",
    width: "xh8yej3",
    maxWidth: "x1j9u4d2",
    display: "x78zum5",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  paywall: {
    "index__styles.paywall": "index__styles.paywall",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  paywallMessage: {
    "index__styles.paywallMessage": "index__styles.paywallMessage",
    lineHeight: "x1u7k74",
    textAlign: "x2b8uid",
    $$css: true
  },
  subscription: {
    "index__styles.subscription": "index__styles.subscription",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    $$css: true
  },
  freeOption: {
    "index__styles.freeOption": "index__styles.freeOption",
    display: "x78zum5",
    cursor: "x1ypdohk",
    margin: "x1v36x0e",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "xif65rj",
    $$css: true
  },
  emphasis: {
    "index__styles.emphasis": "index__styles.emphasis",
    color: "x16cd2qt",
    fontWeight: "x1xlr1w8",
    marginLeft: "xd8780z",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  inviteMsg: {
    "index__styles.inviteMsg": "index__styles.inviteMsg",
    margin: "xvwtkab",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "x1j61zf2",
    textAlign: "x2b8uid",
    maxWidth: "x1j9u4d2",
    $$css: true
  },
  shareButtons: {
    "index__styles.shareButtons": "index__styles.shareButtons",
    display: "x78zum5",
    margin: "x1v36x0e",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    width: "x1oysuqx",
    justifyContent: "x1l1ennw",
    $$css: true
  },
  invitees: {
    "index__styles.invitees": "index__styles.invitees",
    maxWidth: "x193iq5w",
    width: "x1l2rt3b",
    background: "x1tudphz",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  tableChild: {
    "index__styles.tableChild": "index__styles.tableChild",
    padding: "x14odnwx",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  }
};
const UPDATE_PAYMENT_METHOD = (0, _gql.gql)(`
  mutation UpdatePaymentMethod($paymentMethodId: String!) {
    updateStripePaymentMethod(paymentMethodId: $paymentMethodId) {
      ok
    }
  }
`);
const GET_PRODUCT = (0, _gql.gql)(`
  query GetSubscriptionProduct {
    getStripeSubscriptionProduct {
      stripePriceId
      usdAmount
      stripePublishableKey
    }
  }
`);
const GET_SUBSCRIPTION = (0, _gql.gql)(`
  query GetSubscription {
    currentStripeSubscription {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
    currentUser {
      id
      connectionId
      user {
        id
        isSubscribed
      }
    }
  }
`);
const CANCEL_SUBSCRIPTION = (0, _gql.gql)(`
  mutation ReactivateSubscription2 {
    cancelStripeSubscription {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
  }
`);
const REACTIVATE_SUBSCRIPTION = (0, _gql.gql)(`
  mutation ReactivateSubscription {
    reactivateStripeSubscription {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
  }
`);
const SIGNUP_INVITES = (0, _gql.gql)(`
  query SignupInvites {
    signupInvites {
      confirmed
      used
      user {
        id
        displayName
        avatarUrl
      }
    }
    currentUser {
      id
      inviteCode
      user {
        id
      }
    }
  }
`);
const InviteModal = ({
  trigger
}) => {
  var _a2;
  const {
    data
  } = (0, _gql.useQuery)(SIGNUP_INVITES);
  const signupInvites = (data == null ? void 0 : data.signupInvites) || [];
  const inviteCode = ((_a2 = data == null ? void 0 : data.currentUser) == null ? void 0 : _a2.inviteCode) || "";
  const [copySuccess, setCopySuccess] = (0, _react.useState)(false);
  const inputRef = (0, _react.useRef)(null);
  const url = `https://app.kosmi.io/signup/${inviteCode}`;
  const nInvites = signupInvites == null ? void 0 : signupInvites.filter(i => !i.used && i.confirmed).length;
  if (hidePremium) {
    return null;
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    trigger
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "Invite Friends"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteMsg
  }, "Invite 3 friends to sign up and you will get a free month of Kosmi Premium!"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteMsg
  }, "All you need to do is share the link below:"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviteLinkWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Input, {
    ref: inputRef,
    readOnly: true,
    value: copySuccess ? "Copied!" : url
  }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    disabled: copySuccess,
    onClick: () => __async(void 0, null, function* () {
      var _a3, _b2;
      (_a3 = inputRef.current) == null ? void 0 : _a3.select();
      if ((_b2 = inputRef.current) == null ? void 0 : _b2.setSelectionRange) inputRef == null ? void 0 : inputRef.current.setSelectionRange(0, 99999);
      document.execCommand("copy");
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 500);
    }),
    color: "blue"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "copy"
  }), "Copy link")), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.shareButtons
  }, /* @__PURE__ */_react.default.createElement(_reactShare.FacebookShareButton, {
    url
  }, /* @__PURE__ */_react.default.createElement(_reactShare.FacebookIcon, {
    size: "40",
    round: true
  })), /* @__PURE__ */_react.default.createElement(_reactShare.TwitterShareButton, {
    url
  }, /* @__PURE__ */_react.default.createElement(_reactShare.TwitterIcon, {
    size: "40",
    round: true
  })), /* @__PURE__ */_react.default.createElement(_reactShare.RedditShareButton, {
    url
  }, /* @__PURE__ */_react.default.createElement(_reactShare.RedditIcon, {
    size: "40",
    round: true
  })), /* @__PURE__ */_react.default.createElement(_reactShare.WhatsappShareButton, {
    url
  }, /* @__PURE__ */_react.default.createElement(_reactShare.WhatsappIcon, {
    size: "40",
    round: true
  }))), /* @__PURE__ */_react.default.createElement("table", {
    style: styles.invitees
  }, /* @__PURE__ */_react.default.createElement("thead", null, /* @__PURE__ */_react.default.createElement("th", null, /* @__PURE__ */_react.default.createElement("td", null, "Name")), /* @__PURE__ */_react.default.createElement("th", null, /* @__PURE__ */_react.default.createElement("td", null, "Confirmed")), /* @__PURE__ */_react.default.createElement("th", null, nInvites, " / 3")), /* @__PURE__ */_react.default.createElement("tbody", {
    style: styles.tableChild
  }, signupInvites.map((invite, i) => /* @__PURE__ */_react.default.createElement("tr", {
    key: i
  }, /* @__PURE__ */_react.default.createElement("td", {
    style: styles.tableChild
  }, invite.user.displayName || invite.user.username), /* @__PURE__ */_react.default.createElement("td", {
    style: styles.tableChild
  }, !invite.used && invite.confirmed ? "\u2705" : "\u274C"), /* @__PURE__ */_react.default.createElement("td", {
    style: styles.tableChild
  })))))));
};
exports.InviteModal = InviteModal;
const Diamond = exports.Diamond = (0, _react.memo)(({
  className
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className,
    name: "kosmi diamond"
  });
});
const PerkList = props => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, __spreadValues({
    style: styles.perkList
  }, props));
};
const Perk = props => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, __spreadValues({
    style: styles.perk
  }, props));
};
const BuySubscriptionScreen = (0, _helpers.withDialogs)(({
  source,
  straightToPayment
}) => {
  const buyBtnRef = (0, _react.useRef)(null);
  const {
    data
  } = (0, _gql.useQuery)(GET_PRODUCT);
  const {
    usdAmount
  } = (data == null ? void 0 : data.getStripeSubscriptionProduct) || {};
  const currentUser = (0, _helpers.currentUserHook)();
  const [hasNavigated, setHasNavigated] = (0, _react.useState)(false);
  if (hidePremium) {
    return null;
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "See Perks"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.screen
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subscriptionHero
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.info
  }, "For only $", usdAmount, " a month you get:"), /* @__PURE__ */_react.default.createElement(PerkList, {
    style: styles.perkList
  }, /* @__PURE__ */_react.default.createElement(Perk, null, "\u{1F310} Unlimited Virtual Browsing"), /* @__PURE__ */_react.default.createElement(Perk, null, "\u{1F4FA} 1080p & 4K Screensharing"), /* @__PURE__ */_react.default.createElement(Perk, null, "\u{1F3A8} Custom Themes and Color Schemes"), /* @__PURE__ */_react.default.createElement(Perk, null, "\u{1F3A9} Exclusive access to the Kosmi VIP Lounge"), /* @__PURE__ */_react.default.createElement(Perk, null, /* @__PURE__ */_react.default.createElement(Diamond, {
    className: styles.diamondListItem
  }), "Extra surprise for the first 1000 members!"), /* @__PURE__ */_react.default.createElement(Perk, null, "\u{1F389} Many more upcoming features"), /* @__PURE__ */_react.default.createElement(Perk, null, "\u2764\uFE0F Help Kosmi improve and grow"))), /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, showLoginModal => /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    style: {
      margin: "auto"
    },
    ref: buyBtnRef,
    onClick: showLoginModal
  }, "Lets do it!")), /* @__PURE__ */_react.default.createElement(InviteModal, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.freeOption
    }, "Money tight right now? Get Free kosmi Premium by", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.emphasis
    }, "inviting friends."))
  })), !hidePremium && !(currentUser == null ? void 0 : currentUser.isAnonymous) && /* @__PURE__ */_react.default.createElement(_PaymentSection.default, {
    trigger: hasNavigated ? buyBtnRef : straightToPayment || buyBtnRef,
    source,
    checkout: true,
    onBack: () => {
      setHasNavigated(true);
    }
  }));
});
const PremiumScreen = ({
  dark,
  source,
  straightToPayment
}) => {
  const {
    data: subscriptionData,
    loading
  } = (0, _gql.useQuery)(GET_SUBSCRIPTION, {});
  if (loading) return /* @__PURE__ */_react.default.createElement(_uikit.Loader, null);
  const {
    status
  } = (subscriptionData == null ? void 0 : subscriptionData.currentStripeSubscription) || {};
  if (status) return /* @__PURE__ */_react.default.createElement(PremiumSettings, {
    source: "",
    dark
  });
  return /* @__PURE__ */_react.default.createElement(BuySubscriptionScreen, {
    source,
    straightToPayment
  });
};
exports.PremiumScreen = PremiumScreen;
const PremiumSettings = (0, _helpers.withDialogs)(({
  confirm,
  dark
}) => {
  const history = (0, _reactRouterDom.useHistory)();
  const [_paymentMethod, _setPaymentMethod] = (0, _react.useState)(null);
  const {
    data: subscriptionData,
    loading
  } = (0, _gql.useQuery)(GET_SUBSCRIPTION, {});
  const [updateStripePaymentMethod] = (0, _gql.useMutation)(UPDATE_PAYMENT_METHOD);
  const [cancelStripeSubscription] = (0, _gql.useMutation)(CANCEL_SUBSCRIPTION);
  const [reactivateStripeSubscription] = (0, _gql.useMutation)(REACTIVATE_SUBSCRIPTION);
  const {
    cancelAtPeriodEnd,
    periodEnds,
    status
  } = (subscriptionData == null ? void 0 : subscriptionData.currentStripeSubscription) || {};
  if (loading) return /* @__PURE__ */_react.default.createElement(_uikit.Loader, null);
  if (status !== "active" && status !== "trialing") {
    return /* @__PURE__ */_react.default.createElement(_uikit.Loader, {
      size: "massive",
      active: true,
      content: "Processing payment..."
    });
  }
  if (hidePremium) {
    return null;
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Kosmi Premium",
    dark
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subscription
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subscriptionChild
  }, "You are subscribed!"), cancelAtPeriodEnd ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subscriptionChild
  }, "Cancels on ", periodEnds) : /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subscriptionChild
  }, "Renews on ", periodEnds)), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Button, {
    name: "Join VIP Room",
    icon: "star",
    onClick: () => {
      history.push("/room/vip");
    }
  }), cancelAtPeriodEnd ? /* @__PURE__ */_react.default.createElement(_uikit.Settings.Button, {
    color: "success",
    onClick: () => __async(void 0, null, function* () {
      if (yield confirm("Resubscribe?")) {
        reactivateStripeSubscription();
      }
    }),
    name: "Reactivate"
  }) : /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, !hidePremium && /* @__PURE__ */_react.default.createElement(_PaymentSection.default, {
    toggle: true,
    source: "PremiumSettings",
    onPaymentMethod: pm => __async(void 0, null, function* () {
      yield updateStripePaymentMethod({
        variables: {
          paymentMethodId: pm.id
        }
      });
    })
  }), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Button, {
    color: "danger",
    icon: "close",
    onClick: () => __async(void 0, null, function* () {
      if (yield confirm("Cancel subscription?")) {
        cancelStripeSubscription();
      }
    }),
    name: "Cancel Subscription"
  })));
});
const PaywallMessage = ({
  message,
  source
}) => {
  if (hidePremium) {
    return null;
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.paywall
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.paywallMessage
  }, message), /* @__PURE__ */_react.default.createElement(PremiumModal, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      size: "xl",
      color: "red",
      style: {
        width: 200,
        fontSize: 24,
        marginTop: 20
      }
    }, /* @__PURE__ */_react.default.createElement(Diamond, {
      className: styles.diamondPaywall
    }), "Subscribe"),
    source,
    straightToPayment: true
  }));
};
exports.PaywallMessage = PaywallMessage;
const PremiumModal = exports.PremiumModal = (0, _helpers.withDialogs)(({
  trigger,
  source,
  straightToPayment
}) => {
  const track = (0, _helpers.useTracking)();
  const [opened, setOpened] = (0, _react.useState)(false);
  if (hidePremium) {
    return null;
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    huge: true,
    preventClosing: true,
    trigger,
    onOpen: () => {
      track("Premium", "OpenPremiumModal", "Open Premium Modal");
      setOpened(true);
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, /* @__PURE__ */_react.default.createElement(Diamond, {
    className: styles.diamondHeader
  }), "Kosmi Premium"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, opened && /* @__PURE__ */_react.default.createElement(PremiumScreen, {
    source,
    dark: true,
    straightToPayment
  })));
});
var _default = exports.default = PremiumModal;