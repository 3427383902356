"use strict";
import React, { forwardRef, memo } from "react";
import { currentUserHook } from "../helpers/withCurrentUser";
import withDialogs from "../helpers/withDialogs";
export default withDialogs(
  memo(
    forwardRef(
      ({
        children,
        alert,
        onClick
      }, ref) => {
        const currentUser = currentUserHook();
        const emailConfirmed = currentUser == null ? void 0 : currentUser.emailConfirmed;
        const openModal = () => alert && alert("Please confirm your email");
        if (emailConfirmed) {
          return typeof children === "function" ? children(() => {
          }) : React.cloneElement(children, {
            ref,
            onClick: onClick || children.props.onClick
          });
        }
        return /* @__PURE__ */ React.createElement(React.Fragment, null, typeof children === "function" ? children(openModal) : React.cloneElement(children, { onClick: openModal }));
      }
    )
  )
);
