"use strict";
import * as types from "./graphql";
const documents = {
  "\n  query CardsAgainstHumanityQuery($roomId: String!) {\n    cardsAgainstHumanityState(roomId: $roomId) {\n      id\n      hideCardsOnTable\n      timeoutTimestamp\n      blackCard {\n        text\n        color\n        numAnswers\n        visible\n      }\n      whiteCards {\n        color\n        text\n        winner\n        visible\n        owner {\n          id\n        }\n      }\n      players {\n        minutesSinceLastPoop\n        czar\n        state\n        points\n        state\n        user {\n          id\n          displayName\n          avatarUrl\n          isAnonymous\n        }\n        cards {\n          text\n          color\n        }\n      }\n    }\n  }\n": types.CardsAgainstHumanityQueryDocument,
  "\n  subscription CardsAgainstHumanitySubscription($roomId: String!) {\n    cardsAgainstHumanityStateUpdate(roomId: $roomId) {\n      id\n      hideCardsOnTable\n      timeoutTimestamp\n      whiteCards {\n        color\n        text\n        winner\n        visible\n        owner {\n          id\n        }\n      }\n      blackCard {\n        text\n        color\n        numAnswers\n        visible\n      }\n      players {\n        user {\n          id\n          displayName\n          avatarUrl\n          isAnonymous\n        }\n        cards {\n          text\n          color\n        }\n        points\n        minutesSinceLastPoop\n        czar\n        state\n      }\n    }\n  }\n": types.CardsAgainstHumanitySubscriptionDocument,
  "\n  mutation LeaveCardsAgainstHumanity1($roomId: String!) {\n    cardsAgainstHumanityShowBlackCard(roomId: $roomId) {\n      ok\n    }\n  }\n": types.LeaveCardsAgainstHumanity1Document,
  "\n  mutation ChooseCards($roomId: String!, $cardIndexes: [Int]!) {\n    cardsAgainstHumanityChooseCards(\n      roomId: $roomId\n      cardIndexes: $cardIndexes\n    ) {\n      ok\n    }\n  }\n": types.ChooseCardsDocument,
  "\n  mutation CardsAgainstHumanityHideCardsOnTable1($roomId: String!) {\n    cardsAgainstHumanityHideCardsOnTable(roomId: $roomId) {\n      ok\n    }\n  }\n": types.CardsAgainstHumanityHideCardsOnTable1Document,
  "\n  mutation ChooseWinningCards($roomId: String!, $cardIndexes: [Int]!) {\n    cardsAgainstHumanityChooseWinningCards(\n      roomId: $roomId\n      cardIndexes: $cardIndexes\n    ) {\n      ok\n    }\n  }\n": types.ChooseWinningCardsDocument,
  "\n  mutation JoinCardsAgainstHumanity1($roomId: String!) {\n    cardsAgainstHumanityJoinGame(roomId: $roomId) {\n      ok\n    }\n  }\n": types.JoinCardsAgainstHumanity1Document,
  "\n  mutation LeaveCardsAgainstHumanity2($roomId: String!) {\n    cardsAgainstHumanityLeaveGame(roomId: $roomId) {\n      ok\n    }\n  }\n": types.LeaveCardsAgainstHumanity2Document,
  "\n  mutation CardsAgainstHumanityHideCardsOnTable($roomId: String!) {\n    cardsAgainstHumanityHideCardsOnTable(roomId: $roomId) {\n      ok\n    }\n  }\n": types.CardsAgainstHumanityHideCardsOnTableDocument,
  "\n  mutation LeaveCardsAgainstHumanity3($roomId: String!) {\n    cardsAgainstHumanityLeaveGame(roomId: $roomId) {\n      ok\n    }\n  }\n": types.LeaveCardsAgainstHumanity3Document,
  "\n  mutation CardsAgainstHumanityShowNextCard($roomId: String!) {\n    cardsAgainstHumanityShowNextCard(roomId: $roomId) {\n      ok\n    }\n  }\n": types.CardsAgainstHumanityShowNextCardDocument,
  "\n  mutation CardsAgainstHumanitySetPoopMinutes(\n    $roomId: String!\n    $minutes: Int!\n  ) {\n    cardsAgainstHumanitySetPoopMinutes(roomId: $roomId, minutes: $minutes) {\n      ok\n    }\n  }\n": types.CardsAgainstHumanitySetPoopMinutesDocument,
  "\n  mutation CardsAgainstHumanityRemovePlayer(\n    $roomId: String!\n    $playerId: String!\n  ) {\n    cardsAgainstHumanityRemovePlayer(roomId: $roomId, playerId: $playerId) {\n      ok\n    }\n  }\n": types.CardsAgainstHumanityRemovePlayerDocument,
  "\n  query CardsAgainstHumanitySettingsQuery($roomId: String!) {\n    cardsAgainstHumanityState(roomId: $roomId) {\n      id\n      chooseCardsTimeout\n      chooseWinnerTimeout\n      readCardTimeout\n      secondsBetweenRounds\n      showCardTimeout\n      autoStartWhenEnoughPlayers\n    }\n  }\n": types.CardsAgainstHumanitySettingsQueryDocument,
  "\n  mutation SetCardsAgainstHumanityConfig(\n    $roomId: String!\n    $chooseCardsTimeout: Int!\n    $chooseWinnerTimeout: Int!\n    $readCardTimeout: Int!\n    $secondsBetweenRounds: Int!\n    $showCardTimeout: Int!\n    $autoStartWhenEnoughPlayers: Boolean!\n  ) {\n    cardsAgainstHumanitySetConfig(\n      roomId: $roomId\n      chooseCardsTimeout: $chooseCardsTimeout\n      chooseWinnerTimeout: $chooseWinnerTimeout\n      readCardTimeout: $readCardTimeout\n      secondsBetweenRounds: $secondsBetweenRounds\n      showCardTimeout: $showCardTimeout\n      autoStartWhenEnoughPlayers: $autoStartWhenEnoughPlayers\n    ) {\n      ok\n    }\n  }\n": types.SetCardsAgainstHumanityConfigDocument,
  "\n  mutation CAHTableReset($roomId: String!) {\n    cardsAgainstHumanityResetTable(roomId: $roomId) {\n      ok\n    }\n  }\n": types.CahTableResetDocument,
  "\n  mutation CardsAgainstHumanitySitEveryoneDown($roomId: String!) {\n    cardsAgainstHumanitySitEveryoneDown(roomId: $roomId) {\n      ok\n    }\n  }\n": types.CardsAgainstHumanitySitEveryoneDownDocument,
  "\n  mutation JoinCardsAgainstHumanity($roomId: String!) {\n    cardsAgainstHumanityStartGame(roomId: $roomId) {\n      ok\n    }\n  }\n": types.JoinCardsAgainstHumanityDocument,
  "\n  query CardTableQuery($roomId: String!) {\n    cardtable(roomId: $roomId) {\n      id\n      zoom\n      cards {\n        dragging\n        flipped\n        orientation\n        order\n        rank\n        suit\n        joker\n        x\n        y\n      }\n      seats {\n        seatno\n        playerId\n        cards\n        player {\n          id\n          username\n          displayName\n          avatarUrl\n          isAnonymous\n        }\n      }\n    }\n    currentUser {\n      id\n      user {\n        id\n        username\n        displayName\n        avatarUrl\n        isAnonymous\n      }\n    }\n    room(id: $roomId) {\n      id\n      state {\n        members {\n          id\n          user {\n            id\n            username\n            displayName\n            avatarUrl\n          }\n        }\n      }\n    }\n  }\n": types.CardTableQueryDocument,
  "\n  subscription OnCardTableSitDownSubscription($roomId: String!) {\n    cardtableSitDown(roomId: $roomId) {\n      player {\n        id\n        username\n        displayName\n        avatarUrl\n        isAnonymous\n      }\n      seatNo\n    }\n  }\n": types.OnCardTableSitDownSubscriptionDocument,
  "\n  subscription OnCardTableStandUpSubscription($roomId: String!) {\n    cardtableStandUp(roomId: $roomId) {\n      player {\n        id\n        username\n        displayName\n        avatarUrl\n      }\n    }\n  }\n": types.OnCardTableStandUpSubscriptionDocument,
  "\n  subscription OnCardTableMoveCardsSubscription($roomId: String!) {\n    cardtableMoveCards(roomId: $roomId) {\n      indexes\n      x\n      y\n      userId\n    }\n  }\n": types.OnCardTableMoveCardsSubscriptionDocument,
  "\n  subscription OnCardTableDragCardsSubscription($roomId: String!) {\n    cardtableDragCards(roomId: $roomId) {\n      indexes\n      orientation\n      seatIndex\n    }\n  }\n": types.OnCardTableDragCardsSubscriptionDocument,
  "\n  subscription OnCardTableRotateCardsSubscription($roomId: String!) {\n    cardtableRotateCards(roomId: $roomId) {\n      indexes\n      orientation\n    }\n  }\n": types.OnCardTableRotateCardsSubscriptionDocument,
  "\n  subscription OnCardTableUndragCardsSubscription($roomId: String!) {\n    cardtableUndragCards(roomId: $roomId) {\n      indexes\n    }\n  }\n": types.OnCardTableUndragCardsSubscriptionDocument,
  "\n  subscription OnCardTableGiveCardsSubscription($roomId: String!) {\n    cardtableGiveCards(roomId: $roomId) {\n      indexes\n      seatNo\n      handIndex\n    }\n  }\n": types.OnCardTableGiveCardsSubscriptionDocument,
  "\n  subscription OnCardTableUngiveCardsSubscription($roomId: String!) {\n    cardtableUngiveCards(roomId: $roomId) {\n      indexes\n    }\n  }\n": types.OnCardTableUngiveCardsSubscriptionDocument,
  "\n  subscription OnCardTableFlipardsSubscription($roomId: String!) {\n    cardtableFlipCards(roomId: $roomId) {\n      indexes\n    }\n  }\n": types.OnCardTableFlipardsSubscriptionDocument,
  "\n  subscription CardtableStateReconciliation($roomId: String!) {\n    cardtableStateReconciliation(roomId: $roomId) {\n      state {\n        id\n        zoom\n        cards {\n          dragging\n          flipped\n          orientation\n          order\n          rank\n          suit\n          joker\n          x\n          y\n        }\n        seats {\n          seatno\n          playerId\n          cards\n          player {\n            id\n            username\n            displayName\n            avatarUrl\n            isAnonymous\n          }\n        }\n      }\n    }\n  }\n": types.CardtableStateReconciliationDocument,
  "\n  mutation CardTableSitEveryoneDownMutation($roomId: String!) {\n    cardtableSitEveryoneDown(roomId: $roomId) {\n      ok\n    }\n  }\n": types.CardTableSitEveryoneDownMutationDocument,
  "\n  mutation SeatSeatsMutation($roomId: String!, $seats: Int!) {\n    cardtableSetSeats(roomId: $roomId, seats: $seats) {\n      ok\n    }\n  }\n": types.SeatSeatsMutationDocument,
  "\n  mutation SetZoomMutation($roomId: String!, $zoom: Float!) {\n    cardtableSetZoom(roomId: $roomId, zoom: $zoom) {\n      ok\n    }\n  }\n": types.SetZoomMutationDocument,
  "\n  mutation ShuffleDeckMutation($roomId: String!) {\n    cardtableShuffleCards(roomId: $roomId) {\n      ok\n    }\n  }\n": types.ShuffleDeckMutationDocument,
  "\n  mutation AddDeckMutation($roomId: String!, $deckType: DeckType!) {\n    cardtableAddDeck(roomId: $roomId, deckType: $deckType) {\n      ok\n    }\n  }\n": types.AddDeckMutationDocument,
  "\n  mutation RemoveDecksMutation($roomId: String!) {\n    cardtableRemoveDecks(roomId: $roomId) {\n      ok\n    }\n  }\n": types.RemoveDecksMutationDocument,
  "\n  query SeatQuery($roomId: String!) {\n    cardtable(roomId: $roomId) {\n      id\n      zoom\n      seats {\n        seatno\n        playerId\n        cards\n        player {\n          id\n          username\n          displayName\n          avatarUrl\n          isAnonymous\n        }\n      }\n    }\n  }\n": types.SeatQueryDocument,
  "\n      mutation SitDown($roomId: String!, $seatNo: Int!) {\n        cardtableSitDown(seatNo: $seatNo, roomId: $roomId) {\n          ok\n        }\n      }\n    ": types.SitDownDocument,
  "\n      mutation StandUp($roomId: String!, $playerId: String!) {\n        cardtableStandUp(roomId: $roomId, playerId: $playerId) {\n          ok\n        }\n      }\n    ": types.StandUpDocument,
  "\n      mutation MoveCards(\n        $roomId: String!\n        $indexes: [Int]!\n        $x: Int!\n        $y: Int!\n      ) {\n        cardtableMoveCards(roomId: $roomId, indexes: $indexes, x: $x, y: $y) {\n          ok\n        }\n      }\n    ": types.MoveCardsDocument,
  "\n      mutation DragCards(\n        $roomId: String!\n        $indexes: [Int]!\n        $orientation: Float!\n        $seatIndex: Int!\n      ) {\n        cardtableDragCards(\n          roomId: $roomId\n          indexes: $indexes\n          orientation: $orientation\n          seatIndex: $seatIndex\n        ) {\n          ok\n        }\n      }\n    ": types.DragCardsDocument,
  "\n      mutation RotateCards(\n        $roomId: String!\n        $indexes: [Int]!\n        $orientation: Float!\n      ) {\n        cardtableRotateCards(\n          roomId: $roomId\n          indexes: $indexes\n          orientation: $orientation\n        ) {\n          ok\n        }\n      }\n    ": types.RotateCardsDocument,
  "\n      mutation UndragCards($roomId: String!, $indexes: [Int]!) {\n        cardtableUndragCards(roomId: $roomId, indexes: $indexes) {\n          ok\n        }\n      }\n    ": types.UndragCardsDocument,
  "\n      mutation GiveCards(\n        $roomId: String!\n        $indexes: [Int]!\n        $seatNo: Int!\n        $handIndex: Int!\n      ) {\n        cardtableGiveCards(\n          roomId: $roomId\n          indexes: $indexes\n          seatNo: $seatNo\n          handIndex: $handIndex\n        ) {\n          ok\n        }\n      }\n    ": types.GiveCardsDocument,
  "\n      mutation UngiveCards($roomId: String!, $indexes: [Int]!) {\n        cardtableUngiveCards(roomId: $roomId, indexes: $indexes) {\n          ok\n        }\n      }\n    ": types.UngiveCardsDocument,
  "\n      mutation FlipCards($roomId: String!, $indexes: [Int]!) {\n        cardtableFlipCards(roomId: $roomId, indexes: $indexes) {\n          ok\n        }\n      }\n    ": types.FlipCardsDocument,
  "\n  query kosmiCloudStateQuery($roomId: String!) {\n    kosmiCloudState(roomId: $roomId) {\n      url\n    }\n  }\n": types.KosmiCloudStateQueryDocument,
  "\n  query PublicRoomQuery {\n    publicRooms {\n      appInfo\n      appName\n      countryCode\n      joinCode\n      memberCount\n      roomName\n      description\n      avatarUrl\n      autoAcceptJoins\n      boosted\n    }\n  }\n": types.PublicRoomQueryDocument,
  "\nsubscription PublicRoomUpdateSubscription {\n  publicRoomsUpdate {\n      appInfo\n      appName\n      countryCode\n      joinCode\n      memberCount\n      roomName\n      description\n      avatarUrl\n      autoAcceptJoins\n      boosted\n  }\n}\n": types.PublicRoomUpdateSubscriptionDocument,
  "\n  mutation miniroyale2StartServer($roomId: String!, $serverId: String!) {\n    miniroyale2StartServer(roomId: $roomId, serverId: $serverId) {\n      ok\n    }\n  }\n": types.Miniroyale2StartServerDocument,
  "\n  mutation miniroyale2StopServer($roomId: String!) {\n    miniroyale2StopServer(roomId: $roomId) {\n      ok\n    }\n  }\n": types.Miniroyale2StopServerDocument,
  "\n  query GetRoom1($id: String!) {\n    room(id: $id) {\n      id\n      state {\n        members {\n          id\n          user {\n            id\n          }\n        }\n      }\n    }\n  }\n": types.GetRoom1Document,
  "\n  query miniroyale2Query($roomId: String!) {\n    miniroyale2State(roomId: $roomId) {\n      id\n      host {\n        serverId\n      }\n    }\n  }\n": types.Miniroyale2QueryDocument,
  "\n  subscription miniroyale2UpdateState($roomId: String!) {\n    miniroyale2UpdateState(roomId: $roomId) {\n      id\n      host {\n        serverId\n      }\n    }\n  }\n": types.Miniroyale2UpdateStateDocument,
  "\n  query NESPartyStateQuery($roomId: String!) {\n    nespartyState(roomId: $roomId) {\n      id\n      host {\n        user {\n          id\n          username\n          displayName\n        }\n        loading\n      }\n    }\n  }\n": types.NesPartyStateQueryDocument,
  "\n  subscription NESPartyUpdate($roomId: String!) {\n    nespartyUpdateState(roomId: $roomId) {\n      id\n      host {\n        user {\n          id\n          username\n          displayName\n        }\n        loading\n      }\n    }\n  }\n": types.NesPartyUpdateDocument,
  "\n  mutation NESPartyLoadEmulator($roomId: String!) {\n    nespartyLoadEmulator(roomId: $roomId) {\n      ok\n    }\n  }\n": types.NesPartyLoadEmulatorDocument,
  "\n  mutation NESPartyStartmulator($roomId: String!) {\n    nespartyStartEmulator(roomId: $roomId) {\n      ok\n    }\n  }\n": types.NesPartyStartmulatorDocument,
  "\n  mutation NESPartyStopEmulator($roomId: String!) {\n    nespartyStopEmulator(roomId: $roomId) {\n      ok\n    }\n  }\n": types.NesPartyStopEmulatorDocument,
  "\n  mutation OpenArenaStopServer1($roomId: String!) {\n    openarenaStopServer(roomId: $roomId) {\n      ok\n    }\n  }\n": types.OpenArenaStopServer1Document,
  "\n  query OpenArenaMenuQuery($roomId: String!) {\n    openarenaState(roomId: $roomId) {\n      id\n      host {\n        peerId\n        user {\n          id\n          username\n          displayName\n        }\n      }\n      status {\n        gamemode\n        map\n        maxPlayers\n        mod\n        players\n      }\n    }\n  }\n": types.OpenArenaMenuQueryDocument,
  "\n  mutation OpenArenaStartServer($roomId: String!, $peerId: Int) {\n    openarenaStartServer(roomId: $roomId, peerId: $peerId) {\n      ok\n    }\n  }\n": types.OpenArenaStartServerDocument,
  "\n  mutation OpenArenaUpdateStatus(\n    $roomId: String!\n    $gamemode: String\n    $map: String\n    $maxPlayers: Int\n    $mod: String\n    $players: Int\n    $public: Boolean\n  ) {\n    openarenaUpdateStatus(\n      roomId: $roomId\n      gamemode: $gamemode\n      map: $map\n      maxPlayers: $maxPlayers\n      mod: $mod\n      players: $players\n      public: $public\n    ) {\n      ok\n    }\n  }\n": types.OpenArenaUpdateStatusDocument,
  "\n  mutation OpenArenaStopServer2($roomId: String!) {\n    openarenaStopServer(roomId: $roomId) {\n      ok\n    }\n  }\n": types.OpenArenaStopServer2Document,
  "\n  query GetRoom2($id: String!) {\n    room(id: $id) {\n      id\n      state {\n        members {\n          id\n          user {\n            id\n          }\n        }\n      }\n    }\n  }\n": types.GetRoom2Document,
  "\n  query OpenArenaQuery($roomId: String!) {\n    openarenaState(roomId: $roomId) {\n      id\n      host {\n        peerId\n        user {\n          id\n          username\n          displayName\n        }\n      }\n      status {\n        gamemode\n        map\n        maxPlayers\n        mod\n        players\n      }\n    }\n  }\n": types.OpenArenaQueryDocument,
  "\n  subscription OpenArenaUpdateState($roomId: String!) {\n    openarenaUpdateState(roomId: $roomId) {\n      id\n      host {\n        user {\n          id\n          avatarUrl\n          username\n          displayName\n        }\n        peerId\n      }\n      status {\n        gamemode\n        map\n        maxPlayers\n        mod\n        players\n      }\n    }\n  }\n": types.OpenArenaUpdateStateDocument,
  "\n  query CurrentUserQuery1 {\n    currentUser {\n      id\n      user {\n        id\n        displayName\n        username\n        isAnonymous\n      }\n    }\n  }\n": types.CurrentUserQuery1Document,
  "\n  mutation PokerForceCashOut($roomId: String!, $userId: String!) {\n    pokerForceCashOut(userId: $userId, roomId: $roomId) {\n      ok\n    }\n  }\n": types.PokerForceCashOutDocument,
  "\n  mutation PokerForceFoldorCheck($roomId: String!) {\n    pokerFoldOrCheckCurrentPlayer(roomId: $roomId) {\n      ok\n    }\n  }\n": types.PokerForceFoldorCheckDocument,
  "\n  mutation PokerSitEveryoneDown($roomId: String!) {\n    pokerSitEveryoneDown(roomId: $roomId) {\n      ok\n    }\n  }\n": types.PokerSitEveryoneDownDocument,
  "\n  query PokerQuery1($roomId: String!) {\n    poker(roomId: $roomId) {\n      id\n      players {\n        state\n      }\n    }\n  }\n": types.PokerQuery1Document,
  "\n  query PokerQuery2($roomId: String!) {\n    poker(roomId: $roomId) {\n      id\n      playerTurnTimeout\n      tablecards {\n        rank\n        suit\n      }\n      turnStart\n      smallBlind\n      bigBlind\n      autoStart\n      paused\n      players {\n        betAmount\n        totalBetAmount\n        preselectedAction\n        cards {\n          rank\n          suit\n        }\n        hand {\n          cards {\n            rank\n            suit\n          }\n          type\n        }\n        isDealer\n        isWinner\n        state\n        user {\n          id\n          displayName\n          avatarUrl\n          isAnonymous\n        }\n      }\n      funds {\n        funds\n        playerId\n        user {\n          id\n          avatarUrl\n          displayName\n          username\n        }\n      }\n    }\n    currentUser {\n      id\n      connectionId\n      user {\n        id\n        isAnonymous\n      }\n    }\n  }\n": types.PokerQuery2Document,
  "\n  subscription PokerSubscription($roomId: String!) {\n    pokerUpdate(roomId: $roomId) {\n      id\n      turnStart\n      smallBlind\n      bigBlind\n      playerTurnTimeout\n      autoStart\n      paused\n      tablecards {\n        rank\n        suit\n      }\n      players {\n        betAmount\n        totalBetAmount\n        preselectedAction\n        cards {\n          rank\n          suit\n        }\n        hand {\n          cards {\n            rank\n            suit\n          }\n          type\n        }\n        isDealer\n        isWinner\n        state\n        user {\n          id\n          displayName\n          avatarUrl\n          isAnonymous\n        }\n      }\n      funds {\n        playerId\n        funds\n        user {\n          id\n          avatarUrl\n          displayName\n          username\n        }\n      }\n    }\n  }\n": types.PokerSubscriptionDocument,
  "\n      mutation PokerCashIn($roomId: String!) {\n        pokerCashIn(roomId: $roomId) {\n          ok\n        }\n      }\n    ": types.PokerCashInDocument,
  "\n      mutation PokerCashOut($roomId: String!) {\n        pokerCashOut(roomId: $roomId) {\n          ok\n        }\n      }\n    ": types.PokerCashOutDocument,
  "\n      mutation PokerFold($roomId: String!) {\n        pokerFold(roomId: $roomId) {\n          ok\n        }\n      }\n    ": types.PokerFoldDocument,
  "\n      mutation PokerCheck($roomId: String!) {\n        pokerCheck(roomId: $roomId) {\n          ok\n        }\n      }\n    ": types.PokerCheckDocument,
  "\n      mutation PokerCall($roomId: String!) {\n        pokerCall(roomId: $roomId) {\n          ok\n        }\n      }\n    ": types.PokerCallDocument,
  "\n      mutation PokerAutoCall($roomId: String!) {\n        pokerAutoCall(roomId: $roomId) {\n          ok\n        }\n      }\n    ": types.PokerAutoCallDocument,
  "\n      mutation PokerAutoCheckOrFold($roomId: String!) {\n        pokerAutoCheckOrFold(roomId: $roomId) {\n          ok\n        }\n      }\n    ": types.PokerAutoCheckOrFoldDocument,
  "\n      mutation PokerRemoveAutoAction($roomId: String!) {\n        pokerRemoveAutoAction(roomId: $roomId) {\n          ok\n        }\n      }\n    ": types.PokerRemoveAutoActionDocument,
  "\n      mutation PokerRaise($roomId: String!, $amount: Int!) {\n        pokerRaise(roomId: $roomId, amount: $amount) {\n          ok\n        }\n      }\n    ": types.PokerRaiseDocument,
  "\n      mutation PokerStartGame($roomId: String!) {\n        pokerStartGame(roomId: $roomId) {\n          ok\n        }\n      }\n    ": types.PokerStartGameDocument,
  "\n      mutation PokerPauseGame($roomId: String!) {\n        pokerPauseGame(roomId: $roomId) {\n          ok\n        }\n      }\n    ": types.PokerPauseGameDocument,
  "\n  query PokerSettingsQuery($roomId: String!) {\n    poker(roomId: $roomId) {\n      id\n      defaultBuyinAmount\n      timeBetweenRounds\n      playerTurnTimeout\n      enableChatAnnouncements\n      smallBlind\n      bigBlind\n      autoStart\n      funds {\n        funds\n        playerId\n        user {\n          id\n          avatarUrl\n          displayName\n          username\n        }\n      }\n    }\n  }\n": types.PokerSettingsQueryDocument,
  "\n  mutation SetPokerConfig(\n    $roomId: String!\n    $enableChatAnnouncements: Boolean!\n    $playerTurnTimeout: Int!\n    $timeBetweenRounds: Int!\n    $smallBlind: Int!\n    $bigBlind: Int!\n    $defaultBuyinAmount: Int!\n    $autoStart: Boolean!\n  ) {\n    pokerSetConfig(\n      roomId: $roomId\n      enableChatAnnouncements: $enableChatAnnouncements\n      playerTurnTimeout: $playerTurnTimeout\n      timeBetweenRounds: $timeBetweenRounds\n      smallBlind: $smallBlind\n      bigBlind: $bigBlind\n      defaultBuyinAmount: $defaultBuyinAmount\n      autoStart: $autoStart\n    ) {\n      ok\n    }\n  }\n": types.SetPokerConfigDocument,
  "\n  mutation PokerTableReset($roomId: String!) {\n    pokerResetTable(roomId: $roomId) {\n      ok\n    }\n  }\n": types.PokerTableResetDocument,
  "\n  subscription PooltableOnShot($roomId: String!) {\n    pooltableOnShot(roomId: $roomId) {\n      x\n      y\n      order\n      userId\n      power\n    }\n  }\n": types.PooltableOnShotDocument,
  "\n  query PooltableQuery($roomId: String!) {\n    pooltable(roomId: $roomId) {\n      id\n      shots {\n        x\n        y\n        order\n        userId\n        power\n      }\n    }\n  }\n": types.PooltableQueryDocument,
  "\n      mutation ShootBall(\n        $roomId: String!\n        $x: Float!\n        $y: Float!\n        $order: Int!\n        $power: Float!\n      ) {\n        pooltableShoot(\n          roomId: $roomId\n          x: $x\n          y: $y\n          order: $order\n          power: $power\n        ) {\n          ok\n        }\n      }\n    ": types.ShootBallDocument,
  "\n  query PSXStateQuery($roomId: String!) {\n    psxState(roomId: $roomId) {\n      id\n      host {\n        user {\n          id\n          username\n          displayName\n        }\n        loading\n      }\n    }\n  }\n": types.PsxStateQueryDocument,
  "\n  subscription PSXUpdate($roomId: String!) {\n    psxUpdateState(roomId: $roomId) {\n      id\n      host {\n        user {\n          id\n          username\n          displayName\n        }\n        loading\n      }\n    }\n  }\n": types.PsxUpdateDocument,
  "\n  mutation PSXLoadEmulator($roomId: String!) {\n    psxLoadEmulator(roomId: $roomId) {\n      ok\n    }\n  }\n": types.PsxLoadEmulatorDocument,
  "\n  mutation PSXStartmulator($roomId: String!) {\n    psxStartEmulator(roomId: $roomId) {\n      ok\n    }\n  }\n": types.PsxStartmulatorDocument,
  "\n  mutation PSXStopEmulator($roomId: String!) {\n    psxStopEmulator(roomId: $roomId) {\n      ok\n    }\n  }\n": types.PsxStopEmulatorDocument,
  "\n  query SNESPartyStateQuery($roomId: String!) {\n    snespartyState(roomId: $roomId) {\n      id\n      host {\n        user {\n          id\n          username\n          displayName\n        }\n        loading\n      }\n    }\n  }\n": types.SnesPartyStateQueryDocument,
  "\n  subscription SNESPartyUpdate($roomId: String!) {\n    snespartyUpdateState(roomId: $roomId) {\n      id\n      host {\n        user {\n          id\n          username\n          displayName\n        }\n        loading\n      }\n    }\n  }\n": types.SnesPartyUpdateDocument,
  "\n  mutation SNESPartyLoadEmulator($roomId: String!) {\n    snespartyLoadEmulator(roomId: $roomId) {\n      ok\n    }\n  }\n": types.SnesPartyLoadEmulatorDocument,
  "\n  mutation SNESPartyStartmulator($roomId: String!) {\n    snespartyStartEmulator(roomId: $roomId) {\n      ok\n    }\n  }\n": types.SnesPartyStartmulatorDocument,
  "\n  mutation SNESPartyStopEmulator($roomId: String!) {\n    snespartyStopEmulator(roomId: $roomId) {\n      ok\n    }\n  }\n": types.SnesPartyStopEmulatorDocument,
  "\n  query Themes {\n    themes {\n      backgroundImageUrl\n      colorScheme\n      name\n      sofaUrl\n      tvPlaceholderUrl\n    }\n  }\n": types.ThemesDocument,
  "\n  query GetTableMembers1($roomId: String!) {\n    room(id: $roomId) {\n      id\n      state {\n        members {\n          id\n          user {\n            id\n            username\n            displayName\n            avatarUrl\n            countryCode\n            isAnonymous\n            isSubscribed\n          }\n        }\n      }\n    }\n  }\n": types.GetTableMembers1Document,
  "\n  mutation MediaPlayerSetPosition1(\n    $roomId: String!\n    $key: String!\n    $value: String!\n  ) {\n    spacesUpdateValue(roomId: $roomId, key: $key, value: $value) {\n      ok\n    }\n  }\n": types.MediaPlayerSetPosition1Document,
  "\n  mutation MediaPlayerSetPosition2($roomId: String!, $key: String!) {\n    spacesRemoveValue(roomId: $roomId, key: $key) {\n      ok\n    }\n  }\n": types.MediaPlayerSetPosition2Document,
  "\n  query GetTableMembers2($roomId: String!) {\n    room(id: $roomId) {\n      id\n      state {\n        members {\n          id\n          user {\n            id\n            username\n            displayName\n            avatarUrl\n          }\n        }\n      }\n    }\n  }\n": types.GetTableMembers2Document,
  "\n  query usfsStateQuery($roomId: String!) {\n    usfsState(roomId: $roomId) {\n      tags {\n        position\n        review\n        value\n      }\n    }\n  }\n": types.UsfsStateQueryDocument,
  "\n  subscription OnusfsStateUpdate($roomId: String!) {\n    usfsUpdateState(roomId: $roomId) {\n      tags {\n        position\n        review\n        value\n      }\n    }\n  }\n": types.OnusfsStateUpdateDocument,
  "\n  mutation USFSAddTag(\n    $roomId: String!\n    $review: Boolean!\n    $value: String!\n    $position: Float!\n  ) {\n    usfsAddTag(\n      roomId: $roomId\n      value: $value\n      review: $review\n      position: $position\n    ) {\n      ok\n    }\n  }\n": types.UsfsAddTagDocument,
  "\n  mutation USFSUpdateTag(\n    $roomId: String!\n    $index: Int!\n    $review: Boolean!\n    $value: String!\n    $position: Float!\n  ) {\n    usfsUpdateTag(\n      roomId: $roomId\n      index: $index\n      value: $value\n      review: $review\n      position: $position\n    ) {\n      ok\n    }\n  }\n": types.UsfsUpdateTagDocument,
  "\n  mutation USFSRemoveTag($roomId: String!, $index: Int!) {\n    usfsRemoveTag(roomId: $roomId, index: $index) {\n      ok\n    }\n  }\n": types.UsfsRemoveTagDocument,
  "\n  mutation USFSClearTags($roomId: String!) {\n    usfsClearTags(roomId: $roomId) {\n      ok\n    }\n  }\n": types.UsfsClearTagsDocument,
  "\n  mutation VengeIOStopServer($roomId: String!) {\n    vengeioStopServer(roomId: $roomId) {\n      ok\n    }\n  }\n": types.VengeIoStopServerDocument,
  "\n  query VengeIOQuery1($roomId: String!) {\n    vengeioState(roomId: $roomId) {\n      id\n      host {\n        serverId\n      }\n    }\n  }\n": types.VengeIoQuery1Document,
  "\n  mutation CreateVengeServerMutation($map: String!, $country: String!) {\n    vengeioCreateServer(country: $country, map: $map) {\n      serverId\n    }\n  }\n": types.CreateVengeServerMutationDocument,
  "\n  mutation VengeIOStartServer($roomId: String!, $serverId: String!) {\n    vengeioStartServer(roomId: $roomId, serverId: $serverId) {\n      ok\n    }\n  }\n": types.VengeIoStartServerDocument,
  "\n  mutation VengeIOStopServer1($roomId: String!) {\n    vengeioStopServer(roomId: $roomId) {\n      ok\n    }\n  }\n": types.VengeIoStopServer1Document,
  "\n  query GetRoom3($id: String!) {\n    room(id: $id) {\n      id\n      state {\n        members {\n          id\n          user {\n            id\n          }\n        }\n      }\n    }\n  }\n": types.GetRoom3Document,
  "\n  query VengeIOQuery2($roomId: String!) {\n    vengeioState(roomId: $roomId) {\n      id\n      host {\n        serverId\n      }\n    }\n  }\n": types.VengeIoQuery2Document,
  "\n  subscription VengeIOUpdateState($roomId: String!) {\n    vengeioUpdateState(roomId: $roomId) {\n      id\n      host {\n        serverId\n      }\n    }\n  }\n": types.VengeIoUpdateStateDocument,
  "\n  query VideoChatStateQuery($roomId: String!) {\n    videoChatState(roomId: $roomId) {\n      focusedUser {\n        id\n        displayName\n      }\n    }\n  }\n": types.VideoChatStateQueryDocument,
  "\n  subscription OnVideoChatUpdate($roomId: String!) {\n    videoChatStateUpdate(roomId: $roomId) {\n      focusedUser {\n        id\n      }\n    }\n  }\n": types.OnVideoChatUpdateDocument,
  "\n  mutation FocusUser($userId: String, $roomId: String!) {\n    videoChatFocusUser(roomId: $roomId, userId: $userId) {\n      ok\n    }\n  }\n": types.FocusUserDocument,
  "\n  query WebBrowserStateQuery($roomId: String!) {\n    webBrowserState(roomId: $roomId) {\n      sessionId\n    }\n  }\n": types.WebBrowserStateQueryDocument,
  "\n  subscription OnWebBrowserUpdateState($roomId: String!) {\n    webBrowserUpdate(roomId: $roomId) {\n      sessionId\n    }\n  }\n": types.OnWebBrowserUpdateStateDocument,
  "\n  mutation WebBrowserStart($roomId: String!, $url: String, $kiosk: Boolean) {\n    webBrowserStart(roomId: $roomId, url: $url, kiosk: $kiosk) {\n      ok\n    }\n  }\n": types.WebBrowserStartDocument,
  "\n  mutation WebViewSetUrl1($roomId: String!, $url: String!) {\n    webviewSetUrl(roomId: $roomId, url: $url) {\n      ok\n    }\n  }\n": types.WebViewSetUrl1Document,
  "\n  query WebViewStateQuery($roomId: String!) {\n    webviewState(roomId: $roomId) {\n      url\n    }\n  }\n": types.WebViewStateQueryDocument,
  "\n  subscription OnWebViewUpdateState($roomId: String!) {\n    webviewUpdate(roomId: $roomId) {\n      url\n    }\n  }\n": types.OnWebViewUpdateStateDocument,
  "\n  mutation WebViewSetUrl2($roomId: String!, $url: String!) {\n    webviewSetUrl(roomId: $roomId, url: $url) {\n      ok\n    }\n  }\n": types.WebViewSetUrl2Document,
  "\n  mutation WordleForceGiveUp($roomId: String!, $userId: String!) {\n    wortleForceGiveUp(roomId: $roomId, playerId: $userId) {\n      ok\n    }\n  }\n": types.WordleForceGiveUpDocument,
  "\n  query WortleQuery($roomId: String!) {\n    wortleState(roomId: $roomId) {\n      id\n      secondsBetweenRounds\n      started\n      word\n      players {\n        points\n        guesses\n        matches\n        winner\n        currentGuess\n        user {\n          avatarUrl\n          displayName\n          id\n          displayName\n          avatarUrl\n          isAnonymous\n        }\n      }\n    }\n  }\n": types.WortleQueryDocument,
  "\n  subscription WortleSubscription($roomId: String!) {\n    wortleStateUpdate(roomId: $roomId) {\n      id\n      secondsBetweenRounds\n      started\n      word\n      players {\n        points\n        guesses\n        matches\n        winner\n        currentGuess\n        user {\n          avatarUrl\n          displayName\n          id\n          displayName\n          avatarUrl\n          isAnonymous\n        }\n      }\n    }\n  }\n": types.WortleSubscriptionDocument,
  "\n  mutation JoinWortle($roomId: String!) {\n    wortleJoinGame(roomId: $roomId) {\n      ok\n    }\n  }\n": types.JoinWortleDocument,
  "\n  mutation LeaveWortle($roomId: String!) {\n    wortleLeaveGame(roomId: $roomId) {\n      ok\n    }\n  }\n": types.LeaveWortleDocument,
  "\n  mutation WortleRemovePlayer($roomId: String!, $playerId: String!) {\n    wortleRemovePlayer(roomId: $roomId, playerId: $playerId) {\n      ok\n    }\n  }\n": types.WortleRemovePlayerDocument,
  "\n  query WortleSettingsQuery($roomId: String!) {\n    wortleState(roomId: $roomId) {\n      id\n      secondsBetweenRounds\n      collaborate\n    }\n  }\n": types.WortleSettingsQueryDocument,
  "\n  mutation SetWortleConfig(\n    $roomId: String!\n    $secondsBetweenRounds: Int!\n    $collaborate: Boolean!\n  ) {\n    wortleSetConfig(\n      roomId: $roomId\n      collaborate: $collaborate\n      secondsBetweenRounds: $secondsBetweenRounds\n    ) {\n      ok\n    }\n  }\n": types.SetWortleConfigDocument,
  "\n  mutation WortleTableReset($roomId: String!) {\n    wortleResetTable(roomId: $roomId) {\n      ok\n    }\n  }\n": types.WortleTableResetDocument,
  "\n  mutation WortleSitEveryoneDown($roomId: String!) {\n    wortleSitEveryoneDown(roomId: $roomId) {\n      ok\n    }\n  }\n": types.WortleSitEveryoneDownDocument,
  "\n  mutation StartWortle($roomId: String!, $collaborate: Boolean) {\n    wortleStartGame(roomId: $roomId, collaborate: $collaborate) {\n      ok\n    }\n  }\n": types.StartWortleDocument,
  "\n  mutation WordleGuess($roomId: String!, $word: String!) {\n    wortleGuess(roomId: $roomId, word: $word) {\n      ok\n    }\n  }\n": types.WordleGuessDocument,
  "\n  mutation WordleAddLetter($roomId: String!, $letter: String!) {\n    wortleAddLetter(roomId: $roomId, letter: $letter) {\n      ok\n    }\n  }\n": types.WordleAddLetterDocument,
  "\n  mutation WordleBackspace($roomId: String!) {\n    wortleBackspace(roomId: $roomId) {\n      ok\n    }\n  }\n": types.WordleBackspaceDocument,
  "\n  mutation WordleGiveUp($roomId: String!) {\n    wortleGiveUp(roomId: $roomId) {\n      ok\n    }\n  }\n": types.WordleGiveUpDocument,
  "\n  subscription SubscriptionUpdateSubscription {\n    stripeSubscriptionUpdate {\n      subscription {\n        id\n        cancelAtPeriodEnd\n        periodEnds\n        status\n      }\n      user {\n        id\n        isSubscribed\n      }\n    }\n  }\n": types.SubscriptionUpdateSubscriptionDocument,
  "\n  subscription OnFriendListUpdate {\n    friendlistUpdate {\n      id\n      friends {\n        state\n        user {\n          id\n          displayName\n          username\n          avatarUrl\n          email\n          isConnected\n        }\n      }\n    }\n  }\n": types.OnFriendListUpdateDocument,
  "\n  query NotificationQuery1 {\n    currentUser {\n      id\n      connectionId\n      user {\n        id\n        notifications {\n          id\n          title\n          text\n          type\n          timestamp\n          seen\n          autoOpen\n          sender {\n            id\n            username\n            displayName\n            avatarUrl\n          }\n        }\n      }\n    }\n  }\n": types.NotificationQuery1Document,
  "\n  subscription NewNotificationSubscription {\n    newNotification {\n      id\n      title\n      text\n      type\n      timestamp\n      seen\n      autoOpen\n      sender {\n        id\n        username\n        displayName\n        avatarUrl\n      }\n    }\n  }\n": types.NewNotificationSubscriptionDocument,
  "\n  subscription NewPublicNotificationSubscription {\n    newPublicNotification {\n      id\n      title\n      text\n      type\n      timestamp\n      seen\n      autoOpen\n      sender {\n        id\n        username\n        displayName\n        avatarUrl\n      }\n    }\n  }\n": types.NewPublicNotificationSubscriptionDocument,
  "\n  subscription SettingsSubscription {\n    settingsUpdate {\n      id\n      colorScheme\n    }\n  }\n": types.SettingsSubscriptionDocument,
  "\n  query CurrentUserQuery4 {\n    featureFlags {\n      requireLoginToCreateRoom\n    }\n    getServerTime {\n      time\n    }\n    currentUser {\n      id\n      connectionId\n      user {\n        id\n        displayName\n        username\n        isAnonymous\n        isSubscribed\n        avatarUrl\n        email\n        emailConfirmed\n        privateApps\n        countryCode\n        friends {\n          state\n          user {\n            id\n            displayName\n            username\n            avatarUrl\n            email\n            isConnected\n          }\n        }\n      }\n    }\n  }\n": types.CurrentUserQuery4Document,
  "\n  mutation CreateRoomMutation1(\n    $appName: RoomApp\n    $description: String\n    $public: Boolean!\n  ) {\n    createRoom(\n      appName: $appName\n      description: $description\n      public: $public\n      promoteNewUsersToAdmin: true\n    ) {\n      id\n    }\n  }\n": types.CreateRoomMutation1Document,
  "\n  query RoomsQuery($minUsers: Int!) {\n    rooms(minUsers: $minUsers) {\n      id\n      state {\n        memberCount\n        runningApp\n      }\n    }\n  }\n": types.RoomsQueryDocument,
  "\n  query GetUserByInviteCode($inviteCode: String!) {\n    userByInviteCode(inviteCode: $inviteCode) {\n      id\n      username\n      displayName\n      avatarUrl\n    }\n  }\n": types.GetUserByInviteCodeDocument,
  "\n  mutation StopApp($roomId: String!) {\n    stopApp(roomId: $roomId) {\n      ok\n    }\n  }\n": types.StopAppDocument,
  "\n  query VirtualBrowserQuery($roomId: String!, $sessionId: String!) {\n    virtualBrowser(roomId: $roomId, sessionId: $sessionId) {\n      id\n      embedUrl\n    }\n  }\n": types.VirtualBrowserQueryDocument,
  "\n  mutation BrowserCallback(\n    $sessionId: String!\n    $roomId: String!\n    $frameUserId: String!\n  ) {\n    virtualBrowserCallback(\n      sessionId: $sessionId\n      roomId: $roomId\n      frameUserId: $frameUserId\n    ) {\n      ok\n    }\n  }\n": types.BrowserCallbackDocument,
  "\n  query CurrentUserQuery2($roomId: String!) {\n    currentUser {\n      id\n      connectionId\n      user {\n        id\n        displayName\n        username\n        isAnonymous\n      }\n    }\n    room(id: $roomId) {\n      id\n      state {\n        members {\n          id\n          role\n          user {\n            id\n            username\n            displayName\n            avatarUrl\n          }\n        }\n      }\n    }\n  }\n": types.CurrentUserQuery2Document,
  "\n  mutation InviteMembersBackToRoom(\n    $roomId: String!\n    $message: String!\n    $userIds: [String]!\n  ) {\n    inviteMembersBackToRoom(\n      roomId: $roomId\n      userIds: $userIds\n      message: $message\n    ) {\n      ok\n    }\n  }\n": types.InviteMembersBackToRoomDocument,
  "\n  mutation InviteAllMembersBackToRoom($roomId: String!, $message: String!) {\n    inviteAllMembersBackToRoom(message: $message, roomId: $roomId) {\n      ok\n    }\n  }\n": types.InviteAllMembersBackToRoomDocument,
  "\n  query MediaContentHeadings2 {\n    mediaContentHeadings {\n      name\n      categories\n    }\n  }\n": types.MediaContentHeadings2Document,
  '\n  query MediaContentSearch(\n    $query: String!\n    $categories: [MediaCategories]!\n    $types: [MediaType]!\n  ) {\n    mediaContentSearchCategories(categories: $categories, query: $query)\n    mediaContentSearch(query: $query, categories: $categories, types: $types) {\n      id\n      premium\n      thumbnail\n      title\n      type\n      url\n      logo\n    }\n    __type(name: "MediaType") {\n      enumValues {\n        name\n        description\n      }\n    }\n  }\n': types.MediaContentSearchDocument,
  "\n  mutation StartApp2($roomId: String!, $appName: RoomApp!) {\n    startApp(roomId: $roomId, appName: $appName) {\n      ok\n    }\n  }\n": types.StartApp2Document,
  "\n  mutation OpenLinkInWebBrowser(\n    $roomId: String!\n    $url: String!\n    $kiosk: Boolean!\n    $extension: String\n  ) {\n    openLinkInWebBrowser(\n      roomId: $roomId\n      url: $url\n      kiosk: $kiosk\n      extension: $extension\n    ) {\n      ok\n    }\n  }\n": types.OpenLinkInWebBrowserDocument,
  "\n  mutation OpenLinkInWebview2($roomId: String!, $url: String!) {\n    openLinkInWebview(roomId: $roomId, url: $url) {\n      ok\n    }\n  }\n": types.OpenLinkInWebview2Document,
  '\n  query MediaContentHeadings {\n    mediaContentHeadings {\n      name\n      categories\n      mediaTypes\n    }\n    __type(name: "MediaType") {\n      enumValues {\n        name\n        description\n      }\n    }\n  }\n': types.MediaContentHeadingsDocument,
  "\n  query GetPlaylistInfo($url: String!) {\n    mediaPlayerGetPlaylistInfo(url: $url) {\n      status\n      result {\n        totalDuration\n        items {\n          title\n          url\n          duration\n        }\n      }\n    }\n    getServerTime {\n      time\n    }\n  }\n": types.GetPlaylistInfoDocument,
  "\n  query GetProxiedStream($url: String!) {\n    mediaPlayerGetProxiedStream(url: $url) {\n      status\n      result {\n        url\n      }\n    }\n    getServerTime {\n      time\n    }\n  }\n": types.GetProxiedStreamDocument,
  "\n  mutation MediaPlayerSetPlaybackRate($roomId: String!, $playbackRate: Float!) {\n    mediaPlayerSetPlaybackRate(roomId: $roomId, playbackRate: $playbackRate) {\n      ok\n    }\n  }\n": types.MediaPlayerSetPlaybackRateDocument,
  "\n  mutation MediaPlayerStopVideo($roomId: String!) {\n    mediaPlayerStopVideo(roomId: $roomId) {\n      ok\n    }\n  }\n": types.MediaPlayerStopVideoDocument,
  "\n  mutation SetMediaPlayerUrlMutation(\n    $roomId: String!\n    $url: String!\n    $type: String\n  ) {\n    mediaPlayerSetUrl(roomId: $roomId, url: $url, type: $type) {\n      ok\n    }\n  }\n": types.SetMediaPlayerUrlMutationDocument,
  "\n  mutation SetMediaPlayerBrowserUrlMutation($roomId: String!, $url: String!) {\n    mediaPlayerSetVbrowserUrl(roomId: $roomId, url: $url) {\n      ok\n    }\n  }\n": types.SetMediaPlayerBrowserUrlMutationDocument,
  "\n  mutation MediaPlayerSetPaused($roomId: String!, $paused: Boolean!) {\n    mediaPlayerSetPaused(roomId: $roomId, paused: $paused) {\n      ok\n    }\n  }\n": types.MediaPlayerSetPausedDocument,
  "\n  mutation mediaPlayerSetSubtitles(\n    $roomId: String!\n    $filename: String!\n    $cues: [SubtitleCue]!\n  ) {\n    mediaPlayerSetSubtitles(roomId: $roomId, filename: $filename, cues: $cues) {\n      ok\n    }\n  }\n": types.MediaPlayerSetSubtitlesDocument,
  "\n  mutation MediaPlayerRemoveSubtitles($roomId: String!) {\n    mediaPlayerRemoveSubtitles(roomId: $roomId) {\n      ok\n    }\n  }\n": types.MediaPlayerRemoveSubtitlesDocument,
  "\n  mutation MediaPlayerSetPosition3(\n    $roomId: String!\n    $position: Float!\n    $unixTimePositionSent: Int!\n  ) {\n    mediaPlayerSetPosition(\n      roomId: $roomId\n      position: $position\n      unixTimePositionSent: $unixTimePositionSent\n    ) {\n      ok\n    }\n  }\n": types.MediaPlayerSetPosition3Document,
  "\n  mutation InviteToRoomMutation1($userId: String!, $roomId: String!) {\n    inviteToRoom(userId: $userId, roomId: $roomId) {\n      ok\n    }\n  }\n": types.InviteToRoomMutation1Document,
  "\n  mutation GetEmbeddableLinkMetadata($url: String!) {\n    getEmbeddableLinkMetadata(url: $url) {\n      name\n      hostname\n      type\n    }\n  }\n": types.GetEmbeddableLinkMetadataDocument,
  "\n  mutation OpenLinkInWebview1($roomId: String!, $url: String!) {\n    openLinkInWebview(roomId: $roomId, url: $url) {\n      ok\n    }\n  }\n": types.OpenLinkInWebview1Document,
  "\n  query ToolbarMetadataQuery($roomId: String!) {\n    room(id: $roomId) {\n      id\n      state {\n        metadata {\n          requireAdminForWebcamAndMicrophone\n          disableWebcamAndMicrophone\n        }\n      }\n    }\n  }\n": types.ToolbarMetadataQueryDocument,
  "\n  mutation UnbanUser1($roomId: String!, $id: String!) {\n    unbanIpFromRoom(roomId: $roomId, id: $id) {\n      ok\n    }\n  }\n": types.UnbanUser1Document,
  "\n  subscription IPBanListUpdate($roomId: String!) {\n    ipBanlistUpdate(roomId: $roomId) {\n      id\n      timestamp\n    }\n  }\n": types.IpBanListUpdateDocument,
  "\n  query GetBannedIPs($roomId: String!) {\n    ipBans(roomId: $roomId) {\n      id\n      timestamp\n    }\n  }\n": types.GetBannedIPsDocument,
  "\n  mutation UnbanUser2($roomId: String!, $userId: String!) {\n    unbanUserFromRoom(roomId: $roomId, userId: $userId) {\n      ok\n    }\n  }\n": types.UnbanUser2Document,
  "\n  subscription BanListUpdate($roomId: String!) {\n    userBanlistUpdate(roomId: $roomId) {\n      user {\n        id\n        username\n        avatarUrl\n        displayName\n      }\n      timestamp\n    }\n  }\n": types.BanListUpdateDocument,
  "\n  query GetBannedUsers($roomId: String!) {\n    userBans(roomId: $roomId) {\n      user {\n        id\n        username\n        avatarUrl\n        displayName\n      }\n    }\n  }\n": types.GetBannedUsersDocument,
  "\n  query MetaDataQuery($roomId: String!) {\n    room(id: $roomId) {\n      id\n      state {\n        metadata {\n          avatarUrl\n          roomName\n          public\n          requireAdminForWebcamAndMicrophone\n          relayWebcamAndMicrophone\n          relayServerRegion\n          description\n          autoAcceptJoins\n          promoteNewUsersToAdmin\n          requireLogin\n          disableAutoplay\n          disableWebcamAndMicrophone\n          hideSidebarForNonAdmins\n        }\n      }\n    }\n  }\n": types.MetaDataQueryDocument,
  "\n  mutation UpdateMetadata(\n    $roomId: String!\n    $autoAcceptJoins: Boolean\n    $avatarFilename: String\n    $description: String\n    $disableAutoplay: Boolean\n    $disableWebcamAndMicrophone: Boolean\n    $hideSidebarForNonAdmins: Boolean\n    $promoteNewUsersToAdmin: Boolean\n    $public: Boolean\n    $relayServerRegion: String\n    $relayWebcamAndMicrophone: Boolean\n    $requireAdminForWebcamAndMicrophone: Boolean\n    $requireLogin: Boolean\n    $roomName: String\n  ) {\n    updateMetadata(\n      roomId: $roomId\n      autoAcceptJoins: $autoAcceptJoins\n      avatarFilename: $avatarFilename\n      description: $description\n      disableAutoplay: $disableAutoplay\n      disableWebcamAndMicrophone: $disableWebcamAndMicrophone\n      hideSidebarForNonAdmins: $hideSidebarForNonAdmins\n      promoteNewUsersToAdmin: $promoteNewUsersToAdmin\n      public: $public\n      relayServerRegion: $relayServerRegion\n      relayWebcamAndMicrophone: $relayWebcamAndMicrophone\n      requireAdminForWebcamAndMicrophone: $requireAdminForWebcamAndMicrophone\n      requireLogin: $requireLogin\n      roomName: $roomName\n    ) {\n      ok\n    }\n  }\n": types.UpdateMetadataDocument,
  "\n  mutation ClearChat($roomId: String!) {\n    clearChat(roomId: $roomId) {\n      ok\n    }\n  }\n": types.ClearChatDocument,
  "\n  mutation DestroyRoom($roomId: String!) {\n    destroyRoom(roomId: $roomId) {\n      ok\n    }\n  }\n": types.DestroyRoomDocument,
  "\n  mutation SetTemporaryDisplayName($displayName: String!) {\n    setTemporaryDisplayName(displayName: $displayName) {\n      id\n      displayName\n    }\n  }\n": types.SetTemporaryDisplayNameDocument,
  "\n  mutation InviteToRoomMutation2($userId: String!, $roomId: String!) {\n    inviteToRoom(userId: $userId, roomId: $roomId) {\n      ok\n    }\n  }\n": types.InviteToRoomMutation2Document,
  "\n  mutation RemoveFriendMutation($userId: String!) {\n    removeFriend(userId: $userId) {\n      id\n      friends {\n        state\n        user {\n          id\n          isSubscribed\n          displayName\n          username\n          avatarUrl\n          email\n          isConnected\n        }\n      }\n    }\n  }\n": types.RemoveFriendMutationDocument,
  "\n  query UserQuery($id: String!) {\n    user(id: $id) {\n      id\n      username\n      displayName\n      avatarUrl\n      isConnected\n      isSubscribed\n    }\n  }\n": types.UserQueryDocument,
  "\n  query UserSearchQuery($query: String!) {\n    searchUsers(query: $query) {\n      id\n      username\n      displayName\n      avatarUrl\n    }\n  }\n": types.UserSearchQueryDocument,
  "\n  query UserRoomsQuery {\n    userRooms {\n      id\n      state {\n        metadata {\n          roomName\n          avatarUrl\n        }\n      }\n    }\n  }\n": types.UserRoomsQueryDocument,
  "\n  mutation AddFriendMutation1($username: String!) {\n    addFriend(username: $username) {\n      id\n      friends {\n        state\n        user {\n          id\n          displayName\n          username\n          avatarUrl\n          email\n          isConnected\n        }\n      }\n    }\n  }\n": types.AddFriendMutation1Document,
  "\n  mutation SendSupportEmail($body: String!) {\n    sendSupportEmail(body: $body) {\n      ok\n    }\n  }\n": types.SendSupportEmailDocument,
  "\n  subscription OnNewPrivateMessage {\n    newPrivateMessage {\n      message {\n        id\n        time\n        body\n        user {\n          id\n          displayName\n          username\n          avatarUrl\n          email\n          isConnected\n          isSubscribed\n        }\n      }\n      receivingUser {\n        id\n        displayName\n        username\n        avatarUrl\n        email\n        isConnected\n      }\n      seen\n    }\n  }\n": types.OnNewPrivateMessageDocument,
  "\n  subscription NewPrivateMessageSubscription($userId: String!) {\n    newPrivateMessageFromUser(userId: $userId) {\n      seen\n      receivingUser {\n        id\n        displayName\n        username\n        avatarUrl\n        email\n        isConnected\n      }\n      message {\n        id\n        time\n        body\n        user {\n          id\n          displayName\n          username\n          avatarUrl\n          email\n          isConnected\n          isSubscribed\n        }\n      }\n    }\n  }\n": types.NewPrivateMessageSubscriptionDocument,
  "\n  subscription PrivateMessageDeletedSubsciption {\n    privateMessageDeleted {\n      previousMessage {\n        latestMessage {\n          id\n          time\n          body\n        }\n        user {\n          id\n          displayName\n          username\n          avatarUrl\n          email\n          isConnected\n        }\n      }\n      deletedMessageId\n    }\n  }\n": types.PrivateMessageDeletedSubsciptionDocument,
  "\n  mutation RemoveFriendMutation2($userId: String!) {\n    removeFriend(userId: $userId) {\n      id\n      friends {\n        state\n        user {\n          id\n          displayName\n          username\n          avatarUrl\n          email\n          isConnected\n        }\n      }\n    }\n  }\n": types.RemoveFriendMutation2Document,
  "\n  query MessageList {\n    messageList {\n      id\n      unreadCount\n      latestMessage {\n        id\n        time\n        body\n      }\n      user {\n        id\n        displayName\n        username\n        avatarUrl\n        email\n        isConnected\n        isSubscribed\n      }\n    }\n  }\n": types.MessageListDocument,
  "\n  subscription MessageListUpdate {\n    messageListUpdate {\n      id\n      unreadCount\n      latestMessage {\n        id\n        time\n        body\n      }\n      user {\n        id\n        displayName\n        username\n        avatarUrl\n        email\n        isConnected\n      }\n    }\n  }\n": types.MessageListUpdateDocument,
  "\n  query PrivateMessageQuery($userId: String!, $cursor: String) {\n    privateMessages(userId: $userId, cursor: $cursor) {\n      forwardCursor\n      backCursor\n      results {\n        id\n        body\n        time\n        user {\n          id\n          displayName\n          username\n          avatarUrl\n          email\n          isConnected\n          isSubscribed\n        }\n      }\n    }\n  }\n": types.PrivateMessageQueryDocument,
  "\n  mutation SendPrivateMessage($body: String!, $userId: String!) {\n    sendPrivateMessage(body: $body, toUserId: $userId) {\n      ok\n    }\n  }\n": types.SendPrivateMessageDocument,
  "\n  mutation DetelePrivateMessage($privateMessageId: UUID!) {\n    deletePrivateMessage(privateMessageId: $privateMessageId) {\n      ok\n    }\n  }\n": types.DetelePrivateMessageDocument,
  "\n  query NotificationQuery2 {\n    currentUser {\n      id\n      connectionId\n      user {\n        id\n        username\n        isAnonymous\n        notifications {\n          id\n          title\n          text\n          type\n          timestamp\n          seen\n          autoOpen\n          sender {\n            id\n            username\n            displayName\n            avatarUrl\n          }\n        }\n      }\n    }\n  }\n": types.NotificationQuery2Document,
  "\n  query RoomActionQuery($roomId: String!) {\n    room(id: $roomId) {\n      id\n      state {\n        metadata {\n          roomName\n          avatarUrl\n        }\n      }\n    }\n  }\n": types.RoomActionQueryDocument,
  "\n  mutation MarkAllNotificationsAsSeen {\n    markAllNotificationsAsSeen {\n      id\n      seen\n    }\n  }\n": types.MarkAllNotificationsAsSeenDocument,
  "\n  subscription OnRoomListUpdate {\n    roomlistUpdate {\n      id\n      unreadMessages\n      latestMessage {\n        id\n        body\n        time\n        user {\n          displayName\n        }\n      }\n      state {\n        metadata {\n          roomName\n          avatarUrl\n        }\n        isLive\n        members {\n          id\n          role\n          user {\n            id\n            username\n            displayName\n            avatarUrl\n            friends {\n              state\n              user {\n                id\n                displayName\n                username\n                avatarUrl\n                email\n                isConnected\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.OnRoomListUpdateDocument,
  "\n  query UserRoomQuery {\n    userRooms {\n      id\n      unreadMessages\n      latestMessage {\n        id\n        time\n        body\n        user {\n          displayName\n        }\n      }\n      state {\n        metadata {\n          roomName\n          avatarUrl\n        }\n        isLive\n        members {\n          id\n          role\n          user {\n            id\n            username\n            displayName\n            avatarUrl\n            friends {\n              state\n              user {\n                id\n                displayName\n                username\n                avatarUrl\n                email\n                isConnected\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.UserRoomQueryDocument,
  "\n  mutation UnlinkRoom2($roomId: String!) {\n    unlinkRoom(roomId: $roomId) {\n      ok\n    }\n  }\n": types.UnlinkRoom2Document,
  "\n  mutation SendAnnouncement($title: String!, $text: String!) {\n    sendAnnouncement(title: $title, text: $text) {\n      ok\n    }\n  }\n": types.SendAnnouncementDocument,
  "\n  mutation DeleteMessage($roomId: String!, $messageId: String!) {\n    deleteMessage(roomId: $roomId, messageId: $messageId) {\n      ok\n    }\n  }\n": types.DeleteMessageDocument,
  "\n  mutation DeleteMessagesByUser($roomId: String!, $userId: String!) {\n    deleteAllMessagesByUser(roomId: $roomId, userId: $userId) {\n      ok\n    }\n  }\n": types.DeleteMessagesByUserDocument,
  "\n  mutation SendMessage2($roomId: String!, $body: String!, $channelId: String) {\n    sendMessage(roomId: $roomId, body: $body, channelId: $channelId) {\n      ok\n    }\n  }\n": types.SendMessage2Document,
  "\n  mutation JoinRoom($id: String!, $disconnectOtherConnections: Boolean) {\n    joinRoom(id: $id, disconnectOtherConnections: $disconnectOtherConnections) {\n      ok\n    }\n  }\n": types.JoinRoomDocument,
  "\n  mutation LeaveRoom($id: String!) {\n    leaveRoom(id: $id) {\n      ok\n    }\n  }\n": types.LeaveRoomDocument,
  "\n  subscription RoomDisconnect($roomId: String!) {\n    roomDisconnect(id: $roomId) {\n      ok\n    }\n  }\n": types.RoomDisconnectDocument,
  "\n  query mediaPlayerStateQuery1($roomId: String!) {\n    mediaPlayerState(roomId: $roomId) {\n    id\n    type\n    position\n    url\n    paused\n    live\n    unixTimePositionSent\n    playbackRate\n    hostUser {\n      id\n      isAnonymous\n      isConnected\n      username\n      avatarUrl\n      countryCode\n      displayName\n      email\n      emailConfirmed\n    }\n    }\n  }\n": types.MediaPlayerStateQuery1Document,
  "\n  subscription OnMediaPlayerUpdateState($roomId: String!) {\n    mediaPlayerUpdateState(roomId: $roomId) {\n    id\n    type\n    position\n    url\n    paused\n    live\n    unixTimePositionSent\n    playbackRate\n    hostUser {\n      id\n      isAnonymous\n      isConnected\n      username\n      avatarUrl\n      countryCode\n      displayName\n      email\n      emailConfirmed\n    }\n    }\n  }\n": types.OnMediaPlayerUpdateStateDocument,
  "\n  query MediaPlayerSubtitlesQuery($roomId: String!) {\n    mediaPlayerSubtitles(roomId: $roomId) {\n      id\n      cues {\n        end\n        start\n        text\n      }\n      filename\n    }\n  }\n": types.MediaPlayerSubtitlesQueryDocument,
  "\n  subscription OnMediaPlayerUpdateSubtitles($roomId: String!) {\n    mediaPlayerUpdateSubtitles(roomId: $roomId) {\n      id\n      cues {\n        end\n        start\n        text\n      }\n      filename\n    }\n  }\n": types.OnMediaPlayerUpdateSubtitlesDocument,
  "\n  query LinkedMembers($roomId: String!) {\n    linkedMembers(roomId: $roomId) {\n      id\n      memberCount\n      members {\n        id\n        role\n        lastVisited\n        user {\n          id\n          displayName\n          email\n          emailConfirmed\n          avatarUrl\n          isSubscribed\n          isAnonymous\n        }\n      }\n    }\n  }\n": types.LinkedMembersDocument,
  "\n  subscription MemberLeavesABC($id: String!) {\n    memberLeaves(id: $id) {\nid\nrole\nuser {\n      id\n      username\n      displayName\n      avatarUrl\n      countryCode\n      isAnonymous\n      isSubscribed\n      email\n      emailConfirmed\n    }\n    }\n  }\n": types.MemberLeavesAbcDocument,
  "\n  subscription NewMemberSubscription($roomId: String!) {\n    newMember(roomId: $roomId) {\n      id\n    }\n  }\n": types.NewMemberSubscriptionDocument,
  "\n  subscription MemberUnlinksSubscription($roomId: String!) {\n    memberUnlinks(roomId: $roomId) {\n      id\n    }\n  }\n": types.MemberUnlinksSubscriptionDocument,
  "\n  subscription SetRole2($id: String!) {\n    setRole(id: $id) {\nid\nrole\nuser {\n      id\n      username\n      displayName\n      avatarUrl\n      countryCode\n      isAnonymous\n      isSubscribed\n    }\n    }\n  }\n": types.SetRole2Document,
  "\n  query RoomChatQuery($roomId: String!, $channelId: String!, $cursor: String) {\n    chatArchive(roomId: $roomId, channelId: $channelId, cursor: $cursor) {\n      forwardCursor\n      backCursor\n      results {\n        id\n        user {\n          id\n          isSubscribed\n          username\n          displayName\n          avatarUrl\n        }\n        member {\n          id\n          role\n        }\n        body\n        time\n      }\n    }\n    room(id: $roomId) {\n      id\n      state {\n        members {\n          id\n        }\n      }\n    }\n  }\n": types.RoomChatQueryDocument,
  "\n  subscription NewMessageSubscription($roomId: String!, $channelId: String) {\n    newMessage(roomId: $roomId, channelId: $channelId) {\n      id\n      user {\n        id\n        isSubscribed\n        username\n        displayName\n        avatarUrl\n      }\n      member {\n        id\n        role\n      }\n      body\n      time\n    }\n  }\n": types.NewMessageSubscriptionDocument,
  "\n  subscription MessageDeletedSubscription($roomId: String!) {\n    messageDeleted(roomId: $roomId) {\n      messageId\n    }\n  }\n": types.MessageDeletedSubscriptionDocument,
  '\n  subscription ClearMessagesSubscription($roomId: String!) {\n    clearMessages(roomId: $roomId, channelId: "general") {\n      ok\n    }\n  }\n': types.ClearMessagesSubscriptionDocument,
  "\n  query RoomRootQuery($roomId: String!) {\n    room(id: $roomId) {\n      id\n      state {\n        runningApp\n        webrtcEnabled\n        members {\n    id\n    role\n    user {\n      id\n      username\n      displayName\n      avatarUrl\n      countryCode\n      isAnonymous\n      isSubscribed\n    }\n        }\n        metadata {\n    roomName\n    avatarUrl\n    public\n    description\n    autoAcceptJoins\n    requireAdminForWebcamAndMicrophone\n    relayServerRegion\n    promoteNewUsersToAdmin\n    disableWebcamAndMicrophone\n    hideSidebarForNonAdmins\n    requireLogin\n    isNsfw\n    disableAutoplay\n    iptvUrl\n        }\n      }\n    }\n    spacesState(roomId: $roomId) {\n      id\n      data\n    }\n    mediaPlayerState(roomId:$roomId) {\n      id\n      type\n      position\n      url\n      paused\n      live\n      unixTimePositionSent\n      playbackRate\n      hostUser {\n        id\n        isAnonymous\n        isConnected\n        username\n        avatarUrl\n        countryCode\n        displayName\n        email\n        emailConfirmed\n      }\n    }\n  }\n": types.RoomRootQueryDocument,
  "\n  subscription StartAppSubscription($roomId: String!) {\n    startApp(roomId: $roomId) {\n      runningApp\n    }\n  }\n": types.StartAppSubscriptionDocument,
  "\n  subscription MemberLeaves($id: String!) {\n    memberLeaves(id: $id) {\nid\nrole\nuser {\n      id\n      username\n      displayName\n      avatarUrl\n      countryCode\n      isAnonymous\n      isSubscribed\nemail\nemailConfirmed\n    }\n}\n  }\n": types.MemberLeavesDocument,
  "\n  subscription MemberJoins($id: String!) {\n    memberJoins(id: $id) {\nid\nrole\nuser {\n      id\n      username\n      displayName\n      avatarUrl\n      countryCode\n      isAnonymous\n      isSubscribed\n    }\n}\n  }\n": types.MemberJoinsDocument,
  "\n  subscription SetRole($id: String!) {\n    setRole(id: $id) {\nid\nrole\nuser {\n      id\n      username\n      displayName\n      avatarUrl\n      countryCode\n      isAnonymous\n      isSubscribed\n    }\n}\n  }\n": types.SetRoleDocument,
  "\n  subscription MetadataUpdates($id: String!) {\n    metadataUpdate(id: $id) {\n    roomName\n    avatarUrl\n    public\n    description\n    autoAcceptJoins\n    requireAdminForWebcamAndMicrophone\n    relayServerRegion\n    promoteNewUsersToAdmin\n    disableWebcamAndMicrophone\n    hideSidebarForNonAdmins\n    requireLogin\n    isNsfw\n    disableAutoplay\n    iptvUrl\n    }\n  }\n": types.MetadataUpdatesDocument,
  "\n  subscription OnSpacesStateUpdate($roomId: String!) {\n    spacesUpdate(roomId: $roomId) {\n      id\n      data\n    }\n  }\n": types.OnSpacesStateUpdateDocument,
  "\n  query MediasoupGetRouterRtpCapabilities($key: String!, $roomId: String!) {\n    mediasoupGetRouterRtpCapabilities(roomId: $roomId, key: $key) {\n      ok\n      mediasoupResponse\n    }\n  }\n": types.MediasoupGetRouterRtpCapabilitiesDocument,
  "\n  mutation MediasoupCreateProducerTransport($roomId: String!, $key: String!) {\n    mediasoupCreateProducerTransport(roomId: $roomId, key: $key) {\n      ok\n      mediasoupResponse\n    }\n  }\n": types.MediasoupCreateProducerTransportDocument,
  "\n  mutation MediasoupConnectProducerTransport(\n    $roomId: String!\n    $key: String!\n    $dtlsParameters: String!\n  ) {\n    mediasoupConnectProducerTransport(\n      roomId: $roomId\n      key: $key\n      dtlsParameters: $dtlsParameters\n    ) {\n      ok\n      mediasoupResponse\n    }\n  }\n": types.MediasoupConnectProducerTransportDocument,
  "\n  mutation MediaSoupProduce(\n    $roomId: String!\n    $key: String!\n    $kind: String!\n    $rtpParameters: String!\n  ) {\n    mediasoupProduce(\n      roomId: $roomId\n      key: $key\n      kind: $kind\n      rtpParameters: $rtpParameters\n    ) {\n      ok\n      mediasoupResponse\n    }\n  }\n": types.MediaSoupProduceDocument,
  "\n  mutation MediasoupConsume(\n    $roomId: String!\n    $key: String!\n    $kind: String!\n    $targetUserId: String!\n    $rtpCapabilities: String!\n  ) {\n    mediasoupConsume(\n      roomId: $roomId\n      key: $key\n      kind: $kind\n      targetUserId: $targetUserId\n      rtpCapabilities: $rtpCapabilities\n    ) {\n      ok\n      mediasoupResponse\n    }\n  }\n": types.MediasoupConsumeDocument,
  "\n  mutation MediasoupCreateConsumerTransport(\n    $roomId: String!\n    $key: String!\n    $targetUserId: String!\n  ) {\n    mediasoupCreateConsumerTransport(\n      roomId: $roomId\n      key: $key\n      targetUserId: $targetUserId\n    ) {\n      ok\n      mediasoupResponse\n    }\n  }\n": types.MediasoupCreateConsumerTransportDocument,
  "\n  mutation MediasoupConnectConsumerTransport(\n    $roomId: String!\n    $key: String!\n    $targetUserId: String!\n    $dtlsParameters: String!\n  ) {\n    mediasoupConnectConsumerTransport(\n      roomId: $roomId\n      key: $key\n      targetUserId: $targetUserId\n      dtlsParameters: $dtlsParameters\n    ) {\n      ok\n      mediasoupResponse\n    }\n  }\n": types.MediasoupConnectConsumerTransportDocument,
  "\n  mutation MediasoupCloseProducerTransport($roomId: String!, $key: String!) {\n    mediasoupCloseProducerTransport(key: $key, roomId: $roomId) {\n      ok\n    }\n  }\n": types.MediasoupCloseProducerTransportDocument,
  "\n  mutation MediasoupCloseConsumerTransport(\n    $roomId: String!\n    $targetUserId: String!\n    $key: String!\n  ) {\n    mediasoupCloseConsumerTransport(\n      targetUserId: $targetUserId\n      key: $key\n      roomId: $roomId\n    ) {\n      ok\n    }\n  }\n": types.MediasoupCloseConsumerTransportDocument,
  "\n  mutation MediasoupProducerHeartbeat($roomId: String!, $key: String!) {\n    mediasoupProducerHeartbeat(key: $key, roomId: $roomId) {\n      ok\n    }\n  }\n": types.MediasoupProducerHeartbeatDocument,
  "\n  mutation MediasoupConsumerHeartbeat(\n    $roomId: String!\n    $key: String!\n    $targetUserId: String!\n  ) {\n    mediasoupConsumerHeartbeat(\n      key: $key\n      roomId: $roomId\n      targetUserId: $targetUserId\n    ) {\n      ok\n    }\n  }\n": types.MediasoupConsumerHeartbeatDocument,
  "\n  query MediaSoupStateQuery($roomId: String!) {\n    mediasoupState(roomId: $roomId) {\n      conference {\n        userId\n        mediaSources {\n          id\n          type\n        }\n      }\n    }\n  }\n": types.MediaSoupStateQueryDocument,
  "\n  mutation MediaSoupConferenceEnableAudio(\n    $roomId: String!\n    $streamId: String!\n  ) {\n    mediasoupConferenceEnableAudio(roomId: $roomId, streamId: $streamId) {\n      ok\n    }\n  }\n": types.MediaSoupConferenceEnableAudioDocument,
  "\n  mutation MediaSoupConferenceEnableVideo(\n    $roomId: String!\n    $streamId: String!\n  ) {\n    mediasoupConferenceEnableVideo(roomId: $roomId, streamId: $streamId) {\n      ok\n    }\n  }\n": types.MediaSoupConferenceEnableVideoDocument,
  "\n  mutation MediaSoupConferenceDisableAudio($roomId: String!) {\n    mediasoupConferenceDisableAudio(roomId: $roomId) {\n      ok\n    }\n  }\n": types.MediaSoupConferenceDisableAudioDocument,
  "\n  mutation MediaSoupConferenceDisableVideo($roomId: String!) {\n    mediasoupConferenceDisableVideo(roomId: $roomId) {\n      ok\n    }\n  }\n": types.MediaSoupConferenceDisableVideoDocument,
  "\n  subscription OnMediaSoupUpdateState($roomId: String!) {\n    mediasoupUpdateState(roomId: $roomId) {\n      conference {\n        userId\n        mediaSources {\n          id\n          type\n        }\n      }\n    }\n  }\n": types.OnMediaSoupUpdateStateDocument,
  "\n  subscription WebRTCOnReceiveCandidate2(\n    $roomId: String!\n    $rtcChannelId: String!\n  ) {\n    webrtcReceiveCandidate(roomId: $roomId, rtcChannelId: $rtcChannelId) {\n      userId\n      candidate\n    }\n  }\n": types.WebRtcOnReceiveCandidate2Document,
  "\n  mutation WebRTCSendAnswer2(\n    $roomId: String!\n    $rtcChannelId: String!\n    $userId: String!\n    $localDescription: String!\n  ) {\n    webrtcSendAnswer(\n      roomId: $roomId\n      rtcChannelId: $rtcChannelId\n      userId: $userId\n      localDescription: $localDescription\n    ) {\n      ok\n    }\n  }\n": types.WebRtcSendAnswer2Document,
  "\n  mutation WebRTCSendCandidate(\n    $roomId: String!\n    $rtcChannelId: String!\n    $userId: String!\n    $candidate: String!\n  ) {\n    webrtcSendCandidate(\n      roomId: $roomId\n      rtcChannelId: $rtcChannelId\n      userId: $userId\n      candidate: $candidate\n    ) {\n      ok\n    }\n  }\n": types.WebRtcSendCandidateDocument,
  "\n  subscription WebRTCOnReceiveOffer2($roomId: String!, $rtcChannelId: String!) {\n    webrtcReceiveOffer(roomId: $roomId, rtcChannelId: $rtcChannelId) {\n      userId\n      remoteDescription\n    }\n  }\n": types.WebRtcOnReceiveOffer2Document,
  "\n  mutation WebRTCJoin2($roomId: String!, $rtcChannelId: String!) {\n    webrtcJoin(roomId: $roomId, rtcChannelId: $rtcChannelId) {\n      ok\n    }\n  }\n": types.WebRtcJoin2Document,
  "\n  mutation WebRTCLeave2($roomId: String!, $rtcChannelId: String!) {\n    webrtcLeave(roomId: $roomId, rtcChannelId: $rtcChannelId) {\n      ok\n    }\n  }\n": types.WebRtcLeave2Document,
  "\n  subscription WebRTCOnReceiveAnswer2(\n    $roomId: String!\n    $rtcChannelId: String!\n  ) {\n    webrtcReceiveAnswer(roomId: $roomId, rtcChannelId: $rtcChannelId) {\n      userId\n      remoteDescription\n    }\n  }\n": types.WebRtcOnReceiveAnswer2Document,
  "\n  subscription WebRTCOnReceiveCandidate3(\n    $roomId: String!\n    $rtcChannelId: String!\n  ) {\n    webrtcReceiveCandidate(roomId: $roomId, rtcChannelId: $rtcChannelId) {\n      userId\n      candidate\n    }\n  }\n": types.WebRtcOnReceiveCandidate3Document,
  "\n  mutation WebRTCSendCandidate2(\n    $roomId: String!\n    $rtcChannelId: String!\n    $userId: String!\n    $candidate: String!\n  ) {\n    webrtcSendCandidate(\n      roomId: $roomId\n      rtcChannelId: $rtcChannelId\n      userId: $userId\n      candidate: $candidate\n    ) {\n      ok\n    }\n  }\n": types.WebRtcSendCandidate2Document,
  "\n  mutation WebRTCSendOffer(\n    $roomId: String!\n    $rtcChannelId: String!\n    $userId: String!\n    $localDescription: String!\n  ) {\n    webrtcSendOffer(\n      roomId: $roomId\n      rtcChannelId: $rtcChannelId\n      userId: $userId\n      localDescription: $localDescription\n    ) {\n      ok\n    }\n  }\n": types.WebRtcSendOfferDocument,
  "\n  query RTCMembersQuery2($roomId: String!, $rtcChannelId: String!) {\n    webrtcMembers(roomId: $roomId, rtcChannelId: $rtcChannelId) {\n      id\n    }\n    currentUser {\n      id\n      user {\n        id\n      }\n    }\n  }\n": types.RtcMembersQuery2Document,
  "\n  subscription OnWebRTCJoin2($roomId: String!, $rtcChannelId: String!) {\n    webrtcJoinChannel(roomId: $roomId, rtcChannelId: $rtcChannelId) {\n      id\n    }\n  }\n": types.OnWebRtcJoin2Document,
  "\n  subscription OnWebRTCLeave2($roomId: String!, $rtcChannelId: String!) {\n    webrtcLeaveChannel(roomId: $roomId, rtcChannelId: $rtcChannelId) {\n      id\n    }\n  }\n": types.OnWebRtcLeave2Document,
  "\n  query WebRTCIceServerQuery {\n    webrtcIceServers {\n      urls\n      credential\n      username\n    }\n  }\n": types.WebRtcIceServerQueryDocument,
  "\n  query WithGetMembers($roomId: String!) {\n    room(id: $roomId) {\n      id\n      state {\n        runningApp\n        webrtcEnabled\n        members {\n          id\n          role\n          user {\n            id\n            username\n            displayName\n            avatarUrl\n            friends {\n              state\n              user {\n                id\n                displayName\n                username\n                avatarUrl\n                email\n                isConnected\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.WithGetMembersDocument,
  "\n  mutation VerifyEmailCode1($email: String!, $code: String!) {\n    verifyEmailCode(email: $email, code: $code) {\n      ok\n    }\n  }\n": types.VerifyEmailCode1Document,
  "\n  query UserRoomQueryWelcome {\n    userRooms {\n      id\n      unreadMessages\n      state {\n        metadata {\n          roomName\n          avatarUrl\n        }\n        isLive\n        members {\n          id\n          role\n          user {\n            id\n            username\n            displayName\n            avatarUrl\n            friends {\n              state\n              user {\n                id\n                displayName\n                username\n                avatarUrl\n                email\n                isConnected\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.UserRoomQueryWelcomeDocument,
  "\n  mutation CreateRoomMutation2(\n    $appName: RoomApp\n    $description: String\n    $roomName: String\n    $public: Boolean!\n    $autoAcceptJoins: Boolean\n    $promoteNewUsersToAdmin: Boolean\n    $colorScheme: String\n    $roomId: String\n  ) {\n    createRoom(\n      appName: $appName\n      roomName: $roomName\n      description: $description\n      public: $public\n      colorScheme: $colorScheme\n      autoAcceptJoins: $autoAcceptJoins\n      promoteNewUsersToAdmin: $promoteNewUsersToAdmin\n      roomId: $roomId\n    ) {\n      id\n    }\n  }\n": types.CreateRoomMutation2Document,
  "\n  mutation Login($username: String!, $password: String!) {\n    login(username: $username, password: $password) {\n      token\n    }\n  }\n": types.LoginDocument,
  "\n  mutation VerifyEmailCode2($email: String!, $code: String!) {\n    verifyEmailCode(email: $email, code: $code) {\n      ok\n    }\n  }\n": types.VerifyEmailCode2Document,
  "\n  mutation SendResetPasswordCode($email: String!) {\n    sendResetPasswordCode(email: $email) {\n      ok\n    }\n  }\n": types.SendResetPasswordCodeDocument,
  "\n  mutation ResetPassword($email: String!, $code: String!, $password: String!) {\n    resetPassword(email: $email, code: $code, password: $password) {\n      ok\n    }\n  }\n": types.ResetPasswordDocument,
  "\n  query PublicRoom($joinCode: String!) {\n    publicRoom(joinCode: $joinCode) {\n      appInfo\n      appName\n      avatarUrl\n      countryCode\n      description\n      joinCode\n      memberCount\n      roomName\n      autoAcceptJoins\n    }\n  }\n": types.PublicRoomDocument,
  "\n  mutation OktaLogin($token: String!, $userInfoUrl: String!) {\n    loginWithOkta(token: $token, userInfoUrl: $userInfoUrl) {\n      token\n    }\n  }\n": types.OktaLoginDocument,
  "\n  mutation GoogleLogin($token: String!, $inviteCode: String) {\n    loginWithGoogle(token: $token, inviteCode: $inviteCode) {\n      token\n    }\n  }\n": types.GoogleLoginDocument,
  "\n  mutation AppleLogin($token: String!, $native: Boolean!, $inviteCode: String) {\n    loginWithApple(token: $token, native: $native, inviteCode: $inviteCode) {\n      token\n    }\n  }\n": types.AppleLoginDocument,
  "\n  mutation AnonLogin1 {\n    anonLogin {\n      token\n    }\n  }\n": types.AnonLogin1Document,
  "\n  query NotificationQuery3 {\n    userRooms {\n      id\n      unreadMessages\n    }\n    currentUser {\n      id\n      user {\n        id\n        username\n        isAnonymous\n        notifications {\n          id\n          seen\n        }\n      }\n    }\n    messageList {\n      id\n      unreadCount\n    }\n  }\n": types.NotificationQuery3Document,
  "\n  mutation GetRoomId($url: String!)  {\n    getRoomId(url: $url) {\n      roomId\n    }\n  }\n": types.GetRoomIdDocument,
  "\n  mutation setColorScheme($colorScheme: String!) {\n    updateSettings(colorScheme: $colorScheme) {\n      ok\n    }\n  }\n": types.SetColorSchemeDocument,
  "\n  mutation UpdateProfileMutation22(\n    $displayName: String\n    $avatarFilename: String\n    $password: String\n    $email: String\n    $emailCode: String\n  ) {\n    updateProfile(\n      displayName: $displayName\n      avatarFilename: $avatarFilename\n      password: $password\n      email: $email\n      emailCode: $emailCode\n    ) {\n      id\n      username\n      displayName\n      avatarUrl\n      isAnonymous\n      email\n      emailConfirmed\n    }\n  }\n": types.UpdateProfileMutation22Document,
  "\n  mutation SendConfirmEmailCode {\n    sendConfirmEmailCode {\n      ok\n    }\n  }\n": types.SendConfirmEmailCodeDocument,
  "\n  mutation DeleteAllMyMessages {\n    deleteAllMessages {\n      ok\n    }\n  }\n": types.DeleteAllMyMessagesDocument,
  "\n  mutation DeleteAccount {\n    destroyAccount {\n      ok\n    }\n  }\n": types.DeleteAccountDocument,
  "\n  mutation CreateSubscription($paymentMethodId: String!) {\n    createStripeSubscription(paymentMethodId: $paymentMethodId) {\n      id\n      cancelAtPeriodEnd\n      periodEnds\n      status\n    }\n  }\n": types.CreateSubscriptionDocument,
  "\n  query GetSubscriptionProduct {\n    getStripeSubscriptionProduct {\n      stripePriceId\n      usdAmount\n      stripePublishableKey\n    }\n  }\n": types.GetSubscriptionProductDocument,
  "\n  query GetSubscription {\n    currentStripeSubscription {\n      id\n      cancelAtPeriodEnd\n      periodEnds\n      status\n    }\n    currentUser {\n      id\n      connectionId\n      user {\n        id\n        isSubscribed\n      }\n    }\n  }\n": types.GetSubscriptionDocument,
  "\n  mutation UpdatePaymentMethod($paymentMethodId: String!) {\n    updateStripePaymentMethod(paymentMethodId: $paymentMethodId) {\n      ok\n    }\n  }\n": types.UpdatePaymentMethodDocument,
  "\n  mutation ReactivateSubscription2 {\n    cancelStripeSubscription {\n      id\n      cancelAtPeriodEnd\n      periodEnds\n      status\n    }\n  }\n": types.ReactivateSubscription2Document,
  "\n  mutation ReactivateSubscription {\n    reactivateStripeSubscription {\n      id\n      cancelAtPeriodEnd\n      periodEnds\n      status\n    }\n  }\n": types.ReactivateSubscriptionDocument,
  "\n  query SignupInvites {\n    signupInvites {\n      confirmed\n      used\n      user {\n        id\n        displayName\n        avatarUrl\n      }\n    }\n    currentUser {\n      id\n      inviteCode\n      user {\n        id\n      }\n    }\n  }\n": types.SignupInvitesDocument,
  "\n  mutation Signup(\n    $username: String!\n    $password: String!\n    $email: String!\n    $birthday: Date!\n    $inviteCode: String\n  ) {\n    signup(\n      username: $username\n      password: $password\n      email: $email\n      birthday: $birthday\n      inviteCode: $inviteCode\n    ) {\n      token\n    }\n  }\n": types.SignupDocument,
  "\n  query MemberProfileQuery($roomId: String!, $userId: String!) {\n    member(userId: $userId, roomId: $roomId) {\n      id\n      role\n      user {\n        id\n        isSubscribed\n        username\n        displayName\n        avatarUrl\n        countryCode\n        isAnonymous\n      }\n    }\n  }\n": types.MemberProfileQueryDocument,
  '\n  mutation InviteBackToRoom($roomId: String!, $userId: String!) {\n    inviteMemberBackToRoom(userId: $userId, roomId: $roomId, message: "") {\n      ok\n    }\n  }\n': types.InviteBackToRoomDocument,
  "\n  mutation PromoteToAdminMutation($roomId: String!, $userId: String!) {\n    promoteToAdmin(userId: $userId, roomId: $roomId) {\n      ok\n    }\n  }\n": types.PromoteToAdminMutationDocument,
  "\n  mutation DemoteToUserMutation($roomId: String!, $userId: String!) {\n    demoteToUser(userId: $userId, roomId: $roomId) {\n      ok\n    }\n  }\n": types.DemoteToUserMutationDocument,
  "\n  mutation KickAndBanMutation($roomId: String!, $userId: String!) {\n    kickAndBanFromRoom(roomId: $roomId, userId: $userId) {\n      ok\n    }\n  }\n": types.KickAndBanMutationDocument,
  "\n  mutation AddFriendMutation2($username: String!) {\n    addFriend(username: $username) {\n      id\n      friends {\n        state\n        user {\n          id\n          isSubscribed\n          displayName\n          username\n          avatarUrl\n          email\n          isConnected\n        }\n      }\n    }\n  }\n": types.AddFriendMutation2Document,
  "\n  mutation ReportUserMutation($userId: String!, $text: String!) {\n    reportUser(userId: $userId, text: $text) {\n      ok\n    }\n  }\n": types.ReportUserMutationDocument,
  "\n  mutation EventMutation(\n    $label: String!\n    $category: String!\n    $action: String!\n    $value: String\n  ) {\n    sendEvent(\n      label: $label\n      category: $category\n      action: $action\n      value: $value\n    ) {\n      ok\n    }\n  }\n": types.EventMutationDocument,
  "\n  query ColorSchemeQuery {\n    colorSchemes {\n      id\n      name\n      primaryColor\n      secondaryColor\n      foregroundColor\n      darkMode\n    }\n  }\n": types.ColorSchemeQueryDocument,
  "\n  query SettingsQuery {\n    settings {\n      id\n      colorScheme\n    }\n  }\n": types.SettingsQueryDocument,
  "\n  mutation RegisterPushNotificationsMutation(\n    $endpoint: String!\n    $keys: String!\n  ) {\n    registerPushNotifications(endpoint: $endpoint, keys: $keys) {\n      ok\n    }\n  }\n": types.RegisterPushNotificationsMutationDocument
};
export function gql(source) {
  var _a;
  return (_a = documents[source]) != null ? _a : {};
}
