"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import * as SPACES from "../../../apps/spaces/index";
import * as LOBBY from "../../../apps/lobby/index";
export default {
  PSX: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.psx" */
      "psx"
    );
  }),
  CARDTABLE: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.cardtable" */
      "cardtable"
    );
  }),
  SNES_PARTY: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.snes" */
      "snes"
    );
  }),
  NES_PARTY: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.nes" */
      "nes"
    );
  }),
  OPENARENA: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.openarena" */
      "openarena"
    );
  }),
  POKER: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.poker" */
      "poker"
    );
  }),
  CARDS_AGAINST_HUMANITY: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.cards_against_humanity" */
      "cards_against_humanity"
    );
  }),
  LOBBY: () => __async(void 0, null, function* () {
    return LOBBY;
  }),
  VIDEO_CHAT: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.videochat" */
      "videochat"
    );
  }),
  POOLTABLE: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.pooltable" */
      "pooltable"
    );
  }),
  WEBVIEW: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.webview" */
      "webview"
    );
  }),
  KOSMI_CLOUD: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.webview" */
      "kosmi_cloud"
    );
  }),
  WEB_BROWSER: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.web_browser" */
      "web_browser"
    );
  }),
  PAINT: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.paint" */
      "paint"
    );
  }),
  TABLE: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.table" */
      "table"
    );
  }),
  SPACES: () => __async(void 0, null, function* () {
    return SPACES;
  }),
  WORTLE: () => __async(void 0, null, function* () {
    return yield import(
      /* webpackChunkName: "kosmi.app.wortle" */
      "wortle"
    );
  })
};
