"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Notifications = exports.NotificationIcon = exports.NOTIFICATION_QUERY = exports.MARK_SEEN_MUTATION = void 0;
var _gql = require("kosmi-sdk/gql");
var _uikit = require("kosmi-sdk/uikit");
var _widgets = require("kosmi-sdk/widgets");
var _react = _interopRequireWildcard(require("react"));
var _reactLinkify = _interopRequireDefault(require("react-linkify"));
var _reactRouterDom = require("react-router-dom");
var _reactStringReplace = _interopRequireDefault(require("react-string-replace"));
var _ = require("../..");
var _useRegisterWebPushNotifications = _interopRequireDefault(require("../../../../../hooks/useRegisterWebPushNotifications"));
var _LoginWall = _interopRequireDefault(require("@/components/Widgets/LoginWall"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function isNotNull(value) {
  return value !== null;
}
const glowingIcon = "x2u31rq-B";
const styles = {
  notificationDropdown: {
    "index__styles.notificationDropdown": "index__styles.notificationDropdown",
    position: "x1n2onr6",
    height: "x16nrsnc",
    width: "xdzyupr",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    "@media (max-width: 1000px)_height": "x1ltrzbh",
    "@media (max-width: 1000px)_width": "x1nkzv30",
    $$css: true
  },
  notificationsWrapper: {
    "index__styles.notificationsWrapper": "index__styles.notificationsWrapper",
    width: "xh8yej3",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    flexDirection: "xdt5ytf",
    $$css: true
  },
  image: {
    "index__styles.image": "index__styles.image",
    maxWidth: "x193iq5w",
    width: "xh8yej3",
    objectFit: "x19kjcj4",
    $$css: true
  },
  notificationModalContent: {
    "index__styles.notificationModalContent": "index__styles.notificationModalContent",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    width: "xh8yej3",
    height: "xg7h5cd",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  invitingUser: {
    "index__styles.invitingUser": "index__styles.invitingUser",
    display: "x78zum5",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    justifyContent: "x1nhvcw1",
    width: "xh8yej3",
    $$css: true
  },
  invitingUserAvatar: {
    "index__styles.invitingUserAvatar": "index__styles.invitingUserAvatar",
    height: "x1qx5ct2",
    width: "xw4jnvo",
    marginRight: "x17ituo4",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  inviteText: {
    "index__styles.inviteText": "index__styles.inviteText",
    width: "xh8yej3",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  title: {
    "index__styles.title": "index__styles.title",
    fontSize: "x1pvqxga",
    fontWeight: "x1s688f",
    marginBottom: "xieb3on",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    $$css: true
  },
  joinRoomAction: {
    "index__styles.joinRoomAction": "index__styles.joinRoomAction",
    alignItems: "x6s0dn4",
    backgroundColor: "x6kzxpx",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    boxShadow: "x3e6q68",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    marginTop: "x1sy10c2",
    minHeight: "x2lklch",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    $$css: true
  },
  joinRoomActionAvatar: {
    "index__styles.joinRoomActionAvatar": "index__styles.joinRoomActionAvatar",
    width: "x1pju0fl",
    height: "x10wjd1d",
    margin: "x1v36x0e",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  empty: {
    "index__styles.empty": "index__styles.empty",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    $$css: true
  },
  notificationNumber: {
    "index__styles.notificationNumber": "index__styles.notificationNumber",
    position: "x10l6tqk",
    left: "x1rvfpmx",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x1jzctok",
    height: "xx3o462",
    width: "x1a00udw",
    background: "x7v9ooy",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    fontWeight: "x1xlr1w8",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    padding: "x46zyou",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    fontSize: "x1k6wstc",
    lineHeight: "x6kjo9u",
    $$css: true
  },
  dropdownHeader: {
    "index__styles.dropdownHeader": "index__styles.dropdownHeader",
    display: "x78zum5",
    width: "xh8yej3",
    justifyContent: "x1qughib",
    padding: "xetlade",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    alignItems: "x6s0dn4",
    maxHeight: "x127lh2o",
    "@media (max-width: 1000px)_fontSize": "x1b9ffsh",
    "@media (max-width: 1000px)_padding": "x1bstub3",
    "@media (max-width: 1000px)_paddingInline": null,
    "@media (max-width: 1000px)_paddingStart": null,
    "@media (max-width: 1000px)_paddingLeft": null,
    "@media (max-width: 1000px)_paddingEnd": null,
    "@media (max-width: 1000px)_paddingRight": null,
    "@media (max-width: 1000px)_paddingBlock": null,
    "@media (max-width: 1000px)_paddingTop": null,
    "@media (max-width: 1000px)_paddingBottom": null,
    $$css: true
  },
  glowingIcon: {
    "index__styles.glowingIcon": "index__styles.glowingIcon",
    color: "x16cd2qt",
    fontWeight: "xuv8nkb",
    animationName: "x19n8mo0",
    animationDuration: "xmg6eyc",
    animationTimingFunction: "x4hg4is",
    animationIterationCount: "xa4qsjk",
    animationDirection: "xpz12be",
    $$css: true
  },
  emptyNotifications: {
    "index__styles.emptyNotifications": "index__styles.emptyNotifications",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    height: "x5yr21d",
    width: "xh8yej3",
    fontSize: "x1k6wstc",
    $$css: true
  }
};
const NOTIFICATION_QUERY = exports.NOTIFICATION_QUERY = (0, _gql.gql)(`
  query NotificationQuery2 {
    currentUser {
      id
      connectionId
      user {
        id
        username
        isAnonymous
        notifications {
          id
          title
          text
          type
          timestamp
          seen
          autoOpen
          sender {
            id
            username
            displayName
            avatarUrl
          }
        }
      }
    }
  }
`);
const ROOM_QUERY = (0, _gql.gql)(`
  query RoomActionQuery($roomId: String!) {
    room(id: $roomId) {
      id
      state {
        metadata {
          roomName
          avatarUrl
        }
      }
    }
  }
`);
const MARK_SEEN_MUTATION = exports.MARK_SEEN_MUTATION = (0, _gql.gql)(`
  mutation MarkAllNotificationsAsSeen {
    markAllNotificationsAsSeen {
      id
      seen
    }
  }
`);
const JoinRoomAction = (0, _react.memo)(({
  roomId,
  joinRoomCallback
}) => {
  var _a, _b, _c, _d, _e, _f;
  const history = (0, _reactRouterDom.useHistory)();
  const {
    data
  } = (0, _gql.useQuery)(ROOM_QUERY, {
    variables: {
      roomId
    }
  });
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.joinRoomAction
  }, data ? /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, (_c = (_b = (_a = data.room) == null ? void 0 : _a.state) == null ? void 0 : _b.metadata) == null ? void 0 : _c.roomName, " ", /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    circular: true,
    src: ((_f = (_e = (_d = data.room) == null ? void 0 : _d.state) == null ? void 0 : _e.metadata) == null ? void 0 : _f.avatarUrl) || "",
    loading: "lazy",
    className: styles.joinRoomActionAvatar
  }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    inverted: true,
    onClick: () => {
      history.push("/room/" + roomId);
      if (joinRoomCallback) {
        joinRoomCallback(true);
      }
    }
  }, "Join Room"), " ") : /* @__PURE__ */_react.default.createElement(_uikit.Loader, null));
});
const addKosmiEmbeds = body => (0, _reactStringReplace.default)(body, /(https:\/\/img\.kosmi\.io\/\w+[.]\S+)/g, match => /* @__PURE__ */_react.default.createElement(_uikit.Image, {
  className: styles.image,
  src: match,
  loading: "lazy"
}));
const ViewNotificationModal = ({
  notification,
  onClose,
  currentUser
}) => {
  let content = null;
  const username = (currentUser == null ? void 0 : currentUser.username) || "";
  if (notification) {
    if (notification.type === "INVITE") {
      content = /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.notificationModalContent
      }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.invitingUser
      }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
        className: styles.invitingUserAvatar,
        circular: true,
        src: notification.sender.avatarUrl
      }), notification.sender.displayName), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.inviteText
      }, "has invited you to join a room"), /* @__PURE__ */_react.default.createElement(JoinRoomAction, {
        roomId: notification.text,
        joinRoomCallback: onClose
      }));
    } else {
      content = /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.notificationModalContent
      }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.invitingUser
      }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
        className: styles.invitingUserAvatar,
        circular: true,
        src: notification.sender.avatarUrl
      }), notification.sender.displayName), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.title
      }, notification.title), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(_reactLinkify.default, null, notification.text.replace("[username]", username !== "anonymous" ? username : "yourusername").split("\n").map((line, i) => /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        key: i
      }, addKosmiEmbeds(line))))));
    }
  }
  return /* @__PURE__ */_react.default.createElement(_widgets.AnimatedModal, {
    open: !!notification,
    onClose: () => onClose(false)
  }, /* @__PURE__ */_react.default.createElement(_widgets.AnimatedModal.Content, null, content));
};
const Notifications = exports.Notifications = (0, _react.forwardRef)(({
  onClose
}, ref) => {
  var _a, _b;
  const {
    data,
    refetch
  } = (0, _gql.useQuery)(NOTIFICATION_QUERY);
  const notifications = ((_b = (_a = data == null ? void 0 : data.currentUser) == null ? void 0 : _a.user) == null ? void 0 : _b.notifications) || [];
  const prevNotificationsRef = (0, _react.useRef)(notifications);
  const [viewNotification, setViewNotification] = (0, _react.useState)(null);
  (0, _react.useEffect)(() => {
    const prevNotifications = prevNotificationsRef.current;
    if (notifications.length >= prevNotifications.length) {
      const notification = notifications[0];
      if (notification == null ? void 0 : notification.autoOpen) {
        setViewNotification(notification);
      }
      prevNotificationsRef.current = notifications;
    }
  }, [JSON.stringify(notifications)]);
  const items = (0, _react.useMemo)(() => notifications.filter(isNotNull).map(n => {
    var _a2, _b2;
    return {
      onClick: () => setViewNotification(n),
      title: n.title || "",
      content: ((_a2 = n == null ? void 0 : n.sender) == null ? void 0 : _a2.displayName) || "",
      unread: !n.seen,
      avatarUrl: ((_b2 = n == null ? void 0 : n.sender) == null ? void 0 : _b2.avatarUrl) || "",
      timestamp: n.timestamp || 0,
      key: n.timestamp
    };
  }), [JSON.stringify(notifications)]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.notificationDropdown, notifications.length === 0 && styles.empty],
    ref
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.dropdownHeader
  }, "Notifications", /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "white",
    inverted: true,
    style: {
      borderWidth: 0,
      fontSize: 18,
      visibility: "hidden"
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "cog"
  }))), /* @__PURE__ */_react.default.createElement(ViewNotificationModal, {
    notification: viewNotification,
    currentUser: data == null ? void 0 : data.currentUser,
    onClose: closeDropdown => {
      setViewNotification(null);
      if (closeDropdown) {
        onClose();
      }
      if (viewNotification == null ? void 0 : viewNotification.autoOpen) {
        refetch();
      }
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.notificationsWrapper
  }, items.length ? /* @__PURE__ */_react.default.createElement(_uikit.MessageList, {
    items
  }) : /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.emptyNotifications
  }, "You have no notifications!")));
});
const NotificationIcon = exports.NotificationIcon = (0, _react.memo)(() => {
  var _a, _b, _c, _d, _e;
  const {
    data
  } = (0, _gql.useQuery)(NOTIFICATION_QUERY);
  const unseenNotifications = !((_b = (_a = data == null ? void 0 : data.currentUser) == null ? void 0 : _a.user) == null ? void 0 : _b.isAnonymous) && ((_e = (_d = (_c = data == null ? void 0 : data.currentUser) == null ? void 0 : _c.user) == null ? void 0 : _d.notifications) == null ? void 0 : _e.filter(n => !n.seen)) || [];
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, unseenNotifications.length > 0 && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.notificationNumber
  }, unseenNotifications.length), /* @__PURE__ */_react.default.createElement(_.TopBarIcon, {
    name: "bell",
    className: [unseenNotifications.length > 0 && styles.glowingIcon]
  }));
});
const NotificationDropdown = () => {
  const [showDropdown, setShowDropdown] = (0, _react.useState)(false);
  const [markAsSeen] = (0, _gql.useMutation)(MARK_SEEN_MUTATION);
  const registerWebPushNotifications = (0, _useRegisterWebPushNotifications.default)();
  return /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu, {
    trigger: /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_.TopBarButton, {
      onClick: () => {
        setShowDropdown(!showDropdown);
      }
    }, /* @__PURE__ */_react.default.createElement(NotificationIcon, null))),
    open: showDropdown,
    onOpen: () => {
      markAsSeen();
      registerWebPushNotifications();
    },
    onClose: () => {
      setShowDropdown(false);
    },
    position: "right"
  }, /* @__PURE__ */_react.default.createElement(Notifications, {
    onClose: () => {
      setShowDropdown(false);
    }
  }));
};
var _default = exports.default = NotificationDropdown;