"use strict";
import platform from "platform";
import { useIsMobile } from "../hooks/useMobileDetection";
import { useRef } from "react";
function usePlatform() {
  var _a, _b;
  const { isIOS, isMobile, isLandscape } = useIsMobile();
  const hasScreenshareWithAudio = window.chrome && !!navigator.mediaDevices.getDisplayMedia;
  const isAndroid = ((_b = (_a = platform) == null ? void 0 : _a.os) == null ? void 0 : _b.family) === "Android";
  const videoElement = useRef(document.createElement("video"));
  return {
    isMobile,
    isIOS,
    isAndroid,
    isMobileOS: isIOS || isAndroid,
    isLandscape,
    hasScreenshareWithAudio,
    canCaptureVideoElement: !!videoElement.current.captureStream
  };
}
export default usePlatform;
