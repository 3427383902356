"use strict";
var _a, _b, _c, _d;
import React from "react";
import {
  Diamond,
  PaywallMessage
} from "../../../core/components/Widgets/Premium";
import { currentUserHook, withDialogs } from "kosmi-sdk/helpers";
import platform from "platform";
import style from "./style.css";
import { withStyleHook } from "kosmi-sdk/styles";
import { gql, useQuery } from "kosmi-sdk/gql";
import { useSpacesActions } from "../hooks";
const isIOS = ((_b = (_a = platform) == null ? void 0 : _a.os) == null ? void 0 : _b.family) === "iOS";
const isMacOS = ((_d = (_c = platform) == null ? void 0 : _c.os) == null ? void 0 : _d.family) === "OS X";
const isCapacitor = location.href.startsWith("capacitor");
const hidePremium = (isIOS || isMacOS) && isCapacitor;
const THEMES = gql(`
  query Themes {
    themes {
      backgroundImageUrl
      colorScheme
      name
      sofaUrl
      tvPlaceholderUrl
    }
  }
`);
export default withDialogs(({ alert }) => {
  const styles = withStyleHook(style);
  const { data } = useQuery(THEMES);
  let themes = (data == null ? void 0 : data.themes) || [];
  const currentUser = currentUserHook();
  const { setValue, removeValue } = useSpacesActions();
  const freeThreshold = 2;
  if (hidePremium && !currentUser.isSubscribed) {
    themes = themes.filter((_t, i) => i <= freeThreshold);
  }
  return /* @__PURE__ */ React.createElement("div", { className: styles.themes }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: styles.theme,
      onClick: () => {
        removeValue("background");
        removeValue("colorScheme");
        removeValue("sofa");
        removeValue("tvPlaceholder");
      }
    },
    /* @__PURE__ */ React.createElement("div", null, "Default")
  ), themes.map((t, i) => /* @__PURE__ */ React.createElement(
    "div",
    {
      key: i,
      className: styles.theme,
      onClick: () => {
        if (i > freeThreshold && !currentUser.isSubscribed) {
          alert(
            /* @__PURE__ */ React.createElement(
              PaywallMessage,
              {
                message: "Please get Premium to get access to all the themes!",
                source: "Themes"
              }
            ),
            true
          );
        } else {
          setValue(
            "background",
            "https://img.kosmi.io/" + t.backgroundImageUrl
          );
          setValue("colorScheme", t.colorScheme);
          setValue("sofa", "https://img.kosmi.io/" + t.sofaUrl);
          if (t.tvPlaceholderUrl) {
            setValue(
              "tvPlaceholder",
              "https://img.kosmi.io/" + t.tvPlaceholderUrl
            );
          } else {
            removeValue("tvPlaceholder");
          }
        }
      }
    },
    /* @__PURE__ */ React.createElement("img", { src: `https://img.kosmi.io/${t.backgroundImageUrl}?w=300` }),
    /* @__PURE__ */ React.createElement("div", null, t.name, i > freeThreshold && !currentUser.isSubscribed && /* @__PURE__ */ React.createElement(
      Diamond,
      {
        style: {
          fontSize: 15,
          marginLeft: "auto",
          marginBottom: 0
        }
      }
    ))
  )));
});
