"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  volume: {
    "VolumeSlider__styles.volume": "VolumeSlider__styles.volume",
    display: "x78zum5",
    transition: "x1n0frbq",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    pointerEvents: "x71s49j",
    zIndex: "x1vjfegm",
    ":hover_width": "xe0ut24",
    $$css: true
  },
  rangeInput: {
    "VolumeSlider__styles.rangeInput": "VolumeSlider__styles.rangeInput",
    display: "x1s85apg",
    width: "xh8yej3",
    margin: "x20nx6h",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    backgroundColor: "xjbqb8w",
    appearance: "xjyslct",
    ":focus_outline": "x1uvtmcs",
    ":focus_outlineColor": null,
    ":focus_outlineOffset": null,
    ":focus_outlineStyle": null,
    ":focus_outlineWidth": null,
    "::-webkit-slider-runnable-track_background": "x10ucaab",
    "::-webkit-slider-runnable-track_backgroundAttachment": null,
    "::-webkit-slider-runnable-track_backgroundClip": null,
    "::-webkit-slider-runnable-track_backgroundColor": null,
    "::-webkit-slider-runnable-track_backgroundImage": null,
    "::-webkit-slider-runnable-track_backgroundOrigin": null,
    "::-webkit-slider-runnable-track_backgroundPosition": null,
    "::-webkit-slider-runnable-track_backgroundPositionX": null,
    "::-webkit-slider-runnable-track_backgroundPositionY": null,
    "::-webkit-slider-runnable-track_backgroundRepeat": null,
    "::-webkit-slider-runnable-track_backgroundSize": null,
    "::-webkit-slider-runnable-track_border": "xxeukq3",
    "::-webkit-slider-runnable-track_borderWidth": null,
    "::-webkit-slider-runnable-track_borderInlineWidth": null,
    "::-webkit-slider-runnable-track_borderInlineStartWidth": null,
    "::-webkit-slider-runnable-track_borderLeftWidth": null,
    "::-webkit-slider-runnable-track_borderInlineEndWidth": null,
    "::-webkit-slider-runnable-track_borderRightWidth": null,
    "::-webkit-slider-runnable-track_borderBlockWidth": null,
    "::-webkit-slider-runnable-track_borderTopWidth": null,
    "::-webkit-slider-runnable-track_borderBottomWidth": null,
    "::-webkit-slider-runnable-track_borderStyle": null,
    "::-webkit-slider-runnable-track_borderInlineStyle": null,
    "::-webkit-slider-runnable-track_borderInlineStartStyle": null,
    "::-webkit-slider-runnable-track_borderLeftStyle": null,
    "::-webkit-slider-runnable-track_borderInlineEndStyle": null,
    "::-webkit-slider-runnable-track_borderRightStyle": null,
    "::-webkit-slider-runnable-track_borderBlockStyle": null,
    "::-webkit-slider-runnable-track_borderTopStyle": null,
    "::-webkit-slider-runnable-track_borderBottomStyle": null,
    "::-webkit-slider-runnable-track_borderColor": null,
    "::-webkit-slider-runnable-track_borderInlineColor": null,
    "::-webkit-slider-runnable-track_borderInlineStartColor": null,
    "::-webkit-slider-runnable-track_borderLeftColor": null,
    "::-webkit-slider-runnable-track_borderInlineEndColor": null,
    "::-webkit-slider-runnable-track_borderRightColor": null,
    "::-webkit-slider-runnable-track_borderBlockColor": null,
    "::-webkit-slider-runnable-track_borderTopColor": null,
    "::-webkit-slider-runnable-track_borderBottomColor": null,
    "::-webkit-slider-runnable-track_borderRadius": "x10dvr2p",
    "::-webkit-slider-runnable-track_borderStartStartRadius": null,
    "::-webkit-slider-runnable-track_borderStartEndRadius": null,
    "::-webkit-slider-runnable-track_borderEndStartRadius": null,
    "::-webkit-slider-runnable-track_borderEndEndRadius": null,
    "::-webkit-slider-runnable-track_borderTopLeftRadius": null,
    "::-webkit-slider-runnable-track_borderTopRightRadius": null,
    "::-webkit-slider-runnable-track_borderBottomLeftRadius": null,
    "::-webkit-slider-runnable-track_borderBottomRightRadius": null,
    "::-webkit-slider-runnable-track_width": "xrzswki",
    "::-webkit-slider-runnable-track_height": "xfz7hpy",
    "::-webkit-slider-runnable-track_cursor": "xruwzed",
    "::-webkit-slider-thumb_marginTop": "x1krwe39",
    "::-webkit-slider-thumb_width": "xx94js9",
    "::-webkit-slider-thumb_height": "xrf5k58",
    "::-webkit-slider-thumb_background": "x17il7h4",
    "::-webkit-slider-thumb_backgroundAttachment": null,
    "::-webkit-slider-thumb_backgroundClip": null,
    "::-webkit-slider-thumb_backgroundColor": null,
    "::-webkit-slider-thumb_backgroundImage": null,
    "::-webkit-slider-thumb_backgroundOrigin": null,
    "::-webkit-slider-thumb_backgroundPosition": null,
    "::-webkit-slider-thumb_backgroundPositionX": null,
    "::-webkit-slider-thumb_backgroundPositionY": null,
    "::-webkit-slider-thumb_backgroundRepeat": null,
    "::-webkit-slider-thumb_backgroundSize": null,
    "::-webkit-slider-thumb_border": "xydgfbv",
    "::-webkit-slider-thumb_borderWidth": null,
    "::-webkit-slider-thumb_borderInlineWidth": null,
    "::-webkit-slider-thumb_borderInlineStartWidth": null,
    "::-webkit-slider-thumb_borderLeftWidth": null,
    "::-webkit-slider-thumb_borderInlineEndWidth": null,
    "::-webkit-slider-thumb_borderRightWidth": null,
    "::-webkit-slider-thumb_borderBlockWidth": null,
    "::-webkit-slider-thumb_borderTopWidth": null,
    "::-webkit-slider-thumb_borderBottomWidth": null,
    "::-webkit-slider-thumb_borderStyle": null,
    "::-webkit-slider-thumb_borderInlineStyle": null,
    "::-webkit-slider-thumb_borderInlineStartStyle": null,
    "::-webkit-slider-thumb_borderLeftStyle": null,
    "::-webkit-slider-thumb_borderInlineEndStyle": null,
    "::-webkit-slider-thumb_borderRightStyle": null,
    "::-webkit-slider-thumb_borderBlockStyle": null,
    "::-webkit-slider-thumb_borderTopStyle": null,
    "::-webkit-slider-thumb_borderBottomStyle": null,
    "::-webkit-slider-thumb_borderColor": null,
    "::-webkit-slider-thumb_borderInlineColor": null,
    "::-webkit-slider-thumb_borderInlineStartColor": null,
    "::-webkit-slider-thumb_borderLeftColor": null,
    "::-webkit-slider-thumb_borderInlineEndColor": null,
    "::-webkit-slider-thumb_borderRightColor": null,
    "::-webkit-slider-thumb_borderBlockColor": null,
    "::-webkit-slider-thumb_borderTopColor": null,
    "::-webkit-slider-thumb_borderBottomColor": null,
    "::-webkit-slider-thumb_borderRadius": "xipv102",
    "::-webkit-slider-thumb_borderStartStartRadius": null,
    "::-webkit-slider-thumb_borderStartEndRadius": null,
    "::-webkit-slider-thumb_borderEndStartRadius": null,
    "::-webkit-slider-thumb_borderEndEndRadius": null,
    "::-webkit-slider-thumb_borderTopLeftRadius": null,
    "::-webkit-slider-thumb_borderTopRightRadius": null,
    "::-webkit-slider-thumb_borderBottomLeftRadius": null,
    "::-webkit-slider-thumb_borderBottomRightRadius": null,
    "::-webkit-slider-thumb_cursor": "x5pni9w",
    "::-webkit-slider-thumb_appearance": "xibs45w",
    ":focus::-webkit-slider-runnable-track_background": "xcm40tf",
    ":focus::-webkit-slider-runnable-track_backgroundAttachment": null,
    ":focus::-webkit-slider-runnable-track_backgroundClip": null,
    ":focus::-webkit-slider-runnable-track_backgroundColor": null,
    ":focus::-webkit-slider-runnable-track_backgroundImage": null,
    ":focus::-webkit-slider-runnable-track_backgroundOrigin": null,
    ":focus::-webkit-slider-runnable-track_backgroundPosition": null,
    ":focus::-webkit-slider-runnable-track_backgroundPositionX": null,
    ":focus::-webkit-slider-runnable-track_backgroundPositionY": null,
    ":focus::-webkit-slider-runnable-track_backgroundRepeat": null,
    ":focus::-webkit-slider-runnable-track_backgroundSize": null,
    "::-moz-range-track_background": "xjxoeht",
    "::-moz-range-track_backgroundAttachment": null,
    "::-moz-range-track_backgroundClip": null,
    "::-moz-range-track_backgroundColor": null,
    "::-moz-range-track_backgroundImage": null,
    "::-moz-range-track_backgroundOrigin": null,
    "::-moz-range-track_backgroundPosition": null,
    "::-moz-range-track_backgroundPositionX": null,
    "::-moz-range-track_backgroundPositionY": null,
    "::-moz-range-track_backgroundRepeat": null,
    "::-moz-range-track_backgroundSize": null,
    "::-moz-range-track_border": "x1h8y2mz",
    "::-moz-range-track_borderWidth": null,
    "::-moz-range-track_borderInlineWidth": null,
    "::-moz-range-track_borderInlineStartWidth": null,
    "::-moz-range-track_borderLeftWidth": null,
    "::-moz-range-track_borderInlineEndWidth": null,
    "::-moz-range-track_borderRightWidth": null,
    "::-moz-range-track_borderBlockWidth": null,
    "::-moz-range-track_borderTopWidth": null,
    "::-moz-range-track_borderBottomWidth": null,
    "::-moz-range-track_borderStyle": null,
    "::-moz-range-track_borderInlineStyle": null,
    "::-moz-range-track_borderInlineStartStyle": null,
    "::-moz-range-track_borderLeftStyle": null,
    "::-moz-range-track_borderInlineEndStyle": null,
    "::-moz-range-track_borderRightStyle": null,
    "::-moz-range-track_borderBlockStyle": null,
    "::-moz-range-track_borderTopStyle": null,
    "::-moz-range-track_borderBottomStyle": null,
    "::-moz-range-track_borderColor": null,
    "::-moz-range-track_borderInlineColor": null,
    "::-moz-range-track_borderInlineStartColor": null,
    "::-moz-range-track_borderLeftColor": null,
    "::-moz-range-track_borderInlineEndColor": null,
    "::-moz-range-track_borderRightColor": null,
    "::-moz-range-track_borderBlockColor": null,
    "::-moz-range-track_borderTopColor": null,
    "::-moz-range-track_borderBottomColor": null,
    "::-moz-range-track_borderRadius": "x1bi06sl",
    "::-moz-range-track_borderStartStartRadius": null,
    "::-moz-range-track_borderStartEndRadius": null,
    "::-moz-range-track_borderEndStartRadius": null,
    "::-moz-range-track_borderEndEndRadius": null,
    "::-moz-range-track_borderTopLeftRadius": null,
    "::-moz-range-track_borderTopRightRadius": null,
    "::-moz-range-track_borderBottomLeftRadius": null,
    "::-moz-range-track_borderBottomRightRadius": null,
    "::-moz-range-track_width": "xcet9i",
    "::-moz-range-track_height": "x1rj9at6",
    "::-moz-range-track_cursor": "x11lxgmc",
    "::-moz-range-thumb_width": "xi9vfd",
    "::-moz-range-thumb_height": "x8w4urf",
    "::-moz-range-thumb_background": "xpanm18",
    "::-moz-range-thumb_backgroundAttachment": null,
    "::-moz-range-thumb_backgroundClip": null,
    "::-moz-range-thumb_backgroundColor": null,
    "::-moz-range-thumb_backgroundImage": null,
    "::-moz-range-thumb_backgroundOrigin": null,
    "::-moz-range-thumb_backgroundPosition": null,
    "::-moz-range-thumb_backgroundPositionX": null,
    "::-moz-range-thumb_backgroundPositionY": null,
    "::-moz-range-thumb_backgroundRepeat": null,
    "::-moz-range-thumb_backgroundSize": null,
    "::-moz-range-thumb_border": "x1hwl6wz",
    "::-moz-range-thumb_borderWidth": null,
    "::-moz-range-thumb_borderInlineWidth": null,
    "::-moz-range-thumb_borderInlineStartWidth": null,
    "::-moz-range-thumb_borderLeftWidth": null,
    "::-moz-range-thumb_borderInlineEndWidth": null,
    "::-moz-range-thumb_borderRightWidth": null,
    "::-moz-range-thumb_borderBlockWidth": null,
    "::-moz-range-thumb_borderTopWidth": null,
    "::-moz-range-thumb_borderBottomWidth": null,
    "::-moz-range-thumb_borderStyle": null,
    "::-moz-range-thumb_borderInlineStyle": null,
    "::-moz-range-thumb_borderInlineStartStyle": null,
    "::-moz-range-thumb_borderLeftStyle": null,
    "::-moz-range-thumb_borderInlineEndStyle": null,
    "::-moz-range-thumb_borderRightStyle": null,
    "::-moz-range-thumb_borderBlockStyle": null,
    "::-moz-range-thumb_borderTopStyle": null,
    "::-moz-range-thumb_borderBottomStyle": null,
    "::-moz-range-thumb_borderColor": null,
    "::-moz-range-thumb_borderInlineColor": null,
    "::-moz-range-thumb_borderInlineStartColor": null,
    "::-moz-range-thumb_borderLeftColor": null,
    "::-moz-range-thumb_borderInlineEndColor": null,
    "::-moz-range-thumb_borderRightColor": null,
    "::-moz-range-thumb_borderBlockColor": null,
    "::-moz-range-thumb_borderTopColor": null,
    "::-moz-range-thumb_borderBottomColor": null,
    "::-moz-range-thumb_borderRadius": "xbscnv2",
    "::-moz-range-thumb_borderStartStartRadius": null,
    "::-moz-range-thumb_borderStartEndRadius": null,
    "::-moz-range-thumb_borderEndStartRadius": null,
    "::-moz-range-thumb_borderEndEndRadius": null,
    "::-moz-range-thumb_borderTopLeftRadius": null,
    "::-moz-range-thumb_borderTopRightRadius": null,
    "::-moz-range-thumb_borderBottomLeftRadius": null,
    "::-moz-range-thumb_borderBottomRightRadius": null,
    "::-moz-range-thumb_cursor": "xvs8oyn",
    $$css: true
  },
  rangeInputHover: {
    "VolumeSlider__styles.rangeInputHover": "VolumeSlider__styles.rangeInputHover",
    display: "x78zum5",
    $$css: true
  }
};
var _default = exports.default = (0, _react.memo)(({
  volume,
  setVolume,
  muted,
  mute,
  unmute
}) => {
  const [hovering, setHovering] = _react.default.useState(false);
  if (volume === null || volume === void 0) return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.volume,
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false)
  }, mute && unmute && /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "blue",
    onClick: () => {
      return muted ? unmute() : mute();
    },
    style: {
      marginRight: 5,
      marginLeft: 5,
      width: 36
    },
    icon: muted ? "volume off" : "volume up"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.input, {
    style: [styles.rangeInput, hovering && styles.rangeInputHover],
    type: "range",
    onChange: e => {
      setVolume(parseFloat(e.currentTarget.value) / 100);
    },
    min: "0",
    max: "100",
    value: muted ? 0 : volume * 100
  }));
});