"use strict";
import { useTracking } from "kosmi-sdk/helpers";
import React, { useState } from "react";
import Modal from "../../../../../Widgets/AnimatedModal";
import RoomSettings from "./components/RoomSettings";
export default ({ onOpen, trigger }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const track = useTracking();
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      large: true,
      open: modalOpen,
      closeIcon: true,
      trigger,
      onOpen: () => {
        if (onOpen)
          onOpen();
        track("UI", "OpenRoomSettingsModal", "Open room settings modal");
      },
      onClose: handleClose
    },
    /* @__PURE__ */ React.createElement(Modal.Header, null, "Room Settings"),
    /* @__PURE__ */ React.createElement(Modal.Content, { className: "roomsettings" }, /* @__PURE__ */ React.createElement(RoomSettings, null))
  );
};
