"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _reactDom = require("react-dom");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function mergeRefs(...refs) {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    });
  };
}
const styles = {
  root: {
    "index__styles.root": "index__styles.root",
    position: "x10l6tqk",
    height: "x5yr21d",
    width: "xh8yej3",
    $$css: true
  },
  backdrop: {
    "index__styles.backdrop": "index__styles.backdrop",
    position: "x10l6tqk",
    zIndex: "x1n327nk",
    width: "xh8yej3",
    height: "x5yr21d",
    transition: "xsn8bb8",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  spotlight: {
    "index__styles.spotlight": "index__styles.spotlight",
    position: "xixxii4",
    zIndex: "x1355qak",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "x5d3vyf",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  animated: {
    "index__styles.animated": "index__styles.animated",
    transition: "xsn8bb8",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  }
};
const getClassName = (...styleObj) => {
  const {
    className
  } = stylex.props(styleObj);
  return className;
};
var _default = ({
  brightness,
  children,
  enabled,
  animated,
  onClickOutside
}) => {
  var _a, _b;
  const [element, setElement] = (0, _react.useState)(null);
  const elementRef = (0, _react.useCallback)(node => {
    if (node !== null) {
      setElement(node);
    }
  }, []);
  const [screenX, setScreenX] = (0, _react.useState)(0);
  const [screenY, setScreenY] = (0, _react.useState)(0);
  const [width, setWidth] = (0, _react.useState)(0);
  const [height, setHeight] = (0, _react.useState)(0);
  const backdropStyle = {
    backdropFilter: `brightness(${brightness}%)`
  };
  backdropStyle.WebkitBackdropFilter = backdropStyle.backdropFilter;
  const highlightStyle = {
    width: `${width + 10}px`,
    height: `${height + 10}px`,
    left: screenX - 5,
    top: screenY - 5,
    backdropFilter: `brightness(${100 / brightness * 100}%)`
  };
  highlightStyle.WebkitBackdropFilter = highlightStyle.backdropFilter;
  (0, _react.useEffect)(() => {
    if (element && enabled) {
      const updateCoordinates = () => {
        const {
          x,
          y
        } = element.getBoundingClientRect();
        setScreenX(window.pageXOffset + x);
        setScreenY(window.pageYOffset + y);
        setWidth(element.offsetWidth);
        setHeight(element.offsetHeight);
      };
      updateCoordinates();
      const updateLoop = setInterval(updateCoordinates, 100);
      window.addEventListener("resize", updateCoordinates);
      return () => {
        window.removeEventListener("resize", updateCoordinates);
        clearInterval(updateLoop);
      };
    }
  }, [element, enabled]);
  const clonedChildren = _react.default.cloneElement(children, {
    ref: mergeRefs(((_b = (_a = children == null ? void 0 : children.props) == null ? void 0 : _a.trigger) == null ? void 0 : _b.ref) || children.ref, elementRef)
  });
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, element && enabled && (0, _reactDom.createPortal)( /* @__PURE__ */_react.default.createElement("div", {
    "data-spotlight": true,
    className: getClassName(styles.root, animated && styles.animated),
    onClick: onClickOutside
  }, /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.backdrop),
    style: backdropStyle
  }), /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.spotlight),
    style: highlightStyle
  })), document.body, "spotlight"), clonedChildren);
};
exports.default = _default;