"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import {
  ApolloClient,
  gql,
  InMemoryCache
} from "@apollo/client";
import { createHttpLink } from "@apollo/client/link/http/createHttpLink";
import { createClient } from "graphql-ws";
import { useEffect, useRef, useState } from "react";
import config from "../kosmi.config";
import { CHECK_TOKEN, ANON_LOGIN } from "./loggedOutQueries";
window.getServerTime = () => Date.now();
export const INIT_QUERY = gql(`
  query CurrentUserQuery4 {
    featureFlags {
      requireLoginToCreateRoom
    }
    getServerTime {
      time
    }
    currentUser {
      id
      connectionId
      user {
        id
        displayName
        username
        isAnonymous
        isSubscribed
        avatarUrl
        email
        emailConfirmed
        privateApps
        countryCode
        friends {
          state
          user {
            id
            displayName
            username
            avatarUrl
            email
            isConnected
          }
        }
      }
    }
  }
`);
if (config.engineHost) {
  config.WS_URI = `wss://${config.engineHost}/gql-ws`;
  config.HTTP_URI = `https://${config.engineHost}`;
}
export const WS_URI = config.WS_URI;
const HTTP_URI = config.HTTP_URI;
const defaultOptions = {
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "none",
    nextFetchPolicy: "cache-first"
  },
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "none",
    nextFetchPolicy: "cache-first"
  }
};
export const cache = new InMemoryCache({
  typePolicies: {
    Room: {
      fields: {
        state: {
          merge: (existing, incoming) => __spreadValues(__spreadValues({}, existing), incoming)
        }
      }
    },
    RoomState: {
      keyFields: false
    },
    RoomMetadata: {
      keyFields: false,
      merge: true
    }
  }
});
export const getSocketParams = () => {
  const params = {
    token: window.localStorage.getItem("token"),
    ua: btoa(navigator.userAgent),
    v: __VERSION__,
    r: btoa(document.referrer)
  };
  if (location.host.endsWith(".kosmi.business")) {
    params.realm = location.host.split(".kosmi.business")[0];
  }
  const c = new URL(location.href).searchParams.get("c");
  if (c) {
    params.campaign = c;
  }
  return params;
};
class DebugWebSocket extends WebSocket {
  constructor(url, protocols) {
    super(url, protocols);
    __publicField(this, "queryMap", {});
    __publicField(this, "subscriptionMap", {});
    this.queryMap = {};
    this.addEventListener("message", ({ data }) => {
      try {
        const parsed = JSON.parse(data);
        const { id, payload } = parsed;
        const { queryType, queryName, time } = this.queryMap[id] || {};
        if (payload == null ? void 0 : payload.data) {
          console.debug(
            "gql -> \u{1F7E2}",
            queryType,
            queryName,
            payload.data,
            Date.now() - time
          );
        }
      } catch (e) {
        console.error(e);
      }
    });
  }
  send(data) {
    var _a;
    try {
      const parsed = JSON.parse(data);
      if (parsed) {
        const { id, payload, type } = parsed;
        if (type === "complete") {
          const { queryName, variables } = this.queryMap[id];
          console.debug("gql -> \u{1F7E3}", "unsubscribe", queryName, variables);
        } else if (payload) {
          const queryType = (_a = payload.query) == null ? void 0 : _a.split(" ")[0];
          const queryName = payload.operationName;
          const variables = payload.variables;
          const time = Date.now();
          this.queryMap[id] = { queryType, queryName, variables, time };
          console.debug("gql -> \u{1F534}", queryType, queryName, variables);
        }
      }
    } catch (e) {
      console.error(e);
    }
    super.send(data);
  }
}
export const wsClient = (onOpen, onClose) => {
  const socketClient = createClient({
    url: config.WS_URI || "",
    lazy: false,
    keepAlive: Infinity,
    retryAttempts: Infinity,
    shouldRetry: () => true,
    retryWait: (attempt) => {
      return new Promise((resolve) => {
        setTimeout(resolve, Math.min(100 * attempt, 3e3));
      });
    },
    connectionParams: getSocketParams(),
    webSocketImpl: process.env.NODE_ENV === "development" ? DebugWebSocket : WebSocket
  });
  const link = new GraphQLWsLink(socketClient);
  const client = new ApolloClient({
    link,
    defaultOptions,
    cache
  });
  if (onOpen) {
    socketClient.on("connected", () => {
      onOpen(client);
    });
  }
  if (onClose) {
    socketClient.on("closed", () => {
      onClose();
    });
  }
  socketClient.on("error", () => __async(void 0, null, function* () {
    const token = window.localStorage.getItem("token");
    if (token) {
      if (!(yield checkToken(token, 100))) {
        window.localStorage.removeItem("token");
        window.location.reload();
      }
    }
  }));
  return client;
};
const httpClient = new ApolloClient({
  link: createHttpLink({
    uri: HTTP_URI
  }),
  cache: new InMemoryCache(),
  defaultOptions
});
function checkToken(token, max) {
  return __async(this, null, function* () {
    return new Promise((resolve) => __async(this, null, function* () {
      if (max <= 0) {
        resolve(false);
        return;
      }
      try {
        const response = yield httpClient.query({
          query: CHECK_TOKEN,
          variables: { token }
        });
        resolve(response.data.checkToken.ok);
      } catch (e) {
        if (e && e.message.includes("Network error") || e.message.includes("Failed to fetch")) {
          resolve(true);
        } else {
          setTimeout(() => __async(this, null, function* () {
            const result = yield checkToken(token, max - 1);
            resolve(result);
          }), 5e3);
        }
      }
    }));
  });
}
const refetchObservableQueriesOnlyUpdateCacheWhenSuccessful = (client) => __async(void 0, null, function* () {
  const observableQueries = client.getObservableQueries();
  for (const queryId of [...observableQueries.keys()]) {
    const observableQuery = observableQueries.get(queryId);
    if (!observableQuery)
      continue;
    const { query, variables } = observableQuery.options;
    client.query({
      query,
      variables,
      fetchPolicy: "network-only"
    }).then((response) => {
      if (!response.errors) {
        client.writeQuery({
          query,
          variables,
          data: response.data
        });
      }
    }).catch((error) => {
      console.error(
        `An unexpected error occurred during refetch for query ${queryId}:`,
        error
      );
    });
  }
});
export const useConnection = () => {
  const [connectionId, setConnectionId] = useState(null);
  const [disconnected, setDisconnected] = useState(true);
  const hasDisconnected = useRef(false);
  const onConnect = (client2) => __async(void 0, null, function* () {
    var _a;
    const result = yield client2.query({
      query: INIT_QUERY
    });
    client2.writeQuery({ query: INIT_QUERY, data: result.data });
    const { featureFlags, currentUser, getServerTime } = result.data;
    window.featureFlags = featureFlags;
    const serverTime = getServerTime.time;
    const connId = currentUser == null ? void 0 : currentUser.connectionId;
    setConnectionId(connId);
    setDisconnected(!connId);
    if (hasDisconnected.current) {
      refetchObservableQueriesOnlyUpdateCacheWhenSuccessful(client2);
    }
    const timeDiff = Date.now() - serverTime;
    window.getServerTime = () => Date.now() - timeDiff;
    const token = window.localStorage.getItem("token");
    if (token) {
      const currentUserId = (_a = currentUser == null ? void 0 : currentUser.user) == null ? void 0 : _a.id;
      const rollbar = window.rollbar;
      if (location.hostname === "app.kosmi.io") {
        ;
        rollbar.configure({
          payload: {
            custom: {
              current_user_id: currentUserId,
              version_no: __VERSION__
            }
          }
        });
      }
    }
  });
  const onClose = () => {
    hasDisconnected.current = true;
    setDisconnected(true);
  };
  const [client, setClient] = useState(
    null
  );
  const updateClient = () => {
    var _a;
    ;
    (_a = client == null ? void 0 : client.link) == null ? void 0 : _a.client.dispose();
    setConnectionId(null);
    setClient(wsClient(onConnect, onClose));
    client == null ? void 0 : client.resetStore();
  };
  useEffect(() => {
    const onlineListener = () => {
      var _a;
      ;
      (_a = client == null ? void 0 : client.link) == null ? void 0 : _a.client.dispose();
      setClient(wsClient(onConnect, onClose));
    };
    window.addEventListener("offline", onClose);
    window.addEventListener("online", onlineListener);
    (() => __async(void 0, null, function* () {
      const token = window.localStorage.getItem("token");
      if (token) {
        updateClient();
        const initResult = cache.readQuery({ query: INIT_QUERY }) || {};
        const { currentUser } = initResult;
        if (currentUser == null ? void 0 : currentUser.connectionId) {
          setConnectionId(currentUser.connectionId);
        }
      } else {
        const {
          data: {
            anonLogin: { token: token2 }
          }
        } = yield httpClient.mutate({ mutation: ANON_LOGIN });
        window.localStorage.setItem("token", token2);
        updateClient();
      }
    }))();
    return () => {
      window.removeEventListener("offline", onClose);
      window.removeEventListener("online", onlineListener);
    };
  }, []);
  return { disconnected, connectionId, client, updateClient };
};
