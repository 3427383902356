"use strict";
import { Icon } from "kosmi-sdk/uikit";
import React from "react";
import { withStyle } from "../../../sdk/styles";
import style from "./style.css";
const ErrorScreen = ({ styles, text, children }) => /* @__PURE__ */ React.createElement("div", { className: styles.errorScreen }, /* @__PURE__ */ React.createElement("div", { className: styles.errorWrapper }, /* @__PURE__ */ React.createElement(
  Icon,
  {
    color: "yellow",
    size: "huge",
    name: "warning sign",
    className: styles.errorIcon
  }
), /* @__PURE__ */ React.createElement("h2", { className: styles.errorText }, text === "{}" ? null : text), children));
export default withStyle(style)(ErrorScreen);
