"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _web = require("@react-spring/web");
var _react2 = require("@use-gesture/react");
var _usehooksTs = require("usehooks-ts");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _lodash = _interopRequireDefault(require("lodash.debounce"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const styles = {
  throwable: {
    "Throwable__styles.throwable": "Throwable__styles.throwable",
    position: "x10l6tqk",
    touchAction: "x5ve5x3",
    pointerEvents: "x71s49j",
    $$css: true
  }
};
const ThrowableContext = (0, _react.createContext)({
  containerWidth: 0,
  containerHeight: 0,
  containerOffset: [0, 0]
});
const getOffset = element => {
  const rect = element.getBoundingClientRect();
  return [rect.left + window.scrollX, rect.top + window.scrollY];
};
const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
const ThrowableInner = (0, _react.forwardRef)(({
  children,
  className,
  x,
  y,
  width,
  height,
  dragging,
  animate
}, ref) => {
  const sizeSpringStyles = (0, _web.useSpring)({
    to: {
      width,
      height,
      transform: `translate3d(${x}px, ${y}px, 0)`
    },
    config: {
      duration: dragging || animate === false ? 0 : 100
    }
  });
  return /* @__PURE__ */_react.default.createElement(_web.animated.div, {
    ref,
    className,
    style: sizeSpringStyles
  }, children);
});
const Throwable = (0, _react.memo)(({
  children,
  className: classNameProp,
  disabled,
  size,
  animate
}) => {
  const {
    className
  } = stylex.props(...(Array.isArray(classNameProp) ? classNameProp : [classNameProp]), styles.throwable);
  const {
    containerWidth,
    containerHeight,
    containerOffset
  } = (0, _react.useContext)(ThrowableContext);
  const [percentageX, setPercentageX] = (0, _react.useState)(50);
  const [percentageY, setPercentageY] = (0, _react.useState)(50);
  const ref = (0, _react.useRef)(null);
  const [dragging, setDragging] = (0, _react.useState)(false);
  let w = size[0];
  let h = size[1];
  if (typeof w === "string" && (w == null ? void 0 : w.endsWith("%"))) {
    w = w.replace("%", "");
    w = parseInt(w) / 100 * containerWidth;
  }
  if (typeof h === "string" && (h == null ? void 0 : h.endsWith("%"))) {
    h = h.replace("%", "");
    h = parseInt(h) / 100 * containerHeight;
  }
  const width = parseInt(w);
  const height = parseInt(h);
  const x = disabled ? 0 : clamp(0, percentageX / 100 * containerWidth, containerWidth - width);
  const y = disabled ? 0 : clamp(0, percentageY / 100 * containerHeight, Math.max(containerHeight - height, 0));
  const dragListener = (0, _react.useCallback)(({
    down,
    xy: [x2, y2],
    velocity: [vx, vy],
    direction: [dx, dy],
    elapsedTime
  }) => {
    if (disabled) return;
    if (elapsedTime < 100) return;
    const [xOffset, yOffset] = containerOffset;
    x2 = x2 - width / 2 - xOffset;
    y2 = y2 - height / 2 - yOffset;
    const maxX = containerWidth - width;
    const maxY = containerHeight - height;
    if (!down) {
      let endX = x2 + vx * dx * 100;
      let endY = y2 + vy * dy * 100;
      endX = clamp(endX, 0, maxX);
      endY = clamp(endY, 0, maxY);
      setPercentageX(Math.round(endX / containerWidth * 100));
      setPercentageY(Math.round(endY / containerHeight * 100));
      setDragging(false);
    } else {
      const newX = clamp(x2, 0, maxX);
      const newY = clamp(y2, 0, maxY);
      setPercentageX(Math.round(newX / containerWidth * 100));
      setPercentageY(Math.round(newY / containerHeight * 100));
      setDragging(true);
    }
  }, [setPercentageX, setPercentageY, setDragging, width, height, disabled, containerOffset[0], containerOffset[1], containerWidth, containerHeight]);
  (0, _react2.useDrag)(dragListener, {
    target: ref,
    filterTaps: true,
    tapsThreshold: 20,
    pointer: {
      touch: true
    }
  });
  return /* @__PURE__ */_react.default.createElement(ThrowableInner, {
    ref,
    className,
    x,
    y,
    width,
    height,
    animate: !!animate,
    dragging
  }, children);
});
const ThrowableContainer = (0, _react.memo)(({
  children,
  className
}) => {
  const [containerHeight, setContainerHeight] = (0, _react.useState)(0);
  const [containerWidth, setContainerWidth] = (0, _react.useState)(0);
  const [containerOffset, setContainerOffset] = (0, _react.useState)([0, 0]);
  const containerRef = (0, _react.useRef)(null);
  const onResize = (0, _react.useCallback)((0, _lodash.default)(({
    width,
    height
  }) => {
    setContainerWidth(width || 0);
    setContainerHeight(height || 0);
    if (containerRef.current) setContainerOffset(getOffset(containerRef.current));
  }, 100, {
    leading: true,
    maxWait: 100
  }), [setContainerWidth, setContainerHeight]);
  (0, _usehooksTs.useResizeObserver)({
    ref: containerRef,
    box: "border-box",
    onResize
  });
  return /* @__PURE__ */_react.default.createElement(ThrowableContext.Provider, {
    value: {
      containerWidth,
      containerHeight,
      containerOffset
    }
  }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    ref: containerRef,
    style: className
  }, children));
});
var _default = exports.default = Object.assign(Throwable, {
  Container: ThrowableContainer
});