"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _uikit = require("kosmi-sdk/uikit");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  launchImg: {
    "LaunchScreen__styles.launchImg": "LaunchScreen__styles.launchImg",
    width: "x1pju0fl",
    marginBottom: "xieb3on",
    $$css: true
  },
  launchWrapper: {
    "LaunchScreen__styles.launchWrapper": "LaunchScreen__styles.launchWrapper",
    width: "xh8yej3",
    height: "x5yr21d",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    position: "xixxii4",
    paddingTop: "x6vio5b",
    paddingRight: "xnwblhb",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    paddingBottom: "x11ba12",
    paddingLeft: "x1lw1rap",
    $$css: true
  }
};
var _default = exports.default = (0, _react.memo)(({
  onLaunch
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.launchWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    size: "xl",
    color: "yellow",
    inverted: true,
    style: {
      margin: "auto",
      width: 240,
      height: 120,
      flexDirection: "column"
    },
    onClick: onLaunch
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    src: "https://kosmi.io/assets/kosmihead_tinted.svg",
    className: styles.launchImg
  }), "Click here to launch"));
});