"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var _a, _b, _c, _d;
import { gql, useMutation, useQuery } from "kosmi-sdk/gql";
import { Button, Icon, Loader, Input, Settings } from "kosmi-sdk/uikit";
import React, { useState, useRef, memo } from "react";
import { withStyleHook } from "../../../sdk/styles";
import { useTracking } from "kosmi-sdk/helpers";
import { useHistory } from "react-router-dom";
import withDialogs from "../../helpers/withDialogs";
import Modal from "../AnimatedModal";
import style from "./style.css";
import PaymentSection from "./PaymentSection";
import platform from "platform";
import { currentUserHook } from "../../helpers/withCurrentUser";
import LoginWall from "../LoginWall";
const isIOS = ((_b = (_a = platform) == null ? void 0 : _a.os) == null ? void 0 : _b.family) === "iOS";
const isMacOS = ((_d = (_c = platform) == null ? void 0 : _c.os) == null ? void 0 : _d.family) === "OS X";
const isCapacitor = location.href.startsWith("capacitor");
const hidePremium = (isIOS || isMacOS) && isCapacitor;
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  RedditIcon,
  RedditShareButton
} from "react-share";
const UPDATE_PAYMENT_METHOD = gql(`
  mutation UpdatePaymentMethod($paymentMethodId: String!) {
    updateStripePaymentMethod(paymentMethodId: $paymentMethodId) {
      ok
    }
  }
`);
const GET_PRODUCT = gql(`
  query GetSubscriptionProduct {
    getStripeSubscriptionProduct {
      stripePriceId
      usdAmount
      stripePublishableKey
    }
  }
`);
const GET_SUBSCRIPTION = gql(`
  query GetSubscription {
    currentStripeSubscription {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
    currentUser {
      id
      connectionId
      user {
        id
        isSubscribed
      }
    }
  }
`);
const CANCEL_SUBSCRIPTION = gql(`
  mutation ReactivateSubscription2 {
    cancelStripeSubscription {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
  }
`);
const REACTIVATE_SUBSCRIPTION = gql(`
  mutation ReactivateSubscription {
    reactivateStripeSubscription {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
  }
`);
const SIGNUP_INVITES = gql(`
  query SignupInvites {
    signupInvites {
      confirmed
      used
      user {
        id
        displayName
        avatarUrl
      }
    }
    currentUser {
      id
      inviteCode
      user {
        id
      }
    }
  }
`);
export const InviteModal = ({ trigger }) => {
  var _a2;
  const { data } = useQuery(SIGNUP_INVITES);
  const signupInvites = (data == null ? void 0 : data.signupInvites) || [];
  const inviteCode = ((_a2 = data == null ? void 0 : data.currentUser) == null ? void 0 : _a2.inviteCode) || "";
  const styles = withStyleHook(style);
  const [copySuccess, setCopySuccess] = useState(false);
  const inputRef = useRef(null);
  const url = `https://app.kosmi.io/signup/${inviteCode}`;
  const nInvites = signupInvites == null ? void 0 : signupInvites.filter(
    (i) => !i.used && i.confirmed
  ).length;
  if (hidePremium) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(Modal, { size: "tiny", closeIcon: true, trigger }, /* @__PURE__ */ React.createElement(Modal.Header, null, "Invite Friends"), /* @__PURE__ */ React.createElement(Modal.Content, { style: { display: "flex", justifyContent: "center" } }, /* @__PURE__ */ React.createElement("div", { className: styles.inviteMsg }, "Invite 3 friends to sign up and you will get a free month of Kosmi Premium!"), /* @__PURE__ */ React.createElement("div", { className: styles.inviteMsg }, "All you need to do is share the link below:"), /* @__PURE__ */ React.createElement("div", { className: styles.inviteLinkWrapper }, /* @__PURE__ */ React.createElement(
    Input,
    {
      ref: inputRef,
      readOnly: true,
      value: copySuccess ? "Copied!" : url
    }
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      disabled: copySuccess,
      onClick: () => __async(void 0, null, function* () {
        var _a3, _b2;
        (_a3 = inputRef.current) == null ? void 0 : _a3.select();
        if ((_b2 = inputRef.current) == null ? void 0 : _b2.setSelectionRange)
          inputRef == null ? void 0 : inputRef.current.setSelectionRange(0, 99999);
        document.execCommand("copy");
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 500);
      }),
      color: "blue"
    },
    /* @__PURE__ */ React.createElement(Icon, { name: "copy" }),
    "Copy link"
  )), /* @__PURE__ */ React.createElement("div", { className: styles.shareButtons }, /* @__PURE__ */ React.createElement(FacebookShareButton, { url }, /* @__PURE__ */ React.createElement(FacebookIcon, { size: "40", round: true })), /* @__PURE__ */ React.createElement(TwitterShareButton, { url }, /* @__PURE__ */ React.createElement(TwitterIcon, { size: "40", round: true })), /* @__PURE__ */ React.createElement(RedditShareButton, { url }, /* @__PURE__ */ React.createElement(RedditIcon, { size: "40", round: true })), /* @__PURE__ */ React.createElement(WhatsappShareButton, { url }, /* @__PURE__ */ React.createElement(WhatsappIcon, { size: "40", round: true }))), /* @__PURE__ */ React.createElement("table", { className: styles.invitees }, /* @__PURE__ */ React.createElement("thead", null, /* @__PURE__ */ React.createElement("th", null, /* @__PURE__ */ React.createElement("td", null, "Name")), /* @__PURE__ */ React.createElement("th", null, /* @__PURE__ */ React.createElement("td", null, "Confirmed")), /* @__PURE__ */ React.createElement("th", null, nInvites, " / 3")), /* @__PURE__ */ React.createElement("tbody", null, signupInvites.map((invite, i) => /* @__PURE__ */ React.createElement("tr", { key: i, className: invite.used ? styles.used : void 0 }, /* @__PURE__ */ React.createElement("td", null, invite.user.displayName || invite.user.username), /* @__PURE__ */ React.createElement("td", null, !invite.used && invite.confirmed ? "\u2705" : "\u274C"), /* @__PURE__ */ React.createElement("td", null)))))));
};
export const Diamond = memo(
  ({
    style: style2,
    className
  }) => {
    return /* @__PURE__ */ React.createElement(Icon, { className, style: style2, name: "kosmi diamond" });
  }
);
const BuySubscriptionScreen = withDialogs(
  ({
    source,
    straightToPayment
  }) => {
    const styles = withStyleHook(style);
    const buyBtnRef = useRef(null);
    const { data } = useQuery(GET_PRODUCT);
    const { usdAmount } = (data == null ? void 0 : data.getStripeSubscriptionProduct) || {};
    const currentUser = currentUserHook();
    const [hasNavigated, setHasNavigated] = useState(false);
    if (hidePremium) {
      return null;
    }
    return /* @__PURE__ */ React.createElement(Settings.Container, { name: "See Perks" }, /* @__PURE__ */ React.createElement("div", { className: styles.screen }, /* @__PURE__ */ React.createElement("div", { className: styles.subscriptionHero }, /* @__PURE__ */ React.createElement("div", { className: styles.info }, "For only $", usdAmount, " a month you get:"), /* @__PURE__ */ React.createElement("ul", { className: styles.perkList }, /* @__PURE__ */ React.createElement("li", null, "\u{1F310} Unlimited Virtual Browsing"), /* @__PURE__ */ React.createElement("li", null, "\u{1F4FA} 1080p & 4K Screensharing"), /* @__PURE__ */ React.createElement("li", null, "\u{1F3A8} Custom Themes and Color Schemes"), /* @__PURE__ */ React.createElement("li", null, "\u{1F3A9} Exclusive access to the Kosmi VIP Lounge"), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(
      Diamond,
      {
        style: {
          fontSize: 24,
          width: 22,
          height: 22,
          marginRight: 8,
          marginLeft: 0,
          display: "inline-block"
        }
      }
    ), "Extra surprise for the first 1000 members!"), /* @__PURE__ */ React.createElement("li", null, "\u{1F389} Many more upcoming features"), /* @__PURE__ */ React.createElement("li", null, "\u2764\uFE0F Help Kosmi improve and grow"))), /* @__PURE__ */ React.createElement(LoginWall, null, (showLoginModal) => /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "yellow",
        style: { margin: "auto" },
        ref: buyBtnRef,
        onClick: showLoginModal
      },
      "Lets do it!"
    )), /* @__PURE__ */ React.createElement(
      InviteModal,
      {
        trigger: /* @__PURE__ */ React.createElement("div", { className: styles.freeOption }, "Money tight right now? Get Free kosmi Premium by", /* @__PURE__ */ React.createElement("div", { className: styles.emphasis }, "inviting friends."))
      }
    )), !hidePremium && !(currentUser == null ? void 0 : currentUser.isAnonymous) && /* @__PURE__ */ React.createElement(
      PaymentSection,
      {
        trigger: hasNavigated ? buyBtnRef : straightToPayment || buyBtnRef,
        source,
        checkout: true,
        onBack: () => {
          setHasNavigated(true);
        }
      }
    ));
  }
);
export const PremiumScreen = ({
  dark,
  source,
  straightToPayment
}) => {
  const { data: subscriptionData, loading } = useQuery(GET_SUBSCRIPTION, {});
  if (loading)
    return /* @__PURE__ */ React.createElement(Loader, null);
  const { status } = (subscriptionData == null ? void 0 : subscriptionData.currentStripeSubscription) || {};
  if (status)
    return /* @__PURE__ */ React.createElement(PremiumSettings, { source: "", dark });
  return /* @__PURE__ */ React.createElement(
    BuySubscriptionScreen,
    {
      source,
      straightToPayment
    }
  );
};
const PremiumSettings = withDialogs(({ confirm, dark }) => {
  const history = useHistory();
  const styles = withStyleHook(style);
  const [_paymentMethod, _setPaymentMethod] = useState(null);
  const { data: subscriptionData, loading } = useQuery(GET_SUBSCRIPTION, {});
  const [updateStripePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD);
  const [cancelStripeSubscription] = useMutation(CANCEL_SUBSCRIPTION);
  const [reactivateStripeSubscription] = useMutation(REACTIVATE_SUBSCRIPTION);
  const { cancelAtPeriodEnd, periodEnds, status } = (subscriptionData == null ? void 0 : subscriptionData.currentStripeSubscription) || {};
  if (loading)
    return /* @__PURE__ */ React.createElement(Loader, null);
  if (status !== "active" && status !== "trialing") {
    return /* @__PURE__ */ React.createElement(Loader, { size: "massive", active: true, content: "Processing payment..." });
  }
  if (hidePremium) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(Settings.Container, { name: "Kosmi Premium", dark }, /* @__PURE__ */ React.createElement("div", { className: styles.subscription }, /* @__PURE__ */ React.createElement("div", null, "You are subscribed!"), cancelAtPeriodEnd ? /* @__PURE__ */ React.createElement("div", null, "Cancels on ", periodEnds) : /* @__PURE__ */ React.createElement("div", null, "Renews on ", periodEnds)), /* @__PURE__ */ React.createElement(
    Settings.Button,
    {
      name: "Join VIP Room",
      icon: "star",
      onClick: () => {
        history.push("/room/vip");
      }
    }
  ), cancelAtPeriodEnd ? /* @__PURE__ */ React.createElement(
    Settings.Button,
    {
      color: "success",
      onClick: () => __async(void 0, null, function* () {
        if (yield confirm("Resubscribe?")) {
          reactivateStripeSubscription();
        }
      }),
      name: "Reactivate"
    }
  ) : /* @__PURE__ */ React.createElement(React.Fragment, null, !hidePremium && /* @__PURE__ */ React.createElement(
    PaymentSection,
    {
      toggle: true,
      source: "PremiumSettings",
      onPaymentMethod: (pm) => __async(void 0, null, function* () {
        yield updateStripePaymentMethod({
          variables: { paymentMethodId: pm.id }
        });
      })
    }
  ), /* @__PURE__ */ React.createElement(
    Settings.Button,
    {
      color: "danger",
      icon: "close",
      onClick: () => __async(void 0, null, function* () {
        if (yield confirm("Cancel subscription?")) {
          cancelStripeSubscription();
        }
      }),
      name: "Cancel Subscription"
    }
  )));
});
export const PaywallMessage = ({
  message,
  source
}) => {
  const styles = withStyleHook(style);
  if (hidePremium) {
    return null;
  }
  return /* @__PURE__ */ React.createElement("div", { className: styles.paywall }, /* @__PURE__ */ React.createElement("div", null, message), /* @__PURE__ */ React.createElement(
    PremiumModal,
    {
      trigger: /* @__PURE__ */ React.createElement(Button, { size: "xl", color: "red", style: { width: 200, fontSize: 24 } }, /* @__PURE__ */ React.createElement(
        Diamond,
        {
          style: {
            width: 24,
            height: 24
          }
        }
      ), "Subscribe"),
      source,
      straightToPayment: true
    }
  ));
};
export const PremiumModal = withDialogs(
  ({ trigger, source, straightToPayment }) => {
    const track = useTracking();
    const [opened, setOpened] = useState(false);
    if (hidePremium) {
      return null;
    }
    return /* @__PURE__ */ React.createElement(
      Modal,
      {
        large: true,
        closeIcon: true,
        preventClosing: true,
        trigger,
        onOpen: () => {
          track("Premium", "OpenPremiumModal", "Open Premium Modal");
          setOpened(true);
        }
      },
      /* @__PURE__ */ React.createElement(Modal.Header, null, /* @__PURE__ */ React.createElement(Diamond, { style: { fontSize: 24 } }), "Kosmi Premium"),
      /* @__PURE__ */ React.createElement(Modal.Content, null, opened && /* @__PURE__ */ React.createElement(
        PremiumScreen,
        {
          source,
          dark: true,
          straightToPayment
        }
      ))
    );
  }
);
export default PremiumModal;
