"use strict";
import { Button, Icon } from "kosmi-sdk/uikit";
import React from "react";
import AudioEngine from "../../../../core/services/AVEngine";
import { withStyleHook } from "../../../sdk/styles";
import { useTracking } from "../../helpers/withTracking";
import Modal from "../AnimatedModal";
import style from "./style.css";
const ScreenCaptureReview = ({ onDownload }) => {
  const styles = withStyleHook(style);
  const downloadVideo = () => {
    const datenow = Date.now();
    const fileName = `kosmi-recording-${datenow}.webm`;
    const downloadLink = document.createElement("a");
    downloadLink.href = AudioEngine.getScreenRecordingPreviewUrl();
    downloadLink.setAttribute("download", fileName);
    downloadLink.setAttribute("name", fileName);
    downloadLink.click();
    onDownload();
  };
  return /* @__PURE__ */ React.createElement("div", { className: styles.screenRecorderModal }, /* @__PURE__ */ React.createElement(
    "video",
    {
      controls: true,
      className: styles.videoPreview,
      src: AudioEngine.getScreenRecordingPreviewUrl()
    }
  ), /* @__PURE__ */ React.createElement(Button, { fluid: true, color: "yellow", onClick: downloadVideo }, /* @__PURE__ */ React.createElement(Icon, { name: "download" }), "Download video"));
};
export const ScreenCaptureReviewModal = ({
  onClose,
  open,
  onRecordingDownloaded
}) => {
  const track = useTracking();
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      size: "small",
      closeIcon: true,
      open,
      onOpen: () => {
        AudioEngine.stopRecordingDisplayMediaStream();
        track(
          "UI",
          "ScreenCaptureReviewModalOpened",
          "Screen capture review modal opened"
        );
      },
      onClose: () => {
        onClose();
      }
    },
    /* @__PURE__ */ React.createElement(Modal.Header, { style: { display: "flex" } }, "Finished screen recording"),
    /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(ScreenCaptureReview, { onDownload: onRecordingDownloaded }))
  );
};
export default ScreenCaptureReviewModal;
