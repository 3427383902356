"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation } from "kosmi-sdk/gql";
import { useIsAdmin } from "../../withIsAdmin";
import { Button, Form, Icon, Transition, TabView } from "kosmi-sdk/uikit";
import { AnimatedModal as Modal } from "kosmi-sdk/widgets";
import React, { memo, useCallback, useContext, useState } from "react";
import { withStyleHook } from "../../../../sdk/styles";
import { useMediaPlayerStateCached } from "../../hooks/useMediaPlayerData";
import RoomContext from "../../RoomContext";
import RoomUIContext from "../../RoomUIContext";
import { useRoomId } from "../../withRoomId";
import Player from "../Player";
import Members from "./components/Members";
import Messagebox from "./components/Messagebox";
import Messages from "./components/Messages";
import RoomToolbar from "./components/RoomToolbar";
import { useMessagesCached } from "../../hooks/useMessageData";
import style from "./style.css";
import { useIsMobile } from "../../../helpers/withMobileDetection";
import { useRoomStateCached } from "../../hooks/useRoomData";
import InviteBackModal from "../InviteBackModal";
import { WebcamButton, MicrophoneButton } from "./components/RoomToolbar";
const SEND_ANNOUNCEMENT = gql(`
  mutation SendAnnouncement($title: String!, $text: String!) {
    sendAnnouncement(title: $title, text: $text) {
      ok
    }
  }
`);
const DELETE_MESSAGE = gql(`
  mutation DeleteMessage($roomId: String!, $messageId: String!) {
    deleteMessage(roomId: $roomId, messageId: $messageId) {
      ok
    }
  }
`);
const DELETE_MESSAGES_BY_USER = gql(`
  mutation DeleteMessagesByUser($roomId: String!, $userId: String!) {
    deleteAllMessagesByUser(roomId: $roomId, userId: $userId) {
      ok
    }
  }
`);
const SEND_MESSAGE = gql(`
  mutation SendMessage2($roomId: String!, $body: String!, $channelId: String) {
    sendMessage(roomId: $roomId, body: $body, channelId: $channelId) {
      ok
    }
  }
`);
const fixMessages = (messages) => (messages == null ? void 0 : messages.map((m) => ({
  id: (m == null ? void 0 : m.id) || "",
  user: (m == null ? void 0 : m.user) || {
    id: "",
    displayName: "",
    avatarUrl: "",
    isSubscribed: false
  },
  body: (m == null ? void 0 : m.body) || "",
  time: (m == null ? void 0 : m.time) || 0
}))) || [];
const MessagesWithData = () => {
  var _a;
  const roomId = useRoomId();
  const { data, nextPage, loading } = useMessagesCached(roomId, "general");
  const [deleteAllMessagesByUser] = useMutation(DELETE_MESSAGES_BY_USER);
  const [deleteMessage] = useMutation(DELETE_MESSAGE);
  const messages = (_a = data == null ? void 0 : data.chatArchive) == null ? void 0 : _a.results;
  return /* @__PURE__ */ React.createElement(
    Messages,
    {
      messages: fixMessages(messages || []),
      noImages: roomId === "lobby",
      nextPage,
      loading,
      deleteAllMessagesByUser: (userId) => deleteAllMessagesByUser({ variables: { roomId, userId } }),
      deleteMessage: (messageId) => deleteMessage({ variables: { roomId, messageId } })
    }
  );
};
const AdminMenuModal = ({ trigger }) => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [sendAnnouncement] = useMutation(SEND_ANNOUNCEMENT);
  return /* @__PURE__ */ React.createElement(Modal, { trigger, closeIcon: true }, /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(
    Form,
    {
      style: { margin: "20px" },
      onSubmit: (e) => __async(void 0, null, function* () {
        e.preventDefault();
        setTitle("");
        setText("");
        yield sendAnnouncement({
          variables: {
            title,
            text
          }
        });
      })
    },
    /* @__PURE__ */ React.createElement(Form.Field, null, /* @__PURE__ */ React.createElement("label", null, "Announcement"), /* @__PURE__ */ React.createElement(
      Form.Input,
      {
        onChange: (e) => setTitle(e.target.value),
        value: title
      }
    ), /* @__PURE__ */ React.createElement(
      Form.TextArea,
      {
        onChange: (e) => setText(e.target.value),
        value: text
      }
    )),
    /* @__PURE__ */ React.createElement(
      Form.Button,
      {
        color: "yellow",
        inverted: true,
        type: "submit",
        disabled: !title || !text
      },
      "Send"
    )
  )));
};
export const MobileMessagebox = () => {
  const roomId = useRoomId();
  const [focused, setFocused] = useState(false);
  const onBlur = useCallback(() => setFocused(false), []);
  const onFocus = useCallback(() => setFocused(true), []);
  const [_sendMessage] = useMutation(SEND_MESSAGE);
  const isLobby = roomId === "lobby" || (roomId == null ? void 0 : roomId.endsWith("/lobby"));
  const sendMessage = (body) => _sendMessage({
    variables: {
      roomId,
      channelId: "general",
      body
    }
  });
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        display: "flex",
        alignItems: "center",
        maxHeight: 48,
        maxWidth: "100%"
      }
    },
    /* @__PURE__ */ React.createElement(
      Messagebox,
      {
        onFocus,
        onBlur,
        sendMessage,
        isPrivate: false
      }
    ),
    !focused && !isLobby && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      WebcamButton,
      {
        size: "xs",
        color: "white",
        inverted: true,
        style: {
          borderRadius: "50%",
          width: 40,
          minWidth: 40,
          height: 40,
          marginLeft: 4,
          marginRight: 4
        }
      }
    ), /* @__PURE__ */ React.createElement(
      MicrophoneButton,
      {
        size: "xs",
        color: "white",
        inverted: true,
        style: {
          borderRadius: "50%",
          minWidth: 40,
          width: 40,
          height: 40,
          marginLeft: 4,
          marginRight: 4
        }
      }
    ))
  );
};
export const Chat = memo(({ hide }) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        display: hide ? "none" : "flex",
        flex: 1,
        minHeight: 0,
        flexDirection: "column"
      }
    },
    /* @__PURE__ */ React.createElement(MessagesWithData, null),
    /* @__PURE__ */ React.createElement(MobileMessagebox, null)
  );
});
const ChatAndMemberlist = memo(
  ({
    isLobby,
    onTabChange,
    isNsfw
  }) => {
    const isAdmin = useIsAdmin();
    const styles = withStyleHook(style);
    const { isLandscape, isMobile } = useIsMobile();
    const roomId = useRoomId();
    const { memberCount } = useSidebarData(roomId);
    const [_sendMessage] = useMutation(SEND_MESSAGE);
    const sendMessage = (body) => _sendMessage({
      variables: {
        roomId,
        channelId: "general",
        body
      }
    });
    if (isLandscape && isMobile)
      return null;
    return /* @__PURE__ */ React.createElement("div", { className: styles.overlay }, /* @__PURE__ */ React.createElement(TabView, { onTabChange }, /* @__PURE__ */ React.createElement(
      TabView.Item,
      {
        name: /* @__PURE__ */ React.createElement(Icon, { name: "chat" }),
        style: { overflow: "hidden" }
      },
      /* @__PURE__ */ React.createElement(React.Fragment, null, isLobby && isAdmin && /* @__PURE__ */ React.createElement(
        AdminMenuModal,
        {
          trigger: /* @__PURE__ */ React.createElement(Button, { fluid: true, size: "m", color: "blue" }, /* @__PURE__ */ React.createElement(Icon, { name: "setting" }), "Admin Menu")
        }
      ), /* @__PURE__ */ React.createElement(InviteBackModal, null), /* @__PURE__ */ React.createElement(MessagesWithData, null), /* @__PURE__ */ React.createElement("div", { className: styles.messageboxWrapper }, /* @__PURE__ */ React.createElement(Messagebox, { sendMessage, isNsfw })))
    ), !isLobby && /* @__PURE__ */ React.createElement(
      TabView.Item,
      {
        name: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Icon, { name: "group" }), " ", memberCount)
      },
      /* @__PURE__ */ React.createElement("div", { className: styles.memberList }, /* @__PURE__ */ React.createElement(Members, { visible: true }))
    )));
  }
);
export const useSidebarData = (roomId) => {
  var _a, _b, _c, _d, _e;
  const { mediaPlayerState } = useMediaPlayerStateCached();
  const roomUIContext = useContext(RoomUIContext);
  const roomContext = useContext(RoomContext);
  const isLobby = roomId === "lobby" || roomId.endsWith("/lobby");
  const room = useRoomStateCached(roomId);
  const { isLandscape, isMobile } = useIsMobile();
  return {
    isLobby,
    isLandscape,
    isMobile,
    hasMediaPlayer: !roomContext.appModule || !!((_a = roomContext == null ? void 0 : roomContext.appModule) == null ? void 0 : _a.hasMediaPlayer),
    mediaPlayerUrl: mediaPlayerState == null ? void 0 : mediaPlayerState.url,
    menuIndex: roomUIContext.menuIndex,
    isNsfw: !!((_c = (_b = room == null ? void 0 : room.state) == null ? void 0 : _b.metadata) == null ? void 0 : _c.isNsfw),
    memberCount: ((_e = (_d = room == null ? void 0 : room.state) == null ? void 0 : _d.members) == null ? void 0 : _e.length) || 0
  };
};
const Sidebar = memo(({ open }) => {
  const styles = withStyleHook(style);
  const roomId = useRoomId();
  const {
    isLobby,
    isLandscape,
    isMobile,
    hasMediaPlayer,
    mediaPlayerUrl,
    isNsfw
  } = useSidebarData(roomId);
  const [player, setPlayer] = useState(null);
  return /* @__PURE__ */ React.createElement(
    Transition,
    {
      animation: "slide left",
      visible: open,
      duration: { hide: 100, show: 200 }
    },
    /* @__PURE__ */ React.createElement("div", { className: isLandscape && isMobile ? styles.hidden : styles.sidebar }, !isLobby && !isMobile && /* @__PURE__ */ React.createElement(RoomToolbar, { player }), !isLobby && /* @__PURE__ */ React.createElement(React.Fragment, null, !isMobile && !hasMediaPlayer && /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          maxHeight: "200px",
          height: mediaPlayerUrl ? "200px" : "0px",
          position: "relative",
          flex: mediaPlayerUrl ? "0 0 100%" : void 0,
          display: "flex"
        }
      },
      /* @__PURE__ */ React.createElement(Player, { onPlayer: setPlayer, isCompact: true })
    )), /* @__PURE__ */ React.createElement(ChatAndMemberlist, { isNsfw, isLobby: roomId === "lobby" }))
  );
});
export default Sidebar;
