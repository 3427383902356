"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { gql, useQuery, useSubscription } from "kosmi-sdk/gql";
import { currentUserHook } from "kosmi-sdk/helpers";
import { useEffect } from "react";
import { sendNotification } from "../../../notifications";
import { useRoomId } from "./useRoomId";
const GET_ROOM = gql(`
  query RoomRootQuery($roomId: String!) {
    room(id: $roomId) {
      id
      state {
        runningApp
        webrtcEnabled
        members {
    id
    role
    user {
      id
      username
      displayName
      avatarUrl
      countryCode
      isAnonymous
      isSubscribed
    }
        }
        metadata {
    roomName
    avatarUrl
    public
    description
    autoAcceptJoins
    requireAdminForWebcamAndMicrophone
    relayServerRegion
    promoteNewUsersToAdmin
    disableWebcamAndMicrophone
    hideSidebarForNonAdmins
    requireLogin
    isNsfw
    disableAutoplay
    iptvUrl
        }
      }
    }
    spacesState(roomId: $roomId) {
      id
      data
    }
    mediaPlayerState(roomId:$roomId) {
      id
      type
      position
      url
      paused
      live
      unixTimePositionSent
      playbackRate
      hostUser {
        id
        isAnonymous
        isConnected
        username
        avatarUrl
        countryCode
        displayName
        email
        emailConfirmed
      }
    }
  }
`);
const APP_START_SUBSCRIPTION = gql(`
  subscription StartAppSubscription($roomId: String!) {
    startApp(roomId: $roomId) {
      runningApp
    }
  }
`);
const MEMBER_LEAVES_SUBSCRIPTION = gql(`
  subscription MemberLeaves($id: String!) {
    memberLeaves(id: $id) {
id
role
user {
      id
      username
      displayName
      avatarUrl
      countryCode
      isAnonymous
      isSubscribed
email
emailConfirmed
    }
}
  }
`);
const MEMBER_JOINS_SUBSCRIPTION = gql(`
  subscription MemberJoins($id: String!) {
    memberJoins(id: $id) {
id
role
user {
      id
      username
      displayName
      avatarUrl
      countryCode
      isAnonymous
      isSubscribed
    }
}
  }
`);
const SET_ROLE_SUBSCRIPTION = gql(`
  subscription SetRole($id: String!) {
    setRole(id: $id) {
id
role
user {
      id
      username
      displayName
      avatarUrl
      countryCode
      isAnonymous
      isSubscribed
    }
}
  }
`);
const ON_METADATA_UPDATE = gql(`
  subscription MetadataUpdates($id: String!) {
    metadataUpdate(id: $id) {
    roomName
    avatarUrl
    public
    description
    autoAcceptJoins
    requireAdminForWebcamAndMicrophone
    relayServerRegion
    promoteNewUsersToAdmin
    disableWebcamAndMicrophone
    hideSidebarForNonAdmins
    requireLogin
    isNsfw
    disableAutoplay
    iptvUrl
    }
  }
`);
const ON_UPDATE_STATE = gql(`
  subscription OnSpacesStateUpdate($roomId: String!) {
    spacesUpdate(roomId: $roomId) {
      id
      data
    }
  }
`);
export const useRoomStateCached = (roomId) => {
  if (!roomId)
    return null;
  const { data } = useQuery(GET_ROOM, {
    variables: { roomId },
    fetchPolicy: "cache-only"
  });
  return data == null ? void 0 : data.room;
};
export const useSpacesStateCached = () => {
  var _a;
  const roomId = useRoomId();
  const { data } = useQuery(GET_ROOM, {
    variables: { roomId },
    fetchPolicy: "cache-only"
  });
  return (data == null ? void 0 : data.spacesState) && JSON.parse(((_a = data == null ? void 0 : data.spacesState) == null ? void 0 : _a.data) || "");
};
export default (roomId, skip) => {
  if (!roomId)
    return { data: null, loading: true };
  const currentUser = currentUserHook();
  const { data, subscribeToMore, loading } = useQuery(GET_ROOM, {
    variables: { roomId },
    skip
  });
  useSubscription(ON_UPDATE_STATE, { variables: { roomId } });
  useEffect(() => {
    if (!subscribeToMore)
      return;
    if (loading)
      return;
    if (skip)
      return;
    if (roomId == null ? void 0 : roomId.endsWith("lobby"))
      return;
    const unsubscribeToAppChanges = subscribeToMore({
      document: APP_START_SUBSCRIPTION,
      variables: { roomId },
      updateQuery: (prev, { subscriptionData }) => {
        var _a;
        const runningApp = subscriptionData && subscriptionData.data && subscriptionData.data.startApp && subscriptionData.data.startApp.runningApp;
        return __spreadProps(__spreadValues({}, prev), {
          room: __spreadProps(__spreadValues({}, prev.room), {
            state: __spreadProps(__spreadValues({}, (_a = prev == null ? void 0 : prev.room) == null ? void 0 : _a.state), {
              runningApp
            })
          })
        });
      }
    });
    const unsubscribeToRoleChanges = subscribeToMore({
      document: SET_ROLE_SUBSCRIPTION,
      variables: { id: roomId }
    });
    const unsubscribeToJoins = subscribeToMore({
      document: MEMBER_JOINS_SUBSCRIPTION,
      variables: { id: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        var _a, _b, _c, _d, _e, _f;
        const joiningMember = subscriptionData.data.memberJoins;
        const joiningUser = joiningMember == null ? void 0 : joiningMember.user;
        const members = (_b = (_a = prev == null ? void 0 : prev.room) == null ? void 0 : _a.state) == null ? void 0 : _b.members;
        if (((members == null ? void 0 : members.length) || 0) < 30) {
          if (document.hidden) {
            const d = /* @__PURE__ */ new Date();
            sendNotification(
              (joiningMember == null ? void 0 : joiningMember.id) + d.toISOString().substring(0, 16),
              ((_e = (_d = (_c = data == null ? void 0 : data.room) == null ? void 0 : _c.state) == null ? void 0 : _d.metadata) == null ? void 0 : _e.roomName) || "",
              (joiningUser == null ? void 0 : joiningUser.displayName) + " just joined"
            );
          }
        }
        return __spreadProps(__spreadValues({}, prev), {
          room: __spreadProps(__spreadValues({}, prev.room), {
            state: __spreadProps(__spreadValues({}, (_f = prev == null ? void 0 : prev.room) == null ? void 0 : _f.state), {
              members: [
                ...(members || []).filter(
                  (m) => {
                    var _a2, _b2;
                    return ((_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.id) !== ((_b2 = joiningMember == null ? void 0 : joiningMember.user) == null ? void 0 : _b2.id);
                  }
                ),
                {
                  id: joiningMember == null ? void 0 : joiningMember.id,
                  role: joiningMember == null ? void 0 : joiningMember.role,
                  user: joiningUser,
                  __typename: "Member"
                }
              ]
            })
          })
        });
      }
    });
    const unsubscribeToLeaves = subscribeToMore({
      document: MEMBER_LEAVES_SUBSCRIPTION,
      variables: { id: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        var _a, _b, _c, _d, _e;
        const leavingMember = subscriptionData.data.memberLeaves;
        const members = (_b = (_a = prev == null ? void 0 : prev.room) == null ? void 0 : _a.state) == null ? void 0 : _b.members;
        if (((_c = leavingMember == null ? void 0 : leavingMember.user) == null ? void 0 : _c.id) === (currentUser == null ? void 0 : currentUser.id)) {
          return prev;
        }
        return __spreadProps(__spreadValues({}, prev), {
          room: __spreadProps(__spreadValues({}, prev.room), {
            state: __spreadProps(__spreadValues({}, (_d = prev == null ? void 0 : prev.room) == null ? void 0 : _d.state), {
              members: (_e = members || []) == null ? void 0 : _e.filter(
                (m) => {
                  var _a2;
                  return m.user.id !== ((_a2 = leavingMember == null ? void 0 : leavingMember.user) == null ? void 0 : _a2.id);
                }
              )
            })
          })
        });
      }
    });
    const unsubscribeToMetadataUpdates = subscribeToMore({
      document: ON_METADATA_UPDATE,
      variables: { id: roomId },
      updateQuery: (prev, { subscriptionData }) => {
        var _a;
        const updatedMetadata = subscriptionData.data.metadataUpdate;
        return __spreadProps(__spreadValues({}, prev), {
          room: __spreadProps(__spreadValues({}, prev.room), {
            state: __spreadProps(__spreadValues({}, (_a = prev == null ? void 0 : prev.room) == null ? void 0 : _a.state), {
              metadata: updatedMetadata
            })
          })
        });
      }
    });
    return () => {
      unsubscribeToAppChanges();
      unsubscribeToJoins();
      unsubscribeToLeaves();
      unsubscribeToRoleChanges();
      unsubscribeToMetadataUpdates();
    };
  }, [subscribeToMore, skip, loading]);
  return { data, loading };
};
