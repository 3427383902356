"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabView = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _Menu = require("./Menu");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  tabView: {
    "TabView__styles.tabView": "TabView__styles.tabView",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    minHeight: "x2lwn1j",
    maxWidth: "x193iq5w",
    $$css: true
  },
  menu: {
    "TabView__styles.menu": "TabView__styles.menu",
    padding: "xfawy5m",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  menuItem: {
    "TabView__styles.menuItem": "TabView__styles.menuItem",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: "x2lah0s",
    flexBasis: null,
    fontWeight: "x1xlr1w8",
    alignItems: "x6s0dn4",
    width: "x3hqpx7",
    position: "x1n2onr6",
    borderRadius: "x12oqio5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    justifyContent: "xl56j7k",
    transition: "xxksqoq",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  swipableWrapper: {
    "TabView__styles.swipableWrapper": "TabView__styles.swipableWrapper",
    display: "x78zum5",
    minHeight: "x2lwn1j",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    position: "x1n2onr6",
    alignItems: "x6s0dn4",
    width: "xh8yej3",
    $$css: true
  },
  tabviewItem: {
    "TabView__styles.tabviewItem": "TabView__styles.tabviewItem",
    maxWidth: "x193iq5w",
    minHeight: "x2lwn1j",
    display: "x78zum5",
    height: "x5yr21d",
    flexDirection: "xdt5ytf",
    transform: "xmyttsv",
    transition: "x1alzeyj",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    flexShrink: null,
    flex: "x1b0bnr5",
    flexGrow: null,
    flexBasis: "x1l7klhg",
    minWidth: "xgqtt45",
    justifyContent: "x1nhvcw1",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    alignItems: "x6s0dn4",
    $$css: true
  },
  underline: {
    "TabView__styles.underline": "TabView__styles.underline",
    position: "x10l6tqk",
    bottom: "xbfrwjf",
    backgroundColor: "x14xb6xe",
    opacity: "x1iy03kw",
    transition: "xxdbxwx",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    borderRadius: "x12oqio5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    height: "x36qwtl",
    zIndex: "xhtitgo",
    $$css: true
  },
  setWidth: (percentage, width) => [{
    "TabView__styles.setWidth": "TabView__styles.setWidth",
    width: "x17fnjtu",
    left: "x101gtxs",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  }, {
    "--width": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(`${width}%`),
    "--left": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(`${percentage - width / 2}%`)
  }]
};
const TabViewItem = ({
  children,
  className: classNameProp,
  styleProps
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    styleProps,
    style: [styles.tabviewItem, classNameProp]
  }, children);
};
const Underline = ({
  percentage,
  width
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: [styles.underline, styles.setWidth(percentage, width)]
  });
};
const SwipableWrapper = ({
  children,
  index
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.swipableWrapper
  }, _react.default.Children.map(children, (c, i) => {
    var _a;
    return _react.default.cloneElement(c, {
      key: i,
      styleProps: __spreadProps(__spreadValues({}, (_a = c == null ? void 0 : c.props) == null ? void 0 : _a.styleProps), {
        transform: `translate3d(-${index * 100}%,0,0)`
      })
    });
  }));
};
const TabViewMenu = ({
  index,
  onTabChange,
  items
}) => {
  return /* @__PURE__ */_react.default.createElement(_Menu.Menu, {
    tabular: true,
    className: styles.menu
  }, /* @__PURE__ */_react.default.createElement(Underline, {
    width: 100 / items.length - 8,
    percentage: (index + 1) / items.length * 100 - 100 / items.length / 2
  }), items.map((item, i) => {
    return /* @__PURE__ */_react.default.createElement(_Menu.Menu.Item, {
      key: i,
      active: i === index,
      onClick: () => {
        onTabChange(i);
      },
      className: styles.menuItem
    }, item);
  }));
};
const TabView = exports.TabView = Object.assign((0, _react.memo)((0, _react.forwardRef)(({
  children,
  onTabChange,
  forceIndex,
  menu,
  className: classNameProp
}, ref) => {
  var _a, _b;
  menu = menu === void 0 ? true : menu;
  const childCompnents = _react.default.Children.toArray(children).filter(c => !!c);
  const [_index, setIndex] = (0, _react.useState)(0);
  const index = forceIndex !== void 0 ? forceIndex : _index;
  (0, _react.useEffect)(() => {
    onTabChange && onTabChange(index);
  }, [_index, onTabChange, forceIndex]);
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    ref,
    style: [styles.tabView, classNameProp]
  }, childCompnents.length > 1 && menu && /* @__PURE__ */_react.default.createElement(TabViewMenu, {
    index,
    items: childCompnents.map(item => {
      var _a2;
      return (_a2 = item == null ? void 0 : item.props) == null ? void 0 : _a2.name;
    }),
    onTabChange: i => setIndex(i)
  }), !((_b = (_a = childCompnents[index]) == null ? void 0 : _a.props) == null ? void 0 : _b.empty) && /* @__PURE__ */_react.default.createElement(SwipableWrapper, {
    index
  }, childCompnents));
})), {
  Item: TabViewItem,
  Menu: TabViewMenu
});