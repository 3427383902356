"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation, useQuery } from "kosmi-sdk/gql";
import { Button, Form, Loader, TextArea, UserProfile } from "kosmi-sdk/uikit";
import React, { memo, useState } from "react";
import withDialogs from "../../helpers/withDialogs";
import { useCurrentMember } from "../../Room/withCurrentMember";
import { useRoomId } from "../../Room/withRoomId";
import Modal from "../AnimatedModal";
const MEMBER_QUERY = gql(`
  query MemberProfileQuery($roomId: String!, $userId: String!) {
    member(userId: $userId, roomId: $roomId) {
      id
      role
      user {
        id
        isSubscribed
        username
        displayName
        avatarUrl
        countryCode
        isAnonymous
      }
    }
  }
`);
const INVITE_BACK_TO_ROOM = gql(`
  mutation InviteBackToRoom($roomId: String!, $userId: String!) {
    inviteMemberBackToRoom(userId: $userId, roomId: $roomId, message: "") {
      ok
    }
  }
`);
const PROMOTE_TO_ADMIN = gql(`
  mutation PromoteToAdminMutation($roomId: String!, $userId: String!) {
    promoteToAdmin(userId: $userId, roomId: $roomId) {
      ok
    }
  }
`);
const DEMOTE_TO_USER = gql(`
  mutation DemoteToUserMutation($roomId: String!, $userId: String!) {
    demoteToUser(userId: $userId, roomId: $roomId) {
      ok
    }
  }
`);
const KICK_AND_BAN = gql(`
  mutation KickAndBanMutation($roomId: String!, $userId: String!) {
    kickAndBanFromRoom(roomId: $roomId, userId: $userId) {
      ok
    }
  }
`);
const ADD_FRIEND_MUTATION = gql(`
  mutation AddFriendMutation2($username: String!) {
    addFriend(username: $username) {
      id
      friends {
        state
        user {
          id
          isSubscribed
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const REPORT_USER = gql(`
  mutation ReportUserMutation($userId: String!, $text: String!) {
    reportUser(userId: $userId, text: $text) {
      ok
    }
  }
`);
const ReportUserModal = memo(
  ({
    user,
    open,
    onClose
  }) => {
    const [text, setText] = useState("");
    const [reportUser] = useMutation(REPORT_USER);
    return /* @__PURE__ */ React.createElement(Modal, { open, size: "tiny", closeIcon: true, onClose }, /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(
      TextArea,
      {
        placeholder: "What did this user do wrong?(include room link if applies)",
        value: text,
        onChange: (e) => {
          setText(e.target.value);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        disabled: !text,
        fluid: true,
        color: "yellow",
        inverted: true,
        style: { marginTop: 10 },
        onClick: () => __async(void 0, null, function* () {
          yield reportUser({
            variables: {
              userId: user.id,
              text
            }
          });
          onClose();
        })
      },
      "Report"
    ))));
  }
);
const UserProfileModal = memo(
  ({ open, onClose, userId, confirm, alert }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
    const roomId = useRoomId();
    const currentMember = useCurrentMember();
    const [showReport, setShowReport] = useState(false);
    const { data, loading } = useQuery(MEMBER_QUERY, {
      variables: { roomId, userId },
      skip: !userId
    });
    const member = (data == null ? void 0 : data.member) || { user: { id: "" } };
    const [kickAndBanFromRoom] = useMutation(KICK_AND_BAN);
    const [demoteToUser] = useMutation(DEMOTE_TO_USER);
    const [promoteToAdmin] = useMutation(PROMOTE_TO_ADMIN);
    const [inviteBackToRoom] = useMutation(INVITE_BACK_TO_ROOM);
    const [addFriend, { loading: addFriendLoading }] = useMutation(ADD_FRIEND_MUTATION);
    const actions = [];
    if (currentMember && currentMember.role === "ADMIN" && member.id !== currentMember.id) {
      actions.push({
        color: "red",
        text: "Kick and Ban",
        icon: "ban",
        onClick: () => __async(void 0, null, function* () {
          var _a2;
          if (yield confirm(
            "Are you sure you want to kick and ban " + ((_a2 = member == null ? void 0 : member.user) == null ? void 0 : _a2.displayName)
          )) {
            yield kickAndBanFromRoom({
              variables: {
                roomId,
                userId
              }
            });
            onClose();
          }
        })
      });
    }
    if (currentMember && currentMember.role === "ADMIN" && member.role !== "ADMIN") {
      actions.push({
        color: "yellow",
        text: "Promote to Admin",
        icon: "key",
        onClick: () => __async(void 0, null, function* () {
          var _a2;
          if (yield confirm(
            "Are you sure you want to promote " + ((_a2 = member == null ? void 0 : member.user) == null ? void 0 : _a2.username) + " to Admin?"
          )) {
            promoteToAdmin({
              variables: {
                roomId,
                userId
              }
            });
          }
        })
      });
    }
    if (currentMember && currentMember.role === "ADMIN" && member.id !== currentMember.id && member.role === "ADMIN") {
      actions.push({
        color: "yellow",
        text: "Demote to User",
        icon: "level down",
        onClick: () => __async(void 0, null, function* () {
          var _a2;
          if (yield confirm(
            "Are you sure you want to demote " + ((_a2 = member == null ? void 0 : member.user) == null ? void 0 : _a2.username) + " to User?"
          )) {
            yield demoteToUser({
              variables: {
                roomId,
                userId
              }
            });
          }
        })
      });
    }
    if (currentMember && currentMember.role === "ADMIN" && !((_a = member == null ? void 0 : member.user) == null ? void 0 : _a.isAnonymous) && member.id !== currentMember.id) {
      actions.push({
        color: "yellow",
        text: "Invite back to room",
        icon: "mail",
        onClick: () => __async(void 0, null, function* () {
          yield inviteBackToRoom({
            variables: {
              roomId,
              userId
            }
          });
          alert("Invite sent!");
        })
      });
    }
    if (!((_b = member == null ? void 0 : member.user) == null ? void 0 : _b.isAnonymous) && currentMember && !((_c = currentMember == null ? void 0 : currentMember.user) == null ? void 0 : _c.isAnonymous) && member.id !== currentMember.id && ((_d = member == null ? void 0 : member.user) == null ? void 0 : _d.username) && currentMember.user.friends.map((f) => f.user.id).indexOf(member.user.id) === -1) {
      actions.push({
        color: "yellow",
        text: "Add as a friend",
        loading: addFriendLoading,
        icon: "add user",
        onClick: () => __async(void 0, null, function* () {
          var _a2, _b2;
          if (yield confirm("Add " + ((_a2 = member == null ? void 0 : member.user) == null ? void 0 : _a2.displayName) + " as a friend?")) {
            yield addFriend({
              variables: {
                username: ((_b2 = member == null ? void 0 : member.user) == null ? void 0 : _b2.username) || ""
              }
            });
          }
        })
      });
    }
    if (currentMember && currentMember.id !== member.id) {
      actions.push({
        color: "yellow",
        text: "Report User",
        icon: "warning",
        onClick: () => {
          setShowReport(true);
        }
      });
    }
    return /* @__PURE__ */ React.createElement(Modal, { size: "tiny", closeIcon: true, open, onClose }, /* @__PURE__ */ React.createElement(Modal.Content, null, loading ? /* @__PURE__ */ React.createElement(Loader, { size: "massive" }) : /* @__PURE__ */ React.createElement("div", { style: { display: "flex", justifyContent: "center" } }, /* @__PURE__ */ React.createElement(
      UserProfile,
      {
        isAdmin: member.role === "ADMIN",
        displayName: ((_e = member == null ? void 0 : member.user) == null ? void 0 : _e.displayName) || "",
        isAnonymous: ((_f = member == null ? void 0 : member.user) == null ? void 0 : _f.isAnonymous) || false,
        username: ((_g = member == null ? void 0 : member.user) == null ? void 0 : _g.username) || "",
        actions,
        avatarUrl: ((_h = member == null ? void 0 : member.user) == null ? void 0 : _h.avatarUrl) || "",
        isSubscribed: ((_i = member == null ? void 0 : member.user) == null ? void 0 : _i.isSubscribed) || false
      }
    )), currentMember && currentMember.id !== member.id && /* @__PURE__ */ React.createElement(
      ReportUserModal,
      {
        open: showReport,
        user: { id: ((_j = member == null ? void 0 : member.user) == null ? void 0 : _j.id) || "" },
        onClose: () => setShowReport(false)
      }
    )));
  }
);
export default withDialogs(UserProfileModal);
