"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useQuery } from "kosmi-sdk/gql";
import { OnboardingTooltip } from "../../../../../Widgets/OnboardingTooltip";
import {
  currentUserHook,
  useTracking,
  withDialogs
} from "kosmi-sdk/helpers";
import { useIsAdmin } from "../../../../withIsAdmin";
import { useSidebarData } from "../../index";
import {
  Button,
  Icon,
  Input,
  Popup,
  ContextMenu
} from "kosmi-sdk/uikit";
import { AnimatedModal as Modal } from "kosmi-sdk/widgets";
import platform from "platform";
import React, {
  forwardRef,
  memo,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import useWindowSize from "../../../../../../../core/hooks/useWindowSize";
import AVEngine from "../../../../../../../core/services/AVEngine";
import AppContext from "../../../../../../AppContext";
import { withStyleHook } from "../../../../../../sdk/styles";
import ScreenCaptureModal from "../../../../../Widgets/ScreenCaptureModal";
import ScreenCaptureReviewModal from "../../../../../Widgets/ScreenCaptureReviewModal";
import RoomContext from "../../../../RoomContext";
import { useRoomId } from "../../../../withRoomId";
import { useMediaPlayerActions } from "../../../Player/useMediaPlayerState";
import { useMediaPlayerStateCached } from "../../../../hooks/useMediaPlayerData";
import "../FriendSelection";
import LeaveRoomButton from "../LeaveRoomButton";
import RoomBanner from "../RoomBanner";
import SettingsModal from "../SettingsModal";
import style from "./style.css";
import { useIsMobile } from "../../../../../helpers/withMobileDetection";
import MembersModal from "../MembersModal";
import LoginWall from "../../../../../Widgets/LoginWall";
const METADATA_QUERY = gql(`
  query ToolbarMetadataQuery($roomId: String!) {
    room(id: $roomId) {
      id
      state {
        metadata {
          requireAdminForWebcamAndMicrophone
          disableWebcamAndMicrophone
        }
      }
    }
  }
`);
const textAlign = "left";
const menuButtonStyle = {
  flex: 1,
  margin: 5,
  height: 40,
  textAlign,
  boxShadow: "0 0 10px rgb(0 0 0 / 10%)",
  borderRadius: 20
};
const StopScreenRecordingHookListener = () => {
  const windowSize = useWindowSize();
  useEffect(() => AVEngine.handleWindowResized(), [windowSize]);
  return null;
};
export const RoomDropdown = withDialogs(
  memo(({ visible, onClose, trigger }) => {
    const isAdmin = useIsAdmin();
    return /* @__PURE__ */ React.createElement(ContextMenu, { trigger, open: visible, onClose }, isAdmin && /* @__PURE__ */ React.createElement(
      SettingsModal,
      {
        trigger: /* @__PURE__ */ React.createElement(LoginWall, null, /* @__PURE__ */ React.createElement(ContextMenu.Item, { text: "Room settings", icon: "setting" }))
      }
    ), /* @__PURE__ */ React.createElement(
      LeaveRoomButton,
      {
        trigger: /* @__PURE__ */ React.createElement(LoginWall, null, /* @__PURE__ */ React.createElement(ContextMenu.Item, { text: "Leave room", icon: "sign out alternate" }))
      }
    ));
  })
);
export const InviteFriendsModal = memo(
  ({ trigger: Trigger }) => {
    const inputRef = useRef(null);
    const timeoutRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const handleOpen = () => __async(void 0, null, function* () {
      return setModalOpen(true);
    });
    const handleClose = () => setModalOpen(false);
    const track = useTracking();
    const styles = withStyleHook(style);
    const currentUser = currentUserHook();
    const roomId = useRoomId();
    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);
    const inviteLink = `https://kosmi.to/${roomId}`;
    return /* @__PURE__ */ React.createElement(
      Modal,
      {
        trigger: /* @__PURE__ */ React.createElement(
          Trigger,
          {
            onClick: () => {
              handleOpen();
            }
          }
        ),
        open: modalOpen,
        closeIcon: true,
        onClose: handleClose,
        onOpen: () => {
          track(
            "UI",
            "OpenInviteFriendsModal",
            "Open invite friends modal",
            roomId
          );
        },
        className: styles.inviteModal
      },
      /* @__PURE__ */ React.createElement(Modal.Header, null, "Invite to room"),
      /* @__PURE__ */ React.createElement(
        Modal.Content,
        {
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "left"
          }
        },
        /* @__PURE__ */ React.createElement("div", null, "Share this room link for others to join:"),
        /* @__PURE__ */ React.createElement("div", { className: styles.inviteLinkWrapper }, /* @__PURE__ */ React.createElement(
          Input,
          {
            readOnly: true,
            className: styles.roomUrl,
            value: copied ? "Copied!" : inviteLink,
            ref: inputRef
          }
        ), /* @__PURE__ */ React.createElement(
          Button,
          {
            style: {
              marginLeft: 5,
              minWidth: 136
            },
            onClick: () => __async(void 0, null, function* () {
              var _a, _b, _c, _d, _e;
              if (((_b = (_a = platform) == null ? void 0 : _a.os) == null ? void 0 : _b.family) && ["Android", "iOS"].indexOf(platform.os.family) !== -1 && navigator["share"]) {
                track(
                  "UI",
                  "OpenInviteFriendsShareMenu",
                  "Open invite friends share menu"
                );
                handleClose();
                try {
                  yield navigator.share({
                    title: "Kosmi",
                    text: "Join my Kosmi room!",
                    url: inviteLink
                  });
                } catch (e) {
                  if (e.toString().includes("AbortError")) {
                    track(
                      "UI",
                      "OpenInviteFriendsShareMenuCancelled",
                      "Open invite friends share menu cancelled"
                    );
                    console.warn(e);
                  }
                }
              } else {
                if (copied) {
                  return;
                }
                (_c = inputRef == null ? void 0 : inputRef.current) == null ? void 0 : _c.select();
                if ((_d = inputRef.current) == null ? void 0 : _d.setSelectionRange)
                  (_e = inputRef.current) == null ? void 0 : _e.setSelectionRange(0, 99999);
                document.execCommand("copy");
                setCopied(true);
                timeoutRef.current = setTimeout(() => {
                  setCopied(false);
                }, 500);
                track("UI", "CopyRoomLink", "Copy room link");
              }
            }),
            color: "var(--primary-light-5)"
          },
          /* @__PURE__ */ React.createElement(Icon, { name: "copy" }),
          "Copy link"
        )),
        /* @__PURE__ */ React.createElement("div", { className: styles.socialIcons }, "Paste link in any email or messaging app", /* @__PURE__ */ React.createElement("div", { className: styles.socialIcon }, /* @__PURE__ */ React.createElement(Icon, { name: "envelope outline" })), /* @__PURE__ */ React.createElement("div", { className: styles.socialIcon }, /* @__PURE__ */ React.createElement(Icon, { name: "discord" })), /* @__PURE__ */ React.createElement("div", { className: styles.socialIcon }, /* @__PURE__ */ React.createElement(Icon, { name: "twitter" }))),
        false
      )
    );
  }
);
const useWebcamAndMicrophone = (confirm2) => {
  var _a, _b;
  const roomId = useRoomId();
  const { data } = useQuery(METADATA_QUERY, {
    variables: { roomId },
    fetchPolicy: "cache-first"
  });
  const { requireAdminForWebcamAndMicrophone, disableWebcamAndMicrophone } = ((_b = (_a = data == null ? void 0 : data.room) == null ? void 0 : _a.state) == null ? void 0 : _b.metadata) || {};
  const { isMobile } = useIsMobile();
  const currentUser = currentUserHook();
  const {
    disableWebcam,
    disableMicrophone,
    enableWebcam,
    microphoneStreams,
    webcamStreams,
    enableMicrophone
  } = useContext(RoomContext);
  const userWebcamStream = webcamStreams && currentUser && webcamStreams[currentUser.id];
  const webcamEnabled = userWebcamStream && (userWebcamStream.id === "LOADING" || userWebcamStream.getVideoTracks().length > 0);
  const userMicrophoneStream = microphoneStreams && currentUser && microphoneStreams[currentUser.id];
  const microphoneEnabled = userMicrophoneStream && (userMicrophoneStream.id === "LOADING" || userMicrophoneStream.getAudioTracks().length > 0);
  const triggerMicrophone = () => __async(void 0, null, function* () {
    yield enableMicrophone(null);
    track("UI", "EnableMicrophone", "Enable microphone");
  });
  const track = useTracking();
  const [loadingCamera, setLoadingCamera] = useState(false);
  const [loadingMicrophone, setLoadingMicrophone] = useState(false);
  const toggleMicrophone = () => __async(void 0, null, function* () {
    setLoadingMicrophone(true);
    try {
      if (microphoneEnabled) {
        yield disableMicrophone();
        track("UI", "DisableMicrophone", "Disable microphone");
      } else {
        yield triggerMicrophone();
      }
    } finally {
      setLoadingMicrophone(false);
    }
  });
  const toggleCamera = () => __async(void 0, null, function* () {
    setLoadingCamera(true);
    try {
      if (webcamEnabled) {
        track("UI", "DisableWebcam", "Disable webcam");
        yield disableWebcam();
      } else {
        track("UI", "EnableWebcam", "Enable webcam");
        yield enableWebcam(null);
        if (!microphoneEnabled) {
          if (yield confirm2(
            /* @__PURE__ */ React.createElement(React.Fragment, null, "Would you like to turn on your microphone as well?", /* @__PURE__ */ React.createElement(
              "div",
              {
                style: {
                  display: "flex",
                  margin: "30px auto"
                }
              }
            )),
            "No",
            "Yes"
          )) {
            triggerMicrophone();
          }
        }
      }
    } finally {
      setLoadingCamera(false);
    }
  });
  return {
    toggleCamera,
    toggleMicrophone,
    loadingCamera,
    loadingMicrophone,
    requireAdminForWebcamAndMicrophone,
    disableWebcamAndMicrophone,
    webcamEnabled,
    microphoneEnabled,
    isMobile
  };
};
export const WebcamButton = withDialogs(
  forwardRef(
    (_a, ref) => {
      var _b = _a, { confirm: confirm2 } = _b, btnProps = __objRest(_b, ["confirm"]);
      const {
        toggleCamera,
        loadingCamera,
        requireAdminForWebcamAndMicrophone,
        disableWebcamAndMicrophone,
        webcamEnabled
      } = useWebcamAndMicrophone(confirm2);
      const styles = withStyleHook(style);
      const isAdmin = useIsAdmin();
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
        Button,
        __spreadValues({
          ref,
          active: webcamEnabled,
          loading: loadingCamera,
          disabled: disableWebcamAndMicrophone || !isAdmin && requireAdminForWebcamAndMicrophone || false,
          onClick: toggleCamera
        }, btnProps),
        !webcamEnabled && /* @__PURE__ */ React.createElement("div", { className: styles.disabled }, "/"),
        /* @__PURE__ */ React.createElement(
          Icon,
          {
            style: {
              color: webcamEnabled ? "#32CD32" : void 0,
              fontSize: "1.4em"
            },
            name: "video camera"
          }
        )
      ));
    }
  )
);
export const MicrophoneButton = forwardRef((props, ref) => {
  const styles = withStyleHook(style);
  const isAdmin = useIsAdmin();
  const {
    toggleMicrophone,
    loadingMicrophone,
    requireAdminForWebcamAndMicrophone,
    disableWebcamAndMicrophone,
    microphoneEnabled
  } = useWebcamAndMicrophone(confirm);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Button,
    __spreadValues({
      ref,
      loading: loadingMicrophone,
      disabled: disableWebcamAndMicrophone || !isAdmin && requireAdminForWebcamAndMicrophone || false,
      active: microphoneEnabled,
      onClick: toggleMicrophone
    }, props),
    !microphoneEnabled && /* @__PURE__ */ React.createElement("div", { className: styles.disabled }, "/"),
    /* @__PURE__ */ React.createElement(
      Icon,
      {
        name: "microphone",
        style: {
          color: microphoneEnabled ? "#32CD32" : void 0,
          fontSize: "1.4em"
        }
      }
    )
  ));
});
export default withDialogs(({ player }) => {
  const [dropdownIsOpen, setdropdownIsOpen] = useState(false);
  const [recordingScreen, setRecordingScreen] = useState(false);
  const [selectingScreenRecordOptions, setSelectingScreenRecordOptions] = useState(false);
  const [showRecordingReview, setShowRecordingReview] = useState(false);
  const [showRoomUrl, setShowRoomUrl] = useState(false);
  const roomId = useRoomId();
  const isAdmin = useIsAdmin();
  const currentUser = currentUserHook();
  const { microphoneEnabled, webcamEnabled } = useWebcamAndMicrophone();
  const track = useTracking();
  const { isMobile } = useIsMobile();
  const styles = withStyleHook(style);
  const { stopVideo } = useMediaPlayerActions();
  const { mediaPlayerState } = useMediaPlayerStateCached();
  const { appModule } = useContext(RoomContext);
  const appContext = useContext(AppContext);
  const closeDropdown = () => setdropdownIsOpen(false);
  const openDropdown = () => setdropdownIsOpen(true);
  const toggleDropdown = () => {
    if (dropdownIsOpen) {
      closeDropdown();
    } else {
      openDropdown();
    }
  };
  const handleRecordRoomClick = () => {
    if (recordingScreen) {
      track("UI", "EndScreenRecord", "End screen record");
      setRecordingScreen(false);
      setShowRecordingReview(true);
    } else {
      setSelectingScreenRecordOptions(true);
      track("UI", "EnableScreenRecord", "Enable screen record");
    }
  };
  const mediaPlayerUrl = mediaPlayerState.url;
  const inviteLink = `https://kosmi.to/${roomId}`;
  const { memberCount } = useSidebarData(roomId);
  return /* @__PURE__ */ React.createElement("div", { id: styles.roomToolbar }, recordingScreen && /* @__PURE__ */ React.createElement(RoomBanner, { showRoomUrl }), /* @__PURE__ */ React.createElement("div", { id: styles.roomMenuHeader }, /* @__PURE__ */ React.createElement("div", { className: styles.roomMenuTitle }, "Room menu"), /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        height: "40px",
        flex: 1,
        display: "flex",
        justifyContent: "end",
        position: "relative",
        margin: 0
      }
    },
    recordingScreen && !isMobile && /* @__PURE__ */ React.createElement(
      Popup,
      {
        disabled: isMobile,
        content: recordingScreen ? "End recording" : "Record tab",
        trigger: /* @__PURE__ */ React.createElement(
          Button,
          {
            circular: true,
            style: {
              padding: 0,
              width: 30,
              height: 30
            },
            color: "blue",
            onClick: handleRecordRoomClick
          },
          /* @__PURE__ */ React.createElement(
            Icon,
            {
              className: recordingScreen ? styles.recordActive : "",
              name: "circle",
              style: {
                color: "red"
              }
            }
          )
        )
      }
    ),
    !isMobile && /* @__PURE__ */ React.createElement(
      RoomDropdown,
      {
        trigger: /* @__PURE__ */ React.createElement(
          OnboardingTooltip,
          {
            open: !isMobile && appContext.tutorial.roomId === roomId && appContext.tutorial.step === 2,
            content: "You can click here to access the room settings.",
            onClick: () => {
              appContext.setTutorialStep(3);
            },
            onSkip: () => {
              appContext.setTutorialStep(4);
              track("UI", "OnboardingFinished", "Onboarding finished");
            },
            step: 2,
            totalSteps: 3,
            trigger: /* @__PURE__ */ React.createElement(
              Button,
              {
                color: "blue",
                style: {
                  height: 30
                },
                onClick: toggleDropdown
              },
              /* @__PURE__ */ React.createElement(
                Icon,
                {
                  name: "ellipsis horizontal",
                  style: {
                    width: 40,
                    fontSize: 20,
                    margin: 0
                  }
                }
              )
            )
          }
        ),
        styles,
        visible: dropdownIsOpen,
        onClose: closeDropdown,
        recordScreenButton: /* @__PURE__ */ React.createElement(
          Button,
          {
            color: "blue",
            style: { width: "100%" },
            onClick: handleRecordRoomClick
          },
          /* @__PURE__ */ React.createElement(
            Icon,
            {
              className: recordingScreen ? styles.recordActive : "",
              name: "circle",
              style: {
                color: "red"
              }
            }
          ),
          recordingScreen ? "Stop recording" : "Record room"
        )
      }
    )
  )), /* @__PURE__ */ React.createElement(
    OnboardingTooltip,
    {
      position: "left center",
      onClick: () => {
        appContext.setTutorialStep(4);
        track("UI", "OnboardingFinished", "Onboarding finished");
      },
      onSkip: () => appContext.setTutorialStep(4),
      step: 3,
      totalSteps: 3,
      open: !isMobile && appContext.tutorial.roomId === roomId && appContext.tutorial.step === 3,
      content: "You can invite friends, turn on camera and mic here",
      trigger: /* @__PURE__ */ React.createElement("div", { className: styles.toolbarWrapper }, !isMobile && /* @__PURE__ */ React.createElement(
        InviteFriendsModal,
        {
          roomId,
          currentUser,
          trigger: ({ onClick }) => /* @__PURE__ */ React.createElement(
            Button,
            {
              onClick,
              color: "var(--primary-light-5)",
              style: {
                marginTop: 10,
                marginBottom: 10,
                marginLeft: "auto",
                marginRight: "auto",
                width: 230,
                height: 40,
                fontSize: 15
              }
            },
            /* @__PURE__ */ React.createElement(
              Icon,
              {
                name: "add user",
                style: { marginRight: "10px", fontSize: "20px" }
              }
            ),
            "Invite friends"
          )
        }
      ), /* @__PURE__ */ React.createElement("div", { className: styles.communicationToolbar }, /* @__PURE__ */ React.createElement(
        ScreenCaptureModal,
        {
          open: selectingScreenRecordOptions,
          onClose: () => setSelectingScreenRecordOptions(false),
          onRecordingStarted: (showRoomUrl2) => {
            setRecordingScreen(true);
            setShowRoomUrl(showRoomUrl2);
          },
          onRecordingCancelled: () => {
            setShowRecordingReview(true);
            setRecordingScreen(false);
            track(
              "UI",
              "ScreenRecordingCancelled",
              "Screen recording cancelled"
            );
          },
          onSelectingTab: () => {
            setSelectingScreenRecordOptions(false);
          }
        }
      ), /* @__PURE__ */ React.createElement(
        ScreenCaptureReviewModal,
        {
          open: showRecordingReview,
          onClose: () => setShowRecordingReview(false),
          onRecordingDownloaded: () => {
            track(
              "UI",
              "ScreenRecordingDownlaoded",
              "Screen recording downloaded"
            );
          }
        }
      ), isMobile && /* @__PURE__ */ React.createElement(
        MembersModal,
        {
          trigger: /* @__PURE__ */ React.createElement(Button, { style: menuButtonStyle, size: "m", color: "blue" }, memberCount, /* @__PURE__ */ React.createElement(
            Icon,
            {
              style: { marginLeft: 10, fontSize: "1.4em" },
              name: "user group"
            }
          ))
        }
      ), isMobile && /* @__PURE__ */ React.createElement(
        InviteFriendsModal,
        {
          roomId,
          trigger: ({ onClick }) => {
            var _a, _b;
            return /* @__PURE__ */ React.createElement(
              Button,
              {
                style: menuButtonStyle,
                className: styles.mobileInviteFriends,
                color: "blue",
                onClick: ((_b = (_a = platform) == null ? void 0 : _a.os) == null ? void 0 : _b.family) && ["Android", "iOS"].indexOf(platform.os.family) !== -1 && navigator["share"] ? () => __async(void 0, null, function* () {
                  yield navigator.share({
                    title: "Kosmi",
                    text: "Join my Kosmi room!",
                    url: inviteLink
                  });
                  track(
                    "UI",
                    "MobileShareLinkClicked",
                    "Mobile Share Link Clicked",
                    roomId
                  );
                }) : onClick
              },
              /* @__PURE__ */ React.createElement(
                Icon,
                {
                  style: {
                    fontSize: "1.4em"
                  },
                  name: "add user"
                }
              )
            );
          }
        }
      ), /* @__PURE__ */ React.createElement(
        Popup,
        {
          disabled: isMobile,
          position: "bottom center",
          content: webcamEnabled ? "Disable camera" : "Enable camera",
          trigger: /* @__PURE__ */ React.createElement(WebcamButton, { size: "m", color: "blue", style: menuButtonStyle })
        }
      ), /* @__PURE__ */ React.createElement(
        Popup,
        {
          disabled: isMobile,
          position: "bottom center",
          content: microphoneEnabled ? "Disable microphone" : "Enable microphone",
          trigger: /* @__PURE__ */ React.createElement(
            MicrophoneButton,
            {
              size: "m",
              color: "blue",
              style: menuButtonStyle
            }
          )
        }
      ), /* @__PURE__ */ React.createElement(React.Fragment, null, appModule && appModule.name !== "Virtual room" && /* @__PURE__ */ React.createElement(
        Popup,
        {
          content: mediaPlayerUrl ? "Stop music" : "Load Media",
          disabled: isMobile,
          position: "bottom center",
          trigger: /* @__PURE__ */ React.createElement(
            Button,
            {
              style: menuButtonStyle,
              color: "blue",
              className: styles.musicButton,
              disabled: !isAdmin || !appModule || appModule.name === "Virtual room",
              onClick: () => {
                if (mediaPlayerUrl) {
                  track(
                    "UI",
                    "StopVideoFromRoomToolbar",
                    "Stop video from room toolbar"
                  );
                  stopVideo();
                } else {
                  track(
                    "UI",
                    "OpenLoadVideoModalFromRoomToolbar",
                    "Open load video modal from room toolbar"
                  );
                  player == null ? void 0 : player.loadMedia();
                }
              }
            },
            /* @__PURE__ */ React.createElement(
              Icon,
              {
                name: mediaPlayerUrl ? "stop circle" : "kosmi mediabutton",
                style: {
                  fontSize: "1.4em"
                }
              }
            )
          )
        }
      ))))
    }
  ), /* @__PURE__ */ React.createElement(StopScreenRecordingHookListener, null));
});
