"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _helpers = require("kosmi-sdk/helpers");
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _RoomContext = _interopRequireDefault(require("../contexts/RoomContext"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var _default = exports.default = (0, _react.memo)(({
  userId,
  className,
  style
}) => {
  const {
    webcamStreams
  } = _react.default.useContext(_RoomContext.default);
  const currentUser = (0, _helpers.currentUserHook)();
  const videoRef = _react.default.useRef(null);
  let {
    className: classNameProp
  } = stylex.props(className instanceof Object && className);
  if (typeof className === "string") {
    classNameProp = className;
  }
  const classNames = currentUser.id === userId ? [classNameProp, "mirror"] : [classNameProp];
  const stream = webcamStreams && webcamStreams[userId];
  (0, _react.useEffect)(() => {
    if (!stream) return;
    if (stream && stream.id === "LOADING") {
      return;
    }
    const video = videoRef.current;
    if (video) {
      video.srcObject = stream;
      video.onloadedmetadata = () => {
        video.muted = true;
        try {
          video.play();
        } catch (e) {
          console.warn(e);
        }
      };
    }
  }, [stream == null ? void 0 : stream.id]);
  if (stream && stream.id === "LOADING") {
    return /* @__PURE__ */_react.default.createElement(_uikit.Loader, {
      active: true
    });
  }
  return stream ? /* @__PURE__ */_react.default.createElement("video", {
    playsInline: true,
    disableRemotePlayback: true,
    className: classNames.filter(c => c).join(" "),
    style: __spreadProps(__spreadValues({}, style), {
      transform: "rotateY(180deg)"
    }),
    ref: videoRef,
    muted: true
  }) : null;
});