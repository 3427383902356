"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContextMenu = void 0;
var _react = _interopRequireWildcard(require("react"));
var _Transition = require("../Transition");
var _Icon = require("../Icon");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _helpers = require("../helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  menuWrapper: {
    "index__styles.menuWrapper": "index__styles.menuWrapper",
    display: "x78zum5",
    position: "x1n2onr6",
    pointerEvents: "x71s49j",
    $$css: true
  },
  menu: {
    "index__styles.menu": "index__styles.menu",
    position: "x10l6tqk",
    backgroundColor: "x18zb6r9",
    backdropFilter: "x17nw0yu",
    WebkitBackdropFilter: "x1tovznt",
    right: "x3m8u43",
    left: "xu96u03",
    zIndex: "x1n327nk",
    width: "xeq5yr9",
    flexDirection: "xdt5ytf",
    boxShadow: "xvxy23r",
    display: "x78zum5",
    minWidth: "xgqtt45",
    borderWidth: "xmkeg23",
    borderStyle: "x1y0btm7",
    borderColor: "xnu7srt",
    borderRadius: "x1sxf85j",
    overflow: "x1rea2x4",
    $$css: true
  },
  fluid: {
    "index__styles.fluid": "index__styles.fluid",
    width: "xh8yej3",
    minWidth: "xgqtt45",
    maxWidth: "x193iq5w",
    $$css: true
  },
  slideUp: {
    "index__styles.slideUp": "index__styles.slideUp",
    bottom: "x10w3d4m",
    $$css: true
  },
  slideDown: {
    "index__styles.slideDown": "index__styles.slideDown",
    top: "xdsb8wn",
    $$css: true
  },
  center: {
    "index__styles.center": "index__styles.center",
    margin: "x1bpp3o7",
    marginLeft: null,
    marginRight: null,
    $$css: true
  },
  right: {
    "index__styles.right": "index__styles.right",
    marginRight: "xack27t",
    marginLeft: "xj3b58b",
    $$css: true
  },
  left: {
    "index__styles.left": "index__styles.left",
    marginLeft: "x8x9d4c",
    marginRight: "x1yf7rl7",
    $$css: true
  }
};
const ContextMenuItem = (0, _react.memo)((0, _react.forwardRef)(({
  onClick,
  icon,
  text
}, ref) => {
  const {
    className
  } = {
    className: "index__styles.menuItem x1n2onr6 xif65rj x11jfisy xuxw1ft x7z7khe x1igx7t4 x172e5tb x78zum5 x6s0dn4 xgqtt45 x15saapg x1byiabj xn8cnpx x16t5keo x1j37zrf x1277o0a xn6vc48"
  };
  return /* @__PURE__ */_react.default.createElement("div", {
    ref,
    className,
    onClick
  }, icon && (typeof icon === "string" ? /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    name: icon
  }) : icon), text);
}));
const ContextMenu = exports.ContextMenu = Object.assign((0, _react.memo)((0, _react.forwardRef)(({
  open,
  onClose,
  trigger,
  children,
  direction,
  position,
  onOpen,
  fluid,
  style,
  className: classNameProp
}, ref) => {
  const containerRef = (0, _react.useRef)(null);
  const btnRef = (0, _react.useRef)(null);
  const {
    className
  } = stylex.props(styles.menu, direction === "up" ? styles.slideUp : styles.slideDown, position === "right" && styles.right, (!position || position === "left") && styles.left, position === "center" && styles.center, fluid && styles.fluid);
  const {
    className: wrapperClassName
  } = stylex.props(styles.menuWrapper, classNameProp instanceof Object && classNameProp);
  (0, _helpers.useOnClickOutside)([containerRef, "current" in trigger ? trigger : btnRef], onClose || (() => {}));
  (0, _react.useEffect)(() => {
    if (onOpen && open) {
      onOpen();
    }
  }, [open]);
  (0, _react.useImperativeHandle)(ref, () => containerRef.current, [containerRef]);
  return /* @__PURE__ */_react.default.createElement("div", {
    className: wrapperClassName + (typeof className === "string" ? " " + classNameProp : "")
  }, "current" in trigger ? null : _react.default.cloneElement(trigger, {
    ref: btnRef
  }), /* @__PURE__ */_react.default.createElement(_Transition.Transition, {
    duration: {
      hide: 100,
      show: 100
    },
    animation: "slide " + (direction || "down"),
    visible: open
  }, /* @__PURE__ */_react.default.createElement("div", {
    ref: containerRef,
    className,
    style
  }, children)));
})), {
  Item: ContextMenuItem
});