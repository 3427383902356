"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformText = exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _reactLinkify = _interopRequireDefault(require("react-linkify"));
var _react = _interopRequireWildcard(require("react"));
var _reactStringReplace = _interopRequireDefault(require("react-string-replace"));
var _ImageModal = _interopRequireDefault(require("../../../../../Widgets/ImageModal"));
var _LoginWall = _interopRequireDefault(require("../../../../../Widgets/LoginWall"));
var _Premium = require("../../../../../Widgets/Premium");
var _KosmiDropdown = require("../SidebarHead/components/KosmiDropdown");
var _Widgets = require("../../../../../Widgets");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _ImageZoomer = _interopRequireDefault(require("./ImageZoomer"));
var _helpers = require("kosmi-sdk/helpers");
var _withIsAdmin = require("../../../../withIsAdmin");
var _reactSecureLink = require("react-secure-link");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  gif: {
    "index__styles.gif": "index__styles.gif",
    maxWidth: "x193iq5w",
    maxHeight: "xmz0i5r",
    minHeight: "xz65tgg",
    width: "x1oysuqx",
    height: "x1m3v4wt",
    objectFit: "x19kjcj4",
    $$css: true
  },
  link: {
    "index__styles.link": "index__styles.link",
    color: "x16cd2qt",
    webkitTouchCallout: "x1gjbadx",
    ":hover_color": "x1ymi4rj",
    $$css: true
  }
};
const gifClass = {
  className: "index__styles.gif x193iq5w xmz0i5r xz65tgg x1oysuqx x1m3v4wt x19kjcj4"
}.className;
const linkClass = {
  className: "index__styles.link x16cd2qt x1gjbadx x1ymi4rj"
}.className;
const addGiphyEmbeds = (body, onClick) => (0, _reactStringReplace.default)(body, /(https:\/\/\w*\.giphy.com\/media\/\w+\/\S+.gif\S*)/g, match => {
  return /* @__PURE__ */_react.default.createElement(_ImageZoomer.default, {
    key: match,
    styles,
    onClick: onClick && (() => onClick(match))
  }, /* @__PURE__ */_react.default.createElement("img", {
    className: gifClass,
    src: match,
    loading: "lazy"
  }));
});
const addKosmiEmbeds = (body, onClick) => (0, _reactStringReplace.default)(body, /(https:\/\/img\.kosmi\.io\/[\w-]+[.]\S+)/g, match => {
  return /* @__PURE__ */_react.default.createElement(_ImageZoomer.default, {
    key: match,
    styles,
    onClick: onClick && (() => onClick(match))
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: gifClass,
    src: match,
    loading: "lazy"
  }));
});
const addSubscribeButton = (body, _onClick) => (0, _reactStringReplace.default)(body, '[button=":subscribe"]', match => /* @__PURE__ */_react.default.createElement(_Premium.PremiumModal, {
  key: match,
  source: "Chat",
  trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    style: {
      width: 115,
      marginTop: 10
    },
    size: "s",
    color: "red"
  }, /* @__PURE__ */_react.default.createElement(_Premium.Diamond, {
    style: {
      width: 16,
      height: 16
    }
  }), "Subscribe")
}));
const addContactSupportButton = (body, _onClick) => (0, _reactStringReplace.default)(body, '[button=":contactsupport"]', match => /* @__PURE__ */_react.default.createElement(_KosmiDropdown.SupportModal, {
  key: match,
  trigger: /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    style: {
      width: 100,
      marginTop: 10
    },
    size: "s",
    color: "blue"
  }, "Contact us"))
}));
const addInviteButton = (body, _onClick) => (0, _reactStringReplace.default)(body, '[button=":invite"]', match => /* @__PURE__ */_react.default.createElement(_Premium.InviteModal, {
  key: match,
  trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    style: {
      width: 100,
      marginTop: 10
    },
    size: "s",
    color: "blue"
  }, "Learn More")
}));
const transformText = (text, zoomImage) => [addGiphyEmbeds, addKosmiEmbeds, addInviteButton, addSubscribeButton, addContactSupportButton].reduce((acc, currentValue) => currentValue(acc, zoomImage), text);
exports.transformText = transformText;
const Link = (0, _react.forwardRef)(({
  children
}, ref) => {
  return /* @__PURE__ */_react.default.createElement(_reactLinkify.default, {
    ref,
    componentDecorator: (decoratedHref, decoratedText, key) => /* @__PURE__ */_react.default.createElement(_reactSecureLink.SecureLink, {
      className: linkClass,
      href: decoratedHref,
      key
    }, decoratedText)
  }, children);
});
const TransformedContent = (0, _react.memo)((0, _react.forwardRef)(({
  body,
  zoomImage,
  noImages
}, ref) => {
  return /* @__PURE__ */_react.default.createElement(Link, {
    ref
  }, noImages ? body : transformText(body, zoomImage));
}));
var _default = exports.default = (0, _helpers.withDialogs)((0, _react.memo)(({
  loading,
  messages,
  nextPage,
  confirm,
  deleteMessage,
  noImages
}) => {
  const isAdmin = (0, _withIsAdmin.useIsAdmin)();
  const currentUser = (0, _helpers.currentUserHook)();
  const [viewUserProfileId, setViewUserProfileId] = (0, _react.useState)("");
  const [imageZoomed, zoomImage] = (0, _react.useState)(null);
  const currentUserId = currentUser.id;
  const isLoggedIn = !currentUser.isAnonymous;
  const removeMessage = (0, _react.useCallback)(id => __async(void 0, null, function* () {
    if (confirm && (yield confirm("Delete this message?"))) {
      deleteMessage(id);
      return true;
    }
    return false;
  }), [deleteMessage, confirm]);
  const viewUserProfileInner = (0, _react.useCallback)(userId => {
    setViewUserProfileId(userId);
  }, [setViewUserProfileId]);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_Widgets.UserProfileModal, {
    userId: viewUserProfileId,
    open: !!viewUserProfileId,
    onClose: () => setViewUserProfileId("")
  }), /* @__PURE__ */_react.default.createElement(_ImageModal.default, {
    open: imageZoomed ? true : false,
    handleClose: () => zoomImage(null),
    imageSrc: imageZoomed
  }), /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, showLoginModal => /* @__PURE__ */_react.default.createElement(_uikit.ChatMessages, {
    nextPage,
    loading,
    messages: messages == null ? void 0 : messages.map((message, i) => {
      const {
        id,
        user,
        body,
        time
      } = message;
      const prevMessage = messages[i - 1];
      const isOwn = currentUserId === user.id;
      const hideUser = prevMessage && prevMessage.user.id === user.id;
      return {
        id,
        isOwn,
        time,
        hasPrevMessage: hideUser,
        prevTime: prevMessage && (prevMessage == null ? void 0 : prevMessage.time),
        text: body,
        avatarUrl: user.avatarUrl,
        authorId: user.id,
        isSubscribed: user.isSubscribed,
        authorName: user && user.displayName,
        content: /* @__PURE__ */_react.default.createElement(TransformedContent, {
          body,
          zoomImage,
          noImages
        }),
        removeMessage: isOwn || isAdmin ? removeMessage : void 0,
        viewUserProfile: isLoggedIn ? viewUserProfileInner : showLoginModal
      };
    })
  })));
}));