"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ScreenCaptureReviewModal = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireDefault(require("react"));
var _AVEngine = _interopRequireDefault(require("../../../../../../core/services/AVEngine"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useTracking = _interopRequireDefault(require("@/hooks/useTracking"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const styles = {
  screenRecorderModal: {
    "ScreenCaptureReviewModal__styles.screenRecorderModal": "ScreenCaptureReviewModal__styles.screenRecorderModal",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    $$css: true
  },
  videoPreview: {
    "ScreenCaptureReviewModal__styles.videoPreview": "ScreenCaptureReviewModal__styles.videoPreview",
    width: "xvue9z",
    maxHeight: "x1csiy34",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    marginBottom: "xieb3on",
    $$css: true
  },
  modalHeader: {
    "ScreenCaptureReviewModal__styles.modalHeader": "ScreenCaptureReviewModal__styles.modalHeader",
    display: "x78zum5",
    $$css: true
  }
};
const ScreenCaptureReview = ({
  onDownload
}) => {
  const downloadVideo = () => {
    const datenow = Date.now();
    const fileName = `kosmi-recording-${datenow}.webm`;
    const downloadLink = document.createElement("a");
    downloadLink.href = _AVEngine.default.getScreenRecordingPreviewUrl();
    downloadLink.setAttribute("download", fileName);
    downloadLink.setAttribute("name", fileName);
    downloadLink.click();
    onDownload();
  };
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.screenRecorderModal
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.video, {
    controls: true,
    style: styles.videoPreview,
    src: _AVEngine.default.getScreenRecordingPreviewUrl()
  }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    fluid: true,
    color: "yellow",
    onClick: downloadVideo
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "download"
  }), "Download video"));
};
const ScreenCaptureReviewModal = ({
  onClose,
  open,
  onRecordingDownloaded
}) => {
  const track = (0, _useTracking.default)();
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open,
    onOpen: () => {
      _AVEngine.default.stopRecordingDisplayMediaStream();
      track("UI", "ScreenCaptureReviewModalOpened", "Screen capture review modal opened");
    },
    onClose: () => {
      onClose();
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, {
    style: styles.modalHeader
  }, "Finished screen recording"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(ScreenCaptureReview, {
    onDownload: onRecordingDownloaded
  })));
};
exports.ScreenCaptureReviewModal = ScreenCaptureReviewModal;
var _default = exports.default = ScreenCaptureReviewModal;