"use strict";
import React from "react";
import { gql, useQuery } from "kosmi-sdk/gql";
import { withStyleHook } from "kosmi-sdk/styles";
import style from "./style.css";
import LoadingScreen from "../../../../../../../../../components/Widgets/LoadingScreen";
import { useIsMobile } from "../../../../../../../../../components/helpers/withMobileDetection";
import { Select } from "kosmi-sdk/uikit";
const HEADINGS = gql(`
  query MediaContentHeadings2 {
    mediaContentHeadings {
      name
      categories
    }
  }
`);
const useHeadings = () => {
  const { data } = useQuery(HEADINGS);
  return (data == null ? void 0 : data.mediaContentHeadings) || [];
};
export default ({
  categories,
  setCategories
}) => {
  const styles = withStyleHook(style);
  const { isMobile } = useIsMobile();
  const headings = useHeadings();
  if (!headings)
    return /* @__PURE__ */ React.createElement(LoadingScreen, null);
  if (isMobile) {
    return /* @__PURE__ */ React.createElement("div", { className: styles.categoriesDropdown }, /* @__PURE__ */ React.createElement(
      Select,
      {
        value: JSON.stringify(categories),
        onChange: (e) => {
          setCategories(JSON.parse(e.target.value));
        },
        options: headings.map((heading) => ({
          value: JSON.stringify(heading == null ? void 0 : heading.categories) || "",
          text: (heading == null ? void 0 : heading.name) || ""
        }))
      }
    ));
  }
  return /* @__PURE__ */ React.createElement("ul", { className: styles.categories }, headings.map((heading, i) => /* @__PURE__ */ React.createElement(
    "li",
    {
      key: i,
      className: JSON.stringify(heading.categories) === JSON.stringify(categories) ? styles.active : void 0,
      onClick: () => setCategories(heading.categories)
    },
    heading.name
  )));
};
