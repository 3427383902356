"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Message = void 0;
var _react = _interopRequireWildcard(require("react"));
var _Icon = require("../Icon");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  message: {
    "index__styles.message": "index__styles.message",
    position: "x1n2onr6",
    minHeight: "x1xaadd7",
    margin: "x9t3ola",
    background: "x5yjaeh",
    padding: "xs5nf76",
    lineHeight: "x1kq7lva",
    color: "x11jfisy",
    transition: "x1wzgjk3",
    borderRadius: "x1aaq583",
    boxShadow: "x1f0gzb3",
    fontSize: "xrv4cvt",
    borderWidth: "xmkeg23",
    borderStyle: "x1y0btm7",
    borderColor: "x1rstohm",
    ":first-child_marginTop": "x14l7nz5",
    ":last-child_marginBottom": "xzboxd6",
    $$css: true
  },
  messageHeader: {
    "index__styles.messageHeader": "index__styles.messageHeader",
    display: "x1lliihq",
    fontFamily: "xy3j3os",
    fontWeight: "x117nqv4",
    fontSize: "x1uveeis",
    margin: "x12bhije",
    $$css: true
  },
  visibleMessage: {
    "index__styles.visibleMessage": "index__styles.visibleMessage",
    display: "x1lliihq",
    $$css: true
  },
  infoMessage: {
    "index__styles.infoMessage": "index__styles.infoMessage",
    backgroundColor: "x15fdgw4",
    color: "x1cjvsfd",
    boxShadow: "xhbq16y",
    $$css: true
  },
  infoMessageHeader: {
    "index__styles.infoMessageHeader": "index__styles.infoMessageHeader",
    color: "x1t0nrw6",
    $$css: true
  },
  errorMessage: {
    "index__styles.errorMessage": "index__styles.errorMessage",
    backgroundColor: "x1jdrlbx",
    color: "x1cjvsfd",
    boxShadow: "x179zxkx",
    $$css: true
  },
  errorMessageHeader: {
    "index__styles.errorMessageHeader": "index__styles.errorMessageHeader",
    color: "xotk58x",
    $$css: true
  }
};
const Message = exports.Message = (0, _react.memo)(({
  children,
  error,
  header,
  onDismiss,
  content,
  info
}) => {
  if (!content) return null;
  return /* @__PURE__ */_react.default.createElement("div", __spreadValues({}, stylex.props(styles.message, styles.visibleMessage, error && styles.errorMessage, info && styles.infoMessage)), onDismiss && /* @__PURE__ */_react.default.createElement(_Icon.Icon, __spreadProps(__spreadValues({
    name: "close"
  }, {
    className: "index__styles.messageIcon index__styles.messageCloseIcon x1ypdohk x10l6tqk x1ghz6dp x1uieiw1 x1gd1x1u x1ks1olk x19n8f2o x1o7uuvo"
  }), {
    link: true,
    onClick: onDismiss
  })), /* @__PURE__ */_react.default.createElement("div", null, header && /* @__PURE__ */_react.default.createElement("div", __spreadValues({}, stylex.props(styles.messageHeader, error && styles.errorMessageHeader, info && styles.infoMessageHeader)), header), /* @__PURE__ */_react.default.createElement("div", null, content), children));
});