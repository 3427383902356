"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _uikit = require("kosmi-sdk/uikit");
var _platform = _interopRequireDefault(require("platform"));
var _useRoomData = require("./../hooks/useRoomData");
var _helpers = require("kosmi-sdk/helpers");
var _RoomToolbar = require("./Sidebar/components/RoomToolbar");
var _MobileContext = _interopRequireDefault(require("./../../../layout/Mobile/MobileContext"));
var _Sidebar = require("./Sidebar/");
var _LeaveRoomButton = _interopRequireDefault(require("./Sidebar/components/LeaveRoomButton"));
var _MembersModal = _interopRequireDefault(require("./Sidebar/components/MembersModal"));
var _LoginWall = _interopRequireDefault(require("./../../../components/Widgets/LoginWall"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _reactRouterDom = require("react-router-dom");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  topBar: {
    "TopBar__styles.topBar": "TopBar__styles.topBar",
    display: "x78zum5",
    justifyContent: "x1qughib",
    alignItems: "x6s0dn4",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    position: "x1n2onr6",
    height: "x10wjd1d",
    width: "xh8yej3",
    transition: "x1t3whoi",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    backgroundColor: "x15kdby0",
    backdropFilter: "x17nw0yu",
    borderTopLeftRadius: "x1rj90t8",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderTopRightRadius: "xw0cq3c",
    zIndex: "xzkaem6",
    $$css: true
  },
  topBarMinimized: {
    "TopBar__styles.topBarMinimized": "TopBar__styles.topBarMinimized",
    position: "x10l6tqk",
    height: "x5yr21d",
    cursor: "x1ypdohk",
    backgroundColor: "x15kdby0",
    $$css: true
  },
  lobbyTitle: {
    "TopBar__styles.lobbyTitle": "TopBar__styles.lobbyTitle",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    fontWeight: "x1xlr1w8",
    textAlign: "x2b8uid",
    $$css: true
  },
  roomInfo: {
    "TopBar__styles.roomInfo": "TopBar__styles.roomInfo",
    alignItems: "x6s0dn4",
    cursor: "x1ypdohk",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    height: "x5yr21d",
    justifyContent: "x1nhvcw1",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    position: "x1n2onr6",
    right: "x3m8u43",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  mobileTitleWrapper: {
    "TopBar__styles.mobileTitleWrapper": "TopBar__styles.mobileTitleWrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    alignItems: "x1cy8zhl",
    paddingTop: "x889kno",
    paddingBottom: "x1a8lsjc",
    paddingLeft: "xcpk43y",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    $$css: true
  },
  mobileMemberCount: {
    "TopBar__styles.mobileMemberCount": "TopBar__styles.mobileMemberCount",
    fontSize: "xfifm61",
    opacity: "x1us6l5c",
    $$css: true
  },
  mobileTitle: {
    "TopBar__styles.mobileTitle": "TopBar__styles.mobileTitle",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    fontSize: "xif65rj",
    $$css: true
  },
  avatar: {
    "TopBar__styles.avatar": "TopBar__styles.avatar",
    height: "x1gnnpzl",
    $$css: true
  },
  buttons: {
    "TopBar__styles.buttons": "TopBar__styles.buttons",
    display: "x78zum5",
    zIndex: "x1u8a7rm",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  }
};
var _default = exports.default = (0, _react.memo)(() => {
  var _a;
  const roomId = (0, _helpers.useRoomId)();
  const inviteLink = `https://kosmi.to/${roomId}`;
  const data = (0, _useRoomData.useRoomStateCached)(roomId);
  const metadata = ((_a = data == null ? void 0 : data.state) == null ? void 0 : _a.metadata) || {};
  const {
    roomName
  } = metadata;
  const [dropdownIsOpen, setdropdownIsOpen] = (0, _react.useState)(false);
  const {
    setExpandRoom,
    expandRoom
  } = (0, _react.useContext)(_MobileContext.default);
  const {
    memberCount
  } = (0, _Sidebar.useSidebarData)(roomId);
  const track = (0, _helpers.useTracking)();
  const isLobby = roomId === "lobby" || (roomId == null ? void 0 : roomId.endsWith("/lobby"));
  const history = (0, _reactRouterDom.useHistory)();
  const topbarRef = (0, _react.useRef)(null);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    ref: topbarRef,
    onClick: !expandRoom ? e => {
      if (e.target === topbarRef.current) {
        setExpandRoom(true);
      }
    } : void 0,
    style: [styles.topBar, !expandRoom && styles.topBarMinimized]
  }, expandRoom && !isLobby && /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    style: {
      borderWidth: 0
    },
    color: "white",
    inverted: true,
    icon: expandRoom ? "chevron down" : "chevron up",
    onClick: () => {
      setExpandRoom(!expandRoom);
    }
  })), isLobby && /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    style: {
      borderWidth: 0
    },
    color: "white",
    inverted: true,
    icon: "chevron left",
    onClick: () => {
      ;
      window.skipHistoryPrompt = true;
      history.replace("/");
    }
  }), isLobby && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.lobbyTitle
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "globe"
  }), "Lobby"), !isLobby && /* @__PURE__ */_react.default.createElement(_MembersModal.default, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.roomInfo,
      onClick: expandRoom ? void 0 : () => {
        setExpandRoom(true);
      }
    }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
      className: styles.avatar,
      circular: true,
      src: metadata.avatarUrl || ""
    }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.mobileTitleWrapper
    }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.mobileTitle
    }, roomName), memberCount > 0 && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.mobileMemberCount
    }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      name: "user group"
    }), " ", memberCount)))
  }), !isLobby && (expandRoom ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.buttons
  }, /* @__PURE__ */_react.default.createElement(_RoomToolbar.InviteFriendsModal, {
    trigger: ({
      onClick
    }) => {
      var _a2, _b;
      return /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        color: "white",
        inverted: true,
        onClick: ((_b = (_a2 = _platform.default) == null ? void 0 : _a2.os) == null ? void 0 : _b.family) && ["Android", "iOS"].indexOf(_platform.default.os.family) !== -1 && navigator["share"] ? () => __async(void 0, null, function* () {
          yield navigator.share({
            title: "Kosmi",
            text: `Join my Kosmi room! - ${inviteLink}`
          });
          track("UI", "MobileShareLinkClicked", "Mobile Share Link Clicked", roomId);
        }) : onClick,
        style: {
          borderWidth: 0,
          width: 40,
          height: 40,
          marginLeft: "auto",
          marginRight: 4,
          fontSize: 16
        },
        icon: "add user"
      });
    }
  }), /* @__PURE__ */_react.default.createElement(_RoomToolbar.RoomDropdown, {
    visible: dropdownIsOpen,
    onClose: () => setdropdownIsOpen(false),
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      onClick: () => setdropdownIsOpen(!dropdownIsOpen),
      color: "white",
      inverted: true,
      style: {
        borderWidth: 0,
        width: 40,
        height: 40,
        marginLeft: 4,
        marginRight: 4,
        fontSize: 20
      },
      icon: "ellipsis horizontal"
    })
  })) : /* @__PURE__ */_react.default.createElement(_LeaveRoomButton.default, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "white",
      inverted: true,
      style: {
        borderWidth: 0,
        width: 40,
        height: 40,
        marginLeft: 4,
        marginRight: 4,
        fontSize: 20,
        zIndex: 2
      },
      icon: "close"
    })
  })));
});