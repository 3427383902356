"use strict";
import React, { useState, useEffect, useContext } from "react";
import { FadeInTransition } from "kosmi-sdk/widgets";
import { Checkbox } from "kosmi-sdk/uikit";
import { useTracking, withDialogs } from "kosmi-sdk/helpers";
import { useMembers } from "../../../../../withMembers";
import MediaPlayerContext from "../../../../../MediaPlayerContext";
import { withStyleHook } from "kosmi-sdk/styles";
import style from "./style.css";
const relayServerMsg = /* @__PURE__ */ React.createElement("div", null, "Relay server offers better performance for", /* @__PURE__ */ React.createElement("ul", null, /* @__PURE__ */ React.createElement("li", null, "Slow connections"), /* @__PURE__ */ React.createElement("li", null, "Rooms with a large number of members")));
export default withDialogs(
  ({
    onClose,
    children,
    alert
  }) => {
    const members = useMembers();
    const styles = withStyleHook(style);
    const { setMediaPlayerState } = useContext(MediaPlayerContext);
    const [relay, setRelay] = useState(members && members.length > 2);
    const track = useTracking();
    useEffect(() => {
      track("UI", "LocalFileModalOpened", "File modal opened");
    }, []);
    return /* @__PURE__ */ React.createElement(FadeInTransition, null, children, /* @__PURE__ */ React.createElement("div", { className: styles.outerWrapper }, /* @__PURE__ */ React.createElement("div", { className: styles.innerWrapper }, /* @__PURE__ */ React.createElement(
      "label",
      {
        className: styles.loadVideoFileButton,
        htmlFor: "videofile",
        style: { margin: "auto", marginBottom: "30px" }
      },
      "Load a Video File..."
    ), /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "file",
        id: "videofile",
        name: "videofile",
        style: { display: "none" },
        onChange: (e) => {
          const file = e.target.files && e.target.files[0];
          const fileURL = URL.createObjectURL(file);
          e.target.value = "";
          setMediaPlayerState({
            localUrl: fileURL,
            relayStream: relay,
            localStream: null
          });
          onClose();
        }
      }
    ), /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          width: "100%",
          maxWidth: 500,
          margin: "auto",
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }
      },
      /* @__PURE__ */ React.createElement("div", { className: styles.advancedSettings }, /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          label: "Enable Relay Server",
          defaultChecked: relay || false,
          toggle: true,
          onChange: (_e, data) => {
            if (data.checked) {
              track("UI", "RelayServerEnabled", "Relay server enabled");
            } else {
              track(
                "UI",
                "RelayServerDisabled",
                "Relay server disabled"
              );
            }
            setRelay(!!data.checked);
          }
        }
      ), /* @__PURE__ */ React.createElement(
        "div",
        {
          className: styles.questionMark,
          onClick: () => alert && alert(relayServerMsg)
        },
        "(???)"
      ))
    ), /* @__PURE__ */ React.createElement("div", { className: styles.info }, "For list of supported video formats look", " ", /* @__PURE__ */ React.createElement(
      "a",
      {
        rel: "noreferrer noopener",
        target: "_blank",
        href: "https://www.chromium.org/audio-video"
      },
      "here"
    )), /* @__PURE__ */ React.createElement("div", { className: styles.info }, "If you have problems with your file(e.g. audio missing) try converting your file using the", " ", /* @__PURE__ */ React.createElement(
      "a",
      {
        rel: "noreferrer noopener",
        target: "_blank",
        href: "https://kosmi.io/converter.html"
      },
      "Kosmi Video Converter"
    )))));
  }
);
