"use strict";
export const sendNotification = (id, title, message) => {
  if (!("serviceWorker" in window.navigator))
    return;
  if (!window.Notification)
    return;
  try {
    Notification.requestPermission((result) => {
      if (result === "granted") {
        navigator.serviceWorker.ready.then((registration) => {
          if (registration.showNotification)
            registration.showNotification(title, {
              body: message,
              icon: "/favicon.png",
              tag: id
            });
        });
      }
    });
  } catch (e) {
    console.warn(e);
  }
};
export default sendNotification;
