"use strict";
export { gql } from "../../__generated__/gql";
import * as types from "../../__generated__/gql/graphql";
export { types };
export { useMutation, useQuery, useSubscription } from "@apollo/client";
import { useMutation as useMutation2, useQuery as useQuery2 } from "@apollo/client";
import { __rest } from "tslib";
export function Query(props) {
  const query = props.query;
  const options = __rest(props, ["children", "query"]);
  const result = useQuery2(query, options);
  const children = props.children;
  return result ? children(result) : null;
}
export function Mutation(props) {
  const [runMutation, result] = useMutation2(props.mutation, props);
  const children = props.children;
  return children ? children(runMutation, result) : null;
}
