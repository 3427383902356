"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _gql = require("kosmi-sdk/gql");
var _reactStripeJs = require("@stripe/react-stripe-js");
var _pure = require("@stripe/stripe-js/pure");
var _react = _interopRequireWildcard(require("react"));
var _countries = _interopRequireDefault(require("./countries.json"));
var _stripe = _interopRequireDefault(require("./stripe.svg"));
var _helpers = require("kosmi-sdk/helpers");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  screen: {
    "index__styles.screen": "index__styles.screen",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    overflowX: "x6ikm8r",
    $$css: true
  },
  topRow: {
    "index__styles.topRow": "index__styles.topRow",
    width: "xh8yej3",
    display: "x78zum5",
    $$css: true
  },
  stripe: {
    "index__styles.stripe": "index__styles.stripe",
    width: "xrostsh",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  disclaimer: {
    "index__styles.disclaimer": "index__styles.disclaimer",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "xe8ttls",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  checkoutElementWrapper: {
    "index__styles.checkoutElementWrapper": "index__styles.checkoutElementWrapper",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  em: {
    "index__styles.em": "index__styles.em",
    fontWeight: "x1uu0p8",
    color: "x16cd2qt",
    $$css: true
  },
  cc: {
    "index__styles.cc": "index__styles.cc",
    position: "x10l6tqk",
    opacity: "xg01cxk",
    $$css: true
  }
};
const SUBSCRIBE = (0, _gql.gql)(`
  mutation CreateSubscription($paymentMethodId: String!) {
    createStripeSubscription(paymentMethodId: $paymentMethodId) {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
  }
`);
const GET_PRODUCT = (0, _gql.gql)(`
  query GetSubscriptionProduct {
    getStripeSubscriptionProduct {
      stripePriceId
      usdAmount
      stripePublishableKey
    }
  }
`);
const GET_SUBSCRIPTION = (0, _gql.gql)(`
  query GetSubscription {
    currentStripeSubscription {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
    currentUser {
      id
      connectionId
      user {
        id
        isSubscribed
      }
    }
  }
`);
const useProduct = () => {
  const {
    data
  } = (0, _gql.useQuery)(GET_PRODUCT);
  return (data == null ? void 0 : data.getStripeSubscriptionProduct) || {};
};
const useKosmiStripe = () => {
  const {
    stripePublishableKey
  } = useProduct();
  const [stripePromise, setStripePromise] = (0, _react.useState)(null);
  const initializeStripe = () => {
    if (!stripePromise && stripePublishableKey) {
      setStripePromise((0, _pure.loadStripe)(stripePublishableKey));
    }
  };
  (0, _react.useEffect)(() => {
    if (!stripePromise && stripePublishableKey) {
      initializeStripe();
    }
  }, [stripePromise, stripePublishableKey]);
  return stripePromise;
};
const StripeWrapper = ({
  children
}) => {
  const stripePromise = useKosmiStripe();
  if (!stripePromise) return null;
  return /* @__PURE__ */_react.default.createElement(_reactStripeJs.Elements, {
    stripe: stripePromise,
    options: {
      mode: "setup",
      currency: "usd",
      paymentMethodCreation: "manual"
    }
  }, children);
};
const WrappedStripeInput = ({
  children
}) => {
  const [focused, setFocused] = (0, _react.useState)(false);
  return /* @__PURE__ */_react.default.createElement(_uikit.Form.InputWrapper, {
    focused
  }, _react.default.cloneElement(children, {
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false)
  }));
};
const PaymentSection = (0, _helpers.withDialogs)(({
  trigger,
  onPaymentMethod,
  toggle,
  checkout,
  alert,
  source,
  onBack
}) => {
  const product = useProduct();
  const currentUser = (0, _helpers.currentUserHook)();
  const stripe = (0, _reactStripeJs.useStripe)();
  const elements = (0, _reactStripeJs.useElements)();
  const [name, setName] = (0, _react.useState)("");
  const toggleRef = (0, _react.useRef)(toggle);
  const [disabled, setDisabled] = (0, _react.useState)(toggle);
  const [country, setCountry] = (0, _react.useState)((currentUser == null ? void 0 : currentUser.countryCode) || "");
  const [zipCode, setZipCode] = (0, _react.useState)("");
  const reset = () => {
    setName("");
    setDisabled(toggleRef.current);
    setCountry((currentUser == null ? void 0 : currentUser.countryCode) || "");
    setZipCode("");
    if (onBack) {
      onBack();
    }
  };
  const settingsRouter = _uikit.Settings.useSettingsRouter();
  const [subscribe] = (0, _gql.useMutation)(SUBSCRIBE);
  const track = (0, _helpers.useTracking)();
  const {
    refetch
  } = (0, _gql.useQuery)(GET_SUBSCRIPTION, {
    fetchPolicy: "network-only"
  });
  const [loading, setLoading] = (0, _react.useState)(false);
  const handleSubmit = billingDetails => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (!stripe || !elements) throw new Error("Stripe not initialized!");
      const card = elements.getElement(_reactStripeJs.CardNumberElement);
      if (!card) throw new Error("card not found!");
      let {
        error,
        paymentMethod
      } = {
        error: void 0,
        paymentMethod: void 0
      };
      if (billingDetails) {
        ;
        ({
          error,
          paymentMethod
        } = yield stripe.createPaymentMethod({
          elements,
          params: {
            billing_details: billingDetails
          }
        }));
      } else {
        ;
        ({
          error,
          paymentMethod
        } = yield stripe.createPaymentMethod({
          type: "card",
          card,
          billing_details: {
            name,
            address: {
              country,
              postal_code: zipCode
            }
          }
        }));
      }
      if (error) {
        throw error;
      }
      if (!paymentMethod) {
        throw new Error("Payment method not found");
      }
      if (checkout) {
        yield subscribe({
          variables: {
            paymentMethodId: paymentMethod.id
          }
        });
        track("Premium", "Checkout", source ? source : "Checkout");
        refetch();
        settingsRouter.pop(1);
        return false;
      } else if (onPaymentMethod) {
        onPaymentMethod(paymentMethod);
      }
      return true;
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
    return false;
  });
  const options = {
    style: {
      base: {
        backgroundColor: "transparent",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 10px",
        color: "rgb(230, 230, 230)",
        fontSize: "14px",
        lineHeight: "36px",
        padding: "12px"
      }
    }
  };
  let saveText = "Save";
  if (disabled) {
    saveText = "Edit";
  }
  if (checkout) {
    saveText = "Checkout";
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    emphasizeSave: checkout,
    icon: "credit card outline",
    name: "Payment details",
    saveText,
    trigger,
    onBack: reset,
    onSave: !checkout ? () => __async(void 0, null, function* () {
      if (disabled) {
        setDisabled(false);
        return false;
      }
      return yield handleSubmit();
    }) : void 0
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Payment details"
  }, loading && /* @__PURE__ */_react.default.createElement(_uikit.Loader, {
    absolute: true,
    size: "massive"
  }, "Please wait..."), checkout && stripe && elements && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.checkoutElementWrapper
  }, /* @__PURE__ */_react.default.createElement(_reactStripeJs.ExpressCheckoutElement, {
    onConfirm: event => __async(void 0, null, function* () {
      handleSubmit(event.billingDetails);
    })
  })), /* @__PURE__ */_react.default.createElement(_uikit.Form, {
    disabled: disabled || loading,
    className: styles.screen
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.topRow
  }, checkout && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Kosmi Premium Subscription - $", product.usdAmount, " / month"), /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    style: styles.stripe,
    href: "https://stripe.com",
    target: "_blank",
    rel: "noreferrer"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: styles.stripe,
    src: _stripe.default
  }))), /* @__PURE__ */_react.default.createElement(_uikit.Form.Group, {
    inline: true
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.input, {
    id: "cc-number",
    type: "text",
    name: "cc-number",
    value: "",
    style: styles.cc
  }), /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    label: "Name on Card",
    placeholder: disabled ? "************" : "Full name",
    autoFocus: true,
    onChange: e => {
      e.preventDefault();
      setName(e.target.value);
    },
    value: name,
    maxLength: 50,
    autoComplete: "cc-name",
    name: "cc-name",
    required: true,
    disabled
  })), /* @__PURE__ */_react.default.createElement(_uikit.Form.Group, {
    inline: true
  }, /* @__PURE__ */_react.default.createElement(_uikit.Form.Select, {
    options: _countries.default,
    label: "Country or Region",
    placeholder: disabled ? "****" : "Country",
    onChange: e => {
      e.preventDefault();
      setCountry(e == null ? void 0 : e.value);
    },
    value: disabled ? "" : country,
    required: true,
    disabled,
    name: "cc-country"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    label: "Postal/ZIP Code",
    autoComplete: "cc-zip",
    name: "cc-zip",
    placeholder: disabled ? "************" : "Postal/ZIP Code",
    onChange: e => {
      e.preventDefault();
      setZipCode(e.target.value);
    },
    maxLength: 20,
    value: zipCode,
    required: true,
    disabled
  })), /* @__PURE__ */_react.default.createElement(_uikit.Form.Group, {
    inline: true
  }, /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, {
    disabled,
    required: true
  }, /* @__PURE__ */_react.default.createElement(_uikit.Form.Label, null, "Card number"), disabled ? /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    value: "******************",
    required: true,
    disabled: true
  }) : /* @__PURE__ */_react.default.createElement(WrappedStripeInput, null, /* @__PURE__ */_react.default.createElement(_reactStripeJs.CardNumberElement, {
    options
  }))), /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, {
    disabled,
    required: true
  }, /* @__PURE__ */_react.default.createElement(_uikit.Form.Label, null, "Expiration"), disabled ? /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    value: "**/**",
    disabled: true
  }) : /* @__PURE__ */_react.default.createElement(WrappedStripeInput, null, /* @__PURE__ */_react.default.createElement(_reactStripeJs.CardExpiryElement, {
    options
  }))), /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, {
    disabled,
    required: true
  }, /* @__PURE__ */_react.default.createElement(_uikit.Form.Label, null, "CVC"), disabled ? /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    value: "***",
    disabled: true
  }) : /* @__PURE__ */_react.default.createElement(WrappedStripeInput, null, /* @__PURE__ */_react.default.createElement(_reactStripeJs.CardCvcElement, {
    options
  })))), checkout && /* @__PURE__ */_react.default.createElement(_uikit.Form.Group, null, /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, null, /* @__PURE__ */_react.default.createElement(_uikit.Form.Button, {
    style: {
      marginLeft: "auto"
    },
    color: "yellow",
    onClick: () => handleSubmit(),
    disabled: loading
  }, "Pay now"))), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.disclaimer
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.em
  }, "We do not store your payment information in our system at any point in time.", " "), "Any details entered are securely transmitted to our payment processor,", " ", /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    href: "https://stripe.com",
    target: "_blank",
    rel: "noreferrer"
  }, "Stripe"), ", who will manage your subscription going forward."))));
});
var _default = props => /* @__PURE__ */_react.default.createElement(StripeWrapper, null, /* @__PURE__ */_react.default.createElement(PaymentSection, __spreadValues({}, props)));
exports.default = _default;