"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation } from "kosmi-sdk/gql";
import {
  Button,
  Icon,
  ImageUploader,
  Popup,
  useOnClickOutside,
  ChatMessages
} from "kosmi-sdk/uikit";
import EmojiPicker from "./EmojiPicker";
import React, { forwardRef, memo, useEffect, useRef, useState } from "react";
import { currentUserHook } from "../../../../../helpers/withCurrentUser";
import withDialogs from "../../../../../helpers/withDialogs";
import Modal from "../../../../../Widgets/AnimatedModal";
import LoginModal from "../../../../../Widgets/LoginModal";
import { useIsAdmin } from "../../../../withIsAdmin";
import { useRoomId } from "../../../../withRoomId";
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);
const GET_EMBEDDABLE_URL_METADATA = gql(`
  mutation GetEmbeddableLinkMetadata($url: String!) {
    getEmbeddableLinkMetadata(url: $url) {
      name
      hostname
      type
    }
  }
`);
function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
const OpenLinkModal = memo(
  (_a) => {
    var _b = _a, {
      sendToChat,
      linkData
    } = _b, props = __objRest(_b, [
      "sendToChat",
      "linkData"
    ]);
    return /* @__PURE__ */ React.createElement(Modal, __spreadValues({}, props), /* @__PURE__ */ React.createElement(Modal.Header, null, "Supported link detected"), /* @__PURE__ */ React.createElement(Modal.Content, null, "We see that you are trying to post a link to", " ", linkData && linkData.name), /* @__PURE__ */ React.createElement(Modal.Actions, null, /* @__PURE__ */ React.createElement(
      Button,
      {
        inverted: true,
        color: "yellow",
        onClick: () => {
          props.openInRoom();
          props.onClose();
        }
      },
      "Open Link in a Web View"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        inverted: true,
        color: "white",
        onClick: () => {
          sendToChat();
          props.onClose();
        }
      },
      "Post link to chat"
    )));
  }
);
const OPEN_LINK_IN_WEBVIEW = gql(`
  mutation OpenLinkInWebview1($roomId: String!, $url: String!) {
    openLinkInWebview(roomId: $roomId, url: $url) {
      ok
    }
  }
`);
const EmojiPickerButton = forwardRef(
  ({
    open,
    setOpen,
    mountNode,
    style,
    hideGifPicker,
    onSelect,
    onGifSelect,
    onFocus,
    onBlur
  }, ref) => {
    return /* @__PURE__ */ React.createElement(
      Popup,
      {
        controlled: true,
        open,
        position: "top center",
        onClickOutside: () => {
          setOpen(false);
        },
        mountNode,
        content: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
          "div",
          {
            style: __spreadValues({
              width: 300,
              height: 300,
              display: "flex"
            }, style)
          },
          /* @__PURE__ */ React.createElement(
            EmojiPicker,
            {
              ref,
              hideGifPicker,
              onSelect,
              onGifSelect
            }
          )
        )),
        trigger: /* @__PURE__ */ React.createElement(
          Button,
          {
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!open && onFocus)
                onFocus();
              if (open && onBlur)
                onBlur();
              setOpen(!open);
            },
            type: "button",
            inverted: true,
            color: "white",
            style: {
              margin: 0,
              width: 35,
              borderRadius: 35 / 2,
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              borderWidth: 0
            }
          },
          /* @__PURE__ */ React.createElement(Icon, { size: "large", name: "smile outline" })
        )
      }
    );
  }
);
const ImageButton = forwardRef(
  ({ onClick }, ref) => {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        onClick,
        ref,
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          minHeight: 48,
          margin: 0,
          minWidth: 48,
          borderRadius: 35 / 2,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          borderWidth: 0
        }
      },
      /* @__PURE__ */ React.createElement(Icon, { color: "white", size: "large", name: "image outline" })
    );
  }
);
export default withDialogs(
  memo(
    ({
      confirm,
      overrideEmojiPickerStyle,
      emojiPickerMountNode,
      isPrivate,
      isNsfw,
      onFocus,
      onBlur,
      sendMessage
    }) => {
      const roomId = isPrivate ? "" : useRoomId();
      const [openLinkInWebview] = useMutation(OPEN_LINK_IN_WEBVIEW);
      const [getEmbeddableLinkMetadata] = useMutation(
        GET_EMBEDDABLE_URL_METADATA
      );
      const isAdmin = useIsAdmin();
      const currentUser = currentUserHook();
      const inputRef = useRef(null);
      const formRef = useRef(null);
      const emojiPickerRef = useRef(null);
      const [pendingLinkData, setPendingLinkData] = useState(
        null
      );
      const [showEmojiPicker, setShowEmojiPicker] = useState(false);
      const isLobby = roomId === "lobby";
      useOnClickOutside(
        showEmojiPicker ? [formRef, emojiPickerRef] : [formRef],
        onBlur || (() => {
        })
      );
      useEffect(() => {
        if (!inputRef.current)
          return;
        inputRef.current.autocomplete = "off";
      }, [inputRef == null ? void 0 : inputRef.current]);
      if (currentUser.isAnonymous && isLobby) {
        return /* @__PURE__ */ React.createElement(
          LoginModal,
          {
            trigger: ({ onClick }) => /* @__PURE__ */ React.createElement(
              Button,
              {
                color: "var(--primary-light-10)",
                style: {
                  color: "var(--foreground)"
                },
                fluid: true,
                inverted: true,
                onClick
              },
              "Please Log in to chat"
            )
          }
        );
      }
      return /* @__PURE__ */ React.createElement(React.Fragment, null, !isPrivate && /* @__PURE__ */ React.createElement(
        OpenLinkModal,
        {
          open: !!pendingLinkData,
          onClose: () => setPendingLinkData(null),
          linkData: pendingLinkData,
          sendToChat: () => {
            sendMessage(inputRef == null ? void 0 : inputRef.current.value);
            inputRef.current.value = "";
          },
          openInRoom: () => ((input) => __async(void 0, null, function* () {
            if (openLinkInWebview && (yield confirm(
              "Stop the current activity and open link in webview?"
            ))) {
              openLinkInWebview({
                variables: {
                  roomId,
                  url: input.current.value
                }
              });
            }
            if (input) {
              input.current.value = "";
            }
          }))(inputRef)
        }
      ), /* @__PURE__ */ React.createElement(
        ChatMessages.Messagebox,
        {
          inputRef,
          ref: formRef,
          onFocus,
          onBlur,
          onEnter: () => __async(void 0, null, function* () {
            var _a, _b;
            if (!((_a = inputRef == null ? void 0 : inputRef.current) == null ? void 0 : _a.value))
              return;
            if (isAdmin && isValidHttpUrl(inputRef.current.value) && getEmbeddableLinkMetadata && !isPrivate) {
              const result = yield getEmbeddableLinkMetadata({
                variables: {
                  url: inputRef.current.value
                }
              });
              const linkData = (_b = result == null ? void 0 : result.data) == null ? void 0 : _b.getEmbeddableLinkMetadata;
              if (linkData) {
                setPendingLinkData(linkData || null);
                return;
              }
            }
            sendMessage(inputRef.current.value);
            inputRef.current.value = "";
          })
        },
        (isPrivate || !isLobby) && !isNsfw && /* @__PURE__ */ React.createElement(
          ImageUploader,
          {
            unstyled: true,
            forceDefault: true,
            src: /* @__PURE__ */ React.createElement(ImageButton, null),
            onFile: (file) => __async(void 0, null, function* () {
              if (!file)
                return;
              const data = new FormData();
              data.append("file", file);
              try {
                const response = yield fetch("https://img.kosmi.io/", {
                  method: "POST",
                  body: data
                });
                const response_json = yield response.json();
                const filename = response_json.filename;
                if (filename) {
                  sendMessage("https://img.kosmi.io/" + filename);
                }
              } catch (e) {
                console.warn(e);
              }
            })
          }
        ),
        !(isLobby && isMobile) && /* @__PURE__ */ React.createElement(
          EmojiPickerButton,
          {
            ref: emojiPickerRef,
            open: showEmojiPicker,
            setOpen: setShowEmojiPicker,
            mountNode: emojiPickerMountNode,
            style: overrideEmojiPickerStyle,
            hideGifPicker: isLobby,
            onSelect: (e) => {
              inputRef.current.value = inputRef.current.value + e.native;
              inputRef.current.focus();
              setShowEmojiPicker(false);
            },
            onGifSelect: (url) => {
              sendMessage(url);
              setShowEmojiPicker(false);
            },
            onFocus,
            onBlur
          }
        )
      ));
    }
  )
);
