"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Image = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  image: {
    "index__styles.image": "index__styles.image",
    position: "x1n2onr6",
    display: "x1lliihq",
    verticalAlign: "xxymvpz",
    maxWidth: "x193iq5w",
    backgroundColor: "xjbqb8w",
    height: "xt7dq6l",
    marginLeft: "xj3b58b",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "x1is44p2",
    $$css: true
  },
  rounded: {
    "index__styles.rounded": "index__styles.rounded",
    borderRadius: "x1m25ckn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  circular: {
    "index__styles.circular": "index__styles.circular",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    borderRadius: "x1pscjh6",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  avatar: {
    "index__styles.avatar": "index__styles.avatar",
    marginRight: "xsxjf7q",
    marginInlineStart: null,
    marginInlineEnd: null,
    display: "x1rg5ohu",
    width: "xk2f0lv",
    height: "x19hywkt",
    borderRadius: "x1pscjh6",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  },
  mini: {
    "index__styles.mini": "index__styles.mini",
    width: "x1n7h9c3",
    height: "xt7dq6l",
    fontSize: "x1mphdil",
    $$css: true
  },
  tiny: {
    "index__styles.tiny": "index__styles.tiny",
    width: "x1dmp6jm",
    height: "xt7dq6l",
    fontSize: "xs6yfsm",
    $$css: true
  },
  small: {
    "index__styles.small": "index__styles.small",
    width: "xrostsh",
    height: "xt7dq6l",
    fontSize: "xrd5znx",
    $$css: true
  },
  medium: {
    "index__styles.medium": "index__styles.medium",
    width: "xdzyupr",
    height: "xt7dq6l",
    fontSize: "x1jchvi3",
    $$css: true
  },
  large: {
    "index__styles.large": "index__styles.large",
    width: "xbstwhj",
    height: "xt7dq6l",
    fontSize: "xi9rq2s",
    $$css: true
  },
  big: {
    "index__styles.big": "index__styles.big",
    width: "x14rvwrp",
    height: "xt7dq6l",
    fontSize: "xlue8l3",
    $$css: true
  },
  huge: {
    "index__styles.huge": "index__styles.huge",
    width: "xdpfuu1",
    height: "xt7dq6l",
    fontSize: "x5zinoz",
    $$css: true
  },
  massive: {
    "index__styles.massive": "index__styles.massive",
    width: "xbqi55i",
    height: "xt7dq6l",
    fontSize: "x1gbuw7f",
    $$css: true
  }
};
const Image = exports.Image = (0, _react.memo)(({
  src,
  className,
  circular,
  rounded,
  size,
  loading,
  style,
  id,
  avatar,
  width,
  height,
  alt
}) => {
  const {
    className: styleXClassName
  } = stylex.props(styles.image, circular && styles.circular, size && styles[size], rounded && styles.rounded, avatar && styles.avatar, className instanceof Object && className);
  const classNames = [styleXClassName];
  if (typeof className === "string") classNames.push(className);
  return /* @__PURE__ */_react.default.createElement("img", {
    src,
    width,
    height,
    id,
    alt,
    style,
    loading,
    className: classNames.join(" ")
  });
});