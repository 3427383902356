"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Container = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  container: {
    "index__styles.container": "index__styles.container",
    display: "x1lliihq",
    maxWidth: "x193iq5w",
    marginLeft: "x8x9d4c",
    marginRight: "xack27t",
    $$css: true
  },
  textContainer: {
    "index__styles.textContainer": "index__styles.textContainer",
    fontFamily: "xy3j3os",
    lineHeight: "x1evy7pa",
    maxWidth: "x193iq5w",
    width: "x14rvwrp",
    fontSize: "xi9rq2s",
    $$css: true
  }
};
const Container = exports.Container = (0, _react.memo)(({
  text,
  className,
  children
}) => {
  const stylexProps = stylex.props(styles.container, text && styles.textContainer);
  const classNames = [stylexProps.className, className];
  return /* @__PURE__ */_react.default.createElement("div", {
    className: classNames.join(" "),
    style: stylexProps.style
  }, text, children);
});