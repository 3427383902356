"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _widgets = require("kosmi-sdk/widgets");
var _helpers = require("kosmi-sdk/helpers");
var _useMediaPlayerState = require("../../useMediaPlayerState");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  root: {
    "VideoURLModal__styles.root": "VideoURLModal__styles.root",
    width: "xh8yej3",
    maxWidth: "x65f84u",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  fadeIn: {
    "VideoURLModal__styles.fadeIn": "VideoURLModal__styles.fadeIn",
    justifyContent: "xl56j7k",
    $$css: true
  }
};
var _default = ({
  onClose,
  children
}) => {
  const [value, setValue] = (0, _react.useState)("");
  const {
    setUrl
  } = (0, _useMediaPlayerState.useMediaPlayerActions)();
  const track = (0, _helpers.useTracking)();
  (0, _react.useEffect)(() => {
    track("UI", "VideoURLModalOpened", "URL modal opened");
  }, []);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.root
  }, /* @__PURE__ */_react.default.createElement(_widgets.FadeInTransition, {
    className: styles.fadeIn
  }, children, /* @__PURE__ */_react.default.createElement(_uikit.Input, {
    autoFocus: true,
    onChange: e => setValue(e.target.value),
    fluid: true,
    value,
    placeholder: "https://... or magnet:...",
    onKeyPress: e => __async(void 0, null, function* () {
      if (e.key === "Enter") {
        yield setUrl(value);
        setValue("");
        onClose();
      }
    }),
    action: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "blue",
      style: {
        maxHeight: "100%",
        width: 80,
        marginLeft: 5
      },
      onClick: () => __async(void 0, null, function* () {
        if (!value) return;
        yield setUrl(value);
        onClose();
      })
    }, "Open")
  })));
};
exports.default = _default;