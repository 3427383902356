"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireDefault(require("react"));
var _LoginModal = require("../components/Widgets/LoginModal");
var _reactRouterDom = require("react-router-dom");
var _gql = require("kosmi-sdk/gql");
var _helpers = require("kosmi-sdk/helpers");
var _Widgets = require("../components/Widgets");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const GET_USER_BY_INVITE_CODE = (0, _gql.gql)(`
  query GetUserByInviteCode($inviteCode: String!) {
    userByInviteCode(inviteCode: $inviteCode) {
      id
      username
      displayName
      avatarUrl
    }
  }
`);
const styles = {
  signupWrapper: {
    "signup__styles.signupWrapper": "signup__styles.signupWrapper",
    display: "x78zum5",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    width: "x14rvwrp",
    maxWidth: "x193iq5w",
    flexDirection: "xdt5ytf",
    background: "x1smpwcq",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    maxHeight: "xmz0i5r",
    height: "x1jn0hjm",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    justifyContent: "x1nhvcw1",
    padding: "x1qhigcl",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  disabled: {
    "signup__styles.disabled": "signup__styles.disabled",
    opacity: "x1ks1olk",
    pointerEvents: "x47corl",
    $$css: true
  },
  inviter: {
    "signup__styles.inviter": "signup__styles.inviter",
    display: "x78zum5",
    margin: "xhifcr4",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    flexDirection: "xdt5ytf",
    $$css: true
  },
  img: {
    "signup__styles.img": "signup__styles.img",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    width: "x1849jeq",
    height: "x1gnnpzl",
    margin: "x16zck5j",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  inviterInner: {
    "signup__styles.inviterInner": "signup__styles.inviterInner",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  inviterInnerFirst: {
    "signup__styles.inviterInnerFirst": "signup__styles.inviterInnerFirst",
    display: "x78zum5",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    flexDirection: "x1q0g3np",
    alignItems: "x6s0dn4",
    $$css: true
  },
  a: {
    "signup__styles.a": "signup__styles.a",
    color: "x16cd2qt",
    fontSize: "xosj86m",
    margin: "x1v36x0e",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  }
};
var _default = () => {
  var _a;
  const history = (0, _reactRouterDom.useHistory)();
  const params = (0, _reactRouterDom.useParams)();
  const currentUser = (0, _helpers.currentUserHook)();
  const inviteCode = params == null ? void 0 : params.inviteCode;
  const {
    data
  } = (0, _gql.useQuery)(GET_USER_BY_INVITE_CODE, {
    variables: {
      inviteCode
    },
    pollInterval: 5e3
  });
  const user = data == null ? void 0 : data.userByInviteCode;
  if (!user) return /* @__PURE__ */_react.default.createElement(_Widgets.LoadingScreen, null);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.signupWrapper, !currentUser.isAnonymous && styles.disabled]
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviter
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviterInnerFirst
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: styles.img,
    src: ((_a = user == null ? void 0 : user.avatarUrl) == null ? void 0 : _a.replaceAll("=300", "=50")) || ""
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, user.displayName || user.username)), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.inviterInner
  }, "has invited you to Join Kosmi!"), /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    style: styles.a,
    target: "_blank",
    rel: "noopener noreferrer",
    href: "https://www.youtube.com/watch?v=UDYhckhG104"
  }, "What's that?")), /* @__PURE__ */_react.default.createElement(_LoginModal.LoginModalInner, {
    signup: true,
    inviteCode,
    onLogin: () => {
      history.replace("/");
    }
  }));
};
exports.default = _default;