"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState } from "react";
import * as Demo from "../Demo";
import { currentUserHook } from "kosmi-sdk/helpers";
import useMediaSoupChannel from "./webrtc/mediasoup/useMediaSoupChannel";
export default (enabled, members) => {
  const currentUser = currentUserHook();
  const currentUserId = currentUser.id;
  const demoStreams = () => {
    return Demo.demoStreams(members || []);
  };
  const [mutedMicrophones, setMutedMicrophones] = useState(
    {}
  );
  const muteMicrophone = (userId) => __async(void 0, null, function* () {
    mutedMicrophones[userId] = true;
    setMutedMicrophones(__spreadValues({}, mutedMicrophones));
  });
  const unmuteMicrophone = (userId) => __async(void 0, null, function* () {
    delete mutedMicrophones[userId];
    setMutedMicrophones(__spreadValues({}, mutedMicrophones));
  });
  const [enableWebcam, setEnableWebcam] = useState(
    () => null
  );
  const [disableWebcam, setDisableWebcam] = useState(
    () => null
  );
  const [enableMicrophone, setEnableMicrophone] = useState(
    () => null
  );
  const [disableMicrophone, setDisableMicrophone] = useState(
    () => null
  );
  const [webcamStreams, setWebcamStreams] = useState(
    {}
  );
  const [microphoneStreams, setMicrophoneStreams] = useState(
    {}
  );
  const onVideoChannel = (conferenceChannel) => {
    setWebcamStreams(__spreadValues(__spreadValues({}, conferenceChannel.streamStore.streams), demoStreams()));
    setEnableWebcam(() => conferenceChannel.enableWebcam);
    setDisableWebcam(() => conferenceChannel.disableWebcam);
  };
  const onAudioChannel = (conferenceChannel) => {
    setMicrophoneStreams(conferenceChannel.streamStore.streams);
    setEnableMicrophone(() => conferenceChannel.enableMicrophone);
    setDisableMicrophone(() => conferenceChannel.disableMicrophone);
  };
  const onVideoChannelStreamUpdate = (webcamStreams2) => setWebcamStreams(__spreadValues(__spreadValues({}, webcamStreams2), demoStreams()));
  const onAudioChannelStreamUpdate = (microphoneStreams2) => {
    const newMutedMicrophones = {};
    Object.keys(microphoneStreams2).forEach((userId) => {
      if (mutedMicrophones[userId])
        newMutedMicrophones[userId] = mutedMicrophones[userId];
    });
    setMicrophoneStreams(microphoneStreams2);
    setMutedMicrophones(newMutedMicrophones);
  };
  useMediaSoupChannel({
    enabled,
    onStreamStoreUpdate: onVideoChannelStreamUpdate,
    onChannel: onVideoChannel,
    webcamOn: !!(webcamStreams && webcamStreams[currentUserId]),
    localWebcamStream: webcamStreams && webcamStreams[currentUserId],
    mediaType: "video"
  });
  useMediaSoupChannel({
    enabled,
    onStreamStoreUpdate: onAudioChannelStreamUpdate,
    onChannel: onAudioChannel,
    webcamOn: !!(microphoneStreams && microphoneStreams[currentUserId]),
    localWebcamStream: microphoneStreams && microphoneStreams[currentUserId],
    mediaType: "audio"
  });
  useEffect(() => {
    const currentMemberIds = members == null ? void 0 : members.map((m) => (m == null ? void 0 : m.user) && m.user.id);
    const staleStreamMemberIds = Object.keys(webcamStreams).filter(
      (memberId) => (currentMemberIds == null ? void 0 : currentMemberIds.indexOf(memberId)) === -1
    );
    if (staleStreamMemberIds.length > 0) {
      const newWebcamStreams = {};
      currentMemberIds == null ? void 0 : currentMemberIds.map((userId) => {
        if (userId) {
          newWebcamStreams[userId] = webcamStreams[userId];
        }
      });
      setWebcamStreams(newWebcamStreams);
    } else if (currentMemberIds == null ? void 0 : currentMemberIds.find(
      (memberId) => memberId == null ? void 0 : memberId.indexOf("DEMO")
    )) {
      setWebcamStreams(__spreadValues(__spreadValues({}, webcamStreams), demoStreams()));
    }
  }, [JSON.stringify(members)]);
  return {
    enableMicrophone,
    disableMicrophone,
    enableWebcam,
    disableWebcam,
    webcamStreams,
    microphoneStreams,
    mutedMicrophones,
    muteMicrophone,
    unmuteMicrophone
  };
};
