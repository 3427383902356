"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import rollbar from "rollbar";
import platform from "platform";
export function init() {
  rollbar.init({
    captureUncaught: true,
    captureUnhandledRejections: true,
    accessToken: "eeXBAqIldCWMvJtVjJ76PkHNj9",
    endpoint: "https://errorpush.analytics.kosmi.io/",
    environment: "kosmi-web",
    checkIgnore: function(_isUncaught, _args, payload) {
      const payloadString = JSON.stringify(payload.body);
      const ignoredStrings = [
        "play()",
        "play method",
        "aborted by the user",
        "user denied permission",
        "AbortError",
        "ResizeObserver loop limit exceeded",
        "request is not allowed by the user agent or the platform in the current context."
      ];
      return ignoredStrings.some(
        (ignoredString) => payloadString.includes(ignoredString)
      );
    },
    stackTraceLimit: 0,
    transform: (e) => {
      var _a, _b, _c, _d, _e, _f, _g, _h;
      e.telemetry = (_a = e == null ? void 0 : e.body) == null ? void 0 : _a.telemetry;
      e.platform = `${platform.name} on ${(_b = platform.os) == null ? void 0 : _b.family}`;
      if (!e.message) {
        e.message = {};
      }
      e.message.body = ((_c = e == null ? void 0 : e.message) == null ? void 0 : _c.body) || ((_e = (_d = e.body.trace) == null ? void 0 : _d.exception) == null ? void 0 : _e.message);
      e.body = {
        trace: __spreadProps(__spreadValues({}, (_f = e == null ? void 0 : e.body) == null ? void 0 : _f.trace), {
          frames: (_h = (_g = e == null ? void 0 : e.body) == null ? void 0 : _g.trace) == null ? void 0 : _h.frames.filter(
            (t) => (t == null ? void 0 : t.filename.endsWith(".js")) || !(t == null ? void 0 : t.filename.startsWith("http"))
          )
        })
      };
    }
  });
}
