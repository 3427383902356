"use strict";
import { useEffect } from "react";
import useSettings from "./useSettings";
import { initColors, updateColors } from "../components/helpers/colors";
import useColorScheme from "./useColorScheme";
export default (skip) => {
  const settings = useSettings();
  const colorSchemeId = settings == null ? void 0 : settings.colorScheme;
  const colorScheme = useColorScheme(colorSchemeId);
  useEffect(() => {
    if (!colorSchemeId)
      return;
    if (skip)
      return;
    const { primaryColor, secondaryColor, foregroundColor } = colorScheme || {};
    if (primaryColor && secondaryColor && foregroundColor) {
      updateColors(true, primaryColor, secondaryColor, foregroundColor);
    } else {
      initColors();
    }
  }, [skip, colorScheme]);
};
