"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Checkbox = void 0;
var _react = _interopRequireWildcard(require("react"));
var _reactNative = require("react-native");
var _themed = require("@rneui/themed");
var _colors = require("../layout/colors");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
const checkboxStyles = _reactNative.StyleSheet.create({
  checkBoxWrapperStyles: __spreadValues({
    display: "flex",
    padding: 10,
    fontSize: 14,
    flexDirection: "row"
  }, _reactNative.Platform.select({
    web: {
      cursor: "pointer"
    }
  })),
  checkboxInnerStyles: {
    display: "flex",
    marginRight: 10,
    flexDirection: "row"
  },
  checkboxStyles: {
    fontSize: 14
  },
  checkBoxLabel: {
    color: "inherit",
    fontFamily: "inherit"
  },
  checkboxBox: {
    backgroundColor: "rgba(0,0,0,0)",
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: 0
  },
  checkboxBoxInner: {
    backgroundColor: "rgba(0,0,0,1)",
    padding: 3
  }
});
const Checkbox = exports.Checkbox = (0, _react.memo)(({
  onChange,
  checked: _checked,
  defaultChecked,
  label,
  disabled,
  toggle,
  className
}) => {
  const checked = _checked === void 0 ? defaultChecked : _checked;
  const ref = _react.default.useRef(null);
  (0, _react.useEffect)(() => {
    if (ref.current) {
      ;
      ref.current.className = stylex.props(className).className;
    }
  }, []);
  return /* @__PURE__ */_react.default.createElement(_reactNative.Pressable, {
    ref,
    style: checkboxStyles.checkBoxWrapperStyles,
    onPress: _ => {
      if (onChange) {
        onChange(null, {
          checked: !checked
        });
      }
    }
  }, /* @__PURE__ */_react.default.createElement(_reactNative.View, {
    style: checkboxStyles.checkBoxWrapperStyles
  }, /* @__PURE__ */_react.default.createElement(_reactNative.View, {
    style: checkboxStyles.checkboxInnerStyles
  }, toggle ? /* @__PURE__ */_react.default.createElement(_themed.Switch, {
    trackColor: {
      false: (0, _colors.getVar)("primary-light-40"),
      true: (0, _colors.getVar)("secondary")
    },
    color: (0, _colors.getVar)("foreground"),
    disabled,
    onValueChange: val => {
      const data = {
        checked: val
      };
      if (onChange) {
        onChange(null, data);
      }
    },
    value: checked === true
  }) : /* @__PURE__ */_react.default.createElement(_themed.CheckBox, {
    disabled,
    checkedColor: (0, _colors.getVar)("foreground"),
    checkedIcon: "check",
    onPress: () => {
      if (onChange) {
        onChange(null, {
          checked: !checked
        });
      }
    },
    checked: checked === true,
    size: 12,
    containerStyle: checkboxStyles.checkboxBox,
    wrapperStyle: checkboxStyles.checkboxBoxInner
  })), /* @__PURE__ */_react.default.createElement(_reactNative.Text, {
    style: checkboxStyles.checkBoxLabel
  }, label)));
});