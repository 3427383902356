"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql } from "kosmi-sdk/gql";
import { useQuery } from "kosmi-sdk/gql";
import { Image, Button } from "kosmi-sdk/uikit";
import React from "react";
import { useHistory } from "react-router-dom";
import { withStyleHook } from "../../../sdk/styles";
import { useTracking } from "../../helpers/withTracking";
import Flag from "../../Widgets/Flag";
import Modal from "../AnimatedModal";
import style from "./style.css";
const GET_ROOM = gql(`
  query PublicRoom($joinCode: String!) {
    publicRoom(joinCode: $joinCode) {
      appInfo
      appName
      avatarUrl
      countryCode
      description
      joinCode
      memberCount
      roomName
      autoAcceptJoins
    }
  }
`);
const JoinRoomForm = ({ joinCode, styles, history, onClose }) => {
  if (!joinCode)
    return null;
  const { data, loading } = useQuery(GET_ROOM, { variables: { joinCode } });
  const room = (data == null ? void 0 : data.publicRoom) || {};
  return /* @__PURE__ */ React.createElement("div", { className: styles.joinFormWrapper }, /* @__PURE__ */ React.createElement("div", { className: styles.roomName }, room.countryCode && /* @__PURE__ */ React.createElement(Flag, { name: room.countryCode && room.countryCode.toLowerCase() }), room.roomName || "..."), /* @__PURE__ */ React.createElement(
    Image,
    {
      src: room.avatarUrl || "",
      className: styles.roomProfilePicture,
      circular: true,
      loading: "lazy",
      width: 100,
      height: 100
    }
  ), /* @__PURE__ */ React.createElement("div", { className: styles.roomDescription }, loading ? "..." : room.description), /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "blue",
      style: {
        margin: "auto"
      },
      onClick: () => __async(void 0, null, function* () {
        history.push("/room/" + room.joinCode);
        onClose();
      })
    },
    "Join Room"
  ));
};
const JoinRoomModal = ({
  onClose,
  open,
  joinCode
}) => {
  const track = useTracking();
  const history = useHistory();
  const styles = withStyleHook(style);
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      size: "tiny",
      closeIcon: true,
      open,
      onOpen: () => track(
        "UI",
        "JoinPublicRoomModalOpened",
        "Join public room modal opened"
      ),
      onClose: () => {
        onClose();
      }
    },
    /* @__PURE__ */ React.createElement(Modal.Header, { style: { display: "flex" } }, "Join Room"),
    /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(
      JoinRoomForm,
      {
        onClose,
        joinCode,
        styles,
        history
      }
    ))
  );
};
export default JoinRoomModal;
