"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React from "react";
import VirtualBrowser from "../../../VirtualBrowser";
export default ({ sessionId, onSupportedUrl, onBannedUrl }) => /* @__PURE__ */ React.createElement(
  VirtualBrowser,
  {
    sessionId,
    onTabUpdated: (_a, _b, tab) => __async(void 0, null, function* () {
      const url = tab == null ? void 0 : tab.url;
      if (url.startsWith("https://discord.com") || url.startsWith("https://instagram.com") || url.startsWith("https://www.discord.com") || url.startsWith("https://www.instagram.com") || url.startsWith("https://now.gg") || url.startsWith("https://www.now.gg")) {
        if (onBannedUrl) {
          onBannedUrl(url);
          return;
        }
      }
      if (url && url.startsWith("https://videorelay.kosmi.io/")) {
        const parsedUrl = new URL(url);
        const hlsUrl = parsedUrl.origin + parsedUrl.pathname.replace("/play/", "/") + ".m3u8";
        const originalUrl = parsedUrl.searchParams.get("url") || "";
        if (onSupportedUrl) {
          onSupportedUrl(
            hlsUrl + (originalUrl ? "?url=" + encodeURIComponent(originalUrl) : "")
          );
        }
      } else if (url && url.startsWith("https://www.youtube.com/watch?")) {
        onSupportedUrl(url, false);
      }
    })
  }
);
