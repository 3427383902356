"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireDefault(require("react"));
var _platform = _interopRequireDefault(require("platform"));
var _LoginModal = require("../../../components/Widgets/LoginModal");
var _Widgets = require("../../../components/Widgets");
var _Premium = require("../../../components/Widgets/Premium");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _a, _b, _c, _d;
const isIOS = ((_b = (_a = _platform.default) == null ? void 0 : _a.os) == null ? void 0 : _b.family) === "iOS";
const isMacOS = ((_d = (_c = _platform.default) == null ? void 0 : _c.os) == null ? void 0 : _d.family) === "OS X";
const isCapacitor = location.href.startsWith("capacitor");
const hidePremium = (isIOS || isMacOS) && isCapacitor;
const styles = {
  loginWrapper: {
    "RoomError__styles.loginWrapper": "RoomError__styles.loginWrapper",
    minWidth: "x1jzhcrs",
    maxWidth: "x193iq5w",
    padding: "x1qhigcl",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  paywall: {
    "RoomError__styles.paywall": "RoomError__styles.paywall",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "x1pvqxga",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    minWidth: "x1jzhcrs",
    maxWidth: "x193iq5w",
    padding: "x1qhigcl",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  paywallInner: {
    "RoomError__styles.paywallInner": "RoomError__styles.paywallInner",
    margin: "xhifcr4",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  diamond: {
    "RoomError__styles.diamond": "RoomError__styles.diamond",
    fontSize: "xermeyz",
    $$css: true
  }
};
var _default = ({
  error,
  rejoin
}) => {
  if (error == "PREMIUM_ONLY" && hidePremium) return /* @__PURE__ */_react.default.createElement(_Widgets.ErrorScreen, {
    text: "Room not found"
  });
  switch (error) {
    case "DISCONNECTED":
      return /* @__PURE__ */_react.default.createElement(_Widgets.ErrorScreen, {
        text: "Disconnected"
      }, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        size: "xxl",
        inverted: true,
        color: "yellow",
        onClick: () => {
          rejoin();
        },
        style: {
          marginBottom: 30,
          marginTop: 30,
          height: 52
        }
      }, "Reconnect"));
    case "BANNED":
      return /* @__PURE__ */_react.default.createElement(_Widgets.ErrorScreen, {
        text: "Banned"
      });
    case "PLEASE_LOGIN":
      return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.loginWrapper
      }, /* @__PURE__ */_react.default.createElement(_LoginModal.LoginModalInner, null));
    case "ROOM_NOT_FOUND":
      return /* @__PURE__ */_react.default.createElement(_Widgets.ErrorScreen, {
        text: "Room not found"
      });
    case "PLEASE_LOGIN_WITH_REDDIT_EMAIL":
      return /* @__PURE__ */_react.default.createElement(_Widgets.ErrorScreen, {
        text: "Please Login with your @reddit.com email"
      });
    case "PREMIUM_ONLY":
      return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.paywall
      }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.paywallInner
      }, /* @__PURE__ */_react.default.createElement(_Premium.Diamond, {
        className: styles.diamond
      })), /* @__PURE__ */_react.default.createElement(_Premium.PaywallMessage, {
        source: "PremiumRoom",
        message: "This room is for subscribers only"
      }));
    case "ALREADY_CONNECTED":
      return /* @__PURE__ */_react.default.createElement(_Widgets.ErrorScreen, {
        text: "You are already a member of this room elsewhere"
      }, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        size: "xxl",
        inverted: true,
        color: "yellow",
        onClick: () => {
          rejoin(true);
        },
        style: {
          marginBottom: 30,
          marginTop: 30,
          height: 52
        }
      }, "Disconnect other connection and Join Room"));
  }
  return null;
};
exports.default = _default;