"use strict";
import React from "react";
import { ConnectionContext } from "./ConnectionContext";
import { ApolloProvider } from "@apollo/client";
import { useConnection } from "./client";
import { LoadingScreen } from "./components/Widgets";
export default ({ children }) => {
  const { disconnected, client, connectionId, updateClient } = useConnection();
  if (!client || !connectionId) {
    return /* @__PURE__ */ React.createElement(LoadingScreen, { text: "Connecting" });
  }
  return /* @__PURE__ */ React.createElement(
    ConnectionContext.Provider,
    {
      value: { connectionId, updateClient, disconnected }
    },
    /* @__PURE__ */ React.createElement(ApolloProvider, { client }, children)
  );
};
