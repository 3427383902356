"use strict";
import React, { useState, useRef, memo } from "react";
import { withStyleHook } from "kosmi-sdk/styles";
import style from "./style.css";
function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds % 3600 / 60);
  const remainingSeconds = seconds % 60;
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
export default memo(
  ({
    position,
    seekTo,
    duration,
    disabled
  }) => {
    var _a, _b;
    const styles = withStyleHook(style);
    const inputRef = useRef(null);
    const inputWidth = (_a = inputRef == null ? void 0 : inputRef.current) == null ? void 0 : _a.clientWidth;
    const [hoverPos, setHoverPos] = useState(null);
    const currentPos = position / duration * (inputWidth || 0);
    const rect = (_b = inputRef == null ? void 0 : inputRef.current) == null ? void 0 : _b.getBoundingClientRect();
    let positionStr;
    let skipTo = null;
    const pos = hoverPos === null ? currentPos : hoverPos;
    if (inputWidth && rect) {
      skipTo = Math.round(
        pos / inputWidth * parseInt(duration == null ? void 0 : duration.toString(), 10)
      );
      try {
        positionStr = formatTime(isNaN(skipTo) ? duration : skipTo);
      } catch (e) {
        console.warn(e);
      }
    }
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: styles.videoProgress,
        onMouseMove: (e) => {
          const { offsetX } = e.nativeEvent;
          setHoverPos(offsetX);
        },
        onMouseLeave: () => {
          setHoverPos(null);
        }
      },
      /* @__PURE__ */ React.createElement("progress", { value: position, max: duration }),
      /* @__PURE__ */ React.createElement(
        "input",
        {
          className: styles.seek,
          disabled,
          value: position,
          min: "0",
          max: duration,
          type: "range",
          step: "1",
          style: disabled ? { cursor: "default" } : void 0,
          onChange: (e) => {
            if (seekTo === void 0)
              return;
            if (skipTo !== null) {
              seekTo(skipTo);
            } else {
              seekTo(parseInt(e.target.value));
            }
          },
          ref: inputRef
        }
      ),
      positionStr && pos !== null && /* @__PURE__ */ React.createElement(
        "output",
        {
          style: {
            position: "absolute",
            zIndex: 9999,
            top: "-30px",
            left: pos - (pos > 80 ? 80 : -20) + "px",
            pointerEvents: "none",
            padding: "3px",
            borderRadius: "2px",
            background: "rgba(0,0,0,0.5)"
          }
        },
        positionStr
      )
    );
  }
);
