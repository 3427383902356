"use strict";
import React, { forwardRef, memo, useState } from "react";
import { withStyleHook } from "../../../../../../sdk/styles";
import AccountDropdown from "./components/AccountDropdown";
import { currentUserHook } from "../../../../../helpers/withCurrentUser";
import KosmiDropdown from "./components/KosmiDropdown";
import MessagesDropdown from "./components/MessagesDropdown";
import NotificationDropdown from "./components/NotificationDropdown";
import RoomsDropdown from "./components/RoomsDropdown";
import DisplayNameModal from "./components/DisplayNameModal";
import { PremiumModal, Diamond } from "../../../../../Widgets/Premium";
import style from "./style.css";
import { Button, Icon } from "kosmi-sdk/uikit";
import { useLocation } from "react-router-dom";
function toggleFullscreen(elem) {
  elem = elem || document.documentElement;
  if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
    return true;
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      ;
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      ;
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      ;
      document.webkitExitFullscreen();
    }
    return false;
  }
}
export const TopBarButton = memo(
  forwardRef(({ onClick, children }, ref) => {
    const styles = withStyleHook(style);
    return /* @__PURE__ */ React.createElement(
      Button,
      {
        ref,
        color: "white",
        inverted: true,
        className: styles.topBarButton,
        borderLess: true,
        onClick
      },
      children
    );
  })
);
export default memo(() => {
  const currentUser = currentUserHook();
  const styles = withStyleHook(style);
  const [isFullscreen, setIsFullscreen] = useState(
    !!document.fullscreenElement || false
  );
  const location = useLocation();
  if (location.pathname.startsWith("/signup/"))
    return null;
  return /* @__PURE__ */ React.createElement("div", { id: styles.header }, /* @__PURE__ */ React.createElement("div", { className: styles.leftSide }, /* @__PURE__ */ React.createElement("div", { className: styles.leftButtons }, /* @__PURE__ */ React.createElement(KosmiDropdown, null), /* @__PURE__ */ React.createElement(NotificationDropdown, null)), /* @__PURE__ */ React.createElement(RoomsDropdown, null)), /* @__PURE__ */ React.createElement("div", { className: styles.rightSide }, /* @__PURE__ */ React.createElement(
    PremiumModal,
    {
      source: "Header Button",
      trigger: !currentUser.isAnonymous && !currentUser.isSubscribed && !window.location.host.endsWith("kosmi.business") && /* @__PURE__ */ React.createElement(
        Button,
        {
          color: "red",
          style: {
            padding: 0,
            borderWidth: 0,
            width: 115,
            position: "absolute",
            right: "auto",
            left: 0
          }
        },
        /* @__PURE__ */ React.createElement(Diamond, null),
        "Subscribe"
      )
    }
  ), !window.location.host.endsWith("kosmi.business") && !currentUser.isAnonymous && /* @__PURE__ */ React.createElement(MessagesDropdown, null), /* @__PURE__ */ React.createElement(AccountDropdown, null), window.location.host === "comcast.kosmi.business" && /* @__PURE__ */ React.createElement(DisplayNameModal, null), /* @__PURE__ */ React.createElement(
    TopBarButton,
    {
      onClick: () => {
        const result = toggleFullscreen();
        setIsFullscreen(result);
      }
    },
    /* @__PURE__ */ React.createElement(Icon, { name: isFullscreen ? "compress" : "expand" })
  )), /* @__PURE__ */ React.createElement("div", { className: styles.bottomBorder }));
});
