"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Loader = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _reactNative = require("react-native");
var _colors = require("../layout/colors");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const styles = {
  loader: {
    "Loader__styles.loader": "Loader__styles.loader",
    display: "x78zum5",
    fontSize: "x1pvqxga",
    flexDirection: "xdt5ytf",
    fontFamily: "x1l1c18b",
    justifyContent: "xl56j7k",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    maxHeight: "xmz0i5r",
    maxWidth: "x193iq5w",
    width: "xh8yej3",
    height: "x5yr21d",
    alignItems: "x6s0dn4",
    $$css: true
  },
  text: {
    "Loader__styles.text": "Loader__styles.text",
    marginTop: "x1xmf6yo",
    $$css: true
  },
  absolute: {
    "Loader__styles.absolute": "Loader__styles.absolute",
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    top: "x13vifvy",
    bottom: "x1ey2m1c",
    $$css: true
  }
};
const Loader = exports.Loader = (0, _react.memo)(({
  size,
  className,
  children,
  content,
  id,
  color,
  absolute
}) => {
  let indicatorSize = void 0;
  if (size === "massive") indicatorSize = 70;
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    id,
    style: [styles.loader, absolute && styles.absolute, className]
  }, /* @__PURE__ */_react.default.createElement(_reactNative.ActivityIndicator, {
    size: indicatorSize,
    color: color ? color : size === "massive" ? (0, _colors.getVar)("secondary") : (0, _colors.getVar)("foreground")
  }), (content || children) && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: styles.text
  }, content, children));
});