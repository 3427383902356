"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConnectingLoader = void 0;
require("emoji-picker-element");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _client = require("react-dom/client");
var _reactRouterDom = require("react-router-dom");
var global = _interopRequireWildcard(require("./services/global"));
var _capacitor = require("./services/capacitor");
var _AVEngine = _interopRequireDefault(require("../core/services/AVEngine"));
var _AppContext = _interopRequireDefault(require("./AppContext"));
var _AppRouter = _interopRequireWildcard(require("./AppRouter"));
var _SidebarHead = _interopRequireDefault(require("./components/Room/components/Sidebar/components/SidebarHead"));
var _AVPermissionsWarningModal = _interopRequireDefault(require("./components/Widgets/AVPermissionsWarningModal"));
var _Mobile = _interopRequireDefault(require("./components/Widgets/Mobile"));
var _RootSubscriptions = _interopRequireDefault(require("./RootSubscriptions"));
require("./theme/kosmi.css");
var _withMobileDetection = require("./components/helpers/withMobileDetection");
var _KosmiClientProvider = _interopRequireDefault(require("./KosmiClientProvider"));
var _ConnectionContext = require("./ConnectionContext");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
global.init();
const slideBackandForth = "x1vx0qrx-B";
const ConnectingLoader = () => {
  const {
    className
  } = {
    className: "App__styles.connectingLoader x36qwtl xfo81ep xh8yej3 x10l6tqk x13vifvy xb3r6kr x1dc95m1 xakli9p x1mbsb8t x1qy094b x1esw782 xpz12be xa4qsjk x78zum5 xl56j7k x6s0dn4"
  };
  const {
    disconnected
  } = (0, _react.useContext)(_ConnectionContext.ConnectionContext);
  if (!disconnected) return null;
  return /* @__PURE__ */_react.default.createElement("div", {
    className
  });
};
exports.ConnectingLoader = ConnectingLoader;
const CapacitorListeners = (0, _react.memo)(() => {
  (0, _capacitor.useCapacitor)();
  return null;
});
const App = (0, _react.memo)(() => {
  const {
    isMobile
  } = (0, _withMobileDetection.useIsMobile)();
  const [showNav, setShowNav] = (0, _react.useState)(true);
  const [roomsDropdownOpen, setRoomDropdownOpen] = (0, _react.useState)(false);
  const [showAvPermissionsModal, setShowAVPermissionsModal] = (0, _react.useState)(false);
  const [tutorial, setTutorial] = (0, _react.useState)({
    step: 1,
    roomId: null
  });
  (0, _RootSubscriptions.default)();
  (0, _react.useEffect)(() => {
    _AVEngine.default.eventEmitter.on("av-permissions-granted", granted => {
      setShowAVPermissionsModal(!granted);
    });
  }, []);
  const setTutorialStep = (0, _react.useCallback)(step => setTutorial(__spreadProps(__spreadValues({}, tutorial), {
    step
  })), [JSON.stringify(tutorial)]);
  const setTutorialRoomId = (0, _react.useCallback)(roomId => setTutorial(__spreadProps(__spreadValues({}, tutorial), {
    roomId
  })), [JSON.stringify(tutorial)]);
  const toggleNav = (0, _react.useCallback)(() => {
    setShowNav(!showNav);
  }, [showNav]);
  const toggleRoomsDropdown = (0, _react.useCallback)(() => {
    setRoomDropdownOpen(!roomsDropdownOpen);
  }, [roomsDropdownOpen]);
  const context = (0, _react.useMemo)(() => ({
    tutorial,
    setTutorialStep,
    setTutorialRoomId,
    toggleNav,
    showNav,
    roomsDropdownOpen,
    toggleRoomsDropdown
  }), [tutorial, setTutorialStep, setTutorialRoomId, toggleNav, showNav, roomsDropdownOpen, toggleRoomsDropdown]);
  const {
    className
  } = {
    className: "App__styles.currentRoute x78zum5 x1n2onr6 x193iq5w xdt5ytf xysyzu8 x5yr21d x98rzlu"
  };
  return /* @__PURE__ */_react.default.createElement(_reactRouterDom.BrowserRouter, {
    getUserConfirmation: global.getUserConfirmation
  }, /* @__PURE__ */_react.default.createElement(_AppRouter.RouterListeners, null), /* @__PURE__ */_react.default.createElement(CapacitorListeners, null), /* @__PURE__ */_react.default.createElement(_AppContext.default.Provider, {
    value: context
  }, !isMobile && /* @__PURE__ */_react.default.createElement(ConnectingLoader, null), showNav && !isMobile && /* @__PURE__ */_react.default.createElement(_SidebarHead.default, null), /* @__PURE__ */_react.default.createElement(_Mobile.default, null, /* @__PURE__ */_react.default.createElement("div", {
    className
  }, /* @__PURE__ */_react.default.createElement(_AppRouter.default, null))), /* @__PURE__ */_react.default.createElement(_AVPermissionsWarningModal.default, {
    open: showAvPermissionsModal,
    onClose: () => setShowAVPermissionsModal(false)
  })));
});
const LaunchScreen = (0, _react.memo)(({
  onLaunch
}) => {
  const {
    className: wrapperClassName
  } = {
    className: "App__styles.launchWrapper xh8yej3 x5yr21d x78zum5 xdt5ytf x6s0dn4 xl56j7k"
  };
  return /* @__PURE__ */_react.default.createElement("div", {
    className: wrapperClassName
  }, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    size: "xl",
    color: "yellow",
    inverted: true,
    style: {
      margin: "auto",
      width: 240,
      height: 120,
      flexDirection: "column"
    },
    onClick: onLaunch
  }, /* @__PURE__ */_react.default.createElement("img", {
    src: "https://kosmi.io/assets/kosmihead_tinted.svg",
    style: {
      width: "50px",
      marginBottom: "20px"
    }
  }), "Click here to launch"));
});
const AppWrapper = (0, _react.memo)(() => {
  const [launched, setLaunched] = (0, _react.useState)(!!location.pathname.startsWith("/verifyEmail") || !!location.pathname.startsWith("/signup"));
  const onLaunch = (0, _react.useMemo)(() => () => setLaunched(true), []);
  if (location.pathname !== "/" && !launched && !window.localStorage.getItem("token")) {
    return /* @__PURE__ */_react.default.createElement(LaunchScreen, {
      onLaunch
    });
  }
  return /* @__PURE__ */_react.default.createElement(_KosmiClientProvider.default, null, /* @__PURE__ */_react.default.createElement(App, null));
});
const appElement = document.getElementById("app");
const root = (0, _client.createRoot)(appElement);
root.render( /* @__PURE__ */_react.default.createElement(AppWrapper, null));