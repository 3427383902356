"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Input } from "kosmi-sdk/uikit";
import React, { useState } from "react";
import { withStyleHook } from "kosmi-sdk/styles";
import style from "./style.css";
import ContentBrowser from "./components/ContentBrowser";
import VideoChoiceMenu from "./components/VideoChoiceMenu";
import CategoryMenu from "./components/CategoryMenu";
import { gql, useQuery } from "kosmi-sdk/gql";
import BackButton from "../../components/BackButton";
import { useIsMobile } from "../../../../../../helpers/withMobileDetection";
const HEADINGS = gql(`
  query MediaContentHeadings {
    mediaContentHeadings {
      name
      categories
      mediaTypes
    }
    __type(name: "MediaType") {
      enumValues {
        name
        description
      }
    }
  }
`);
const useHeadings = () => {
  var _a, _b;
  const { data } = useQuery(HEADINGS, { fetchPolicy: "cache-first" });
  if (!((_a = data == null ? void 0 : data.mediaContentHeadings) == null ? void 0 : _a.length))
    return [];
  const mediaTypes = (_b = data.__type) == null ? void 0 : _b.enumValues;
  return data.mediaContentHeadings.map((r) => {
    var _a2;
    return __spreadProps(__spreadValues({}, r), {
      mediaTypes: (_a2 = r == null ? void 0 : r.mediaTypes) == null ? void 0 : _a2.map(
        (t) => mediaTypes == null ? void 0 : mediaTypes.find((e) => e.name === t)
      )
    });
  });
};
const SearchForm = ({
  value,
  setValue
}) => {
  const styles = withStyleHook(style);
  return /* @__PURE__ */ React.createElement("div", { className: styles.search }, /* @__PURE__ */ React.createElement(
    Input,
    {
      className: styles.searchInput,
      debounce: 300,
      debounceMinLength: 2,
      placeholder: "Search apps and media content",
      value,
      onChange: (e) => setValue(e.target.value),
      icon: "search"
    }
  ));
};
export default ({
  setScreen,
  screen,
  closeModal,
  setModalTitle,
  openCustomScreenshare
}) => {
  const headings = useHeadings();
  const styles = withStyleHook(style);
  const [searchQuery, setSearchQuery] = useState("");
  const [categories, setCategories] = useState([]);
  const [_mediaTypes, setMediaTypes] = useState([]);
  const { isMobile } = useIsMobile();
  let mediaTypes = _mediaTypes;
  if (isMobile) {
    mediaTypes = !searchQuery && !categories.length ? ["CATALOG"] : ["VIDEO"];
  }
  const showNav = headings.some((h) => {
    return h.categories && JSON.stringify(h.categories) === JSON.stringify(categories);
  });
  if (screen !== "pick")
    return null;
  return /* @__PURE__ */ React.createElement("div", { className: styles.root }, showNav ? /* @__PURE__ */ React.createElement("div", { className: styles.navigation }, /* @__PURE__ */ React.createElement(
    VideoChoiceMenu,
    {
      screen,
      onChange: (screen2) => setScreen(screen2)
    }
  ), !isMobile && /* @__PURE__ */ React.createElement(
    CategoryMenu,
    {
      categories,
      setCategories: (categories2) => {
        setSearchQuery("");
        setMediaTypes([]);
        setCategories(categories2);
      }
    }
  )) : /* @__PURE__ */ React.createElement(
    BackButton,
    {
      onClick: () => {
        setSearchQuery("");
        setCategories([]);
        setMediaTypes([]);
        setModalTitle("Load Media");
      }
    }
  ), /* @__PURE__ */ React.createElement("div", { className: styles.content }, /* @__PURE__ */ React.createElement(SearchForm, { value: searchQuery, setValue: setSearchQuery }), /* @__PURE__ */ React.createElement(
    ContentBrowser,
    {
      closeModal,
      setCategories,
      categories,
      searchQuery,
      setModalTitle,
      openCustomScreenshare,
      setMediaTypes,
      mediaTypes,
      showTags: showNav
    }
  )));
};
