"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, useState, memo } from "react";
const baseStyle = {
  display: "flex",
  flex: 1,
  width: "100%",
  height: "100%",
  flexDirection: "column",
  maxHeight: "100%",
  transition: "opacity 0.3s ease-in-out"
};
const FadeInTransition = memo(
  (props) => {
    const [style, setStyle] = useState(__spreadProps(__spreadValues({}, baseStyle), {
      opacity: 0
    }));
    useEffect(() => {
      const timeout = setTimeout(
        () => setStyle(__spreadProps(__spreadValues({}, style), {
          opacity: 1
        }))
      );
      return () => {
        if (timeout)
          clearTimeout(timeout);
      };
    }, []);
    return /* @__PURE__ */ React.createElement("div", { className: props.className, style: __spreadValues(__spreadValues({}, style), props.style) }, props.children);
  }
);
export default FadeInTransition;
