"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _gql = require("kosmi-sdk/gql");
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _reactRouterDom = require("react-router-dom");
var _AppContext = _interopRequireDefault(require("../AppContext"));
var _CreateRoomModal = _interopRequireDefault(require("../components/Widgets/CreateRoomModal"));
var _FadeInTransition = _interopRequireDefault(require("../components/Widgets/FadeInTransition"));
var _kosmihead_tinted = _interopRequireDefault(require("./room/components/Sidebar/components/RoomBanner/kosmihead_tinted.svg"));
var _LoginWall = _interopRequireDefault(require("../components/Widgets/LoginWall"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _helpers = require("kosmi-sdk/helpers");
var _useMobileDetection = require("@/hooks/useMobileDetection");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const USER_ROOMS = (0, _gql.gql)(`
  query UserRoomQueryWelcome {
    userRooms {
      id
      unreadMessages
      state {
        metadata {
          roomName
          avatarUrl
        }
        isLive
        members {
          id
          role
          user {
            id
            username
            displayName
            avatarUrl
            friends {
              state
              user {
                id
                displayName
                username
                avatarUrl
                email
                isConnected
              }
            }
          }
        }
      }
    }
  }
`);
const styles = {
  discover: {
    "welcome__styles.discover": "welcome__styles.discover",
    display: "x78zum5",
    width: "xh8yej3",
    maxHeight: "xmz0i5r",
    flexDirection: "xdt5ytf",
    fontSize: "x1j61zf2",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  noFriendsOnline: {
    "welcome__styles.noFriendsOnline": "welcome__styles.noFriendsOnline",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    "@media(max-width: 1000px)_margin": "x1q3lw3x",
    "@media(max-width: 1000px)_marginInline": null,
    "@media(max-width: 1000px)_marginInlineStart": null,
    "@media(max-width: 1000px)_marginLeft": null,
    "@media(max-width: 1000px)_marginInlineEnd": null,
    "@media(max-width: 1000px)_marginRight": null,
    "@media(max-width: 1000px)_marginBlock": null,
    "@media(max-width: 1000px)_marginTop": null,
    "@media(max-width: 1000px)_marginBottom": null,
    "@media(max-width: 1000px)_display": "x4m06ut",
    $$css: true
  },
  discoverInner: {
    "welcome__styles.discoverInner": "welcome__styles.discoverInner",
    textAlign: "x2b8uid",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  logo: {
    "welcome__styles.logo": "welcome__styles.logo",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "x4n8cb0",
    width: "x1dmp6jm",
    height: "xwzfr38",
    $$css: true
  },
  welcome: {
    "welcome__styles.welcome": "welcome__styles.welcome",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "xdj266r",
    marginBottom: "x4n8cb0",
    fontSize: "x1pvqxga",
    $$css: true
  },
  subtitle: {
    "welcome__styles.subtitle": "welcome__styles.subtitle",
    marginBottom: "x4n8cb0",
    $$css: true
  },
  legalLinks: {
    "welcome__styles.legalLinks": "welcome__styles.legalLinks",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  link: {
    "welcome__styles.link": "welcome__styles.link",
    color: "x3bicyu",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  }
};
const Welcome = (0, _react.memo)(() => {
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const currentUser = (0, _helpers.currentUserHook)();
  const track = (0, _helpers.useTracking)();
  const history = (0, _reactRouterDom.useHistory)();
  const {
    data
  } = (0, _gql.useQuery)(USER_ROOMS);
  const appContext = _react.default.useContext(_AppContext.default);
  if (!data) return null;
  return /* @__PURE__ */_react.default.createElement(_FadeInTransition.default, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.discover
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.discoverInner
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: styles.logo,
    src: _kosmihead_tinted.default,
    alt: "Kosmi Logo"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.welcome
  }, "Welcome to Kosmi"), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subtitle
  }, "The place to hang out with friends and colleagues.", " "), data.userRooms && data.userRooms.length && !isMobile ? /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "blue",
    style: {
      margin: "auto",
      marginTop: 0,
      height: 50,
      width: 120
    },
    onClick: () => {
      var _a, _b;
      if (((_a = data == null ? void 0 : data.userRooms) == null ? void 0 : _a.length) === 1) {
        history.push(`/room/${(_b = data.userRooms[0]) == null ? void 0 : _b.id}`);
      } else {
        appContext.toggleRoomsDropdown();
      }
    }
  }, "My room", (data == null ? void 0 : data.userRooms.length) > 1 ? "s" : "")) : /* @__PURE__ */_react.default.createElement(_CreateRoomModal.default, {
    onOpen: () => track("UI", "CreateRoomModalOpenedFromFrontPage", "CreateRoom Modal Opened from Front Page"),
    onExplicitClose: () => {
      var _a;
      if (currentUser.isAnonymous && ((_a = window == null ? void 0 : window.featureFlags) == null ? void 0 : _a.requireLoginToCreateRoom)) {
        track("UI", "CreateRoomModalLoginRequiredClosed", "CreateRoom Modal closed (Login required)");
      } else if (currentUser.isAnonymous) {
        track("UI", "CreateRoomModalLoginNotRequiredClosed", "CreateRoom Modal closed (Login not required)");
      } else {
        track("UI", "CreateRoomModalClosed", "CreateRoom Modal closed");
      }
    },
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      style: {
        height: 50,
        width: 220,
        margin: "auto"
      },
      color: "yellow"
    }, "Click here to create a room")
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.noFriendsOnline
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.subtitle
  }, "No friends online?"), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    inverted: true,
    color: "white",
    onClick: () => {
      history.push("/lobby");
    },
    style: {
      height: 50,
      maxWidth: 150
    }
  }, "Join the lobby")), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.legalLinks
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    style: styles.link,
    target: "_blank",
    href: "https://www.iubenda.com/terms-and-conditions/30233393",
    rel: "noreferrer"
  }, "Terms & Conditions"), /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    style: styles.link,
    target: "_blank",
    href: "https://www.iubenda.com/privacy-policy/30233393/legal",
    rel: "noreferrer"
  }, "Privacy Policy"))));
});
var _default = exports.default = Welcome;