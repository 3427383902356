"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation, useQuery } from "kosmi-sdk/gql";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Form, Loader, Settings } from "kosmi-sdk/uikit";
import React, { useState, useRef, useEffect } from "react";
import { withStyleHook } from "../../../../sdk/styles";
import countries from "../../../helpers/countries.json";
import { currentUserHook } from "../../../helpers/withCurrentUser";
import withDialogs from "../../../helpers/withDialogs";
import style from "../style.css";
import poweredByStripe from "./stripe.svg";
import { useTracking } from "kosmi-sdk/helpers";
const SUBSCRIBE = gql(`
  mutation CreateSubscription($paymentMethodId: String!) {
    createStripeSubscription(paymentMethodId: $paymentMethodId) {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
  }
`);
const GET_PRODUCT = gql(`
  query GetSubscriptionProduct {
    getStripeSubscriptionProduct {
      stripePriceId
      usdAmount
      stripePublishableKey
    }
  }
`);
const GET_SUBSCRIPTION = gql(`
  query GetSubscription {
    currentStripeSubscription {
      id
      cancelAtPeriodEnd
      periodEnds
      status
    }
    currentUser {
      id
      connectionId
      user {
        id
        isSubscribed
      }
    }
  }
`);
const useKosmiStripe = () => {
  const { data } = useQuery(GET_PRODUCT);
  const { stripePublishableKey } = (data == null ? void 0 : data.getStripeSubscriptionProduct) || {};
  const [stripePromise, setStripePromise] = useState(null);
  const initializeStripe = () => {
    if (!stripePromise && stripePublishableKey) {
      setStripePromise(loadStripe(stripePublishableKey));
    }
  };
  useEffect(() => {
    if (!stripePromise && stripePublishableKey) {
      initializeStripe();
    }
  }, [stripePromise, stripePublishableKey]);
  return stripePromise;
};
const withStripe = (Component) => {
  const EnhancedComponent = (props) => {
    const stripePromise = useKosmiStripe();
    if (!stripePromise)
      return null;
    return /* @__PURE__ */ React.createElement(Elements, { stripe: stripePromise }, /* @__PURE__ */ React.createElement(Component, __spreadValues({}, props)));
  };
  return EnhancedComponent;
};
const WrappedStripeInput = ({ children }) => {
  const [focused, setFocused] = useState(false);
  return /* @__PURE__ */ React.createElement(Form.InputWrapper, { focused }, React.cloneElement(children, {
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false)
  }));
};
export default withDialogs(
  withStripe(
    ({
      trigger,
      onPaymentMethod,
      toggle,
      checkout,
      alert,
      source,
      onBack
    }) => {
      const styles = withStyleHook(style);
      const currentUser = currentUserHook();
      const stripe = useStripe();
      const elements = useElements();
      const [name, setName] = useState("");
      const toggleRef = useRef(toggle);
      const [disabled, setDisabled] = useState(toggle);
      const [country, setCountry] = useState((currentUser == null ? void 0 : currentUser.countryCode) || "");
      const [zipCode, setZipCode] = useState("");
      const reset = () => {
        setName("");
        setDisabled(toggleRef.current);
        setCountry((currentUser == null ? void 0 : currentUser.countryCode) || "");
        setZipCode("");
        if (onBack) {
          onBack();
        }
      };
      const settingsRouter = Settings.useSettingsRouter();
      const [subscribe] = useMutation(SUBSCRIBE);
      const track = useTracking();
      const { refetch } = useQuery(GET_SUBSCRIPTION, {
        fetchPolicy: "network-only"
      });
      const [loading, setLoading] = useState(false);
      const handleSubmit = () => __async(void 0, null, function* () {
        try {
          setLoading(true);
          if (!stripe || !elements)
            throw new Error("Stripe not initialized!");
          const card = elements.getElement(CardNumberElement);
          if (!card)
            throw new Error("card not found!");
          const { error, paymentMethod } = yield stripe.createPaymentMethod({
            type: "card",
            card,
            billing_details: {
              name,
              address: {
                country,
                postal_code: zipCode
              }
            }
          });
          if (error) {
            throw error;
          }
          if (checkout) {
            yield subscribe({
              variables: { paymentMethodId: paymentMethod.id }
            });
            track("Premium", "Checkout", source ? source : "Checkout");
            refetch();
            settingsRouter.pop(1);
            return false;
          } else if (onPaymentMethod) {
            onPaymentMethod(paymentMethod);
          }
          return true;
        } catch (e) {
          alert(e.message);
        } finally {
          setLoading(false);
        }
        return false;
      });
      const options = {
        style: {
          base: {
            backgroundColor: "transparent",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 10px",
            color: "rgb(230, 230, 230)",
            fontSize: "14px",
            lineHeight: "36px",
            padding: "12px"
          }
        }
      };
      let saveText = "Save";
      if (disabled) {
        saveText = "Edit";
      }
      if (checkout) {
        saveText = "Checkout";
      }
      return /* @__PURE__ */ React.createElement(
        Settings.Section,
        {
          emphasizeSave: checkout,
          icon: "credit card outline",
          name: "Payment details",
          saveText,
          trigger,
          onBack: reset,
          onSave: !checkout ? () => __async(void 0, null, function* () {
            if (disabled) {
              setDisabled(false);
              return false;
            }
            return yield handleSubmit();
          }) : void 0
        },
        /* @__PURE__ */ React.createElement(Settings.Container, { name: "Payment details" }, loading && /* @__PURE__ */ React.createElement(Loader, { absolute: true, size: "massive" }, "Please wait..."), /* @__PURE__ */ React.createElement(Form, { disabled: disabled || loading, className: styles.screen }, /* @__PURE__ */ React.createElement(
          "a",
          {
            className: styles.stripe,
            href: "https://stripe.com",
            target: "_blank",
            rel: "noreferrer"
          },
          /* @__PURE__ */ React.createElement("img", { className: styles.stripe, src: poweredByStripe })
        ), /* @__PURE__ */ React.createElement(
          Form.Input,
          {
            label: "Name on Card",
            placeholder: disabled ? "************" : "Full name",
            autoFocus: true,
            onChange: (e) => {
              e.preventDefault();
              setName(e.target.value);
            },
            value: name,
            maxLength: 50,
            autoComplete: "cc-name",
            name: "cc-name",
            required: true,
            disabled
          }
        ), /* @__PURE__ */ React.createElement(Form.Group, { inline: true }, /* @__PURE__ */ React.createElement(
          Form.Select,
          {
            options: countries,
            label: "Country or Region",
            placeholder: disabled ? "****" : "Country",
            onChange: (e) => {
              e.preventDefault();
              setCountry(e == null ? void 0 : e.value);
            },
            value: disabled ? "" : country,
            required: true,
            disabled
          }
        ), /* @__PURE__ */ React.createElement(
          Form.Input,
          {
            label: "Postal/ZIP Code",
            autoComplete: "cc-zip",
            name: "cc-zip",
            placeholder: disabled ? "************" : "Postal/ZIP Code",
            onChange: (e) => {
              e.preventDefault();
              setZipCode(e.target.value);
            },
            maxLength: 20,
            value: zipCode,
            required: true,
            disabled
          }
        )), /* @__PURE__ */ React.createElement(Form.Group, { inline: true, className: styles.cc }, /* @__PURE__ */ React.createElement(Form.Field, { disabled, required: true }, /* @__PURE__ */ React.createElement(Form.Label, null, "Card number"), disabled ? /* @__PURE__ */ React.createElement(
          Form.Input,
          {
            value: "******************",
            required: true,
            disabled: true
          }
        ) : /* @__PURE__ */ React.createElement(WrappedStripeInput, null, /* @__PURE__ */ React.createElement(CardNumberElement, { options }))), /* @__PURE__ */ React.createElement(Form.Field, { disabled, required: true }, /* @__PURE__ */ React.createElement(Form.Label, null, "Expiration"), disabled ? /* @__PURE__ */ React.createElement(Form.Input, { value: "**/**", disabled: true }) : /* @__PURE__ */ React.createElement(WrappedStripeInput, null, /* @__PURE__ */ React.createElement(CardExpiryElement, { options }))), /* @__PURE__ */ React.createElement(Form.Field, { disabled, required: true }, /* @__PURE__ */ React.createElement(Form.Label, null, "CVC"), disabled ? /* @__PURE__ */ React.createElement(Form.Input, { value: "***", disabled: true }) : /* @__PURE__ */ React.createElement(WrappedStripeInput, null, /* @__PURE__ */ React.createElement(CardCvcElement, { options })))), checkout && /* @__PURE__ */ React.createElement(Form.Group, null, /* @__PURE__ */ React.createElement(Form.Field, null, /* @__PURE__ */ React.createElement(
          Form.Button,
          {
            style: { marginLeft: "auto" },
            color: "yellow",
            onClick: handleSubmit,
            disabled: loading
          },
          "Pay now"
        ))), /* @__PURE__ */ React.createElement(
          "div",
          {
            style: {
              margin: "auto",
              padding: 8
            }
          },
          /* @__PURE__ */ React.createElement("em", null, "We do not store your payment information in our system at any point in time.", " "),
          "Any details entered are securely transmitted to our payment processor,",
          " ",
          /* @__PURE__ */ React.createElement("a", { href: "https://stripe.com", target: "_blank", rel: "noreferrer" }, "Stripe"),
          ", who will manage your subscription going forward."
        )))
      );
    }
  )
);
