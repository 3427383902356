"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _reactTransitionGroup = require("react-transition-group");
var _useMessageData = require("../hooks/useMessageData");
var _ImageModal = _interopRequireDefault(require("./ImageModal"));
var _Messages = require("./Sidebar/components/Messages");
var _RoomContext = _interopRequireDefault(require("../contexts/RoomContext"));
var _useRoomId = require("../hooks/useRoomId");
var _Sidebar = require("./Sidebar");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  overlayMessageWrapper: {
    "MessageOverlay__styles.overlayMessageWrapper": "MessageOverlay__styles.overlayMessageWrapper",
    pointerEvents: "x47corl",
    position: "x10l6tqk",
    bottom: "x1ey2m1c",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    zIndex: "x1u8a7rm",
    width: "xh8yej3",
    height: "x5yr21d",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  messages: {
    "MessageOverlay__styles.messages": "MessageOverlay__styles.messages",
    marginTop: "xr1yuqi",
    $$css: true
  },
  messageBoxWrapper: {
    "MessageOverlay__styles.messageBoxWrapper": "MessageOverlay__styles.messageBoxWrapper",
    backgroundColor: "xjbqb8w",
    pointerEvents: "x71s49j",
    display: "x78zum5",
    $$css: true
  },
  messageOverlay: {
    "MessageOverlay__styles.messageOverlay": "MessageOverlay__styles.messageOverlay",
    position: "x10l6tqk",
    width: "xdzyupr",
    height: "x5yr21d",
    maxHeight: "x1rkru5j",
    zIndex: "x1n327nk",
    display: "x78zum5",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    bottom: "xbfrwjf",
    alignItems: "xuk3077",
    $$css: true
  },
  overlayMessageUser: {
    "MessageOverlay__styles.overlayMessageUser": "MessageOverlay__styles.overlayMessageUser",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    $$css: true
  },
  overlayMessageUserAvatar: {
    "MessageOverlay__styles.overlayMessageUserAvatar": "MessageOverlay__styles.overlayMessageUserAvatar",
    width: "xezl2tj",
    marginRight: "xyo1k6t",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  overlayMessage: {
    "MessageOverlay__styles.overlayMessage": "MessageOverlay__styles.overlayMessage",
    pointerEvents: "x71s49j",
    cursor: "x1ypdohk",
    padding: "x1tamke2",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    backgroundColor: "x9ck2vs",
    backdropFilter: "x17nw0yu",
    WebkitBackdropFilter: "x1tovznt",
    width: "xdzyupr",
    borderRadius: "x1m3ak92",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: "x8x9d4c",
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "xr1yuqi",
    marginBottom: null,
    zIndex: "x1n327nk",
    $$css: true
  },
  enter: {
    "MessageOverlay__styles.enter": "MessageOverlay__styles.enter",
    opacity: "xg01cxk",
    transform: "xumwmo6",
    $$css: true
  },
  enterActive: {
    "MessageOverlay__styles.enterActive": "MessageOverlay__styles.enterActive",
    opacity: "x1hc1fzr",
    transform: "xbryuvx",
    transition: "x1j7l3zm",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  exit: {
    "MessageOverlay__styles.exit": "MessageOverlay__styles.exit",
    opacity: "x1hc1fzr",
    transform: "xbryuvx",
    $$css: true
  },
  exitActive: {
    "MessageOverlay__styles.exitActive": "MessageOverlay__styles.exitActive",
    opacity: "xg01cxk",
    transform: "xumwmo6",
    transition: "x1j7l3zm",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  }
};
const getClassName = s => stylex.props(s).className;
var _default = exports.default = (0, _react.memo)(() => {
  var _a, _b;
  const roomId = (0, _useRoomId.useRoomId)();
  const [messages, setMessages] = (0, _react.useState)([]);
  const timeoutsRef = (0, _react.useRef)([]);
  const [imageZoomed, setImageZoomed] = (0, _react.useState)(null);
  const {
    data
  } = (0, _useMessageData.useMessagesCached)(roomId, "general");
  const newestMessage = (_b = (_a = data == null ? void 0 : data.chatArchive) == null ? void 0 : _a.results) == null ? void 0 : _b.at(-1);
  const firstRender = (0, _react.useRef)(true);
  (0, _react.useEffect)(() => {
    if (!newestMessage) return;
    if (firstRender.current) return;
    setMessages(messages2 => [...messages2, newestMessage].slice(-3));
    const to = setTimeout(() => {
      setMessages(messages2 => [...messages2].filter(m => m.id !== newestMessage.id));
      timeoutsRef.current = timeoutsRef.current.filter(t => t !== to);
    }, 5e3);
    timeoutsRef.current.push(to);
  }, [newestMessage == null ? void 0 : newestMessage.id]);
  (0, _react.useEffect)(() => {
    firstRender.current = false;
    return () => {
      const timeouts = timeoutsRef.current;
      timeouts.forEach(clearTimeout);
    };
  }, []);
  const zoomImage = url => {
    setImageZoomed(url);
  };
  const {
    showChatBoxWhenChatHidden
  } = (0, _react.useContext)(_RoomContext.default);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messageOverlay
  }, /* @__PURE__ */_react.default.createElement(_ImageModal.default, {
    open: imageZoomed ? true : false,
    handleClose: () => setImageZoomed(null),
    imageSrc: imageZoomed || ""
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.overlayMessageWrapper
  }, /* @__PURE__ */_react.default.createElement(_reactTransitionGroup.TransitionGroup, {
    style: styles.messages
  }, messages.map(message => /* @__PURE__ */_react.default.createElement(_reactTransitionGroup.CSSTransition, {
    key: message.id,
    timeout: 500,
    classNames: {
      enter: getClassName(styles.enter),
      enterActive: getClassName(styles.enterActive),
      exit: getClassName(styles.exit),
      exitActive: getClassName(styles.exitActive)
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.overlayMessage,
    onClick: () => {
      setMessages(messages2 => [...messages2].filter(m => m.id !== message.id));
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.overlayMessageUser
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: styles.overlayMessageUserAvatar,
    circular: true,
    src: message.user.avatarUrl
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, message.user.displayName)), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, (0, _Messages.transformText)(message.body, zoomImage)))))), showChatBoxWhenChatHidden && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.messageBoxWrapper
  }, /* @__PURE__ */_react.default.createElement(_Sidebar.MobileMessagebox, null))));
});