"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabView = void 0;
var _react = _interopRequireWildcard(require("react"));
var _Menu = require("../Menu");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  tabView: {
    "index__styles.tabView": "index__styles.tabView",
    flex: "x98rzlu",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    minHeight: "x2lwn1j",
    maxWidth: "x193iq5w",
    $$css: true
  },
  menuItem: {
    "index__styles.menuItem": "index__styles.menuItem",
    flex: "x98rzlu",
    fontWeight: "x1xlr1w8",
    alignItems: "x6s0dn4",
    width: "x3hqpx7",
    position: "x1n2onr6",
    borderRadius: "x12oqio5",
    justifyContent: "xl56j7k",
    transition: "xxksqoq",
    $$css: true
  },
  tabviewItem: {
    "index__styles.tabviewItem": "index__styles.tabviewItem",
    maxWidth: "x193iq5w",
    flex: "x98rzlu",
    flexGrow: "x1c4vz4f",
    flexShrink: "x2lah0s",
    flexBasis: "x1l7klhg",
    minHeight: "x2lwn1j",
    display: "x78zum5",
    height: "x5yr21d",
    flexDirection: "xdt5ytf",
    transform: "xmyttsv",
    transition: "x1alzeyj",
    justifyContent: "x1nhvcw1",
    overflow: "xysyzu8",
    alignItems: "x6s0dn4",
    $$css: true
  }
};
const TabViewItem = ({
  children,
  style,
  className: classNameProp
}) => {
  const {
    className
  } = stylex.props(styles.tabviewItem, classNameProp);
  return /* @__PURE__ */_react.default.createElement("div", {
    className,
    style
  }, children);
};
const Underline = ({
  percentage,
  width
}) => {
  const {
    className
  } = {
    className: "index__styles.underline x10l6tqk xbfrwjf x14xb6xe x1iy03kw xxdbxwx x12oqio5 x36qwtl xhtitgo"
  };
  return /* @__PURE__ */_react.default.createElement("div", {
    className,
    style: {
      width: `${width}%`,
      left: `${percentage - width / 2}%`
    }
  });
};
const SwipableWrapper = ({
  children,
  index
}) => {
  const {
    className
  } = {
    className: "index__styles.swipableWrapper x78zum5 x2lwn1j xb3r6kr x98rzlu x1n2onr6 x6s0dn4 xh8yej3"
  };
  return /* @__PURE__ */_react.default.createElement("div", {
    className
  }, _react.default.Children.map(children, (c, i) => {
    var _a;
    return _react.default.cloneElement(c, {
      key: i,
      style: __spreadProps(__spreadValues({}, (_a = c == null ? void 0 : c.props) == null ? void 0 : _a.style), {
        transform: `translate3d(-${index * 100}%,0,0)`
      })
    });
  }));
};
const TabViewMenu = ({
  index,
  onTabChange,
  items
}) => {
  const {
    className: menuClassName
  } = {
    className: "index__styles.menu xfawy5m"
  };
  return /* @__PURE__ */_react.default.createElement(_Menu.Menu, {
    tabular: true,
    className: menuClassName
  }, /* @__PURE__ */_react.default.createElement(Underline, {
    width: 100 / items.length - 8,
    percentage: (index + 1) / items.length * 100 - 100 / items.length / 2
  }), items.map((item, i) => {
    const {
      className
    } = stylex.props(styles.menuItem, i === index);
    return /* @__PURE__ */_react.default.createElement(_Menu.Menu.Item, {
      key: i,
      active: i === index,
      onClick: () => {
        onTabChange(i);
      },
      className
    }, item);
  }));
};
const TabView = exports.TabView = Object.assign((0, _react.memo)((0, _react.forwardRef)(({
  children,
  style,
  onTabChange,
  forceIndex,
  menu,
  className: classNameProp
}, ref) => {
  var _a, _b;
  menu = menu === void 0 ? true : menu;
  const childCompnents = _react.default.Children.toArray(children).filter(c => !!c);
  const [_index, setIndex] = (0, _react.useState)(0);
  const {
    className
  } = stylex.props(styles.tabView, classNameProp);
  const index = forceIndex !== void 0 ? forceIndex : _index;
  (0, _react.useEffect)(() => {
    onTabChange && onTabChange(index);
  }, [_index, onTabChange, forceIndex]);
  return /* @__PURE__ */_react.default.createElement("div", {
    ref,
    style,
    className
  }, childCompnents.length > 1 && menu && /* @__PURE__ */_react.default.createElement(TabViewMenu, {
    index,
    items: childCompnents.map(item => {
      var _a2;
      return (_a2 = item == null ? void 0 : item.props) == null ? void 0 : _a2.name;
    }),
    onTabChange: i => setIndex(i)
  }), !((_b = (_a = childCompnents[index]) == null ? void 0 : _a.props) == null ? void 0 : _b.empty) && /* @__PURE__ */_react.default.createElement(SwipableWrapper, {
    index
  }, childCompnents));
})), {
  Item: TabViewItem,
  Menu: TabViewMenu
});