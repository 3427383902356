"use strict";
import { gql, useQuery } from "kosmi-sdk/gql";
const COLOR_SCHEMES = gql(`
  query ColorSchemeQuery {
    colorSchemes {
      id
      name
      primaryColor
      secondaryColor
      foregroundColor
      darkMode
    }
  }
`);
export default () => {
  const { data } = useQuery(COLOR_SCHEMES, {
    fetchPolicy: "cache-first"
  });
  return (data == null ? void 0 : data.colorSchemes) || [];
};
