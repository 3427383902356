"use strict";
import React, { memo, useState } from "react";
import MediaPlayerContext from "../contexts/MediaPlayerContext";
export default memo(
  ({ children }) => {
    const [localUrl, setLocalUrl] = useState("");
    const [localStream, setLocalStream] = useState(null);
    const [relayStream, setRelayStream] = useState(false);
    const [player, setPlayer] = useState(null);
    return /* @__PURE__ */ React.createElement(
      MediaPlayerContext.Provider,
      {
        value: {
          localUrl,
          localStream,
          setLocalStream,
          relayStream,
          setRelayStream,
          setLocalUrl: (url) => setLocalUrl(url || ""),
          player,
          setPlayer
        }
      },
      children
    );
  }
);
