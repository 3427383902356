"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Transition = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
const slideInY = "xddk0gz-B";
const slideInX = "x1dyssvo-B";
const slideOutY = "x194ms9s-B";
const slideOutX = "x6tv30u-B";
const fadeIn = "xekv6nw-B";
const styles = {
  transition: {
    "index__styles.transition": "index__styles.transition",
    animationIterationCount: "x1v7wizp",
    animationDuration: "x7t6ubo",
    animationTimingFunction: "x4hg4is",
    animationFillMode: "x1u6ievf",
    $$css: true
  },
  slideDownIn: {
    "index__styles.slideDownIn": "index__styles.slideDownIn",
    animationName: "xt8xfk9",
    transformOrigin: "x1dp6rp6",
    $$css: true
  },
  slideDownOut: {
    "index__styles.slideDownOut": "index__styles.slideDownOut",
    animationName: "x28wfl8",
    transformOrigin: "x1dp6rp6",
    $$css: true
  },
  slideUpIn: {
    "index__styles.slideUpIn": "index__styles.slideUpIn",
    animationName: "xt8xfk9",
    transformOrigin: "x18ouj0k",
    $$css: true
  },
  slideUpOut: {
    "index__styles.slideUpOut": "index__styles.slideUpOut",
    animationName: "x28wfl8",
    transformOrigin: "x18ouj0k",
    $$css: true
  },
  slideLeftIn: {
    "index__styles.slideLeftIn": "index__styles.slideLeftIn",
    animationName: "x1j15sg",
    transformOrigin: "xoe7o5k",
    $$css: true
  },
  slideLeftOut: {
    "index__styles.slideLeftOut": "index__styles.slideLeftOut",
    animationName: "x1eukugu",
    transformOrigin: "xoe7o5k",
    $$css: true
  },
  fadeIn: {
    "index__styles.fadeIn": "index__styles.fadeIn",
    animationName: "x127lhb5",
    $$css: true
  }
};
const Transition = exports.Transition = (0, _react.memo)(({
  visible,
  children,
  duration,
  animation,
  onShow
}) => {
  var _a, _b, _c;
  const toggledRef = (0, _react.useRef)({
    toggled: false
  });
  const inClass = () => {
    if (animation === "slide down") {
      return styles.slideDownIn;
    }
    if (animation === "slide left") {
      return styles.slideLeftIn;
    }
    if (animation === "slide up") {
      return styles.slideUpIn;
    }
    if (animation === "fade in") {
      return styles.fadeIn;
    }
  };
  const outClass = () => {
    if (animation === "slide down") {
      return styles.slideDownOut;
    }
    if (animation === "slide left") {
      return styles.slideLeftOut;
    }
    if (animation === "slide up") {
      return styles.slideUpOut;
    }
    if (animation === "fade in") {
      return styles.fadeIn;
    }
  };
  const {
    className
  } = stylex.props(styles.transition, !visible && outClass(), visible && toggledRef.current.toggled && inClass());
  (0, _react.useEffect)(() => {
    if (visible && onShow) {
      onShow();
    }
  }, [visible, onShow]);
  (0, _react.useEffect)(() => {
    if (!visible) {
      toggledRef.current.toggled = true;
    }
  }, [visible]);
  return _react.default.cloneElement(children, {
    style: __spreadValues(__spreadValues({
      animationDuration: (visible ? (duration == null ? void 0 : duration.show) || 300 : (duration == null ? void 0 : duration.hide) || 300) + "ms"
    }, (_a = children.props) == null ? void 0 : _a.style), visible ? {
      display: "flex",
      visibility: "visible"
    } : {
      display: "none",
      visibility: "hidden"
    }),
    className: ((_b = children.props) == null ? void 0 : _b.className) ? ((_c = children.props) == null ? void 0 : _c.className) + " " + className : className
  });
});