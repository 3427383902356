"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Dimmer = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const styles = {
  dimmer: {
    "Dimmer__styles.dimmer": "Dimmer__styles.dimmer",
    display: "x1s85apg",
    position: "x10l6tqk",
    top: "x13vifvy",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    width: "xh8yej3",
    height: "x5yr21d",
    textAlign: "x2b8uid",
    verticalAlign: "xxymvpz",
    padding: "x119fht0",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    backgroundColor: "xjbqb8w",
    opacity: "xg01cxk",
    lineHeight: "xo5v014",
    animationFillMode: "x1u6ievf",
    animationDuration: "xxkxylk",
    transition: "x5gkzjs",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    userSelect: "x87ps6o",
    willChange: "x1larqbn",
    zIndex: "x1vjfegm",
    $$css: true
  },
  dimmerActive: {
    "Dimmer__styles.dimmerActive": "Dimmer__styles.dimmerActive",
    display: "x78zum5",
    opacity: "x1hc1fzr",
    $$css: true
  }
};
const Dimmer = exports.Dimmer = (0, _react.memo)(({
  active,
  className,
  children
}) => {
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: [styles.dimmer, active && styles.dimmerActive, className]
  }, children);
});