"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchInput = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Input = require("../Input");
var _Image = require("../Image");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
const styles = {
  result: {
    "index__styles.result": "index__styles.result",
    padding: "x7z7khe",
    alignItems: "x1cy8zhl",
    justifyContent: "x1nhvcw1",
    display: "x78zum5",
    height: "x1vqgdyp",
    ":hover_backgroundColor": "x1imby3o",
    ":hover_cursor": "x1277o0a",
    $$css: true
  },
  selectedResult: {
    "index__styles.selectedResult": "index__styles.selectedResult",
    backgroundColor: "xivp17k",
    ":hover_backgroundColor": "x836mop",
    $$css: true
  }
};
const SearchInput = exports.SearchInput = (0, _react.memo)(({
  value,
  className,
  autoComplete,
  onSelect,
  placeholder,
  readOnly,
  action,
  onChange,
  id,
  type,
  fluid,
  autoFocus,
  disabled,
  min,
  loading,
  max,
  required,
  label,
  name,
  maxLength,
  onKeyPress,
  onKeyDown,
  results
}) => {
  const [selected, setSelected] = (0, _react.useState)(null);
  (0, _react.useEffect)(() => {
    setSelected(null);
  }, [JSON.stringify(results)]);
  const {
    className: wrapperClassName
  } = {
    className: "index__styles.searchDropdown x78zum5 xdt5ytf x98rzlu x5yr21d x9ybwvh"
  };
  const {
    className: resultsClassName
  } = {
    className: "index__styles.results xqtp20y x78zum5 x98rzlu xdt5ytf x1odjw0f x1sxf85j x1anpbxc xyorhqc x8x9d4c xack27t xh8yej3 xmkeg23 x1y0btm7 x9r1u3d x128cn8a"
  };
  const {
    className: resultTextClassName
  } = {
    className: "index__styles.resultText x78zum5"
  };
  const {
    className: resultImgClassName
  } = {
    className: "index__styles.resultImg x19phtnz xr1yuqi x4ii5y1 x16ydxro xyo1k6t"
  };
  return /* @__PURE__ */_react.default.createElement("div", {
    className: wrapperClassName
  }, /* @__PURE__ */_react.default.createElement(_Input.Input, __spreadValues({}, {
    value,
    className,
    autoComplete: autoComplete || "off",
    placeholder,
    readOnly,
    action,
    onChange,
    loading,
    id,
    type,
    fluid,
    autoFocus,
    disabled,
    min,
    max,
    required,
    icon: "search",
    label,
    name,
    maxLength,
    onKeyPress,
    onKeyDown
  })), /* @__PURE__ */_react.default.createElement("div", {
    className: resultsClassName
  }, results.map((u, i) => {
    const {
      className: resultClassName
    } = stylex.props(styles.result, i === selected && styles.selectedResult);
    return /* @__PURE__ */_react.default.createElement("div", {
      key: u.key,
      className: resultClassName,
      onClick: () => {
        setSelected(i);
        onSelect(u.key);
      }
    }, u.image && /* @__PURE__ */_react.default.createElement(_Image.Image, {
      className: resultImgClassName,
      circular: true,
      src: u.image
    }), /* @__PURE__ */_react.default.createElement("div", {
      className: resultTextClassName
    }, u.text));
  })));
});