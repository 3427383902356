"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Settings = _interopRequireDefault(require("./Settings"));
var _MobileContext = _interopRequireDefault(require("./MobileContext"));
var _helpers = require("kosmi-sdk/helpers");
var _LoginScreen = _interopRequireDefault(require("./LoginScreen"));
var _MessagesDropdown = require("../Desktop/Header/components/MessagesDropdown");
var _Rooms = _interopRequireDefault(require("../Desktop/Header/components/RoomsDropdown/Rooms"));
var _NotificationDropdown = require("../Desktop/Header/components/NotificationDropdown");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  overlay: {
    "MobileOverlay__styles.overlay": "MobileOverlay__styles.overlay",
    minHeight: "x2lwn1j",
    width: "xh8yej3",
    pointerEvents: "x71s49j",
    position: "x1n2onr6",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    zIndex: "x1vjfegm",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    inset: "x10a8y8t",
    insetInline: null,
    insetInlineStart: null,
    insetInlineEnd: null,
    left: null,
    right: null,
    insetBlock: null,
    top: null,
    bottom: null,
    height: "x5yr21d",
    $$css: true
  },
  roomsScreen: {
    "MobileOverlay__styles.roomsScreen": "MobileOverlay__styles.roomsScreen",
    width: "xh8yej3",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  mainScreen: {
    "MobileOverlay__styles.mainScreen": "MobileOverlay__styles.mainScreen",
    height: "x5yr21d",
    $$css: true
  }
};
const RoomsScreen = (0, _react.memo)(({
  visible
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomsScreen
  }, /* @__PURE__ */_react.default.createElement(_Rooms.default, {
    visible
  }));
});
const FriendsScreen = (0, _react.memo)(props => {
  const {
    messageList,
    refetch,
    loading
  } = (0, _MessagesDropdown.useMessages)();
  return /* @__PURE__ */_react.default.createElement(_MessagesDropdown.MessagesScreen, __spreadProps(__spreadValues({}, props), {
    messageList,
    refetchMessageList: () => refetch(),
    loading
  }));
});
const contextHook = () => {
  const {
    overlayMenu,
    setOverlayMenu
  } = (0, _react.useContext)(_MobileContext.default);
  return {
    overlayMenu,
    setOverlayMenu
  };
};
var _default = exports.default = (0, _react.memo)(() => {
  const {
    overlayMenu
  } = contextHook();
  const currentUser = (0, _helpers.currentUserHook)();
  const isLoggedIn = !currentUser.isAnonymous;
  if (!currentUser) return /* @__PURE__ */_react.default.createElement(_uikit.Loader, null);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.overlay
  }, isLoggedIn ? /* @__PURE__ */_react.default.createElement(_uikit.TabView, {
    forceIndex: ["my_rooms", "chats", "notifications", "you"].indexOf(overlayMenu),
    menu: false,
    className: styles.mainScreen
  }, /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: "Rooms"
  }, /* @__PURE__ */_react.default.createElement(RoomsScreen, {
    visible: overlayMenu === "my_rooms"
  })), /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: "Friends"
  }, /* @__PURE__ */_react.default.createElement(FriendsScreen, null)), /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: "Notifications"
  }, /* @__PURE__ */_react.default.createElement(_NotificationDropdown.Notifications, null)), /* @__PURE__ */_react.default.createElement(_uikit.TabView.Item, {
    name: "You"
  }, /* @__PURE__ */_react.default.createElement(_Settings.default, null))) : /* @__PURE__ */_react.default.createElement(_LoginScreen.default, null));
});