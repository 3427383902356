"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useQuery, gql } from "kosmi-sdk/gql";
import React, { useState, useEffect } from "react";
import { LoadingScreen } from "kosmi-sdk/widgets";
const GET_PLAYLIST_INFO = gql(`
  query GetPlaylistInfo($url: String!) {
    mediaPlayerGetPlaylistInfo(url: $url) {
      status
      result {
        totalDuration
        items {
          title
          url
          duration
        }
      }
    }
    getServerTime {
      time
    }
  }
`);
function isYouTubePlaylist(url) {
  if (!url)
    return false;
  if (url.startsWith("magnet:"))
    return true;
  try {
    const urlObj = new URL(url);
    return urlObj.hostname.includes("youtube.com") && (urlObj.searchParams.has("list") || urlObj.pathname.startsWith("/@") || urlObj.pathname.startsWith("/playlist") || urlObj.pathname.startsWith("/channel/"));
  } catch (e) {
    console.error(e);
    return false;
  }
}
function getPositionWithinCurrentPlaylistItem(playlistItems, position) {
  let accumulatedDuration = 0;
  for (const item of playlistItems) {
    if (!(item == null ? void 0 : item.duration))
      continue;
    if (accumulatedDuration + item.duration > position) {
      break;
    }
    accumulatedDuration += item.duration;
  }
  return position - accumulatedDuration;
}
function getCurrentPlaylistUrl(playlistItems, position) {
  let accumulatedDuration = 0;
  let currentItem = playlistItems.length ? playlistItems[0] : null;
  for (const item of playlistItems) {
    currentItem = item;
    if (!(item == null ? void 0 : item.duration))
      continue;
    if (accumulatedDuration + item.duration > position) {
      break;
    }
    accumulatedDuration += item.duration;
  }
  return currentItem == null ? void 0 : currentItem.url;
}
const usePlaylistInfo = (url) => {
  const isPlaylist = isYouTubePlaylist(url);
  const [error, setError] = useState(false);
  const [started, setStarted] = useState(false);
  const {
    error: queryError,
    data,
    stopPolling
  } = useQuery(GET_PLAYLIST_INFO, {
    variables: { url },
    fetchPolicy: "network-only",
    pollInterval: 2e3,
    skip: !isYouTubePlaylist(url)
  });
  const { status, result } = (data == null ? void 0 : data.mediaPlayerGetPlaylistInfo) || {};
  const { time } = (data == null ? void 0 : data.getServerTime) || {};
  useEffect(() => {
    if (status === "ready") {
      stopPolling();
    } else if (queryError) {
      setError(true);
    } else if (status === "started") {
      if (started) {
        setError(true);
      } else {
        setStarted(true);
      }
    }
  }, [status, queryError, time]);
  useEffect(() => {
    if (error)
      stopPolling();
  }, [error]);
  if (error) {
    return { error, loading: false };
  }
  return {
    playlist: result,
    loading: isPlaylist && status !== "ready"
  };
};
export default (Component) => (props) => {
  var _a, _b;
  const { playlist, loading } = usePlaylistInfo(props.url);
  const [playlistIndex, setPlaylistIndex] = useState(0);
  const playlistUrl = (playlist == null ? void 0 : playlist.items) && ((_a = playlist.items[playlistIndex]) == null ? void 0 : _a.url);
  const previousPlaylistItemsDuration = ((_b = playlist == null ? void 0 : playlist.items) == null ? void 0 : _b.map((item) => item.duration).slice(0, playlistIndex).reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  )) || 0;
  const onUpdate = props.onUpdate ? (params) => props.onUpdate(__spreadProps(__spreadValues({}, params), {
    duration: playlist == null ? void 0 : playlist.totalDuration,
    position: params.position + previousPlaylistItemsDuration
  })) : void 0;
  if (loading)
    return /* @__PURE__ */ React.createElement(LoadingScreen, { text: "Loading Playlist" });
  if (!playlist)
    return /* @__PURE__ */ React.createElement(Component, __spreadValues({}, props));
  const onReady = props.onReady ? (player) => {
    const seekTo = (pos) => {
      var _a2;
      const url = getCurrentPlaylistUrl((playlist == null ? void 0 : playlist.items) || [], pos);
      const newPlaylistIndex = (_a2 = playlist == null ? void 0 : playlist.items) == null ? void 0 : _a2.map((item) => item.url).indexOf(url);
      if (newPlaylistIndex !== playlistIndex) {
        setPlaylistIndex(newPlaylistIndex || 0);
      } else {
        const innerPosition = getPositionWithinCurrentPlaylistItem(
          (playlist == null ? void 0 : playlist.items) || [],
          pos
        );
        player.seekTo(innerPosition);
      }
    };
    props.onReady(__spreadProps(__spreadValues({}, player), { seekTo }));
  } : void 0;
  return /* @__PURE__ */ React.createElement(
    Component,
    __spreadProps(__spreadValues({}, props), {
      key: playlistUrl,
      onReady,
      onUpdate,
      url: playlistUrl,
      loop: false
    })
  );
};
