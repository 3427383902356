"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Select = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Image = require("../Image");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
const styles = {
  dropdown: {
    "index__styles.dropdown": "index__styles.dropdown",
    cursor: "x1ypdohk",
    position: "x1n2onr6",
    display: "x1rg5ohu",
    outline: "x1a2a7pz",
    textAlign: "xdpxx8g",
    transition: "x8g6ub2",
    height: "x16wdlz0",
    padding: "xon2cxt",
    visibility: "xnpuxes",
    borderWidth: "xmkeg23",
    borderStyle: "x1y0btm7",
    borderColor: "xzgz5g3",
    appearance: "xjyslct",
    backgroundImage: "x17gz8yi",
    backgroundRepeat: "xiy17q3",
    backgroundPositionX: "x10mjbor",
    backgroundPositionY: "x1yzgxni",
    $$css: true
  },
  compact: {
    "index__styles.compact": "index__styles.compact",
    minWidth: "xeuugli",
    $$css: true
  },
  disabled: {
    "index__styles.disabled": "index__styles.disabled",
    cursor: "xt0e3qv",
    pointerEvents: "x47corl",
    opacity: "xyd83as",
    $$css: true
  },
  image: {
    "index__styles.image": "index__styles.image",
    paddingLeft: "x1dcfgta",
    $$css: true
  },
  selection: {
    "index__styles.selection": "index__styles.selection",
    cursor: "x1ypdohk",
    wordWrap: "x1vvkbs",
    lineHeight: "xtqhoxe",
    whiteSpace: "xeaf4i8",
    outline: "x1b6ykf0",
    transform: "x1atnlmd",
    minWidth: "x1fh14hl",
    minHeight: "xyt6bas",
    backgroundColor: "x128cn8a",
    display: "x1rg5ohu",
    padding: "x1yy9s8v",
    color: "x11jfisy",
    boxShadow: "x1gnnqk1",
    borderRadius: "x1aaq583",
    transition: "x8g6ub2",
    borderWidth: "xmkeg23",
    borderStyle: "x1y0btm7",
    borderColor: "xzgz5g3",
    fontWeight: "x1fcty0u",
    ":hover_borderColor": "x1saclv8",
    ":focus_borderColor": "x1eg2tmu",
    ":focus-within_borderColor": "x6po30u",
    $$css: true
  },
  select: {
    "index__styles.select": "index__styles.select",
    display: "x78zum5",
    flex: "x98rzlu",
    width: "xh8yej3",
    height: "x5yr21d",
    borderWidth: "xc342km",
    background: "x11g6tue",
    padding: "xsu7wfv",
    cursor: "x1ypdohk",
    outline: "x1a2a7pz",
    appearance: "xjyslct",
    color: "x11jfisy",
    $$css: true
  }
};
const Select = exports.Select = (0, _react.memo)(({
  id,
  name,
  defaultValue,
  className,
  value,
  onChange,
  options,
  disabled,
  label,
  style,
  compact,
  placeholder,
  required
}) => {
  var _a, _b;
  const image = (_a = options.find(o => !!o.image && o.value === value)) == null ? void 0 : _a.image;
  const hasImage = !!((_b = options.find(o => !!o.image)) == null ? void 0 : _b.image);
  const {
    className: styleXClassName
  } = stylex.props(styles.selection, styles.dropdown, compact && styles.compact, disabled && styles.disabled);
  const {
    className: labelClassName
  } = {
    className: "index__styles.label x1a8lsjc x117nqv4"
  };
  const classNames = [styleXClassName];
  if (className) classNames.push(className);
  const {
    className: selectClassName
  } = stylex.props(styles.select, hasImage && styles.image);
  const {
    className: imgClassName
  } = {
    className: "index__styles.img x10l6tqk x13vifvy x1ey2m1c xt7dq6l xhtitgo x1bpp3o7 x47corl xc1hvs2 xgd8bvy xz75wtk x1ypdohk"
  };
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, label && /* @__PURE__ */_react.default.createElement("label", {
    className: labelClassName
  }, label), /* @__PURE__ */_react.default.createElement("div", {
    id,
    style: __spreadValues(__spreadValues({}, {
      flexDirection: "column",
      padding: 0,
      flex: 1
    }), style),
    className: classNames.join(" ")
  }, image && /* @__PURE__ */_react.default.createElement("div", {
    className: imgClassName
  }, /* @__PURE__ */_react.default.createElement(_Image.Image, {
    src: image,
    avatar: true
  })), /* @__PURE__ */_react.default.createElement("select", {
    defaultValue,
    disabled,
    onChange,
    value,
    className: selectClassName,
    name,
    required
  }, !value && !defaultValue && /* @__PURE__ */_react.default.createElement("option", {
    value: "",
    disabled: true,
    selected: !value
  }, placeholder || "Select an option"), options.map(({
    text,
    value: optionValue
  }) => /* @__PURE__ */_react.default.createElement("option", {
    key: optionValue,
    value: optionValue,
    selected: optionValue === value
  }, text, " ", optionValue === value)))));
});