"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Player = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _reactPlayer = _interopRequireDefault(require("react-player"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _canAutoplay = _interopRequireDefault(require("can-autoplay"));
var _AVEngine = _interopRequireDefault(require("../../../../../../core/services/AVEngine"));
var _Cinesend = _interopRequireDefault(require("./Cinesend"));
var _VBrowserPlayer = _interopRequireDefault(require("./VBrowserPlayer"));
var _spinner = _interopRequireDefault(require("./spinner.mp4"));
var _withPlaylistHandling = _interopRequireDefault(require("./withPlaylistHandling"));
var _withProxyHandling = _interopRequireDefault(require("./withProxyHandling"));
var _helpers = require("./helpers");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const isLocalhost = location.hostname == "localhost";
const tryParseUrl = url => {
  try {
    return new URL(url);
  } catch (_e) {
    return null;
  }
};
const styles = {
  reactPlayer: {
    "index__styles.reactPlayer": "index__styles.reactPlayer",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    backgroundColor: "x1r1mewj",
    $$css: true
  },
  noMouse: {
    "index__styles.noMouse": "index__styles.noMouse",
    pointerEvents: "x47corl",
    $$css: true
  },
  playOverlay: {
    "index__styles.playOverlay": "index__styles.playOverlay",
    zIndex: "xhtitgo",
    width: "xh8yej3",
    height: "x5yr21d",
    background: "x11skabg",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    backdropFilter: "x1ydx4q1",
    position: "x10l6tqk",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    cursor: "x1ypdohk",
    $$css: true
  },
  playIcon: {
    "index__styles.playIcon": "index__styles.playIcon",
    fontSize: "xyywni2",
    $$css: true
  },
  placeholder: {
    "index__styles.placeholder": "index__styles.placeholder",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    position: "x1n2onr6",
    width: "xh8yej3",
    height: "x5yr21d",
    backgroundColor: "x1r1mewj",
    $$css: true
  },
  icon: {
    "index__styles.icon": "index__styles.icon",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "xyorhqc",
    $$css: true
  }
};
const getClassName = (...styleObj) => {
  const {
    className
  } = stylex.props(styleObj);
  return className;
};
const Player = exports.Player = (0, _react.memo)(({
  autoPlay,
  onBannedUrl,
  onError,
  onSupportedUrl,
  onUnmount,
  paused,
  stream,
  subtitles,
  url,
  onReady,
  onUpdate,
  loop,
  controls
}) => {
  var _a;
  const [error, setError] = (0, _react.useState)("");
  const [playerPaused, setPlayerPaused] = (0, _react.useState)(false);
  const [muted, setMuted] = (0, _react.useState)(false);
  const [showPlayButton, setShowPlayButton] = (0, _react.useState)(false);
  const [reactPlayer, setReactPlayer] = (0, _react.useState)(null);
  const [position, setPosition] = (0, _react.useState)(void 0);
  const [duration, setDuration] = (0, _react.useState)(void 0);
  const autoPlayBusterRef = (0, _react.useRef)(null);
  const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
  const httpUrl = url && (url.startsWith("blob") || url.startsWith("http") ? url : _spinner.default);
  const initialVolume = _AVEngine.default.getCurrentlySpeaking().size > 0 ? _AVEngine.default.dimLevel : _AVEngine.default.initialEmbedVolume;
  const [currentVolume, setCurrentVolume] = (0, _react.useState)(initialVolume);
  const [preDuckUserVolume, setPreduckUserVolume] = (0, _react.useState)(_AVEngine.default.initialEmbedVolume);
  const applySubtitles = (subtitles2, offset = 0) => {
    if (!internalPlayer) return;
    if (!internalPlayer.textTracks) return;
    if (!subtitles2) return;
    if (!(subtitles2 == null ? void 0 : subtitles2.cues)) return;
    (0, _helpers.addSubtitles)(internalPlayer, subtitles2, offset);
  };
  const offsetSubtitles = (subtitles2, offset) => applySubtitles(subtitles2, offset);
  const setVolume = volume => {
    if (volume === 0) {
      mute();
    } else {
      unmute();
    }
    setCurrentVolume(volume);
    localStorage.setItem("embedVolume", String(volume));
  };
  const seekTo = position2 => {
    if (Number.isNaN(position2)) return;
    if (reactPlayer) {
      return reactPlayer == null ? void 0 : reactPlayer.seekTo(position2);
    }
  };
  const isPaused = () => __async(void 0, null, function* () {
    const result = yield (0, _helpers.getIsPaused)(internalPlayer);
    return Boolean(result !== null ? result : playerPaused);
  });
  const pause = () => (0, _helpers.pausePlayer)(internalPlayer);
  const play = () => (0, _helpers.unPausePlayer)(internalPlayer);
  const mute = () => setMuted(true);
  const unmute = () => {
    setShowPlayButton(false);
    if (currentVolume == 0) return;
    setMuted(false);
  };
  const syncDuration = () => {
    const playerDuration = reactPlayer == null ? void 0 : reactPlayer.getDuration();
    if (playerDuration) {
      setDuration(playerDuration);
    }
  };
  const handleVideoLoaded = () => __async(void 0, null, function* () {
    var _a2;
    onReady({
      id: (_a2 = reactPlayer == null ? void 0 : reactPlayer._reactInternals) == null ? void 0 : _a2.key,
      video: internalPlayer,
      offsetSubtitles,
      setVolume,
      seekTo,
      isPaused,
      pause,
      play,
      mute,
      unmute
    });
    applySubtitles(subtitles);
    onUpdate({
      volume: currentVolume,
      duration,
      muted,
      position,
      paused: playerPaused
    });
    if (internalPlayer instanceof HTMLVideoElement) {
      internalPlayer.oncanplay = () => {
        if (internalPlayer.videoHeight === 0 && internalPlayer.videoWidth === 0) {
          internalPlayer.poster = "";
        }
      };
    }
  });
  const applyStream = () => {
    if (internalPlayer && stream) {
      internalPlayer.src = "";
      internalPlayer.srcObject = stream;
      handleVideoLoaded();
    }
  };
  const onPlayerError = e => __async(void 0, null, function* () {
    var _a2, _b;
    const error2 = e;
    console.error({
      e
    });
    const errorName = (error2 == null ? void 0 : error2.message) || ((_a2 = error2 == null ? void 0 : error2.name) == null ? void 0 : _a2.toLowerCase());
    if (_helpers.ignoredErrors.indexOf(errorName) !== -1 || _helpers.ignoredErrors.indexOf((_b = error2 == null ? void 0 : error2.name) == null ? void 0 : _b.toLowerCase()) !== -1) {
      return;
    }
    if ((0, _helpers.isHttp)(url) && onError && onError(errorName)) {
      return;
    }
    if (typeof errorName === "string") {
      setError(errorName || "Unknown Error");
    }
  });
  const onPlayerReady = newReactPlayer => __async(void 0, null, function* () {
    var _a2, _b;
    if (((_a2 = newReactPlayer == null ? void 0 : newReactPlayer._reactInternals) == null ? void 0 : _a2.key) === ((_b = reactPlayer == null ? void 0 : reactPlayer._reactInternals) == null ? void 0 : _b.key)) {
      return;
    }
    const hls = newReactPlayer.getInternalPlayer("hls");
    hls == null ? void 0 : hls.on("hlsError", function (_eventName, data) {
      if (data.fatal) {
        if (data.type === "mediaError") {
          hls.recoverMediaError();
        }
      }
    });
    setReactPlayer(newReactPlayer);
  });
  const registerEmbedVolumeCallback = () => _AVEngine.default.registerEmbedVolumeCallback(url, level => __async(void 0, null, function* () {
    const currentPlayerVolume = yield (0, _helpers.getInternalPlayerVolume)(internalPlayer);
    setPreduckUserVolume(currentPlayerVolume);
    if (level !== null) {
      if (currentPlayerVolume > level) {
        setCurrentVolume(level);
      }
    } else {
      setCurrentVolume(preDuckUserVolume);
    }
  }));
  const onProgress = ({
    playedSeconds
  }) => {
    setPosition(playedSeconds);
    syncDuration();
  };
  const onPlay = () => {
    setPlayerPaused(false);
    syncDuration();
  };
  const onEnded = () => {
    setPlayerPaused(true);
    syncDuration();
  };
  const onPause = () => {
    setPlayerPaused(true);
    syncDuration();
  };
  const onDuration = duration2 => {
    if (duration2) {
      setDuration(duration2);
    }
  };
  const unPauseAndPlay = () => {
    if (!paused) {
      play();
    }
    unmute();
  };
  (0, _react.useEffect)(() => {
    if (!(subtitles == null ? void 0 : subtitles.cues)) {
      (0, _helpers.clearSubtitleCues)(internalPlayer);
    } else {
      applySubtitles(subtitles);
    }
  }, [JSON.stringify(subtitles)]);
  (0, _react.useEffect)(() => {
    applyStream();
  }, [internalPlayer, stream]);
  (0, _react.useEffect)(() => {
    onUpdate({
      volume: currentVolume,
      duration,
      muted,
      position,
      paused: playerPaused
    });
  }, [currentVolume, duration, muted, position, playerPaused]);
  (0, _react.useEffect)(() => {
    if (muted && showPlayButton && !paused) {
      play();
    }
  }, [muted, showPlayButton, paused]);
  (0, _react.useEffect)(() => {
    ;
    (() => __async(void 0, null, function* () {
      const playerIsMuted = yield (0, _helpers.getIsMuted)(internalPlayer);
      setMuted(playerIsMuted || false);
    }))();
  }, [controls]);
  (0, _react.useEffect)(() => {
    if (!internalPlayer) return;
    if (!paused) {
      play();
    }
    (0, _helpers.facebookAndVidyardWorkaround)(internalPlayer, setPlayerPaused);
    handleVideoLoaded();
    applyStream();
    applySubtitles(subtitles);
    if (url) {
      registerEmbedVolumeCallback();
    }
    if (!autoPlayBusterRef.current) {
      const playFallback = () => {
        mute();
        play();
        setShowPlayButton(true);
      };
      autoPlayBusterRef.current = setTimeout(() => __async(void 0, null, function* () {
        const {
          result
        } = yield _canAutoplay.default.audio();
        if (!result) {
          playFallback();
        } else {
          const {
            result: videoResult
          } = yield _canAutoplay.default.video({
            inline: true
          });
          if (!videoResult) {
            setShowPlayButton(true);
          }
          if (yield (0, _helpers.getIsPaused)(internalPlayer)) {
            playFallback();
          }
        }
        autoPlayBusterRef.current = null;
      }), 2e3);
    }
  }, [(_a = reactPlayer == null ? void 0 : reactPlayer._reactInternals) == null ? void 0 : _a.key]);
  (0, _react.useEffect)(() => {
    var _a2, _b;
    const parsedUrl = tryParseUrl(url);
    if ((url == null ? void 0 : url.startsWith("https://www.twitch.tv/")) && (location.href.startsWith("capacitor") || isLocalhost)) {
      onPlayerError("twitch won't load on capacitor");
    }
    if ((_a2 = parsedUrl == null ? void 0 : parsedUrl.hostname) == null ? void 0 : _a2.includes("pluto.tv")) {
      onPlayerError("Force Pluto to use proxy");
    }
    if ((_b = parsedUrl == null ? void 0 : parsedUrl.hostname) == null ? void 0 : _b.includes(".real-debrid.com")) {
      onPlayerError("Force RD to use proxy");
    }
    return () => {
      if (reactPlayer) {
        if (autoPlayBusterRef.current) {
          clearTimeout(autoPlayBusterRef.current);
          autoPlayBusterRef.current = null;
        }
        const hlsPlayer = reactPlayer.getInternalPlayer("hls");
        const dashPlayer = reactPlayer.getInternalPlayer("dash");
        if (hlsPlayer) {
          hlsPlayer.destroy();
        } else if (dashPlayer) {
          dashPlayer.reset();
        }
        if (internalPlayer && stream) {
          internalPlayer.src = "";
          internalPlayer.srcObject = null;
          internalPlayer.pause();
        }
      }
      if (onUnmount) {
        onUnmount();
      }
      if (url) {
        _AVEngine.default.removeEmbedVolumeCallback(url);
      }
    };
  }, []);
  if (!url && !stream) {
    return null;
  }
  if (url.startsWith("VBrowser://")) return /* @__PURE__ */_react.default.createElement(_VBrowserPlayer.default, {
    onSupportedUrl,
    onBannedUrl,
    sessionId: url.replace("VBrowser://", "")
  });
  if ((0, _helpers.isCinesendUrl)(url)) return /* @__PURE__ */_react.default.createElement(_Cinesend.default, {
    src: url,
    onReady
  });
  if (error) return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.placeholder
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.icon,
    name: "frown",
    size: "huge"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, error));
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, !controls && showPlayButton && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.playOverlay,
    onClick: unPauseAndPlay
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.playIcon,
    name: "play",
    size: "huge"
  })), /* @__PURE__ */_react.default.createElement(_reactPlayer.default, {
    key: httpUrl,
    className: getClassName(styles.reactPlayer, !controls && styles.noMouse),
    url: httpUrl,
    controls,
    muted: muted || position === void 0,
    volume: currentVolume,
    playing: !paused,
    onError: onPlayerError,
    onReady: onPlayerReady,
    config: (0, _helpers.getPlayerOptions)(autoPlay),
    onDuration,
    onProgress,
    onPlay,
    onEnded,
    onPause,
    width: "100%",
    height: "100%",
    playsinline: true,
    loop
  }));
});
var _default = exports.default = (0, _withPlaylistHandling.default)((0, _withProxyHandling.default)(Player));