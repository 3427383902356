"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _gql = require("kosmi-sdk/gql");
var _EmojiPicker = _interopRequireDefault(require("./EmojiPicker"));
var _react = _interopRequireWildcard(require("react"));
var _LoginModal = _interopRequireDefault(require("../../../../../../components/Widgets/LoginModal"));
var _useIsAdmin = require("../../../../hooks/useIsAdmin");
var _useRoomId = require("../../../../hooks/useRoomId");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _helpers = require("kosmi-sdk/helpers");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  imageButton: {
    "index__styles.imageButton": "index__styles.imageButton",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    cursor: "x1ypdohk",
    minHeight: "x1wxaq2x",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    minWidth: "x900493",
    borderRadius: "x15fcutc",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    paddingBottom: "x18d9i69",
    paddingLeft: "x1uhho1l",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    paddingRight: "x1xpa7k",
    paddingTop: "xexx8yu",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    $$css: true
  },
  emojiPicker: {
    "index__styles.emojiPicker": "index__styles.emojiPicker",
    width: "xdzyupr",
    height: "x1vd4hg5",
    display: "x78zum5",
    $$css: true
  }
};
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const GET_EMBEDDABLE_URL_METADATA = (0, _gql.gql)(`
  mutation GetEmbeddableLinkMetadata($url: String!) {
    getEmbeddableLinkMetadata(url: $url) {
      name
      hostname
      type
    }
  }
`);
function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
const OpenLinkModal = (0, _react.memo)(_a => {
  var _b = _a,
    {
      sendToChat,
      linkData
    } = _b,
    props = __objRest(_b, ["sendToChat", "linkData"]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, __spreadValues({}, props), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "Supported link detected"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, "We see that you are trying to post a link to", " ", linkData && linkData.name), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Actions, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    inverted: true,
    color: "yellow",
    onClick: () => {
      props.openInRoom();
      props.onClose();
    }
  }, "Open Link in a Web View"), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    inverted: true,
    color: "white",
    onClick: () => {
      sendToChat();
      props.onClose();
    }
  }, "Post link to chat")));
});
const OPEN_LINK_IN_WEBVIEW = (0, _gql.gql)(`
  mutation OpenLinkInWebview1($roomId: String!, $url: String!) {
    openLinkInWebview(roomId: $roomId, url: $url) {
      ok
    }
  }
`);
const EmojiPickerButton = (0, _react.forwardRef)(({
  open,
  setOpen,
  mountNode,
  hideGifPicker,
  onSelect,
  onGifSelect,
  onFocus,
  onBlur
}, ref) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    controlled: true,
    open,
    position: "top center",
    onClickOutside: () => {
      setOpen(false);
    },
    mountNode,
    content: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.emojiPicker
    }, /* @__PURE__ */_react.default.createElement(_EmojiPicker.default, {
      ref,
      hideGifPicker,
      onSelect,
      onGifSelect
    }))),
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        if (!open && onFocus) onFocus();
        if (open && onBlur) onBlur();
        setOpen(!open);
      },
      type: "button",
      inverted: true,
      color: "white",
      style: {
        margin: 0,
        width: 35,
        borderRadius: 35 / 2,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        borderWidth: 0
      }
    }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      size: "large",
      name: "smile outline"
    }))
  });
});
const ImageButton = (0, _react.forwardRef)(({
  onClick
}, ref) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    onClick,
    ref,
    style: styles.imageButton
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    color: "white",
    size: "large",
    name: "image outline"
  }));
});
var _default = exports.default = (0, _helpers.withDialogs)((0, _react.memo)(({
  confirm,
  overrideEmojiPickerStyle,
  emojiPickerMountNode,
  isPrivate,
  isNsfw,
  onFocus,
  onBlur,
  sendMessage
}) => {
  const roomId = isPrivate ? "" : (0, _useRoomId.useRoomId)();
  const [openLinkInWebview] = (0, _gql.useMutation)(OPEN_LINK_IN_WEBVIEW);
  const [getEmbeddableLinkMetadata] = (0, _gql.useMutation)(GET_EMBEDDABLE_URL_METADATA);
  const isAdmin = (0, _useIsAdmin.useIsAdmin)();
  const currentUser = (0, _helpers.currentUserHook)();
  const inputRef = (0, _react.useRef)(null);
  const formRef = (0, _react.useRef)(null);
  const emojiPickerRef = (0, _react.useRef)(null);
  const [pendingLinkData, setPendingLinkData] = (0, _react.useState)(null);
  const [showEmojiPicker, setShowEmojiPicker] = (0, _react.useState)(false);
  const isLobby = roomId === "lobby";
  (0, _uikit.useOnClickOutside)(showEmojiPicker ? [formRef, emojiPickerRef] : [formRef], onBlur || (() => {}));
  (0, _react.useEffect)(() => {
    if (!inputRef.current) return;
    inputRef.current.autocomplete = "off";
  }, [inputRef == null ? void 0 : inputRef.current]);
  if (currentUser.isAnonymous && isLobby) {
    return /* @__PURE__ */_react.default.createElement(_LoginModal.default, {
      trigger: ({
        onClick
      }) => /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        color: "var(--primary-light-10)",
        style: {
          color: "var(--foreground)"
        },
        fluid: true,
        inverted: true,
        onClick
      }, "Please Log in to chat")
    });
  }
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, !isPrivate && /* @__PURE__ */_react.default.createElement(OpenLinkModal, {
    open: !!pendingLinkData,
    onClose: () => setPendingLinkData(null),
    linkData: pendingLinkData,
    sendToChat: () => {
      sendMessage(inputRef == null ? void 0 : inputRef.current.value);
      inputRef.current.value = "";
    },
    openInRoom: () => (input => __async(void 0, null, function* () {
      if (openLinkInWebview && (yield confirm("Stop the current activity and open link in webview?"))) {
        openLinkInWebview({
          variables: {
            roomId,
            url: input.current.value
          }
        });
      }
      if (input) {
        input.current.value = "";
      }
    }))(inputRef)
  }), /* @__PURE__ */_react.default.createElement(_uikit.ChatMessages.Messagebox, {
    inputRef,
    ref: formRef,
    onFocus,
    onBlur,
    onEnter: () => __async(void 0, null, function* () {
      var _a, _b;
      if (!((_a = inputRef == null ? void 0 : inputRef.current) == null ? void 0 : _a.value)) return;
      if (isAdmin && isValidHttpUrl(inputRef.current.value) && getEmbeddableLinkMetadata && !isPrivate) {
        const result = yield getEmbeddableLinkMetadata({
          variables: {
            url: inputRef.current.value
          }
        });
        const linkData = (_b = result == null ? void 0 : result.data) == null ? void 0 : _b.getEmbeddableLinkMetadata;
        if (linkData) {
          setPendingLinkData(linkData || null);
          return;
        }
      }
      sendMessage(inputRef.current.value);
      inputRef.current.value = "";
    })
  }, (isPrivate || !isLobby) && !isNsfw && /* @__PURE__ */_react.default.createElement(_uikit.ImageUploader, {
    unstyled: true,
    forceDefault: true,
    src: /* @__PURE__ */_react.default.createElement(ImageButton, null),
    onFile: file => __async(void 0, null, function* () {
      if (!file) return;
      const data = new FormData();
      data.append("file", file);
      try {
        const response = yield fetch("https://img.kosmi.io/", {
          method: "POST",
          body: data
        });
        const response_json = yield response.json();
        const filename = response_json.filename;
        if (filename) {
          sendMessage("https://img.kosmi.io/" + filename);
        }
      } catch (e) {
        console.warn(e);
      }
    })
  }), !(isLobby && isMobile) && /* @__PURE__ */_react.default.createElement(EmojiPickerButton, {
    ref: emojiPickerRef,
    open: showEmojiPicker,
    setOpen: setShowEmojiPicker,
    mountNode: emojiPickerMountNode,
    style: overrideEmojiPickerStyle,
    hideGifPicker: isLobby,
    onSelect: e => {
      inputRef.current.value = inputRef.current.value + e.native;
      inputRef.current.focus();
      setShowEmojiPicker(false);
    },
    onGifSelect: url => {
      sendMessage(url);
      setShowEmojiPicker(false);
    },
    onFocus,
    onBlur
  })));
}));