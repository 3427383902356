"use strict";
import { Popup, Button, Spotlight } from "kosmi-sdk/uikit";
import React, { forwardRef } from "react";
import style from "./style.css";
import { withStyleHook } from "../../../sdk/styles";
const HighlightedElement = forwardRef(
  ({ children, enableSpotlight }, ref) => {
    const clonedChild = React.cloneElement(children, { ref });
    return /* @__PURE__ */ React.createElement(Spotlight, { animated: true, noClick: true, brightness: 50, enabled: enableSpotlight }, clonedChild);
  }
);
export const OnboardingTooltip = forwardRef(
  (props, ref) => {
    const styles = withStyleHook(style);
    const { onClick, onSkip, open, step, totalSteps, trigger } = props;
    return /* @__PURE__ */ React.createElement(
      Popup,
      {
        trigger: /* @__PURE__ */ React.createElement(HighlightedElement, { enableSpotlight: open, ref }, trigger),
        controlled: true,
        open,
        className: styles.popup,
        position: props.position,
        content: /* @__PURE__ */ React.createElement("div", { className: styles.content }, props.content, /* @__PURE__ */ React.createElement("div", { style: { display: "flex", marginTop: 10 } }, step && totalSteps && /* @__PURE__ */ React.createElement("div", null, step, "/", totalSteps), /* @__PURE__ */ React.createElement(
          "div",
          {
            style: {
              display: "flex",
              marginLeft: "auto"
            }
          },
          onSkip && /* @__PURE__ */ React.createElement(Button, { inverted: true, size: "xs", color: "black", onClick: onSkip }, "Hide tips"),
          /* @__PURE__ */ React.createElement(
            Button,
            {
              style: {
                marginLeft: 5
              },
              size: "xs",
              color: "white",
              onClick
            },
            "Got it"
          )
        )))
      }
    );
  }
);
