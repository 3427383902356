"use strict";
var _a, _b, _c, _d;
import React from "react";
import platform from "platform";
import { LoginModalInner } from "../../Widgets/LoginModal";
import { Button } from "kosmi-sdk/uikit";
import { ErrorScreen } from "../../Widgets";
import { Diamond, PaywallMessage } from "../../Widgets/Premium";
const isIOS = ((_b = (_a = platform) == null ? void 0 : _a.os) == null ? void 0 : _b.family) === "iOS";
const isMacOS = ((_d = (_c = platform) == null ? void 0 : _c.os) == null ? void 0 : _d.family) === "OS X";
const isCapacitor = location.href.startsWith("capacitor");
const hidePremium = (isIOS || isMacOS) && isCapacitor;
export default ({
  error,
  rejoin
}) => {
  if (error == "PREMIUM_ONLY" && hidePremium)
    return /* @__PURE__ */ React.createElement(ErrorScreen, { text: "Room not found" });
  switch (error) {
    case "DISCONNECTED":
      return /* @__PURE__ */ React.createElement(ErrorScreen, { text: "Disconnected" }, /* @__PURE__ */ React.createElement(
        Button,
        {
          size: "xxl",
          inverted: true,
          color: "yellow",
          onClick: () => {
            rejoin();
          },
          style: { marginBottom: 30, marginTop: 30, height: 52 }
        },
        "Reconnect"
      ));
    case "BANNED":
      return /* @__PURE__ */ React.createElement(ErrorScreen, { text: "Banned" });
    case "PLEASE_LOGIN":
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          style: {
            minWidth: 200,
            maxWidth: "100%",
            padding: 20,
            margin: "auto"
          }
        },
        /* @__PURE__ */ React.createElement(LoginModalInner, null)
      );
    case "ROOM_NOT_FOUND":
      return /* @__PURE__ */ React.createElement(ErrorScreen, { text: "Room not found" });
    case "PLEASE_LOGIN_WITH_REDDIT_EMAIL":
      return /* @__PURE__ */ React.createElement(ErrorScreen, { text: "Please Login with your @reddit.com email" });
    case "PREMIUM_ONLY":
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          style: {
            margin: "auto",
            fontSize: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: 200,
            maxWidth: "100%",
            padding: 20
          }
        },
        /* @__PURE__ */ React.createElement("div", { style: { margin: "20px" } }, /* @__PURE__ */ React.createElement(
          Diamond,
          {
            style: {
              fontSize: 50
            }
          }
        )),
        /* @__PURE__ */ React.createElement(
          PaywallMessage,
          {
            source: "PremiumRoom",
            message: "This room is for subscribers only"
          }
        )
      );
    case "ALREADY_CONNECTED":
      return /* @__PURE__ */ React.createElement(ErrorScreen, { text: "You are already a member of this room elsewhere" }, /* @__PURE__ */ React.createElement(
        Button,
        {
          size: "xxl",
          inverted: true,
          color: "yellow",
          onClick: () => {
            rejoin(true);
          },
          style: { marginBottom: 30, marginTop: 30, height: 52 }
        },
        "Disconnect other connection and Join Room"
      ));
  }
  return null;
};
