"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect, useContext } from "react";
import { withDialogs, useTracking, currentUserHook } from "kosmi-sdk/helpers";
import { AnimatedModal as Modal, FadeInTransition } from "kosmi-sdk/widgets";
import { Button, Checkbox, Select } from "kosmi-sdk/uikit";
import { useMembers } from "../../../../../withMembers";
import { PaywallMessage } from "../../../../../../Widgets/Premium";
import MediaPlayerContext from "../../../../..//MediaPlayerContext";
import { withStyleHook } from "kosmi-sdk/styles";
import style from "./style.css";
import BackButton from "../BackButton";
const videoConstraints = (quality) => {
  if (quality === "360p") {
    return {
      width: {
        max: 640
      },
      height: {
        max: 360
      },
      framerate: 24
    };
  }
  if (quality === "480p") {
    return {
      width: {
        max: 853
      },
      height: {
        max: 480
      },
      framerate: 24
    };
  }
  if (quality === "720p") {
    return {
      width: {
        max: 1280
      },
      height: {
        max: 720
      },
      framerate: 24
    };
  }
  if (quality === "1080p") {
    return {
      width: {
        max: 1920
      },
      height: {
        max: 1080
      },
      framerate: 24
    };
  }
  return {
    framerate: 24
  };
};
const audioConstraints = {
  channelCount: 2,
  noiseSuppression: false,
  autoGainControl: false,
  echoCancellation: false,
  latency: 0,
  sampleRate: 44100
  //suppressLocalAudioPlayback: true,
};
const EnableAudioModal = ({
  styles,
  open,
  shareScreen,
  onClose,
  addAudioTrack,
  videoConstraints: videoConstraints2,
  stream,
  relay
}) => {
  const [showWindowWoraround, setShowWindowWoraround] = useState(false);
  const mediaPlayerContext = useContext(MediaPlayerContext);
  return /* @__PURE__ */ React.createElement(Modal, { id: styles.audioModal, open, onClose, preventClosing: true }, /* @__PURE__ */ React.createElement(Modal.Header, null, "Did you forget to enable audio?"), showWindowWoraround ? /* @__PURE__ */ React.createElement(FadeInTransition, { className: styles.workaround }, /* @__PURE__ */ React.createElement(BackButton, { onClick: () => setShowWindowWoraround(false) }), /* @__PURE__ */ React.createElement("div", null, 'Click the button below, then select "Your entire screen" and make sure to tick the "Share audio" box on the bottom left.'), /* @__PURE__ */ React.createElement("div", null, "Once that is done, click the Share button and the audio track will be added, keeping the window as video."), /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "yellow",
      inverted: true,
      onClick: () => __async(void 0, null, function* () {
        const stream2 = yield navigator.mediaDevices.getDisplayMedia({
          video: videoConstraints2,
          audio: audioConstraints
        });
        const audioTrack = [...stream2.getAudioTracks()][0];
        stream2.getVideoTracks().map((t) => t.stop());
        if (audioTrack) {
          addAudioTrack(audioTrack);
          onClose();
        } else {
          stream2.getTracks().map((t) => t.stop());
        }
      })
    },
    "Get Audio from Entire screen"
  )) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { style: { textAlign: "center" } }, "Currently only Google Chrome supports streaming audio. In Chrome you need to tick the box shown in the image below:"), /* @__PURE__ */ React.createElement("div", { className: styles.audioGuide }), /* @__PURE__ */ React.createElement("div", { style: { textAlign: "center", marginTop: "10px" } }, 'Note that for "Application Window" sharing this box is not available.', /* @__PURE__ */ React.createElement(
    "a",
    {
      className: styles.workaroundLink,
      onClick: () => setShowWindowWoraround(true)
    },
    "Click here for a workaround"
  ))), /* @__PURE__ */ React.createElement(Modal.Actions, null, /* @__PURE__ */ React.createElement(
    Button,
    {
      inverted: true,
      color: "white",
      onClick: () => {
        mediaPlayerContext.setLocalStream(stream);
        mediaPlayerContext.setRelayStream(!!relay);
        onClose();
      }
    },
    "Continue without audio"
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "yellow",
      inverted: true,
      onClick: () => {
        shareScreen();
      }
    },
    "Reselect Screensharing"
  )));
};
const relayServerMsg = /* @__PURE__ */ React.createElement("div", null, "Relay server offers better performance for", /* @__PURE__ */ React.createElement("ul", null, /* @__PURE__ */ React.createElement("li", null, "Slow connections"), /* @__PURE__ */ React.createElement("li", null, "Rooms with a large number of members")));
export default withDialogs(
  ({
    localStream,
    onClose,
    children,
    alert,
    confirm,
    simple,
    customLabel
  }) => {
    const members = useMembers();
    const currentUser = currentUserHook();
    const [relay, setRelay] = useState(members && members.length > 2);
    const [quality, setQuality] = useState("480p");
    const [stream, setStream] = useState(null);
    const [audioMissing, setAudioMissing] = useState(false);
    const [advancedSettingsOpen, _setAdvancedSettingsOpen] = useState(true);
    const track = useTracking();
    const mediaPlayerContext = useContext(MediaPlayerContext);
    const styles = withStyleHook(style);
    const addAudioTrack = (audioTrack) => {
      if (!stream) {
        throw Error("Adding track to non existing stream");
      }
      ;
      stream.oninactive = null;
      const newStream = new MediaStream(stream.getTracks().concat([audioTrack]));
      mediaPlayerContext.setLocalStream(newStream);
      setStream(newStream);
    };
    const shareScreen = () => __async(void 0, null, function* () {
      setAudioMissing(false);
      try {
        if (stream) {
          stream.getVideoTracks().map((t) => t.stop());
        }
        let controller;
        if (window.CaptureController) {
          controller = new window.CaptureController();
          controller.setFocusBehavior("no-focus-change");
        }
        const newStream = yield navigator.mediaDevices.getDisplayMedia(
          {
            controller,
            selfBrowserSurface: "exclude",
            video: videoConstraints(quality),
            audio: audioConstraints
          }
        );
        if (!newStream)
          throw "Stream missing";
        const hasAudio = newStream.getAudioTracks().length > 0;
        if (hasAudio) {
          mediaPlayerContext.setLocalUrl(null);
          mediaPlayerContext.setLocalStream(newStream);
          mediaPlayerContext.setRelayStream(!!relay);
          setStream(newStream);
          onClose();
        } else {
          setStream(newStream);
          setAudioMissing(true);
        }
      } catch (e) {
        console.error(e);
        onClose();
      }
    });
    useEffect(() => {
      track("UI", "ScreenshareModalOpened", "Screenshare modal opened");
    }, []);
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      EnableAudioModal,
      {
        relay: !!relay,
        stream,
        styles,
        open: audioMissing,
        onClose: () => {
          setAudioMissing(false);
          onClose();
        },
        shareScreen,
        addAudioTrack,
        videoConstraints: videoConstraints(quality)
      }
    ), /* @__PURE__ */ React.createElement(FadeInTransition, null, children, !!window.chrome && navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia ? /* @__PURE__ */ React.createElement("div", { className: styles.outerWrapper }, /* @__PURE__ */ React.createElement("div", { className: styles.innerWrapper }, /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "yellow",
        onClick: () => {
          localStream == null ? void 0 : localStream.getTracks().map((t) => t.stop());
          shareScreen();
        }
      },
      customLabel || "Click here to share your screen"
    ), /* @__PURE__ */ React.createElement(
      "div",
      {
        id: "yo",
        className: (advancedSettingsOpen ? [styles.advancedSettings, styles.open] : [styles.advancedSettings]).join(" ")
      },
      !simple && /* @__PURE__ */ React.createElement(
        "div",
        {
          style: {
            margin: "auto",
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }
        },
        /* @__PURE__ */ React.createElement(
          Checkbox,
          {
            label: "Enable Relay Server",
            defaultChecked: relay || false,
            toggle: true,
            onChange: (_e, data) => {
              if (data.checked) {
                track(
                  "UI",
                  "RelayServerEnabled",
                  "Relay server enabled"
                );
              } else {
                track(
                  "UI",
                  "RelayServerDisabled",
                  "Relay server disabled"
                );
              }
              setRelay(!!data.checked);
            }
          }
        ),
        /* @__PURE__ */ React.createElement(
          "div",
          {
            className: styles.questionMark,
            onClick: () => alert && alert(relayServerMsg)
          },
          "(???)"
        )
      ),
      /* @__PURE__ */ React.createElement(
        Select,
        {
          className: styles.qualitySelect,
          value: quality,
          onChange: (e) => __async(void 0, null, function* () {
            const value = e.target.value;
            if (value === "1080p" || value === "unlimited") {
              if (!currentUser.isSubscribed) {
                alert && alert(
                  /* @__PURE__ */ React.createElement(
                    PaywallMessage,
                    {
                      message: "Please get Premium to get 1080p+ screensharing. Please keep in mind that you will need a high speed internet connection to take advantage of it.",
                      source: "ScreenshareModal"
                    }
                  ),
                  true
                );
                return;
              } else {
                if (confirm && !(yield confirm(
                  "Quality over 720p requires a very high speed internet connection and might cause video choppiness. Continue?"
                ))) {
                  return;
                }
              }
            }
            track("UI", "QualitySelected", "Quality Selected");
            setQuality(value);
          }),
          options: [
            { text: "360p", value: "360p" },
            { text: "480p", value: "480p" },
            { text: "720p", value: "720p" },
            { text: "1080p", value: "1080p" },
            { text: "Unlimited", value: "unlimited" }
          ]
        }
      )
    ))) : /* @__PURE__ */ React.createElement("div", { className: styles.info }, "Unfortunately your browser does not support screensharing with audio. Please switch to a Chromium based browser such as Google Chrome.")));
  }
);
