"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _gql = require("kosmi-sdk/gql");
var _react = _interopRequireWildcard(require("react"));
var _apps = _interopRequireDefault(require("../pages/room/apps"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  dashboard: {
    "dashboard__styles.dashboard": "dashboard__styles.dashboard",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xh8yej3",
    height: "x5yr21d",
    $$css: true
  },
  table: {
    "dashboard__styles.table": "dashboard__styles.table",
    maxWidth: "x1cxeki3",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  membersCell: {
    "dashboard__styles.membersCell": "dashboard__styles.membersCell",
    fontSize: "x93iunb",
    paddingLeft: "x1uhho1l",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    $$css: true
  },
  roomsCell: {
    "dashboard__styles.roomsCell": "dashboard__styles.roomsCell",
    fontSize: "x93iunb",
    paddingLeft: "x1uhho1l",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    $$css: true
  }
};
const ROOMS_QUERY = (0, _gql.gql)(`
  query RoomsQuery($minUsers: Int!) {
    rooms(minUsers: $minUsers) {
      id
      state {
        memberCount
        runningApp
      }
    }
  }
`);
const sum = arr => arr.reduce((a, b) => a + b, 0);
const minUsers = window.location.search.startsWith("?min=") ? parseInt(window.location.search.split("?min=")[1]) : 1;
const Dashboard = (0, _react.memo)(() => {
  var _a, _b, _c;
  const {
    data
  } = (0, _gql.useQuery)(ROOMS_QUERY, {
    variables: {
      minUsers
    }
  });
  const appNames = Object.keys(_apps.default);
  if (!data) return null;
  const memberCount = (_b = (_a = data == null ? void 0 : data.rooms) == null ? void 0 : _a.map(r => {
    var _a2;
    return (_a2 = r == null ? void 0 : r.state) == null ? void 0 : _a2.memberCount;
  })) == null ? void 0 : _b.reduce((a, b) => (a || 0) + (b || 0), 0);
  const roomCount = (_c = data == null ? void 0 : data.rooms) == null ? void 0 : _c.length;
  if (!roomCount) return null;
  const aggregatedData = appNames.map(appName => {
    var _a2;
    const rooms = (_a2 = data == null ? void 0 : data.rooms) == null ? void 0 : _a2.filter(r => {
      var _a3;
      return ((_a3 = r == null ? void 0 : r.state) == null ? void 0 : _a3.runningApp) === appName;
    });
    return {
      appName,
      userCount: sum(rooms == null ? void 0 : rooms.map(r => {
        var _a3;
        return (_a3 = r == null ? void 0 : r.state) == null ? void 0 : _a3.memberCount;
      })),
      roomCount: rooms == null ? void 0 : rooms.length
    };
  }).filter(r => r.userCount > 0).sort((a, b) => b.userCount - a.userCount);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.dashboard
  }, /* @__PURE__ */_react.default.createElement(_uikit.Table, {
    striped: true,
    className: styles.table
  }, /* @__PURE__ */_react.default.createElement(_uikit.Table.Header, null, /* @__PURE__ */_react.default.createElement(_uikit.Table.Row, null, /* @__PURE__ */_react.default.createElement(_uikit.Table.Cell, null), /* @__PURE__ */_react.default.createElement(_uikit.Table.Cell, null, "Connected Users"), /* @__PURE__ */_react.default.createElement(_uikit.Table.Cell, null, "Room count"))), /* @__PURE__ */_react.default.createElement(_uikit.Table.Body, null, /* @__PURE__ */_react.default.createElement(_uikit.Table.Row, null, /* @__PURE__ */_react.default.createElement(_uikit.Table.Cell, null), /* @__PURE__ */_react.default.createElement(_uikit.Table.Cell, {
    className: styles.membersCell
  }, memberCount), /* @__PURE__ */_react.default.createElement(_uikit.Table.Cell, {
    className: styles.roomsCell
  }, roomCount)), aggregatedData.map(d => /* @__PURE__ */_react.default.createElement(_uikit.Table.Row, {
    key: d.appName
  }, /* @__PURE__ */_react.default.createElement(_uikit.Table.Cell, null, d.appName), /* @__PURE__ */_react.default.createElement(_uikit.Table.Cell, null, d.userCount), /* @__PURE__ */_react.default.createElement(_uikit.Table.Cell, null, d.roomCount))))));
});
var _default = exports.default = Dashboard;