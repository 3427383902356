"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OnboardingTooltip = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const HighlightedElement = (0, _react.forwardRef)(({
  children,
  enableSpotlight
}, ref) => {
  const clonedChild = _react.default.cloneElement(children, {
    ref
  });
  return /* @__PURE__ */_react.default.createElement(_uikit.Spotlight, {
    animated: true,
    noClick: true,
    brightness: 50,
    enabled: enableSpotlight
  }, clonedChild);
});
const styles = {
  popup: {
    "OnboardingTooltip__styles.popup": "OnboardingTooltip__styles.popup",
    backgroundColor: "x1ut2sa4",
    cursor: "xt0e3qv",
    color: "x1525slw",
    fontSize: "xif65rj",
    zIndex: "x1jinmle",
    borderWidth: null,
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "xng3xce",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  innerContent: {
    "OnboardingTooltip__styles.innerContent": "OnboardingTooltip__styles.innerContent",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    height: "x1vqgdyp",
    marginTop: "x1anpbxc",
    $$css: true
  },
  gotItButton: {
    "OnboardingTooltip__styles.gotItButton": "OnboardingTooltip__styles.gotItButton",
    display: "x78zum5",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  }
};
const OnboardingTooltip = exports.OnboardingTooltip = (0, _react.forwardRef)((props, ref) => {
  const {
    onClick,
    onSkip,
    open,
    step,
    totalSteps,
    trigger
  } = props;
  return /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    trigger: /* @__PURE__ */_react.default.createElement(HighlightedElement, {
      enableSpotlight: open,
      ref
    }, trigger),
    controlled: true,
    open,
    className: styles.popup,
    position: props.position,
    content: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, props.content, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.innerContent
    }, step && totalSteps && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, step, "/", totalSteps), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.gotItButton
    }, onSkip && /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      inverted: true,
      size: "xs",
      color: "black",
      onClick: onSkip
    }, "Hide tips"), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      style: {
        marginLeft: 5
      },
      size: "xs",
      color: "white",
      onClick
    }, "Got it"))))
  });
});