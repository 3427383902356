"use strict";
import { gql } from "kosmi-sdk/gql";
import { Query } from "kosmi-sdk/gql";
import { Button } from "kosmi-sdk/uikit";
import { withStyle } from "kosmi-sdk/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import AppContext from "../../AppContext";
import CreateRoomModal from "../Widgets/CreateRoomModal";
import FadeInTransition from "../Widgets/FadeInTransition";
import withTracking from "../helpers/withTracking";
import withCurrentUser from "../helpers/withCurrentUser";
import styles from "./style.css";
import kosmiHead from "../../components/Room/components/Sidebar/components/RoomBanner/kosmihead_tinted.svg";
import { useIsMobile } from "../../components/helpers/withMobileDetection";
import LoginWall from "../../components/Widgets/LoginWall";
const USER_ROOMS = gql(`
  query UserRoomQueryWelcome {
    userRooms {
      id
      unreadMessages
      state {
        metadata {
          roomName
          avatarUrl
        }
        isLive
        members {
          id
          role
          user {
            id
            username
            displayName
            avatarUrl
            friends {
              state
              user {
                id
                displayName
                username
                avatarUrl
                email
                isConnected
              }
            }
          }
        }
      }
    }
  }
`);
const Welcome = ({ styles: styles2, history, track, currentUser }) => {
  const { isMobile } = useIsMobile();
  return /* @__PURE__ */ React.createElement(FadeInTransition, null, /* @__PURE__ */ React.createElement("div", { className: styles2.discover }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("img", { className: styles2.logo, src: kosmiHead, alt: "Kosmi Logo" }), /* @__PURE__ */ React.createElement("div", { className: styles2.welcome }, "Welcome to Kosmi"), /* @__PURE__ */ React.createElement("div", null, "The place to hang out with friends and colleagues. "), /* @__PURE__ */ React.createElement(Query, { query: USER_ROOMS }, ({ data }) => {
    if (!data)
      return null;
    if (data.userRooms && data.userRooms.length && !isMobile) {
      return /* @__PURE__ */ React.createElement(AppContext.Consumer, null, (appContext) => /* @__PURE__ */ React.createElement(LoginWall, null, /* @__PURE__ */ React.createElement(
        Button,
        {
          color: "blue",
          style: { height: 50, width: 120 },
          onClick: () => {
            var _a;
            if (((_a = data == null ? void 0 : data.userRooms) == null ? void 0 : _a.length) === 1) {
              history.push(`/room/${data.userRooms[0].id}`);
            } else {
              appContext.toggleRoomsDropdown();
            }
          }
        },
        "My room",
        (data == null ? void 0 : data.userRooms.length) > 1 ? "s" : ""
      )));
    }
    return /* @__PURE__ */ React.createElement(
      CreateRoomModal,
      {
        onOpen: () => track(
          "UI",
          "CreateRoomModalOpenedFromFrontPage",
          "CreateRoom Modal Opened from Front Page"
        ),
        onExplicitClose: () => {
          var _a;
          if (currentUser.isAnonymous && ((_a = window == null ? void 0 : window.featureFlags) == null ? void 0 : _a.requireLoginToCreateRoom)) {
            track(
              "UI",
              "CreateRoomModalLoginRequiredClosed",
              "CreateRoom Modal closed (Login required)"
            );
          } else if (currentUser.isAnonymous) {
            track(
              "UI",
              "CreateRoomModalLoginNotRequiredClosed",
              "CreateRoom Modal closed (Login not required)"
            );
          } else {
            track(
              "UI",
              "CreateRoomModalClosed",
              "CreateRoom Modal closed"
            );
          }
        },
        trigger: /* @__PURE__ */ React.createElement(Button, { style: { height: 50, width: 220 }, color: "yellow" }, "Click here to create a room")
      }
    );
  })), /* @__PURE__ */ React.createElement("div", { className: styles2.noFriendsOnline }, /* @__PURE__ */ React.createElement("div", null, "No friends online?"), /* @__PURE__ */ React.createElement(
    Button,
    {
      inverted: true,
      color: "white",
      onClick: () => {
        history.push("/lobby");
      },
      style: { height: 50, maxWidth: 150 }
    },
    "Join the lobby"
  )), /* @__PURE__ */ React.createElement("div", { className: styles2.legalLinks }, /* @__PURE__ */ React.createElement(
    "a",
    {
      target: "_blank",
      href: "https://www.iubenda.com/terms-and-conditions/30233393",
      rel: "noreferrer"
    },
    "Terms & Conditions"
  ), /* @__PURE__ */ React.createElement(
    "a",
    {
      target: "_blank",
      href: "https://www.iubenda.com/privacy-policy/30233393/legal",
      rel: "noreferrer"
    },
    "Privacy Policy"
  ))));
};
export default withCurrentUser(
  withTracking(withRouter(withStyle(styles)(Welcome)))
);
