"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _uikit = require("kosmi-sdk/uikit");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useTracking = require("@/hooks/useTracking");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  wrapper: {
    "ImageModal__styles.wrapper": "ImageModal__styles.wrapper",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    $$css: true
  },
  image: {
    "ImageModal__styles.image": "ImageModal__styles.image",
    maxWidth: "x14r61f",
    $$css: true
  }
};
const ImageModal = (0, _react.memo)(({
  imageSrc,
  handleClose,
  open
}) => {
  const track = (0, _useTracking.useTracking)();
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open,
    onOpen: () => track("UI", "ImageModalOpened", "An image modal was opened form the chat"),
    onClose: () => {
      handleClose();
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.wrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: styles.image,
    width: 400,
    src: imageSrc,
    alt: "Zoomed image"
  }))));
});
var _default = exports.default = ImageModal;