"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _reactRouterDom = require("react-router-dom");
var _MobileContext = _interopRequireDefault(require("./MobileContext"));
var _BottomMenu = _interopRequireDefault(require("./BottomMenu"));
var _MobileOverlay = _interopRequireDefault(require("./MobileOverlay"));
var _uikit = require("kosmi-sdk/uikit");
var _usePersistedState = _interopRequireDefault(require("use-persisted-state"));
var _helpers = require("kosmi-sdk/helpers");
var _useApplyColorScheme = _interopRequireDefault(require("../../hooks/useApplyColorScheme"));
var _App = require("../../App");
var _useMobileDetection = require("@/hooks/useMobileDetection");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const useMobileOverlayState = (0, _usePersistedState.default)("mobileTab");
const styles = {
  wrapper: {
    "index__styles.wrapper": "index__styles.wrapper",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    flexDirection: "xdt5ytf",
    position: "x1n2onr6",
    height: "x5yr21d",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    justifyContent: "x1qughib",
    $$css: true
  },
  innerWrapper: {
    "index__styles.innerWrapper": "index__styles.innerWrapper",
    height: "x5yr21d",
    width: "xh8yej3",
    $$css: true
  },
  innerWrapperMobile: {
    "index__styles.innerWrapperMobile": "index__styles.innerWrapperMobile",
    zIndex: "xhtitgo",
    $$css: true
  },
  innerWrapperMobilePortrait: {
    "index__styles.innerWrapperMobilePortrait": "index__styles.innerWrapperMobilePortrait",
    position: "x10l6tqk",
    pointerEvents: "x47corl",
    $$css: true
  }
};
var _default = exports.default = (0, _react.memo)(({
  children
}) => {
  const location = (0, _reactRouterDom.useLocation)();
  const {
    isMobile,
    isLandscape
  } = (0, _useMobileDetection.useIsMobile)();
  const isRoom = location.pathname.startsWith("/room/");
  const roomId = location.pathname.split("/room/")[1];
  const [_expandRoom, setExpandRoom] = (0, _react.useState)(isRoom);
  const expandRoom = _expandRoom || isLandscape;
  const [hideBottomMenu, setHideBottomMenu] = (0, _react.useState)(false);
  const [overlayMenu, setOverlayMenu] = useMobileOverlayState("my_rooms");
  const currentUser = (0, _helpers.currentUserHook)();
  const isLoggedIn = !(currentUser == null ? void 0 : currentUser.isAnonymous);
  (0, _useApplyColorScheme.default)(!!roomId && roomId !== "lobby");
  (0, _react.useEffect)(() => {
    if (roomId) {
      setExpandRoom(true);
    } else {
      setExpandRoom(false);
    }
  }, [roomId]);
  return /* @__PURE__ */_react.default.createElement(_MobileContext.default.Provider, {
    value: {
      expandRoom,
      setExpandRoom,
      overlayMenu,
      setOverlayMenu,
      setHideBottomMenu
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.wrapper
  }, isMobile && /* @__PURE__ */_react.default.createElement(_App.ConnectingLoader, null), isMobile && /* @__PURE__ */_react.default.createElement(_MobileOverlay.default, null), (!isMobile || isRoom || isLandscape) && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.innerWrapper, isMobile && !isLandscape && styles.innerWrapperMobilePortrait, isMobile && styles.innerWrapperMobile]
  }, children), isMobile && isLoggedIn && /* @__PURE__ */_react.default.createElement(_BottomMenu.default, {
    hide: hideBottomMenu || expandRoom
  })));
});