"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _widgets = require("kosmi-sdk/widgets");
var _react = _interopRequireWildcard(require("react"));
var _AVEngine = _interopRequireDefault(require("../../../services/AVEngine"));
var _RoomContext = _interopRequireDefault(require("../contexts/RoomContext"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
const styles = {
  audioModal: {
    "WebRTCUserAudioTrack__styles.audioModal": "WebRTCUserAudioTrack__styles.audioModal",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    display: "x78zum5",
    paddingBottom: "x1hhzuzn",
    $$css: true
  }
};
class WebRTCAudioElement extends _react.default.Component {
  constructor(props) {
    super(props);
    __publicField(this, "audioel");
    __publicField(this, "mediaStream", null);
    __publicField(this, "forcePlay");
    this.state = {};
  }
  shouldComponentUpdate(nextProps) {
    var _a, _b;
    return ((_a = nextProps.track) == null ? void 0 : _a.id) !== ((_b = this.props.track) == null ? void 0 : _b.id) || this.props.muted !== nextProps.muted;
  }
  componentDidMount() {
    this.forcePlay = setInterval(() => {
      if (this.audioel && this.audioel.paused) {
        try {
          this.audioel.play();
        } catch (e) {
          console.warn(e);
        }
      } else {
        this.setState({
          paused: false
        }, () => {
          clearInterval(this.forcePlay);
        });
      }
    }, 5e3);
  }
  componentDidUpdate() {
    var _a;
    if ((_a = this.props.track) == null ? void 0 : _a.id) _AVEngine.default.removeMediaElementAudioFromEngine(this.props.track.id, this.props.userId);
  }
  componentWillUnmount() {
    if (this.audioel) this.audioel.srcObject = null;
    this.mediaStream = null;
    if (this.props.track) this.props.track.stop();
    clearInterval(this.forcePlay);
    _AVEngine.default.removeMediaElementAudioFromEngine(this.props.track.id, this.props.userId);
  }
  render() {
    const {
      track,
      muted
    } = this.props;
    const enableAudio = () => [...document.getElementsByClassName("rtcAudioTrack")].forEach(t => {
      try {
        t.play();
      } catch (e) {
        console.warn(e);
      }
    });
    return track ? /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, this.state.paused ? /* @__PURE__ */_react.default.createElement(_widgets.AnimatedModal, {
      open: true,
      preventClosing: true
    }, /* @__PURE__ */_react.default.createElement(_widgets.AnimatedModal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.audioModal
    }, "Please enable audio to use Kosmi"), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      fluid: true,
      color: "yellow",
      inverted: true,
      onClick: () => {
        enableAudio();
      }
    }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      name: "volume up"
    }), "Enable audio"))) : null, /* @__PURE__ */_react.default.createElement("audio", {
      className: "rtcAudioTrack",
      key: track.id,
      muted,
      ref: audio => {
        this.audioel = audio;
        if (audio) {
          this.mediaStream = new MediaStream([track]);
          audio.srcObject = this.mediaStream;
          audio.onloadedmetadata = () => {
            audio.play();
            try {
              audio.play();
            } catch (e) {
              console.warn(e);
            }
            if (audio.paused) {
              if (document.getElementsByClassName("autoPlayBuster").length === 0) {
                this.setState({
                  paused: true
                });
              }
            }
          };
        }
      }
    })) : null;
  }
}
var _default = exports.default = (0, _react.memo)(props => {
  const {
    microphoneStreams
  } = (0, _react.useContext)(_RoomContext.default);
  const stream = microphoneStreams && microphoneStreams[props.userId];
  const track = stream && stream.getAudioTracks && stream.getAudioTracks()[0];
  return (track == null ? void 0 : track.id) && track.id !== "LOADING" ? /* @__PURE__ */_react.default.createElement(WebRTCAudioElement, __spreadProps(__spreadValues({}, props), {
    track
  })) : null;
}, (props, nextProps) => {
  const relevantProps = props2 => JSON.stringify([props2.userId, props2.muted]);
  return relevantProps(props) === relevantProps(nextProps);
});