"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useEffect, useState, useRef, memo, useContext } from "react";
import rollbar from "rollbar";
import { currentUserHook, withDialogs } from "kosmi-sdk/helpers";
import { useWebRTCConsumer, useWebRTCProducer } from "kosmi-sdk/webrtc";
import Video from "./Video";
import {
  RTC_URL_PROTOCOL,
  RTC_SCREENSHARE_PROTOCOL,
  RELAYED_RTC_SCREENSHARE_PROTOCOL,
  RELAYED_RTC_URL_PROTOCOL,
  isRelayed,
  isScreenShare,
  isRtcUrl,
  isObsUrl,
  isHttp,
  isProxied
} from "./Video/helpers";
import { getPosition as getVideoPosition } from "./helpers";
import { useMediaPlayerActions } from "./useMediaPlayerState";
import { useMediaPlayerStateCached } from "../../hooks/useMediaPlayerData";
import { useMediaSoup } from "../../hooks/webrtc/mediasoup/useMediaSoup";
import ReactPlayer from "react-player";
import MediaPlayerContext from "../../MediaPlayerContext";
import { ErrorScreen } from "../../../Widgets";
import { isNumber } from "lodash";
import { useObsStream } from "../../hooks/webrtc/obs";
const RTCVideoConsumer = memo(
  ({ onStream, onError, hostUserId, url }) => {
    const { stream, error } = useWebRTCConsumer(hostUserId, url);
    useEffect(() => {
      if (stream) {
        onStream(stream);
      }
    }, [stream]);
    useEffect(() => {
      if (error) {
        onError(error);
      }
    }, [error]);
    return null;
  }
);
const RTCVideoConsumerWithRetries = memo(
  ({ onStream, onError, hostUserId, url }) => {
    const [retries, setRetries] = useState(0);
    const onErrorWithRetries = (error) => {
      if (retries > 5)
        onError(error);
      else {
        setRetries(retries + 1);
      }
    };
    return /* @__PURE__ */ React.createElement(
      RTCVideoConsumer,
      __spreadValues({
        key: retries
      }, { onStream, onError: onErrorWithRetries, hostUserId, url })
    );
  }
);
const RTCVideoProducer = memo(
  ({ stream, setUrl, currentUser, videoEl, localUrl }) => {
    const url = localUrl ? `${RTC_URL_PROTOCOL}/${currentUser.id}/${localUrl}` : `${RTC_SCREENSHARE_PROTOCOL}${currentUser.id}/${stream.id}`;
    const producer = useWebRTCProducer(url);
    useEffect(() => {
      let sourceStream = stream;
      if (!sourceStream) {
        if (!videoEl || !localUrl)
          return;
        const useMoz = !!videoEl.mozCaptureStream;
        if (useMoz) {
          sourceStream = videoEl.mozCaptureStream();
        } else if (videoEl.captureStream) {
          sourceStream = videoEl.captureStream();
        }
      }
      if (sourceStream && producer) {
        producer.setStream(sourceStream);
        setUrl(url);
      }
    }, [producer, url, videoEl]);
    return null;
  }
);
const RelayedRTCVideoProducer = memo(
  ({ stream, setUrl, currentUser, videoEl, localUrl }) => {
    let url = localUrl ? `${RELAYED_RTC_URL_PROTOCOL}/${currentUser.id}/${localUrl}` : `${RELAYED_RTC_SCREENSHARE_PROTOCOL}${currentUser.id}/${stream.id}`;
    const { produceMediaSoupStream, loading } = useMediaSoup();
    useEffect(() => {
      if (loading)
        return;
      let sourceStream = stream;
      if (!sourceStream) {
        if (!videoEl || !localUrl)
          return;
        const useMoz = !!videoEl.mozCaptureStream;
        sourceStream = useMoz ? videoEl.mozCaptureStream() : videoEl.captureStream();
      }
      if (sourceStream && url) {
        ;
        (() => __async(void 0, null, function* () {
          const hasAudio = sourceStream.getAudioTracks().length > 0;
          const params = hasAudio ? "" : "?noaudio=1";
          if (!hasAudio) {
            url = url + params;
          }
          yield produceMediaSoupStream(`MEDIA_PLAYER_${url}`, sourceStream);
          setUrl(url);
        }))();
      }
    }, [url, videoEl, loading]);
    return null;
  }
);
const RelayedRTCVideoConsumer = memo(
  ({ onStream, onError, hostUserId, url }) => {
    const { consumeMediaSoupStream, loading } = useMediaSoup();
    useEffect(() => {
      if (loading)
        return;
      (() => __async(void 0, null, function* () {
        try {
          const stream = yield consumeMediaSoupStream(
            hostUserId,
            `MEDIA_PLAYER_${url}`,
            url.endsWith("?noaudio=1") ? "video" : void 0
          );
          onStream(stream);
        } catch (e) {
          ;
          rollbar.error(e);
          onError((e == null ? void 0 : e.message) || e.toString());
        }
      }))();
    }, [loading]);
    return null;
  }
);
const RelayedRTCVideoConsumerWithRetries = memo(
  ({ onStream, onError, hostUserId, url }) => {
    const [retries, setRetries] = useState(0);
    const onErrorWithRetries = (error) => {
      if (retries > 5)
        onError(error);
      else {
        setRetries(retries + 1);
      }
    };
    return /* @__PURE__ */ React.createElement(
      RelayedRTCVideoConsumer,
      __spreadValues({
        key: retries
      }, { onStream, onError: onErrorWithRetries, hostUserId, url })
    );
  }
);
const VideoConsumer = memo(
  (_a) => {
    var _b = _a, { relay } = _b, props = __objRest(_b, ["relay"]);
    return relay ? /* @__PURE__ */ React.createElement(RelayedRTCVideoConsumerWithRetries, __spreadValues({}, props)) : /* @__PURE__ */ React.createElement(RTCVideoConsumerWithRetries, __spreadValues({}, props));
  }
);
const VideoProducer = memo(
  (_c) => {
    var _d = _c, { relay } = _d, props = __objRest(_d, ["relay"]);
    return relay ? /* @__PURE__ */ React.createElement(RelayedRTCVideoProducer, __spreadValues({}, props)) : /* @__PURE__ */ React.createElement(RTCVideoProducer, __spreadValues({}, props));
  }
);
const SyncedVideo = memo(
  ({ onReady, onUpdate, controls }) => {
    var _a, _b;
    const { closeProducerTransport, closeConsumerTransport } = useMediaSoup();
    const currentUser = currentUserHook();
    const { mediaPlayerState, subtitles } = useMediaPlayerStateCached();
    const mediaplayerContext = useContext(MediaPlayerContext);
    const { stopVideo, setUrl, setPosition, setBrowserUrl } = useMediaPlayerActions();
    const {
      url,
      type,
      paused,
      playbackRate,
      unixTimePositionSent,
      position,
      live,
      hostUser
    } = mediaPlayerState;
    const mediaPlayerStateRef = useRef();
    const iAmHostRef = useRef(false);
    const prevIamHost = iAmHostRef.current;
    const prevMediaPlayerState = mediaPlayerStateRef.current;
    const [player, setPlayer] = useState(null);
    const [stream, setStream] = useState(null);
    const [error, setError] = useState(null);
    const [localPlayerState, setLocalPlayerState] = useState(
      {}
    );
    const {
      position: playerPosition,
      paused: playerPaused,
      duration
    } = localPlayerState;
    const roundedPosition = playerPosition ? Math.round(playerPosition / 10) * 10 : void 0;
    const hostId = hostUser && hostUser.id;
    const iAmHost = currentUser.id === hostId;
    const hostUserId = (_a = mediaPlayerState == null ? void 0 : mediaPlayerState.hostUser) == null ? void 0 : _a.id;
    const isRelayedStream = Boolean(url && isRelayed(url));
    const imStreamingSomething = Boolean(
      mediaplayerContext.localStream || mediaplayerContext.localUrl
    );
    const isObsStream = !!(url == null ? void 0 : url.toLowerCase().startsWith("obs://"));
    const hostname = (url2) => url2.replace(/^(.*\/\/)?([^/]+).*$/, "$2");
    const obsHost = isObsStream && hostname(url || "") || null;
    const streamKey = isObsStream && (url == null ? void 0 : url.split("/").pop()) || null;
    const obsStream = useObsStream(obsHost, streamKey);
    const someOneElseIsStreamingSomething = Boolean(
      url && (isRtcUrl(url) || isScreenShare(url)) && !iAmHost
    );
    const autoPlay = true;
    //!room?.state?.metadata?.disableAutoplay || isScreenShare(url) || live
    const onStream = (stream2) => {
      setStream(stream2);
    };
    const playerStream = mediaPlayerState.url && (isRtcUrl(mediaPlayerState.url) || isScreenShare(mediaPlayerState.url) || isObsUrl(mediaPlayerState.url)) && (mediaplayerContext.localStream || stream);
    const onError = (_error) => {
      if (isProxied(url))
        return false;
      if (ReactPlayer.canPlay(url || "")) {
        return false;
      }
      if (url && isHttp(url)) {
        setBrowserUrl(url);
        return true;
      }
      return false;
    };
    const onStreamError = (error2) => {
      setError(error2);
    };
    const getPosition = () => getVideoPosition(
      !!mediaPlayerState.paused,
      position || 0,
      duration || 0,
      unixTimePositionSent || 0
    );
    const syncPosition = () => {
      if (!player)
        return;
      if ((mediaPlayerState == null ? void 0 : mediaPlayerState.live) !== false && !mediaplayerContext.localUrl)
        return;
      const position2 = getPosition();
      if (player && !(stream == null ? void 0 : stream.active)) {
        player.seekTo(position2);
      }
    };
    const videoPlayer = {
      player,
      video: player && player.video,
      syncPosition
    };
    const prevUrl = prevMediaPlayerState == null ? void 0 : prevMediaPlayerState.url;
    useEffect(() => {
      if (!url)
        return;
      if (playerPosition === void 0)
        return;
      if (!mediaplayerContext.localUrl && isRtcUrl(url) && subtitles) {
        const timeDiff = getPosition() - (playerPosition || 0);
        if (!isNaN(timeDiff)) {
          player == null ? void 0 : player.offsetSubtitles(subtitles, timeDiff);
        }
      }
      if (duration === void 0)
        return;
      if (live !== false && !mediaplayerContext.localUrl)
        return;
      if (Math.abs(playerPosition - getPosition()) >= 1.5) {
        syncPosition();
      }
    }, [
      duration,
      unixTimePositionSent,
      roundedPosition,
      position,
      live,
      paused,
      JSON.stringify(subtitles),
      url,
      mediaplayerContext.localUrl,
      controls
    ]);
    useEffect(() => {
      if (!player)
        return;
      if (playerPaused && !paused) {
        try {
          if (autoPlay) {
            player.play();
          }
          if (mediaPlayerState == null ? void 0 : mediaPlayerState.position) {
            setPosition(mediaPlayerState.position);
          }
        } catch (e) {
          console.warn(e);
        }
      }
      if (!playerPaused && paused && url && !isScreenShare(url)) {
        try {
          player.pause();
        } catch (e) {
          console.warn(e);
        }
      }
    }, [player == null ? void 0 : player.id, paused, playerPaused]);
    useEffect(() => {
      if (obsStream) {
        onStream(obsStream);
      }
    }, [obsStream]);
    useEffect(() => {
      mediaPlayerStateRef.current = mediaPlayerState;
    }, [JSON.stringify(mediaPlayerState)]);
    if (mediaplayerContext.localStream) {
      ;
      mediaplayerContext.localStream.oninactive = () => {
        var _a2;
        if (url && mediaplayerContext.localStream && (url == null ? void 0 : url.indexOf((_a2 = mediaplayerContext.localStream) == null ? void 0 : _a2.id)) !== -1)
          stopVideo();
      };
    }
    useEffect(() => {
      if (!player)
        return;
      mediaplayerContext.setPlayer(videoPlayer);
      if (player.video instanceof Element) {
        try {
          player.video.playbackRate = isNumber(playbackRate) ? playbackRate : 1;
        } catch (e) {
          console.error(e);
        }
      }
      if (onReady) {
        onReady(videoPlayer);
      }
      if (isScreenShare(url) && iAmHost) {
        player.mute();
      }
    }, [player == null ? void 0 : player.id]);
    useEffect(() => {
      var _a2, _b2;
      if (!prevMediaPlayerState)
        return;
      if (!prevUrl)
        return;
      const prevHostId = (_a2 = prevMediaPlayerState == null ? void 0 : prevMediaPlayerState.hostUser) == null ? void 0 : _a2.id;
      if (prevUrl !== url) {
        if (isRelayed(prevUrl)) {
          if (prevIamHost) {
            closeProducerTransport(`MEDIA_PLAYER_${prevUrl}`);
          } else {
            closeConsumerTransport(prevHostId, `MEDIA_PLAYER_${prevUrl}`);
          }
        }
        if (mediaplayerContext.localUrl && !isRtcUrl(url)) {
          mediaplayerContext.setLocalUrl(null);
        }
        if (mediaplayerContext.localStream && !isScreenShare(url)) {
          (_b2 = mediaplayerContext.localStream) == null ? void 0 : _b2.getTracks().map((t) => t.stop());
          mediaplayerContext.setLocalStream(null);
        }
      }
    }, [
      url,
      iAmHost,
      prevMediaPlayerState,
      mediaplayerContext.localStream,
      mediaplayerContext.localUrl
    ]);
    if (error) {
      return /* @__PURE__ */ React.createElement(ErrorScreen, { text: error });
    }
    return /* @__PURE__ */ React.createElement(React.Fragment, null, imStreamingSomething && /* @__PURE__ */ React.createElement(
      VideoProducer,
      {
        stream: mediaplayerContext.localStream,
        setUrl,
        currentUser,
        videoEl: mediaplayerContext.localUrl ? player == null ? void 0 : player.video : null,
        localUrl: mediaplayerContext.localUrl,
        key: "PRODUCER_" + (((_b = mediaplayerContext.localStream) == null ? void 0 : _b.id) || mediaplayerContext.localUrl),
        relay: mediaplayerContext.relayStream
      }
    ), someOneElseIsStreamingSomething && !!hostUserId && /* @__PURE__ */ React.createElement(
      VideoConsumer,
      {
        hostUserId,
        onStream,
        onError: onStreamError,
        url: url || "",
        key: "CONSUMER_" + url,
        relay: isRelayedStream
      }
    ), /* @__PURE__ */ React.createElement(
      Video,
      {
        key: mediaplayerContext.localUrl || url,
        controls,
        autoPlay,
        stream: playerStream,
        type,
        url: mediaplayerContext.localUrl || url,
        paused,
        subtitles,
        onUnmount: () => setPlayer(null),
        onSupportedUrl: (url2) => setUrl(url2),
        onBannedUrl: stopVideo,
        onReady: setPlayer,
        onError,
        onUpdate: (localPlayerState2) => {
          setLocalPlayerState(localPlayerState2);
          if (onUpdate) {
            onUpdate(localPlayerState2);
          }
        }
      }
    ));
  }
);
export default withDialogs(SyncedVideo);
