"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { gql, useQuery, useSubscription } from "kosmi-sdk/gql";
import { useEffect } from "react";
const ON_SUBSCRIPTION_UPDATE = gql(`
  subscription SubscriptionUpdateSubscription {
    stripeSubscriptionUpdate {
      subscription {
        id
        cancelAtPeriodEnd
        periodEnds
        status
      }
      user {
        id
        isSubscribed
      }
    }
  }
`);
const ON_FRIENDLIST_UPDATE = gql(`
  subscription OnFriendListUpdate {
    friendlistUpdate {
      id
      friends {
        state
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const NOTIFICATION_QUERY = gql(`
  query NotificationQuery1 {
    currentUser {
      id
      connectionId
      user {
        id
        notifications {
          id
          title
          text
          type
          timestamp
          seen
          autoOpen
          sender {
            id
            username
            displayName
            avatarUrl
          }
        }
      }
    }
  }
`);
const NEW_NOTIFICATION = gql(`
  subscription NewNotificationSubscription {
    newNotification {
      id
      title
      text
      type
      timestamp
      seen
      autoOpen
      sender {
        id
        username
        displayName
        avatarUrl
      }
    }
  }
`);
const NEW_PUBLIC_NOTIFICATION = gql(`
  subscription NewPublicNotificationSubscription {
    newPublicNotification {
      id
      title
      text
      type
      timestamp
      seen
      autoOpen
      sender {
        id
        username
        displayName
        avatarUrl
      }
    }
  }
`);
const SETTINGS_SUBSCRIPTION = gql(`
  subscription SettingsSubscription {
    settingsUpdate {
      id
      colorScheme
    }
  }
`);
const useNotificationSubscriptions = () => {
  const { subscribeToMore } = useQuery(NOTIFICATION_QUERY);
  useEffect(() => {
    const unsub = [NEW_NOTIFICATION, NEW_PUBLIC_NOTIFICATION].map(
      (document) => subscribeToMore({
        document,
        variables: {},
        updateQuery: (prev, { subscriptionData: { data } }) => {
          var _a, _b, _c, _d, _e;
          const { newNotification, newPublicNotification } = data;
          const notification = newNotification || newPublicNotification;
          if (!notification)
            return prev;
          const prevNotifications = (((_b = (_a = prev == null ? void 0 : prev.currentUser) == null ? void 0 : _a.user) == null ? void 0 : _b.notifications) || []).filter((n) => n != null);
          const result = __spreadProps(__spreadValues({}, prev), {
            currentUser: __spreadProps(__spreadValues({}, prev.currentUser), {
              user: __spreadProps(__spreadValues({}, (_c = prev == null ? void 0 : prev.currentUser) == null ? void 0 : _c.user), {
                notifications: notification ? [notification].concat(prevNotifications) : (_e = (_d = prev.currentUser) == null ? void 0 : _d.user) == null ? void 0 : _e.notifications
              })
            })
          });
          return result;
        }
      })
    );
    return () => {
      unsub.forEach((unsubscribe) => unsubscribe());
    };
  }, []);
};
export default () => {
  useNotificationSubscriptions();
  useSubscription(ON_FRIENDLIST_UPDATE);
  useSubscription(ON_SUBSCRIPTION_UPDATE);
  useSubscription(SETTINGS_SUBSCRIPTION);
};
