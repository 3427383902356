"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { forwardRef, useCallback, useState } from "react";
import style from "./style.css";
import { Button, Icon, Popup } from "kosmi-sdk/uikit";
import { withStyleHook } from "kosmi-sdk/styles";
import usePlatform from "../../../../../../../../../hooks/usePlatform";
const ChoiceButton = forwardRef((props, ref) => /* @__PURE__ */ React.createElement(
  Button,
  __spreadProps(__spreadValues({}, props), {
    inverted: true,
    color: "white",
    ref,
    style: {
      height: "60px",
      width: "60px",
      margin: "5px"
    }
  })
));
export default ({
  screen,
  onChange
}) => {
  const styles = withStyleHook(style);
  const [div, setDiv] = useState(null);
  const { isMobile, canCaptureVideoElement } = usePlatform();
  const divRef = useCallback(
    (node) => {
      setDiv(node);
    },
    [screen]
  );
  return /* @__PURE__ */ React.createElement("div", { className: styles.videoChoices, ref: divRef }, !isMobile && div && /* @__PURE__ */ React.createElement(
    Popup,
    {
      position: "bottom center",
      content: /* @__PURE__ */ React.createElement("div", { style: { fontWeight: 900 } }, /* @__PURE__ */ React.createElement("div", null, "Screenshare"), "Great for Netflix and other streaming services or simply for sharing your screen or browser tab!"),
      mountNode: div,
      trigger: /* @__PURE__ */ React.createElement(
        ChoiceButton,
        {
          className: styles.choice,
          inverted: true,
          onClick: () => onChange("screenshare")
        },
        /* @__PURE__ */ React.createElement(Icon, { name: "tv" })
      )
    }
  ), isMobile ? /* @__PURE__ */ React.createElement(
    ChoiceButton,
    {
      className: styles.choice,
      inverted: true,
      onClick: () => onChange("url")
    },
    /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Icon, { name: "linkify" }), isMobile && /* @__PURE__ */ React.createElement("div", null, "Load URL"))
  ) : /* @__PURE__ */ React.createElement(
    Popup,
    {
      content: /* @__PURE__ */ React.createElement("div", { style: { fontWeight: 900 } }, /* @__PURE__ */ React.createElement("div", null, "URL"), "Supports links from YouTube, Vimeo, Twitch and many others as well as direct video links."),
      mountNode: div,
      trigger: /* @__PURE__ */ React.createElement(
        ChoiceButton,
        {
          className: styles.choice,
          inverted: true,
          onClick: () => onChange("url"),
          style: {
            marginLeft: 4,
            marginRight: 4
          }
        },
        /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Icon, { name: "linkify" }), isMobile && /* @__PURE__ */ React.createElement("div", null, "Load URL"))
      )
    }
  ), canCaptureVideoElement && /* @__PURE__ */ React.createElement(
    Popup,
    {
      content: /* @__PURE__ */ React.createElement("div", { style: { fontWeight: 900 } }, /* @__PURE__ */ React.createElement("div", null, "Local file"), "Load a local video file. Supports mkv, mp4 and more. Subtitles supported."),
      mountNode: div,
      trigger: /* @__PURE__ */ React.createElement(
        ChoiceButton,
        {
          className: styles.choice,
          inverted: true,
          onClick: () => onChange("file"),
          style: {
            marginLeft: 4,
            marginRight: 4
          }
        },
        /* @__PURE__ */ React.createElement(Icon, { name: "file video" }),
        isMobile && /* @__PURE__ */ React.createElement("div", null, "Load File")
      )
    }
  ));
};
