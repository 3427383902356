"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation, useQuery } from "kosmi-sdk/gql";
import createPersistedState from "use-persisted-state";
import React, {
  forwardRef,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  Button,
  Checkbox,
  ContextMenu,
  Icon,
  Image,
  TabView,
  MessageList,
  Popup
} from "kosmi-sdk/uikit";
import { TopBarButton } from "../..";
import { withStyleHook } from "../../../../../../../../sdk/styles";
import { currentUserHook } from "../../../../../../../helpers/withCurrentUser";
import withDialogs from "../../../../../../../helpers/withDialogs";
import { useIsMobile } from "../../../../../../../helpers/withMobileDetection";
import LoginModal from "../../../../../../../Widgets/LoginModal";
import Messagebox from "../../../Messagebox";
import Messages from "../../../Messages";
import { UserPickerModal, UserProfileModal } from "../FriendsDropdown";
import style from "./style.css";
import { LoadingScreen } from "../../../../../../../Widgets";
import { useApolloClient } from "@apollo/client";
import MobileContext from "../../../../../../../Widgets/Mobile/MobileContext";
function isNotNull(value) {
  return value !== null;
}
const useCurrentChatUserIdState = createPersistedState(
  "friendlist-currentChatUserId"
);
const useOnlyOnlineState = createPersistedState("friendlist-onlyOnline");
const ON_MESSAGE = gql(`
  subscription OnNewPrivateMessage {
    newPrivateMessage {
      message {
        id
        time
        body
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
          isSubscribed
        }
      }
      receivingUser {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
      }
      seen
    }
  }
`);
const ON_MESSAGE_FROM_USER = gql(`
  subscription NewPrivateMessageSubscription($userId: String!) {
    newPrivateMessageFromUser(userId: $userId) {
      seen
      receivingUser {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
      }
      message {
        id
        time
        body
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
          isSubscribed
        }
      }
    }
  }
`);
const ON_PRIVATE_MESSAGE_DELETED = gql(`
  subscription PrivateMessageDeletedSubsciption {
    privateMessageDeleted {
      previousMessage {
        latestMessage {
          id
          time
          body
        }
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
      deletedMessageId
    }
  }
`);
const ADD_FRIEND_MUTATION = gql(`
  mutation AddFriendMutation1($username: String!) {
    addFriend(username: $username) {
      id
      friends {
        state
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const REMOVE_FRIEND_MUTATION = gql(`
  mutation RemoveFriendMutation2($userId: String!) {
    removeFriend(userId: $userId) {
      id
      friends {
        state
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const MESSAGE_LIST = gql(`
  query MessageList {
    messageList {
      id
      unreadCount
      latestMessage {
        id
        time
        body
      }
      user {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
        isSubscribed
      }
    }
  }
`);
const ON_MESSAGE_LIST_UPDATE = gql(`
  subscription MessageListUpdate {
    messageListUpdate {
      id
      unreadCount
      latestMessage {
        id
        time
        body
      }
      user {
        id
        displayName
        username
        avatarUrl
        email
        isConnected
      }
    }
  }
`);
const PRIVATE_MESSAGES = gql(`
  query PrivateMessageQuery($userId: String!, $cursor: String) {
    privateMessages(userId: $userId, cursor: $cursor) {
      forwardCursor
      backCursor
      results {
        id
        body
        time
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
          isSubscribed
        }
      }
    }
  }
`);
const SEND_MESSAGE = gql(`
  mutation SendPrivateMessage($body: String!, $userId: String!) {
    sendPrivateMessage(body: $body, toUserId: $userId) {
      ok
    }
  }
`);
const DELETE_PRIVATE_MESSAGE = gql(`
  mutation DetelePrivateMessage($privateMessageId: UUID!) {
    deletePrivateMessage(privateMessageId: $privateMessageId) {
      ok
    }
  }
`);
const usePrivateMessages = (userId) => {
  var _a, _b;
  const { data, fetchMore, subscribeToMore, networkStatus } = useQuery(
    PRIVATE_MESSAGES,
    {
      variables: {
        userId
      },
      fetchPolicy: "cache-and-network",
      skip: !userId,
      notifyOnNetworkStatusChange: true
    }
  );
  const privateMessages = ((_a = data == null ? void 0 : data.privateMessages) == null ? void 0 : _a.results) || [];
  const forwardCursor = (_b = data == null ? void 0 : data.privateMessages) == null ? void 0 : _b.forwardCursor;
  useEffect(() => {
    if (!userId)
      return;
    const unsubscribeToMessages = subscribeToMore({
      document: ON_MESSAGE_FROM_USER,
      variables: { userId },
      updateQuery: (prev, { subscriptionData: { data: subData } }) => {
        var _a2, _b2, _c, _d, _e;
        const newMessage = (_a2 = subData == null ? void 0 : subData.newPrivateMessageFromUser) == null ? void 0 : _a2.message;
        const sender = newMessage == null ? void 0 : newMessage.user;
        const receiver = (_b2 = subData == null ? void 0 : subData.newPrivateMessageFromUser) == null ? void 0 : _b2.receivingUser;
        if (userId === (sender == null ? void 0 : sender.id) || userId === (receiver == null ? void 0 : receiver.id)) {
          return __spreadProps(__spreadValues({}, prev), {
            privateMessages: __spreadProps(__spreadValues({}, prev.privateMessages), {
              results: newMessage ? (_d = (_c = prev.privateMessages) == null ? void 0 : _c.results) == null ? void 0 : _d.concat([newMessage]) : (_e = prev.privateMessages) == null ? void 0 : _e.results
            })
          });
        }
        return prev;
      }
    });
    const unsubscribeToMessageDeletion = subscribeToMore({
      document: ON_PRIVATE_MESSAGE_DELETED,
      updateQuery: (prev, { subscriptionData: { data: subData } }) => {
        var _a2, _b2, _c;
        const messageId = (_a2 = subData == null ? void 0 : subData.privateMessageDeleted) == null ? void 0 : _a2.deletedMessageId;
        const updated = (_c = (_b2 = prev == null ? void 0 : prev.privateMessages) == null ? void 0 : _b2.results) == null ? void 0 : _c.filter(
          (message) => message.id != messageId
        );
        const updatedState = __spreadProps(__spreadValues({}, prev), {
          privateMessages: __spreadProps(__spreadValues({}, prev.privateMessages), {
            results: updated
          })
        });
        return updatedState;
      }
    });
    return () => {
      unsubscribeToMessages();
      unsubscribeToMessageDeletion();
    };
  }, [subscribeToMore, userId]);
  const nextPage = () => __async(void 0, null, function* () {
    if (!forwardCursor)
      return;
    yield fetchMore({
      variables: {
        userId,
        cursor: forwardCursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        var _a2, _b2, _c, _d, _e;
        if (!fetchMoreResult)
          return prev;
        return __spreadProps(__spreadValues({}, prev), {
          privateMessages: __spreadProps(__spreadValues({}, prev.privateMessages), {
            results: (_c = (_a2 = fetchMoreResult == null ? void 0 : fetchMoreResult.privateMessages) == null ? void 0 : _a2.results) == null ? void 0 : _c.concat(
              ((_b2 = prev == null ? void 0 : prev.privateMessages) == null ? void 0 : _b2.results) || []
            ),
            forwardCursor: (_d = fetchMoreResult == null ? void 0 : fetchMoreResult.privateMessages) == null ? void 0 : _d.forwardCursor,
            backCursor: (_e = fetchMoreResult == null ? void 0 : fetchMoreResult.privateMessages) == null ? void 0 : _e.forwardCursor
          })
        });
      }
    });
  });
  return {
    privateMessages,
    nextPage,
    loading: !data || networkStatus === 3
  };
};
const fixMessages = (messages) => (messages == null ? void 0 : messages.map((m) => ({
  id: (m == null ? void 0 : m.id) || "",
  user: (m == null ? void 0 : m.user) || {
    id: "",
    displayName: "",
    avatarUrl: "",
    isSubscribed: false
  },
  body: (m == null ? void 0 : m.body) || "",
  time: (m == null ? void 0 : m.time) || 0
}))) || [];
const MessagesWithData = memo(
  ({
    loading,
    messages,
    nextPage,
    deleteMessage
  }) => {
    return /* @__PURE__ */ React.createElement(
      Messages,
      {
        loading,
        messages: fixMessages(messages),
        nextPage,
        deleteMessage: (privateMessageId) => __async(void 0, null, function* () {
          yield deleteMessage(privateMessageId);
        })
      }
    );
  }
);
const PrivateChat = withDialogs(
  memo(
    ({
      styles,
      onClose,
      user,
      viewProfile,
      alert
    }) => {
      var _a;
      const currentUser = currentUserHook();
      const client = useApolloClient();
      const friend = (_a = currentUser == null ? void 0 : currentUser.friends) == null ? void 0 : _a.find(
        (f) => f.user.id === (user == null ? void 0 : user.id)
      );
      const friendAccepted = (friend == null ? void 0 : friend.state) === "ACCEPTED";
      const { privateMessages, nextPage, loading } = usePrivateMessages(
        user == null ? void 0 : user.id
      );
      const [sendMessage] = useMutation(SEND_MESSAGE);
      const [deleteMessage] = useMutation(DELETE_PRIVATE_MESSAGE);
      const [addFriend, { loading: addFriendLoading }] = useMutation(ADD_FRIEND_MUTATION);
      const [removeFriend, { loading: removeFriendLoading }] = useMutation(
        REMOVE_FRIEND_MUTATION
      );
      const { contextMenuRef } = useContext(MessagesDropdownContext);
      useEffect(() => {
        var _a2;
        const data = client.readQuery({ query: MESSAGE_LIST });
        if (data == null ? void 0 : data.messageList) {
          client.writeQuery({
            query: MESSAGE_LIST,
            data: {
              messageList: (_a2 = data == null ? void 0 : data.messageList) == null ? void 0 : _a2.map((m) => {
                var _a3;
                if (((_a3 = m == null ? void 0 : m.user) == null ? void 0 : _a3.id) === (user == null ? void 0 : user.id))
                  return __spreadProps(__spreadValues({}, m), { unreadCount: 0 });
                return m;
              })
            }
          });
        }
      }, [client, user == null ? void 0 : user.id]);
      return /* @__PURE__ */ React.createElement("div", { className: styles.privateChat }, /* @__PURE__ */ React.createElement("div", { className: styles.titlebar }, /* @__PURE__ */ React.createElement(Icon, { onClick: () => onClose(), name: "angle left", size: "big" }), /* @__PURE__ */ React.createElement(
        "div",
        {
          className: styles.userBox,
          onClick: () => viewProfile(user == null ? void 0 : user.id)
        },
        /* @__PURE__ */ React.createElement(
          Icon,
          {
            name: "circle",
            color: "green",
            style: {
              position: "relative",
              cursor: "default",
              fontSize: "9px",
              visibility: !(user == null ? void 0 : user.isConnected) ? "hidden" : void 0
            }
          }
        ),
        /* @__PURE__ */ React.createElement(
          Image,
          {
            src: user == null ? void 0 : user.avatarUrl,
            size: "mini",
            circular: true,
            className: styles.avatar,
            loading: "lazy"
          }
        ),
        /* @__PURE__ */ React.createElement("div", null, user == null ? void 0 : user.displayName)
      )), /* @__PURE__ */ React.createElement(
        MessagesWithData,
        {
          key: user == null ? void 0 : user.id,
          loading,
          messages: fixMessages(privateMessages.filter(isNotNull)),
          nextPage,
          deleteMessage: (privateMessageId) => __async(void 0, null, function* () {
            yield deleteMessage({ variables: { privateMessageId } });
          })
        }
      ), friendAccepted ? /* @__PURE__ */ React.createElement("div", { className: styles.messageBox }, /* @__PURE__ */ React.createElement(
        Messagebox,
        {
          isPrivate: true,
          sendMessage: (body) => sendMessage({
            variables: {
              body,
              userId: user == null ? void 0 : user.id
            }
          }),
          emojiPickerMountNode: (contextMenuRef == null ? void 0 : contextMenuRef.current) || void 0
        }
      )) : friend && /* @__PURE__ */ React.createElement("div", { className: styles.actions }, friend.state === "REQUESTED" && /* @__PURE__ */ React.createElement("div", null, "Friend requested"), friend.state === "PENDING" && /* @__PURE__ */ React.createElement("div", null, "Friend request pending"), friend.state === "PENDING" && /* @__PURE__ */ React.createElement(
        Button,
        {
          loading: addFriendLoading,
          inverted: true,
          size: "xs",
          color: "white",
          disabled: !(user == null ? void 0 : user.username),
          onClick: (e) => __async(void 0, null, function* () {
            e.stopPropagation();
            try {
              yield addFriend({
                variables: {
                  username: user == null ? void 0 : user.username
                }
              });
            } catch (e2) {
              alert(e2.message);
            }
          }),
          style: {
            borderRadius: 10,
            width: 25,
            height: 25,
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 4,
            marginRight: 4
          }
        },
        /* @__PURE__ */ React.createElement(Icon, { name: "check" })
      ), /* @__PURE__ */ React.createElement(
        Button,
        {
          loading: removeFriendLoading,
          inverted: true,
          size: "xs",
          color: "white",
          onClick: (e) => __async(void 0, null, function* () {
            e.stopPropagation();
            onClose();
            yield removeFriend({
              variables: { userId: user == null ? void 0 : user.id }
            });
          }),
          style: {
            borderRadius: 10,
            width: 25,
            height: 25,
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 4,
            marginRight: 4
          }
        },
        /* @__PURE__ */ React.createElement(Icon, { name: "remove" })
      )));
    }
  )
);
const MessagesDropdown = memo(
  ({
    viewProfile,
    messageList,
    currentChatUserId,
    setCurrentChatUserId,
    onlyOnline,
    loading,
    setChoosingFriend
  }) => {
    var _a;
    const styles = withStyleHook(style);
    const currentChatUser = (_a = messageList.find(
      (m) => m.id === currentChatUserId
    )) == null ? void 0 : _a.user;
    const filteredMessageList = useMemo(
      () => onlyOnline ? messageList.filter((m) => {
        var _a2;
        return (_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.isConnected;
      }) : messageList,
      [onlyOnline, JSON.stringify(messageList)]
    );
    if (loading)
      return /* @__PURE__ */ React.createElement(LoadingScreen, null);
    return /* @__PURE__ */ React.createElement(
      TabView,
      {
        menu: false,
        style: { width: "100%" },
        forceIndex: currentChatUser ? 1 : 0
      },
      /* @__PURE__ */ React.createElement(TabView.Item, { name: "MessageList" }, /* @__PURE__ */ React.createElement("div", { className: styles.messageList }, messageList.length > 0 && /* @__PURE__ */ React.createElement(
        MessageList,
        {
          items: (filteredMessageList == null ? void 0 : filteredMessageList.map((member, index) => {
            var _a2, _b;
            return {
              key: member.user.id,
              onClick: () => setCurrentChatUserId(member.user.id),
              title: member.user.displayName,
              content: ((_a2 = member.latestMessage) == null ? void 0 : _a2.body) || "",
              unread: member.unreadCount > 0,
              online: member.user.isConnected,
              avatarUrl: member.user.avatarUrl,
              timestamp: (_b = member.latestMessage) == null ? void 0 : _b.time,
              odd: index % 2 !== 0
            };
          })) || []
        }
      ), (messageList == null ? void 0 : messageList.length) === 0 && /* @__PURE__ */ React.createElement("div", { className: styles.noMessages }, "Add friends to use DMs!", /* @__PURE__ */ React.createElement(
        Button,
        {
          color: "blue",
          onClick: () => {
            setChoosingFriend(true);
          }
        },
        "Add a friend"
      )))),
      /* @__PURE__ */ React.createElement(TabView.Item, { name: "PrivateChat" }, /* @__PURE__ */ React.createElement(
        PrivateChat,
        {
          styles,
          user: currentChatUser,
          onClose: () => setCurrentChatUserId(null),
          viewProfile
        }
      ))
    );
  }
);
const PrivateMessageList = memo(
  ({
    messageList,
    notifyOnUserOnline,
    loading
  }) => {
    var _a;
    const [currentChatUserId, setCurrentChatUserId] = useCurrentChatUserIdState(
      null
    );
    const currentChatUser = (_a = messageList == null ? void 0 : messageList.find(
      (m) => m.id === currentChatUserId
    )) == null ? void 0 : _a.user;
    const styles = withStyleHook(style);
    const [onlyOnline, setOnlyOnline] = useOnlyOnlineState(false);
    const currentUser = currentUserHook();
    const [choosingFriend, setChoosingFriend] = useState(false);
    const [viewUserProfileId, setViewUserProfileId] = useState(null);
    const { isMobile } = useIsMobile();
    const { setHideBottomMenu, overlayMenu } = useContext(MobileContext);
    useEffect(() => {
      if (setHideBottomMenu && overlayMenu === "chats") {
        setHideBottomMenu(!!currentChatUser);
      }
    }, [currentChatUser, setHideBottomMenu, overlayMenu]);
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      UserPickerModal,
      {
        open: choosingFriend,
        onClose: () => {
          setChoosingFriend(false);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      UserProfileModal,
      {
        userId: viewUserProfileId,
        open: !!viewUserProfileId,
        onClose: () => {
          setViewUserProfileId(null);
        },
        currentUser
      }
    ), !currentChatUser && /* @__PURE__ */ React.createElement("div", { className: styles.friendsHeader }, /* @__PURE__ */ React.createElement("div", null, "Chats"), messageList && messageList.length > 0 && /* @__PURE__ */ React.createElement(
      Checkbox,
      {
        style: {
          zoom: 0.7,
          marginLeft: "auto",
          marginRight: "15px"
        },
        defaultChecked: onlyOnline,
        label: "Only online",
        toggle: true,
        onChange: (_e, data) => {
          if (data.checked !== onlyOnline) {
            setOnlyOnline(!!data.checked);
          }
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Popup,
      {
        content: "Add a friend",
        trigger: /* @__PURE__ */ React.createElement(
          Button,
          {
            color: "white",
            inverted: true,
            size: isMobile ? void 0 : "s",
            style: isMobile ? {
              borderWidth: 0
            } : {
              width: 50,
              height: 25,
              padding: 0,
              borderWidth: 0
            },
            onClick: () => {
              setChoosingFriend(true);
            }
          },
          /* @__PURE__ */ React.createElement(Icon, { name: "plus", style: { fontSize: 18 } })
        )
      }
    )), /* @__PURE__ */ React.createElement(
      MessagesDropdown,
      {
        loading,
        styles,
        notifyOnUserOnline,
        setCurrentChatUserId: (currentChatUserId2) => setCurrentChatUserId(currentChatUserId2),
        currentChatUserId,
        onlyOnline,
        messageList,
        loggedIn: Boolean(currentUser && !currentUser.isAnonymous),
        viewingProfile: !!viewUserProfileId,
        currentUser,
        setChoosingFriend,
        viewProfile: (userId) => {
          setViewUserProfileId(userId);
        }
      }
    ));
  }
);
const MessagesScreen = memo(
  ({
    refetchMessageList,
    messageList,
    loading
  }) => {
    const styles = withStyleHook(style);
    const currentUser = currentUserHook();
    useEffect(() => {
      if (refetchMessageList) {
        refetchMessageList();
      }
    }, []);
    return /* @__PURE__ */ React.createElement("div", { id: styles.messagesDropdown }, (currentUser == null ? void 0 : currentUser.isAnonymous) ? /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          margin: "auto",
          display: "flex"
        }
      },
      "Please Log in to add friends",
      /* @__PURE__ */ React.createElement(
        LoginModal,
        {
          trigger: ({ onClick }) => /* @__PURE__ */ React.createElement(
            Button,
            {
              onClick,
              inverted: true,
              size: "xs",
              color: "white",
              style: { marginTop: 10 }
            },
            "Click here to log in"
          )
        }
      )
    ) : /* @__PURE__ */ React.createElement(
      PrivateMessageList,
      {
        notifyOnUserOnline: true,
        messageList,
        loading
      }
    ));
  }
);
export const useMessages = () => {
  const { loading, data, subscribeToMore, refetch } = useQuery(MESSAGE_LIST, {
    fetchPolicy: "cache-first"
  });
  const currentUser = currentUserHook();
  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribeToMessageList = subscribeToMore({
        document: ON_MESSAGE_LIST_UPDATE,
        updateQuery: (prev, { subscriptionData: { data: data2 } }) => {
          const { messageListUpdate } = data2;
          return __spreadProps(__spreadValues({}, prev), {
            messageList: messageListUpdate
          });
        }
      });
      const unsubscribetoMessages = subscribeToMore({
        document: ON_MESSAGE,
        updateQuery: (prev, { subscriptionData: { data: data2 } }) => {
          var _a;
          const { newPrivateMessage } = data2;
          if (!newPrivateMessage)
            return prev;
          const { message, receivingUser, seen } = newPrivateMessage;
          const updated = __spreadProps(__spreadValues({}, prev), {
            messageList: (_a = prev == null ? void 0 : prev.messageList) == null ? void 0 : _a.map((item) => {
              var _a2, _b, _c;
              if (item.user.id === ((_a2 = message == null ? void 0 : message.user) == null ? void 0 : _a2.id) || currentUser.id === ((_b = message == null ? void 0 : message.user) == null ? void 0 : _b.id) && (receivingUser == null ? void 0 : receivingUser.id) === ((_c = item == null ? void 0 : item.user) == null ? void 0 : _c.id)) {
                return __spreadProps(__spreadValues({}, item), {
                  latestMessage: message,
                  unreadCount: seen ? 0 : item.unreadCount + 1
                });
              }
              return item;
            }).sort((a, b) => {
              const aTime = a.latestMessage ? a.latestMessage.time : 0;
              const bTime = b.latestMessage ? b.latestMessage.time : 0;
              return bTime - aTime;
            })
          });
          return updated;
        }
      });
      const unsubscribetoDeletedMessages = subscribeToMore({
        document: ON_PRIVATE_MESSAGE_DELETED,
        updateQuery: (prev, { subscriptionData: { data: subData } }) => {
          const messageId = subData.privateMessageDeleted.deletedMessageId;
          const previousMessage = subData.privateMessageDeleted.previousMessage.latestMessage;
          return __spreadProps(__spreadValues({}, prev), {
            messageList: prev.messageList.map((elem) => {
              var _a;
              if (((_a = elem == null ? void 0 : elem.latestMessage) == null ? void 0 : _a.id) == messageId)
                return __spreadProps(__spreadValues({}, elem), { latestMessage: previousMessage });
              return elem;
            })
          });
        }
      });
      return () => {
        unsubscribeToMessageList();
        unsubscribetoMessages();
        unsubscribetoDeletedMessages();
      };
    }
  }, [subscribeToMore]);
  const messageList = (data == null ? void 0 : data.messageList) || [];
  const unreadCount = messageList.reduce(
    (acc, item) => acc + item.unreadCount,
    0
  );
  return { loading, messageList, unreadCount, refetch };
};
const FriendsIcon = forwardRef(
  ({ unreadCount, onClick }, ref) => {
    const styles = withStyleHook(style);
    return /* @__PURE__ */ React.createElement(
      TopBarButton,
      {
        ref,
        className: styles.friendsIcon,
        onClick,
        inverted: true
      },
      unreadCount > 0 && /* @__PURE__ */ React.createElement("div", { className: styles.messageNumber }, unreadCount),
      /* @__PURE__ */ React.createElement(
        Icon,
        {
          name: "comment",
          className: unreadCount > 0 ? styles.glowingIcon : void 0
        }
      )
    );
  }
);
const MessagesDropdownContext = React.createContext({
  contextMenuRef: null
});
const MessagesDropdownWrapper = memo(() => {
  const {
    messageList,
    refetch: refetchMessageList,
    unreadCount
  } = useMessages();
  const styles = withStyleHook(style);
  const [showDropdown, setShowDropdown] = useState(false);
  const contextMenuRef = useRef(null);
  return /* @__PURE__ */ React.createElement(
    ContextMenu,
    {
      open: showDropdown,
      onClose: () => setShowDropdown(false),
      position: "left",
      ref: contextMenuRef,
      trigger: /* @__PURE__ */ React.createElement(
        FriendsIcon,
        {
          onClick: () => {
            setShowDropdown(!showDropdown);
          },
          unreadCount
        }
      )
    },
    /* @__PURE__ */ React.createElement("div", { className: styles.messagesDropdownMenu }, /* @__PURE__ */ React.createElement(MessagesDropdownContext.Provider, { value: { contextMenuRef } }, /* @__PURE__ */ React.createElement(
      MessagesScreen,
      {
        refetchMessageList,
        messageList
      }
    )))
  );
});
export default MessagesDropdownWrapper;
export { PrivateMessageList, MessagesScreen, FriendsIcon };
