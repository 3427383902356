"use strict";
import React from "react";
import Modal from "../AnimatedModal";
import { AppSettings } from "../Mobile/Settings";
export default ({ trigger }) => {
  return /* @__PURE__ */ React.createElement(Modal, { large: true, closeIcon: true, trigger }, /* @__PURE__ */ React.createElement(Modal.Header, { style: { display: "flex" } }, "App Settings"), /* @__PURE__ */ React.createElement(
    Modal.Content,
    {
      style: {
        display: "flex",
        flexDirection: "column"
      }
    },
    /* @__PURE__ */ React.createElement(AppSettings, { dark: true })
  ));
};
