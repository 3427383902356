"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.JoinRoomForm = exports.CreateRoomForm = void 0;
var _gql = require("kosmi-sdk/gql");
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var _reactRouterDom = require("react-router-dom");
var _AppContext = _interopRequireDefault(require("./../../AppContext"));
var _Widgets = require("./../Widgets");
var _LoginModal = require("./../Widgets/LoginModal");
var _Premium = require("./Premium");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _helpers = require("kosmi-sdk/helpers");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const CREATE_ROOM = (0, _gql.gql)(`
  mutation CreateRoomMutation2(
    $appName: RoomApp
    $description: String
    $roomName: String
    $public: Boolean!
    $autoAcceptJoins: Boolean
    $promoteNewUsersToAdmin: Boolean
    $colorScheme: String
    $roomId: String
  ) {
    createRoom(
      appName: $appName
      roomName: $roomName
      description: $description
      public: $public
      colorScheme: $colorScheme
      autoAcceptJoins: $autoAcceptJoins
      promoteNewUsersToAdmin: $promoteNewUsersToAdmin
      roomId: $roomId
    ) {
      id
    }
  }
`);
const glowing = "xaqy3us-B";
const styles = {
  modalHeader: {
    "CreateRoomModal__styles.modalHeader": "CreateRoomModal__styles.modalHeader",
    display: "x78zum5",
    $$css: true
  },
  roomButtonText: {
    "CreateRoomModal__styles.roomButtonText": "CreateRoomModal__styles.roomButtonText",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  buttonWrapper: {
    "CreateRoomModal__styles.buttonWrapper": "CreateRoomModal__styles.buttonWrapper",
    height: "x1vd4hg5",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  loginDisclaimer: {
    "CreateRoomModal__styles.loginDisclaimer": "CreateRoomModal__styles.loginDisclaimer",
    textAlign: "x2b8uid",
    fontSize: "x1j61zf2",
    $$css: true
  },
  em: {
    "CreateRoomModal__styles.em": "CreateRoomModal__styles.em",
    fontWeight: "x1uu0p8",
    color: "x16cd2qt",
    display: "xt0psk2",
    $$css: true
  }
};
const CreateRoomForm = exports.CreateRoomForm = (0, _helpers.withDialogs)(({
  type,
  modal,
  alert,
  onRoomJoined,
  confirm
}) => {
  var _a;
  const [description, setDescription] = (0, _react.useState)("");
  const [roomName, setRoomName] = (0, _react.useState)("");
  const [customRoomId, setCustomRoomId] = (0, _react.useState)("");
  const [useCustomRoomId, setUseCustomRoomId] = (0, _react.useState)(false);
  const [promoteNewUsersToAdmin, setPromoteNewUsersToAdmin] = (0, _react.useState)(type !== "public");
  const history = (0, _reactRouterDom.useHistory)();
  const currentUser = (0, _helpers.currentUserHook)();
  const appContext = (0, _react.useContext)(_AppContext.default);
  const [createRoom, {
    loading
  }] = (0, _gql.useMutation)(CREATE_ROOM);
  const requireLogin = (_a = window == null ? void 0 : window.featureFlags) == null ? void 0 : _a.requireLoginToCreateRoom;
  const isSubscribed = !!(currentUser == null ? void 0 : currentUser.isSubscribed);
  if ((currentUser == null ? void 0 : currentUser.isAnonymous) && requireLogin) {
    return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.loginDisclaimer
    }, "Creating a room requires an account. But don't worry,", " ", /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.em
    }, "it's free"), ", and your friends can join your room without signing up."), /* @__PURE__ */_react.default.createElement(_LoginModal.LoginModalInner, null));
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Form, {
    onSubmit: e => __async(void 0, null, function* () {
      var _a2, _b, _c;
      e.preventDefault();
      if (!((_a2 = e == null ? void 0 : e.currentTarget) == null ? void 0 : _a2.checkValidity())) return;
      if (type === "public" && confirm && !(yield confirm("Please be aware that any explicit content(18+) is not allowed in public rooms. A violation of this rule may result in a permanent ban."))) {
        return;
      }
      try {
        const result = yield createRoom({
          variables: {
            roomName,
            description,
            public: type === "public",
            promoteNewUsersToAdmin,
            roomId: useCustomRoomId ? customRoomId : void 0
          }
        });
        setRoomName("");
        setDescription("");
        setPromoteNewUsersToAdmin(false);
        setUseCustomRoomId(false);
        setCustomRoomId("");
        modal == null ? void 0 : modal.handleClose();
        if (onRoomJoined) {
          onRoomJoined();
        }
        const roomId = (_c = (_b = result == null ? void 0 : result.data) == null ? void 0 : _b.createRoom) == null ? void 0 : _c.id;
        if (roomId) {
          appContext.setTutorialRoomId(roomId);
          history.push("/room/" + roomId);
        }
      } catch (e2) {
        const errorMessage = (e2 == null ? void 0 : e2.message) || e2.toString();
        alert && alert(errorMessage);
      }
    })
  }, /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, null, /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    label: "Room name",
    autoFocus: true,
    required: true,
    placeholder: "Room name",
    value: roomName,
    onChange: e => {
      setRoomName(e.target.value);
    }
  })), useCustomRoomId && /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, null, /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    label: "Custom room URL",
    placeholder: "roomId",
    prefix: "https://app.kosmi.io/room/",
    minLength: 6,
    maxLength: 20,
    required: true,
    value: customRoomId,
    onChange: e => {
      setCustomRoomId(e.target.value);
    }
  })), type === "public" && /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, null, /* @__PURE__ */_react.default.createElement(_uikit.Form.Label, null, "Room Description (optional)"), /* @__PURE__ */_react.default.createElement(_uikit.Input, {
    value: description,
    onChange: e => {
      setDescription(e.target.value);
    }
  })), type !== "public" && /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, null, /* @__PURE__ */_react.default.createElement(_uikit.Checkbox, {
    label: "Use custom room URL",
    toggle: true,
    checked: useCustomRoomId,
    onChange: (_e, data) => {
      if (!isSubscribed) {
        alert && alert( /* @__PURE__ */_react.default.createElement(_Premium.PaywallMessage, {
          message: "Please get Premium to use custom room URLs!",
          source: "Custom Room ID"
        }), true);
        return;
      }
      setUseCustomRoomId(data.checked);
      setCustomRoomId("");
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Checkbox, {
    label: "Give new members admin permissions by default",
    toggle: true,
    checked: promoteNewUsersToAdmin,
    onChange: (_e, data) => {
      setPromoteNewUsersToAdmin(data.checked);
    }
  })), /* @__PURE__ */_react.default.createElement(_uikit.Form.Button, {
    loading,
    color: "yellow",
    style: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: "auto",
      marginRight: "auto",
      width: 150
    },
    type: "submit"
  }, "Create"));
});
const JoinRoomForm = ({
  modal,
  alert,
  onRoomJoined
}) => {
  const [value, setValue] = (0, _react.useState)("");
  const history = (0, _reactRouterDom.useHistory)();
  return /* @__PURE__ */_react.default.createElement(_uikit.Form, null, /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    placeholder: "Room URL...",
    onChange: e => setValue(e.target.value)
  }), /* @__PURE__ */_react.default.createElement(_uikit.Form.Button, {
    color: "yellow",
    fluid: true,
    style: {
      marginTop: 20,
      marginBottom: 0,
      marginLeft: "auto",
      marginRight: "auto"
    },
    onClick: () => {
      const roomId = value.split("https://app.kosmi.io/room/")[1];
      if (!roomId && alert) {
        alert("Invalid Room URL");
        return;
      }
      if (onRoomJoined) {
        onRoomJoined();
      }
      history.push("/room/" + roomId);
      modal.handleClose();
    }
  }, "Join Room"));
};
exports.JoinRoomForm = JoinRoomForm;
const CreateRoomModal = (0, _react.memo)((0, _react.forwardRef)(({
  type,
  onRoomJoined,
  onExplicitClose,
  onOpen,
  onClose,
  open,
  trigger
}, ref) => {
  const [form, setForm] = (0, _react.useState)(null);
  const [modal, setModal] = (0, _react.useState)(null);
  const inPwa = ["fullscreen", "standalone", "minimal-ui"].some(displaymode => window.matchMedia("(display-mode: " + displaymode + ")").matches) || location.href.startsWith("capacitor") || location.hostname === "localhost" || "standalone" in window.navigator && window.navigator.standalone;
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    preventClosing: true,
    open,
    trigger: trigger && _react.default.cloneElement(trigger, {
      ref
    }),
    onOpen,
    onClose: () => {
      if (onExplicitClose) onExplicitClose();
      if (onClose) onClose();
    },
    onModal: modal2 => {
      setModal(modal2);
    }
  }, !inPwa && /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, {
    style: styles.modalHeader
  }, "Create a ", type === "public" ? "Public " : "", "Room"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, inPwa ? /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, !form && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.buttonWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    inverted: true,
    icon: true,
    size: "l",
    style: {
      margin: "auto",
      marginBottom: 10,
      width: 300,
      height: 50
    },
    onClick: () => {
      setForm("createRoom");
    },
    color: "white"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "plus square",
    size: "large"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomButtonText
  }, "Create a new Room")), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    inverted: true,
    style: {
      margin: "auto",
      marginTop: 10,
      width: 300,
      height: 50
    },
    icon: true,
    size: "l",
    onClick: () => {
      setForm("joinRoom");
    },
    color: "white"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "sign-in",
    size: "large"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomButtonText
  }, "Join an existing Room"))), form && /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "white",
    style: {
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 0,
      marginRight: 0,
      maxWidth: 50
    },
    inverted: true,
    icon: true,
    onClick: () => setForm(null)
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "chevron left"
  })), form === "createRoom" && /* @__PURE__ */_react.default.createElement(_Widgets.FadeInTransition, null, /* @__PURE__ */_react.default.createElement(CreateRoomForm, {
    onRoomJoined,
    modal,
    type
  })), form === "joinRoom" && /* @__PURE__ */_react.default.createElement(_Widgets.FadeInTransition, null, /* @__PURE__ */_react.default.createElement(JoinRoomForm, {
    onRoomJoined,
    modal
  }))) : /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(CreateRoomForm, {
    type,
    onRoomJoined,
    modal
  }))));
}));
var _default = exports.default = (0, _helpers.withDialogs)(CreateRoomModal);