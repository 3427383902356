"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { memo, useState } from "react";
import { withStyleHook } from "kosmi-sdk/styles";
import style from "./style.css";
import LocalFileModal from "./components/LocalFileModal";
import ScreenshareModal from "./components/ScreenshareModal";
import VideoURLModal from "./components/VideoURLModal";
import Discover from "./components/Discover";
import CustomScreenshare from "./components/Discover/components/CustomScreenshare";
import BackButton from "./components/BackButton";
const VideoChoiceScreen = (_a) => {
  var _b = _a, {
    screen,
    goBack,
    onClose
  } = _b, props = __objRest(_b, [
    "screen",
    "goBack",
    "onClose"
  ]);
  const styles = withStyleHook(style);
  if (screen === "pick" || screen === "customScreenshare")
    return null;
  return /* @__PURE__ */ React.createElement("div", { className: styles.videoChoiceScreen }, /* @__PURE__ */ React.createElement(BackButton, { onClick: goBack }), (() => {
    if (screen === "screenshare")
      return /* @__PURE__ */ React.createElement(
        ScreenshareModal,
        __spreadProps(__spreadValues({}, props), {
          onClose: () => {
            goBack();
            onClose();
          }
        })
      );
    if (screen === "url")
      return /* @__PURE__ */ React.createElement(
        VideoURLModal,
        __spreadProps(__spreadValues({}, props), {
          onClose: () => {
            goBack();
            onClose();
          },
          styles
        })
      );
    if (screen === "file")
      return /* @__PURE__ */ React.createElement(
        LocalFileModal,
        __spreadProps(__spreadValues({}, props), {
          onClose: () => {
            goBack();
            onClose();
          }
        })
      );
  })());
};
export default memo(
  ({
    onClose,
    setModalTitle
  }) => {
    const [_screen, setScreen] = useState("pick");
    const initializeScreen = () => setScreen("pick");
    const [customScreenshare, setCustomScreenshare] = useState(
      null
    );
    const screen = customScreenshare ? "customScreenshare" : _screen;
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      VideoChoiceScreen,
      {
        screen,
        goBack: initializeScreen,
        onClose
      }
    ), customScreenshare && /* @__PURE__ */ React.createElement(
      CustomScreenshare,
      {
        title: customScreenshare == null ? void 0 : customScreenshare.title,
        url: customScreenshare == null ? void 0 : customScreenshare.url,
        logo: customScreenshare == null ? void 0 : customScreenshare.logo,
        goBack: () => {
          setCustomScreenshare(null);
          setModalTitle("Select Media");
        },
        onClose
      }
    ), /* @__PURE__ */ React.createElement(
      Discover,
      {
        closeModal: onClose,
        screen,
        setScreen,
        setModalTitle,
        openCustomScreenshare: setCustomScreenshare
      }
    ));
  }
);
