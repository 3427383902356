"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { memo } from "react";
import { currentUserHook } from "../helpers/withCurrentUser";
import { useRoomStateCached } from "./hooks/useRoomData";
import { useRoomId } from "./withRoomId";
export const useCurrentMember = () => {
  var _a;
  const roomId = useRoomId();
  const room = useRoomStateCached(roomId);
  const currentUser = currentUserHook();
  const currentUserId = currentUser == null ? void 0 : currentUser.id;
  const members = ((_a = room == null ? void 0 : room.state) == null ? void 0 : _a.members) || [];
  const currentMember = members == null ? void 0 : members.find((m) => {
    var _a2;
    return ((_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.id) === currentUserId;
  });
  return __spreadProps(__spreadValues({}, currentMember), { user: currentUser });
};
export default (WrappedComponent) => memo((props) => {
  const currentMember = useCurrentMember();
  const roomId = useRoomId();
  const currentUser = currentUserHook();
  return /* @__PURE__ */ React.createElement(
    WrappedComponent,
    __spreadProps(__spreadValues({}, props), {
      currentMember,
      currentUser,
      roomId
    })
  );
});
