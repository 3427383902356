"use strict";
import { Icon } from "kosmi-sdk/uikit";
import React from "react";
import { withStyleHook } from "../../../sdk/styles";
import { useTracking } from "../../helpers/withTracking";
import Modal from "../AnimatedModal";
import style from "./style.css";
export const AVPermissionsWarningModal = ({
  onClose,
  open
}) => {
  const track = useTracking();
  const styles = withStyleHook(style);
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      size: "small",
      closeIcon: true,
      open,
      onOpen: () => track("UI", "AVPermissionsModalOpened", "AV permission modal opened"),
      onClose: () => {
        onClose();
      }
    },
    /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement("div", { className: styles.permissionsWarning }, /* @__PURE__ */ React.createElement(Icon, { size: "huge", name: "warning", style: { color: "#fff377" } }), /* @__PURE__ */ React.createElement("h2", null, "Camera/microphone blocked!"), /* @__PURE__ */ React.createElement("div", null, "Kosmi needs access to your ", /* @__PURE__ */ React.createElement("b", null, "camera"), " and ", /* @__PURE__ */ React.createElement("b", null, "micophone"), " so that other participants can see and hear you.")))
  );
};
export default AVPermissionsWarningModal;
