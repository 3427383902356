"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _webrtc = require("kosmi-sdk/webrtc");
var _ReactFitText = _interopRequireDefault(require("react-fittext/lib/ReactFitText"));
var _react = _interopRequireWildcard(require("react"));
var _hooks = require("../hooks");
var _sofa = require("../sofa.svg");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const getClassName = (...styleObj) => {
  const {
    className
  } = stylex.props(...styleObj);
  return className;
};
const styles = {
  seat: {
    "Sofa__styles.seat": "Sofa__styles.seat",
    minWidth: "xb1udk4",
    minHeight: "x1ay95g3",
    maxWidth: "xewcech",
    maxHeight: "x1fkrrjw",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "xoyqsm5",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    display: "x78zum5",
    zIndex: "x1vjfegm",
    padding: "x27d7eh",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    transition: "xgsnffn",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    position: "x1n2onr6",
    $$css: true
  },
  seatInner: {
    "Sofa__styles.seatInner": "Sofa__styles.seatInner",
    minHeight: "x1us19tq",
    minWidth: "xgqtt45",
    maxHeight: "xmz0i5r",
    maxWidth: "x193iq5w",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    backgroundSize: "x18d0r48",
    backgroundRepeat: "xui5s3p",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    position: "x1n2onr6",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    borderWidth: "x1a87jhh",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xnu7srt",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  seatVideo: {
    "Sofa__styles.seatVideo": "Sofa__styles.seatVideo",
    objectFit: "xl1xv1r",
    height: "x5yr21d",
    width: "xh8yej3",
    $$css: true
  },
  displayName: {
    "Sofa__styles.displayName": "Sofa__styles.displayName",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    height: "xu1mrb",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    zIndex: "xhtitgo",
    objectFit: "x19kjcj4",
    display: "x78zum5",
    textAlign: "x2b8uid",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    fontSize: "x1l97dnv",
    textShadow: "x16e0nh6",
    bottom: "x1ey2m1c",
    width: "xh8yej3",
    minHeight: "x7ywyr2",
    maxHeight: "x20ht4g",
    $$css: true
  },
  sofa: {
    "Sofa__styles.sofa": "Sofa__styles.sofa",
    display: "x78zum5",
    position: "x10l6tqk",
    zIndex: "x1vjfegm",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    top: "xgnilib",
    width: "x1sgfpsl",
    height: "xt7dq6l",
    aspectRatio: "x1jbcnsl",
    backgroundSize: "x1h4uluw",
    backgroundRepeat: "xiy17q3",
    justifyContent: "xl56j7k",
    $$css: true
  },
  sofaInner: {
    "Sofa__styles.sofaInner": "Sofa__styles.sofaInner",
    width: "xh8yej3",
    height: "x5yr21d",
    overflowX: "xw2csxc",
    display: "x78zum5",
    position: "x1n2onr6",
    $$css: true
  },
  sofaSvg: {
    "Sofa__styles.sofaSvg": "Sofa__styles.sofaSvg",
    position: "x10l6tqk",
    $$css: true
  }
};
const Member = (0, _react.memo)(({
  userId,
  avatarUrl,
  styles: styles2,
  user
}) => /* @__PURE__ */_react.default.createElement("div", {
  className: getClassName(styles2.seat)
}, /* @__PURE__ */_react.default.createElement("div", {
  className: getClassName(styles2.seatInner),
  style: {
    backgroundImage: "url(" + avatarUrl + ")"
  }
}, /* @__PURE__ */_react.default.createElement(_webrtc.WebRTCUserVideoTrack, {
  userId,
  className: getClassName(styles2.seatVideo)
})), /* @__PURE__ */_react.default.createElement(_ReactFitText.default, {
  compressor: 0.9
}, /* @__PURE__ */_react.default.createElement("div", {
  className: getClassName(styles2.displayName)
}, (user == null ? void 0 : user.isAnonymous) ? "Anonymous" : (user == null ? void 0 : user.displayName) || (user == null ? void 0 : user.username)))));
var _default = exports.default = (0, _react.memo)(({
  backgroundImage
}) => {
  const members = (0, _hooks.useMembers)();
  return /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.sofa),
    style: {
      backgroundImage: backgroundImage ? "url(" + backgroundImage + ")" : void 0
    }
  }, !backgroundImage && /* @__PURE__ */_react.default.createElement(_sofa.ReactComponent, {
    className: getClassName(styles.sofaSvg)
  }), /* @__PURE__ */_react.default.createElement("div", {
    className: getClassName(styles.sofaInner)
  }, members.map((user, i) => /* @__PURE__ */_react.default.createElement(Member, {
    key: i,
    user,
    userId: user.id,
    avatarUrl: user.avatarUrl,
    styles
  }))));
});