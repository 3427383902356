"use strict";
import { withStyleHook } from "kosmi-sdk/styles";
import React from "react";
import style from "./style.css";
const Tag = ({
  tag,
  selected,
  onSelect
}) => {
  const styles = withStyleHook(style);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: (selected ? [styles.tag, styles.selected] : [styles.tag]).join(
        " "
      ),
      key: tag.name,
      onClick: () => onSelect(tag.name || "")
    },
    tag.description
  );
};
export default ({
  tags,
  selected,
  onSelect
}) => {
  const styles = withStyleHook(style);
  return /* @__PURE__ */ React.createElement("div", { className: styles.tags }, /* @__PURE__ */ React.createElement(
    Tag,
    {
      tag: { name: "all", description: "All" },
      selected: !selected.length,
      onSelect: () => onSelect(null)
    }
  ), tags.map((t) => /* @__PURE__ */ React.createElement(
    Tag,
    {
      key: t.name,
      tag: t,
      selected: selected.includes(t.name || ""),
      onSelect
    }
  )));
};
