"use strict";
import React, { memo } from "react";
import { Button } from "kosmi-sdk/uikit";
import { withStyleHook } from "kosmi-sdk/styles";
import style from "./style.css";
export default memo(
  ({ volume, setVolume, muted, mute, unmute }) => {
    if (volume === null || volume === void 0)
      return null;
    const styles = withStyleHook(style);
    return /* @__PURE__ */ React.createElement("div", { className: styles.volume }, mute && unmute && /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "blue",
        onClick: () => {
          return muted ? unmute() : mute();
        },
        style: { marginRight: 5, marginLeft: 5, width: 36 },
        icon: muted ? "volume off" : "volume up"
      }
    ), /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "range",
        onChange: (e) => {
          setVolume(parseFloat(e.currentTarget.value) / 100);
        },
        min: "0",
        max: "100",
        value: muted ? 0 : volume * 100
      }
    ));
  }
);
