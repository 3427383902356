"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _usePlatform = _interopRequireDefault(require("../../../../../../../../../hooks/usePlatform"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  videoChoices: {
    "index__styles.videoChoices": "index__styles.videoChoices",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    justifyContent: "xl56j7k",
    $$css: true
  },
  icon: {
    "index__styles.icon": "index__styles.icon",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "x1anpbxc",
    marginBottom: "xyorhqc",
    "@media(max-width: 999px)_margin": "x56dphp",
    "@media(max-width: 999px)_marginInline": null,
    "@media(max-width: 999px)_marginInlineStart": null,
    "@media(max-width: 999px)_marginLeft": "x1c85f7r",
    "@media(max-width: 999px)_marginInlineEnd": null,
    "@media(max-width: 999px)_marginRight": null,
    "@media(max-width: 999px)_marginBlock": null,
    "@media(max-width: 999px)_marginTop": null,
    "@media(max-width: 999px)_marginBottom": null,
    $$css: true
  },
  choice: {
    "index__styles.choice": "index__styles.choice",
    alignItems: "x6s0dn4",
    borderRadius: "xn5hx6u",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    fontSize: "x1j61zf2",
    height: "xng8ra",
    justifyContent: "xl56j7k",
    margin: "x16zck5j",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "x1sy10c2",
    marginBottom: "xieb3on",
    padding: "x1v6jie7",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    whiteSpace: "xuxw1ft",
    width: "x1247r65",
    "@media(max-width: 999px)_display": "x1klvcs",
    "@media(max-width: 999px)_marginLeft": "xhhnchk",
    "@media(max-width: 999px)_marginInlineStart": null,
    "@media(max-width: 999px)_marginInlineEnd": null,
    "@media(max-width: 999px)_marginRight": "xykue44",
    "@media(max-width: 999px)_marginBottom": "x1wjfj57",
    "@media(max-width: 999px)_textAlign": "xa3vh2d",
    ":hover_backgroundColor": "x1sla2a1",
    ":hover_color": "x1frr76i",
    $$css: true
  },
  bold: {
    "index__styles.bold": "index__styles.bold",
    fontWeight: "x1uu0p8",
    $$css: true
  }
};
const ChoiceButton = (0, _react.forwardRef)((props, ref) => /* @__PURE__ */_react.default.createElement(_uikit.Button, __spreadProps(__spreadValues({}, props), {
  inverted: true,
  color: "white",
  ref,
  className: styles.choice
})));
var _default = ({
  screen,
  onChange
}) => {
  const [div, setDiv] = (0, _react.useState)(null);
  const {
    isMobile,
    canCaptureVideoElement
  } = (0, _usePlatform.default)();
  const divRef = (0, _react.useCallback)(node => {
    setDiv(node);
  }, [screen]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.videoChoices,
    ref: divRef
  }, !isMobile && div && /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    position: "bottom center",
    content: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.bold
    }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Screenshare"), "Great for Netflix and other streaming services or simply for sharing your screen or browser tab!"),
    mountNode: div,
    trigger: /* @__PURE__ */_react.default.createElement(ChoiceButton, {
      inverted: true,
      onClick: () => onChange("screenshare")
    }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      className: styles.icon,
      name: "tv"
    }))
  }), isMobile ? /* @__PURE__ */_react.default.createElement(ChoiceButton, {
    inverted: true,
    onClick: () => onChange("url")
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.icon,
    name: "linkify"
  }), isMobile && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Load URL")) : /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    content: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.bold
    }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "URL"), "Supports links from YouTube, Vimeo, Twitch and many others as well as direct video links."),
    mountNode: div,
    trigger: /* @__PURE__ */_react.default.createElement(ChoiceButton, {
      inverted: true,
      onClick: () => onChange("url"),
      style: {
        marginLeft: 4,
        marginRight: 4
      }
    }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      className: styles.icon,
      name: "linkify"
    }), isMobile && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Load URL"))
  }), canCaptureVideoElement && /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    content: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.bold
    }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Local file"), "Load a local video file. Supports mkv, mp4 and more. Subtitles supported."),
    mountNode: div,
    trigger: /* @__PURE__ */_react.default.createElement(ChoiceButton, {
      inverted: true,
      onClick: () => onChange("file"),
      style: {
        marginLeft: 4,
        marginRight: 4
      }
    }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      name: "file video"
    }), isMobile && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "Load File"))
  }));
};
exports.default = _default;