"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { Button, Input } from "kosmi-sdk/uikit";
import React, { forwardRef, useRef, useState } from "react";
import Modal from "../Widgets/AnimatedModal";
export default (Component) => {
  const EnhancedComponent = forwardRef((_a, ref) => {
    var props = __objRest(_a, []);
    const [value, setValue] = useState("");
    const resolveRef = useRef((_val) => {
    });
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [dialogType, setDialogType] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const [okText, setOkText] = useState("");
    const [cancelText, setCancelText] = useState("");
    const [hideButton, setHideButton] = useState(false);
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      Modal,
      {
        open,
        preventClosing: true,
        dialog: true,
        onClose: () => {
          const value2 = dialogType === "confirm" ? false : null;
          setOpen(false);
          setValue("");
          setCancelText(null);
          setOkText(null);
          resolveRef.current(value2);
        }
      },
      /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement("div", null, message), dialogType === "prompt" && /* @__PURE__ */ React.createElement(
        Input,
        {
          autoFocus: true,
          onChange: (e) => setValue(e.target.value),
          fluid: true,
          value: value || void 0,
          placeholder: placeholder || void 0,
          onKeyPress: (event) => {
            if (event.key === "Enter") {
              setOpen(false);
              setValue(null);
              resolveRef.current(value);
            }
          }
        }
      )),
      /* @__PURE__ */ React.createElement(Modal.Actions, null, dialogType !== "alert" && /* @__PURE__ */ React.createElement(
        Button,
        {
          color: "red",
          inverted: true,
          style: { margin: 5 },
          onClick: () => {
            const value2 = dialogType === "confirm" ? false : null;
            setOpen(false);
            setValue(null);
            resolveRef.current(value2);
          }
        },
        cancelText || "Cancel"
      ), !hideButton && /* @__PURE__ */ React.createElement(
        Button,
        {
          color: "yellow",
          inverted: true,
          style: { margin: 5 },
          onClick: () => {
            const _value = dialogType === "prompt" ? value : true;
            setOpen(false);
            setValue(null);
            resolveRef.current(_value);
          }
        },
        okText || "OK"
      ))
    ), /* @__PURE__ */ React.createElement(
      Component,
      __spreadProps(__spreadValues({}, props), {
        ref,
        alert: (message2, hideButton2) => {
          return new Promise((resolve) => {
            setMessage(message2);
            setOpen(true);
            setDialogType("alert");
            setPlaceholder(null);
            setHideButton(!!hideButton2);
            resolveRef.current = resolve;
          });
        },
        confirm: (message2, cancelText2, okText2) => {
          return new Promise((resolve) => {
            setMessage(message2);
            setOpen(true);
            setDialogType("confirm");
            setPlaceholder(null);
            setCancelText(cancelText2 || null);
            setOkText(okText2 || null);
            resolveRef.current = resolve;
          });
        },
        prompt: (message2, placeholder2) => {
          return new Promise((resolve) => {
            setMessage(message2);
            setOpen(true);
            setDialogType("prompt");
            setPlaceholder(placeholder2);
            resolveRef.current = resolve;
          });
        }
      })
    ));
  });
  return EnhancedComponent;
};
