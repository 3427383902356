"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation } from "kosmi-sdk/gql";
import { Loader } from "kosmi-sdk/uikit";
import { withStyleHook } from "kosmi-sdk/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import style from "./style.css";
const VERIFY_EMAIL_CODE = gql(`
  mutation VerifyEmailCode1($email: String!, $code: String!) {
    verifyEmailCode(email: $email, code: $code) {
      ok
    }
  }
`);
const VerifyEmailPage = () => {
  const styles = withStyleHook(style);
  const [message, setMessage] = useState("");
  const { email, code } = useParams();
  const [verifyEmailCode] = useMutation(VERIFY_EMAIL_CODE);
  useEffect(() => {
    if (email && code) {
      ;
      (() => __async(void 0, null, function* () {
        try {
          yield verifyEmailCode({
            variables: { email, code }
          });
          setMessage("Email successfully verified!");
        } catch (e) {
          setMessage(e.graphQLErrors[0].message);
        }
      }))();
    }
  }, [email, code]);
  return /* @__PURE__ */ React.createElement("div", { className: styles.discover }, message ? message : /* @__PURE__ */ React.createElement(Loader, { active: true }));
};
export default VerifyEmailPage;
