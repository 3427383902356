"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Dimensions;
var _uikit = require("kosmi-sdk/uikit");
var _elementResizeEvent = _interopRequireWildcard(require("element-resize-event"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
const styles = {
  dimensions: {
    "Dimensions__styles.dimensions": "Dimensions__styles.dimensions",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    height: "x5yr21d",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    maxHeight: "xmz0i5r",
    maxWidth: "x193iq5w",
    minHeight: "x1us19tq",
    minWidth: "xgqtt45",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    pointerEvents: "x47corl",
    position: "x10l6tqk",
    width: "xh8yej3",
    $$css: true
  }
};
function Dimensions() {
  return Component => {
    return (0, _react.memo)(props => {
      const [containerHeight, setContainerHeight] = (0, _react.useState)(null);
      const [containerWidth, setContainerWidth] = (0, _react.useState)(null);
      const [container, setContainer] = (0, _react.useState)(null);
      const containerRef = (0, _react.useCallback)(node => {
        if (node !== null) {
          setContainer(node);
        }
      }, []);
      const updateDimensions = (0, _react.useCallback)(() => {
        setContainerWidth(container.clientWidth);
        setContainerHeight(container.clientHeight);
      }, [container]);
      (0, _react.useEffect)(() => {
        if (!container) return;
        (0, _elementResizeEvent.default)(container, updateDimensions);
        updateDimensions();
        return () => {
          (0, _elementResizeEvent.unbind)(container);
        };
      }, [container, updateDimensions]);
      return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        ref: containerRef,
        style: styles.dimensions
      }, (!!containerWidth || !!containerHeight) && /* @__PURE__ */_react.default.createElement(Component, __spreadValues(__spreadValues({}, {
        containerWidth,
        containerHeight
      }), props)));
    });
  };
}