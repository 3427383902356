"use strict";
import useColorSchemes from "./useColorSchemes";
import { colors } from "../../__generated__/colors";
export default (colorSchemeId) => {
  const colorSchemes = useColorSchemes();
  if (colorSchemeId === "default") {
    return {
      id: "default",
      name: "Kosmi",
      primaryColor: colors["primary"],
      secondaryColor: colors["secondary"],
      foregroundColor: colors["foreground"]
    };
  }
  if (!colorSchemeId)
    return null;
  return colorSchemes.filter((c) => c.id === colorSchemeId)[0] || null;
};
