"use strict";
import { Button } from "kosmi-sdk/uikit";
import { AnimatedModal as Modal } from "kosmi-sdk/widgets";
import React, { memo, useRef } from "react";
import * as Subtitle from "subtitle";
export default memo(
  ({ open, subtitles, onSubtitles, onRemoveSubtitles, onClose }) => {
    const labelRef = useRef(null);
    return /* @__PURE__ */ React.createElement(Modal, { open, closeIcon: true, onClose }, /* @__PURE__ */ React.createElement(Modal.Header, null, "Subtitles"), /* @__PURE__ */ React.createElement(Modal.Content, { style: { textAlign: "center" } }, (subtitles == null ? void 0 : subtitles.cues) ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", null, subtitles.filename), /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "yellow",
        inverted: true,
        style: {
          marginTop: 20,
          width: 150,
          marginLeft: "auto",
          marginRight: "auto"
        },
        onClick: () => onRemoveSubtitles()
      },
      "Remove subtitles"
    )) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      "label",
      {
        ref: labelRef,
        style: { margin: "auto" },
        htmlFor: "subtitle_file"
      },
      /* @__PURE__ */ React.createElement(
        Button,
        {
          onClick: () => {
            var _a;
            return (_a = labelRef.current) == null ? void 0 : _a.click();
          },
          color: "yellow",
          style: { margin: "auto" }
        },
        "Load a Subtitle File (.srt or .vtt)..."
      )
    ), /* @__PURE__ */ React.createElement(
      "input",
      {
        style: { display: "none" },
        type: "file",
        id: "subtitle_file",
        name: "subtitle_file",
        onChange: (e) => {
          var _a, _b;
          const fileName = ((_a = e == null ? void 0 : e.target) == null ? void 0 : _a.files) && e.target.files[0].name;
          const reader = new FileReader();
          reader.onload = (event) => {
            var _a2;
            const cues = Subtitle.parse((_a2 = event == null ? void 0 : event.target) == null ? void 0 : _a2.result).map((s) => ({
              start: s.start,
              end: s.end,
              text: s.text
            })).filter((s) => !!s.start && !!s.end && !!s.text);
            onSubtitles({ filename: fileName, cues });
          };
          reader.readAsText(
            ((_b = e == null ? void 0 : e.target) == null ? void 0 : _b.files) && e.target.files[0]
          );
        }
      }
    ))));
  }
);
