"use strict";
import React, { memo } from "react";
import { withStyleHook } from "../../../sdk/styles";
import { useTracking } from "../../helpers/withTracking";
import Modal from "../AnimatedModal";
import style from "./style.css";
const ImageModal = memo(
  ({
    imageSrc,
    handleClose,
    open
  }) => {
    const track = useTracking();
    const styles = withStyleHook(style);
    return /* @__PURE__ */ React.createElement(
      Modal,
      {
        size: "small",
        closeIcon: true,
        open,
        onOpen: () => track(
          "UI",
          "ImageModalOpened",
          "An image modal was opened form the chat"
        ),
        basic: true,
        onClose: () => {
          handleClose();
        }
      },
      /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement("div", { className: styles.wrapper }, /* @__PURE__ */ React.createElement(
        "img",
        {
          className: styles.image,
          width: 400,
          height: "auto",
          src: imageSrc,
          alt: "Zoomed image"
        }
      )))
    );
  }
);
export default ImageModal;
