"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SupportModal = exports.SupportForm = exports.Links = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _platform = _interopRequireDefault(require("platform"));
var _react = _interopRequireWildcard(require("react"));
var _ = require("../..");
var _gql = require("kosmi-sdk/gql");
var _helpers = require("kosmi-sdk/helpers");
var _kosmihead = _interopRequireDefault(require("../../../../../pages/room/components/Sidebar/components/RoomBanner/kosmihead.svg"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _LoginWall = _interopRequireDefault(require("@/components/Widgets/LoginWall"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  dropdownWrapper: {
    "index__styles.dropdownWrapper": "index__styles.dropdownWrapper",
    position: "x1n2onr6",
    display: "x78zum5",
    $$css: true
  }
};
const inPwa = ["fullscreen", "standalone", "minimal-ui"].some(displayMode => window.matchMedia("(display-mode: " + displayMode + ")").matches) || "standalone" in window.navigator && window.navigator.standalone;
const CONTACT_SUPPORT = (0, _gql.gql)(`
  mutation SendSupportEmail($body: String!) {
    sendSupportEmail(body: $body) {
      ok
    }
  }
`);
const SupportForm = exports.SupportForm = (0, _helpers.withDialogs)(({
  onSuccess,
  alert
}) => {
  const [text, setText] = _react.default.useState("");
  const [sendSupportEmail, {
    loading
  }] = (0, _gql.useMutation)(CONTACT_SUPPORT);
  return /* @__PURE__ */_react.default.createElement(_uikit.Form, null, /* @__PURE__ */_react.default.createElement(_uikit.Form.TextArea, {
    placeholder: "What can we help you with?",
    value: text,
    onChange: e => {
      setText(e.target.value);
    }
  }), /* @__PURE__ */_react.default.createElement(_uikit.Form.Button, {
    disabled: !text || text.split(" ").length < 5,
    fluid: true,
    color: "yellow",
    inverted: true,
    loading,
    style: {
      marginTop: 10
    },
    onClick: () => __async(void 0, null, function* () {
      if (!alert) return;
      try {
        yield sendSupportEmail({
          variables: {
            body: `${text}

Sent from ${_platform.default.description}
              `
          }
        });
        yield alert("Thanks for contacting support. We will reach out to you as soon as possible. Keep an eye on your email!");
        if (onSuccess) onSuccess();
      } catch (_e) {
        alert("There was an error sending your message. Please try again later.");
      }
    })
  }, "Send"));
});
const _SupportModal = (0, _react.memo)(({
  trigger
}) => {
  const track = (0, _helpers.useTracking)();
  const [modal, setModal] = _react.default.useState(null);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    trigger,
    onOpen: () => track("UI", "OpenSupportModal", "Open Support Modal"),
    onClose: () => track("UI", "CloseSupportModal", "Close Support Modal"),
    onModal: modal2 => setModal(modal2)
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "Send support email"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(SupportForm, {
    onSuccess: () => modal == null ? void 0 : modal.handleClose()
  })));
});
const SupportModal = exports.SupportModal = (0, _helpers.withDialogs)(_SupportModal);
const Links = exports.Links = (0, _react.memo)(() => /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
  target: "_blank",
  href: "https://kosmi.io",
  rel: "noreferrer"
}, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
  name: "home"
}), " Visit Website"), /* @__PURE__ */_react.default.createElement(SupportModal, {
  trigger: /* @__PURE__ */_react.default.createElement(_uikit.Html.a, null, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "help circle"
  }), " Contact Support")
})));
const KosmiDropdown = (0, _react.memo)(({
  trigger,
  onClose,
  open
}) => {
  const [showInstallButton, setShowInstallButton] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    const isAndroid = /(android)/i.test(navigator.userAgent);
    const waitForInstallPrompt = () => {
      if (!window.installPrompt && window.installPrompt !== null) {
        setTimeout(() => waitForInstallPrompt(), 1e3);
        return;
      }
      if (window.installPrompt) {
        setShowInstallButton(!inPwa && !isAndroid);
        if (window.installPrompt.userChoice) {
          ;
          (() => __async(void 0, null, function* () {
            const userChoice = yield window.installPrompt.userChoice;
            if (userChoice && userChoice.outcome === "accepted") {
              setShowInstallButton(false);
            }
          }))();
        }
      }
    };
    waitForInstallPrompt();
  }, []);
  return /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu, {
    position: "right",
    open,
    trigger,
    onClose
  }, showInstallButton && /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu.Item, {
    text: "Install Kosmi",
    onClick: () => __async(void 0, null, function* () {
      yield window.installPrompt.prompt();
    })
  }), /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu.Item, {
    text: "Visit Website",
    icon: "home",
    onClick: () => {
      window.open("https://kosmi.io", "_blank");
    }
  }), /* @__PURE__ */_react.default.createElement(SupportModal, {
    trigger: /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu.Item, {
      text: "Contact Support",
      icon: "help circle"
    }))
  }));
});
const KosmiDropdownWrapper = (0, _react.memo)(() => {
  const [showDropdown, setShowDropdown] = (0, _react.useState)(false);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.dropdownWrapper
  }, /* @__PURE__ */_react.default.createElement(KosmiDropdown, {
    open: showDropdown,
    trigger: /* @__PURE__ */_react.default.createElement(_.TopBarButton, {
      onClick: () => {
        setShowDropdown(!showDropdown);
      }
    }, /* @__PURE__ */_react.default.createElement(_.TopBarImage, {
      src: _kosmihead.default,
      alt: "Kosmi Logo"
    })),
    onClose: () => {
      if (showDropdown) {
        setShowDropdown(false);
      }
    }
  }));
});
var _default = exports.default = KosmiDropdownWrapper;