"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireDefault(require("react"));
var _gql = require("kosmi-sdk/gql");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _LoadingScreen = _interopRequireDefault(require("../../../../../../../../../components/Widgets/LoadingScreen"));
var _useMobileDetection = require("@/hooks/useMobileDetection");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const HEADINGS = (0, _gql.gql)(`
  query MediaContentHeadings2 {
    mediaContentHeadings {
      name
      categories
    }
  }
`);
const useHeadings = () => {
  const {
    data
  } = (0, _gql.useQuery)(HEADINGS);
  return (data == null ? void 0 : data.mediaContentHeadings) || [];
};
const styles = {
  categories: {
    "index__styles.categories": "index__styles.categories",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  item: {
    "index__styles.item": "index__styles.item",
    backgroundColor: "x1vaw2wd",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xiephms",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    listStyle: "xe8uvvx",
    listStyleImage: null,
    listStylePosition: null,
    listStyleType: null,
    margin: "x16zck5j",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    cursor: "x1ypdohk",
    ":hover_backgroundColor": "x1sifw1d",
    $$css: true
  },
  active: {
    "index__styles.active": "index__styles.active",
    background: "x1odt36a",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    $$css: true
  },
  categoriesDropdown: {
    "index__styles.categoriesDropdown": "index__styles.categoriesDropdown",
    display: "x78zum5",
    padding: "x1b4vry6",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    borderRadius: "x1f96onm",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    $$css: true
  }
};
var _default = ({
  categories,
  setCategories
}) => {
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const headings = useHeadings();
  if (!headings) return /* @__PURE__ */_react.default.createElement(_LoadingScreen.default, null);
  if (isMobile) {
    return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.categoriesDropdown
    }, /* @__PURE__ */_react.default.createElement(_uikit.Select, {
      value: JSON.stringify(categories),
      onChange: e => {
        setCategories(JSON.parse(e.target.value));
      },
      options: headings.map(heading => ({
        value: JSON.stringify(heading == null ? void 0 : heading.categories) || "",
        text: (heading == null ? void 0 : heading.name) || ""
      }))
    }));
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.ul, {
    style: styles.categories
  }, headings.map((heading, i) => /* @__PURE__ */_react.default.createElement(_uikit.Html.li, {
    key: i,
    style: [styles.item, JSON.stringify(heading.categories) === JSON.stringify(categories) && styles.active],
    onClick: () => setCategories(heading.categories)
  }, heading.name)));
};
exports.default = _default;