"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Radio = void 0;
var _react = _interopRequireWildcard(require("react"));
var _Icon = require("./Icon");
var _themed = require("@rneui/themed");
var _colors = require("../layout/colors");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  checkedIcon: {
    "Radio__styles.checkedIcon": "Radio__styles.checkedIcon",
    marginRight: "xyo1k6t",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  uncheckedIcon: {
    "Radio__styles.uncheckedIcon": "Radio__styles.uncheckedIcon",
    $$css: true
  }
};
const Radio = exports.Radio = (0, _react.memo)(({
  checked,
  onChange,
  label
}) => {
  return /* @__PURE__ */_react.default.createElement(_themed.CheckBox, {
    checked: !!checked,
    onPress: onChange,
    checkedIcon: /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      name: "dot circle",
      color: "white",
      className: styles.checkedIcon
    }),
    uncheckedIcon: /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      name: "circle outline",
      color: "white",
      className: styles.uncheckedIcon
    }),
    title: /* @__PURE__ */_react.default.createElement(_themed.Text, {
      style: {
        color: (0, _colors.getVar)("foreground"),
        fontFamily: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif",
        justifyContent: "space-between"
      }
    }, label),
    textStyle: {
      color: "white"
    },
    containerStyle: {
      backgroundColor: "transparent",
      marginLeft: 0,
      marginRight: 0,
      padding: 0
    }
  });
});