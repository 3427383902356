"use strict";
import React from "react";
import Modal from "../../../../../../Widgets/AnimatedModal";
import LoginModal from "../../../../../../Widgets/LoginModal";
import { Form, Button, Checkbox } from "kosmi-sdk/uikit";
import { withStyle } from "../../../../../../../sdk/styles";
import withDialogs from "../../../../../../helpers/withDialogs";
import withCurrentUser from "../../../../../../helpers/withCurrentUser";
import style from "../style.css";
import { useState } from "react";
import { gql, useMutation } from "kosmi-sdk/gql";
const SET_NAME = gql(`
  mutation SetTemporaryDisplayName($displayName: String!) {
    setTemporaryDisplayName(displayName: $displayName) {
      id
      displayName
    }
  }
`);
let initialRender = true;
export default withCurrentUser(
  withDialogs(
    withStyle(style)(({ styles, alert, currentUser }) => {
      var _a;
      const [setName] = useMutation(SET_NAME);
      const [open, setOpen] = useState(
        initialRender && ((_a = currentUser == null ? void 0 : currentUser.displayName) == null ? void 0 : _a.startsWith("Anonymous"))
      );
      initialRender = false;
      return /* @__PURE__ */ React.createElement(
        Modal,
        {
          trigger: /* @__PURE__ */ React.createElement(
            "div",
            {
              className: [styles.loginButton, styles.changeNameButton].join(
                " "
              ),
              onClick: () => setOpen(true)
            },
            "Change name"
          ),
          open,
          basic: true,
          className: styles.setName,
          onOpen: () => setOpen(true),
          onClose: () => setOpen(false)
        },
        /* @__PURE__ */ React.createElement(Modal.Header, null, "Choose a name", /* @__PURE__ */ React.createElement(
          Button,
          {
            onClick: () => {
              setOpen(false);
            },
            basic: true,
            inverted: true,
            color: "white"
          },
          "Skip"
        )),
        /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(
          Form,
          {
            onSubmit: (e) => {
              const form = e.target;
              const name = e.target.name;
              if (!form.agree.checked) {
                alert("Please accept the Privacy Policy");
                return;
              }
              setName({ variables: { displayName: name.value } });
              setOpen(false);
            }
          },
          /* @__PURE__ */ React.createElement(Form.Input, { name: "name", required: true }),
          /* @__PURE__ */ React.createElement(
            "em",
            {
              style: {
                margin: "10px",
                display: "flex",
                justifyContent: "center",
                fontSize: "12px"
              }
            },
            "Username is optional and will be visible to others in the chat"
          ),
          /* @__PURE__ */ React.createElement(Form.Field, null, /* @__PURE__ */ React.createElement(
            Checkbox,
            {
              label: /* @__PURE__ */ React.createElement("label", null, "I agree to the", " ", /* @__PURE__ */ React.createElement(
                "a",
                {
                  target: "_blank",
                  rel: "noreferrer",
                  href: "https://www.iubenda.com/privacy-policy/30233393/legal"
                },
                "Privacy Policy"
              )),
              name: "agree",
              defaultChecked: true
            }
          )),
          /* @__PURE__ */ React.createElement(Form.Button, { color: "yellow" }, "Join Demo Day"),
          /* @__PURE__ */ React.createElement(
            LoginModal,
            {
              trigger: ({ onClick }) => /* @__PURE__ */ React.createElement(
                "div",
                {
                  style: {
                    opacity: 0.5,
                    cursor: "pointer",
                    position: "absolute"
                  },
                  onClick
                },
                "Login"
              )
            }
          )
        ))
      );
    })
  )
);
