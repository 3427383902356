"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useEffect, useRef } from "react";
export default ({
  src,
  onReady
}) => {
  const iframeRef = useRef(null);
  const lastUpdateTimestampRef = useRef(window.getServerTime() / 1e3);
  const onEvent = (ev) => {
    var _a, _b, _c, _d, _e, _f;
    const playerState = playerStateRef.current;
    if (ev.origin === "https://screeners.cinesend.com" || ev.origin === "https://cinesend.kosmi.io") {
      const timeCode = (_a = ev == null ? void 0 : ev.data) == null ? void 0 : _a.timecode;
      if (((_b = ev == null ? void 0 : ev.data) == null ? void 0 : _b.type) === "media_pause") {
        playerState.paused = true;
      }
      if (((_c = ev == null ? void 0 : ev.data) == null ? void 0 : _c.type) === "media_playing") {
        playerState.paused = false;
      }
      if (((_d = ev == null ? void 0 : ev.data) == null ? void 0 : _d.type) === "media_end") {
        playerState.duration = Infinity;
      }
      if (((_e = ev == null ? void 0 : ev.data) == null ? void 0 : _e.type) === "media_seek") {
        playerState.currentTime = ((_f = ev == null ? void 0 : ev.data) == null ? void 0 : _f.end) / 1e3;
        updateTimestamp();
        return;
      }
      if (timeCode) {
        playerState.currentTime = timeCode / 1e3;
        updateTimestamp();
      }
    }
  };
  const playerStateRef = useRef({
    currentTime: 0,
    paused: false,
    duration: null
  });
  const updateTimestamp = () => {
    lastUpdateTimestampRef.current = window.getServerTime() / 1e3;
  };
  useEffect(() => {
    var _a;
    const playerState = playerStateRef.current;
    try {
      const duration = (_a = new URL(src)) == null ? void 0 : _a.searchParams.get("duration");
      if (duration) {
        playerState.duration = parseInt(duration) || null;
      }
    } catch (e) {
      console.warn(e);
    }
    if (iframeRef.current) {
      iframeRef.current.onload = () => {
        var _a2;
        const playerState2 = playerStateRef.current;
        const iwindow = (_a2 = iframeRef.current) == null ? void 0 : _a2.contentWindow;
        const cb = {
          getDuration: () => {
            if (playerState2.duration === null) {
              return Infinity;
            }
            return playerState2.duration;
          },
          getCurrentTime: () => {
            if (playerState2.paused)
              return playerState2.currentTime;
            const lastUpdateTimestamp = lastUpdateTimestampRef.current;
            const secondsSinceLastUpdate = window.getServerTime() / 1e3 - lastUpdateTimestamp;
            return playerState2.currentTime + secondsSinceLastUpdate;
          },
          isPaused: () => __async(void 0, null, function* () {
            return playerState2.paused;
          }),
          pause: () => {
            iwindow == null ? void 0 : iwindow.postMessage({ type: "pause" }, "*");
          },
          play: () => {
            iwindow == null ? void 0 : iwindow.postMessage({ type: "play" }, "*");
            updateTimestamp();
          },
          seekTo: (position) => {
            iwindow == null ? void 0 : iwindow.postMessage({ type: "seek", timeInSeconds: position }, "*");
            updateTimestamp();
          }
        };
        onReady(cb);
        cb.play();
        if (onEvent) {
          window.addEventListener("message", onEvent);
        }
      };
    }
  }, [src, onEvent]);
  return /* @__PURE__ */ React.createElement(
    "iframe",
    {
      allow: "geolocation; microphone; camera; midi; encrypted-media; encrypted-media; fullscreen;",
      sandbox: "allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation",
      width: "100%",
      height: "100%",
      frameBorder: "0",
      src,
      ref: iframeRef
    }
  );
};
