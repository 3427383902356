"use strict";
import { gql, useMutation, useQuery } from "kosmi-sdk/gql";
import { useRoomId } from "kosmi-sdk/helpers";
import { useMemo } from "react";
const GET_MEMBERS = gql(`
  query GetTableMembers1($roomId: String!) {
    room(id: $roomId) {
      id
      state {
        members {
          id
          user {
            id
            username
            displayName
            avatarUrl
            countryCode
            isAnonymous
            isSubscribed
          }
        }
      }
    }
  }
`);
const SET_VALUE = gql(`
  mutation MediaPlayerSetPosition1(
    $roomId: String!
    $key: String!
    $value: String!
  ) {
    spacesUpdateValue(roomId: $roomId, key: $key, value: $value) {
      ok
    }
  }
`);
const REMOVE_VALUE = gql(`
  mutation MediaPlayerSetPosition2($roomId: String!, $key: String!) {
    spacesRemoveValue(roomId: $roomId, key: $key) {
      ok
    }
  }
`);
export const useSpacesActions = () => {
  const roomId = useRoomId();
  const [_setValue] = useMutation(SET_VALUE);
  const [_removeValue] = useMutation(REMOVE_VALUE);
  return useMemo(() => {
    const setValue = (key, value) => _setValue({
      variables: {
        roomId,
        key,
        value: JSON.stringify(value)
      }
    });
    const removeValue = (key) => _removeValue({
      variables: {
        roomId,
        key
      }
    });
    return {
      setValue,
      removeValue
    };
  }, [_setValue, _removeValue, roomId]);
};
export const useMembers = () => {
  var _a, _b;
  const roomId = useRoomId();
  const { data } = useQuery(GET_MEMBERS, {
    variables: {
      roomId
    }
  });
  return useMemo(() => {
    var _a2, _b2;
    return (((_b2 = (_a2 = data == null ? void 0 : data.room) == null ? void 0 : _a2.state) == null ? void 0 : _b2.members) || []).map((m) => m.user).sort((a, b) => a.id > b.id ? 1 : -1);
  }, [(_b = (_a = data == null ? void 0 : data.room) == null ? void 0 : _a.state) == null ? void 0 : _b.members]);
};
