"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _Icon = require("./Icon");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Settings = require("./Settings");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
const fadeIn = "xekv6nw-B";
const Header = _a => {
  var _b = _a,
    {
      children
    } = _b,
    props = __objRest(_b, ["children"]);
  return /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: [styles.header, props.style]
  }, children);
};
const styles = {
  modal: {
    "Modal__styles.modal": "Modal__styles.modal",
    position: "x10l6tqk",
    outline: "x1a2a7pz",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    backgroundColor: "x1d1vqyr",
    backdropFilter: "x17nw0yu",
    WebkitBackdropFilter: "x1tovznt",
    color: "x11jfisy",
    boxShadow: "xvxy23r",
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    height: "xg7h5cd",
    maxHeight: "x179dxpb",
    minHeight: "xz65tgg",
    borderWidth: "xdh2fpr",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    userSelect: "x87ps6o",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    borderColor: "xzgz5g3",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    maxWidth: "xapjn7j",
    width: "xh8yej3",
    animationName: "x127lhb5",
    animationDuration: "xw8ag78",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    "@media (max-width: 1000px)_maxWidth": "xde8ll5",
    "@media (max-width: 1000px)_maxHeight": "x85exu9",
    "::backdrop_background": "xqz7dei",
    "::backdrop_backgroundAttachment": null,
    "::backdrop_backgroundClip": null,
    "::backdrop_backgroundColor": null,
    "::backdrop_backgroundImage": null,
    "::backdrop_backgroundOrigin": null,
    "::backdrop_backgroundPosition": null,
    "::backdrop_backgroundPositionX": null,
    "::backdrop_backgroundPositionY": null,
    "::backdrop_backgroundRepeat": null,
    "::backdrop_backgroundSize": null,
    "::backdrop_animationDuration": "xkpo8v9",
    "::backdrop_animationName": "xwtu3cs",
    $$css: true
  },
  content: {
    "Modal__styles.content": "Modal__styles.content",
    display: "x78zum5",
    overflow: "x1rea2x4",
    overflowX: null,
    overflowY: null,
    transition: "x1hhud1w",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    width: "xh8yej3",
    height: "xg7h5cd",
    minHeight: "x2lwn1j",
    fontSize: "xrv4cvt",
    lineHeight: "x37zpob",
    padding: "xis3749",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    justifyContent: "xl56j7k",
    $$css: true
  },
  largeInner: {
    "Modal__styles.largeInner": "Modal__styles.largeInner",
    height: "x5yr21d",
    $$css: true
  },
  dialog: {
    "Modal__styles.dialog": "Modal__styles.dialog",
    maxWidth: "xrlsmeg",
    $$css: true
  },
  large: {
    "Modal__styles.large": "Modal__styles.large",
    height: "x5yr21d",
    "@media (max-width: 1000px)_maxWidth": "xq1f2kx",
    $$css: true
  },
  huge: {
    "Modal__styles.huge": "Modal__styles.huge",
    height: "x5yr21d",
    maxWidth: "xted6l9",
    maxHeight: "xhcnd13",
    "@media (max-width: 1000px)_maxWidth": "xq1f2kx",
    $$css: true
  },
  actions: {
    "Modal__styles.actions": "Modal__styles.actions",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x4couov",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    color: "x11jfisy",
    fontSize: "x1k87nke",
    lineHeight: "xcsx53u",
    fontWeight: "x1xlr1w8",
    display: "x78zum5",
    justifyContent: "x13a6bvl",
    $$css: true
  },
  header: {
    "Modal__styles.header": "Modal__styles.header",
    maxHeight: "x1jquxbb",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x73anc6",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    fontSize: "xobrv83",
    lineHeight: "xcsx53u",
    fontWeight: "x1xlr1w8",
    display: "x78zum5",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  main: {
    "Modal__styles.main": "Modal__styles.main",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xh8yej3",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  },
  closeIcon: {
    "Modal__styles.closeIcon": "Modal__styles.closeIcon",
    position: "x10l6tqk",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x13vifvy",
    width: "x1247r65",
    height: "xng8ra",
    fontSize: "x1pvqxga",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    cursor: "x1ypdohk",
    zIndex: "xhtitgo",
    transition: "x1trvaba",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    ":active_transform": "xvruv2t",
    $$css: true
  }
};
const CustomModal = Object.assign(_react.default.memo((0, _react.forwardRef)(({
  children,
  dialog,
  onClose,
  open: propsOpen,
  preventClosing,
  trigger,
  style,
  onModal,
  onOpen,
  large,
  huge
}, ref) => {
  const [open, setOpen] = (0, _react.useState)(false);
  const close = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };
  const closeHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    close();
  };
  const dialogRef = (0, _react.useRef)(null);
  (0, _react.useImperativeHandle)(ref, () => {
    return {
      close
    };
  }, []);
  (0, _react.useEffect)(() => {
    const observer = new IntersectionObserver(entries => {
      var _a;
      const entry = entries[0];
      if (!entry.isIntersecting) {
        (_a = dialogRef.current) == null ? void 0 : _a.close();
      }
    }, {
      root: null,
      // observing the dialog in relation to the viewport
      threshold: 0.1
      // callback is executed when 10% of the element is visible
    });
    if (dialogRef.current) {
      observer.observe(dialogRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);
  (0, _react.useEffect)(() => {
    const dialogNode = dialogRef.current;
    if (!dialogNode) return;
    dialogNode.onclose = closeHandler;
    if (open || propsOpen) {
      dialogNode.inert = true;
      dialogNode.showModal();
      dialogNode.inert = false;
      if (onOpen) {
        onOpen();
      }
    } else {
      dialogNode.close();
    }
  }, [open, propsOpen]);
  (0, _react.useEffect)(() => {
    const dialogNode = dialogRef.current;
    if (!dialogNode) return;
    dialogNode.onclick = function (event) {
      const rect = dialogNode.getBoundingClientRect();
      const isInDialog = rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width;
      const dialogs = document.querySelectorAll("dialog[open]");
      const topDialog = dialogs[dialogs.length - 1];
      if (!isInDialog && !preventClosing && topDialog === dialogNode) {
        dialogNode.close();
      }
    };
  }, [open, propsOpen, preventClosing]);
  (0, _react.useEffect)(() => {
    const dialogNode = dialogRef.current;
    if (onModal) {
      onModal({
        handleClose: () => {
          dialogNode == null ? void 0 : dialogNode.close();
        }
      });
    }
  }, [dialogRef.current]);
  const containsHeader = _react.default.Children.toArray(children).some(child => _react.default.isValidElement(child) && child.type === Header);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, typeof trigger === "function" ? trigger(() => setOpen(true)) : trigger && _react.default.cloneElement(trigger, {
    onClick: trigger.props.onClick ? trigger.props.onClick : () => setOpen(true)
  }), /* @__PURE__ */_react.default.createElement(_Html.default.dialog, {
    style: [styles.modal, large && styles.large, huge && styles.huge, dialog && styles.dialog, style],
    ref: dialogRef
  }, /* @__PURE__ */_react.default.createElement(_Settings.SettingsContextReset, null, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
    style: [styles.main, large && styles.largeInner]
  }, /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
    "aria-hidden": "true",
    name: "close",
    className: styles.closeIcon,
    onClick: () => {
      var _a;
      return (_a = dialogRef.current) == null ? void 0 : _a.close();
    }
  }), !containsHeader && /* @__PURE__ */_react.default.createElement(Header, null), children))));
})), {
  Actions: _c => {
    var _d = _c,
      {
        children
      } = _d,
      props = __objRest(_d, ["children"]);
    return /* @__PURE__ */_react.default.createElement(_Html.default.div, __spreadProps(__spreadValues({}, props), {
      style: [styles.actions, props.style]
    }), children);
  },
  Content: _e => {
    var _f = _e,
      {
        children
      } = _f,
      props = __objRest(_f, ["children"]);
    return /* @__PURE__ */_react.default.createElement(_Html.default.div, __spreadProps(__spreadValues({}, props), {
      style: [styles.content, props.style]
    }), children);
  },
  Header
});
var _default = exports.default = CustomModal;