"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _uikit = require("kosmi-sdk/uikit");
var _gql = require("kosmi-sdk/gql");
var _helpers = require("kosmi-sdk/helpers");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _widgets = require("kosmi-sdk/widgets");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const SET_NAME = (0, _gql.gql)(`
  mutation SetTemporaryDisplayName($displayName: String!) {
    setTemporaryDisplayName(displayName: $displayName) {
      id
      displayName
    }
  }
`);
let initialRender = true;
const styles = {
  a: {
    "DisplayNameModal__styles.a": "DisplayNameModal__styles.a",
    color: "x16cd2qt",
    $$css: true
  },
  loginButton: {
    "DisplayNameModal__styles.loginButton": "DisplayNameModal__styles.loginButton",
    position: "x1n2onr6",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    width: "x1exxlbk",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    margin: "x1suov85",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    height: "x5yr21d",
    transition: "x1jte46n",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  changeNameButton: {
    "DisplayNameModal__styles.changeNameButton": "DisplayNameModal__styles.changeNameButton",
    width: "x1oysuqx",
    $$css: true
  },
  loginButtonAbs: {
    "DisplayNameModal__styles.loginButtonAbs": "DisplayNameModal__styles.loginButtonAbs",
    opacity: "xbyyjgo",
    cursor: "x1ypdohk",
    position: "x10l6tqk",
    $$css: true
  },
  em: {
    "DisplayNameModal__styles.em": "DisplayNameModal__styles.em",
    margin: "x1oin6zd",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    display: "x78zum5",
    justifyContent: "xl56j7k",
    fontSize: "xfifm61",
    fontWeight: "x1uu0p8",
    color: "x16cd2qt",
    $$css: true
  }
};
var _default = exports.default = (0, _helpers.withDialogs)(({
  alert
}) => {
  var _a;
  const currentUser = (0, _helpers.currentUserHook)();
  const [setName] = (0, _gql.useMutation)(SET_NAME);
  const [open, setOpen] = (0, _react.useState)(initialRender && ((_a = currentUser == null ? void 0 : currentUser.displayName) == null ? void 0 : _a.startsWith("Anonymous")));
  initialRender = false;
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: [styles.loginButton, styles.changeNameButton],
      onClick: () => setOpen(true)
    }, "Change name"),
    open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false)
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "Choose a name", /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    onClick: () => {
      setOpen(false);
    },
    basic: true,
    inverted: true,
    color: "white"
  }, "Skip")), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Form, {
    onSubmit: e => {
      const form = e.target;
      const name = e.target.name;
      if (!form.agree.checked && alert) {
        alert("Please accept the Privacy Policy");
        return;
      }
      setName({
        variables: {
          displayName: name.value
        }
      });
      setOpen(false);
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Form.Input, {
    name: "name",
    required: true
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.em
  }, "Username is optional and will be visible to others in the chat"), /* @__PURE__ */_react.default.createElement(_uikit.Form.Field, null, /* @__PURE__ */_react.default.createElement(_uikit.Checkbox, {
    label: /* @__PURE__ */_react.default.createElement(_uikit.Html.label, null, "I agree to the", " ", /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
      style: styles.a,
      target: "_blank",
      rel: "noreferrer",
      href: "https://www.iubenda.com/privacy-policy/30233393/legal"
    }, "Privacy Policy")),
    name: "agree",
    defaultChecked: true
  })), /* @__PURE__ */_react.default.createElement(_uikit.Form.Button, {
    color: "yellow"
  }, "Join Demo Day"), /* @__PURE__ */_react.default.createElement(_widgets.LoginModal, {
    trigger: ({
      onClick
    }) => /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
      style: styles.loginButtonAbs,
      onClick
    }, "Login")
  }))));
});