"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const styles = {
  errorScreen: {
    "ErrorScreen__styles.errorScreen": "ErrorScreen__styles.errorScreen",
    display: "x78zum5",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  errorWrapper: {
    "ErrorScreen__styles.errorWrapper": "ErrorScreen__styles.errorWrapper",
    flexDirection: "xdt5ytf",
    display: "x78zum5",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  errorText: {
    "ErrorScreen__styles.errorText": "ErrorScreen__styles.errorText",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "x11fxgd9",
    marginBottom: null,
    fontSize: "xew7ush",
    fontWeight: "x1xlr1w8",
    $$css: true
  },
  errorIcon: {
    "ErrorScreen__styles.errorIcon": "ErrorScreen__styles.errorIcon",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "x11fxgd9",
    marginBottom: null,
    $$css: true
  }
};
const ErrorScreen = ({
  text,
  children
}) => /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
  style: styles.errorScreen
}, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
  style: styles.errorWrapper
}, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
  color: "yellow",
  size: "huge",
  name: "warning sign",
  className: styles.errorIcon
}), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
  style: styles.errorText
}, text === "{}" ? null : text), children));
var _default = exports.default = ErrorScreen;