"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("kosmi-sdk/uikit");
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _room = require("kosmi-sdk/room");
var _Seekbar = _interopRequireDefault(require("./Seekbar"));
var _VolumeSlider = _interopRequireDefault(require("./VolumeSlider"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
const styles = {
  fullScreenControls: {
    "index__styles.fullScreenControls": "index__styles.fullScreenControls",
    alignItems: "x6s0dn4",
    background: "x1y8t58i",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    bottom: "x1ey2m1c",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    height: "xvt47uu",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    maxWidth: "x193iq5w",
    padding: "x17qkr04",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    position: "x10l6tqk",
    right: "x3m8u43",
    width: "xh8yej3",
    zIndex: "xhtitgo",
    pointerEvents: "x71s49j",
    $$css: true
  },
  middle: {
    "index__styles.middle": "index__styles.middle",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    height: "x5yr21d",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  videoInfo: {
    "index__styles.videoInfo": "index__styles.videoInfo",
    position: "x10l6tqk",
    inset: "x10a8y8t",
    insetInline: null,
    insetInlineStart: null,
    insetInlineEnd: null,
    left: null,
    right: null,
    insetBlock: null,
    top: null,
    bottom: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "x1e1m7ul",
    marginBottom: "xu06os2",
    width: "x1oysuqx",
    textAlign: "x2b8uid",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    color: "x11jfisy",
    height: "x1gnnpzl",
    maxHeight: "x990d50",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  menu: {
    "index__styles.menu": "index__styles.menu",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    width: "xh8yej3",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    padding: "x1p3i4rr",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: "x1120s5i",
    $$css: true
  },
  toggleNativeControlsButton: {
    "index__styles.toggleNativeControlsButton": "index__styles.toggleNativeControlsButton",
    cursor: "x1ypdohk",
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    top: "x1eu8d0j",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    zIndex: "x1vjfegm",
    pointerEvents: "x71s49j",
    $$css: true
  }
};
const HintedButton = _a => {
  var _b = _a,
    {
      hint
    } = _b,
    props = __objRest(_b, ["hint"]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    content: hint,
    position: "top center",
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, __spreadProps(__spreadValues({}, props), {
      style: {
        marginLeft: 5,
        marginRight: 5,
        pointerEvents: "all",
        width: 36,
        height: 36
      }
    }))
  });
};
var _default = exports.default = (0, _react.memo)((0, _room.Dimensions)()(({
  paused,
  timestamp,
  duration,
  url,
  isLive,
  isFullscreen,
  videoInfo,
  freeUpRightSide,
  controls: {
    play,
    pause,
    stop,
    loadMedia,
    showCcMenu,
    setVolume,
    seekTo,
    fullscreen,
    mute,
    unmute,
    toggleNativeControls
  },
  muted,
  volume,
  nativeControls,
  containerWidth,
  isCompact,
  isSmall
}) => {
  const rightButtons = /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, showCcMenu && !isCompact && /* @__PURE__ */_react.default.createElement(HintedButton, {
    hint: "Subtitles",
    color: "blue",
    onClick: showCcMenu,
    icon: "closed captioning outline",
    disabled: !showCcMenu
  }), fullscreen && !isCompact && /* @__PURE__ */_react.default.createElement(HintedButton, {
    hint: isFullscreen ? "Shrink into TV" : "Expand",
    icon: isFullscreen ? "compress" : "expand",
    color: "blue",
    onClick: fullscreen
  }));
  if (toggleNativeControls && nativeControls) {
    return /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
      content: "Go back to Kosmi Controls",
      trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        style: {
          borderWidth: 1,
          position: "absolute",
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          top: "10%",
          width: "10%",
          maxWidth: 50
        },
        color: "blue",
        onClick: () => {
          toggleNativeControls();
        },
        icon: "caret down"
      })
    });
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.fullScreenControls
  }, !isLive && url && duration !== void 0 && timestamp !== void 0 && /* @__PURE__ */_react.default.createElement(_Seekbar.default, {
    seekTo,
    disabled: !seekTo,
    position: timestamp,
    duration
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.menu
  }, !isCompact && /* @__PURE__ */_react.default.createElement(HintedButton, {
    hint: "Load media",
    icon: "kosmi mediabutton",
    color: "blue",
    onClick: loadMedia,
    disabled: !loadMedia
  }), !isCompact && /* @__PURE__ */_react.default.createElement(HintedButton, {
    hint: "Stop",
    disabled: !stop,
    icon: "stop",
    color: "blue",
    onClick: stop
  }), paused && !isCompact && /* @__PURE__ */_react.default.createElement(HintedButton, {
    hint: "Play",
    disabled: !play,
    icon: "play",
    color: "blue",
    onClick: play
  }), !paused && !isLive && !isCompact && /* @__PURE__ */_react.default.createElement(HintedButton, {
    hint: "Pause",
    disabled: !pause,
    icon: "pause",
    color: "blue",
    onClick: pause
  }), freeUpRightSide && rightButtons, setVolume && unmute && mute && /* @__PURE__ */_react.default.createElement(_VolumeSlider.default, {
    unmute,
    mute,
    muted,
    volume,
    setVolume
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.middle
  }, !isSmall && toggleNativeControls && containerWidth > 300 && /* @__PURE__ */_react.default.createElement(_uikit.Popup, {
    content: "Fallback to native controls",
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
      className: styles.toggleNativeControlsButton,
      onClick: () => {
        toggleNativeControls();
      },
      name: "caret up"
    })
  }), !isSmall && containerWidth > 400 && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.videoInfo
  }, videoInfo)), !freeUpRightSide && rightButtons));
}));