"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useMutation } from "kosmi-sdk/gql";
import {
  Button,
  SearchInput,
  Loader,
  Select,
  UserProfile
} from "kosmi-sdk/uikit";
import React, { useState } from "react";
import withDialogs from "../../../../../../../helpers/withDialogs";
import Modal from "../../../../../../../Widgets/AnimatedModal";
import { useQuery } from "@apollo/client";
const INVITE_TO_ROOM_MUTATION = gql(`
  mutation InviteToRoomMutation2($userId: String!, $roomId: String!) {
    inviteToRoom(userId: $userId, roomId: $roomId) {
      ok
    }
  }
`);
const REMOVE_FRIEND_MUTATION = gql(`
  mutation RemoveFriendMutation($userId: String!) {
    removeFriend(userId: $userId) {
      id
      friends {
        state
        user {
          id
          isSubscribed
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const USER_QUERY = gql(`
  query UserQuery($id: String!) {
    user(id: $id) {
      id
      username
      displayName
      avatarUrl
      isConnected
      isSubscribed
    }
  }
`);
const USER_SEARCH_QUERY = gql(`
  query UserSearchQuery($query: String!) {
    searchUsers(query: $query) {
      id
      username
      displayName
      avatarUrl
    }
  }
`);
const USER_ROOMS = gql(`
  query UserRoomsQuery {
    userRooms {
      id
      state {
        metadata {
          roomName
          avatarUrl
        }
      }
    }
  }
`);
const ADD_FRIEND_MUTATION = gql(`
  mutation AddFriendMutation1($username: String!) {
    addFriend(username: $username) {
      id
      friends {
        state
        user {
          id
          displayName
          username
          avatarUrl
          email
          isConnected
        }
      }
    }
  }
`);
const InviteToRoomModal = withDialogs(
  ({
    alert,
    userId,
    user,
    onClose
  }) => {
    const roomRegex = /\/room\/([a-zA-Z0-9@]+)/;
    const matches = roomRegex.exec(window.location.pathname);
    const currentRoomId = matches ? matches[1] : null;
    const [roomId, setRoomId] = useState(currentRoomId);
    const { data } = useQuery(USER_ROOMS, { fetchPolicy: "cache-first" });
    const rooms = data && data.userRooms ? data.userRooms : [];
    const [inviteToRoom] = useMutation(INVITE_TO_ROOM_MUTATION);
    return /* @__PURE__ */ React.createElement(Modal, { dialog: true, closeIcon: true, open: !!userId, size: "mini", onClose }, /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          display: "flex",
          flexDirection: "column",
          padding: 10
        }
      },
      /* @__PURE__ */ React.createElement("div", { style: { margin: 10 } }, "Invite ", user.displayName, " to room:"),
      /* @__PURE__ */ React.createElement(
        Select,
        {
          style: { margin: 10 },
          placeholder: "Select room",
          defaultValue: roomId || "",
          value: roomId || "",
          options: rooms.map((r) => {
            return {
              key: r.id,
              value: r.id,
              text: r.state.metadata.roomName,
              image: r.state.metadata.avatarUrl
            };
          }),
          onChange: (e) => {
            setRoomId(e.target.value);
          }
        }
      ),
      /* @__PURE__ */ React.createElement(
        Button,
        {
          color: "yellow",
          inverted: true,
          style: { margin: 10 },
          onClick: () => __async(void 0, null, function* () {
            if (roomId) {
              inviteToRoom({
                variables: {
                  userId: userId || "",
                  roomId
                }
              });
              if (alert)
                yield alert("Invite sent");
            } else if (alert) {
              yield alert("No room selected");
            }
            onClose();
          })
        },
        "Invite"
      )
    )));
  }
);
export const UserProfileModal = withDialogs(
  ({
    open,
    onClose,
    userId,
    currentUser,
    confirm
  }) => {
    const friendIds = currentUser.friends.filter((f) => f.state === "ACCEPTED").map((f) => f.user.id);
    const isFriend = friendIds.indexOf(userId) !== -1;
    const [removeFriend] = useMutation(REMOVE_FRIEND_MUTATION);
    const [inviteToRoomUserId, setInviteToRoomUserId] = useState(
      null
    );
    const { data } = useQuery(USER_QUERY, {
      variables: { id: userId || "" },
      fetchPolicy: "cache-first",
      skip: !userId
    });
    const user = data == null ? void 0 : data.user;
    const actions = [];
    if (isFriend) {
      actions.push({
        icon: "remove user",
        text: "Remove friend",
        color: "red",
        onClick: () => __async(void 0, null, function* () {
          if (confirm && (yield confirm(
            `Are you sure you want to remove ${user == null ? void 0 : user.displayName}`
          ))) {
            yield removeFriend({ variables: { userId: userId || "" } });
            onClose();
          }
        })
      });
      actions.push({
        icon: "user plus",
        onClick: () => setInviteToRoomUserId(userId),
        text: "Invite to room..."
      });
    }
    return /* @__PURE__ */ React.createElement(Modal, { size: "tiny", closeIcon: true, open, onClose }, /* @__PURE__ */ React.createElement(Modal.Content, null, user ? /* @__PURE__ */ React.createElement("div", { style: { display: "flex", justifyContent: "center" } }, /* @__PURE__ */ React.createElement(
      InviteToRoomModal,
      {
        userId: inviteToRoomUserId,
        user,
        onClose: () => setInviteToRoomUserId(null)
      }
    ), /* @__PURE__ */ React.createElement(
      UserProfile,
      {
        displayName: user.displayName || "",
        username: user.username || "",
        avatarUrl: user.avatarUrl || "",
        isSubscribed: user.isSubscribed || false,
        isAnonymous: false,
        actions
      }
    )) : /* @__PURE__ */ React.createElement(Loader, { size: "massive" })));
  }
);
export const UserPickerModal = withDialogs(
  ({
    alert,
    open,
    onClose
  }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const { data, loading } = useQuery(USER_SEARCH_QUERY, {
      variables: { query: searchQuery },
      skip: searchQuery.length < 3
    });
    const _onClose = () => {
      onClose();
    };
    const [addFriend, { loading: addFriendLoading }] = useMutation(ADD_FRIEND_MUTATION);
    const [value, setValue] = useState(null);
    return /* @__PURE__ */ React.createElement(Modal, { size: "tiny", closeIcon: true, open, onClose: _onClose }, /* @__PURE__ */ React.createElement(Modal.Header, null, "Add a Friend"), /* @__PURE__ */ React.createElement(Modal.Content, null, /* @__PURE__ */ React.createElement("div", { style: { height: 150 } }, /* @__PURE__ */ React.createElement(
      SearchInput,
      {
        placeholder: "Search...",
        fluid: true,
        value: searchQuery,
        onChange: (e) => {
          setValue(null);
          setSearchQuery(e.target.value);
        },
        onSelect: (value2) => {
          setValue(value2);
        },
        loading,
        results: data && data.searchUsers && data.searchUsers.map((u) => ({
          key: u.username,
          image: u.avatarUrl,
          text: u.username
        })) || []
      }
    ))), /* @__PURE__ */ React.createElement(Modal.Actions, null, /* @__PURE__ */ React.createElement(
      Button,
      {
        inverted: true,
        color: "white",
        disabled: loading,
        onClick: () => {
          setSearchQuery("");
          setValue(null);
          _onClose();
        }
      },
      "Cancel"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        color: "yellow",
        disabled: !value,
        inverted: true,
        loading: addFriendLoading,
        style: {
          marginLeft: 8
        },
        onClick: () => __async(void 0, null, function* () {
          var _a;
          try {
            yield addFriend({
              variables: { username: value || "" }
            });
            if (alert)
              alert("Friend request sent");
            setSearchQuery("");
            setValue(null);
            _onClose();
          } catch (e) {
            if (alert)
              alert((_a = e == null ? void 0 : e.graphQLErrors[0]) == null ? void 0 : _a.message);
          }
        })
      },
      "Add friend"
    )));
  }
);
