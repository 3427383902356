"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect } from "react";
import { Button, Input } from "kosmi-sdk/uikit";
import { FadeInTransition } from "kosmi-sdk/widgets";
import { useTracking } from "kosmi-sdk/helpers";
import { useMediaPlayerActions } from "../../useMediaPlayerState";
export default ({
  onClose,
  children
}) => {
  const [value, setValue] = useState("");
  const { setUrl } = useMediaPlayerActions();
  const track = useTracking();
  useEffect(() => {
    track("UI", "VideoURLModalOpened", "URL modal opened");
  }, []);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        width: "100%",
        maxWidth: "500px",
        flex: 1
      }
    },
    /* @__PURE__ */ React.createElement(FadeInTransition, { style: { justifyContent: "center" } }, children, /* @__PURE__ */ React.createElement(
      Input,
      {
        autoFocus: true,
        onChange: (e) => setValue(e.target.value),
        fluid: true,
        value,
        placeholder: "https://... or magnet:...",
        onKeyPress: (e) => __async(void 0, null, function* () {
          if (e.key === "Enter") {
            yield setUrl(value);
            setValue("");
            onClose();
          }
        }),
        action: /* @__PURE__ */ React.createElement(
          Button,
          {
            color: "blue",
            style: {
              maxHeight: "100%",
              width: 80,
              marginLeft: 5
            },
            onClick: () => __async(void 0, null, function* () {
              if (!value)
                return;
              yield setUrl(value);
              onClose();
            })
          },
          "Open"
        )
      }
    ))
  );
};
